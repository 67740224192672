import React from 'react';
import './carousel.css';
import {  Link } from "react-router-dom";
import NavbarMenu from './NavbarMenu.js';
function DirDesk() {
    return (<div> <NavbarMenu />
        <div class="manual">
        <div class="row container">
        <p class="mypathhead">
        <Link to="/"><span class="mypath">Home</span></Link>&gt;<span class="mypath">The Institute</span>&gt;<span class="mypath">Director's Desk</span>
        </p>
          
        </div>
        <h1 class="mypathhead">Director's Desk</h1>
        <div class="scientistdetail">
        <div class="mytext"><strong>
        <p align="justify">National Institute of Occupational Health is a premier  public health institute, which was established in 1966 as Occupational Health  Research Institute (OHRI), taking 2 rooms at BJ Medical College, Ahmedabad. The OHRI was later renamed as &quot;National Institute of  Occupational Health&quot; (NIOH) in 1970 and was moved to its present premises.  Since then till now, NIOH has been working continuously through its main as  well as two of its regional centres – Bangalore &amp; Kolkata. Our primary responsibility is to support the national  policy makers for adopting an appropriate policy in the field of occupational  health, based on felt needs of our country. This is done through multi-disciplinary research, capacity building and technological development. Considering  quantum of burden of occupational diseases, &gt;90% of our workers work in the  unorganized sectors. Priority-wise we have a focus on working towards dust  related respiratory morbidities (such as silicosis, anthracosis, byssinosis  etc.). Similarly, other diseases of concern are heavy metal poisoning,  musculosketal disorders, pesticide poisoning etc. in various occupational  groups.</p>
    <p align="justify">We have already started working on prevention &amp; control  of silicosis and other dust related lung diseases. There are an estimated  3-million workers, who are working in various industries (ceramic, foundries,  agate, stone polishing, stone quarries etc.) and they are getting exposed to  silica dust daily while working. Recently, we have identified a serum protein  known as CC-16 which is secreted from the non-ciliated epithelial cells of the lungs  known as club cells. CC-16 could be used as a potential bio-marker for early  detection of any dust related disease where extensive lung damage occurs due to  its continuous inhalational exposure. </p>
    <p align="justify">Silicosis is an irreversible disease for which there  is no specific treatment &amp; no cure. But it is 100% preventable disease,  provided it is detected at an early stage. CC-16 bio-marker appears to be a  promising one as evidenced from our recent pilot study. Now, we are preparing for  a bigger study to validate our initial finding. Our next approach would be to  develop a point of care diagnostic device for detection of CC-16 level among  dust exposed workers. We are trying to develop an indigenous kit for detection  of CC16 in serum. Our next attempt would be initiating advocacy for changing  legislation towards ensuring regular screening of dust exposed workers, so that  early detection is made possible for silicosis or anthracosis. Extensive  awareness campaign to educate workers/management staff is equally important  using suitable technology such as mobile phone. We also wish to remind that  silicosis cases are more at risk of developing tuberculosis including  multi-drug resistant tuberculosis. So, unless silicosis is controlled, control  of TB will not be possible from our country. </p>
    <p align="justify">We are also in the process of developing a transdermal  patch that would detect sweat lead level among workers vulnerable to lead  toxicity. Once in use, workers can detect their sweat lead status by  themselves. Similarly, we need many more technological developments through  collaboration &amp; cooperation with industries, academia &amp; other bio-medical  research organizations. </p>
    <p align="justify">Lastly, we sincerely welcome for any kind of useful suggestion  or constructive criticism that would help us growing further focusing betterment  of under-privileged workers&rsquo; health &amp; safety of our country.</p>

        </strong>
                    </div>
        </div>
       
        
        </div>
        </div>
    
    );
}
export default DirDesk;