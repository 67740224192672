import React, {useState} from 'react';
import { Link, NavLink} from "react-router-dom";
import './App.css';
function NavbarMenu () {
  const [showMenu, setShowMenu] = useState(false)
    function myFunction(){
        var x = document.getElementById("topnav");
        if (x.className === "container-fluid topnav") {
            x.className += " responsive";
          } 
        
 }     
    return (<nav class="navbar navbar-expand-xl navbar-dark"  style={{backgroundColor:"#002147"}}>
   
      
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon" ></span>Click Here For Detailed Menu
      </button>
      <div class="collapse navbar-collapse" id="navbarNav" >
      
      <ul class="navbar-nav my-nav-font" >
    <li class="nav-item">
      <Link to="/" href="#" ><p class= "nav-link my-nav-font" style={{color:'white',marginTop:"2px",marginLeft:"180px"}}>Home</p></Link>
    </li>
    <li class="nav-item dropdown">
      <p class="my-nav-font nav-link dropdown-toggle" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{color:'white'}}> The Institute </p>
      <ul class="dropdown-menu myulnav" aria-labelledby="navbarDropdownMenuLink">
          <Link to="/about"><li><a class="dropdown-item" href="#" style={{fontSize:"16px"}}>About Institute</a></li></Link>
          <Link to="/meet-minister"><li><a class="dropdown-item" href="#" style={{fontSize:"16px"}}>Meet the Ministers</a></li></Link>
          <Link to="/dic"><li><a class="dropdown-item" href="#" style={{fontSize:"16px"}}>Director's Profile</a></li></Link>
          <Link to="/DirDesk"><li><a class="dropdown-item" href="#" style={{fontSize:"16px"}}>Director's Desk</a></li></Link>
          <Link to="/Orgorgano"><li><a class="dropdown-item" href="#" style={{fontSize:"16px"}}> Organogram</a></li></Link>
          <Link to="/commitee"><li><a class="dropdown-item" href="#" style={{fontSize:"16px"}}>Committee</a></li></Link>
</ul>
</li>
<li class="nav-item dropdown">
<p class="my-nav-font nav-link dropdown-toggle" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{color:'white'}}> Education</p>
<ul class="dropdown-menu myulnav" aria-labelledby="navbarDropdownMenuLink">
 
  <Link to="/EdAFIH"><li><a class="dropdown-item" href="#" style={{fontSize:"16px"}}>AFIH</a></li></Link>
  <Link to="/EdPhd"><li><a class="dropdown-item" href="#" style={{fontSize:"16px"}}>PHD</a></li></Link>
  
  <Link to="/EdDissert"><li><a class="dropdown-item" href="#" style={{fontSize:"16px"}}>Dissertation</a></li></Link>
</ul>
</li>
<li class="nav-item">
<Link to="/Division" href="#" ><p class= "nav-link my-nav-font" style={{color:'white',marginTop:"2px"}}>Division</p></Link>
</li>
<li class="nav-item dropdown">
<p class="my-nav-font nav-link dropdown-toggle" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{color:'white'}}> Dissemination </p>
      <ul class="dropdown-menu myulnav" aria-labelledby="navbarDropdownMenuLink">
          <Link to="/"><li><a class="dropdown-item" href="#" style={{fontSize:"16px"}}>Publications</a></li></Link>
          <Link to="/"><li><a class="dropdown-item" href="#" style={{fontSize:"16px"}}>Annual Reports</a></li></Link>
          <Link to="/"><li><a class="dropdown-item" href="#" style={{fontSize:"16px"}}>News Letter</a></li></Link>
          <Link to="/workshop"><li><a class="dropdown-item" href="#" style={{fontSize:"16px"}}>Workshop/Seminars/Conference</a></li></Link>
          <Link to="/"><li><a class="dropdown-item" href="#" style={{fontSize:"16px"}}>Training</a></li></Link>
</ul>
</li>
<li class="nav-item dropdown">
<p class="my-nav-font nav-link dropdown-toggle" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{color:'white'}}>Notification </p>
      <ul class="dropdown-menu myulnav" aria-labelledby="navbarDropdownMenuLink">
          <Link to="/tenderNioh"><li><a class="dropdown-item" href="#" style={{fontSize:"16px"}}>Tenders</a></li></Link>
          <Link to="/recruitment"><li><a class="dropdown-item" href="#" style={{fontSize:"16px"}}>Recruitments</a></li></Link>
          <Link to="/circular"><li><a class="dropdown-item" href="#" style={{fontSize:"16px"}}>Office Circulars</a></li></Link>
      </ul>
</li>
<li class="nav-item dropdown">
<p class="my-nav-font nav-link dropdown-toggle" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{color:'white'}}>Scientist's Profile</p>
      <ul class="dropdown-menu myulnav" aria-labelledby="navbarDropdownMenuLink">
          <Link to="/ScientistNIOH"><li><a class="dropdown-item" href="#" style={{fontSize:"16px"}}>NIOH Ahmedabad</a></li></Link>
          <Link to="/ScientistROHCS"><li><a class="dropdown-item" href="#" style={{fontSize:"16px"}}>ROHCS Bengaluru</a></li></Link>
         
      </ul>
</li>

<li class="nav-item dropdown">
<p class="my-nav-font nav-link dropdown-toggle" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{color:'white'}}> Staff </p>
<ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
  
  <li class="dropdown-submenu"><a class="my-nav-font dropdown-item dropdown-toggle" href="#">NIOH Ahmedabad</a>
      <ul class="dropdown-menu">
      <li><Link to="/AdminNioh"><a class=" dropdown-item" href="#" style={{fontSize:"16px"}}>Administrative</a></Link></li>
      <li><Link to="/TechNioh"> <a class="dropdown-item" href="#" style={{fontSize:"16px"}}>Technical</a></Link></li>
          
      </ul>
  </li>
  <li class="dropdown-submenu"><a class="my-nav-font dropdown-item dropdown-toggle" href="#">ROHCS Bengaluru</a>
      <ul class="dropdown-menu">
      <li><Link to="/AdminROHCS"><a class=" dropdown-item" href="#" style={{fontSize:"16px"}}>Administrative</a></Link></li>
      <li><Link to="/TechROHCS"> <a class="dropdown-item" href="#" style={{fontSize:"16px"}}>Technical</a></Link></li>
          
      </ul>
  </li>
</ul>
</li>
<li class="nav-item">
<Link to="/contactUs" href="#" ><p class= "nav-link my-nav-font" style={{color:'white',marginTop:"2px"}}>Connect Us</p></Link>
    </li>
  </ul>
  </div>
     
   
  </nav>);
}
export default NavbarMenu;