import React from 'react';
import '../carousel.css';
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import NavbarMenu from '../NavbarMenu.js';
function DrImmad() {
    return (
      <div> <NavbarMenu /><div class="manual">
        <div class="row container">
        <p class="mypathhead">
        <Link to="/"><span class="mypath">Home</span></Link>&gt;<Link to="/ScientistNIOH"><span class="mypath">Scientist's Profile</span></Link>&gt;<span class="mypath">NIOH Ahmedabad</span>
        </p>
          
        </div>
        <h1 class="mypathhead">Scientist's Detail</h1>
        <div class="scientistdetail">
      
            <div class="panel my-panel" >
      <div class="panel-heading my-panel-head" align="left"><strong>Basic Details</strong></div>
      <div class="panel-body" align="left">
      <table width="100%">
            <tr>
              <td width="28%" rowspan="5"><img src="../assests/images/niohScientist/immed.jpg" width="170px" height="170px" CLASS="dg-img"/></td>
              <td width="50%" ><p class="myfontSci trSci">Dr Immad Ahmad Shah</p> </td>
              
            </tr>
            
            <tr>
              
              <td class="myfontSci"><p class="myfontSci">Health sciences Division</p></td>
             </tr>
            <tr>
              <td><p ><a href=""><i class="fa fa-envelope" aria-hidden="true"></i>   Email: immad.shah@icmr.gov.in</a></p></td>
            </tr> 
            <tr>
              <td><p ><a href=""> <i class="fa fa-phone-square"></i>  Contact: 079-22688922</a></p></td>
            </tr>   
           
            </table>   
      </div>
    </div>
       
        <div class="panel my-panel" >
      <div class="panel-heading my-panel-head" align="left"><strong>Academic Qualification</strong></div>
      <div class="panel-body" align="left">
      B.Sc. (Agriculture); M.Sc. (Statistics), Ph.D. (Statistics)
</div>
    </div>
    <div class="panel my-panel" >
      <div class="panel-heading my-panel-head" align="left"><strong>Research Interest </strong></div>
      <div class="panel-body" align="left">
      <ul><li>Big data, Machine Learning, Statistical Modelling, Applied Statistics, Biostatistics, Design of Experiments, Sampling</li></ul>

</div>
</div>
<div class="panel my-panel">
      <div class="panel-heading my-panel-head" align="left"><strong>Membership/Affliation </strong></div>
      <div class="panel-body" align="left">
      <ul>
	  <li>	Life Member of the Society for Bioinformatics and Biological Sciences</li>
      <li>	Life Member of Agro Environmental Developmental Society (AEDS)</li>

</ul>
</div>
</div>
<div class="panel my-panel">
      <div class="panel-heading my-panel-head" align="left"><strong>Fellowship/Awards </strong></div>
      <div class="panel-body" align="left">
      <ul>
	  <li>	Faculty topper in Doctoral programme, Batch 2016.</li><br/>
      <li>	1st Rank Holder in Division of Agricultural Statistics during Masters Programme in Statistics, Batch 2013.</li><br/>
      <li>	Recipient of University Merit Fellowship at Masters Level (2013-AS-12-M) vide university order no. 19 (Acad.) of 2015 dated 23.04.2015.</li><br/>
      <li>	Recipient of University Merit Fellowship at Doctoral Level (2016-674-D) vide university order no. AU/FOH/2018/S-10/14183-14202 dated 31.03.2017.</li><br/>
      <li>	Young Scientist Award 2021 at 3rd ICFAI conference from 24th to 26th December 2021, Ranchi Jharkhand, India.</li><br/>
      <li>	Letter of appreciation from Indian Society of Agricultural Statistics for organizing 73rd Annual Conference of ISAS on Statistics and Machine Learning for Big Data Analytics at SKUAST-K from 14th-16th Nov 2022</li><br/>
      <li>	Certificate of appreciation from Dean, Faculty of Forestry, SKUAST-K.</li><br/>
      <li>	Certificate of appreciation from Head, Division of Agricultural Statistics, SKUAST-K</li>

</ul>
</div>
</div>
<div class="panel my-panel">
      <div class="panel-heading my-panel-head" align="left"><strong>Publications </strong></div>
      <div class="panel-body" align="left">
	
      <ol>
      <li> 	<strong>Immad A Shah</strong>*, S A Mir, M S Pukhta, Imran Khan, Pradeep Mishra, S Maqbool, Nageena Nazir, Bariz Aijaz Wani. Ameliorated Shewhart type Mean Control Chart Using Quartile Based Variant of the Ranked Set Sampling Scheme. Journal of Indian Society of Agricultural Statistics (Accepted)</li><br/>
      <li> 	<strong>Immad A Shah</strong>. An approach to preliminary detection and confirmation of potential outliers using R: A case of false suspicionof outliers in data. International Journal of Agricultural and Statistical Sciences (Accepted)</li><br/>
      <li> 	Shikha Yadav , Pradeep Mishra, Binita Kumari, <strong>Immad A Shah</strong> , Kadir Karakaya, Shweta Shrivastri, Chellai Fatih , Soumik Ray and Abdullah Mohammad Ghazi Al Khatib. Modelling and Forecasting of Maize production in South Asian counties. Economic Affairs,2022 Vol. 67, No. 09, pp. 01-14.</li><br/>
      <li> 	Suhail Altaf, Shaheen Kousar Jan, Umer Basu, Shafat Ahmad Ahanger, Anand Dave, Sardar Singh Kakraliya, Alaa Baazeem, Ajay Kumar Mishra, Anupam Kumar, <strong>Immad Ahmad Shah</strong>  and Muntazir Mushtaq. Sustainable Management of Green Mold Disease of White Button Mushroom Using Botanicals and Biocontrol Agents under Temperate Conditions. Horticulturae 2022, 8, 768. https://doi.org/10.3390/horticulturae8090768 </li><br/>
      <li> 	Ishfaq Ahmad Bhat* , S.A. Mir, <strong>Immad A Shah</strong> and Uzma Majeed. Evaluation of efficient Experimental Design for Leaf Analysis of Apple Cultivar “Red Delicious” as affected by Sample Size under Kashmir Climatic conditions. Biological Forum 14(3): 1234-1236 (2022)</li><br/>
      <li> 	Khder Mohammed Alakkari 1, Pradeep Mishra 2*, Deepa Rawat 3, Mostafa Abotaleb 4, Abdullah Mohammad Ghazi Al khatib 5, Monika Devi 6 and <strong>Immad A Shah</strong> 7. Using ETS State Space Model for forecasting on third wave on COVID19 in India. Mathematical Science Letters 11, No. 2, 1-5 (2022), http://dx.doi.org/10.18576/msl/paper</li><br/>
      <li> 	K.N.Qaisar, <strong>Immad A Shah</strong>*, P A Khan, Tareq A Rather, Mehraj u din Dar, Monisa Banday. Mapping and economic evaluation of traditional wicker willow in the plains of Kashmir Himalaya, India. Current Science, Vol. 122, No. 12, 25 June 2022</li><br/>
      <li> 	Ali, M.T., Mir, M.S., Mehraj, S. <strong>Shah, I. A.</strong> Implications of variable environments on phenology of apple (Malus × domestica Borkh.) in Northwestern Himalayan region. Int J Biometeorology (Springer Nature) (2022). https://doi.org/10.1007/s00484-022- 02250-0</li><br/>
      <li> 	Anup Raj, <strong>Immad A. Shah</strong>*, Ashfaq A. Mir and Amjad M. Hussaini (2022). Assessment of Clonal differences in Poplar (Populus deltoides) by Virtual Microscopy using Foldscope. Biological Forum – An International Journal, 14(1): 1200-1200</li><br/>
      <li> 	Masoodi T. H., <strong>Shah, I. A.</strong>, Dar. M U, Sofi, P A., Mugloo, J A., Evaluation of Probability distribution functions applied to tree diameter in a mixed uneven Kiker (Robinia pseudocacia) stand of Kashmir Himalaya. Current Science, VOL. 121, NO. 4, 25 AUGUST 2021</li><br/>
      <li> 	<strong>Shah, I. A.</strong>, Mir, S. A., Khan, I. 2020. New quartile based variant of the ranked set sampling scheme. Journal of Indian Society of Agricultural Statistics. 74 (3), 255-264</li><br/>
      <li> 	Mir, S. A., <strong>Shah, I. A.</strong> 2020. The construction of Repeated Measurement Designs using Trial and Error method. Statistics in Transition (New Series), March 2021 Vol. 22, No. 1 pp. 131–144, DOI 10.21307/stattrans-2021-007</li><br/>
      <li> 	Dar, M., Qaisar, K. N., Mughal, A. H., Khan, P. A., Masoodi, T. H., Mugloo, J. A., <strong>Shah, I. A.</strong> 2020.Soil organic content and nutrient status in temperate agroforestry systems of Kashmir Himalaya. Range Management and Agroforestry Journal. 41 (2) : 374-380</li><br/>
      <li> 	Tahir Mushtaq, SA Gangoo, Peerzada Ishtiyak Ahmad and <strong>Immad Ahmad Shah</strong>. 2021. Standardisation of propagation techniques of Tribulus terristris Linn: An important medicinal species of Western Himalayas. The Pharma Innovation Journal. TPI 2021; 10(4): 85-91</li><br/>
      <li> 	K.N. Qaisar, Vaishnu Dutt, P.A. Khan, S.N. Zaffar, J.A. Mugloo and <strong>Immad A. Shah</strong>. 2020. Phenodynamics of Twenty Multipurpose Tree Species (MPTS) of Kashmir under Shalimar conditions. Journal of Tree Sciences. Vol. 39, No. 2</li><br/>
      <li> 	<strong>Shah, I. A.</strong>, Mir, S. A., Raja, T. A., Khan, I., Nazir. N. 2018. An Approach to Multivariate Average Linkage Clustering Utilizing R Software. Mathematical Sciences International Research Journal. vol. 7, no. 5, pp. 113-117</li><br/>
      <li> 	<strong>Shah, Immad A</strong>, Khan, I., Mir, S. A., Pukhta, M.S., Wani, M.S., Lone, A.A. 2018. R Software Graphical Visualisation of Multivariate Dataset. International Journal of Advanced Research in Science and Engineering.” vol. 7, no. 4. 77-83</li><br/>
      <li> 	<strong>Shah, I. A.</strong>, Mir, S. A., Khan, I., Nazir, N. 2019. A graphical visualisation of cancer data utilizing R statistical software. International Journal of Medical and Health Research. Volume 5; Issue 3; Page No. 116-121</li><br/>
      <li> 	<strong>Shah, I. A.</strong>, Khan, I., Mir, S. A., Pukhta, M.S., Wani, M.S., Lone, A.A. 2019. Exploratory factor analysis (EFA) utilizing R software analytics. Advances in Bioresearch. Vol 10 [1]. 19-23
      <li> 	<strong>Shah, I. A.</strong>, Mir, S. A., Wani, S.A., Singh, K. N., Raja, T. A., Khan,I., Nazir, N. 2018. Evaluation of an efficient design of experiment for High Density Apple plantation. Multilogic in Science.. Volume VIII, Issue-XXVIII</li><br/>
      <li> 	<strong>Shah, I. A.</strong>, Mir, S. A., Khan, I., Nazir, N., Bhat, O., Bhat. S. 2019. Application of R software in Life Sciences. Journal of Medical Science and Clinical Research.. Volume 07 Issue 03 March.</li><br/>
      <li> 	<strong>Shah, I. A.</strong>, Khan, I., Mir, S. A., Pukhta, M.S., Lone, A.A.2018. Principal Component Analysis Utilizing R and SAS Softwares. International Journal of Current Microbiology and Applied Sciences. vol. 7, no. 2.</li><br/>
      <li> 	<strong>Shah, I. A.</strong>, Khan, I., Mir, S. A., Pukhta, M.S., Dar, Z.A., Lone, A.A. 2018. Genetic Diversity by Multivariate Analysis Using R Software. International Journal of pure and applied Biosciences. 10.18782/2320-7051</li><br/>
      <li> 	<strong>Shah, I. A.</strong>, Mir. S. A. 2019. Evaluation of the test unit for High Density Apple Experiments. International Journal of pure and applied Biosciences. 7(6), 408-411.</li><br/>
      <li> 	<strong>Shah, I. A.</strong>, Wani, S. A., Yousuf, S., Bhat. T, Noor, F. Multivariate Clustering Utilizing R Software Analytics, International Journal of Chemical Studies. vol. 6, no. 1, 18 Dec. 2017, pp. 971–974., doi:10.22271/chemi.</li><br/>
      <li> 	M. A. Bhat, T. A. Raja, S. Maqbool, N. A. Sofi, Ab. Rauf, S. H. Baba and <strong>Immad. A. Shah</strong>. Robust Estimators for Estimation of Population Variance Using Linear Combination of Downton’s Method and Deciles as Auxiliary Information. Advances in Research 15(2): 1-7, 2018; Article no.AIR.41956</li><br/>
      <li> 	M. A. Bhat, S. Maqbool, S. A. Mir, T. A. Raja, Ab. Rauf, N. A. Sofi and <strong>Immad A. Shah</strong>. Estimate of Population Variance by Introducing Gini’s Mean Difference as Auxiliary Information. Journal of Scientific Research & Reports. 19(3): 1-4, 2018; Article no.JSRR.41962</li><br/>
      <li> 	M. A. Bhat, S. Maqbool, S. A. Mir, N. A. Sofi, Ab. Rauf, <strong>Immad. A. Shah</strong> and Mir Subzar. An Improvement of Robust Estimator Using Known Values of Probability Weighted Moment for Finite Population Variance. Asian Journal of Agricultural Extension, Economics & Sociology. 25(2): 1-5, 2018; Article no.AJAEES.41961</li><br/>
      <li> 	I. A. Bhat, S.A. Mir, Nageena Nazir, Imran Khan, J.A. Wani and <strong>Shah. I. A.</strong> Does Aspect Based Blocking Affect The Experimental Efficiency. Multilogic In Science. Vol. VIII, ISSUE XXVIII, JAN 2019</li><br/>
      <li> 	A. Rouf, H. R. Naik, Uzma Altaf, <strong>Immad A. Shah</strong>, Shabeer Ahmad Mir and Munazah Mehraj. Effect of various natural fruit juices on physicochemical characteristics and browning inhibition of apple (Cv. White dotted red). International Journal of Chemical Studies, 2018; 6(3): 3495-3501</li><br/>
</li>

      
</ol>
	
	
	
</div>
</div>

</div>   
    </div>
    </div>
    );
}
export default DrImmad;