import React from 'react';
import '../carousel.css';
import NavbarMenu from '../NavbarMenu.js';
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
function DrRaghvan() {
    return (
      <div> <NavbarMenu /><div class="manual">
        <div class="row container">
        <p class="mypathhead">
        <Link to="/"><span class="mypath">Home</span></Link>&gt;<Link to="/ScientistROHCS"><span class="mypath">Scientist's Profile</span></Link>&gt;<span class="mypath">ICMR-ROHCS Bengaluru</span>
        </p>
          
        </div>
        <h1 class="mypathhead">ICMR-ROHCS Scientist's Detail</h1>
        <div class="scientistdetail">
      
            <div class="panel my-panel" >
      <div class="panel-heading my-panel-head" align="left"><strong>Basic Details</strong></div>
      <div class="panel-body" align="left">
      <table width="100%">
            <tr>
                <td width="28%" rowspan="5"><img src="../assests/images/rohcsScientist/sraghavan.png" alt="" width="180px" height="170px" CLASS="dg-img"/></td>
                <td width="50%" ><p class="myfontSci trSci"> Dr S Raghavan</p> </td>
                <td width="22%" ></td>
                
            </tr>
            <tr>
              <td><p ><a href=""><i class="fa fa-envelope" aria-hidden="true"></i>   Email: raghuharihar@gov.in</a></p></td>
            </tr> 
            <tr>
              <td><p ><a href=""> <i class="fa fa-phone-square"></i>  Contact: 080-22172501</a></p></td>
            </tr> 
           
            </table>   
      </div>
    </div>
       
        <div class="panel my-panel" >
      <div class="panel-heading my-panel-head" align="left"><strong>Academic Qualification</strong></div>
      <div class="panel-body" align="left">
      M. Sc., Ph.D.(Chemistry)
</div>
    </div>
    <div class="panel my-panel" >
      <div class="panel-heading my-panel-head" align="left"><strong>Research Interest </strong></div>
      <div class="panel-body" align="left">
      Industrial Hygiene, Air Pollution, Occupational Health

</div>
</div>
<div class="panel my-panel">
      <div class="panel-heading my-panel-head" align="left"><strong>Membership/Affliation </strong></div>
      <div class="panel-body" align="left">
      <ul><li>
</li>

</ul>
</div>
</div>
<div class="panel my-panel">
      <div class="panel-heading my-panel-head" align="left"><strong>Fellowship/Awards </strong></div>
      <div class="panel-body" align="left">
      <ul><li>	ICMR International Fellowhship for Young Biomedical Scientists 2011-12</li>

<li>Best Paper Award from Indian Building Council New Delhi in 2009
</li><br/>


</ul>
</div>
</div>
<div class="panel my-panel">
      <div class="panel-heading my-panel-head" align="left"><strong>Publications </strong></div>
      <div class="panel-body" align="left">
      <ol>
	<li>Rajnarayan R. Tiwari, <strong>S. Raghavan</strong>.  Quantification of Interleukin Level in the Workers Handling Highly Toxic Gas Phosgene.  JOURNAL OF ENVIRONMENTAL AND OCCUPATIONAL HEALTH 2021 VOL 11, NO. 8, PAGE 89 – 92

</li><br/><li><strong>Raghavan Sampathraju</strong>, Pankaj Doctor, Rajnarayan R Tiwari, Mohammad Asif Mansuri, Anuradha Derasri, Idrish Shaikh.  Assessment of exposure to dust, gaseous pollutants and endotoxins in sewage treatment plants of Ahmedabad city, India. Accepted, in Press, WORK: A Journal of Prevention, Assessment & Rehabilitation, PubMed Indexed).

</li><br/><li><strong>Raghavan S</strong>, Tiwari RR, Doctor PB, RekhaKashyap, Mahamad AM, Mansuri PR. Exposure to toluene di-isocyanate and respiratory effects in flexible polyurethane foam industries in Western India.  Indian J Occup Environ Med 2021; Volume 25, No.2: 106-10.  

</li><br/><li>Tiwari RR and <strong>Raghavan S</strong>. Spirometric measurement among polyurethane foam mattress-making workers of India. Indian J Community Med 2021; Volume 46, Issue 2:313-6. 
 
</li><br/><li>Kashyap R, Viramgami AP, Sadhu HG, <strong>Raghavan S</strong>, Mishra SD, Thasale RR. Effect of kerosene and biomass fuel as cooking medium on pulmonary function of adult nontobacco addict homemaker women residing in slums of Ahmedabad city, Gujarat. Indian J Public Health 2020; 64:362-7.. Citations: 0; Impact Factor: Not Available
 
</li><br/><li>Ravichandran B*, Dhananjayan V, Mala A, Somnath Sen, <strong>Raghavan S</strong>.  Assessment of Occupational And Respiratory Health Implications Among Sheep Breeding And Wool Shearing Workers In Karnataka, India. Central European Journal of Occupational and Environmental Medicine 2020; 26 (1-2); 66.
 
</li><br/><li>Dr. <strong>Raghavan Sampathraju</strong> and Mahammad Asif Mansuri.  Assessment of air pollutants in the occupational environment of plasma biomedical waste disposal system in Ahmedabad, India. Journal of Environment & Safety, 2019. Vol. 10, No.2: 89-93, Citations-2 Nos.

</li><br/><li>Mala, B.Ravichandran, <strong>Dr.S.Raghavan</strong>, HR Rajmohan ; Multinominal logistic regression model to assess the levels of trans,trans-muconic acid and inferential- risk age group among benzene-exposed group; Indian Journal of Occupational and Environmental Medicine, Vol.14, No.2, May-Aug 2010.39-41

</li><br/><li><strong>Dr.S.Raghavan</strong>,: Sources of Indoor Air Pollution from Organic Compounds and Control Measures; Journal of Indian Building Congress, Vol.16, No.1, 2009.

</li><br/><li>B Ravichandran, V. Krishnamurthy, K. Ravibabu, <strong>S. Raghavan</strong>, B.K. Rajan and H.R.Rajmohan: Assessing dust exposure in an integrated iron and steel manufacturing plant in South India; WORK: A Journal of Prevention, Assessment & Rehabilitation, Vol 30, No.2/2008,195-200.

</li><br/><li>B.Ravichandran, <strong>S. Raghavan</strong>, K.Ravibabu, V.Krishnamurthy and H.R.Rajmohan: Monitoring the work environment of job processes in a telephone handset manufacturing industry Central European Journal of Occupational and Environmental Medicine: 2007; 13 (2): 193-199. 

</li><br/><li>B.Ravichandran, V.Krishnamurthy, <strong>S. Raghavan</strong>, B.K.Rajan and H.R.Rajmohan: Assessment of Thermal Environment and Noise Intensity Levels in an Integrated Iron and Steel Plant of South India. Central European J Environ Occup Med, CEJOEM 2006, Vol.12. No.3 (2006).: 159–166.

</li><br/><li><strong>S. Raghavan</strong>,& K.Basavaiah: Biological monitoring among benzene-exposed workers in Bangalore city, India.: Biomarkers, September-October 2005; 10(5):336-341.

</li><br/><li>B.Ravichandran, K.Ravibabu, <strong>S. Raghavan</strong>, V.Krishnamurthy, B.K.Rajan and H.R.Rajmohan; Environmental and Biological Monitoring in a Lead Acid Battery Manufacturing Unit in India. Journal of Occupational Health 2005; 47:350-353.

</li><br/><li>B.Ravichandran, V.Krishnamurhty, <strong>Raghavan.S</strong> and H.R.Rajmohan: Dust Problems-A Health Concern In State Archives Department, Pollution Research; 24(1):235-238(2005).

</li><br/><li>V.Krishnamurthy, H.R.Rajmohan, B.K.Rajan, <strong>S. Raghavan</strong> and Y.Kakde: Noise Level Monitoring in Diesel Engine Power Plant in Bangalore. Indian Journal of Environmental Protection, Vol.19. No.7, July 1999:508-511.
</li>
	
</ol>
	
	
	
</div>
</div>
</div>
      
    </div>
    </div>
    );
}
export default DrRaghvan;