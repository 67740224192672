import React from 'react';
import '../carousel.css';
import NavbarMenu from '../NavbarMenu.js';
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
function DrMani() {
    return (
      <div> <NavbarMenu /><div class="manual">
        <div class="row container">
        <p class="mypathhead">
        <Link to="/"><span class="mypath">Home</span></Link>&gt;<Link to="/ScientistROHCS"><span class="mypath">Scientist's Profile</span></Link>&gt;<span class="mypath">ICMR-ROHCS Bengaluru</span>
        </p>
          
        </div>
        <h1 class="mypathhead">ICMR-ROHCS Scientist's Detail</h1>
        <div class="scientistdetail">
      
            <div class="panel my-panel" >
      <div class="panel-heading my-panel-head" align="left"><strong>Basic Details</strong></div>
      <div class="panel-body" align="left">
      <table width="100%">
            <tr>
                <td width="28%" rowspan="5"><img src="../assests/images/TechnicalStaffImg/man.png" alt="Manikandan" width="180px" height="170px" CLASS="dg-img"/></td>
                <td width="50%" ><p class="myfontSci trSci"> Dr Manikandan S</p> </td>
                <td width="22%" ></td>
                
            </tr>
            <tr>
              <td><p ><a href=""><i class="fa fa-envelope" aria-hidden="true"></i>   Email: manikandan.s@icmr.gov.in</a></p></td>
              <td></td>
            </tr> 
            <tr>
              <td><p ><a href=""> <i class="fa fa-phone-square"></i>  Contact: To Be Update</a></p></td>
              <td></td>
            </tr> 
           
            </table>   
      </div>
    </div>
       
        <div class="panel my-panel" >
      <div class="panel-heading my-panel-head" align="left"><strong>Academic Qualification</strong></div>
      <div class="panel-body" align="left">
      To Be Update
</div>
    </div>
    <div class="panel my-panel" >
      <div class="panel-heading my-panel-head" align="left"><strong>Research Interest </strong></div>
      <div class="panel-body" align="left">
      To Be Update

</div>
</div>
<div class="panel my-panel">
      <div class="panel-heading my-panel-head" align="left"><strong>Membership/Affliation </strong></div>
      <div class="panel-body" align="left">
      To Be Update
</div>
</div>
<div class="panel my-panel">
      <div class="panel-heading my-panel-head" align="left"><strong>Fellowship/Awards </strong></div>
      <div class="panel-body" align="left">
      To Be Update
</div>
</div>
<div class="panel my-panel">
      <div class="panel-heading my-panel-head" align="left"><strong>Publications </strong></div>
      <div class="panel-body" align="left">
	
      To Be Update
	
	
	
</div>
</div>

</div>   
    </div>
    </div>
    );
}
export default DrMani;