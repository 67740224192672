import React from 'react';
import '../carousel.css';
import NavbarMenu from '../NavbarMenu.js';
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
function DrDhanan() {
    return (
      <div> <NavbarMenu /><div class="manual">
        <div class="row container">
        <p class="mypathhead">
        <Link to="/"><span class="mypath">Home</span></Link>&gt;<Link to="/ScientistROHCS"><span class="mypath">Scientist's Profile</span></Link>&gt;<span class="mypath">ICMR-ROHCS Bengaluru</span>
        </p>
          
        </div>
        <h1 class="mypathhead">ICMR-ROHCS Scientist's Detail</h1>
        <div class="scientistdetail">
      
            <div class="panel my-panel" >
      <div class="panel-heading my-panel-head" align="left"><strong>Basic Details</strong></div>
      <div class="panel-body" align="left">
      <table width="100%">
            <tr>
                <td width="28%" rowspan="5"><img src="../assests/images/rohcsScientist/Dr V Dhananjayan.jpg" width="180px" height="170px" CLASS="dg-img"/></td>
                <td width="50%" ><p class="myfontSci trSci">Dr V. Dhananjayan</p> </td>
                <td width="22%" ></td>
                
            </tr>
            <tr>
              <td><p ><a href=""><i class="fa fa-envelope" aria-hidden="true"></i>   Email: dhananjayan.v@gov.in</a></p></td>
              <td></td>
            </tr> 
            <tr>
              <td><p ><a href=""> <i class="fa fa-phone-square"></i>  Contact: 080-22172500</a></p></td>
              <td></td>
            </tr>   
           
            </table>   
      </div>
    </div>
       
        <div class="panel my-panel" >
      <div class="panel-heading my-panel-head" align="left"><strong>Academic Qualification</strong></div>
      <div class="panel-body" align="left">
      <ul><li>	Ph. D (Environmental Science)</li>
      <li>	M. Sc. (Environmental Science)</li>
      <li>M. Sc. (Chemistry)</li>
<li>Diploma in Software Commercial Application</li>
<li>Diploma in Industrial Pollution Control</li>
</ul>
</div>
    </div>
    <div class="panel my-panel" >
      <div class="panel-heading my-panel-head" align="left"><strong>Research Interest </strong></div>
      <div class="panel-body" align="left">
        <ul><li>Occupational exposure assessment</li>
        <li>Environmental Impact Assessment</li><li>Agrochemicals and Biomarkers</li><li>Risk assessment of toxicants (pesticides, PCBs, PAHs and heavy metals)</li></ul>

</div>
</div>
<div class="panel my-panel">
      <div class="panel-heading my-panel-head" align="left"><strong>Membership/Affliation </strong></div>
      <div class="panel-body" align="left">
      <ul><li>	Environmental Mutation Society of India (EMSI) (No.LM202008).</li>
      <li>	Bombay Natural History Society, Mumbai-400023 (No.O67872).</li>
</ul>
</div>
</div>
<div class="panel my-panel">
      <div class="panel-heading my-panel-head" align="left"><strong>Fellowship/Awards </strong></div>
      <div class="panel-body" align="left">
      <ul><li>	Young Scientist, DST-SERB: Under Fast Tract Scheme for Young Scientist.</li>
      <li>	TICA Fellowship for the International training on Detection of Environmental Pollution, Testing and  Screening of Toxicity” at Chulabhorn Research Institute, Bangkok, Thailand
</li></ul>
</div>
</div>
<div class="panel my-panel">
      <div class="panel-heading my-panel-head" align="left"><strong>Publications </strong></div>
      <div class="panel-body" align="left">
	
      <ol><li>	<strong>Dhananjayan V</strong>, Panjakumar K, Ravichandran B, Debi Sharma et al., (2021). Evaluation of work place pesticide concentration and health complaints among women workers in tea plantation, Southern India. Journal of Exposure Science & Environmental Epidemiology, 31: (560-570). (IF 5.563) 
      </li><br/><li>	Panjakumar K, Embiranahalli M, Beerappa R, <strong>Dhananjayan V</strong>, Karthik G(2021). Pulmonary function impairment and lung infections among tannery workers in Southern India. Indian Journal of Science and Technology 14 (4)
      </li><br/><li>	<strong>Dhananjayan V</strong>, M Subramanian, J Rajamani, J PalaniyappanLevels and distribution pattern of organochlorine pesticide residues in eggs of 22 terrestrial birds from Tamil Nadu, India. Environmental Science and Pollution Research 27 (31), 39253-39264.(IF 4.306) 
      </li><br/><li>	Gaikwad AS, R Mahmood, R Beerappa, P Karunamoorthy, VDhanajayan (2020). Mitochondrial DNA copy number and Cytogenetic damage among Fuel Filling Station Attendants. Environmental and Molecular Mutagenesis, (IF 3.216) 
      </li><br/><li>	Jayakumar S, Muralidharan S, <strong>Dhananjayan V</strong> (2020). Organochlorine pesticide residues among colonial nesting birds in Tamil Nadu, India: A maiden assessment from their breeding grounds. Arch Environ ContamToxicol 1-13 (IF:3.399) 
      </li><br/><li>	Ravichandran B, V Dhananjayan, A Mala, Sen Somnath, S Raghava (2020). Assessment of occupational and respiratory health implications among sheep breeding and wool shearing workers in Karnataka, India. Central European Journal of Occupational and Environmental Medicine, 26:66.
      </li><br/><li>	<strong>Dhananjayan V</strong>, Jayakumar S, Jayanthi P, Ravichandran B (2020). Agrochemicals Impact on Ecosystem and Biomonitoring: In: Kumar et al., (Eds.) Resources Use Efficiency in Agriculture. Book Chapter, pp 349-388, Springer
      </li><br/><li>	<strong>Dhananjayan V</strong>, Jayakumar S, Ravichandran B (2020). Conventional methods of pesticide application in agricultural field and fate of the pesticides on the environment and human health. Eds (Thomas S et al.), In: Controlled Release of Pesticides for Sustainable Agriculture. ISBN 978-3-030-23395-2, Cham, Springer
      </li><br/><li>	<strong>Dhananjayan V</strong>, Ravichandran B, Panjakumar K, Kalaiselvi K, Kausic Rajasekar, Mala A, Avinash G, Shridhar K, Manju A, Rajesh Wilson (2019). Assessment of genotoxicity and cholinesterase activity among women workers occupationally exposed to pesticides in tea garden. Mutation Research 841:1-7 (IF: 2.873) 
      </li><br/><li>	Jayakumar S, Muralidharan S, <strong>Dhananjayan V</strong> (2019). Levels of organochlorine pesticides in fresh water fishes of three bird sanctuaries in Tamil Nadu, India. Environ Sci Pollut Res 26:1983-1993 (IF 4.306) 
      </li><br/><li>	<strong>Dhananjayan V</strong>, Ravichandran B, Sen S, Panjakumar K (2019). Source and health effects of nanoparticles with special reference to occupational exposure. In: Nanoarchitectonics in Biomedicine, Recent progress of nanoarchitectonics in biomedical science. Ed: Alexandru MG, William Andrew Publishing, pp 643-676, ISBN 9780128162002, Elsevier
      </li><br/><li>	Manju A, Kalaiselvi K, <strong>Dhananjayan V</strong>, Palanivel M, Banupriya GS, Vidhya MH, Panjakumar K, Ravichandran B (2018). Spatio-seasonal variation in ambient air pollutants and influence of meteorological factors in Coimbatore, Southern India. Air Qual Atmos Health 11:1179-1189 (IF 3.656) 
      </li><br/><li>	Sen S, Narayana J, Ravichandran B, <strong>Dhananjayan V</strong> (2018). Polyaromatic hydrocarbons depositions and their carcinogenic risk assessment in the foundry workers. Aerosol Science and Engineering 2 (4):173–181
      </li><br/><li>	<strong>Dhananjayan V</strong>, Ravichandran B (2018). Occupational health risk of farmers exposed to pesticides in agricultural activities. Current Opin Environ Sci & Health 4: 31-37 Elsevier
      </li><br/><li>	Sen S, Narayana J, Ravichandran B, <strong>Dhananjayan V</strong>, Gaikwad AS (2016). Biological monitoring of PAHs exposure among the foundry workers, Int. J. of Research in Chemistry and Environment. Int  J Research in Chemistry and Environment 6:37-41
      </li><br/><li>	Muralidharan S, Ganesan K, Nambirajan K, Kirubanandhini V, <strong>Dhananjayan V</strong> (2015). Wetland birds-indicators of pesticide contamination current and future prospects for research in India. In: ENVIS Bulletin Water birds of India, Chapter 22, pp 314-323. WII, Dehradun, India
      </li><br/><li>	<strong>Dhananjayan V</strong>, Ravichandran B (2014). Organochlorine pesticide residues in foodstuffs, fish, wildlife and human tissues from India: historical trend and contamination status. In: Abdul Malik and Elisabeth Grohmann (eds), Environmental Deterioration and Human Health: Natural and anthropogenic determinants. Chap 10, pp229-262, ISBN: 978-94-007-7889-4, Dordrecht, Springer
      </li><br/><li>	Ravichandran B, <strong>Dhananjayan V</strong>, Somnath Sen, Mala A, Rajmohan HR (2013). Assessment of 8-oxo-7,8-dihydro-2’-deoxyguanosine as a marker of oxidative DNA damage in gasoline filling station attendants. Int J Occup Med Environ Health 26:780-789 (IF:2.215) 
      </li><br/><li>	<strong>Dhananjayan V</strong>, Muralidharan S (2013). Accumulation pattern and distribution of polycyclic aromatic hydrocarbons (PAHs) in liver tissues of seven species of birds from Ahmedabad, India, during 2005–2007. Environ Sci Pollut Res 20:3414–3422 (IF 4.306) 
      </li><br/><li>	<strong>Dhananjayan V</strong> (2013). Accumulation pattern of persistent organochlorine pesticides in liver tissues of various species of birds from India. Environ Sci Pollut Res 20:3149–3156 (IF 4.306) 
      </li><br/><li>	<strong>Dhananjayan V</strong>, Muralidharan S (2013). Levels of polycyclic aromatic hydrocarbons, polychlorinated biphenyls, and organochlorine pesticides in various tissues of white-backed vulture in India. Biomed Res Int 2013; 2013:190353 (IF 3.411) 
      </li><br/><li>	<strong>Dhananjayan V</strong>, Muralidharan S, Vinny R. Peter (2012). Occurrence and distribution of polycyclic aromatic hydrocarbons in water and sediment collected along the harbour line, Mumbai, India. Int J Oceanography 2012:1-7
      </li><br/><li>	<strong>Dhananjayan V</strong>, Ravichandran B, Anitha N, Rajmohan HR (2012). Assessment of acetylcholinesterase and butyrylcholinesterase activity in blood plasma of agriculture workers. Indian J Occup Environ Med 16(3):127-130 
      </li><br/><li>	<strong>Dhananjayan V</strong> (2012). Organochlorine pesticides and polychlorinated biphenyls in various tissues of Waterbirds in Nalabana Bird Sanctuary, Chilika Lake, Orrisa, India. Bull Environ ContamToxicol, 89(1):197-201 (IF: 2.129) 
      </li><br/><li>	<strong>Dhananjayan V</strong>, Muralidharan S (2012). Polycyclic aromatic hydrocarbons in various species of fishes from Mumbai Harbour, India and their dietary intake concentration to human. Int J Oceanography 2012:1-6
      </li><br/><li>	<strong>Dhananjayan V</strong>, Ravichandran B, Rajmohan HR (2012). Organochlorine pesticide residues in blood samples of agriculture and sheep wool workers in Bangalore (rural), India. Bull Environ ContamToxicol, 88(4):497-500 (IF: 2.129) 
      </li><br/><li>	Muralidharan S, <strong>Dhananjayan V</strong>, Jayakumar S (2012). Impact of pesticides on birds in India with special reference to threatened species. In: Threatened bird species in India. (ed.) Rahmani, BNHS, Bombay, India. Oxford University Press, Mumbai. pp 71-93, ISBN: 978-01980-85973
      </li><br/><li>	<strong>Dhananjayan V</strong>, Muralidharan S, Ranapratap S (2011). Organochlorine pesticide residues in eggs and tissues of House Sparrow, Passer domesticus, from Tamil Nadu, India. Bull Environ ContamToxicol, 87(6):684–688 (IF:2.129) 
      </li><br/><li>	<strong>Dhananjayan V</strong>, Muralidharan S, Jayanthi P (2011). Distribution of persistent organochlorine chemical residues in blood plasma of three species of vultures from India. Environ Monitor Assess 173:803-811 (IF:2.871) 
      </li><br/><li>	Smith PN, Afzal M, Al-Hasan R, Bouwman K, Castillo LE, Depledge, MH, Muralidharan S, <strong>Dhananjayan V</strong>, et al., (2011). Global perspectives on wildlife toxicology: emerging issues. In: Kendall RJ, Lacher TE, Cobb GP, Cox SB (eds.), Wildlife Toxicology: Emerging Contaminant and Biodiversity Issues. Pp 197-255. Taylor & Francis, CRC Press, FL. ISBN: 9781439817940.
      </li><br/><li>	<strong>Dhananjayan V</strong>, Muralidharan S (2010). Organochlorine pesticide residues in inland wetland fishes of Karnataka, India and their implications on human dietary intake. Bull Environ ContamToxicol 85(6):619-623 (IF: 2.129) 
      </li><br/><li>	Muralidharan S, <strong>Dhananjayan V</strong> (2010). Diclofenac residues in blood plasma and tissues of vultures collected from Ahmedabad, India. Bull Environ ContamToxicol 85:377-380 (IF: 2.129)  
      </li><br/><li>	<strong>Dhananjayan V</strong>, Muralidharan S (2010). Levels of organochlorine pesticide residues in blood plasma of various species of birds from India. Bull Environ ContamToxicol 85:129-136 (IF: 2.129) 
      </li><br/><li>	Muralidharan S, <strong>Dhananjayan V</strong>, Jayanthi P (2009). Organochlorine pesticides in commercial marine fishes of Coimbatore, India and their suitability for human consumption. Environmental Research 109:15-21 (IF:6.489) 
      </li><br/><li>	Muralidharan S, <strong>Dhananjayan V</strong>, Risebrough R, Prakash V, Jayakumar R, Bloom PH (2008). Persistent organochlorine pesticides residues in tissues and eggs of white-backed vulture, Gyps bengalensis from different locations in India. Bull Environ ContamToxicol 81(6):561-565 (IF: 2.219)
</li></ol>
	
	
	
</div>
</div>
</div>
      
    </div>
    </div>
    );
}
export default DrDhanan;