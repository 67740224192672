import React from 'react';
import '../carousel.css';
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import NavbarMenu from '../NavbarMenu.js';
function DrRajendra() {
    return (
      <div> <NavbarMenu /><div class="manual">
        <div class="row container">
        <p class="mypathhead">
        <Link to="/"><span class="mypath">Home</span></Link>&gt;<Link to="/ScientistNIOH"><span class="mypath">Scientist's Profile</span></Link>&gt;<span class="mypath">NIOH Ahmedabad</span>
        </p>
          
        </div>
        <h1 class="mypathhead">Scientist's Detail</h1>
        <div class="scientistdetail">
      
            <div class="panel my-panel" >
      <div class="panel-heading my-panel-head" align="left"><strong>Basic Details</strong></div>
      <div class="panel-body" align="left">
      <table width="100%">
        <thead>
          <th width="28%"></th>
          <th width="50%"></th>
          <th width="22%"></th>
        </thead>
        <tbody>
          <tr>
              <td  rowspan="5"><img src="../assests/images/niohScientist/rrpalkhade.png" width="170px" height="170px" CLASS="dg-img"/></td>
              <td  ><p class="myfontSci trSci"> Dr Rajendra Palkhade</p> </td>
              
            </tr>
            
            <tr>
              
              <td class="myfontSci"><p class="myfontSci">Biological sciences Division </p></td>
              <td></td>
             </tr>
            <tr>
              <td><p ><a href=""><i class="fa fa-envelope" aria-hidden="true"></i>   Email: rajendra.palkhade@gov.in</a></p></td>
              <td></td>
            </tr> 
            <tr>
              <td><p ><a href=""> <i class="fa fa-phone-square"></i>  Contact: 079-22688831</a></p></td>
              <td></td>
            </tr>   
        </tbody>
           
            
            </table>
      </div>
    </div>
       
        <div class="panel my-panel" >
      <div class="panel-heading my-panel-head" align="left"><strong>Academic Qualification</strong></div>
      <div class="panel-body" align="left"><ul><li>B.V.Sc.& A.H., M. V. Sc. (Veterinary Pharmacology)</li>

<li>International Course on Laboratory Animal Science (FELASA Cat 'C')</li>

<li>Certificate Course in Laboratory Animals Science (FELASA Function C & D)</li></ul>
</div>
    </div>
    <div class="panel my-panel" >
      <div class="panel-heading my-panel-head" align="left"><strong>Research Interest </strong></div>
      <div class="panel-body" align="left"> Occupational Health, Exp. Toxicology, Zoonoses</div>
    </div>
    <div class="panel my-panel" >
      <div class="panel-heading my-panel-head" align="left"><strong>Membership/Affliation </strong></div>
      <div class="panel-body" align="left">
      <ul><li>Life member of Society of Toxicology India (STOX)</li>
<li>Life member of Indian Society of Veterinary Pharmacology and Toxicology (ISVPT) </li>
<li>Life member of Laboratory Animal Association of India (LASAI)</li>
<li>Life member of Indian Society for the Study of Reproduction and Fertility (ISSRF)</li>
</ul>
</div>
</div>
<div class="panel my-panel">
      <div class="panel-heading my-panel-head" align="left"><strong>Fellowship/Awards </strong></div>
      <div class="panel-body" align="left">NIL
</div>
</div>
<div class="panel my-panel">
      <div class="panel-heading my-panel-head" align="left"><strong>Publications </strong></div>
      <div class="panel-body" align="left">
	
      <ol>

      <li>	<strong>Palkhade R.*</strong>, S Mishra, Barbuddhe S. (2022) Occupation-related Biological Health hazards and infection control practices among Indian Veterinarians Veterinary Medicine International (Manuscript accepted).

</li><br/><li>	SD Mishra, <strong>Palkhade R.*</strong> (2020) Risk factors and prevalence of work-related injuries and accidents among veterinarians in India Veterinary world 13 (11), 2555 (IF:0.5 & Citation-4).

</li><br/><li>	Pagdhune A., Kunal K., Patel KA, <strong>Palkhade R.</strong>, Muhamed J. (2020) Poisoning Cases Reported to Poison Information Centre, Ahmedabad, India: A Three Year Observational Study Cent. Asian Journal of global Health 9 (1) (IF:0.9) (Citation-1).

</li><br/><li>	YogendraMevada, <strong>Palkhade R.*</strong>, Chattopadhyay UK, Amit...(2020) Serological Survey of Leptospirosis among Poultry Farm and Slaughterhouse Workers and its Co-relation with the Risk Factors Int.J.Curr.Microbiol.App.Sci 9 (9), 3556-3571.

</li><br/><li>	Alamuri A., KV Kumar, Varghese B, <strong>Palkhade R.</strong>, SN Mahadeviah, et al., (2021) Evaluation of recombinant leptospiral surface antigen (Lsa27) lipoprotein for serodiagnosis of human leptospirosis by latex agglutination test Indian Journal of Medical Microbiology 39 (2), 212-217 (IF:0.985).

</li><br/><li>	<strong>Palkhade R.*</strong>, S Yadav, Mishra S, Muhamed J. (2018) Acute oral toxicity of pesticide combination (acephate 50% and imidacloprid 1.8% as active ingredients) in Sprague dawley rats Veterinary World 11 (9), 1291. (IF:0.5 & Citation:8).

</li><br/><li>	<strong>Palkhade R.*</strong>, Pankaj Burfal, Suresh Yadav, Awandkar SP, A. Pagdhune (2018) Epididymo-Orchitis in a Veterinary Physician: A Case of Occupational Brucellosis Journal of Foodborne and Zoonotic Diseases 6(01) 05-07 (Citation-1).

</li><br/><li>	<strong>Palkhade R*</strong>, CR Jangade (2016) Screening of analgesic and antipyretic activity of aqueous and alcoholic extracts of aloe veralinn. Veterinary Research International 4 (2), 67-73.
</li>
</ol>
	
	
	
</div>
</div>
</div>
        </div>
    </div>
    );
}
export default DrRajendra;