import React, {Component,useState} from 'react';
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route, Link, NavLink} from "react-router-dom";
import {FiAlignRight,FiXCircle,FiChevronDown } from "react-icons/fi";
import Main from './Component/main.js';
import About from'./Component/About.js'
import Orgorgano from './Component/orgorgano';
import TechNioh from './Component/TechNioh.js';
import ScientistNIOH from './Component/ScientistNIOH.js';
import ScientistROHCS from './Component/ScientistROHCS.js';
import TenderNioh from './Component/tenderNioh.js';
import AdminNioh from './Component/AdminNioh.js';
import Commitee from './Component/commitee.js';
import Recruitment from './Component/recruitment.js';
import Othertech from './Component/othertech.js';
import Dic from './Component/ScientistNIOH/dic.js';
import './index.css';
import './Component/App.css';
import Otheradmin from './Component/otheradmin.js';
import Circular from './Component/circular.js';
import ContactUs from './Component/contactUs.js';
import DrAnkit from './Component/ScientistNIOH/DrAnkit.js';
import DrLKSharma from './Component/ScientistNIOH/DrLKSharma.js';
import DrMihir from './Component/ScientistNIOH/DrMihir.js';
import DrSnehal from './Component/ScientistNIOH/DrSnehal.js';
import DrNibedita from './Component/ScientistNIOH/DrNibedita.js';
import DrSarang from './Component/ScientistNIOH/DrSarang.js';
import DrSiva from './Component/ScientistNIOH/DrSiva';
import DrAsha from './Component/ScientistNIOH/DrAsha.js';
import DrRakesh from './Component/ScientistNIOH/DrRakesh.js';
import DrMugdha from './Component/ScientistNIOH/DrMugdha.js';
import DrSukhdev from './Component/ScientistNIOH/DrSukhdev.js';
import DrNitish from './Component/ScientistNIOH/DrNitish.js';
import DrPankaj from './Component/ScientistNIOH/DrPankaj.js';
import DrRajendra from './Component/ScientistNIOH/DrRajendra.js';
import DrGyanendra from './Component/ScientistNIOH/DrGyanendra.js';
import DrGitika from './Component/ScientistNIOH/DrGitika.js';
import DrKuldip from './Component/ScientistNIOH/DrKuldip.js';
import DrAnkitVir from './Component/ScientistNIOH/DrAnkitVir.js';
import DirDesk from './Component/DirDesk.js';
import DrDPS from './Component/ScientistNIOH/DrDPS.js';
import DrShivKumar from './Component/ScientistNIOH/DrShivKumar.js';
import DrNikhil from './Component/ScientistNIOH/DrNikhil.js';
import DrSaundarya from './Component/ScientistNIOH/DrSaundarya.js';
import DrRakshit from './Component/ScientistNIOH/DrRakshit.js';
import DrSwati from './Component/ScientistNIOH/DrSwati.js';
import DrAnu from './Component/ScientistNIOH/DrAnu.js';
import DrAkshya from './Component/ScientistROHCS/DrAkshya.js';
import DrDebasis from './Component/ScientistROHCS/DrDebasis.js';
import DrDhanan from './Component/ScientistROHCS/DrDhanan.js';
import DrMani from './Component/ScientistROHCS/DrMani.js';
import DrRavi from './Component/ScientistROHCS/DrRavi.js';
import DrShubh from './Component/ScientistROHCS/DrShubh.js';
import DrRaviBabu from './Component/ScientistROHCS/DrRaviBabu.js';
import DrRaghvan from './Component/ScientistROHCS/DrRaghvan.js';
import DrGeethu from './Component/ScientistROHCS/DrGeethu.js';
import Division from './Component/division.js';
import NavbarMenu from './Component/NavbarMenu.js';
import ShortMenu from './Component/shortMenu.js';
import Pio from './Component/pio';
import EdPhd from './Component/edPhd';
import Rti from './Component/rti';
import EdAFIH from './Component/edAFIH';
import EdDissert from './Component/edDissert';
import PhotoGallery from './Component/photoGallery'
import IndexHindi from './Component/indexHindi';
import Workshop from './Component/workshop';
import EdDetailNoti from './Component/edDetailNoti.js';
import AdminROHCS from './Component/AdminROHCS.js';
import TechROHCS from './Component/TechROHCS.js';
import GuestHouse from './Component/guestHouse.js';
import DrImmad from './Component/ScientistNIOH/DrImmad.js';
class App extends Component {
    constructor(props) {
        super(props);
        this.myFunction = this.myFunction.bind(this);
      }
      myFunction() {
        console.log('Click happened');
      }
    render()
    {
        return (<body class="test">
          <BrowserRouter>
         <ShortMenu/>
          <div class="container-fluid"style={{backgroundColor:"white",marginTop:"-20px"}}>
          

<div class="row">
  <div class="col-md-2">
    <img src="./assests/images/gnrImg/logo.jpg" alt="ICMR - NIOH, Ahmedabad"  class="img-responsive logo" style={{marginTop:"10px"}}/>
  </div>
  <div class="col-md-9">
    <center>
    <p CLASS="heading1">आई.सी.एम.आर.- राष्ट्रीय व्यावसायिक स्वास्थ्य संस्थान, ICMR- National Institute Of Occupational Health</p>
    <p CLASS="heading1" style={{color:"#002147",marginTop:"-15px"}}>अहमदाबाद - 380016 भारत. Ahmedabad - 380016 India. </p>
    <p CLASS="heading1"style={{color:"#002147",marginTop:"-15px"}}>व्यावसायिक स्वास्थ्य के लिए डब्ल्यूएचओ का सहयोग केंद्र. WHO Collaborating Center for Occupational Health</p>
    </center>
       
  </div>
  <div class="col-md-1">
  <img src="./assests/images/gnrImg/g20.png" alt="ICMR - NIOH, Ahmedabad"  class="img-responsive logo" style={{marginTop:"20px"}} />
  
  </div>
</div>

</div>
        <Routes>
            
          <Route index element={<Main />} />
          <Route exact path='/guestHouse' element={<GuestHouse/>}></Route>
          <Route exact path='/TechROHCS' element={<TechROHCS/>}></Route>
          <Route exact path='/AdminROHCS' element={<AdminROHCS/>}></Route>
          <Route exact path='/DrShivKumar' element={<DrShivKumar/>}></Route>
          <Route exact path='/workshop' element={<Workshop/>}></Route>
          <Route exact path='/edPhd' element={< EdPhd/>}></Route>
          <Route exact path='/edDetailNoti' element={< EdDetailNoti/>}></Route>
          <Route exact path='/edDissert' element={< EdDissert/>}></Route>
          <Route exact path='/edAFIH' element={< EdAFIH/>}></Route>
          <Route exact path='/photoGallery' element={< PhotoGallery/>}></Route>
            <Route exact path='/indexHindi' element={< IndexHindi/>}></Route>
            <Route exact path='/pio' element={< Pio/>}></Route>
              <Route exact path='/rti' element={< Rti/>}></Route>
              <Route exact path='/about' element={< About />}></Route>
              <Route exact path='/orgorgano' element={< Orgorgano />}></Route>
              <Route exact path='/TechNioh' element={< TechNioh />}></Route> 
              <Route exact path='/contactUs' element={< ContactUs />}></Route> 
              <Route exact path='/ScientistNIOH' element={< ScientistNIOH />}></Route> 
              <Route exact path='/ScientistROHCS' element={< ScientistROHCS />}></Route> 
              <Route exact path='/commitee' element={<Commitee />}></Route> 
              <Route exact path='/tenderNioh' element={<TenderNioh />}></Route> 
              <Route exact path='/recruitment' element={<Recruitment />}></Route> 
              <Route exact path='/othertech' element={<Othertech />}></Route> 
              <Route exact path='/AdminNioh' element={<AdminNioh />}></Route>
              <Route exact path='/otheradmin' element={<Otheradmin />}></Route>
              <Route exact path='/circular' element={<Circular />}></Route>
              <Route exact path='/dic' element={<Dic />}></Route>
              <Route exact path='/DrAnkit' element={<DrAnkit />}></Route>.
              <Route exact path='/DrLKSharma' element={<DrLKSharma />}></Route>
              <Route exact path='/DrMihir' element={<DrMihir />}></Route>
              <Route exact path='/DrSnehal' element={<DrSnehal />}></Route>
              <Route exact path='/DrNibedita' element={<DrNibedita />}></Route>
              <Route exact path='/DirDesk' element={<DirDesk />}></Route>
              <Route exact path='/division' element={<Division />}></Route>
              <Route exact path='/drSarang' element={<DrSarang />}></Route>
              <Route exact path='/DrSiva' element={<DrSiva/>}></Route>
              <Route exact path='/DrAsha' element={<DrAsha/>}></Route>
              <Route exact path='/DrRakesh' element={<DrRakesh/>}></Route>
              <Route exact path='/DrMugdha' element={<DrMugdha/>}></Route>
              <Route exact path='/DrSukhdev' element={<DrSukhdev/>}></Route>
              <Route exact path='/DrNitish' element={<DrNitish/>}></Route>
              <Route exact path='/DrPankaj' element={<DrPankaj/>}></Route>
              <Route exact path='/DrRajendra' element={<DrRajendra/>}></Route>
              <Route exact path='/DrGyanendra' element={<DrGyanendra/>}></Route>
              <Route exact path='/DrGitika' element={<DrGitika/>}></Route>
              <Route exact path='/DrKuldip' element={<DrKuldip/>}></Route>
              <Route exact path='/DrAnkitVir' element={<DrAnkitVir/>}></Route>
              <Route exact path='/DrDPS' element={<DrDPS/>}></Route>
              <Route exact path='/DrNikhil' element={<DrNikhil/>}></Route>
              <Route exact path='/DrSaundarya' element={<DrSaundarya/>}></Route>
              <Route exact path='/DrRakshit' element={<DrRakshit/>}></Route>
              <Route exact path='/DrSwati' element={<DrSwati/>}></Route>
              <Route exact path='/DrAnu' element={<DrAnu/>}></Route>
              <Route exact path='/DrAkshya' element={<DrAkshya/>}></Route>
              <Route exact path='/DrDebasis' element={<DrDebasis/>}></Route>
              <Route exact path='/DrDhanan' element={<DrDhanan/>}></Route>
              <Route exact path='/DrMani' element={<DrMani/>}></Route>
              <Route exact path='/DrShubh' element={<DrShubh/>}></Route>
              <Route exact path='/DrRavi' element={<DrRavi/>}></Route>
              <Route exact path='/DrRaviBabu' element={<DrRaviBabu/>}></Route>
              <Route exact path='/DrGeethu' element={<DrGeethu/>}></Route>
              <Route exact path='/DrRaghvan' element={<DrRaghvan/>}></Route>
              <Route exact path='/DrImmad' element={<DrImmad/>}></Route>
               </Routes>
        </BrowserRouter>
        </body>);
    }
}
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
