import React from 'react';
import '../carousel.css';
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import NavbarMenu from '../NavbarMenu.js';
function DrLKSharma() {
    return (
      <div> <NavbarMenu /><div class="manual">
        <div class="row container">
        <p class="mypathhead">
        <Link to="/"><span class="mypath">Home</span></Link>&gt;<Link to="/ScientistNIOH"><span class="mypath">Scientist's Profile</span></Link>&gt;<span class="mypath">NIOH Ahmedabad</span>
        </p>
          
        </div>
        <h1 class="mypathhead">Scientist's Detail</h1>
        <div class="scientistdetail">
      
            <div class="panel my-panel" >
      <div class="panel-heading my-panel-head" align="left"><strong>Basic Details</strong></div>
      <div class="panel-body" align="left">
      <table width="100%">
        <thead>
          <th width="28%"></th>
          <th width="50%"></th>
          <th width="22%"></th>
        </thead>
        <tbody>
          <tr>
              <td  rowspan="5"><img src="../assests/images/niohScientist/DrLKS.JPG" width="170px" height="170px" CLASS="dg-img"/></td>
              <td  ><p class="myfontSci trSci">Dr Lokesh Sharma</p> </td>
              <td  ><button className="mybuttonSci trSci">View Profile</button></td>
            </tr>
            
            <tr>
              <td class="myfontSci"><p class="myfontSci">Designation: Scientist E & Head (IT)</p></td>
             </tr>
            <tr>
              <td><p ><a href=""><i class="fa fa-envelope" aria-hidden="true"></i>  Email: sharma.lk@gov.in</a></p></td>
            </tr> 
            <tr>
              <td><p ><a href=""> <i class="fa fa-phone-square"></i>  Contact: +91-79-2268749</a></p></td>
            </tr>   
        </tbody>
           
            
            </table>
      </div>
    </div>
       
        <div class="panel my-panel" >
      <div class="panel-heading my-panel-head" align="left"><strong>Academic Qualification</strong></div>
      <div class="panel-body" align="left">Ph. D. (Computer Science), M. Tech. (Computer Science & Engineering)</div>
    </div>
    <div class="panel my-panel" >
      <div class="panel-heading my-panel-head" align="left"><strong>Research Interest </strong></div>
      <div class="panel-body" align="left">Health Informatics, Artificial Intelligence, Machine Learning and Data Mining, Computational Modelling, Bioinformatics </div>
    </div>
    <div class="panel my-panel" >
      <div class="panel-heading my-panel-head" align="left"><strong>Membership/Affliation </strong></div>
      <div class="panel-body" align="left">
      <ul><li>Indian Association of Occupational Health</li>
</ul>
        </div>
        </div>
        <div class="panel my-panel">
      <div class="panel-heading my-panel-head" align="left"><strong>Fellowship/Awards </strong></div>
      <div class="panel-body" align="left"><ul><li>DAAD Fellowship, Germany </li>
</ul>
</div>
</div>
<div class="panel my-panel">
      <div class="panel-heading my-panel-head" align="left"><strong>Publications </strong></div>
      <div class="panel-body" align="left">
	
      <ol >
	  <li>R Kant, E Gupta, <strong>LK Sharma</strong>, et al., Dealing with infodemic during COVID-19 pandemic: Role of effective health communication in facilitating outbreak response & actions – An ICMR experience, Public Health in Practice, Volume 5, 2023, 100394, https://doi.org/10.1016/j.puhip.2023.100394.</li>
	<br/>
	<li>A Grover, B Bhargava, S Srivastava, <strong>LK Sharma</strong>, et al. Developing Standard Treatment Workflows-way to universal healthcare in India. Front Public Health. 2023 Aug 16;11:1178160. doi: 10.3389/fpubh.2023.1178160. PMID: 37663866; PMCID: PMC10472454.</li>
	<br/>
    <li>G Kumar, A Bhalla, A Mukherjee, A Turuk, A Talukdar, S Mukherjee, P Bhardwaj, G Menon, D Sahu, P Misra, <strong>LK Sharma</strong>, et al., Post COVID sequelae among COVID-19 survivors: insights from the Indian National Clinical Registry for COVID-19,  BMJ Global Health 2023;8:e012245.</li>
	<br/>
	<li>SD Mishra, A Viramgami,<strong>LK Sharma</strong>, A Malhotra (2023) Findings and Experiences from the paper-based occupational disease and injury registry in India, Occupational and Environmental Medicine 80 (Suppl 1), A71-A72</li>
	<br/>
    <li>A Turuk et al. (2023), Evaluation of a Hospitalized Pediatric COVID‑19 Cohort from Indian National Clinical Registry of COVID‑19, Indian Journal of Pediatrics, https://doi.org/10.1007/s12098-022-04449-w</li>
	<br/>
    <li>PD Yadav et al. (2022) Delta variant SARS-CoV-2 infections in pediatric cases during the second wave in India, Journal of Microbiology, Immunology and Infection 55 (6), 1060-1068</li>
	<br/>
    <li>A Mukherjee, et al. Vaccination saves lives: a real-time study of patients with chronic diseases and severe COVID-19 infection, QJM: An International Journal of Medicine, Volume 116, Issue 1, January 2023, Pages 47–56, https://doi.org/10.1093/qjmed/hcac202</li>
	<br/>
    <li>V Parganiha, SP Shukla, <strong>LK Sharma</strong>a (2022), Developing Decision Making and Risk Mitigation: Using CRISP-Data Mining, Data Mining and Machine Learning Applications, Wiley pp. 281-314</li>
	<br/>
    <li>V Parganiha, SP Shukla,<strong>LK Sharma</strong>(2022), Cloud intrusion detection model based on deep belief network and grasshopper optimization, International Journal of Ambient Computing and Intelligence (IJACI), 13(1), 1-24</li>
	<br/>
    <li>Kumar G et al. (2021) Clinical profile of hospitalized COVID-19 patients in first & second wave of the pandemic: Insights from an Indian registry based observational study, IJMR.</li>
	<br/>
    <li>Majumder J, Kotadiya S, <strong>LK Sharma</strong> & Kumar S (2019), Upper extremity muscular strength in wrist-twisting tasks: Model approach towards task design, Human Factors and Ergonomics in Manufacturing & Service Industries, 1-9 https://doi.org/10.1002/hfm.20820</li>
	<br/>
    <li>AK Tiwari, G Ramakrishna, <strong>LK Sharma</strong> and SK Kashyap, "New Data Mining Method based on Probabilistic-Possibilistic-Mean (Discrete Data Mining Algorithm)," 2019 International Conference on Computing, Communication, and Intelligent Systems (ICCCIS), Greater Noida, India, 2019, pp. 100-104, doi: 10.1109/ICCCIS48478.2019.8974501.</li>
	<br/>
    <li>AK Tiwari, G Ramakrishna,<strong>LK Sharma</strong> and SK Kashyap, "Neural Network and Genetic Algorithm based Hybrid Data Mining Algorithm (Hybrid Data Mining Algorithm)," 2019 International Conference on Computing, Communication, and Intelligent Systems (ICCCIS), Greater Noida, India, 2019, pp. 95-99, doi: 10.1109/ICCCIS48478.2019.8974485</li>
	<br/>
    <li>J Majumder, S Kotadiya<strong>LK Sharma</strong> & S Kumar  (2018) Upper extremity muscular strength in push-pull tasks: Model approach towards task design. Indian J Occup Environ Med. </li>
	<br/>
    <li>M Shah  &  <strong>LK Sharma</strong> (2018), Study on 6LoWPAN Routing Protocols with SD aspects in IOT, IEEE ISMAC - 2018 </li>
	<br/>
    <li>LK Sharma, & J Majumder, (2017). Data Mining Approach to Pre-Screening of Manual Material Handlers Based on Hand Grip Strength. Journal of Ecophysiology and Occupational Health, 17(1-2), 57-62.</li>
	<br/>
    <li>AK  Tiwari, G Ramakrishna , <strong>LK Sharma</strong>, Kashyap SK (2017), “Big Data Management by Fuzzy, Neural Network and Genetic Algorithm”, International Journal of Inventive Engineering and Science, Vol. 4, No. 5, pp. 27-28. ISSN: 2319-9598.</li>
	<br/>
    <li>SK  Shukla, <strong>LK Sharma</strong>, SK Kashyap  (2017), “Artificial Neural Network Model for the Road Network Optimization”, Journal of Information Engineering and Applications, Vol. 7, No. 3, pp. 28-31. ISSN: 2224-5782.</li>
	<br/>
    <li>AK Tiwari , G Ramakrishna , <strong>LK Sharma</strong>, SK Kashyap (2017), “New Technique of Academic Data Analysis by Fuzzy Variance”, IIOAB Journal, Vol.  8, No. 2, pp. 131-135. ISSN: 0976-3104.</li>
	<br/>
    <li><strong>LK Sharma</strong> and J Majumder  (2016), “Pre-screening of Manual Material Handlers based on Hand Grip Strength: A Cluster Analysis Approach”, 14th International Conference on HWWE-2016, 451-452, ISBN: 978-93-83006-81-6.</li>
	<br/>
    <li>J Majumder  and <strong>LK Sharma</strong> (2015), “Identifying Body Size Group Clusters from Anthropometric Body Composition Indicators”, Journal of Ecophysiology and Occupational Health, Vol. 15, No. 3-4, pp. 81-88. ISSN: 0972-4397.</li>
	<br/>
    <li><strong>LK Sharma</strong> and J Majumder  (2015), “Application of Artificial Neural Network on Body Somatotype Analysis among Indian Population”, International Conference on Ergonomics (HWWE 2013), pp. 124-131, ISBN: 978-93-5174-905-9.</li>
	<br/>
    <li>S Pavani, HS Hota ,<strong>LK Sharma</strong> (2015), “Students Performance Evaluation using MCDM Methods through Customized Software”, International Journal of Computer Applications 130 (15), pp. 11-14. ISSN: 0975-8887.</li>
	<br/>
    <li>J Majumder  and <strong>LK Sharma</strong> (2014), “Application of Data Mining Techniques to Audiometric Data among Professional in India”, Journal of Scientific Research and Report, Vol. 3, Issue 23, pp. 2960-2871, ISSN: 2320-0227.</li>
	<br/>
    <li><strong>LK Sharma</strong>, K Baudha  and N Sharma  (2014) “Towards Computational Model for Climate Change”, International Journal Environmental Sciences, Vol. 4, Issue 5 pp. 816-822. ISSN: 0976-4402.</li>
	<br/>
    <li>HS Hota , S Pavani  and <strong>LK Sharma</strong> (2014) “ Fuzzy TOPSIS method applied for Ranking of Teacher in Higher Education”, Springer Advances in Intelligent System Computing 243, pp. 1225-1232. ISSN: 2194-5357.</li>
	<br/>
    <li><strong>LK Sharma</strong> and K Bhatia  (2014), “Building an Institutional Repository for National Institute of Occupational Health: a Case Study”, e-Liberary Science Research Journal, Vol. 2 Issue 8</li>
	<br/>
    <li>J Majumder and <strong>LK Sharma</strong> (2014), “Age Difference in Anthropometric Charateristics of Body Composition among Adult Rural Population of Odisha, India”, Indian Journal of Biological Sciences, Vol. 20, pp. 13-18.</li>
	<br/>
    <li>S Pavani , <strong>LK Sharma</strong> and HS Hota  (2013), “A Group Expert Evaluation for Teachers by integrating fuzzy AHP and TOPSIS models”, IEEE International Conference MOOC Innovation and Technology in Education, pp. 85-90, Publisher IEEE, ISBN: 978-1-4799-1625-2, DOI: 10.1109/MITE.2013.6756311.</li>
	<br/>
    <li>AK Tiwari , <strong>LK Sharma</strong> and G Ramakrishana  (2013), “Comparative Study of Artificial Neural Network based Classification for Liver Patient”, Journal of Information Engineering and Applications, USA, Vol. 3, Issue 4, ISSN 2224-5782 (print)  ISSN 2225-0506 (online) </li>
	<br/>
    <li><strong>LK Sharma</strong> and S Rungta  (2012), "Comparative Study of Data Cluster Analysis for Microarray" Int. J Computer Trends and Technology, Volume 3 Issue 3, May - June 2012, pp. 387-392, ISSN: 2231-2803, IC Impact factor value 4.15.</li>
	<br/>
    <li>SK Shukla , S Rungta , <strong>LK Sharma</strong> (2012) "Trajectory Clustering Approaches for Location Aware Services", Int. J. of Advanced Research in Computer Science, Vol. 3, No. 3, May, pp. 323-327, ISSN 0976-5697.</li>
	<br/>
    <li><strong>LK Sharma</strong> and S Rungta  (2012),"The Data Mining Approaches for Multi-Class Protein Fold Recognition", Int. J. of Biometrics and Bioinformatics, June 2012, ISSN: 0974-9675 Impact Factor: 0.3, DOI: BB062012005. </li>
	<br/>
    <li>S Agarwal , SK Satpathy <strong>LK Sharma</strong> (2011), "Routing Techniques for Reliable wireless Sensor Networks", IEEE sponsored CSNT2011, Katra, India, 3-5 June 2011, pp 54-57, 978-0-7695-4437-3/11,  IEEE, DOI: 10.1109/CSNT.2011.18.</li>
	<br/>
    <li>AK Tiwari ,<strong>LK Sharma</strong>,  and G Ramakrishna  (2010), “Entropy Weighting Genetic k-Means Algorithm for Subspace Clustering”, Int. Journal of Computer Applications,  Vol. 7, No.7, Oct 2010, pp. 27-30. ISSN 0975 – 8887</li>
	<br/>
    <li><strong>LK Sharma</strong>, OP Vyas  and  S Scheider  (2010), “Nearest Neighbour Classification for Trajectory Data”, Springer Verlag CCIS 101, Berlin, pp. 180–185, Impact Factor: 0.4, DOI: 10.1007/978-3-642-15766-0, ISSN: 1865-0929. </li>
	<br/>
    <li>DK Roy  and <strong>LK Sharma</strong> (2010), “Genetic K-Means Clustering Algorithm For Mixed Numeric And Categorical Data Sets”, Int. Journal of Artificial Intelligence and Applications (IJAIA), Vol. 1, No. 2, pp. 23-28, April 2010,  ISSN 0976-2191. </li>
	<br/>
    <li>DK Roy  and <strong>LK Sharma</strong> (2010), “Incremental Genetic K-Modes Algorithm For Clustering Categorical Data Sets”, Int. Journal of Information Science and Computer Mathematics, Vol. 1, No. 2, pp. 89-96, May 2010,   ISSN 1829-4969. </li>
	<br/>
    <li>A Ambhaikar  and<strong>LK Sharma</strong> (2010), “Exploring the Behavior of Mobile Ad hoc Network Routing protocols with Reference to Speed and Terrain range”, Proc. of The Int. Multi-Conference of Engineers and Computer Scientists IMECS-2010 , Hong Kong 17-19 March 2010, Vol. 2,  pp798-803, ISSN: 2078-0958 (Print), 2078-0966(Online), ISBN 978-988-18210-4-1.</li>
	<br/>
    <li>R Vyas , <strong>LK Sharma</strong> and OP Vyas  (2008), “Associative Classifier for Predictive Analytics: Comparative Performance Study” Published on IEEE, EMS, pp. 289-294, ISBN: 978-0-7695-3325-4, DOI: 10.1109/EMS.2008.29.</li>
	<br/>
    <li><strong>LK Sharma</strong>, S Scheider ,W  Kloesgen  and OP Vyas (2008), “Efficient Clustering Technique for Regionalisation of a Spatial Database”, International Journal of Business Intelligence and Data Mining, Vol. 3, Issue 1, pp. 66-81, ISSN: 1743-8187(print), 1743-8195(online), DOI: 10.1504/IJBIDM.2008.017976 </li>
	<br/>
    <li>R Vyas, <strong>LK Sharma</strong> and US Tiwari , (2007) “Exploring SPARM (Spatial Association Rule Mining) for Geo-Decision Support System”, Journal of Computer Science, Vol.  3, Issue 12, pp. 882-886, New York, ISSN: 1549-3636</li>
	<br/>
    <li><strong>LK Sharma</strong>, OP Vyas  and Tiwary US (2005), “A Novel Approach of Multilevel Positive and Negative Association Rule Mining for Spatial Databases”, Springer Verlag LNAI 3587, pp. 620 – 629, 2005, Berlin Heidelberg 2005 ISSN: 0302-9743, Impact Factor: 0.4, DOI: 10.1007/11510888_61 </li>
	<br/>
</ol>
	
</div>	
	
</div>
</div>

        </div>
    </div>
    );
}
export default DrLKSharma;