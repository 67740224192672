import React from 'react';
import '../carousel.css';
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import NavbarMenu from '../NavbarMenu.js';
function DrAnu() {
    return (
      <div> <NavbarMenu /><div class="manual">
        <div class="row container">
        <p class="mypathhead">
        <Link to="/"><span class="mypath">Home</span></Link>&gt;<Link to="/ScientistNIOH"><span class="mypath">Scientist's Profile</span></Link>&gt;<span class="mypath">NIOH Ahmedabad</span>
        </p>
          
        </div>
        <h1 class="mypathhead">Scientist's Detail</h1>
        <div class="scientistdetail">
      
            <div class="panel my-panel" >
      <div class="panel-heading my-panel-head" align="left"><strong>Basic Details</strong></div>
      <div class="panel-body" align="left">
      <table width="100%">
        <thead>
          <th width="28%"></th>
          <th width="50%"></th>
          <th width="22%"></th>
        </thead>
        <tbody>
          <tr>
              <td  rowspan="5"><img src="../assests/images/niohScientist/anu.jpg" width="170px" height="170px" className="dg-img"/></td>
              <td  ><p className="myfontSci trSci"> Dr Anu Priya Minhas</p> </td>
              <td  ><button className="mybuttonSci trSci">View Profile</button></td>
            </tr>
            
            <tr>
              
              <td className="myfontSci"><p className="myfontSci">Biological sciences Division </p></td>
              <td></td>
             </tr>
            <tr>
              <td><p ><a href=""><i className="fa fa-envelope" aria-hidden="true"></i>   Email: anu.minhas@icmr.gov.in</a></p></td>
              <td></td>
            </tr> 
            <tr>
              <td><p ><a href=""> <i className="fa fa-phone-square"></i>  Contact: 079-22688512</a></p></td>
              <td></td>
            </tr>   
        </tbody>
           
            
            </table>
      </div>
    </div>
       
        <div class="panel my-panel" >
      <div class="panel-heading my-panel-head" align="left"><strong>Academic Qualification</strong></div>
      <div class="panel-body" align="left">PhD, IMTECH-CSIR Chandigarh/ Jawaharlal Nehru University, Delhi, India</div>
    </div>
    <div class="panel my-panel" >
      <div class="panel-heading my-panel-head" align="left"><strong>Research Interest </strong></div>
      <div class="panel-body" align="left">Microbiological research in occupational health</div>
    </div>
    <div class="panel my-panel" >
      <div class="panel-heading my-panel-head" align="left"><strong>Membership/Affliation </strong></div>
      <div class="panel-body" align="left">
      <ul><li>Life membership of Indian society for veterinary immunology and Biotechnology</li>
	  <li>Life membership of The Indian Science congress association (Membership number: L28560)</li>
	  <li>Member of Reviewer committee in Bio-Protocol journal</li>

</ul>
        </div>
        </div>
        <div class="panel my-panel">
      <div class="panel-heading my-panel-head" align="left"><strong>Fellowship/Awards </strong></div>
      <div class="panel-body" align="left"><ul><li>	2019- Received Research award in UIET, Panjab University, Chandigarh.</li><br/>
<li>	2005 – 2010: CSIR (Council of Scientific and Industrial Research) Fellowship. </li><br/>
<li>	2005: Qualified UGC-JRF/NET (National Eligibility Test) conducted jointly by CSIR and University Grants Commission (UGC), New Delhi, India. </li><br/>
<li>	1996: Awarded National Scholarship at matriculation level by Govt of Himachal.</li><br/>
<li>	1995- Awarded 1st prize in state level “Science, Technology and Environment Council”.</li><br/>
<li><strong>Projects ongoing:</strong> Dec, 2019-May, 2022- Research grant from Deptt. of S&T & Renewable Energy, UT, Chandigarh </li>
<li><strong>Projects Completed:</strong> 2012-2017 –DST Fast Track Young Scientist Fellowship research project</li>
<br/>


</ul>
</div>
</div>
<div class="panel my-panel">
      <div class="panel-heading my-panel-head" align="left"><strong>Publications </strong></div>
      <div class="panel-body" align="left">
	
      <ol >
      <li><strong>Minhas, Anu Priya</strong>; Biswas, Dipanwita; Mondal, Alok K. Development of host and vector for high-efficiency transformation and gene disruption in Debaryomyces hansenii. FEMS Yeast Research, Volume 9, Number 1, February 2009, pp. 95-102(8). (IF-3.299).
</li><br/><li><strong>Anu Priya Minhas</strong>, Anupam Sharma, Harsimran Kaur, Yashpal FNU, Kaliannan Ganesan, and Alok K. Mondal. A conserved Ser/Arg rich motif in PPZ orthologs from fungi is important for its role in cation tolerance. J Biol Chem. 2012 Mar 2; 287(10):7301-12. (IF-5.328).
</li><br/><li><strong>Anu Priya Minhas</strong>, Rakesh Tuli, Sanjeev Puri. Pathway Editing Targets for Thiamine Biofortification in Rice Grains. 2018. Frontiers in plant science 9 (1-8). doi: 10.3389/fpls.2018.00975. (IF-4.2).
</li><br/><li><strong>Anu P. Minhas</strong> and Dipanwita Biswas. Development of an Efficient Transformation System for Halotolerant Yeast Debaryomyces hansenii CBS767. Bio-protocol.  Vol 9, Iss 17, September 05, 2019. 10.21769/BioProtoc.3352. (ISSN: 2331-8325) SCOPUS.
</li><br/><li>Sheena Raina, Anupma Thakur, Aditi Sharma, Pooja D., <strong>Anu Priya Minhas</strong>. Bactericidal activity of Cannabis sativa phytochemicals from leaf extract and their derived Carbon Dots and Ag@Carbon Dots. Materials Letters. (2020), 262, 127122. (IF-3.423).
</li><br/><li>Tarun Bhatt, Aditi Sharma, Sanjeev Puri, <strong>Anu Priya Minhas</strong>. Salt Tolerance Mechanisms and Approaches: Future Scope of Halotolerant Genes and Rice Landraces. Rice Science. 2020. (IF-2.37).
</li><br/><li>Jashan Kaur, Simranpreet Kaur, Mukesh Kumar, Priya Krishnan, Jaspreet Kaur, <strong>Anu Priya Minhas</strong>. Characterization, optimization and machine learning based prediction of biosurfactant production from Debaryomyces hansenii CBS767. nternational Journal of Environmental Science and Technology, (), 1-14. 10.1007/s13762-021-03639-x. (IF-2.86).
</li><br/><li>Aditi Sharma, Ayush Chouhan, Tarun Bhatt, Anupreet Kaur, <strong>Anu Priya Minhas</strong>. 2022. Selectable Markers to Marker-Free Selection in Rice. Molecular Biotechnology https://doi.org/10.1007/s12033-022-00460-w.
</li>
</ol>
	
	
	
</div>
</div>
</div>
        </div>
    </div>
    );
}
export default DrAnu;