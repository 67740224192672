import React, {Component} from 'react';
import './carousel.css';
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import Slider from "react-slick";
import NavbarMenu from './NavbarMenu.js';
import {Link } from "react-router-dom";
import { TwitterTimelineEmbed, TwitterShareButton, TwitterFollowButton, TwitterHashtagButton, TwitterMentionButton, TwitterTweetEmbed, TwitterMomentShare, TwitterDMButton, TwitterVideoEmbed, TwitterOnAirButton } from 'react-twitter-embed';

class Main extends Component {
  constructor(props) {
   
    super(props);
    this.openCity = this.openCity.bind(this);
  }
  openCity(evt,cityName) {
   
    
  }
  
    
 render()
    {
      const Settings = {
        infinite: true,
        slidesToShow: 5,
        slidesToScroll: 1,
        autoplay:400,
        
    };
    
     return (
      <div> <NavbarMenu />
     
    <div class="manual">
      
     <div class="row">
     
      <div class="col-xs-12 col-sm-12 col-md-8 col-lg-8 mb-4">
         <center>
         <div id="carousel-example" class="carousel slide carousel-fade caro" data-ride="carousel">
          <ol class="carousel-indicators">
            <li data-target="#carousel-example" data-slide-to="0" class="active"></li>
            <li data-target="#carousel-example" data-slide-to="1"></li>
            <li data-target="#carousel-example" data-slide-to="2"></li>
            <li data-target="#carousel-example" data-slide-to="3"></li>
            <li data-target="#carousel-example" data-slide-to="4"></li>
          </ol>
          <div class="carousel-inner" role="listbox">
            <div class="carousel-item active">
              <div class="view">
                <img  class="d-block w-100 fff" src='../assests/images/SliderImg/image1.jpg'  alt="GFG" height="480px"/>
                <div class="mask rgba-black-light"></div>
              </div>
              <div class="carousel-caption">
                <h3 class="h3-responsive"style={{fontSize:"1vw"}}>National Institute of Occupational Health</h3>
                
              </div>
            </div>
            <div class="carousel-item">
              <div class="view">
                <img class="d-block w-100 fff" src='../assests/images/SliderImg/harghartiranga.png'  
                  alt="Second slide" height="480px"/>
                <div class="mask rgba-black-strong"></div>
              </div>
              <div class="carousel-caption">
                <h3 class="h3-responsive" style={{fontSize:"1vw"}}>Har Ghar Tiranga</h3>
                <p></p>
              </div>
            </div>
            <div class="carousel-item">
              <div class="view">
                <img class="d-block w-100 fff" src='../assests/images/SliderImg/rohcs.png'  
                  alt="Second slide" height="480px"/>
                <div class="mask rgba-black-strong"></div>
              </div>
              <div class="carousel-caption">
                <h3 class="h3-responsive" style={{fontSize:"1vw"}}>Regional Occupational Health Centre(Bengaluru)</h3>
                <p></p>
              </div>
            </div>
            <div class="carousel-item">
              <div class="view">
                <img class="d-block w-100 fff" src='../assests/images/SliderImg/image4.jpg'  
                  alt="third slide" height="480px"/>
                <div class="mask rgba-black-strong"></div>
              </div>
              <div class="carousel-caption">
                <h3 class="h3-responsive" style={{fontSize:"1vw"}}>Release Of Commemorative postal stamp on COVID-19 Vaccine by Hon'able Uninon Minister of Health & FW</h3>
                <p></p>
              </div>
            </div>
            <div class="carousel-item">
              <div class="view">
                <img class="d-block w-100 fff" src='../assests/images/SliderImg/image5.jpg'  
                  alt="Second slide"  height="480px"/>
                <div class="mask rgba-black-strong"></div>
              </div>
              <div class="carousel-caption">
                <h3 class="h3-responsive" style={{fontSize:"1vw"}}>Swach Bharat Divas</h3>
                <p></p>
              </div>
            </div>
            <div class="carousel-item">
            <div class="view">
                <img class="d-block w-100 fff" src='../assests/images/SliderImg/image3.jpg' 
                  alt="Third slide" height="480px"/>
                <div class="mask rgba-black-slight"></div>
              </div>
              <div class="carousel-caption">
                <h3 class="h3-responsive" style={{fontSize:"1vw"}}>Fit India</h3>
                <p></p>
              </div>
            </div>
          </div>
          <a class="carousel-control-prev" href="#carousel-example" role="button" data-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="sr-only">Previous</span>
          </a>
          <a class="carousel-control-next" href="#carousel-example" role="button" data-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="sr-only">Next</span>
          </a>
          
        </div>
          </center>
          
       </div>
      <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 align-self-lg-center">
        <center> <p class="myheading">Meet Our Director</p></center>
        <div class="row"style={{marginTop:"20px"}}>
          <div class="col">
            <img src="../assests/images/dic/dir_resize.jpg" />
          </div>
          <div class="col">
              <p class="myfont2"style={{margin:"20px"}}>Dr Santasabuj Das</p>
              
              <p class="myfont2" style={{margin:"20px"}}>Scientist G & Director</p>
          </div>
        </div>
        <div class="row" style={{marginTop:"20px"}}>
          <div class="col">
            <center><Link to="/DirDesk"><button class="btn btn-primary temp-btn" >Director's Message</button></Link></center>
          </div>
          <div class="col">
            <center><Link to="/About"><button class="btn btn-primary temp-btn">Know About NIOH</button></Link></center>
          </div>
        </div>
      </div>
      </div>
      <div class="row mydiv1">
      <div class="col-sm-4 publ">
         <center>
           <table>
             <tr>
               <td><h1 class="myheading">Vision</h1></td>
               <td><h5 class="mysubheading">Read More</h5> </td>
             </tr>
           </table>
           <p class="myfontCardbody">To prevent and control occupation related Health problems by creating a safer work environment through intensive research (basic/ epidemiological/ translational), development of appropriate technology for risk minimization and dissemination of knowledge generated by above</p>

          </center>
          
       </div>
      <div class="col-sm-4 publ verLine" >
      
         <center>
          
         <table>
             <tr>
               <td><h1 class="myheading">Mission</h1></td>
               <td><h5 class="mysubheading">Read More</h5> </td>
             </tr>
          </table>
         
                <p class="myfontCardbody" >To emerge as an international ranking center of excellence:
<ul><li>Research and Technology Centre</li><li>Academic Centre</li></ul>


</p>

              
          </center>
          
          
        
       </div>
      <div class="col-sm-4 publ  verLine">
         <center>
         
         <table>
             <tr>
               <td><h1 class="myheading">Mandate</h1></td>
               <td><h5 class="mysubheading">Read More</h5> </td>
             </tr>
          </table>
          
                <p class="myfontCardbody">Devoted to provide "Occupational Health" to workers of all occupations & minimize industrial activity induced environmental changes by : "Research, Education, Services & related activities"</p>
 
          </center>
         
        
       </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <hr class="horz"></hr>
        </div>
      </div>
      <div class="row">
        <div class="col sm-8 ">
        <center>
          <h1 class="myheading">Divisons of ICMR-NIOH</h1>
          
            <div class="col-sm-2 ">
             
                <img  class="myimghover1" src="../assests/images/DivImg/Health1.jpg" alt="Card image cap" width="100px" height="100px"/>
                  <div class="card-body">
                    <p class="myfont1">Health Sciences</p>
                  </div>
              
            </div>
            <div class="col-sm-2 ">
             
                <img  class="myimghover1" src="../assests/images/DivImg/bio.jpg" alt="Card image cap" width="100px" height="100px" />
                  <div class="card-body">
                    <p class="myfont1">Biological Sciences</p>
                  </div>
              
            </div>
           
              <div class="col-sm-2">
                <img  class="myimghover1" src="../assests/images/DivImg/chem1.jpg" alt="Card image cap" width="100px" height="100px"/>
                  <div class="card-body">
                    <p class="myfont1">Chemical Sciences</p>
                  </div>
              </div>
            
            <div class="col-sm-2 ">
              
                <img  class="myimghover1" src="../assests/images/DivImg/support1.jpg" alt="Card image cap" width="100px" height="100px"/>
                  <div class="card-body">
                    <p class="myfont1">Supporting Facility</p>
                  </div>
              </div>
            
             </center>
        </div>
        
      
      <div class="col-xl-4 verLine" style={{overflow: "auto"}}>
         <center>
         <table>
             <tr>
               <td><h1 class="myheading" style={{marginTop:"25px"}}>Monthly Highlights</h1></td>
              
             </tr>
           </table>
           <hr style={{marginTop:"10px"}}/>
          
          <div class="news" >
          <br/>
          
          <p style={{fontSize:"18px"}}><img src="../assests/images/gnrImg/notify.jpg"  width="30" height="30"/>&nbsp;&nbsp;&nbsp;<a href="https://www.nature.com/articles/d44151-024-00098-4" target="_blank">NIOH study on Agate workers is reported at Nature </a></p>
           </div>
          </center>
          </div>
          </div>
      <div class="row">
        <div class="col-sm-12">
          <hr class="horz"></hr>
        </div>
      </div>


      <div class="container-fluid row" style={{margin:"auto"}}>
      <div class="col-xl-4" >
      <ul class="nav nav-tabs myheading3">
      <li class="active"><a data-toggle="tab" href="#twitter" class="myheading3">Twitter</a></li>
        <li ><a data-toggle="tab" href="#fac" class="myheading3">Facbook</a></li>
        
       
      </ul>

      <div class="tab-content" height="550px">
        <div id="fac" class="tab-pane active">
        <div class="facbok" style={{marginTop:"10px"}} >
          
        <center>
           <iframe
              src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Ficmrnioh&tabs=timeline&small_header=true&adapt_container_width=true&hide_cover=true&show_facepile=true&appId"
               height="550" WIDTH="auto"
              allowTransparency="true" allow="encrypted-media" style={{borderRadius:"10px"}}></iframe>
            
          </center>
        </div>
        </div>
        <div id="twitter" class="tab-pane fade">
          
          <div class="facbok" style={{marginTop:"10px"}}>
           <TwitterTimelineEmbed sourceType="profile" screenName="icmrnioh" options={{height: 550}}/>
          </div>
        </div>
        
      </div>
        
       </div>
<div class="col-xl-4 verLine" >
<ul class="nav nav-tabs myheading3">
        
        <li class="active"><a data-toggle="tab" href="#Career" class="myheading3">Career</a></li>
        <li><a data-toggle="tab" href="#Tender" class="myheading3">Tender</a></li>
        <li ><a data-toggle="tab" href="#Circular" class="myheading3">Circular</a></li>
      </ul>

      <div class="tab-content facbok" style={{overflow: "auto"}}>
      <div id="Career" class="tab-pane active">

    <div style={{fontSize:"16px"}} >

    <p ><a href="https://www.nioh.org/recruitment" target="_blank"><img src="../assests/images/gnrImg/pdf.png" alt="Holiday 2022" width="37" height="49"/>Walk in interview for the Project Technical Support II and Project Technical Support I position  at ICMR-NIOH, Ahmedabad, walk in interview date 04/09/2024</a></p>
    <p ><a href="https://www.nioh.org/recruitment" target="_blank"><img src="../assests/images/gnrImg/pdf.png" alt="Holiday 2022" width="37" height="49"/>Final list of candidates selected and joined under technical cadre recruitment at ICMR-NIOH Ahmedabad advertised Vide No. NIOH/RCT/TECHNICAL/2023-24 dated 05.07.2023</a></p>
    
      

      </div>
      </div>

      <div id="Circular" class="tab-pane fade">
        
     
          <div style={{fontSize:"16px"}} >
          
          <p ><a href="https://nioh.org/circular" target="_blank"><img src="../assests/images/gnrImg/pdf.png" alt="Holiday 2022" width="37" height="49"/> Regarding Azadi ka Amrit Mahotsav Har Ghar Tiranga Campaign</a> </p>
          <p ><a href="https://nioh.org/circular" target="_blank"><img src="../assests/images/gnrImg/pdf.png" alt="Holiday 2022" width="37" height="49"/> DHR Policy on Research Publication, 2024</a> </p>
          
          
        </div>
        </div>
        
        <div id="Tender" class="tab-pane fade">
          
        <div style={{fontSize:"16px"}} >

        { <p ><Link to="/tenderNioh"><img src="../assests/images/gnrImg/notify.jpg"  width="30" height="30"/>&nbsp;&nbsp;&nbsp;High Performance Liquid Chromatography (HPCL) system with Electrochemical Detector (ECD) GEM/2024/B/5281926</Link></p>}
         { <p ><Link to="/tenderNioh"><img src="../assests/images/gnrImg/notify.jpg"  width="30" height="30"/>&nbsp;&nbsp;&nbsp;Freeze Dryer / Lyophilizer GEM/2024/B/5281832</Link></p>}
         { <p ><Link to="/tenderNioh"><img src="../assests/images/gnrImg/notify.jpg"  width="30" height="30"/>&nbsp;&nbsp;&nbsp;Cryo-Microtome GEM/2024/B/5281620</Link></p>}
         { <p ><Link to="/tenderNioh"><img src="../assests/images/gnrImg/notify.jpg"  width="30" height="30"/>&nbsp;&nbsp;&nbsp;Ultraviolet / Visible Spectrophotometer GEM/2024/B/5280606</Link></p>}
         { <p ><Link to="/tenderNioh"><img src="../assests/images/gnrImg/notify.jpg"  width="30" height="30"/>&nbsp;&nbsp;&nbsp;Professional Large Format Display GEM/2024/B/5259839</Link></p>}
       
<br/>

{/* <p ><Link to="/tenderNioh"><img src="../assests/images/gnrImg/notify.jpg"  width="30" height="30"/>&nbsp;&nbsp;&nbsp;Tender for Video Conferencing system with large display panel 01 set</Link></p>
<br/>
<p ><Link to="/tenderNioh"><img src="../assests/images/gnrImg/notify.jpg"  width="30" height="30"/>&nbsp;&nbsp;&nbsp;AMC for Computer and its peripherals with Tender ID : 2022_DoHR_667352_1</Link></p>
<br/>
<p ><Link to="/tenderNioh"><img src="../assests/images/gnrImg/notify.jpg"  width="30" height="30"/>&nbsp;&nbsp;&nbsp;Tender for condemnation on CPP portal with Tender ID : 2022_DoHR_660806_1</Link></p>
<br/>

  <p ><Link to="/tenderNioh"><img src="../assests/images/gnrImg/notify.jpg"  width="30" height="30"/>&nbsp;&nbsp;&nbsp;Bioelectrical Impedence Assessment(BIA) Instrument GeM/2022/B/889268</Link></p>
<br/> */}


</div>
        </div>
        
      </div>
   
  </div>
          
        
       
       <div class="col-xl-4 verLine" style={{overflow: "auto"}}>
         <left>
         <table>
             <tr>
               <td><h1 class="myheading" style={{marginTop:"25px"}}>News</h1></td>
              
             </tr>
           </table>
           <hr style={{marginTop:"10px"}}/>
          
          <div class="news" >
          <br/>
          
          <p style={{fontSize:"18px"}}><img src="../assests/images/gnrImg/notify.jpg"  width="30" height="30"/>&nbsp;&nbsp;&nbsp;<a href="https://www.nioh.org/recruitment" target="_blank">List of Eligible and Inelibible candidate List for the post of LDC and UDC</a></p>
          <p style={{fontSize:"18px"}}><img src="../assests/images/gnrImg/notify.jpg"  width="30" height="30"/>&nbsp;&nbsp;&nbsp;<a href="https://nioh.org/workshop" target="_blank">Workshop on Systematic Review and Meta- Analysis at ROHC(S), Bengaluru 21-23 Aug 2024</a></p>
          <p style={{fontSize:"18px"}}><img src="../assests/images/gnrImg/notify.jpg"  width="30" height="30"/>&nbsp;&nbsp;&nbsp;<a href="../assests/pdf/phd/AcSIR_NIOH_Selection_Candidate_PhD.pdf" target="_blank">Selection list for PhD admission under AcSIR </a></p>
          <p style={{fontSize:"18px"}}><img src="../assests/images/gnrImg/notify.jpg"  width="30" height="30"/>&nbsp;&nbsp;&nbsp;<a href="https://www.nioh.org/recruitment" target="_blank">Notification of Syllabus for LDC and UDC Examination and update the experience</a></p>
          </div>
          </left>
          </div>
          
      </div>
      
      
      <div class="row">
        <div class="col-sm-12">
          <hr class="horz"></hr>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-8 col-sm-offset-2 bottom">
        <center>
            <h1 class="myheading">Useful Connections</h1>
            
            <Slider {...Settings}>
          <div>
            <img class="myimghover" src= "../assests/images/footerImg/footer-arpg.png"alt="footer"  height="50"/>
          </div>
          <div>
            <img class="myimghover" src= "../assests/images/footerImg/data_gov_logo.png"alt="footer"  height="60"/>
          </div>
          <div>
            <img class="myimghover" src= "../assests/images/footerImg/footer-dhr.png"alt="footer"  height="50"/>
          </div>
          
          <div>
            <img class="myimghover" src= "../assests/images/footerImg/mi2.png"alt="footer"  height="60"/>
          </div>
          <div>
            <img class="myimghover" src= "../assests/images/footerImg/pm.png"alt="footer" height="50"/>
          </div>
          <div>
            <img class="myimghover" src= "../assests/images/footerImg/who.png"alt="footer"  height="60"/>
          </div>
          <div>
            <img class="myimghover" src= "../assests/images/footerImg/icmr.png"alt="footer"  height="50"/>
          </div>
          <div>
            <img class="myimghover" src= "../assests/images/footerImg/g20.png"alt="footer"  height="50"/>
          </div>
        </Slider>
        </center>
        </div>
        
        
      </div>
      <div class="my-footer">
      <center>
          <h1 class="myheading1">Connect With Us</h1>
      </center>
      
  <div class="row">
  <div class="col-lg-2">
          </div>
    <div class="col-lg-2">
      <p class="my-footer-head">Sitemap</p>
      <p class="my-footer-text">Home</p>
      <Link to="/about"><p class="my-footer-text">About Institute</p></Link>
      <Link to="/division"><p class="my-footer-text">Division</p></Link>
      <Link to="/"><p class="my-footer-text">Conference</p></Link>
      <Link to="/recruitment"><p class="my-footer-text">Placement</p></Link>
    </div>
    <div class="col-lg-2">
      <p class="my-footer-head">Quick links</p>
     
      <Link to="/rti"><p class="my-footer-text">RTI</p></Link>
      <p class="my-footer-text">Guest House</p>
      <p class="my-footer-text">Citizen Charter</p>
      <p class="my-footer-text">Public grieveance</p>
    </div>
    <div class="col-lg-2">
    
      <p class="my-footer-head">External Links</p>
      <a href="https://www.icmr.gov.in/" target="_blank"><p class="my-footer-text">ICMR</p></a>
      <a href="https://email.gov.in/"target="_blank"><p class="my-footer-text">Email</p></a>
      <p class="my-footer-text">NIOH Intranet</p>
      <Link to="/PhotoGallery"><p class="my-footer-text">Gallery</p></Link>
    </div>
    <div class="col-lg-3">
          
    <p class="my-footer-head">YouTube Videos</p>
       <iframe width="300" height="160" src="https://www.youtube.com/embed/MOn95iyELUI" frameborder="0" allowfullscreen></iframe>
       
    </div>
    <div class="col-lg-1">
          </div>
  </div>

<p align='center' class="mycopy">Copyright © 2024  ICMR-National Institute of Occupational Health.All Rights Reserved.</p>   

      </div>
    </div>
    </div>
    );
  }
} 
 
// Exporting the component
export default Main;