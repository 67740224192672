import React,{Component} from 'react';
import Slider from "react-slick";
import './carousel.css';
import {  Link } from "react-router-dom";
import NavbarMenu from './NavbarMenu.js';
class PhotoGallery extends Component  {
    render()
    {
      const Settings = {
        infinite: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay:200,
        
    };
    
    return (
      <div> <NavbarMenu /><div class="manual">
          
        <div class="row container">
        <p class="mypathhead">
        <Link to="/"><span class="mypath">Home</span></Link>&gt;<span class="mypath"> Gallery</span>
        </p>
          
        </div>
        <h1 class="mypathhead">Gallery</h1>
        <div class="scientistdetail">
        <p style={{color:"Green",fontWeight:"bold",fontSize:"20px"}}><center>Stakeholders’ meet cum workshop on Occupational Health and Safety among Construction Workers, 24th January 2024, at ICMR-NIOH Ahmedabad</center></p>
       
       <center>
           
           <Slider {...Settings}>
           <div>
           <img  src= "../assests/images/photoGallery/WorkshopImg/Img(0).JPG"alt="footer"  height="150"/>
         </div>
         <div>
           <img  src= "../assests/images/photoGallery/WorkshopImg/Img(1).JPG"alt="footer"  height="150"/>
         </div>
         <div>
           <img src= "../assests/images/photoGallery/WorkshopImg/Img(2).JPG"alt="footer" height="150"/>
         </div>
         <div>
           <img  src= "../assests/images/photoGallery/WorkshopImg/Img(3).JPG"alt="footer"  height="150"/>
         </div>
         <div>
           <img src= "../assests/images/photoGallery/WorkshopImg/Img(4).JPG"alt="footer" width="150" height="150"/>
         </div>
         <div>
           <img src= "../assests/images/photoGallery/WorkshopImg/Img(5).jpg"alt="footer"  height="150"/>
         </div>
         <div>
           <img  src= "../assests/images/photoGallery/WorkshopImg/Img(6).jpg"alt="footer"  width="150" height="150"/>
         </div>
         <div>
           <img  src= "../assests/images/photoGallery/WorkshopImg/Img(7).jpg"alt="footer"  width="150" height="150"/>
         </div>
         <div>
           <img  src= "../assests/images/photoGallery/WorkshopImg/Img(8).jpg"alt="footer"  width="150" height="150"/>
         </div>
         <div>
           <img  src= "../assests/images/photoGallery/WorkshopImg/Img(9).JPG"alt="footer"  width="150" height="150"/>
         </div>
         <div>
           <img  src= "../assests/images/photoGallery/WorkshopImg/Img(10).JPG"alt="footer"  width="150" height="150"/>
         </div>
         
       </Slider>
       </center>
    <br/><br/>
         <p style={{fontSize:"14px"}}>Stakeholders’ meet cum workshop on Occupational Health and Safety among Construction Workers was organized on 24 Jan 2024 at ICMR-NIOH Ahmedabad. Delegates from Health and Family Welfare Department; Building and other Construction Work Welfare Board; Confederation of Real Estate Developers' Associations of India; Gujarat Contractors Association; Builder Association of India; Aajeevika Bureau; Self Employed Women's Association have taken part. Dr Santasbuj Das, Director ICMR-NIOH presented health issues among the construction workers and research carried out by ICMR-NIOH. Mr Nilay Patel, Hon. Secretary, Confederation of Real Estate Developers' Associations of India (CREDAI), Ahmedabad expressed his support for measures to improve the well-being of workers. Mr Vipul Pandya, Bandhkam Mazdoor Sangathan emphasised the problem of Musculoskeletal Disorders (MCSD) among construction workers.Mr Nilesh Makwana provided information on several initiatives undertaken by the Gujarat Building & Other Construction Worker's Welfare Board to enhance the welfare of the workforce. Mr Mahesh Gajera from Aajeevika Bureau stressed the importance of ensuring the safety of the construction site, allowing workers to perform their tasks without any hindrance. Ms Ramila Parmar from SEWA discussed health issues that affect women who work at building construction site. Dr Jayesh Katira Deputy Director (Epidemic), MoHFW, Govt of Gujarat emphasized importance of health of women and child, and strengthening periodic monitoring and examination of construction workers.</p>

        <p style={{color:"Green",fontWeight:"bold",fontSize:"20px"}}><center>राष्ट्रीय खेल दिवस उत्सव समारोह आई सी एम आर- राष्ट्रीय व्यावसायिक स्वास्थ्य संस्थान दिनाँक 21-29 अगस्त, 2023</center></p>
       
       <center>
           
           <Slider {...Settings}>
        
         <div>
           <img  src= "../assests/images/photoGallery/National Sportsday/Img(1).jpg"alt="footer"  height="150"/>
         </div>
         <div>
           <img src= "../assests/images/photoGallery/National Sportsday/Img(2).jpg"alt="footer" height="150"/>
         </div>
         <div>
           <img  src= "../assests/images/photoGallery/National Sportsday/Img(3).jpg"alt="footer"  height="150"/>
         </div>
         <div>
           <img src= "../assests/images/photoGallery/National Sportsday/Img(4).jpg"alt="footer" width="150" height="150"/>
         </div>
         <div>
           <img src= "../assests/images/photoGallery/National Sportsday/Img(5).jpg"alt="footer"  height="150"/>
         </div>
         <div>
           <img  src= "../assests/images/photoGallery/National Sportsday/Img(6).jpg"alt="footer"  width="150" height="150"/>
         </div>
         <div>
           <img  src= "../assests/images/photoGallery/National Sportsday/Img(7).jpg"alt="footer"  width="150" height="150"/>
         </div>
         <div>
           <img  src= "../assests/images/photoGallery/National Sportsday/Img(8).jpg"alt="footer"  width="150" height="150"/>
         </div>
         
       </Slider>
       </center>
    <br/><br/>
         <p style={{fontSize:"14px"}}>कार्यालय आदेश संदर्भ संख्या 1/मिस./16/ राष्ट्रीय खेल दिवस/23-24/884 दिनाँक 23 अगस्त 2023 के अनुसार बनी आयोजन कमेटी द्वारा इसका कार्यक्रम 24 अगस्त 2023 को प्रभारी निदेशक के अनुमोदन, जिसमें आयोजन संबन्धित व्य्य का ब्योरा प्रदान किया गया। संस्थान के कर्मचारियों जिसमें वैज्ञानिक, तकनीकी अधिकारी और कर्मचारी, संस्थान के विभिन्न परियोजनाओं में कार्यरत प्रोजेक्ट स्टाफ, अन्य स्टाफ को फिट इंडिया के लोगो के टी शर्ट को वितरित किया गया।  
इस संबंध में कमेटी द्वारा दिनाँक 25 अगस्त 2023, शुक्रवार को संस्थान में टेबल टेनिस (एकल महिला और पुरुष), चेस (एकल महिला और पुरुष) और बैडमिंटन (एकल महिला और पुरुष) जैसे खेलों का सफल आयोजन करवाया गया। जिसमें संस्थान के कर्मचारियों द्वारा बढ़चढ़ कर भाग लिया गया। <br/>
दिनाँक 28 अगस्त 2023, सोमवार को संस्थान के प्रांगण में लेमन रेस (एकल महिला और पुरुष), खो खो (समूह महिला और पुरुष) और क्रिकेट (समूह महिला और पुरुष) का सफल आयोजन करवाया गया। जिसमें भाग लेने वाले प्रतिभागियों को उनके प्रदर्शन के अनुसार प्रथम और द्वितीय पुरुष्कार अनुमोदित राशि से सम्मानित किया गया।<br/>
इस वर्ष राष्ट्रीय खेल दिवस के उपलक्ष्य में हॉकी के जादूगर मेजर ध्यानचंद ट्रॉफी को उनकी स्मृति सम्मान के रूप में क्रिकेट प्रतियोगिता जिसमें समूह महिला और पुरुष की टीमों द्वारा भाग लिया गया, विजयी टीमों को यह ट्रॉफी प्रदान की गयी। कार्यालय के अंत में सभी कर्मचारियों और प्रतिभागियों द्वारा शपथ जिसे संस्थान के वरिष्ठ वैज्ञानिक डॉ स्नेहल चवहाण द्वारा दिलवाई गई कि प्रतिदिन खेल कूद द्वारा 30 मिनट अपने स्वास्थ्य पर देंगे। 
</p>

        <p style={{color:"Green",fontWeight:"bold",fontSize:"20px"}}><center>Celebration of World Antimicrobial Awareness Week (WAAW) 18th -24th Nov 2022</center></p>
       
        <center>
            
            <Slider {...Settings}>
         
          <div>
            <img  src= "../assests/images/photoGallery/Antimicrobial resistance/pic2.jpeg"alt="footer"  height="150"/>
          </div>
          <div>
            <img src= "../assests/images/photoGallery/Antimicrobial resistance/pic3.jpeg"alt="footer" height="150"/>
          </div>
          <div>
            <img  src= "../assests/images/photoGallery/Antimicrobial resistance/pic4.jpeg"alt="footer"  height="150"/>
          </div>
          <div>
            <img src= "../assests/images/photoGallery/Antimicrobial resistance/pic5.jpeg"alt="footer" width="150" height="150"/>
          </div>
          <div>
            <img src= "../assests/images/photoGallery/Antimicrobial resistance/pic6.jpeg"alt="footer"  height="150"/>
          </div>
          <div>
            <img  src= "../assests/images/photoGallery/Antimicrobial resistance/pic7.jpeg"alt="footer"  width="150" height="150"/>
          </div>
          
        </Slider>
        </center>
     <br/><br/>
          <p style={{fontSize:"14px"}}>Antimicrobial resistance (AMR) is a growing concern to human and animal health globally. World Antimicrobial Awareness Week (WAAW) is a global campaign that is celebrated annually to improve awareness and understanding of AMR and encourage best practices amongst all stakeholders. This year the week was celebrated from 18th to 24th Nov 2022 with the theme - ' “Preventing Antimicrobial Resistance Together.” ICMR-NIOH organized  'Expert Talk on Antimicrobial Resistance' on 25th Nov 2022 to spread awareness on AMR amongst its staff to commemorate the 'World Antimicrobial Awareness Week (WAAW)</p>







        <p style={{color:"Green",fontWeight:"bold",fontSize:"20px"}}><center>हिंदी पखवाड़ा आयोजन की संक्षिप्त रिपोर्ट</center></p>
       
        <center>
            
            <Slider {...Settings}>
          <div>
            <img src= "../assests/images/photoGallery/HindiPakhwada/1.jpg"alt="footer"  height="150"/>
          </div>
          <div>
            <img  src= "../assests/images/photoGallery/HindiPakhwada/2.jpg"alt="footer"  height="150"/>
          </div>
          <div>
            <img src= "../assests/images/photoGallery/HindiPakhwada/3.jpg"alt="footer" height="150"/>
          </div>
          <div>
            <img  src= "../assests/images/photoGallery/HindiPakhwada/4.jpg"alt="footer"  height="150"/>
          </div>
          <div>
            <img src= "../assests/images/photoGallery/HindiPakhwada/5.jpeg"alt="footer"height="150"/>
          </div>
          <div>
            <img src= "../assests/images/photoGallery/HindiPakhwada/6.jpeg"alt="footer"  height="150"/>
          </div>
          <div>
            <img  src= "../assests/images/photoGallery/HindiPakhwada/1.jpg"alt="footer"  height="150"/>
          </div>
        </Slider>
        </center>
     <br/><br/>
          <p style={{fontSize:"14px"}}>आईसीएमआर- राष्ट्रीय व्यावसायिक स्वास्थ्य संस्थान, अहमदाबाद में दिनांक 16 से 29 सितंबर, 2022 तक पखवाड़े का आयोजन किया गया। हिंदी पखवाड़े का उदघाटन संस्थान के प्रभारी निदेशक डॉ. शांतसबुज दास द्वारा दिनांक 16 सितंबर,
2022 को हिंदी सामान्य ज्ञान प्रतियोगिता से किया गया। पखवाड़े के दौरान विभिन्न प्रतियोगिताओं जैसे हिंदी सामान्य ज्ञान, हिंदी निबंध लेखन, हिंदी श्रुत लेखन, हिंदी सुलेख, हिंदी टिप्पणी एवं मसौदा लेखन, कंप्यूटर पर यूनिकोड के माध्यम से हिंदी
टंकण तथा डिजिटल हिंदी पोस्टर प्रतियोगिता का आयोजन किया गया। अंत में दिनांक 29.09.2022 को दोपहर 2 बजे हिंदी हास्य कवि सम्मेलन एवं पुरस्कार वितरण समारोह का आयोजन किया गया। हिंदी हास्य कवि सम्मेलन में कुशल मंच संचालक
व ग़ज़लकर डॉ. ऋषिपाल धीमान, हास्य कवि श्री ऋतेश त्रिपाठी, हास्य-व्यंग्य कवि श्री संपत लोहार एवं हास्य कवि श्री हरिवदन भट्ट ने अपनी हास्य रचनाएं सुनाकर श्रोताओं को मंत्र-मुग्ध कर दिया। सम्पूर्ण कार्यक्रम का संचालन संस्थान के कनिष्ठअनुवाद अधिकारी श्री आर. एम. पोरवाल द्वारा किया गया।</p>

        </div>
       
        
        </div>
        </div>
    
    );
}
}
export default PhotoGallery;