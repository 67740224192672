import React from 'react';
import '../carousel.css';
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import NavbarMenu from '../NavbarMenu.js';
function DrNitish() {
    return (
      <div> <NavbarMenu /><div class="manual">
        <div class="row container">
        <p class="mypathhead">
        <Link to="/"><span class="mypath">Home</span></Link>&gt;<Link to="/ScientistNIOH"><span class="mypath">Scientist's Profile</span></Link>&gt;<span class="mypath">NIOH Ahmedabad</span>
        </p>
          
        </div>
        <h1 class="mypathhead">Scientist's Detail</h1>
        <div class="scientistdetail">
      
            <div class="panel my-panel" >
      <div class="panel-heading my-panel-head" align="left"><strong>Basic Details</strong></div>
      <div class="panel-body" align="left">
      <table width="100%">
            <tr>
              <td width="28%" rowspan="5"><img src="../assests/images/niohScientist/nitish.jpg" width="170px" height="170px" CLASS="dg-img"/></td>
              <td width="50%" ><p class="myfontSci trSci">Dr NitishKumar Dhirajlal Tank</p> </td>
              <td width="22%" ></td>
            </tr>
            
            <tr>
              
              <td class="myfontSci"><p class="myfontSci">Health sciences Division </p></td>
             </tr>
            <tr>
              <td><p ><a href=""><i class="fa fa-envelope" aria-hidden="true"></i>   Email: tank.nitish@icmr.gov.in</a></p></td>
            </tr> 
            <tr>
              <td><p ><a href=""> <i class="fa fa-phone-square"></i>  Contact: 079-22688762</a></p></td>
            </tr>   
           
            </table>
              
      </div>
    </div>
       
        <div class="panel my-panel" >
      <div class="panel-heading my-panel-head" align="left"><strong>Academic Qualification</strong></div>
      <div class="panel-body" align="left">M.B.B.S.,M.D. (Pharmacology)
</div>
    </div>
    <div class="panel my-panel" >
      <div class="panel-heading my-panel-head" align="left"><strong>Research Interest </strong></div>
      <div class="panel-body" align="left"><ul><li>To explore possible pharmacological interventions for the prevention and treatment of occupational diseases</li></ul></div>
    </div>
    <div class="panel my-panel" >
      <div class="panel-heading my-panel-head" align="left"><strong>Membership/Affliation </strong></div>
      <div class="panel-body" align="left">
      To be Update
</div>
</div>
<div class="panel my-panel">
      <div class="panel-heading my-panel-head" align="left"><strong>Fellowship/Awards </strong></div>
      <div class="panel-body" align="left">To be Update
</div>
</div>
<div class="panel my-panel">
      <div class="panel-heading my-panel-head" align="left"><strong>Publications </strong></div>
      <div class="panel-body" align="left">
	
      <ol>

      <li>	Dutta S, Vegada BN, Tank ND, Charan J, Rustagi N, Ambwani S. Assessment of Medical Students’ Perception Regarding Educational Environment at Different Phases of Medical Education in a Tertiary Centre Medical College using Dundee Ready Educational Environment Measure (DREEM) Questionnaire. Int J Pharm Sci Rev Res 2022; 72(1): 63-72.
</li><br/><li>	Charan J, Tank N, Reljic T, Singh S, Bhardwaj P, Kaur R, et al. Prevalence of multidrug resistance tuberculosis in adult patients in India: A systematic review and meta-analysis. J Family Med Prim Care 2019; 8:3191-201. 
</li><br/><li>	Singh S, Tank N, Dwiwedi P, Charan J, Kaur R, Sidhu P, et al. Monoclonal Antibodies: A Review. Curr Clin Pharmacol 2018; 13(2):85-99. 
</li><br/><li>	Tank ND, Karelia BN, Vegada BN. Biological response modifiers in rheumatoid arthritis: Systematic review and metaanalysis of safety. J Pharmacol Pharmacother 2017; 8:92-105. 
</li><br/><li>	Misra A, Gupta A, Tank N, Kaklotar D, Singh S, Sharma P. Pharmacotherapy in Metabolic Syndrome. J Rational Pharmacother Res 2017; 3(1):20-37. 
</li><br/><li>	Dela AI, Tank ND, Singh AP, Piparva KG. Adverse Drug Reactions and Treatment Outcome Analysis of MDR TB Patients of DOTS plus Therapy at District Tuberculosis Centre – A Four Years Retrospective Study. Lung India 2017; 34(6):522-526. 
</li><br/><li>	Tank ND, Karelia BN, Bhansali NB. An observational comparative study of clinical efficacy and safety of chlordiazepoxide and lorazepam in alcohol withdrawal syndrome. Int J Med Res Rev 2016; 4(9):1646-54. 
</li><br/><li>	Tank ND, Bhansali NB and Karelia BN. Study of Prescribed Dose Calculation in Pediatric Patients. Int J Pharm Sci Res 2016; 7(8):3465-71. 
</li><br/><li>	Tank ND, Bhansali NB, Karelia BN. In Vitro Comparison of Generic and Branded Formulations of Ceftazidime Using Standard Strain of Pseudomonas. Natl J Integer Res Med 2016; 7(2):31-36. 
</li><br/><li>	Tank ND, Bhansali NB Karelia BN. Paracetamol Induced Drug Reaction with Eosinophilia and Systemic Symptoms (Dress Syndrome): A Case Report. Int J Pharm Sci Rev Res 2015; 32(1): 246-48
</li>
</ol>
	
	
	
</div>
</div>
</div>
        </div>
    </div>
    );
}
export default DrNitish;