import React from 'react';
import '../carousel.css';
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import NavbarMenu from '../NavbarMenu.js';
function DrAkshya() {
    return (
      <div> <NavbarMenu /><div class="manual">
        <div class="row container">
        <p class="mypathhead">
        <Link to="/"><span class="mypath">Home</span></Link>&gt;<Link to="/ScientistROHCS"><span class="mypath">Scientist's Profile</span></Link>&gt;<span class="mypath">ICMR-ROHCS Bengaluru</span>
        </p>
          
        </div>
        <h1 class="mypathhead">ICMR-ROHCS Scientist's Detail</h1>
        <div class="scientistdetail">
      
            <div class="panel my-panel" >
      <div class="panel-heading my-panel-head" align="left"><strong>Basic Details</strong></div>
      <div class="panel-body" align="left">
      <table width="100%">
            <tr>
                <td width="28%" rowspan="5"><img src="../assests/images/rohcsScientist/ShubhangiP.jpg" width="180px" height="170px" CLASS="dg-img"/></td>
                <td width="50%" ><p class="myfontSci trSci"> Dr Shubhangi Pingle</p> </td>
                <td width="22%" ></td>
                
            </tr>
            <tr>
              <td><p ><a href=""><i class="fa fa-envelope" aria-hidden="true"></i>   Email: pingle.shubhangi@gmail.com</a></p></td>
              <td></td>
            </tr> 
            <tr>
              <td><p ><a href=""> <i class="fa fa-phone-square"></i>  Contact: To Be Update</a></p></td>
              <td></td>
            </tr>  
           
            </table>   
      </div>
    </div>
       
        <div class="panel my-panel" >
      <div class="panel-heading my-panel-head" align="left"><strong>Academic Qualification</strong></div>
      <div class="panel-body" align="left">
      M. Sc., Ph.D., Biochemistry
</div>
    </div>
    <div class="panel my-panel" >
      <div class="panel-heading my-panel-head" align="left"><strong>Research Interest </strong></div>
      <div class="panel-body" align="left">
      Occupational disease biomarkers, proteomics, Immunology and cell culture biology, Histopathology, Animal studies, Serology, Development of Diagnostics, NIHL, Silicosis, COPD, CWP, Lectinology

</div>
</div>
<div class="panel my-panel">
      <div class="panel-heading my-panel-head" align="left"><strong>Membership/Affliation </strong></div>
      <div class="panel-body" align="left">
      <ul><li>	Life member of the Proteomics Society (India), Hyderabad</li>
<li>Life member of Society for Neurochemistry, India (SNCI) started way back in 1979, as University of Hyderabad, Hyderabad</li>
<li>Life member of The Society of Biological Chemists, Bangalore (India) (SBC-I). It is affiliated to FAOBMB and IUBMB.


</li>

</ul>
</div>
</div>
<div class="panel my-panel">
      <div class="panel-heading my-panel-head" align="left"><strong>Fellowship/Awards </strong></div>
      <div class="panel-body" align="left">
      <ul>	<li>	A method for detection of aluminium (Al) in biological fluid, Date of filing of Application : 2312/2015. Application No/ 4834/MUM/2015</li><br/>
<li>	Contraceptive activity of saponins from Sapindus trifoliatuslinn Application No. 8960/8/01/07 CBR No. 27840</li><br/>
<li>	Isolation of saponins new compound from Acacia concinna DC.  Patent No. 225314. Application No. 812/MUM/2006</li><br/>
<li>	Conference grant received from European Science Foundation and Travel award received from Department of Biotechnology to attained conference on “The Impact of the Environment on Innate Immunity Threat of Diseases” at Obergurgl, near Innsbruck, Austria 2009.
</li>
</ul>
</div>
</div>
<div class="panel my-panel">
      <div class="panel-heading my-panel-head" align="left"><strong>Publications </strong></div>
      <div class="panel-body" align="left">
	
      <ol>
	<li>	Prestin: Piezo-electric Mechanotransducer bridge in hearing. Kartikey P Matte1, Akanksha A Singh, Amie P Madekar, <strong>Shubhangi K Pingle</strong>*, Rajani G Tumane, Aruna A Jawade, Siddhiki P Shrikhandkar, Chetak M Matte, Rounak V Janbandhu Bulletin of Environment, Pharmacology and Life Sciences [BEPLS] Vol 10 (8), 2021.
</li><br/><li>	Studies on characterization of maytenussenegalensis lectins with rbc surface markers of cancer, diabetes and sickle cell anemia R.K. Chahande, Snehal Parate,Rajani G Tumane, <strong>Shubhangi K Pingle</strong> Bulletin of Environment, Pharmacology and Life Sciences [BEPLS] Vol 10 (8), 2021.
</li><br/><li>	Rajani Tumane, <strong>shubhangi pingle</strong>, Aruna Jawade, Kirtikumar Randive,  Managing Water Quality in Mining Areas: Changing Paradigm of Sustainability: Balancing Environment, Ecology, and Economy, Innovations in Sustainable Mining pp 203-217, Part of the Earth and Environmental Sciences Library book series (EESL), Springer Nature ( BOOK CHAPTER)
</li><br/><li>	Piyush V Shende, Nidhi K Meshram, Surbhi V Barde, <strong>Shubhangi K Pingle</strong>,Rajani G Tumane, Aruna A Jawade, Priyanka P Urade,  Upper tip-link density Intermicrovillar protein: a linkage of stereocilia in hearing Bulletin of Environment, Pharmacology and Life Sciences [BEPLS] 2021 Vol 10(4), 48-57.
</li><br/><li>	Rajani G. Tumanea, Lucky Thakkara, <strong>Shubhangi K.Pingle</strong>,  Ruchika K.Jain, Aruna A.Jawade, Dhananjay V.Raje  Expression of serum proteins in noise induced hearing loss workers of mining based industry Journal of Proteomics Journal of Proteomics, Volume 240, 30 May 2021, https://doi.org/10.1016/j.jprot.2021.104185
</li><br/><li>	The impact of silica dust exposure on humoral immunity of exposed workers Aruna A Jawade , Rajani G Tumane , <strong>Shubhangi K Pingle</strong>, Bhuvneshwari A. Mehere International Journal of Advanced Research and Review IJARR, 5(6), 2020; 21-27
</li><br/><li>	Priyanka P Urade, Surbhi V BARDE  <strong>Shubhangi K Pingle</strong>*  , Rajani G Tumane  , Aruna, A Jawade , Nidhi K. Meshram , Piyush V Shende, Shardul S. Wagh  Tip links and Top Connector Proteins in Stereocilia: a pursuit in hearing loss, Bulletin of Environment, Pharmacology and Life Sciences [BEPLS] 2020 Vol 9(3), 72-79.
</li><br/><li>	 Bulletin of Environment, Pharmacology and Life Sciences [BEPLS] 2021 Vol 10 (8)
</li><br/><li>	Nikeeta P Chahande  <strong>Shubhangi K Pingle</strong>*  , Rajani G Tumane  , Aruna, A Jawade, Screening for Oral, rectal and bladder cancer by Lagerstroemia speciosa leaves lectin as a marker  , Pharmacology and Life Sciences [BEPLS] 2020 Vol 9(3),31-36.
</li><br/><li>	Surabhi V Barde1,  Rajani G Tumane3, Aruna A Jawade3, <strong>Shubhangi K Pingle</strong>*3, Priyanka P Urade1, Piyush V Shende2, Nidhi R.Meshram2, Shardul S. Wagh1 Succinct look to the ERM protein family in Earshot Impairment, Bulletin of Environment, Pharmacology and Life Sciences [BEPLS] 2020 Vol 9(2), 152-159.
</li><br/><li>	Nidhi R.Meshram, Rajani G Tumane, Aruna A Jawade, Surabhi V Barde, Piyush V Shende, Priyanka P Urade, <strong>Shubhangi K Pingle</strong> Significance of Actin Bundling Protein In Hearing: An Update  , Advances of Bioresearch 2019 Vol 10(3),13-18.
</li><br/><li>	Jain RK, <strong>Pingle SK</strong>, Tumane RG, Thakkar LR, Jawade AA, Barapatre A, Trivedi M. Cochlear Proteins Associated with Noise-induced Hearing Loss: An Update. Indian J Occup Environ Med. 2018 May-Aug;22(2):60-73. doi: 10.4103/ijoem.IJOEM_43_18. Review. PubMed PMID: 30319226; PubMed Central PMCID: PMC6176698.
</li><br/><li>	<strong>Shubhangi K. Pingle</strong>, Rajani G. Tumane, Aruna A. Jawade Ruchika K. Jain, Lucky R. Thakkar, Pravin Barapatre A, Jaiswal S. A Short review on : Silicosis,”The ruler of occupational Diseases. The Botanique . 2017; 21(I): 79-86.
</li><br/><li>	Aaditi Khagar , Shrddha Jaiswal,  <strong>Shubhangi K. Pingle</strong>, , Aruna A. Jawade Rajani G. Tumane, Anand Barapatre  Ruchika K. Jain, “Role of plant lectin in occupational diseases”. The Botanique . 2017; 21(I): 91-97.
</li><br/><li>	Rajani G. Tumane, <strong>Shubhangi K. Pingle</strong>, Aruna A. Jawade, Ruchika K. Jain, Shweta Dudhalkar “ Study of occupational health hazard effects in traffic police by proteomic technology”. The Botanique . 2017; 21(I): 116-123.
</li><br/><li>	Aruna A. Jawade, <strong>Shubhangi K. Pingle</strong>, Rajani G. Tumane, , Ruchika K. Jain, Sneha Pakhale “ Respiratory disorder among cotton dust exposed  workers in Maharashtra”. The Botanique. 2017; 21(I): 130-135.
</li><br/><li>	Jawade AA, <strong>Pingle SK</strong>, Tumane RG, Sharma AS,  Ramteke AS, Jain RK.,. Isolation and Charaterization of lectin from the leaves of Euphoria tithymaloides (L.)., Tropical Plant Research. . 2016; 3(3): 634-641.
</li><br/><li>	BA Mehere, <strong>SK Pingle</strong>, MB Patil, LR Thakkar, RG Tumane, A Jawade, Analysis of Rheumatoid Arthritis Factor and Haematological Parameters among stone quarry Dusts exposed Workers and Establishing its correlation with years of exposure to stone Quarry Dust. Advances in Bioresearch 8 (2) 2017.
</li><br/><li>	B. A. Mehere, M. B. Patil, <strong>S. K. Pingle</strong>, L. R.Thakkar, A. B. Butle Determination of levels of C-Reactive Protein (CRP) and Liver function test parameters among occupationally exposed stone quarry workers of Central India: A quest in pursuit of Biomarkers of occupational diseases of stone quarries, Env. Pharmacol. Life Sci., 2016; 5(6): 33-38.
</li><br/><li>	<strong>Shubhangi K. Pingle</strong>, Rajani G. Tumane, Aruna A. Jawade Ruchika K. Jain, Lucky R. Thakkar, Pravin N. Soni. Identification of Heme Oxygenase-1 (HO-1) For Risk Assessment and Disease Progression in Bauxite Mine Workers. IJHSR. 2015; 5(5): 304-313.
</li><br/><li>	<strong>Shubhangi K. Pingle</strong>, Lucky R. Thakkar, Aruna A. Jawade, Rajani G. Tumane Ruchika K. Jain,. Neopterin: A candidate Biomarker for the early assessment of toxicity of Aluminium among Bauxite dust exposed mine workers. Indian Journal of Occupational and Environmental Medicine, 2015 May-Aug; 19(2): 102–109. 
</li><br/><li>	Ruchika K. Jain, Aruna A. Jawade, <strong>Shubhangi K. Pingle</strong>, Rajani G. Tumane and Lucky R. Thakkar. Separation of low molecular weight serum proteins using acetonitrile precipitation assessed by one dimensional gel electrophoresis. European Journal of Experimental Biology, 2015, 5(5):18-23.
</li><br/><li>	Tumane RG, Jawade AA, Thakkar LR, Jain RK and <strong>Pingle SK</strong> Determination of Serum TSH among Coal Mine Worker And Population Living In Vicinity Of Chandrapur District, Maharashtra” International Journal of Recent Scientific Research Vol. 6, Issue, 6, pp.4861-4865, June, 2015. 
</li><br/><li>	Lucky Ramesh Thakkar, <strong>Shubhangi Kailas Pingle</strong>, Divya Vishambhar Kumbhakar1,Aruna Anil Jawade, Rajani Ganpatrao Tumane, Pravin Naresh Soni, Ruchika Kishor Jain, Kaiser Jamil2. Expression of 16.2kDa protein in elderly population: A quest for the detection of age related hearing impairment, IJO 2015; 21 ( 4) 
</li><br/><li>	M. Trivedi, <strong>S. Pingle</strong>, R. Tumane, P. Soni. Absence of 22.97 kda & 26.10kda proteins in noise exposed workers” International Journal of Clinical and Diagnostic Research Volume 3, Issue 1, Jan-Feb 2015. 
</li><br/><li>	Aruna A. Jawade, Ruchika K. Jain, <strong>Shubhangi K. Pingle</strong>, Rajani G. Tumane, Lucky R. Thakkar, B. B. Mandal, Pravin N. Soni, “Impact of high level of Aluminum on Parathyroid hormone in Bauxite dust exposed mine workers”, Indian Journal of Applied Research, 2014; 4(12).
</li><br/><li>	<strong>S. Pingle</strong>, B. Tiwari, A Gaikwad, NG Bhawsar, S. Khandelwal, A Varma, Estimation of Aluminium in miners blood samples by using ICP-AES, Pharmacology and toxicology, Vol 2, Issue 2, 2014, 95-98.
</li><br/><li>	Rajani G. Tumane, Aruna A. Jawade, Bibhuti. B. Mandal, Ruchika K. Jain, Lucky R. Thakkar, <strong>Shubhangi K. Pingle</strong>. Impact of Bauxite dust toxicity on the Health of Mine Workers. Asiatic Journal of Biotechnology Resources; 2014 (SPL11); 29: 89-93.
</li><br/><li>	M. Trivedi and <strong>S. Pingle</strong> “Noise Induced Hearing Loss (NIHL) (Risk factors, genes and associated proteins)” review article in Asiatic Journal of Biotechnology Resources,  May 2013,  vol 14 (01) pp1-6. 
</li><br/><li>	Y. Gadge , <strong>S. Pingle</strong> , R.Tumane , A. Jawade and P. Soni, “Expression of Protein in Bauxite Exposed Mine Workers by Induction of Heme Oxygenase-1” Asian J. Exp. Biol. Sci,  2013; 4(2): 318-321. 
</li><br/><li>	P. N. Soni, <strong>S. K. Pingle</strong>, R. G. Tumane and A. A. Jawade, “Study on protein biomarkers in municipal solid waste exposed workers” Asiatic J Biotechnology Resources, 2013; 04 (01): 17-21. Impact factor:0.33
</li><br/><li>	Lucky R. Thakkar, <strong>Shubhangi K. Pingle</strong>, Rajani G. Tumane, Pravin n. Soni, “Expression of Low Molecular Mass Proteins in Stone Quarry Workers” National Journal of Laboratory Medicine. 2013 April, Vol-2(1): 8-11. 
</li><br/><li>	P Katolkar, A Rulhe, S Pingle, SB Bodele, NJ Duragkar Antiimplantation activity of the Methanolic Extract of Balanites aegyptiaca Bark Del. in Rats, Research Journal of Pharmacy and Technology 5 (2), 288-290.
</li><br/><li>	<strong>Shubhangi K Pingle</strong> , M B Patil Anti fertility activity of Ficus bengalensis Linn. Special Emphasis on Histoarchitecture changes of female reproductive system on Rat, Journal of Pharmacy & Technology vol. 03 issue 04 Dec. 2010.
</li><br/><li>	Rajani G Tumane, <strong>Shubhangi K Pingle</strong>, Aruna A Jawade, Nirmalendu N Nath An overview of caspase: Apoptotic protein for silicosis, Indian Journal of Occupational and Environmental Medicine 2010 Volume : 14 Issue : 2 Page  31-38.
</li><br/><li>	<strong>Shubhangi K Pingle</strong>, Rajani G Tumane, Aruna A Jawade, Neopterin: Biomarker of cell-mediated immunity and potent usage as biomarker in silicosis and other occupational diseases. Indian Journal of Occupational and Environmental Medicine" in the year December 2008, Vol.12., Issue 3. 2008.
</li><br/><li>	Subhangi Pingle and Mandakini Patil, Periodicity of Estrus cycle in rats: Response to Ficus bengalensis in Bark extract. Adv. Pharmacol. Toxicol. Vol. 9 (2) 2008, 85-89.
</li><br/><li>	N.J. Duragkar <strong>S. K. Pingle</strong> S. B. Bodele, Antioxidant activity of Syzygium cumini seeds in asprin induced peptic ulcer, Advances in Pharmacology and Toxicology Vol.8( 3) 2008 1577-1580.
</li><br/><li>	Duragkar Nandakishore, <strong>Gore Shubhangi</strong>, Itankar Prakash, Sadanshio Pallavi, Katolkar Parimal, Bodele Shishupal Herbal plants with antifertilty activity: a review, Pharma review Vol. 5, Issue 29, 2007.
</li><br/><li>	N.J. Duragkar <strong>S.N. Gore</strong> S.V. Bansod M.V. Dhavale Periodicity of Estrus Cycle in Rats: Response to Sapindus trifoliatus Linn. Fruit Extract The Medicinal Research Council of Maharashtra, Vol 4, Issue 4, 2006. 25-27.
</li><br/><li>	<strong>S. Gore</strong> and M B Patil, Duragkar Nandakishore, Itankar Prakash, Sadanshio Pallavi, Katolkar Parimal, Periodicity of estrus cycle of rats: response to Sapindus trifoliatus Linn. fruit extract.Milestone, Vol. 4, Issue 4, 2005.
</li><br/><li>	<strong>S N Gore</strong> & M B Patil, Effect of alcoholic extracts of barks of Zizyphus Jujuba, Ficus Bengalensis and Ficus Religiosa on intra uterine luminal proteins rats; Indian Vet. Med. Journal; Vol –29,117-121 June 2005
</li><br/><li>	<strong>S. Gore</strong> and M B Patil, Effect of Zizyphus jujuba Lamk., Ficus bengalensis Linn. Ficus religiosa Linn. on estrus cycles of the albino rats, Nagpur University Journal 2004.
</li><br/><li>	K V Bansod, C R Jangde, M S Ande, M S Jogekar, S K Bansod & <strong>S N Gore</strong>.In vitro anthihelmanthic screening of piper Longum against Toxocara Cati; The Royal veterinarian journal of India, Vol-1, Issue –1, Jan 2005.
</li><br/><li>K. V. Bansod, <strong>S. N. Gore</strong>, R.R. Chakraborty, B. Wanjari, C. R. Jangade Effect of Ocimum sanctum Linn. (Tulsi) and Piper betle Linn. (Pann) on bleeding time in rabbits. Vaterinarian,  Vol 28, 2004.
</li><br/><li>	S. Patil, <strong>S. Gore</strong>, K. Deshpande and S. Gajbhiye, Effect of storage on viability of pollen grains in Bajra. The International Journal of Soils & Crops Vol 8 Issue1, 1998.
</li>
	
</ol>
	
	
</div>
</div>

</div>   
    </div>
    </div>
    );
}
export default DrAkshya;