import React from 'react';
import '../carousel.css';
import NavbarMenu from '../NavbarMenu.js';
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
function DrDebasis() {
    return (
      <div> <NavbarMenu /><div class="manual">
        <div class="row container">
        <p class="mypathhead">
        <Link to="/"><span class="mypath">Home</span></Link>&gt;<Link to="/ScientistROHCS"><span class="mypath">Scientist's Profile</span></Link>&gt;<span class="mypath">ICMR-ROHCS Bengaluru</span>
        </p>
          
        </div>
        <h1 class="mypathhead">ICMR-ROHCS Scientist's Detail</h1>
        <div class="scientistdetail">
      
            <div class="panel my-panel" >
      <div class="panel-heading my-panel-head" align="left"><strong>Basic Details</strong></div>
      <div class="panel-body" align="left">
      <table width="100%">
            <tr>
                <td width="28%" rowspan="5"><img src="../assests/images/rohcsScientist/Debasis.jpg" width="180px" height="170px" CLASS="dg-img"/></td>
                <td width="50%" ><p class="myfontSci trSci"> Mr Debasis Chatterjee</p> </td>
                <td width="22%" ></td>
                
            </tr>
            <tr>
              <td><p ><a href=""><i class="fa fa-envelope" aria-hidden="true"></i>   Email: Chatterjee.d@icmr.gov.in</a></p></td>
              <td></td>
            </tr> 
            <tr>
              <td><p ><a href=""> <i class="fa fa-phone-square"></i>  Contact: +91-9477281019</a></p></td>
              <td></td>
            </tr> 
           
            </table>   
      </div>
    </div>
       
        <div class="panel my-panel" >
      <div class="panel-heading my-panel-head" align="left"><strong>Academic Qualification</strong></div>
      <div class="panel-body" align="left">
      <ul><li>M.Sc. Physiology, specialization in Work Physiology & Ergonomics, Calcutta University</li>
	  <br/><li>PGDEnM, Environmental Management, Indian Institute of Social Welfare & Business Management DIH, Annamalai University </li>
	  <br/><li>Registered for PhD in Environmental Science, Gandhi Institute of Technology & Management (GITAM ) University</li>
	  </ul>
</div>
    </div>
    <div class="panel my-panel" >
      <div class="panel-heading my-panel-head" align="left"><strong>Research Interest </strong></div>
      <div class="panel-body" align="left">
      Occupational Hygiene, Environmental monitoring of Noise, dust, vibration, illumination, heat stress, Ergonomics evaluation of work, Ergonomics assessment of workspace, Work physiology and other studies in Mines, Mineral based industries and different factories.

</div>
</div>
<div class="panel my-panel">
      <div class="panel-heading my-panel-head" align="left"><strong>Membership/Affliation </strong></div>
      <div class="panel-body" align="left">
      To Be Update
</div>
</div>
<div class="panel my-panel">
      <div class="panel-heading my-panel-head" align="left"><strong>Fellowship/Awards </strong></div>
      <div class="panel-body" align="left">
      To Be Update
</div>
</div>
<div class="panel my-panel">
      <div class="panel-heading my-panel-head" align="left"><strong>Publications </strong></div>
      <div class="panel-body" align="left">
	
      <ol>
      <li>	Sarmishtha Chatterjee & <strong>Debasis Chatterjee</strong>; Endometriosis in Women is a Common Headache of This New Era: Review Article; International Journal of Health Sciences & Research (www.ijhsr.org) 316 Vol.7; Issue: 7; July 2017
</li><br/><li>	Usha G. Shenoy, Karthiyanee Kutty, <strong>Debasis Chatterjee</strong>, Ranganath B. G.; Assessment of respirable dust concentration in the mine trailing area in comparison with non mining area; IOSR Journal of Environment Science, Toxicology and Food Technology (IOSR-JESTFT) e-ISSN:2319-2402; P-ISSN:2319-2399, volume 12, Issue 3, Ver I (March 2018),  PP 37-40, www.isojournal.org
</li><br/><li>	Priyanka Mankar, Bibhuti Bhusan Mandal, <strong>Debasis Chatterjee</strong>; “Monitoring and assessment of airborne respirable limestone dust and free Silica content in an Indian mine; Journal of Health & Pollution, Vol.9, No.23, September 2019.
</li><br/><li>	S Nandi, S Dhatrak, <strong>Debasis Chatterjee</strong>, U.L Dhumne; A study on morbidity profile of lignite miners in Western India; International journal of Occupational Safety and Health 5 (1), 14-16; 2015
</li><br/><li>	SV Dhatrak, SS Nandi, <strong>Debasis M Chatterjee</strong>, UL Dhumne; Health Status Evaluation Of Limestone Mine Workers; Community Med 5 (4), 410-3, 2014
</li><br/><li>	<strong>Debasis Chatterjee</strong>, G. S. Ravindra; Assessment of ambient noise of a fixed crusher and a mobile crushing plant of an open cast iron-ore mine in South India; International conference on Humanizing work & work environment 2015 & community nutrition & health: a social responsibility; Indian Institute of Technology, 7th to 9th December 2015.
</li><br/><li>	<strong>Debasis Chatterjee</strong>; Visual display and operators’ work efficiency: an ergonomic assessment of various work equipment used in open cast mines in India; International conference on Humanizing work & work environment 2015 & community nutrition & health: a social responsibility; Indian Institute of Technology, 7th to 9th December 2015. 
</li><br/><li>	Prakash Kumar Tiwari,  Ashis Bhattacherjee,  Biswajit Samanta, B. B. Mandal, <strong>Debasis Chatterjee</strong>; Assessment of Risk due to Whole Body Vibration in Open Cast Coal Mines: A Case Study, International Congress on Humanizing Work and Work Environment, University of Calcutta, December 17th-19th  2009.
</li><br/><li>	<strong>Debasis Chatterjee</strong>, Sarang Dhatrak, Subroto Nandi, B B Mandal, Sachin Narwadiya; Respiratory Morbidity Among Miners’ Of Rajasthan State – Report of a Pilot Study; Asian-Pacific Newsletter on Occupational Health and Safety; September 2008, Volume 15, No. 2, 42-43.
</li><br/><li>	<strong>D. Chatterjee</strong> and S. K. Dave, Evaluation of ambient impulsive noise produced by bursting variety of firecrackers, International Congress on Humanizing Work and Work Environment 2005, PP 596-602.
</li><br/><li>	<strong>Debasis Chatterjee</strong>, Arun Kumar B; Ergonomic Assessment of Whole Body Vibration Exposure Monitoring on Heavy Earth Moving Machinery Operators’ in Open Cast Limestone Mine of South India; The Indian Mining & Engineering Journal, 59 (No.08-09, August-September 2020
</li><br/><li>	<strong>Debasis Chatterjee</strong>; Prevalence of Silicosis and Respiratory Impairment among the Miners’ of Rajasthan State – A Pilot Study Report; Indian Journal of Physiology and Allied Science, Vol. 70, No.4, PP 156 to 162, 2016.
</li><br/><li>	<strong>Debasis Chatterjee</strong>, G. S. Ravindra, S. Viswanatha; A comparative study of pre and post monsoon airborne respirable dust in the limestone mine of Andhra Pradesh & Telangana; The Indian Mining & Engineering Journal, Vol. 55, No.03; March 2016; 22-28, ISSN 0019-594. 
</li><br/><li>	<strong>Debasis Chatterjee</strong>, G. S. Ravindra, Dr. Pradeep P. Piplatkar; Assessment and monitoring of airborne respirable dust studies in the Limestone mines of Andhra Pradesh & Telenangana State – a case study; The Indian Mining & Engineering Journal, Vol. 54, No.03; March 2015; 11-17, ISSN 0019-594.
</li><br/><li>	G. S. Ravindra, <strong>Debasis Chatterjee</strong>, Dr. Pradeep P. Piplatkar; J. Jayakumar, S. Viswanata; “Assessment of respirable dust exposure levels at the limestone mines of South India”; National Conference on Miners’ health hazards and diseases in Indian Mining & Mineral Industries; 21st & 22nd February 2015; page 91-97.
</li><br/><li>	G. S. Ravindra, <strong>Debasis Chatterjee</strong>, Dr. Pradeep P. Piplatkar; “ Illumination level studies in an open cast limestone mine of Ariyalur district, Tamil Nadu – a case study”; National Conference on Miners’ health hazards and diseases in Indian Mining & Mineral Industries; 21st & 22nd February 2015; page 73-82.
</li><br/><li>	Sarang Vilas Dhatrak, Subroto Shambu Nandi, <strong>Debasis Chatterjee</strong>, Umesh Laxman Dhumne; Health status evaluation of limestone mine workers, original article; National journal of community medicine, volume 5, issue 4, October- December, 2014; Page 410-413.
</li><br/><li>	G. S. Ravindra, <strong>Debasis Chatterjee</strong>, Dr. Pradeep P. Piplatkar; “Air borne respirable dust studies at Limestone mines located in Andhra Pradesh & Telenangana State – a review; 30th Mine safety week celebration at Hyderabad region from 11th to 22nd November 2014 at KCP cement Ltd., Macherlo Guntur Dist.; page 86-91, 2014.
</li><br/><li>	Priyanka S. Padole, <strong>Debasis Chatterjee</strong>, B. B. Mandal, Nikhil P. Kulkarni; study of risk assessment of dust exposure at Rajanka Limestone Mine ACC Ltd.; NEERI Conference on “Air pollution induced health effects, development of health risk software & demo; 6th to 7th August 2014.
</li><br/><li>	M. Trivadi, S. Pingle, <strong>Debasis Chatterjee</strong>, R. Tumane & P. Soni; “Absence of 22.97 KDa & 26.10 KDa protein in Noise exposed workers”; “National Conference on Biomarkers in Health & diseases” NIMH Nagpur; 21st & 22nd February 2013.
</li><br/><li> Classification of Mining equipments used in India according to their Vibration hazard potential; B. B. Mandal, K. Sarkar, <strong>Debasis Chatterjee</strong>; 34th International Conference of safety in Mine Research Institutes; 7-10 December 2011
</li><br/><li>	B. B. Mandal, Krishnendu Sarkar, <strong>Debasis Chatterjee</strong>, Prahlad K. Sishodiya; Health risk assessment of operators of HEMMs exposed to whole body vibration in Indian Mines; International Conference on molecules to system Physiology: 100 years journey (ICMSP100), 21-23 September 2011.
</li><br/><li>	Subroto S. Nandi, Sarang V. Dhatrak, <strong>Debasis M. Chatterjee</strong>, Umesh L. Dhumne; Health Survey in Gypsum Mines in India; Indian Journal of Community Medicine; October 2009, Volume 34, Issue 4, 343-345.
</li><br/><li>	B. B. Mandal, <strong>Debasis Chatterjee</strong>, N. P. Kulkarni, Prashant P. Zade, Atul P. Vanerkar, S. Narwadiya, N. R. Thote; Whole body vibration exposure of heavy earth moving machinery operators in Indian mines; The Indian mining and engineering journal; September 2005, vol. 45, No.9, 29-31
</li>  </ol>
	
	
</div>
</div>
</div>
      
    </div>
    </div>
    );
}
export default DrDebasis;