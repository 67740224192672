import React from 'react';
import './carousel.css';
import {  Link } from "react-router-dom";
import NavbarMenu from './NavbarMenu.js';
function ScientistNIOH () {

  function myFunction(event){
        var elems = document.querySelectorAll(".active");
        
        [].forEach.call(elems, function(el) {
          
          el.classList.remove("active");
        });
        event.target.className = "active";
        
        
}     
    return (<div> <NavbarMenu /><div class="manual" id="accordion" >
      
      <div class="row container">
        <p class="mypathhead">
        <Link to="/"><span class="mypath">Home</span></Link>&gt;<span class="mypath">Scientist's Profile</span>&gt;<span class="mypath">ICMR-NIOH Scientist</span>
        </p>
        </div>
          <h1 class="mypathhead">ICMR-NIOH Scientist</h1>
        
              
   <div class="row container">
    <div class="col-md-2">
    <div class="vertical-menu myverti" onClick={(e)=>myFunction(e)}>
       <a class="active" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne" id="headingOne">
          Director
        </a>    
        <a class="collapsed" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo" id="headingTwo">
          Scientist-E
        </a>
        <a class="collapsed" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree" id="headingThree">
          Scientist-D
        </a>
        <a class=" collapsed" data-toggle="collapse" data-target="#collapsefour" aria-expanded="false" aria-controls="collapsefour">
          Scientist-C
        </a>
        <a class="collapsed" data-toggle="collapse" data-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
          Scientist-B
        </a>     
      </div>
      
    </div>  
    <div class="col-md-1"></div>
    <div class="col-md-9">
   
    <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
      <div class="row myvertic">
        <div class="col-md-3">
          <img src="../assests/images/niohScientist/director.jpg"  class="img-responsive"/>
        </div>
        <div class="col-md-6">
          <p class="myfontSci trSci">Dr Santasabuj Das</p>
          <p class="myfontSci">Director & Scientist-G </p>
          <p ><a href=""><i class="fa fa-envelope" aria-hidden="true"></i>   Email: director-nioh@gov.in</a></p>
          <p ><a href=""> <i class="fa fa-phone-square"></i>  Contact: 079-22688710,079-22688711</a></p>
        </div>
        <div class="col-md-2">
          <Link to="/dic" class="btn btn-primary mybuttonSci trSci" style={{fontSize:"18px"}}>View Profile</Link>
        </div>
      </div>
    </div>
  
  
    <div id="collapseTwo" class="collapse " aria-labelledby="headingTwo" data-parent="#accordion">
    <div class="row myvertic">
            <div class="col-md-4">
              <img src="../assests/images/niohScientist/DrLKS.JPG"   width="170px" height="170px" class="dg-img"/>
            </div>
            <div class="col-md-6">
            <p class="myfontSci trSci">Dr Lokesh Sharma</p>
            <p class="myfontSci">Information Technology Division, Division Head </p>
            <p ><a href=""><i class="fa fa-envelope" aria-hidden="true"></i>   Email: sharma.lk@gov.in</a></p>
            <p ><a href=""> <i class="fa fa-phone-square"></i>  Contact: 079-22688749</a></p>
            </div>
            <div class="col-md-2">
              <Link to="/DrLKSharma" class="btn btn-primary mybuttonSci trSci" style={{fontSize:"18px"}}>View Profile</Link>
            </div>
          </div>
        <div class="row myvertic">
            <div class="col-md-4">
              <img src="../assests/images/niohScientist/AnkitS.JPG"   width="170px" height="170px" class="dg-img"/>
            </div>
            <div class="col-md-6">
            <p class="myfontSci trSci">Dr Ankit Mayurkumar Sheth</p>
            <p class="myfontSci">Health Sciences Division, Division Head </p>
            <p ><a href=""><i class="fa fa-envelope" aria-hidden="true"></i>   Email: ankit.sheth@icmr.gov.in</a></p>
            <p ><a href=""> <i class="fa fa-phone-square"></i>  Contact: 079-22688703</a></p>
            </div>
            <div class="col-md-2">
              <Link to="/DrAnkit" class="btn btn-primary mybuttonSci trSci" style={{fontSize:"18px"}}>View Profile</Link>
            </div>
          </div>
         
          <div class="row myvertic">
            <div class="col-md-4">
              <img src="../assests/images/niohScientist/MihirR.jpeg"   width="170px" height="170px" class="dg-img"/>
            </div>
            <div class="col-md-6">
            <p class="myfontSci trSci">Dr Mihir Prafulbhai Rupani</p>
            <p class="myfontSci">Health Sciences Division </p>
            <p ><a href=""><i class="fa fa-envelope" aria-hidden="true"></i>   Email: mihir.rupani@icmr.gov.in</a></p>
            <p ><a href=""> <i class="fa fa-phone-square"></i>  Contact: 079-22688741</a></p>
            </div>
            <div class="col-md-2">
              <Link to="/DrMihir" class="btn btn-primary mybuttonSci trSci" style={{fontSize:"18px"}}>View Profile</Link>
            </div>
          </div>
      
        <div class="row myvertic">
            <div class="col-md-4">
              <img src="../assests/images/niohScientist/SnehalC.jpg"   width="170px" height="170px" class="dg-img"/>
            </div>
            <div class="col-md-6">
            <p class="myfontSci trSci">Dr Snehal Chavhan</p>
            <p class="myfontSci">Health Sciences Division </p>
            <p ><a href=""><i class="fa fa-envelope" aria-hidden="true"></i>   Email: chavhan.sp@icmr.gov.in</a></p>
            <p ><a href=""> <i class="fa fa-phone-square"></i>  Contact: 079-22688741,079-22688767</a></p>
            </div>
            <div class="col-md-2">
              <Link to="/DrSnehal" class="btn btn-primary mybuttonSci trSci" style={{fontSize:"18px"}}>View Profile</Link>
            </div>
          </div>
 
         <div class="row myvertic">
            <div class="col-md-4">
              <img src="../assests/images/niohScientist/nnaha.png"   width="170px" height="170px" class="dg-img"/>
            </div>
            <div class="col-md-6">
            <p class="myfontSci trSci">Dr Nibedita Naha</p>
            <p class="myfontSci">Biological Sciences Division </p>
            <p ><a href=""><i class="fa fa-envelope" aria-hidden="true"></i>   Email: naha.n@gov.in</a></p>
            <p ><a href=""> <i class="fa fa-phone-square"></i>  Contact: 079-22688737</a></p>
            </div>
            <div class="col-md-2">
              <Link to="/DrNibedita" class="btn btn-primary mybuttonSci trSci" style={{fontSize:"18px"}}>View Profile</Link>
            </div>
          </div>
          <div class="row myvertic">
            <div class="col-md-4">
              <img src="../assests/images/niohScientist/psivaperumal.png"   width="170px" height="170px" class="dg-img"/>
            </div>
            <div class="col-md-6">
            <p class="myfontSci trSci">Dr P Sivaperumal</p>
            <p class="myfontSci">Chemical Sciences Division, Division Head </p>
            <p ><a href=""><i class="fa fa-envelope" aria-hidden="true"></i>   Email: sivaperumal.p@gov.in</a></p>
            <p ><a href=""> <i class="fa fa-phone-square"></i>  Contact: 079-2268470</a></p>
            </div>
            <div class="col-md-2">
              <Link to="/DrSiva" class="btn btn-primary mybuttonSci trSci" style={{fontSize:"18px"}}>View Profile</Link>
            </div>
          </div>
          <div class="row myvertic">
            <div class="col-md-4">
              <img src="../assests/images/niohScientist/ashaK.jpg"   width="170px" height="170px" class="dg-img"/>
            </div>
            <div class="col-md-6">
            <p class="myfontSci trSci">Dr K Asha</p>
            <p class="myfontSci">Health Sciences Division </p>
            <p ><a href=""><i class="fa fa-envelope" aria-hidden="true"></i>   Email: asha.k@gov.in</a></p>
            <p ><a href=""> <i class="fa fa-phone-square"></i>  Contact: 079-22688702</a></p>
            </div>
            <div class="col-md-2">
              <Link to="/DrAsha" class="btn btn-primary mybuttonSci trSci" style={{fontSize:"18px"}}>View Profile</Link>
            </div>
          </div>
          <div class="row myvertic">
            <div class="col-md-4">
              <img src="../assests/images/niohScientist/rakeshb.jpg"   width="170px" height="170px" class="dg-img"/>
            </div>
            <div class="col-md-6">
            <p class="myfontSci trSci">Dr B Rakesh</p>
            <p class="myfontSci">Health Sciences Division </p>
            <p ><a href=""><i class="fa fa-envelope" aria-hidden="true"></i>   Email: rakesh.bal@icmr.gov.in</a></p>
            <p ><a href=""> <i class="fa fa-phone-square"></i>  Contact: 079-22688753</a></p>
            </div>
            <div class="col-md-2">
              <Link to="/DrRakesh" class="btn btn-primary mybuttonSci trSci" style={{fontSize:"18px"}}>View Profile</Link>
            </div>
          </div>
          <div class="row myvertic">
            <div class="col-md-4">
              <img src="../assests/images/niohScientist/SDhatrak.jpg"   width="170px" height="170px" class="dg-img"/>
            </div>
            <div class="col-md-6">
            <p class="myfontSci trSci">Dr Sarang Dhatrak </p>
            <p class="myfontSci">Health Sciences Division, In-Charge PIC </p>
            <p ><a href=""><i class="fa fa-envelope" aria-hidden="true"></i>   Email: dhatrak.sv@icmr.gov.in</a></p>
            <p ><a href=""> <i class="fa fa-phone-square"></i>  Contact: 079-22688757</a></p>
            </div>
            <div class="col-md-2">
              <Link to="/DrSarang" class="btn btn-primary mybuttonSci trSci" style={{fontSize:"18px"}}>View Profile</Link>
            </div>
          </div>

          
          </div>


          
    
    <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordion">
      <div class="card-body">

     
          
          <div class="row myvertic">
            <div class="col-md-4">
              <img src="../assests/images/niohScientist/mtiwari.png"   width="170px" height="170px" class="dg-img"/>
            </div>
            <div class="col-md-6">
            <p class="myfontSci trSci">Dr Mugdha Tiwari</p>
            <p class="myfontSci">Biological Sciences Division </p>
            <p ><a href=""><i class="fa fa-envelope" aria-hidden="true"></i>   Email: mugdha.tiwari@gov.in</a></p>
            <p ><a href=""> <i class="fa fa-phone-square"></i>  Contact: 079-22688873</a></p>
            </div>
            <div class="col-md-2">
              <Link to="/DrMugdha" class="btn btn-primary mybuttonSci trSci" style={{fontSize:"18px"}}>View Profile</Link>
            </div>
          </div>
      
      <div class="row myvertic">
            <div class="col-md-4">
              <img src="../assests/images/niohScientist/sdmishra.jpg"   width="170px" height="170px" class="dg-img"/>
            </div>
            <div class="col-md-6">
            <p class="myfontSci trSci">Dr Sukhdev Mishra</p>
            <p class="myfontSci">Health Sciences Division </p>
            <p ><a href=""><i class="fa fa-envelope" aria-hidden="true"></i>   Email: sd [dot]Mishra[at]gov[dot]in</a></p>
            <p ><a href=""> <i class="fa fa-phone-square"></i>  Contact: 079-22688843</a></p>
            </div>
            <div class="col-md-2">
              <Link to="/DrSukhdev" class="btn btn-primary mybuttonSci trSci" style={{fontSize:"18px"}}>View Profile</Link>
            </div>
          </div>
          <div class="row myvertic">
            <div class="col-md-4">
              <img src="../assests/images/niohScientist/nitish.jpg"   width="170px" height="170px" class="dg-img"/>
            </div>
            <div class="col-md-6">
            <p class="myfontSci trSci">Dr NitishKumar Dhirajlal Tank</p>
            <p class="myfontSci">Health Sciences Division </p>
            <p ><a href=""><i class="fa fa-envelope" aria-hidden="true"></i>   Email: tank.nitish@icmr.gov.in</a></p>
            <p ><a href=""> <i class="fa fa-phone-square"></i>  Contact: 079-22688762</a></p>
            </div>
            <div class="col-md-2">
              <Link to="/DrNitish" class="btn btn-primary mybuttonSci trSci" style={{fontSize:"18px"}}>View Profile</Link>
            </div>
          </div>
      
      
      
          <div class="row myvertic">
            <div class="col-md-4">
              <img src="../assests/images/niohScientist/rrpalkhade.png"   width="170px" height="170px" class="dg-img"/>
            </div>
            <div class="col-md-6">
            <p class="myfontSci trSci">Dr Rajendra Palkhade</p>
            <p class="myfontSci">Biological Sciences Division </p>
            <p ><a href=""><i class="fa fa-envelope" aria-hidden="true"></i>   Email: rajendra.palkhade@gov.in</a></p>
            <p ><a href=""> <i class="fa fa-phone-square"></i>  Contact: 079-22688831</a></p>
            </div>
            <div class="col-md-2">
              <Link to="/DrRajendra" class="btn btn-primary mybuttonSci trSci" style={{fontSize:"18px"}}>View Profile</Link>
            </div>
          </div>
          <div class="row myvertic">
            <div class="col-md-4">
              <img src="../assests/images/niohScientist/pbarfal.png"   width="170px" height="170px" class="dg-img"/>
            </div>
            <div class="col-md-6">
            <p class="myfontSci trSci">Mr. Pankaj Barfal</p>
            <p class="myfontSci">Biological Sciences Division </p>
            <p ><a href=""><i class="fa fa-envelope" aria-hidden="true"></i>   Email: barfal.p@gov.in</a></p>
            <p ><a href=""> <i class="fa fa-phone-square"></i>  Contact: 079-22688848</a></p>
            </div>
            <div class="col-md-2">
              <Link to="/DrPankaj" class="btn btn-primary mybuttonSci trSci" style={{fontSize:"18px"}}>View Profile</Link>
            </div>
          </div>
          <div class="row myvertic">
            <div class="col-md-4">
              <img src="../assests/images/niohScientist/gs.jpg"   width="170px" height="170px" class="dg-img"/>
            </div>
            <div class="col-md-6">
            <p class="myfontSci trSci">Dr Gyanendra Singh</p>
            <p class="myfontSci">Biological Sciences Division </p>
            <p ><a href=""><i class="fa fa-envelope" aria-hidden="true"></i>   Email: singh.drgyanendra@gov.in</a></p>
            <p ><a href=""> <i class="fa fa-phone-square"></i>  Contact: 079-22688842</a></p>
            </div>
            <div class="col-md-2">
              <Link to="/DrGyanendra" class="btn btn-primary mybuttonSci trSci" style={{fontSize:"18px"}}>View Profile</Link>
            </div>
          </div>

        </div>
        </div>


    <div id="collapsefour" class="collapse" aria-labelledby="headingfour" data-parent="#accordion">
      <div class="card-body">

         
          
          <div class="row myvertic">
            <div class="col-md-4">
              <img src="../assests/images/niohScientist/gitika.jpeg"   alt="GITIKA" width="170px" height="170px" class="dg-img"/>
            </div>
            <div class="col-md-6">
            <p class="myfontSci trSci">Dr Gitika Kharkwal</p>
            <p class="myfontSci">Biological Sciences Division </p>
            <p ><a href=""><i class="fa fa-envelope" aria-hidden="true"></i>   Email: kharkwal.g@icmr.gov.in</a></p>
            <p ><a href=""> <i class="fa fa-phone-square"></i>  Contact: 079-22688849</a></p>
            </div>
            <div class="col-md-2">
              <Link to="/DrGitika" class="btn btn-primary mybuttonSci trSci" style={{fontSize:"18px"}}>View Profile</Link>
            </div>
          </div>
          <div class="row myvertic">
            <div class="col-md-4">
              <img src="../assests/images/niohScientist/shweta.jpeg"   alt="Shweta" width="170px" height="170px" class="dg-img"/>
            </div>
            <div class="col-md-6">
            <p class="myfontSci trSci">Dr Shweta Pasi</p>
            <p class="myfontSci">Biological Sciences Division </p>
            <p ><a href=""><i class="fa fa-envelope" aria-hidden="true"></i>   Email: shweta.pasi.nimr@gov.in</a></p>
            <p ><a href=""> <i class="fa fa-phone-square"></i>  Contact: 079-22688700</a></p>
            </div>
            <div class="col-md-2">
              <Link to="/DrShweta" class="btn btn-primary mybuttonSci trSci" style={{fontSize:"18px"}}>View Profile</Link>
            </div>
          </div>
          <div class="row myvertic">
            <div class="col-md-4">
              <img src="../assests/images/niohScientist/kuldipU.jpg"   alt="" width="170px" height="170px" class="dg-img"/>
            </div>
            <div class="col-md-6">
            <p class="myfontSci trSci">Dr Kuldip Upadhyay</p>
            <p class="myfontSci">Chemical Sciences Division </p>
            <p ><a href=""><i class="fa fa-envelope" aria-hidden="true"></i>   Email: upadhyay.kul@icmr.gov.in</a></p>
            <p ><a href=""> <i class="fa fa-phone-square"></i>  Contact: 079-22688868</a></p>
            </div>
            <div class="col-md-2">
              <Link to="/DrKuldip" class="btn btn-primary mybuttonSci trSci" style={{fontSize:"18px"}}>View Profile</Link>
            </div>
          </div>
          <div class="row myvertic">
            <div class="col-md-4">
              <img src="../assests/images/niohScientist/AnkitV.jpg"   alt="" width="170px" height="170px" class="dg-img"/>
            </div>
            <div class="col-md-6">
            <p class="myfontSci trSci">Dr Ankit Viramgami</p>
            <p class="myfontSci">Health Sciences Division </p>
            <p ><a href=""><i class="fa fa-envelope" aria-hidden="true"></i>   Email: viramgami.a@icmr.gov.in</a></p>
            <p ><a href=""> <i class="fa fa-phone-square"></i>  Contact: 079-22688756</a></p>
            </div>
            <div class="col-md-2">
              <Link to="/DrAnkitVir" class="btn btn-primary mybuttonSci trSci" style={{fontSize:"18px"}}>View Profile</Link>
            </div>
          </div>
          <div class="row myvertic">
            <div class="col-md-4">
              <img src="../assests/images/niohScientist/dps.jpg"   alt="" width="170px" height="170px" class="dg-img"/>
            </div>
            <div class="col-md-6">
            <p class="myfontSci trSci">Dr Dhirendra Pratap Singh</p>
            <p class="myfontSci">Biological Sciences Division </p>
            <p ><a href=""><i class="fa fa-envelope" aria-hidden="true"></i>   Email: singh.dpratap@icmr.gov.in</a></p>
            <p ><a href=""> <i class="fa fa-phone-square"></i>  Contact: 079-22688747</a></p>
            </div>
            <div class="col-md-2">
              <Link to="/DrDPS" class="btn btn-primary mybuttonSci trSci" style={{fontSize:"18px"}}>View Profile</Link>
            </div>
          </div>
           <div class="row myvertic">
            <div class="col-md-4">
              <img src="../assests/images/niohScientist/nikhilK.png"   alt="" width="170px" height="170px" class="dg-img"/>
            </div>
            <div class="col-md-6">
            <p class="myfontSci trSci">Dr Nikhil Kulkarni </p>
            <p class="myfontSci">Chemical Sciences Division </p>
            <p ><a href=""><i class="fa fa-envelope" aria-hidden="true"></i>   Email: kulkarni.np@icmr.gov.in</a></p>
            <p ><a href=""> <i class="fa fa-phone-square"></i>  Contact: 079-22688466</a></p>
            </div>
            <div class="col-md-2">
              <Link to="/DrNikhil" class="btn btn-primary mybuttonSci trSci" style={{fontSize:"18px"}}>View Profile</Link>
            </div>
          </div> 
          <div class="row myvertic">
            <div class="col-md-4">
              <img src="../assests/images/niohScientist/rsb.png"   alt="" width="170px" height="170px" class="dg-img"/>
            </div>
            <div class="col-md-6">
            <p class="myfontSci trSci">Mr Ram Shankar Barai</p>
            <p class="myfontSci">Biological Sciences Division </p>
            <p ><a href=""><i class="fa fa-envelope" aria-hidden="true"></i>   Email: barairnirrh@icmr.gov.in</a></p>
            <p ><a href=""> <i class="fa fa-phone-square"></i>  Contact: 079-22688849</a></p>
            </div>
            <div class="col-md-2">
              <Link to="/rsb" class="btn btn-primary mybuttonSci trSci" style={{fontSize:"18px"}}>View Profile</Link>
            </div>
          </div>
               
      </div>
     
       
            
      </div>
    
    <div id="collapseFive" class="collapse" aria-labelledby="headingFive" data-parent="#accordion">
      <div class="card-body">
      
          <div class="row myvertic">
            <div class="col-md-4">
              <img src="../assests/images/niohScientist/Soundraya.jpg"   alt="" width="170px" height="170px" class="dg-img"/>
            </div>
            <div class="col-md-6">
            <p class="myfontSci trSci">Dr Soundarya</p>
            <p class="myfontSci">Health Sciences Division </p>
            <p ><a href=""><i class="fa fa-envelope" aria-hidden="true"></i>   Email: s.soundarya@icmr.gov.in</a></p>
            <p ><a href=""> <i class="fa fa-phone-square"></i>  Contact: 079-22688752</a></p>
            </div>
            <div class="col-md-2">
              <Link to="/DrSaundarya" class="btn btn-primary mybuttonSci trSci" style={{fontSize:"18px"}}>View Profile</Link>
            </div>
          </div>
          <div class="row myvertic">
            <div class="col-md-4">
              <img src="../assests/images/niohScientist/rakshitS.jpg"   alt="" width="170px" height="170px" class="dg-img"/>
            </div>
            <div class="col-md-6">
            <p class="myfontSci trSci">Dr Rakshit Shah</p>
            <p class="myfontSci">Health Sciences Division, In-Charge, Ergonomics</p>
            <p ><a href=""><i class="fa fa-envelope" aria-hidden="true"></i>   Email: raskhit.shah@icmr.gov.in</a></p>
            <p ><a href=""> <i class="fa fa-phone-square"></i>  Contact: 079-22688846</a></p>
            </div>
            <div class="col-md-2">
              <Link to="/DrRakshit" class="btn btn-primary mybuttonSci trSci" style={{fontSize:"18px"}}>View Profile</Link>
            </div>
          </div>
            
          <div class="row myvertic">
            <div class="col-md-4">
              <img src="../assests/images/niohScientist/swati.JPEG"   alt="" width="170px" height="170px" class="dg-img"/>
            </div>
            <div class="col-md-6">
            <p class="myfontSci trSci">Dr Swati Joshi</p>
            <p class="myfontSci">Biological Sciences Division</p>
            <p ><a href=""><i class="fa fa-envelope" aria-hidden="true"></i>   Email: swati.joshi05@icmr.gov.in</a></p>
            <p ><a href=""> <i class="fa fa-phone-square"></i>  Contact: 079-22688981</a></p>
            </div>
            <div class="col-md-2">
              <Link to="/DrSwati" class="btn btn-primary mybuttonSci trSci" style={{fontSize:"18px"}}>View Profile</Link>
            </div>
          </div>
          
          <div class="row myvertic">
            <div class="col-md-4">
              <img src="../assests/images/niohScientist/shiv.jpg"   alt="" width="170px" height="170px" class="dg-img"/>
            </div>
            <div class="col-md-6">
            <p class="myfontSci trSci"> Dr ShivKumar S Prajapati </p>
            <p class="myfontSci">Chemical Sciences Division</p>
            <p ><a href=""><i class="fa fa-envelope" aria-hidden="true"></i>   Email: prajapati.shiv@icmr.gov.in</a></p>
            <p ><a href=""> <i class="fa fa-phone-square"></i>  Contact: To be Update</a></p>
            </div>
            <div class="col-md-2">
              <Link to="/DrShivKumar" class="btn btn-primary mybuttonSci trSci" style={{fontSize:"18px"}}>View Profile</Link>
            </div>
          </div>
          
      </div>
    </div>
  
    </div>
    </div>           
  
  </div>
  </div>
   
    );
    
}
export default ScientistNIOH;