import React from 'react';
import './carousel.css';
import {  Link } from "react-router-dom";
import NavbarMenu from './NavbarMenu.js';
function ContactUs () {
    return (<div> <NavbarMenu /><div class="manual">
        <div class="row container">
        <p class="mypathhead">
        <Link to="/"><span class="mypath">Home</span></Link>&gt;<span class="mypath">The Institute</span>&gt;<span class="mypath">About Institute</span>
        </p>
          
        </div>
        <h1 class="mypathhead">Contact Us</h1>
        <div class="scientistdetail">
        <h2> ICMR-National Institute of Occupational Health<br />
                      Meghani Nagar, Ahmedabad-380016, Gujarat, INDIA<br />
                      Phone : +91- 79-22688700 (EPABX No), 22686351, 22686430, 22686330, <br />
                 22686340 (PS to Director)<br />
                      Fax : +91-79-22686110<br />
                  Email : director-nioh[at]gov[dot]in </h2><br />
                  <p> <iframe width="950" height="350" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://maps.google.co.in/maps?ie=UTF8&amp;q=National+Institute+of+Occupational+Health+(ICMR)&amp;fb=1&amp;gl=in&amp;hq=nioh&amp;hnear=0x395e848aba5bd449:0x4fcedd11614f6516,Ahmedabad,+Gujarat&amp;cid=0,0,14750253060424140688&amp;ll=23.054808,72.610702&amp;spn=0.006295,0.006295&amp;t=m&amp;iwloc=A&amp;output=embed"></iframe><br /><small><a href="https://maps.google.co.in/maps?ie=UTF8&amp;q=National+Institute+of+Occupational+Health+(ICMR)&amp;fb=1&amp;gl=in&amp;hq=nioh&amp;hnear=0x395e848aba5bd449:0x4fcedd11614f6516,Ahmedabad,+Gujarat&amp;cid=0,0,14750253060424140688&amp;ll=23.054808,72.610702&amp;spn=0.006295,0.006295&amp;t=m&amp;iwloc=A&amp;source=embed" >View Larger Map</a></small></p>
    </div></div></div>);
}
export default ContactUs;