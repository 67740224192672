import React from 'react';
import '../carousel.css';
import NavbarMenu from '../NavbarMenu.js';
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
function DrSaundarya() {
    return (
      <div> <NavbarMenu /><div class="manual">
        <div class="row container">
        <p class="mypathhead">
        <Link to="/"><span class="mypath">Home</span></Link>&gt;<Link to="/ScientistNIOH"><span class="mypath">Scientist's Profile</span></Link>&gt;<span class="mypath">NIOH Ahmedabad</span>
        </p>
          
        </div>
        <h1 class="mypathhead">Scientist's Detail</h1>
        <div class="scientistdetail">
      
            <div class="panel my-panel" >
      <div class="panel-heading my-panel-head" align="left"><strong>Basic Details</strong></div>
      <div class="panel-body" align="left">
      <table width="100%">
        <thead>
          <th width="28%"></th>
          <th width="50%"></th>
          <th width="22%"></th>
        </thead>
        <tbody>
          <tr>
              <td  rowspan="5"><img src="../assests/images/niohScientist/Soundraya.jpg" width="170px" height="170px" className="dg-img"/></td>
              <td  ><p className="myfontSci trSci"> Dr Soundarya</p> </td>
             
            </tr>
            
            <tr>
              
              <td className="myfontSci"><p className="myfontSci">Health sciences Division </p></td>
              <td></td>
             </tr>
            <tr>
              <td><p ><a href=""><i className="fa fa-envelope" aria-hidden="true"></i>   Email: s.soundarya@icmr.gov.in</a></p></td>
              <td></td>
            </tr> 
            <tr>
              <td><p ><a href=""> <i className="fa fa-phone-square"></i>  Contact: 079-22688752</a></p></td>
              <td></td>
            </tr>   
        </tbody>
           
            
            </table>
      </div>
    </div>
       
        <div class="panel my-panel" >
      <div class="panel-heading my-panel-head" align="left"><strong>Academic Qualification</strong></div>
      <div class="panel-body" align="left">MBBS, Ph.D. (NIMHANS), Postdoctoral Fellowship (NIAAA/NIH)</div>
    </div>
    <div class="panel my-panel" >
      <div class="panel-heading my-panel-head" align="left"><strong>Research Interest </strong></div>
      <div class="panel-body" align="left">Occupational Mental Health, Addiction, Data Visualization and Machine Learning, Genetics/Epigenetics</div>
    </div>
    <div class="panel my-panel" >
      <div class="panel-heading my-panel-head" align="left"><strong>Membership/Affliation </strong></div>
      <div class="panel-body" align="left">
      <ul><li>Research Society on Alcoholism </li><br/>
<li>	International Society of Addiction Medicine (ISAM)</li> <br/>
<li>	International Society for Biological Research in Alcoholism - ISBRA </li><br/>
<li>	Asia Pacific Society for Alcohol and Addiction Research - APSAAR </li><br/>

</ul>
        </div>
        </div>
        <div class="panel my-panel">
      <div class="panel-heading my-panel-head" align="left"><strong>Fellowship/Awards </strong></div>
      <div class="panel-body" align="left"><ul><li>		Postdoctoral fellowship from the National Institute on Alcohol Abuse and Alcoholism (NIAAA), National Institutes of Health (NIH), USA</li><br/>
<li>	Diversity Scholar Award for R Studio Global Conference 2021</li><br/>
<li>	Travel Fellowship Gordon Research Conference – Alcohol and Nervous System-2018</li><br/>
<li>	NIAAA Student Merit Award to attend RSA 2017</li><br/>
<li>	Travel Fellowship from Science and Engineering ResearchBoard (SERB), for  Gordon Research Conference – Alcohol and Nervous System – 2016</li><br/>


</ul>
</div>
</div>
<div class="panel my-panel">
      <div class="panel-heading my-panel-head" align="left"><strong>Publications </strong></div>
      <div class="panel-body" align="left">
	
      <ol >
      <li>	StanglB, ByrdN, <strong>Soundararajan S</strong>, Plawecki M, Connor S, Ramchandani V.The Motivation for Alcohol Reward: Predictors of Progressive-Ratio Intravenous Alcohol Self-Administration in Humans. JoVEJournal of Visualized Experiments
</li><br/><li>	<strong>Soundararajan S</strong>, Kazmi N, Brooks AT, et al. FAAH and CNR1 Polymorphisms in the Endocannabinoid System and Alcohol-Related Sleep Quality. Frontiers in Psychiatry. 2021;12:1504. doi:10.3389/fpsyt.2021.712178
</li><br/><li>	<strong>Soundararajan S</strong>, Agrawal A, Purushottam M, et al. Changes in DNA methylation persist over time in males with severe alcohol use disorder-A longitudinal follow-up study. Am J Med Genet B Neuropsychiatr Genet. Published online January 25, 2021. doi:10.1002/ajmg.b.32833
</li><br/><li>	Balachandar R, <strong>Soundararajan S</strong>, Bagepally BS. Docosahexaenoic acid supplementation in age-related cognitive decline: a systematic review and meta-analysis. Eur J ClinPharmacol. 2020;76(5):639-648. doi:10.1007/s00228-020-02843-x
</li><br/><li>	<strong>Soundararajan S</strong>, Narayanan G, Agrawal A, Prabhakaran D, Murthy P. Relation between age at first alcohol drink & adult life drinking patterns in alcohol-dependent patients. Indian J Med Res. 2017;146(5):606-611. doi:10.4103/ijmr.IJMR_1363_15
</li><br/><li>	<strong>Soundararajan S</strong>, Narayanan G, Agrawal A, Murthy P. Personality Profile and Short-term Treatment Outcome in Patients with Alcohol Dependence: A Study from South India. Indian J Psychol Med. 2017;39(2):169-175. doi:10.4103/0253-7176.203127
</li><br/><li>	Mythri RB, Raghunath NR, Narwade SC, <strong>Soundararajan S</strong> et al. Manganese- and 1-methyl-4-phenylpyridinium-induced neurotoxicity display differences in morphological, electrophysiological and genome-wide alterations: implications for  idiopathic Parkinson’s disease. J Neurochem. 2017;143(3):334-358. doi:10.1111/jnc.14147
</li><br/><li>	Issac TG, <strong>Soundarya S</strong>, Christopher R, Chandra SR. Vitamin B12 deficiency: an important reversible co-morbidity in neuropsychiatric manifestations. Indian J Psychol Med. 2015;37(1):26-29. doi:10.4103/0253-7176.150809
</li><br/><li>	Sivakumar T, <strong>Soundararajan S</strong>, Waghmare A, et al. Services for Enhanced Recovery with Intensive and Continued Engagement (SERWICE): an outpatient psychiatric rehabilitation model from India. Asian J Psychiatr. 2014;11:84-85. doi:10.1016/j.ajp.2014.06.024
</li>
</ol>
	
	
	
</div>
</div></div>

        </div>
    </div>
    );
}
export default DrSaundarya;