import React from 'react';
import Collapsible from 'react-collapsible';
import {  Link } from "react-router-dom";
import NavbarMenu from './NavbarMenu.js';
import './carousel.css';
function Commitee () {
 
    return (<div> <NavbarMenu /><div class="manual">
        <div class="row container">
        <p class="mypathhead">
		<Link to="/"><span class="mypath">Home</span></Link>&gt;<span class="mypath">The Institute</span>&gt;<span class="mypath">Commitee</span>
        </p>
          
        </div>
        <div class="container">
        
          <Collapsible  trigger="Scientific Advisory Committee (SAC)"  >
            <div class="col-md-10 col-md-offset-1">
              <center>
            <table class="table table-bordered"width="90%" style={{fontFamily:"Arial, Helvetica, sans-serif",fontSize:"12px"}}>
	<thead>
	<tr>
	<th width="10%">Sr.No.</th>
	<th width="20%">Name</th>
	<th width="30%">Position</th>
	<th width="30%">Members</th>
	</tr>
	</thead>
	<tr>
	
	<td>1.</td><td>Dr. D. Prabhakaran</td><td>Vice President (Research and Policy) and Director, Centre for Control of Chronic Conditions, Public Health Foundation of India</td><td>Chairperson</td>

	</tr>
	<tr>
	
	<td>2.</td><td>Dr. K. Krishnamurthi</td><td>Professor AcSIR and Chief Scientist & Head (2017- till now) Health and Toxicity CellCSIR-NEERI, Nagpur</td><td>Co-Chair</td>

	</tr>
	<tr>
	
	<td>3.</td><td>Dr. Anurag Agrawal </td><td> India Former Director CSIR- Institute of Genomics and Integrative Biology, New Delhi </td><td>Member</td>

	</tr>
	<tr>
	
	<td>4.</td><td>Dr. Yogesh Souche </td><td>Scientist Emeritus, NCCS & Professor Azim Premji University Formerly at  National Centre for Cell Sciences Pune </td><td>Member</td>

	</tr>
	<tr>
	
	<td>5.</td><td>Dr. Yogeshwer Shukla</td><td>Chief Scientist,Food, Drug & Chemical Toxicology CSIR-Indian Institute of Toxicology Research, Lucknow </td><td>Member</td>

	</tr>
	<tr>
	
	<td>6.</td><td>Girish N Rao </td><td>Professor, National Institute of Mental Health Neurosciences, Bengaluru</td><td>Member</td>

	</tr>
	<tr>
	
	<td>7.</td><td>Dr Arun Kumar Sharma</td><td>Professor & Director,  University College of Medical Sciences, New Delhi </td><td>Member</td>

	</tr>
	<tr>
	
	<td>8.</td><td>Dr. Sagnik Dey</td><td>Institute Chair Professor, Centre for Atmospheric Sciences, Indian Institute of Technology Delhi Hauz Khas, New Delhi</td><td> Member</td>

	</tr>
	<tr>
	
	<td>9.</td><td>Dr Tavpritesh Sethi</td><td>Associate Professor in Computational Biology, Indraprastha Institute of Information Technology, Delhi </td><td>Member</td>

	</tr>
	<tr>
	
	<td>10.</td><td>Director</td><td>ICMR-NIREH,Bhopal</td><td>Member</td>

	</tr>
	<tr>
	
	<td>11.</td><td>Director</td><td>ICMR-NIRT,Chennai</td><td>Member</td>

	</tr>
	<tr>
	
	<td>12.</td><td>Head</td><td> NCD, ICMR HQ</td><td>Member</td>

	</tr>
	<tr>
	
	<td>13.</td><td>Head</td><td>ECD, ICMR HQ</td><td>Member</td>

	</tr>
	
	
	
	
	</table>
  </center>       </div>
          
        </Collapsible>
        <Collapsible  trigger="Technical Specification Committee"  >
        <div class="col-md-10 col-md-offset-1">
              <center>
            <table class="table table-bordered"width="90%" style={{fontFamily:"Arial, Helvetica, sans-serif",fontSize:"12px"}}>
	<thead>
	<tr>
	<th width="10%">Sr.No.</th>
	<th width="27%">Name</th>
	<th width="38%">Position</th>
	<th width="25%">Members</th>
	</tr>
	</thead>
	<tr>
	
	<td>1.</td><td>Prof N.K. Jain</td><td>Professor & Head, Dept of Life Science, Gujarat University</td><td>Chairperson</td>

	</tr>
	<tr>
	
	<td>2.</td><td>Prof. Prabhudas Patel</td><td>Retd. Professor & Head, Bio Chem. Divison, GCRI</td><td>Member</td>

	</tr>
	<tr>
	
	<td>3.</td><td>Prof. P. Srivastava</td><td>Professor & Head, Dept. of Chemistry, Gujarat University</td><td>Member</td>

	</tr>
	<tr>
	
	<td>4.</td><td>Dr Deepak Rawtani</td><td>Associate Professor, National Institute of Forensic Science University</td><td>Member</td>

	</tr>
	<tr>
	
	<td>5.</td><td>Dr Nibedita Naha</td><td>Scientist-E, ICMR-NIOH</td><td>Member</td>

	</tr>
	<tr>
	
	<td>6.</td><td>Dr P. Sivaperumal</td><td>Scientist-D, ICMR-NIOH</td><td>Member</td>

	</tr>
	<tr>
	
	<td>7.</td><td>Dr D.P. Singh</td><td>Scientist-C, ICMR-NIOH</td><td>Member</td>

	</tr>
	<tr>
	
	<td>8.</td><td>Sh.Rahul Wadhvani</td><td>Admn. Officer</td><td>Member</td>

	</tr>
	<tr>
	
	<td>9.</td><td>Sh.Manohar Lal Meena</td><td>Accounts officer</td><td>Member</td>

	</tr>
	<tr>
	
	<td>10.</td><td>Indenter</td><td>Indenter</td><td>Member</td>

	</tr>
<tr>
	
	<td>11.</td><td>Mrs. Parul Modi</td><td>Section Officer</td><td>Member Secretary</td>

	</tr>
	
	
	</table>
  </center>       </div>
        </Collapsible>
        <Collapsible  trigger="Capital Works Advisory Committee (CWAC)"  >
        
        <div class="col-md-10 col-md-offset-1">
              <center>
            <table class="table table-bordered"width="90%" style={{fontFamily:"Arial, Helvetica, sans-serif",fontSize:"12px"}}>

	<thead>
	<tr>
	<th width="10%">Sr.No.</th>
	<th width="20%">Name</th>
	<th width="20%">Position</th>
	<th width="20%">Members</th>
	</tr>
	</thead>
	<tr>
	
	<td>1.</td><td>Dr Ketan P Badgujar</td><td>Professor & Head, Elctrical Enggineering Dept, S.S. Engg. College, Bhavnagar</td><td>Chairperson</td>

	</tr>
	<tr>
	
	<td>2.</td><td>Dr N M Bhatt</td><td>Professor & Head, Mechanical Engg, LD College of Engineering, Ahmedabad</td><td>External Member</td>

	</tr>
	<tr>
	
	<td>3.</td><td>Sh. Nimish M. Shah</td><td>Retired Exe. Engineer, R&B, Govt. of Gujarat</td><td>External Member</td>

	</tr>
	<tr>
	
	<td>4.</td><td>Sh. Sudesh P Sogani</td><td>Retired Dy. Exe. Engineer, R&B, Govt. of Gujarat</td><td>External Member</td>

	</tr>
	<tr>
	
	<td>5.</td><td>Dr Aushotosh K Patel</td><td>Civil Enggineering Dept., Govt. Polytechnic, Ahmedabad</td><td> External Member</td>

	</tr>
	<tr>
	
	<td>6.</td><td>Dr Santasabuj Das</td><td>Director-In-Charge, ICMR-NIOH, Ahmedabad</td><td>Member</td>

	</tr>
	<tr>
	
	<td>7.</td><td>Dr Ankit Sheth</td><td>Scientist-E, ICMR-NIOH</td><td>Member</td>

	</tr>
	<tr>
	
	<td>8.</td><td>Sh.Rahul Wadhvani</td><td>Admn. Officer</td><td>Member</td>

	</tr>
	<tr>
	
	<td>9.</td><td>Sh.Manohar Lal Meena</td><td>Accounts officer</td><td>Member</td>

	</tr>
	<tr>
	
	<td>10.</td><td>Invitees</td><td>Representative of the Executing Agency</td><td>Member</td>

	</tr>
<tr>
	
	<td>11.</td><td>Sh. Sanjay Kotadiya</td><td>Technical Officer</td><td>Member Secretary</td>

	</tr>
	
	
	</table></center>       </div>
  </Collapsible>
  <Collapsible  trigger="Institutional Human Ethics Committee (IHEC)"  >
  <div class="col-md-10 col-md-offset-1">
              <center>
            <table class="table table-bordered"width="90%" style={{fontFamily:"Arial, Helvetica, sans-serif",fontSize:"12px"}}>

	<thead>
	<tr>
	<th width="10%">Sr.No.</th>
	<th width="30%">Name</th>
	
	<th width="20%">Members</th>
	</tr>
	</thead>
	<tr>
	
	<td>1.</td><td>Dr Mahendra K Joshi</td><td>Chairperson</td>

	</tr>
	<tr>
	
	<td>2.</td><td>Dr Shyam Pingle</td><td>External Member</td>

	</tr>
	<tr>
	
	<td>3.</td><td>Dr Bipin Amin</td><td>External Member</td>

	</tr>
	<tr>
	
	<td>4.</td><td>Prof. Baldev Patel</td><td>External Member</td>

	</tr>
	<tr>
	
	<td>5.</td><td>Dr Bupendra Naik</td><td> External Member</td>

	</tr>
	<tr>
	
	<td>6.</td><td>Dr Rakesh Raval</td><td>External Member</td>

	</tr>
	<tr>
	
	<td>7.</td><td>Dr Snehal Patel</td><td>External Member</td>

	</tr>
	<tr>
	
	<td>8.</td><td>Sh. Vipul Pandya</td><td>External Member</td>

	</tr>
	<tr>
	
	<td>9.</td><td>Smt. Pratima Pandya</td><td>External Member</td>

	</tr>
	<tr>
	
	<td>10.</td><td>Dr Mansi Gurmukhani </td><td>External Member</td>

	</tr>
<tr>
	
	<td>11.</td><td>Dr Rakesh B </td><td>Internal Member</td>

	</tr>
	<tr>
	
	<td>12.</td><td>Dr Asha Ketharam </td><td>Internal Member</td>

	</tr>
	<tr>
	
	<td>13.</td><td>Dr Gyanendra Singh </td><td>Internal Member</td>

	</tr>
	<tr>
	
	<td>14.</td><td>Dr Dhirendra Pratap Singh </td><td>Internal Member</td>

	</tr>
	
	
	</table></center>       </div>
  </Collapsible>
  <Collapsible  trigger="Institutional Animals Ethics Committee (IAEC)"  >
  <div class="col-md-10 col-md-offset-1">
              <center>
            <table class="table table-bordered"width="90%" style={{fontFamily:"Arial, Helvetica, sans-serif",fontSize:"12px"}}>

	<thead>
	<tr>
	<th width="10%">Sr.No.</th>
	<th width="30%">Name</th>
	
	<th width="20%">Members</th>
	</tr>
	</thead>
	<tr>
	
	<td>1.</td><td>Dr Santasabuj Das</td><td>Chairperson</td>

	</tr>
	<tr>
	
	<td>2.</td><td>Dr M.N. Brahmbhatt</td><td>External Member</td>

	</tr>
	<tr>
	
	<td>3.</td><td>Dr Nileshkumar Joitaram Patel</td><td>External Member</td>

	</tr>
	<tr>
	
	<td>4.</td><td>Dr Kalpesh Patani</td><td>External Member</td>

	</tr>
	<tr>
	
	<td>5.</td><td>Dr Ganeshbhai B. patel</td><td> External Member</td>

	</tr>
	<tr>
	
	<td>6.</td><td>Dr Rajendra Palkhade</td><td>Member Secretary</td>

	</tr>
	<tr>
	
	<td>7.</td><td>Dr Gyanendra Singh</td><td>Internal Member</td>

	</tr>
	<tr>
	
	<td>8.</td><td>Dr D.P. Singh</td><td>Internal Member</td>

	</tr>
	<tr>
	
	<td>9.</td><td>Dr Mugdha Tiwari</td><td>Internal Member</td>

	</tr>
	
	</table></center>       </div>
  </Collapsible>
  <Collapsible  trigger="Vigilance Officer"  >
  <div class="col-md-10 col-md-offset-1">
              <center>
            <table class="table table-bordered"width="90%" style={{fontFamily:"Arial, Helvetica, sans-serif",fontSize:"12px"}}>

	<thead>
	<tr>
	<th>&nbsp;</th>
	<th>Center</th>
	<th>Name</th>
	<th>Position</th>
	<th>Email</th>
	</tr>
	</thead>
	<tr>
	<td>1</td>
	<td>NIOH</td>	<td>Dr Rajendra Palkhade</td>	<td>Scientist-D</td>	<td>rajendra.palkhade@gov.in</td>


	</tr>
	<tr>
	<td>2</td><td>ROHCS</td>	<td>Dr B Ravichandran</td>	<td>Scientist-E & OIC</td>	<td>ravichandran.b@gov.in</td>
	</tr>
	
	</table></center>       </div>
  </Collapsible>
  <Collapsible  trigger="Grievance Officer"  >
  <div class="col-md-10 col-md-offset-1">
              <center>
            <table class="table table-bordered"width="90%" style={{fontFamily:"Arial, Helvetica, sans-serif",fontSize:"12px"}}>

	<thead>
	<tr>
	<th>&nbsp;</th>
	<th>Center</th>
	<th>Name</th>
	<th>Position</th>
	<th>Email</th>
	</tr>
	</thead>
	<tr>
	<td>1</td>
	<td>NIOH</td>	<td>Dr Gyanendra Singh</td>	<td>	Scientist-D	</td>	<td>singh.drgyanendra@gov.in</td>



	</tr>
	<tr>
	<td>2</td><td>ROHCS</td>	<td>Dr B Ravichandran</td>	<td>Scientist-E & OIC</td>	<td>ravichandran.b@gov.in</td>
	</tr>
	
	</table></center>       </div>
  </Collapsible>
  <Collapsible  trigger="Liaison Officer for SC/ST"  >
  <div class="col-md-10 col-md-offset-1">
              <center>
            <table class="table table-bordered"width="90%" style={{fontFamily:"Arial, Helvetica, sans-serif",fontSize:"12px"}}>

	<thead>
	<tr>
	<th>&nbsp;</th>
	
	<th>Name</th>
	<th>Position</th>
	<th>Email</th>
	</tr>
	</thead>
	<tr>
	<td>1</td>
	<td>Dr P Sivaperumal</td>	<td>	Scientist-E	</td>	<td>sivaperumal.p@gov.in</td>



	</tr>
	
	
	</table></center>       </div>
  </Collapsible>
  <Collapsible  trigger="Liaison Officer for OBC"  >
  <div class="col-md-10 col-md-offset-1">
              <center>
            <table class="table table-bordered"width="90%" style={{fontFamily:"Arial, Helvetica, sans-serif",fontSize:"12px"}}>

	<thead>
	<tr>
	<th>&nbsp;</th>
	
	<th>Name</th>
	<th>Position</th>
	<th>Email</th>
	</tr>
	</thead>
	<tr>
	<td>1</td>
	<td>Dr Rajendra Palkhade</td>	<td>Scientist-D</td>	<td>rajendra.palkhade@gov.in</td>



	</tr>
	
	
	</table></center>       </div>
  </Collapsible>
  <Collapsible  trigger="Welfare Officer"  >
  <div class="col-md-10 col-md-offset-1">
              <center>
            <table class="table table-bordered"width="90%" style={{fontFamily:"Arial, Helvetica, sans-serif",fontSize:"12px"}}>

	<thead>
	<tr>
	<th>&nbsp;</th>
	
	<th>Name</th>
	<th>Position</th>
	<th>Email</th>
	</tr>
	</thead>
	<tr>
	<td>1</td>
	<td>Dr K Asha</td>	<td>Scientist-D</td>	<td>asha.k@gov.in</td>
	</tr>
	</table>
	</center>       </div>
  </Collapsible>
  <Collapsible  trigger="Research Integrity Officer (RIO)"  >
  <div class="col-md-10 col-md-offset-1">
              <center>
            <table class="table table-bordered"width="90%" style={{fontFamily:"Arial, Helvetica, sans-serif",fontSize:"12px"}}>

	<thead>
	<tr>
	<th>&nbsp;</th>
	
	<th>Name</th>
	<th>Position</th>
	<th>Email</th>
	</tr>
	</thead>
	<tr>
	<td>1</td>
	<td>Dr Rakesh B.</td>	<td>Scientist-D</td>	<td>rakesh.bal@icmr.gov.in</td>
	</tr>
	</table>
	</center>       </div>
  </Collapsible>
  <Collapsible  trigger="Internal Complaint Committee"  >
  <div class="col-md-10 col-md-offset-1">
              <center>
            <table class="table table-bordered"width="90%" style={{fontFamily:"Arial, Helvetica, sans-serif",fontSize:"12px"}}>

	<thead>
	<tr>
	<th>&nbsp;</th>
	
	<th>Name</th>
	<th>Position</th>
	<th>Members</th>
	</tr>
	</thead>
	<tr><td>1</td><td>	Dr Shubhangi Pingle</td><td>	Scientist-E	</td><td>Presiding Officer (OBC/Female)</td></tr>
<tr><td>2</td><td>	Dr Manisha Vaja</td><td>	Expert from NGO</td><td>	External Member</td></tr>
<tr><td>4</td><td>	Dr Sweta Pasi</td><td>	Scientist-C	</td><td>Member (SC/Female)</td></tr>
<tr><td>3</td><td>	Dr. Ankit Viramgami</td><td>	Scientist-C	</td><td>Member (UR/Male)</td></tr>
<tr><td>5</td><td>	Sh. Rahul Wadhvani</td><td>	AO	</td><td>	Member(UR/Male/Admin)</td></tr>
<tr><td>6</td><td>Mrs. Rupal Thasale</td><td>	TO</td><td>	Member(OBC/Female)</td></tr>


	
	
	</table></center>       </div>
  </Collapsible>
  <Collapsible  trigger="Nodal Officer for eOffice"  >
  <div class="col-md-10 col-md-offset-1">
              <center>
            <table class="table table-bordered"width="90%" style={{fontFamily:"Arial, Helvetica, sans-serif",fontSize:"12px"}}>

	<thead>
	<tr>
	<th>&nbsp;</th>
	
	<th>Name</th>
	<th>Position</th>
	<th>Email</th>
	</tr>
	</thead>
	<tr>
	<td>1</td>
	<td>Sh. Rahul Wadhvani</td>	<td>Admin Officer</td>	<td>wadhvani.rj@icmr.gov.in</td>
	</tr>
	<tr>
	<td>2</td>
	<td>Dr Swati Joshi</td>	<td>Scientist-B</td>	<td>swati.joshi@icmr.gov.in</td>
	</tr>
	
	
	</table></center>       </div>
  </Collapsible>
  <Collapsible  trigger="Guest House & Canteen Committee"  >
  <div class="col-md-10 col-md-offset-1">
              <center>
            <table class="table table-bordered"width="90%" style={{fontFamily:"Arial, Helvetica, sans-serif",fontSize:"12px"}}>

	<thead>
	<tr>
	<th>&nbsp;</th>
	
	<th>Name</th>
	<th>Position</th>
	<th>Email</th>
	</tr>
	</thead>
	<tr>
	<td>1</td>
	<td>Dr Shiv kumar Prajapati</td>	<td>Scientist -B</td>	<td>prajapati.shiv@icmr.gov.in</td>
	</tr>
	<tr>
	<td>2</td>
	<td>Dr Anu Priya Minhas</td>	<td>Scientist-B</td>	<td>anu.minhas@icmr.gov.in</td>
	</tr>
	<tr>
	<td>3</td>
	<td>Mrs. Sunita Kerketta</td>	<td>Section Officer</td>	<td>kerketta.sh@gov.in</td>
	</tr>
	<tr>
	<td>4</td>
	<td>Mr. Gaurav Anand</td>	<td>Assistant</td>	<td>anand.grv@icmr.gov.in</td>
	</tr>
	<tr>
	<td>5</td>
	<td>Ms. Shruti Patel</td>	<td>Sr. Tech-I</td>	<td>patel.s@gov.in</td>
	</tr>
	<tr>
	<td>6</td>
	<td>Mrs. Dharti Parmar</td>	<td>Sr. Tech-I</td>	<td>parmar.ds@gov.in</td>
	</tr>
	<tr>
	<td>7</td>
	<td>Mr. M R Sayed</td>	<td>UDC</td>	<td>saiyed.msr@gov.in</td>
	</tr>
	<tr>
	<td>8</td>
	<td>Mr. Bhavesh Kumar Vaghela</td>	<td>Technician-A</td>	<td>vaghela.bd@icmr.gov.in</td>
	</tr>
	<tr>
	<td>9</td>
	<td>Mr. Dhaval Kumar Sangadia</td>	<td>MTS(Technical)</td>	<td></td>
	</tr>
	<tr>
	<td>10</td>
	<td>Mr. Zakir Hussain Patel</td>	<td>MTS(Technical)</td>	<td>zakir.deaf4@gmail.com</td>
	</tr>
	
	</table></center>       </div>
  </Collapsible>
  <Collapsible  trigger="AFIH Committee"  >
  <div class="col-md-10 col-md-offset-1">
              <center>
            <table class="table table-bordered"width="90%" style={{fontFamily:"Arial, Helvetica, sans-serif",fontSize:"12px"}}>

	<thead>
	<tr>
	<th>&nbsp;</th>
	
	<th>Name</th>
	<th>Position</th>
	<th>Email</th>
	</tr>
	</thead>
	<tr>
	<td>1</td>
	<td>Dr Sarang Dhatrak</td>	<td>Scientist -D</td>	<td>dhatrak.sv@icmr.gov.in</td>
	</tr>
	<tr>
	<td>2</td>
	<td>Dr Rakshit Shah</td>	<td>Scientist-B</td>	<td>raskhit.shah@icmr.gov.in</td>
	</tr>
	<tr>
	<td>3</td>
	<td>Sh. Ashvin Malhotra</td>	<td>Technical Officer-A</td>	<td>malhotra.aj@icmr.gov.in</td>
	</tr>
	<tr>
	<td>4</td>
	<td>Sh. Paras Panchal</td>	<td>Assistant</td>	<td>--</td>
	</tr>
	<tr>
	<td>5</td>
	<td>Sh. Vikki Kothekar</td>	<td>Sr. Tech-I</td>	<td>kothekar.vr@icmr.gov.in</td>
	</tr>
	
	
	</table></center>       </div>
  </Collapsible>
        </div>
        </div>
    </div>);

}
export default Commitee;