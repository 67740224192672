import React from 'react';
import './carousel.css';
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import Othertech from './othertech';
import NavbarMenu from './NavbarMenu.js';
function TechNioh () {
    return (<div> <NavbarMenu /><div className="manual">
        <div className="row container">
        <p className="mypathhead">
        <Link to="/"><span class="mypath">Home</span></Link>&gt;<span className="mypath">NIOH Staff</span>&gt;<span className="mypath">Technical Staff</span>
        </p>
          
        </div>
        <h1 class="mypathhead">Technical Staff-NIOH Ahmedabad</h1>
        <div className="row row1">
            <div className="col-md-4">
                <div className="panel panel-primary">
                <div className="panel-body">
                    <div className="row">
                        <div className="col-md-4">
                            <img src='../assests/images/TechnicalStaffImg/parveen.jpeg' width="130px" height="160px;"></img>
                            
                        </div>
                        
                        <div className="col-md-8 carddata">
                            <table><tbody>
                                <tr>
                                    <td width="20%"><button className="mybutton-top" ><i className="fa fa-user fa-1x" aria-hidden="true"></i></button></td>
                                    <td WIDTH="95%" className="myfontCard">Parveen Mansuri</td>
                                </tr>
                                <tr>
                                    <td width="20%"></td>
                                    <td WIDTH="95%"className="myfontCardbody">Technical Officer - C</td>
                                </tr>
                                <tr>
                                    <td width="10%"></td>
                                    <td WIDTH="95%"className="myfontCardbody">Establishment Section</td>
                                </tr>
                                <tr>
                                    <td width="20%"><button className="mybutton-top" ><i className="fa fa-history fa-1x" aria-hidden="true"></i></button></td>
                                    <td WIDTH="95%" className="myfontCardbody">33 Yrs Experience</td>
                                </tr>
                                <tr>
                                    <td width="20%"><button className="mybutton-top" ><i className="fa fa-envelope fa-1x" aria-hidden="true"></i></button></td>
                                    <td WIDTH="95%" className="myfontCardbody">mansuri.pr@gov.in</td>
                                </tr>
                                
                            </tbody></table>
                        </div>
                    </div>
                </div>
                </div>
            </div>
            <div className="col-md-4">
                <div className="panel panel-primary">
                <div className="panel-body">
                <div className="row">
                        <div className="col-md-4">
                            <img src='../assests/images/TechnicalStaffImg/shilpa.jpg' width="135px" height="160px;"></img>
                            
                        </div>
                        
                        <div className="col-md-8 carddata">
                            <table><tbody>
                                <tr>
                                    <td width="20%"><button className="mybutton-top" ><i className="fa fa-user fa-1x" aria-hidden="true"></i></button></td>
                                    <td WIDTH="95%" className="myfontCard">Shilpa Ingole</td>
                                </tr>
                                <tr>
                                    <td width="20%"></td>
                                    <td WIDTH="95%"className="myfontCardbody">Technical Officer-B</td>
                                </tr>
                                <tr>
                                    <td width="10%"></td>
                                    <td WIDTH="95%"className="myfontCardbody">Health sciences</td>
                                </tr>
                                <tr>
                                    <td width="20%"><button className="mybutton-top" ><i className="fa fa-history fa-1x" aria-hidden="true"></i></button></td>
                                    <td WIDTH="95%" className="myfontCardbody">2 Yrs Experience</td>
                                </tr>
                                <tr>
                                    <td width="20%"><button className="mybutton-top" ><i className="fa fa-envelope fa-1x" aria-hidden="true"></i></button></td>
                                    <td WIDTH="95%" className="myfontCardbody">shilpa.ingole@icmr.gov.in</td>
                                </tr>
                                
                            </tbody></table>
                        </div>
                    </div>
                </div>
                </div>
            </div>
            <div className="col-md-4">
                <div className="panel panel-primary">
                <div className="panel-body">
                <div className="row">
                        <div className="col-md-4">
                            <img src='../assests/images/TechnicalStaffImg/Mehul.jfif' width="135px" height="160px;"></img>
                            
                        </div>
                        
                        <div className="col-md-8 carddata">
                            <table><tbody>
                                <tr>
                                    <td width="20%"><button className="mybutton-top" ><i className="fa fa-user fa-1x" aria-hidden="true"></i></button></td>
                                    <td WIDTH="95%" className="myfontCard">Mehul M. Madia</td>
                                </tr>
                                <tr>
                                    <td width="20%"></td>
                                    <td WIDTH="95%"className="myfontCardbody">Technical Officer-B</td>
                                </tr>
                                <tr>
                                    <td width="10%"></td>
                                    <td WIDTH="95%"className="myfontCardbody">Health sciences</td>
                                </tr>
                                <tr>
                                    <td width="20%"><button className="mybutton-top" ><i className="fa fa-history fa-1x" aria-hidden="true"></i></button></td>
                                    <td WIDTH="95%" className="myfontCardbody">25 Yrs Experience</td>
                                </tr>
                                <tr>
                                    <td width="20%"><button className="mybutton-top" ><i className="fa fa-envelope fa-1x" aria-hidden="true"></i></button></td>
                                    <td WIDTH="95%" className="myfontCardbody">madia.mm@gov.in</td>
                                </tr>
                                
                            </tbody></table>
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
        <div className="row row1">
            <div className="col-md-4">
                <div className="panel panel-primary">
                <div className="panel-body">
                    <div className="row">
                        <div className="col-md-4">
                            <img src='../assests/images/TechnicalStaffImg/sanjay.jpg' width="130px" height="160px;"></img>
                            
                        </div>
                        
                        <div className="col-md-8 carddata">
                            <table><tbody>
                                <tr>
                                    <td width="20%"><button className="mybutton-top" ><i className="fa fa-user fa-1x" aria-hidden="true"></i></button></td>
                                    <td WIDTH="95%" className="myfontCard">Sanjay Kotadiya</td>
                                </tr>
                                <tr>
                                    <td width="20%"></td>
                                    <td WIDTH="95%"className="myfontCardbody">Technical Officer - B</td>
                                </tr>
                                <tr>
                                    <td width="10%"></td>
                                    <td WIDTH="95%"className="myfontCardbody">Health sciences</td>
                                </tr>
                                <tr>
                                    <td width="20%"><button className="mybutton-top" ><i className="fa fa-history fa-1x" aria-hidden="true"></i></button></td>
                                    <td WIDTH="95%" className="myfontCardbody">12 Yrs Experience</td>
                                </tr>
                                <tr>
                                    <td width="20%"><button className="mybutton-top" ><i className="fa fa-envelope fa-1x" aria-hidden="true"></i></button></td>
                                    <td WIDTH="95%" className="myfontCardbody">kotadiya.sm@gov.in</td>
                                </tr>
                                
                            </tbody></table>
                        </div>
                    </div>
                </div>
                </div>
            </div>
            <div className="col-md-4">
            <div className="panel panel-primary">
                <div className="panel-body">
                <div className="row">
                        <div className="col-md-4">
                            <img src='../assests/images/TechnicalStaffImg/gajanan.jpg' width="135px" height="160px;"></img>
                            
                        </div>
                        
                        <div className="col-md-8 carddata">
                            <table><tbody>
                                <tr>
                                    <td width="20%"><button className="mybutton-top" ><i className="fa fa-user fa-1x" aria-hidden="true"></i></button></td>
                                    <td WIDTH="95%" className="myfontCard">Gajanan Pratap Patil</td>
                                </tr>
                                <tr>
                                    <td width="20%"></td>
                                    <td WIDTH="95%"className="myfontCardbody">Technical Officer - B</td>
                                </tr>
                                <tr>
                                    <td width="10%"></td>
                                    <td WIDTH="95%"className="myfontCardbody">Biological sciences</td>
                                </tr>
                                <tr>
                                    <td width="20%"><button className="mybutton-top" ><i className="fa fa-history fa-1x" aria-hidden="true"></i></button></td>
                                    <td WIDTH="95%" className="myfontCardbody">11 Yrs Experience</td>
                                </tr>
                                <tr>
                                    <td width="20%"><button className="mybutton-top" ><i className="fa fa-envelope fa-1x" aria-hidden="true"></i></button></td>
                                    <td WIDTH="95%" className="myfontCardbody">patil.gp@gov.in</td>
                                </tr>
                                
                            </tbody></table>
                        </div>
                    </div>
                </div>
                </div>
            </div>
            <div className="col-md-4">
                <div className="panel panel-primary">
                <div className="panel-body">
                <div className="row">
                        <div className="col-md-4">
                            <img src='../assests/images/TechnicalStaffImg/man.png' width="130px" height="160px;"></img>
                            
                        </div>
                        
                        <div className="col-md-8 carddata">
                            <table><tbody>
                                <tr>
                                    <td width="20%"><button className="mybutton-top" ><i className="fa fa-user fa-1x" aria-hidden="true"></i></button></td>
                                    <td WIDTH="95%" className="myfontCard">Poonam Vyas</td>
                                </tr>
                                <tr>
                                    <td width="20%"></td>
                                    <td WIDTH="95%"className="myfontCardbody">Technical Officer - A</td>
                                </tr>
                                <tr>
                                    <td width="10%"></td>
                                    <td WIDTH="95%"className="myfontCardbody"></td>
                                </tr>
                                <tr>
                                    <td width="20%"><button className="mybutton-top" ><i className="fa fa-history fa-1x" aria-hidden="true"></i></button></td>
                                    <td WIDTH="95%" className="myfontCardbody"> Yrs Experience</td>
                                </tr>
                                <tr>
                                    <td width="20%"><button className="mybutton-top" ><i className="fa fa-envelope fa-1x" aria-hidden="true"></i></button></td>
                                    <td WIDTH="95%" className="myfontCardbody">vyas.pa@gov.in</td>
                                </tr>
                                
                            </tbody></table>
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
        <div className="row row1">
            <div className="col-md-4">
                <div className="panel panel-primary">
                <div className="panel-body">
                <div className="row">
                        <div className="col-md-4">
                            <img src='../assests/images/TechnicalStaffImg/shweta.jpg' width="135px" height="160px;"></img>
                            
                        </div>
                        
                        <div className="col-md-8 carddata">
                            <table><tbody>
                                <tr>
                                    <td width="20%"><button className="mybutton-top" ><i className="fa fa-user fa-1x" aria-hidden="true"></i></button></td>
                                    <td WIDTH="95%" className="myfontCard">Shweta R Gupta</td>
                                </tr>
                                <tr>
                                    <td width="20%"></td>
                                    <td WIDTH="95%"className="myfontCardbody">Technical Assistant</td>
                                </tr>
                                <tr>
                                    <td width="10%"></td>
                                    <td WIDTH="95%"className="myfontCardbody">Biological sciences</td>
                                </tr>
                                <tr>
                                    <td width="20%"><button className="mybutton-top" ><i className="fa fa-history fa-1x" aria-hidden="true"></i></button></td>
                                    <td WIDTH="95%" className="myfontCardbody">2 Yrs Experience</td>
                                </tr>
                                <tr>
                                    <td width="20%"><button className="mybutton-top" ><i className="fa fa-envelope fa-1x" aria-hidden="true"></i></button></td>
                                    <td WIDTH="95%" className="myfontCardbody">gupta.sr@icmr.gov.in</td>
                                </tr>
                                
                            </tbody></table>
                        </div>
                    </div>
                </div>
                </div>
            </div>
            <div className="col-md-4">
                <div className="panel panel-primary">
                <div className="panel-body">
                <div className="row">
                        <div className="col-md-4">
                            <img src='../assests/images/TechnicalStaffImg/ashwin.jpg' width="135px" height="160px;"></img>
                            
                        </div>
                        
                        <div className="col-md-8 carddata">
                            <table><tbody>
                                <tr>
                                    <td width="20%"><button className="mybutton-top" ><i className="fa fa-user fa-1x" aria-hidden="true"></i></button></td>
                                    <td WIDTH="95%" className="myfontCard">Ashvin J Malhotra</td>
                                </tr>
                                <tr>
                                    <td width="20%"></td>
                                    <td WIDTH="95%"className="myfontCardbody">Technical Officer-A</td>
                                </tr>
                                <tr>
                                    <td width="10%"></td>
                                    <td WIDTH="95%"className="myfontCardbody">Account Section</td>
                                </tr>
                                <tr>
                                    <td width="20%"><button className="mybutton-top" ><i className="fa fa-history fa-1x" aria-hidden="true"></i></button></td>
                                    <td WIDTH="95%" className="myfontCardbody">5 Yrs Experience</td>
                                </tr>
                                <tr>
                                    <td width="20%"><button className="mybutton-top" ><i className="fa fa-envelope fa-1x" aria-hidden="true"></i></button></td>
                                    <td WIDTH="95%" className="myfontCardbody">malhotra.aj@icmr.gov.in</td>
                                </tr>
                                
                            </tbody></table>
                        </div>
                    </div>
                </div>
                </div>
            </div>
            <div className="col-md-4">
                <div className="panel panel-primary">
                <div className="panel-body">
                <div className="row">
                        <div className="col-md-4">
                            <img src='../assests/images/TechnicalStaffImg/srushti thasale.jpg' width="130px" height="160px;"></img>
                            
                        </div>
                        
                        <div className="col-md-8 carddata">
                            <table><tbody>
                                <tr>
                                    <td width="20%"><button className="mybutton-top" ><i className="fa fa-user fa-1x" aria-hidden="true"></i></button></td>
                                    <td WIDTH="95%" className="myfontCard">Rupal Rajesh Thasale</td>
                                </tr>
                                <tr>
                                    <td width="20%"></td>
                                    <td WIDTH="95%"className="myfontCardbody">Technical Officer-A</td>
                                </tr>
                                <tr>
                                    <td width="10%"></td>
                                    <td WIDTH="95%"className="myfontCardbody">Chemical sciences</td>
                                </tr>
                                <tr>
                                    <td width="20%"><button className="mybutton-top" ><i className="fa fa-history fa-1x" aria-hidden="true"></i></button></td>
                                    <td WIDTH="95%" className="myfontCardbody">7 Yrs Experience</td>
                                </tr>
                                <tr>
                                    <td width="20%"><button className="mybutton-top" ><i className="fa fa-envelope fa-1x" aria-hidden="true"></i></button></td>
                                    <td WIDTH="95%" className="myfontCardbody">thasle.r@icmr.gov.in</td>
                                </tr>
                                
                            </tbody></table>
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
        <div className="row row1">
            <div className="col-md-4">
                <div className="panel panel-primary">
                <div className="panel-body">
                    <div className="row">
                        <div className="col-md-4">
                            <img src='../assests/images/TechnicalStaffImg/hardik.png' width="135px" height="160px;"></img>
                            
                        </div>
                        
                        <div className="col-md-8 carddata">
                            <table><tbody>
                                <tr>
                                    <td width="20%"><button className="mybutton-top" ><i className="fa fa-user fa-1x" aria-hidden="true"></i></button></td>
                                    <td WIDTH="95%" className="myfontCard">Hardik Pravinbhai Gami</td>
                                </tr>
                                <tr>
                                    <td width="20%"></td>
                                    <td WIDTH="95%"className="myfontCardbody">Technical Officer-A </td>
                                </tr>
                                <tr>
                                    <td width="10%"></td>
                                    <td WIDTH="95%"className="myfontCardbody">Maintenance Section</td>
                                </tr>
                                <tr>
                                    <td width="20%"><button className="mybutton-top" ><i className="fa fa-history fa-1x" aria-hidden="true"></i></button></td>
                                    <td WIDTH="95%" className="myfontCardbody">8 Yrs Experience</td>
                                </tr>
                                <tr>
                                    <td width="20%"><button className="mybutton-top" ><i className="fa fa-envelope fa-1x" aria-hidden="true"></i></button></td>
                                    <td WIDTH="95%" className="myfontCardbody">gami.hp@gov.in</td>
                                </tr>
                                
                            </tbody></table>
                        </div>
                    </div>
                </div>
                </div>
            </div>
            <div className="col-md-4">
                <div className="panel panel-primary">
                <div className="panel-body">
                <div className="row">
                        <div className="col-md-4">
                            <img src='../assests/images/TechnicalStaffImg/nishant.jpg' width="135px" height="160px;"></img>
                            
                        </div>
                        
                        <div className="col-md-8 carddata">
                            <table><tbody>
                                <tr>
                                    <td width="20%"><button className="mybutton-top" ><i className="fa fa-user fa-1x" aria-hidden="true"></i></button></td>
                                    <td WIDTH="95%" className="myfontCard">Nishant Solanki</td>
                                </tr>
                                <tr>
                                    <td width="20%"></td>
                                    <td WIDTH="95%"className="myfontCardbody">Technical Assistant</td>
                                </tr>
                                <tr>
                                    <td width="10%"></td>
                                    <td WIDTH="95%"className="myfontCardbody">Store Section</td>
                                </tr>
                                <tr>
                                    <td width="20%"><button className="mybutton-top" ><i className="fa fa-history fa-1x" aria-hidden="true"></i></button></td>
                                    <td WIDTH="95%" className="myfontCardbody">4 Yrs Experience</td>
                                </tr>
                                <tr>
                                    <td width="20%"><button className="mybutton-top" ><i className="fa fa-envelope fa-1x" aria-hidden="true"></i></button></td>
                                    <td WIDTH="95%" className="myfontCardbody">solanki.nc@icmr.gov.in</td>
                                </tr>
                                
                            </tbody></table>
                        </div>
                    </div>
                </div>
                </div>
            </div>
            <div className="col-md-4">
                <div className="panel panel-primary">
                <div className="panel-body">
                <div className="row">
                        <div className="col-md-4">
                            <img src='../assests/images/TechnicalStaffImg/man.png' width="135px" height="160px;"></img>
                            
                        </div>
                        
                        <div className="col-md-8 carddata">
                            <table><tbody>
                                <tr>
                                    <td width="20%"><button className="mybutton-top" ><i className="fa fa-user fa-1x" aria-hidden="true"></i></button></td>
                                    <td WIDTH="95%" className="myfontCard">M I Shaikh</td>
                                </tr>
                                <tr>
                                    <td width="20%"></td>
                                    <td WIDTH="95%"className="myfontCardbody">Technical Assistant</td>
                                </tr>
                                <tr>
                                    <td width="10%"></td>
                                    <td WIDTH="95%"className="myfontCardbody"></td>
                                </tr>
                                <tr>
                                    <td width="20%"><button className="mybutton-top" ><i className="fa fa-history fa-1x" aria-hidden="true"></i></button></td>
                                    <td WIDTH="95%" className="myfontCardbody"> Yrs Experience</td>
                                </tr>
                                <tr>
                                    <td width="20%"><button className="mybutton-top" ><i className="fa fa-envelope fa-1x" aria-hidden="true"></i></button></td>
                                    <td WIDTH="95%" className="myfontCardbody">shaikh.mi@gov.in</td>
                                </tr>
                                
                            </tbody></table>
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
        <div className="row row1">
            <div className="col-md-4">
                <div className="panel panel-primary">
                <div className="panel-body">
                    <div className="row">
                        <div className="col-md-4">
                            <img src='../assests/images/TechnicalStaffImg/maheria.jpg' width="130px" height="160px;"></img>
                            
                        </div>
                        
                        <div className="col-md-8 carddata">
                            <table><tbody>
                                <tr>
                                    <td width="20%"><button className="mybutton-top" ><i className="fa fa-user fa-1x" aria-hidden="true"></i></button></td>
                                    <td WIDTH="95%" className="myfontCard">Pareshkumar G Maheria</td>
                                </tr>
                                <tr>
                                    <td width="20%"></td>
                                    <td WIDTH="95%"className="myfontCardbody">Sr Technician - 2</td>
                                </tr>
                                <tr>
                                    <td width="10%"></td>
                                    <td WIDTH="95%"className="myfontCardbody">Director Office</td>
                                </tr>
                                <tr>
                                    <td width="20%"><button className="mybutton-top" ><i className="fa fa-history fa-1x" aria-hidden="true"></i></button></td>
                                    <td WIDTH="95%" className="myfontCardbody">40 Yrs Experience</td>
                                </tr>
                                <tr>
                                    <td width="20%"><button className="mybutton-top" ><i className="fa fa-envelope fa-1x" aria-hidden="true"></i></button></td>
                                    <td WIDTH="95%" className="myfontCardbody">maheria.pg@gov.in</td>
                                </tr>
                                
                            </tbody></table>
                        </div>
                    </div>
                </div>
                </div>
            </div>
            <div className="col-md-4">
                <div className="panel panel-primary">
                <div className="panel-body">
                <div className="row">
                        <div className="col-md-4">
                            <img src='../assests/images/TechnicalStaffImg/shruti.jpg' width="130px" height="160px;"></img>
                            
                        </div>
                        
                        <div className="col-md-8 carddata">
                            <table><tbody>
                                <tr>
                                    <td width="20%"><button className="mybutton-top" ><i className="fa fa-user fa-1x" aria-hidden="true"></i></button></td>
                                    <td WIDTH="95%" className="myfontCard">Shruti Patel</td>
                                </tr>
                                <tr>
                                    <td width="20%"></td>
                                    <td WIDTH="95%"className="myfontCardbody">Sr Technician - 1</td>
                                </tr>
                                <tr>
                                    <td width="10%"></td>
                                    <td WIDTH="95%"className="myfontCardbody">Director Office</td>
                                </tr>
                                <tr>
                                    <td width="20%"><button className="mybutton-top" ><i className="fa fa-history fa-1x" aria-hidden="true"></i></button></td>
                                    <td WIDTH="95%" className="myfontCardbody">9 Yrs Experience</td>
                                </tr>
                                <tr>
                                    <td width="20%"><button className="mybutton-top" ><i className="fa fa-envelope fa-1x" aria-hidden="true"></i></button></td>
                                    <td WIDTH="95%" className="myfontCardbody">patel.s@gov.in</td>
                                </tr>
                                
                            </tbody></table>
                        </div>
                    </div>
                </div>
                </div>
            </div>
            <div className="col-md-4">
                <div className="panel panel-primary">
                <div className="panel-body">
                <div className="row">
                        <div className="col-md-4">
                            <img src='../assests/images/TechnicalStaffImg/Tejal.jpg' width="130px" height="160px;"></img>
                            
                        </div>
                        
                        <div className="col-md-8 carddata">
                            <table><tbody>
                                <tr>
                                    <td width="20%"><button className="mybutton-top" ><i className="fa fa-user fa-1x" aria-hidden="true"></i></button></td>
                                    <td WIDTH="95%" className="myfontCard">TEJAL G MEHTA</td>
                                </tr>
                                <tr>
                                    <td width="20%"></td>
                                    <td WIDTH="95%"className="myfontCardbody">Sr. Technician-1</td>
                                </tr>
                                <tr>
                                    <td width="10%"></td>
                                    <td WIDTH="95%"className="myfontCardbody">Chemical Sciences</td>
                                </tr>
                                <tr>
                                    <td width="20%"><button className="mybutton-top" ><i className="fa fa-history fa-1x" aria-hidden="true"></i></button></td>
                                    <td WIDTH="95%" className="myfontCardbody">8 Yrs Experience</td>
                                </tr>
                                <tr>
                                    <td width="20%"><button className="mybutton-top" ><i className="fa fa-envelope fa-1x" aria-hidden="true"></i></button></td>
                                    <td WIDTH="95%" className="myfontCardbody">manvar.t@gov.in</td>
                                </tr>
                                
                            </tbody></table>
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
        <div className="row row1">
            <div className="col-md-4">
                <div className="panel panel-primary">
                <div className="panel-body">
                    <div className="row">
                        <div className="col-md-4">
                            <img src='../assests/images/TechnicalStaffImg/vishal.jpg' width="135px" height="160px;"></img>
                            
                        </div>
                        
                        <div className="col-md-8 carddata">
                            <table><tbody>
                                <tr>
                                    <td width="20%"><button className="mybutton-top" ><i className="fa fa-user fa-1x" aria-hidden="true"></i></button></td>
                                    <td WIDTH="95%" className="myfontCard">Vishal Vallabh Nagose</td>
                                </tr>
                                <tr>
                                    <td width="20%"></td>
                                    <td WIDTH="95%"className="myfontCardbody">Sr. Technician-1</td>
                                </tr>
                                <tr>
                                    <td width="10%"></td>
                                    <td WIDTH="95%"className="myfontCardbody">Health sciences</td>
                                </tr>
                                <tr>
                                    <td width="20%"><button className="mybutton-top" ><i className="fa fa-history fa-1x" aria-hidden="true"></i></button></td>
                                    <td WIDTH="95%" className="myfontCardbody">3 Yrs Experience</td>
                                </tr>
                                <tr>
                                    <td width="20%"><button className="mybutton-top" ><i className="fa fa-envelope fa-1x" aria-hidden="true"></i></button></td>
                                    <td WIDTH="95%" className="myfontCardbody">nagose.vv@icmr.gov.in</td>
                                </tr>
                                
                            </tbody></table>
                        </div>
                    </div>
                </div>
                </div>
            </div>
            <div className="col-md-4">
                <div className="panel panel-primary">
                <div className="panel-body">
                <div className="row">
                        <div className="col-md-4">
                            <img src='../assests/images/TechnicalStaffImg/dharati.jpg' width="135px" height="160px;"></img>
                            
                        </div>
                        
                        <div className="col-md-8 carddata">
                            <table><tbody>
                                <tr>
                                    <td width="20%"><button className="mybutton-top" ><i className="fa fa-user fa-1x" aria-hidden="true"></i></button></td>
                                    <td WIDTH="95%" className="myfontCard">Dharati B Parmar</td>
                                </tr>
                                <tr>
                                    <td width="20%"></td>
                                    <td WIDTH="95%"className="myfontCardbody">Sr. Technician-1</td>
                                </tr>
                                <tr>
                                    <td width="10%"></td>
                                    <td WIDTH="95%"className="myfontCardbody">Biological sciences</td>
                                </tr>
                                <tr>
                                    <td width="20%"><button className="mybutton-top" ><i className="fa fa-history fa-1x" aria-hidden="true"></i></button></td>
                                    <td WIDTH="95%" className="myfontCardbody">7 Yrs Experience</td>
                                </tr>
                                <tr>
                                    <td width="20%"><button className="mybutton-top" ><i className="fa fa-envelope fa-1x" aria-hidden="true"></i></button></td>
                                    <td WIDTH="95%" className="myfontCardbody">parmar.ds@gov.in</td>
                                </tr>
                                
                            </tbody></table>
                        </div>
                    </div>
                </div>
                </div>
            </div>
            <div className="col-md-4">
                <div className="panel panel-primary">
                <div className="panel-body">
                <div class="row">
                    <div class="col-md-4">
                        <img src='../assests/images/TechnicalStaffImg/Shabrin.jpeg' width="130px" height="160px;"></img>
                        
                    </div>
                    
                    <div class="col-md-8 carddata">
                        <table>
                            <tr>
                                <td width="20%"><button class="mybutton-top" ><i class="fa fa-user fa-1x" aria-hidden="true"></i></button></td>
                                <td WIDTH="95%" CLASS="myfontCard">Shabrin Fathima</td>
                            </tr>
                            <tr>
                                <td width="20%"></td>
                                <td WIDTH="95%"CLASS="myfontCardbody">Technician - C</td>
                            </tr>
                            <tr>
                                <td width="10%"></td>
                                <td WIDTH="95%"CLASS="myfontCardbody">Establishment Section</td>
                            </tr>
                            <tr>
                                <td width="20%"><button class="mybutton-top" ><i class="fa fa-history fa-1x" aria-hidden="true"></i></button></td>
                                <td WIDTH="95%" CLASS="myfontCardbody">4 Yrs Experience</td>
                            </tr>
                            <tr>
                                <td width="20%"><button class="mybutton-top" ><i class="fa fa-envelope fa-1x" aria-hidden="true"></i></button></td>
                                <td WIDTH="95%" CLASS="myfontCardbody">fathima.s@icmr.gov.in </td>
                            </tr>
                            
                        </table>
                    </div>
                </div>
                </div>
                </div>
            </div>
        </div>
        <div className="row row1">
            <div className="col-md-4">
                <div className="panel panel-primary">
                <div className="panel-body">
                    <div className="row">
                        <div className="col-md-4">
                            <img src='../assests/images/TechnicalStaffImg/jitendra.jpeg' width="135px" height="160px;"></img>
                            
                        </div>
                        
                        <div className="col-md-8 carddata">
                            <table><tbody>
                                <tr>
                                    <td width="20%"><button className="mybutton-top" ><i className="fa fa-user fa-1x" aria-hidden="true"></i></button></td>
                                    <td WIDTH="95%" className="myfontCard">Jitendra Parmar</td>
                                </tr>
                                <tr>
                                    <td width="20%"></td>
                                    <td WIDTH="95%"className="myfontCardbody">Technician - C</td>
                                </tr>
                                <tr>
                                    <td width="10%"></td>
                                    <td WIDTH="95%"className="myfontCardbody">Animal House</td>
                                </tr>
                                <tr>
                                    <td width="20%"><button className="mybutton-top" ><i className="fa fa-history fa-1x" aria-hidden="true"></i></button></td>
                                    <td WIDTH="95%" className="myfontCardbody">7 Yrs Experience</td>
                                </tr>
                                <tr>
                                    <td width="20%"><button className="mybutton-top" ><i className="fa fa-envelope fa-1x" aria-hidden="true"></i></button></td>
                                    <td WIDTH="95%" className="myfontCardbody">parmar.jg@gov.in</td>
                                </tr>
                                
                            </tbody></table>
                        </div>
                    </div>
                </div>
                </div>
            </div>
            <div className="col-md-4">
                <div className="panel panel-primary">
                <div className="panel-body">
                <div className="row">
                        <div className="col-md-4">
                            <img src='../assests/images/TechnicalStaffImg/himmat.jpg' width="135px" height="160px;"></img>
                            
                        </div>
                        
                        <div className="col-md-8 carddata">
                            <table><tbody>
                                
                                <tr>
                                    <td width="20%"><button className="mybutton-top" ><i className="fa fa-user fa-1x" aria-hidden="true"></i></button></td>
                                    <td WIDTH="95%" className="myfontCard">Himmat N Makwana</td>
                                </tr>
                                <tr>
                                    <td width="20%"></td>
                                    <td WIDTH="95%"className="myfontCardbody">Technician - C</td>
                                </tr>
                                <tr>
                                    <td width="10%"></td>
                                    <td WIDTH="95%"className="myfontCardbody">Establishment Section</td>
                                </tr>
                                <tr>
                                    <td width="20%"><button className="mybutton-top" ><i className="fa fa-history fa-1x" aria-hidden="true"></i></button></td>
                                    <td WIDTH="95%" className="myfontCardbody">4 Yrs Experience</td>
                                </tr>
                                <tr>
                                    <td width="20%"><button className="mybutton-top" ><i className="fa fa-envelope fa-1x" aria-hidden="true"></i></button></td>
                                    <td WIDTH="95%" className="myfontCardbody">makwana.hn@icmr.gov.in</td>
                                </tr>
                               </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                </div>
            </div>
           <div className="col-md-4">
                <div className="panel panel-primary">
                <div className="panel-body">
                <div className="row">
                        <div className="col-md-4">
                            <img src='../assests/images/TechnicalStaffImg/sanju.jpg' width="135px" height="160px;"></img>
                            
                        </div>
                        
                        <div className="col-md-8 carddata">
                            <table><tbody>
                                
                                <tr>
                                    <td width="20%"><button className="mybutton-top" ><i className="fa fa-user fa-1x" aria-hidden="true"></i></button></td>
                                    <td WIDTH="95%" className="myfontCard">Sanjeev Kumar</td>
                                </tr>
                                <tr>
                                    <td width="20%"></td>
                                    <td WIDTH="95%"className="myfontCardbody">Technician - C</td>
                                </tr>
                                <tr>
                                    <td width="10%"></td>
                                    <td WIDTH="95%"className="myfontCardbody">Biological Science</td>
                                </tr>
                                <tr>
                                    <td width="20%"><button className="mybutton-top" ><i className="fa fa-history fa-1x" aria-hidden="true"></i></button></td>
                                    <td WIDTH="95%" className="myfontCardbody">9 Yrs Experience</td>
                                </tr>
                                <tr>
                                    <td width="20%"><button className="mybutton-top" ><i className="fa fa-envelope fa-1x" aria-hidden="true"></i></button></td>
                                    <td WIDTH="95%" className="myfontCardbody">kumar.sa@gov.in</td>
                                </tr>
                               </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
        
        
        <div className="col-md-12">
                <center>
                <Link to="/TechNioh"><button className="btn btn-primary" style={{fontSize:"19px", width:"35px", marginRight :"10px"}}>1</button></Link>
                <Link to="/othertech"><button className="btn btn-primary" style={{fontSize:"19px", width:"35px"}}>2</button></Link>
                   
                    <Routes>
                        <Route exact path='/othertech' element={<Othertech />}></Route> 
                    </Routes>
                </center>
            </div>
            </div>

        </div>);
}
export default TechNioh;