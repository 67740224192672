import React from 'react';
import '../carousel.css';
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import NavbarMenu from '../NavbarMenu.js';
function DrAnkit() {
    return (
      <div> <NavbarMenu /><div class="manual">
        <div class="row container">
        <p class="mypathhead">
        <Link to="/"><span class="mypath">Home</span></Link>&gt;<Link to="/ScientistNIOH"><span class="mypath">Scientist's Profile</span></Link>&gt;<span class="mypath">NIOH Ahmedabad</span>
        </p>
          
        </div>
        <h1 class="mypathhead">Scientist's Detail</h1>
        <div class="scientistdetail">
      
            <div class="panel my-panel" >
      <div class="panel-heading my-panel-head" align="left"><strong>Basic Details</strong></div>
      <div class="panel-body" align="left">
      <table width="100%">
        <thead>
          <th width="28%"></th>
          <th width="50%"></th>
          <th width="22%"></th>
        </thead>
        <tbody>
          <tr>
              <td  rowspan="5"><img src="../assests/images/niohScientist/AnkitS.JPG" width="170px" height="170px" CLASS="dg-img"/></td>
              <td  ><p class="myfontSci trSci"> Dr Ankit Mayurkumar Sheth</p> </td>
            </tr>
            
            <tr>
              <td class="myfontSci"><p class="myfontSci">Health Sciences Division, Division Head </p></td>
             </tr>
            <tr>
              <td><p ><a href=""><i class="fa fa-envelope" aria-hidden="true"></i>   Email: ankit.sheth@icmr.gov.in</a></p></td>
            </tr> 
            <tr>
              <td><p ><a href=""> <i class="fa fa-phone-square"></i>  Contact: 079-22688703</a></p></td>
            </tr>   
        </tbody>
           
            
            </table>
      </div>
    </div>
       
        <div class="panel my-panel" >
      <div class="panel-heading my-panel-head" align="left"><strong>Academic Qualification</strong></div>
      <div class="panel-body" align="left">MBBS, MD (Community Medicine), PGD (Health Management & Administration)</div>
    </div>
    <div class="panel my-panel" >
      <div class="panel-heading my-panel-head" align="left"><strong>Research Interest </strong></div>
      <div class="panel-body" align="left">Occupational Safety & Health Management, Prevention of work related diseases, Health Hazards Evaluation, Physical Ergonomics, Work and mental Health, Air pollution and Health</div>
    </div>
    <div class="panel my-panel" >
      <div class="panel-heading my-panel-head" align="left"><strong>Membership/Affliation </strong></div>
      <div class="panel-body" align="left">
      <ul><li>Life Member of Indian Medical Association</li>
<li>Life Member of Indian Association of Preventive and Social Medicine</li>
<li>Life Member of Epidemiological Foundation of India</li>
</ul>
        </div>
        </div>
        <div class="panel my-panel">
      <div class="panel-heading my-panel-head" align="left"><strong>Fellowship/Awards </strong></div>
      <div class="panel-body" align="left"><ul><li>IAPSM Presidential Appreciation Award” conferred by IAPSM for contributions made to IAPSM and excellent work done in the field of public Health </li>
</ul>
</div>
</div>
<div class="panel my-panel">
      <div class="panel-heading my-panel-head" align="left"><strong>Publications </strong></div>
      <div class="panel-body" align="left">
	
      <ol >
	  <li><strong>Sheth AM</strong>, Jadav PA. Prevalence and factors affecting hypertension among old age population in rural area. Int J Community Med Public Health. 2016;3(7):1866-1871</li>
	<br/>
	<li><strong>Sheth AM</strong>, Rangoonwala MM, Lodhiya KK, Zalavadiya DD, Joshi NB. A study on awareness and practice regarding Vitamin A intake and their deficiency disorders among mothers of pre-school children in Khirasara village, Rajkot, Gujarat. Ntl J Community Med 2016;7(6):505-509</li>
	<br/>
	<li><strong>Sheth AM</strong>, Jani DS, Rangoonwala MM, Kadri AM. Assessing the awareness and practice of Hospital Acquired Infections (HAIs) among nursing staff of Civil Hospital, Rajkot, Gujarat, India. Int J Res Med Sci. 2015;3(8):1844-1850</li>
	<br/>
	<li>Zalavadiya DD, Banerjee A, Joshi NB, Bhola CN, <strong>Sheth AM</strong>. A Comparative Study of Morbidity Profile of Elderly Residing in Old Age Homes and in the Community of a Tier-II City in India. Natl J Community Med 2018;9(7):480-485</li>
	<br/>
	<li>Zalavadiya DD, Banerjee A, <strong>Sheth AM</strong>, Rangoonwala M, Mitra A, Kadri AM. A comparative study of depression and associated risk factors among elderly inmates of old age homes and community of Rajkot: A Gujarati version of the geriatric depression scale-short form (GDS-G). Indian J Community Med 2017;42:204-8</li>
	<br/>
	<li>Modi B, <strong>Sheth A</strong>. Evaluation of Public Private Partnership Model for Newborn Care Services at Trust Hospitals in High Priority Talukas of Gujarat, India. Ntl J Community Med. 2016;7(8):716-21</li>
	<br/>
	<li>Chudasama RK, Lakkad SG, Patel UV, <strong>Sheth A</strong>, Thakkar D, Rangoonwala M. Evaluation of national leprosy eradication program after integration into general Health system in Rajkot district, Gujarat from 2003 to 2014. Indian J Dermatol. 2016;61:57‑62</li>
	<br/>
	<li>Pandya VP, Joshi IV, <strong>Sheth AM</strong>, Kadri AM. Studying the effectiveness of mixed models of community based and institutional based interventions for management of severe acute malnourished children. Int J Res Med Sci. 2015;3(7):1758-1764</li>
	<br/>
	<li>Chudasama RK, Kadri AM, Verma PB, Vala MC, Rangoonwala MM, <strong>Sheth AM</strong>. Evaluation of nutritional and other activities at Anganwadi centers under integrated child development services program in different districts of Gujarat, India. Journal of Medical Nutrition and Nutraceuticals. 2015;4(2):101-106</li>
	<br/>
	<li>Chudasama RK, Patel UV, Verma PB, Vala MC, Rangoonwala MM, <strong>Sheth AM</strong>. Evaluation of Anganwadi centres performance under Integrated Child Development Services (ICDS) program in Gujarat State, India during year 2012-13. J Mahatma Gandhi Inst Med Sci. 2015;20:60-5</li><br/>
	<li>Viramgami AP, Vala MC, Sharma S, <strong>Sheth AM</strong>, Ninama R, Verma PB. Study of socio-demographic profile of malnourished children residing in urban slums of Rajkot city, Gujarat, India. Int J Res Med. 2014;3(2):53-56</li><br/>
	<li>Kadri AM, Veravadiya CL, <strong>Sheth AM</strong>, Rangoonwala MM, Pathak VH. An epidemiological investigation of outbreak of Project Malaria in PHC area of Rajkot district, Gujarat, India, 2014. Healthline. 2014;5(2):53-59</li><br/>
	<li>Zalavadiya DD, Joshi NB, Vala MC, Bhola CN, <strong>Sheth AM</strong>, Rangoonwala MM. Assessment of status of internet addiction and related factors among medical students of Rajkot city. Healthline. 2014;5(1):19-23</li><br/>
	<br/>
	
</ol>
	
</div>	
	
</div>
</div>

        </div>
    </div>
    );
}
export default DrAnkit;