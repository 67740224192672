import React from 'react';
import '../carousel.css';
import NavbarMenu from '../NavbarMenu.js';
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
function DrSukhdev() {
    return (
      <div> <NavbarMenu /><div class="manual">
        <div class="row container">
        <p class="mypathhead">
        <Link to="/"><span class="mypath">Home</span></Link>&gt;<Link to="/ScientistNIOH"><span class="mypath">Scientist's Profile</span></Link>&gt;<span class="mypath">NIOH Ahmedabad</span>
        </p>
          
        </div>
        <h1 class="mypathhead">Scientist's Detail</h1>
        <div class="scientistdetail">
      
            <div class="panel my-panel" >
      <div class="panel-heading my-panel-head" align="left"><strong>Basic Details</strong></div>
      <div class="panel-body" align="left">
      <table width="100%">
            <tr>
              <td width="28%" rowspan="5"><img src="../assests/images/niohScientist/sdmishra.jpg" width="170px" height="170px" CLASS="dg-img"/></td>
              <td width="50%" ><p class="myfontSci trSci">Dr Sukhdev Mishra</p> </td>
              <td width="22%" ></td>
            </tr>
            
            <tr>
              
              <td class="myfontSci"><p class="myfontSci">Health sciences Division </p></td>
             </tr>
            <tr>
              <td><p ><a href=""><i class="fa fa-envelope" aria-hidden="true"></i>   Email: sd.Mishra@gov.in</a></p></td>
            </tr> 
            <tr>
              <td><p ><a href=""> <i class="fa fa-phone-square"></i>  Contact: 079-22688843</a></p></td>
            </tr>   
           
            </table>    
      </div>
    </div>
       
        <div class="panel my-panel" >
      <div class="panel-heading my-panel-head" align="left"><strong>Academic Qualification</strong></div>
      <div class="panel-body" align="left">B.Sc (Maths, Stats, Computer Sc.),M.Stat (Statistics),Ph.D(Statistics)
</div>
    </div>
    <div class="panel my-panel" >
      <div class="panel-heading my-panel-head" align="left"><strong>Research Interest </strong></div>
      <div class="panel-body" align="left"><ul><li>Applied Statistics, Biostatistics, Occupational epidemiology, Clinical Trials, Geo-spatial Statistics, Geographical Information System(GIS)</li> 
</ul></div>
    </div>
    <div class="panel my-panel" >
      <div class="panel-heading my-panel-head" align="left"><strong>Membership/Affliation </strong></div>
      <div class="panel-body" align="left">
      <ul>
	  <li>Life member of International Indian Statistical Association (IISA)</li>
<li>Life Member of  Indian Society of  Medical Statistics(ISMS)</li>


</ul>
</div>
</div>
<div class="panel my-panel">
      <div class="panel-heading my-panel-head" align="left"><strong>Fellowship/Awards </strong></div>
      <div class="panel-body" align="left"><ul>
      <li>	ICMR - International Fellowship for Biomedical Scientists 2018-19 </li>

<li>	Qualified UGC-NET </li>

<li>	ICMR-NIOH Scientist Award for Scientific contributions made during year 2019</li>

<li>	Received award “For Making Difference and Driving •Excellence” by Ranbaxy Laboratories Ltd. for departmental work performance, 2011 </li>


</ul>
</div>
</div>
<div class="panel my-panel">
      <div class="panel-heading my-panel-head" align="left"><strong>Publications </strong></div>
      <div class="panel-body" align="left">
	
      <ol>

      <li>1.	Verma, S., Mehta, F., <strong>Mishra, S.</strong>, Mohamed, R. N., Parekh, H. K. A., Sokhi, R. K., . . . Alam, M. K. (2021).Anthropometric and Physiologic Parameters in Cleft Neonates: A Hospital-Based Study. Children, 8(10). IF = 2.8</li>
<br/>
<li>2.	Verma, D., Srivastava, A., Garg, P. K., Akhter, Y., Dubey, A. K., <strong>Mishra, S.</strong>, &Deo, S. (2021). Taxonomic profiling and functional characterization of the Healthy human oral bacterial microbiome from the north Indian urban sub-population. Archives of Microbiology, 203(3), 927-939. IF = 2.5</li>
<br/>
<li>3.	Srivastava, A., <strong>Mishra, S.</strong>, &Verma, D. (2021). Characterization of Oral Bacterial Composition of Adult Smokeless Tobacco Users from Healthy Indians Using 16S rDNA Analysis. Microbial Ecology, 82(4), 1061-1073. IF = 4.5</li>
<br/>
<li>4.	<strong>Mishra, S.</strong>, & Sarkar, K. (2021). Work-related musculoskeletal disorders and associated risk factors among urban metropolitan hairdressers in India. Journal of occupational Health, 63(1), e12200.  IF = 2.8</li>
<br/>
<li>5.	Dutta, A., Sharma, A., Torres-Castro, R., Pachori, H., & <strong>Mishra, S.</strong> (2021). Mental Health outcomes among Health-care workers dealing with COVID-19/severe acute respiratory syndrome coronavirus 2 pandemic: A systematic review and meta-analysis. Indian Journal of Psychiatry, 63(4), 335. IF = 1.7</li>


</ol>
	
</div>
	
</div>
</div>

        </div>
    </div>
    );
}
export default DrSukhdev;