import React from 'react';
import '../carousel.css';
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import NavbarMenu from '../NavbarMenu.js';
function DrNibedita() {
    return (
      <div> <NavbarMenu /> <div class="manual">
        <div class="row container">
        <p class="mypathhead">
        <Link to="/"><span class="mypath">Home</span></Link>&gt;<Link to="/ScientistNIOH"><span class="mypath">Scientist's Profile</span></Link>&gt;<span class="mypath">NIOH Ahmedabad</span>
        </p>
          
        </div>
        <h1 class="mypathhead">Scientist's Detail</h1>
        <div class="scientistdetail">
      
            <div class="panel my-panel" >
      <div class="panel-heading my-panel-head" align="left"><strong>Basic Details</strong></div>
      <div class="panel-body" align="left">
      <table width="100%">
            <tr>
              <td width="28%" rowspan="5"><img src="../assests/images/niohScientist/nnaha.png" width="170px" height="170px" CLASS="dg-img"/></td>
              <td width="50%" ><p class="myfontSci trSci">Dr Nibedita Naha</p> </td>
              <td width="22%" ></td>
            </tr>
            
            <tr>
              
              <td class="myfontSci"><p class="myfontSci">Biological sciences Division </p></td>
             </tr>
            <tr>
              <td><p ><a href=""><i class="fa fa-envelope" aria-hidden="true"></i>   Email: naha.n@gov.in</a></p></td>
            </tr> 
            <tr>
              <td><p ><a href=""> <i class="fa fa-phone-square"></i>  Contact: 079-22688737</a></p></td>
            </tr>   
           
            </table>  
      </div>
    </div>
       
        <div class="panel my-panel" >
      <div class="panel-heading my-panel-head" align="left"><strong>Academic Qualification</strong></div>
      <div class="panel-body" align="left"><ul><li>PhD (Jadavpur University, India)</li><br/>

<li> Post Doctorate (Gyeongsang National University, South
Korea)</li><br/>
<li> Diploma & Certificate course: UNIX & C, DOA, DTP (University
of Calcutta & Govt. of West Bengal, India)</li><br/>
</ul></div>
    </div>
    <div class="panel my-panel" >
      <div class="panel-heading my-panel-head" align="left"><strong>Research Interest </strong></div>
      <div class="panel-body" align="left"><ul><li>Mechanistic approach of carcinogenic/neurotoxic substances
of occupational/environmental/public health interest
</li><br/><li>Studies of biomarkers of different occupational exposure with respect to public Health interest
</li><br/><li> Reproductive toxicology with regard to molecular signalling of occupational / environmental toxicants
</li><br/><li> Gene therapy for management of diseases in pre-clinical models</li></ul></div>
    </div>
    <div class="panel my-panel" >
      <div class="panel-heading my-panel-head" align="left"><strong>Membership/Affliation </strong></div>
      <div class="panel-body" align="left">
      <ul>
	  <li>Executive Committee Member (since 2013 re-elected),
Physiological Society of India (PSI)
 </li><br/><li>Life Member, Indian Society for the Study of Reproduction and Fertility (ISSRF)
</li><br/><li> Life Member, Society for Reproductive Biology &
Comparative Endocrinology (SRBCE)
</li><br/><li> Life Member, National Environmental Science
Academy (NESA)
</li><br/><li> Life Member, Indian Science News Association (ISNA)
</li><br/><li> Life Member, Indian Science Congress Association
(ISCA)
</li><br/><li> Life Member, Physiological Society of India (PSI)
</li><br/><li> Regular Member, Society for Neuroscience (SfN), USA
</li><br/><li> Regular Member, American Association for the
Advancement of Science (AAAS), USA
</li><br/><li> CPCSEA Main Nominee / External Nominee of Several
Research Institute / University / Pharmaceutical Co.
</li><br/><li> IBSC-DBT Nominee in Biotechnology research centre
</li><br/><li> IHEC Chairperson in Ayurvedic College
</li><br/><li> RDC Member & PhD Guide affiliation in Gujarat
University
</li><br/><li> RDC Member, Gujarat Forensic Science University
University
</li><br/><li> Board of Studies Member, Gujarat Forensic Science University
University
</li><br/><li> Reviewer of International SCI-journals (USA & NZ)
</li><br/><li> Reviewer, CRG program (SERB)
</li><br/><li> Reviewer, Inspire program (DST)
</li><br/><li> Editorial Board Member, Annals of Cytology and
Pathology (India); & Indian Journal of Diabetes and
Endocrinology
</li><br/><li> Editorial Board Member, Indian Journal of Diabetes and
Endocrinology
</li><br/><li> Advisory Board Member, ICMR & DST-sponsored
National Conference on Climate change & human
Health, 2018 (GDC, J&K)
</li><br/><li> Advisory Board Member, ICAR, NESA & DRDOsponsored
National Conference on Food security issues
& environmental challenges for Indian agriculture in
the next decades, 2016 (Punjab University, Chandigarh)
</li>
</ul>
</div>
</div>
<div class="panel my-panel">
      <div class="panel-heading my-panel-head" align="left"><strong>Fellowship/Awards </strong></div>
      <div class="panel-body" align="left"><ul><li> Founder-Fellow Award, Physiological Society of India (PSI)
</li><br/><li> ICMR- NIOH Appreciation Award in recognition for scientific
commitment & dedicated service
</li><br/><li>  Cell Biologist Congress, Japan
</li><br/><li>  Society for Neuroscience (SfN) Award, USA
</li><br/><li>  BK 21 Post Doc Fellow, MOEST, Govt. of South Korea
</li><br/><li>  BK 21 Project Presentation Award, MOEST, Govt. of South
Korea
</li><br/><li>  PB Sen Memorial Gold Medal, Physiological Society of India
</li><br/><li>  Young Scientist Award, Indian Science Congress Association
</li><br/><li>  Best Oral Presentation Award, Physiological Society of India
</li><br/><li>  Research Fellowship, DST, Govt. of West Bengal
</li><br/><li> National Scholarship, DDPI, Govt. of West Bengal</li>

</ul>
</div>
</div>
<div class="panel my-panel">
      <div class="panel-heading my-panel-head" align="left"><strong>Publications </strong></div>
      <div class="panel-body" align="left">
	
      <ol>

<li><strong>Naha N*</strong>. Molecular network for management of
neurodegenerative diseases and its translational importance
using animal biotechnology as a tool in pre-clinical Studies.
Chapter 14. Advances in Animal Genomics. Elsevier, USA. Pp
219-235, 2021. (ISBN: 978-0-12-820595-2; Invited book
chapter; *Corresponding author)
</li><br/><li> Shrimali V, <strong>Naha N*</strong>, Mondal S. Climate change and livestock
fertility. Chapter 12. Climate Change and Fertility Potential.
IGI-Global, USA. Pp 241-262, 2021. (ISBN: 978-1-7998-4480-8;
Invited book chapter; *Corresponding author)

</li><br/><li>  <strong>Naha N*</strong>, Manickavachagam G. Textile industry and health
hazards on climate change and fertility potential. Chapter 3.
Climate Change and Fertility Potential. IGI-Global, USA. Pp 42-
69, 2021. (ISBN: 978-1-7998-4480-8; Invited book chapter;
*Corresponding author)
</li><br/><li>  <strong>Naha N</strong>, Jaseer MCJ, Pagdhune A, Sarkar B, Sarkar K. Club cell
protein could be used as a biomarker for early detection of
silicosis. Ind J Med Res, 151 (4): 319-325, 2020. (If: 1.25; doi:
10.4103/ijmr.IJMR_1799_18; PMID: 32461395)
</li><br/><li>  <strong>Naha N*</strong>. Nicotine, tobacco smoking and alcoholism: Impact
on BDNF-Trk-β and dopamine-D2 receptor activity in brain.
Chapter 11. Environment and Society. Bazooka Publication,
New Delhi, India. Pp 200-230, 2019. (ISBN: 978-93-86895-67-
7; Invited book chapter; *Corresponding author)
</li><br/><li> <strong>Naha N*</strong>, Gandhi DN, Gautam AK, Ravi Prakash J. Nicotine and
cigarette smoke modulate Nrf2-BDNF-dopaminergic signal
and neurobehavioral disorders in adult rat cerebral cortex.
Hum Expt Toxicol, 37 (5): 540-556, 2018 (If: 1.8; doi:
10.1177/0960327117698543; PMID: 28641491;
*Corresponding author)
</li><br/><li> <strong>Naha N*</strong>. Tobacco smoking and nicotine modulate Nrf2-
BDNF-DA signaling in cerebral cortex and testis of adult rats
during addiction and withdrawal. Biochem Anal Biochem, 7:
35, 2018 (If: 2.63; doi: 10.4172/2161-1009-C1-017; Invited
research article; *Corresponding author)
</li><br/><li>  <strong>Naha N*</strong>, Das M, Banerjee A. Toxic exposure and life style
factors on ageing brain neurodegenerative disease,
Alzheimer’s and Parkinson’s: role of natural antioxidants to
ameliorate the condition. J Alcohol Drug Depend, 6 (2): 309-
317, 2018 (If 1.57; doi: 10.4172/2329-6488.1000309; Invited
Review; *Corresponding author)
</li><br/><li>  <strong>Naha N*</strong>, Desai R, Fernandez JRD, Banerjee A. Globalization,
obesity and polycystic ovary syndrome: What we pay? Austin

Endocrin Diab Case Rep, 2 (1): 1010-1018, 2017 (Peer-
reviewed journal; Invited Review; *Corresponding author)

</li><br/><li>  <strong>Naha N*</strong>, Gandhi DN, Gautam AK, Patel S. Involvement of

neurotransmitter and Nrf2 in nicotine- and cigarette smoke-
induced testicular toxicity in adult rat. Reprod Biol Insight, 9

(1): 5-17, 2016 (Peer reviewed journal;
doi:10.4137/RBI.S40225; *Corresponding author)
</li><br/><li> Majumder J, Bagepally BS, Shah P, Kotadiya S, Yadav S, <strong>Naha
N</strong>. Comparison of workers’ perception towards work climate
and health symptoms between ceramic and iron foundry
workers. Ind J Occup Environ Medicine, 20 (1): 48-53, 2016 (If
1.06; doi: 10.4103/0019-5278.183845; PMID: 27390480)
</li><br/><li>  <strong>Naha N*</strong>. Lead and male reproduction: Impact of workplace
exposure. ISSRF News Lett, 18 (1): 54-61, 2016. (ISSN: 2395-

2806; Peer reviewed journal; Invited Review; *Corresponding
author)
</li><br/><li>  <strong>Naha N</strong>, Kumar S. Impact of lifestyle factors on deterioration
of reproductive health in human. ISSRF News Lett, 14 (1): 6-
11, 2014. (Peer reviewed journal; Invited Review)
</li><br/><li>  <strong>Naha N</strong>, Li SP, Yang BC, Park TJ, Kim MO. Time-dependent
exposure of nicotine and smoke modulate ultrasubcellular
organelle localization of dopamine D1 and D2 receptors in the
rat caudate-putamen. Synapse, 63 (10): 847-854, 2009. (If
3.22; doi: 10.1002/syn.20662)
</li><br/><li>  <strong>Naha N</strong>, Lee HY, Hwang JS, Bahk JY, Park MS, Lee SY, Kim SH,
Kim MO. Nicotine Tolerance to PC12 Cell Line: Acute and
Chronic Exposure Modulate Dopamine D2 Receptor and
Tyrosine Hydroxylase Expression. Neurol Res, 31 (3): 289-299,
2009. (If 1.638; doi: 10.1179/174313209X382403;
PMID: 19406037)
</li><br/><li>  Das A, <strong>Naha N</strong>, Chang KC. Targeted necrosis in P-53 mutant
brain metastatic cell line: an alternative death pathway. J
Neurochem, 110 (S2): 233, 2009. (If 3.842; ISN/APSN Spl. issue
publication)
</li><br/><li>  Kim MO, Naseer M, Lee HY, <strong>Naha N</strong>. Maternal and in vitro
ethanol modulated GABAB1 receptor downstream and
apoptosis pathway: effect of siRNA in prenatal brain. J
Neurochem, 110 (S2): 172-173, 2009. (If 3.842; ISN/APSN Spl.
issue publication)
</li><br/><li>  <strong>Naha N</strong>, Lee HY, Naser MI, Park TJ, Kim SH, Kim MO. Ethanol
inhibited apoptosis-related RNA binding protein, Napor-3
gene expression in the prenatal rat brain. Med Sci Monit, 15
(1): BR 6-12, 2009. (If 1.607; PMID: 19114963)
</li><br/><li> <strong>Naha N*</strong>, Lee HY, Jo MJ, Chung BC, Kim SH, Kim MO*. Rare
sugar (D)-allose induces programmed cell death in hormone
refractory prostate cancer cells. Apoptosis, 13 (9): 1121-1134,
2008. (If 4.497; doi: 10.1007/s10495-008-0232-7;
PMID: 18622705; Patent: PCT/KR 2008/007320, 10-12-2008;
*Corresponding authors)
</li><br/><li>  Lee HY*, <strong>Naha N*</strong>, Li S, Jo MJ, Naseer ML, Park MS, Park TJ,
Chung BC, Kim MO. In vivo and in vitro ethanol exposure in
prenatal rat brain: GABAB receptor modulation on dopamine
D1 receptor and protein kinase A. Synapse, 62 (7): 534-543,
2008. (*Equal contribution; If 3.22; doi: 10.1002/syn.20522)
</li><br/><li>  Lee HY*, <strong>Naha N*</strong>, Ullah N, Jin GZ, Kong IK, Koh PO, Seong HH,
Kim MO. Effect of the co-administration of vitamin C and
vitamin E on tyrosine hydroxylase and Nurr1 expression in the
prenatal rat ventral mesencephalon. J Vet Med Sci, 70 (8): 791-
797, 2008. (*Equal contribution; If 0.663; doi:
10.1292/jvms.70.791; PMID: 18772553)

</li><br/><li>  Lee HY*, <strong>Naha N*</strong>, Kim JH, Jo M, Min KS, Seong HH, Shin DH,
Kim MO. Age and area dependent distinct effects of ethanol
on the bax and bcl-2 expression of the prenatal rat brain. J
Microbiol Biotechnol, 18 (9): 1590-1598, 2008. (*Equal
contribution; If 2.062; PMID: 18852517)
</li><br/><li> <strong>Naha N*</strong>, Manna B: Mechanism of lead induced effects on
human spermatozoa after occupational exposure. Kathmandu
Univ Med J, 5 (1): 85-94, 2007. (Peer reviewed journal; PMID:
18603992; *Corresponding author)
</li><br/><li>  <strong>Naha N*</strong>, Roy Chowdhury A: Inorganic lead exposure in
battery and paint factory: effect on human sperm structure
and functional activity. J Univ Occup Environ Health (Japan),
28 (2): 157-171, 2006. (If 1.5; doi: 10.7888/juoeh.28.157;
PMID: 16780224; *Corresponding author)
</li><br/><li>  <strong>Naha N*</strong>, Sa GS, Ganesan V, Bhar R, Roy Chowdhury A: Is
occupational lead exposure detrimental to human
spermatozoa? A question of recent concern. J Coll Med Sci, 2
(1): 105-110, 2005. (Peer reviewed journal; *Corresponding
author)
</li><br/><li>  <strong>Naha N</strong>, Bhar RB, Mukherjee A, Roy Chowdhury A: Structural
alteration of spermatozoa in the persons employed in lead
acid battery factory. Ind J Physiol Pharmacol, 49 (2): 153-162,
2005. (If 3.31; PMID: 16170983)
</li><br/><li>  <strong>Naha N</strong>, Roy Chowdhury A: Toxic effect of lead on human
spermatozoa: a study among pigment factory workers. Ind J
Occup Environ Med, 9 (3): 118-123, 2005. (If 1.06; ISSN: 0973-
2284)
</li><br/><li> <strong>Naha N</strong>, Roy Chowdhury A: Morphological abnormalities of
spermatozoa in workers exposed to lead fumes and dust in the
factory. Ind J Physiol Allied Sci, 58 (3): 80-91, 2004. (Peer
reviewed journal)
</li><br/><li>  <strong>Naha N</strong>, Roy S, Guha T, Roy Chowdhury A: Lead induced
changes in seminal constituents of workers from battery and
paint factories in Kolkata. Ind Biol, 35 (1): 41-48, 2003. (Peer
reviewed journal)
</li><br/><li> Roy Chowdhury A, <strong>Naha N</strong>: Heavy metal induced toxicity in
male reproductive system. Ind J Toxicol, 9 (2): 61-67, 2002 (If
1.243; Review).
</li><br/><li> Roy Chowdhury A, <strong>Naha N</strong>: Toxic effect of industrial chemicals
on male reproductive system. Ind J Physiol Allied Sci, 56 (2):
47-60, 2002 (Peer reviewed journal; Review)</li>
</ol>
	
	
	
</div>
</div>

</div>  </div>
    </div>
    );
}
export default DrNibedita;