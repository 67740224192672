import React from 'react';
import '../carousel.css';
import NavbarMenu from '../NavbarMenu.js';
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
function DrRakesh() {
    return (
      <div> <NavbarMenu /><div class="manual">
        <div class="row container">
        <p class="mypathhead">
        <Link to="/"><span class="mypath">Home</span></Link>&gt;<Link to="/ScientistNIOH"><span class="mypath">Scientist's Profile</span></Link>&gt;<span class="mypath">NIOH Ahmedabad</span>
        </p>
          
        </div>
        <h1 class="mypathhead">Scientist's Detail</h1>
        <div class="scientistdetail">
      
            <div class="panel my-panel" >
      <div class="panel-heading my-panel-head" align="left"><strong>Basic Details</strong></div>
      <div class="panel-body" align="left">
      <table width="100%">
            <tr>
              <td width="28%" rowspan="5"><img src="../assests/images/niohScientist/rakeshb.jpg" width="170px" height="170px" CLASS="dg-img"/></td>
              <td width="50%" ><p class="myfontSci trSci">Dr B Rakesh</p> </td>
              <td width="22%" ></td>
            </tr>
            
            <tr>
              
              <td class="myfontSci"><p class="myfontSci">Health sciences Division </p></td>
             </tr>
            <tr>
              <td><p ><a href=""><i class="fa fa-envelope" aria-hidden="true"></i>   Email: rakesh.bal@icmr.gov.in</a></p></td>
            </tr> 
            <tr>
              <td><p ><a href=""> <i class="fa fa-phone-square"></i>  Contact: 079-22688753</a></p></td>
            </tr>   
           
            </table>   
      </div>
    </div>
       
        <div class="panel my-panel" >
      <div class="panel-heading my-panel-head" align="left"><strong>Academic Qualification</strong></div>
      <div class="panel-body" align="left">
      MBBS (RGUHS),PhD (NIMHANS) Clinical Neurosciences,Post doctoral fellow (University of Hamburg),Joint postdoctoral fellow (Simon Fraser University & University of British Columbia, Canada)
</div>
    </div>
    <div class="panel my-panel" >
      <div class="panel-heading my-panel-head" align="left"><strong>Research Interest </strong></div>
      <div class="panel-body" align="left">
      <ul><li>Occupational mental Health, Heavy metal and Toxin related Occupational Diseases and Emerging Non communicable diseases among workers</li></ul>
</div>
</div>
<div class="panel my-panel">
      <div class="panel-heading my-panel-head" align="left"><strong>Membership/Affliation </strong></div>
      <div class="panel-body" align="left">
      <ul>
	  <li>The Alzheimer's Association International Society to Advance Alzheimer's Research and Treatment (ISTAART)</li>
<li>	Alzheimer’s and related disorders society of India (ARDSI)</li>
<li>	Neurological society of India</li>
</ul>
</div>
</div>
<div class="panel my-panel">
      <div class="panel-heading my-panel-head" align="left"><strong>Fellowship/Awards </strong></div>
      <div class="panel-body" align="left">
      <ul>
	 <li>	Gold Medal (2009) in Forensic Medicine during MBBS.</li>
<li>	Best research Award” (2011) by International Congress of Neuropsychiatric Association (2011).</li>
<li>	Alzheimer’s Association International award” (2012) from International Alzheimer’s Association.</li> 
<li>	Alzheimer’s Association International award” (2014) from International Alzheimer’s Association.</li>
<li>	Alzheimer’s Association International award” (2015) from International Alzheimer’s Association.</li>
<li>	Post doctoral fellowship, University of Hamburg (2015)</li>
<li>	Post doctoral fellowship, Canadian Institute of Health research (2016)</li>

</ul>
</div>
</div>
<div class="panel my-panel">
      <div class="panel-heading my-panel-head" align="left"><strong>Publications </strong></div>
      <div class="panel-body" align="left">
	
      <ol>
      <li><strong>Rakesh, B.</strong>,Bagepally, B., Bharath, S., Saini, J., Sadanand, S., Donthi, N., Sivakumar, P., et al. (2012). Cognitive decline and regional brain perfusion in Alzheimer’s disease using single photon emission computed tomography: A retrospective study. Alzheimer's & Dementia: The Journal of the Alzheimer's Association, 8(4, Supplement), 508–509. 
</li><br/><li>	<strong>Balachandar R</strong>, John JP, Saini J, Kumar KJ, Joshi H, Sadanand S, et al (2014). A study of structural and functional connectivity in early Alzheimer's disease using rest fMRI and diffusion tensor imaging. Int J Geriatr Psychiatry; 30(5):497-504. 
</li><br/><li>	R Tripathi, K Kumar, <strong>R Balachandar</strong>, P Marimuthu, M Varghese, S Bharath (2014), Neuropsychological markers of mild cognitive impairment: A clinic based study from urban India, Annals of Indian Academy of Neurology; 18(2): 177–180. 
</li><br/><li>	<strong>R Balachandar</strong>, S Bharath, J P. John, J Saini, et al (2014); Resting state functional connectivity of executive network predicts the performance of cognitive function; Alzheimer's & Dementia: The Journal of the Alzheimer's Association; 10(4, Supplement): 838–839 
</li><br/><li>	<strong>Rakesh, B.</strong>, Bharath, S., Bagepally, B. S., Saini, J., &Sadanand, S. (2014). A Retrospective Study on Relation between Cognitive Performance and Lobar Perfusions of Brain in Alzheimer’s Dementia using Single Photon Emission Computer Tomography. Brain DisordTher; 3(4): 135 – 139 
</li><br/><li>	<strong>Balachandar, R.</strong>, Tripathi, R., Bharath, S., Kumar, K., (2015). Classic Tower of Hanoi, Planning Skills, and the Indian Elderly. East Asian archives of psychiatry;25(3):108-114. 
</li><br/><li>	<strong>Balachandar R</strong>, Bharath S, Shilpa S (2015); Evidence based management of Alzheimer’s Disease. Annals medicus; supplement; 29-36 https://goo.gl/5fySkc
</li><br/><li>	Sadanand, S <strong>Balachandar R</strong> Bharath, S (2015); Memory and Executive Functions in Persons with Type-2 Diabetes, A Meta-analysis; Diabetes Metab Res Rev; 32(2):132-142.
</li><br/><li>	<strong>Balachandar R</strong>, Bharath S, John P J, Joshi H, Sadanand S, Kumar J, Saini J (2015) Resting state functional connectivity changes associated with visuo-spatial cognitive deficits in patients with mild Alzheimer's disease. Neurodegenerative Diseases; 15 (Supplement 1): 876 - 879 
</li><br/><li><strong>R Balachandar</strong>, S Bharath, J P. John, H Joshi et al (2015); Effect of apolipoprotein epsilon 4 allele on functional and structural connectivity of the brain in patients with mild Alzheimer’s disease; Alzheimer's & Dementia: The Journal of the Alzheimer's Association; 11(7, supplement): 529-530 
</li><br/><li>Bharath, S., Joshi, H., John, J.P., <strong>Balachandar, R.</strong>, Sadanand, S., Saini, J., Kumar, K.J., Varghese, M., (2017). A Multimodal Structural and Functional Neuroimaging Study of Amnestic Mild Cognitive Impairment. The American Journal of Geriatric Psychiatry; 25(2): 158–169. 
</li><br/><li>K Sriganesh, <strong>R Balachandar</strong>, BS Bagepally, J Saini, GSU Rao (2017) Effect of propofol anesthesia on resting state brain functional connectivity in Indian population with chronic back pain Neurology India; 65 (2): 286-292 
</li><br/><li><strong>Balachandar R</strong>, Bharath S, John P J, Joshi H, Sadanand S, Kumar J, Saini J (2017) Resting state functional connectivity changes associated with visuo-spatial cognitive deficits in patients with mild Alzheimer's disease. Dementia and Geriatric Cognitive Disorders; 43 (5-6): 229-236 
</li><br/><li>Bharath S, Sadanand S, Kumar KJ, <strong>Balachandar R</strong>, Joshi H, Varghese M (2017). Clinical and neuropsychological profile of persons with mild cognitive impairment, a hospital based study from a lower and middle income country. Asian J Psychiatr. 2017; 30:185-189.
</li><br/><li><strong>Balachandar R</strong>, Maria G, Brigitte R, Ramesh K (2016),  Hippocampal shape changes after sight restoration in congenital dense cataract patients Federation of European neuroscience Societies
</li><br/><li>K Popuri, E Dowds, MF Beg, <strong>R Balachandar</strong>, M Bhalla, et al (2018), Gray matter changes in asymptomatic C9orf72 and GRN mutation carriers NeuroImage: Clinical 18, 591-598 
</li><br/><li>D Lu, K Popuri, GW Ding, <strong>R Balachandar</strong>, MF Beg (2018) Multimodal and Multiscale Deep Neural Networks for the Early Diagnosis of Alzheimer’s Disease using structural MR and FDG-PET images Scientific reports; 8 (1): 5697 
</li><br/><li>D Lu, K Popuri, GW Ding, <strong>R Balachandar</strong>, MF Beg (2018) Multiscale deep neural network based analysis of FDG-PET images for the early diagnosis of Alzheimer’s disease Medical image analysis; 46: 26-34 
</li><br/><li>KarteekPopuri <strong>R Balachandar</strong> Kathryn Alpert Donghuan Lu et al (2018) Development and validation of a novel dementia of Alzheimer's type (DAT) score based on metabolism FDG-PET imaging NeuroImage: Clinical; 18: 802-813 
</li><br/><li>Joshi H, Bharath S, <strong>Balachandar R</strong>, et al. (2019) Differentiation of Early Alzheimer's Disease, Mild Cognitive Impairment, and Cognitively Healthy Elderly Samples Using Multimodal Neuroimaging Indices. Brain Connect. 2019;9(9):730-741.
</li><br/><li>Upadhyay, K., Viramgami, A., Bagepally, B. S., &<strong>Balachandar, R.</strong> (2022). Association between blood lead levels and markers of calcium homeostasis: a systematic review and meta-analysis. Scientific reports, 12(1), 1850.
</li><br/><li>Kalahasthi, R., Nagaraju, R., <strong>Balachandar, R.</strong>, &Bagepally, B. S. (2022). Association between occupational lead exposure and immunotoxicity markers: A systematic review and meta-analysis. Toxicology, 465, 153047.
</li><br/><li>Murhekar, M. V., Bhatnagar, T., Thangaraj, J., Saravanakumar, V., Santhosh Kumar, M., Selvaraju, S., Rade, K., Kumar, C., Sabarinathan, R., Asthana, S., <strong>Balachandar, R.</strong>, ICMR serosurveillance group (2021). Seroprevalence of IgG antibodies against SARS-CoV-2 among the general population and Healthcare workers in India, June-July 2021: A population-based cross-sectional study. PLoS medicine, 18(12), e1003877.
</li><br/><li><strong>Balachandar, R.</strong>, Pullakhandam, R., Kulkarni, B., &Sachdev, H. S. (2021). Relative Efficacy of Vitamin D2 and Vitamin D3 in Improving Vitamin D Status: Systematic Review and Meta-Analysis. Nutrients, 13(10), 3328.
</li><br/><li>Murhekar, M. V., Bhatnagar, T., Thangaraj, J., Saravanakumar, V., Kumar, M. S., Selvaraju, S., Rade, K., Kumar, C., Sabarinathan, R., Turuk, A., Asthana, S., <strong>Balachandar, R.</strong>, ..ICMR Serosurveillance Group (2021). SARS-CoV-2 seroprevalence among the general population and Healthcare workers in India, December 2020-January 2021. International journal of infectious diseases : IJID : official publication of the International Society for Infectious Diseases, 108, 145–155.
</li><br/><li>Chakma, T., Thomas, B. E., Kohli, S., Moral, R., Menon, G. R., Periyasamy, M., Venkatesh, U., Kulkarni, R. N., Prusty, R. K., Balu, V., Grover, A., Kishore, J., Viray, M., Venkateswaran, C., Mathew, G., Ketharam, A., <strong>Balachandar, R.</strong>,… Panda, S. (2021). Psychosocial impact of COVID-19 pandemic on Healthcare workers in India & their perceptions on the way forward - A qualitative study. The Indian journal of medical research, 153(5&6), 637–648.
</li><br/><li>Bagepally, B. S., <strong>Balachandar, R.</strong>, Kalahasthi, R., Tripathi, R., &Haridoss, M. (2021). Association between aluminium exposure and cognitive functions: A systematic review and meta-analysis. Chemosphere, 268, 128831.
</li><br/><li>Singh, D. P., Sahu, M. C., Pagdhune, A., Viramgami, A., Perumal, S., <strong>Balachandar, R.</strong>, & Sarkar, K. (2021). Viral load could be an important determinant for fomites based transmission of viral infections. Journal of family medicine and primary care, 10(2), 929–932.
</li><br/><li>Murhekar, M. V., Bhatnagar, T., Selvaraju, S., Saravanakumar, V., Thangaraj, J., Shah, N., Kumar, M. S., Rade, K., Sabarinathan, R., Asthana, S., <strong>Balachandar, R.</strong>,… ICMR Serosurveillance Group (2021). SARS-CoV-2 antibody seroprevalence in India, August-September, 2020: findings from the second nationwide household serosurvey. The Lancet. Global Health, 9(3), e257–e266.
</li><br/><li>Popuri, K., Beg, M. F., Lee, H., <strong>Balachandar, R.</strong>, Wang, L., Sossi, V., Jacova, C., Baker, M., Shahinfard, E., Rademakers, R., Mackenzie, I., &Hsiung, G. R. (2021). FDG-PET in presymptomatic C9orf72 mutation carriers. NeuroImage. Clinical, 31, 102687.
</li><br/><li><strong>Balachandar, R.</strong>, Bagepally, B. S., Kalahasthi, R., &Haridoss, M. (2020). Blood lead levels and male reproductive hormones: A systematic review and meta-analysis. Toxicology, 443, 152574.
</li><br/><li>Viramgami, A., Upadhyay,K., <strong>Balachandar, R.</strong>, (2020). Catastrophic Health expenditure and Health facility access among rural informal sector families, Clinical Epidemiology and Global Health, 8(4), 1325-1329
</li><br/><li>Upadhyay,K., Viramgami, A., Pagdhune, A., <strong>Balachandar, R.</strong>, Sarkar, K., (2021) Hematological and cardiovascular effects of chronic low level lead exposure: A study on e-waste recyclers, Clinical Epidemiology and Global Health, 9, 269 – 274
</li><br/><li>Viramgami, A. ., Pagdhune, A. ., Sarkar, K. ., & <strong>Balachandar, R.</strong>. (2020). Occupational Health and Safety Practices at workplace during COVID-19 Pandemic. Journal of Comprehensive Health, 8(2), 77-82.
</li><br/><li><strong>Balachandar, R.</strong>, Soundararajan, S., &Bagepally, B. S. (2020). Docosahexaenoic acid supplementation in age-related cognitive decline: a systematic review and meta-analysis. European journal of clinical pharmacology, 76(5), 639–648.
</li><br/><li>Murhekar, M. V., Bhatnagar, T., Selvaraju, S., Rade, K., Saravanakumar, V., Vivian Thangaraj, J. W., Kumar, M. S., Shah, N., Sabarinathan, R., Turuk, A., Anand, P. K., Asthana, S., <strong>Balachandar, R.</strong>, … Bhargava, B. (2020). Prevalence of SARS-CoV-2 infection in India: Findings from the national serosurvey, May-June 2020. The Indian journal of medical research, 152(1 & 2), 48–60.
</li>
      
</ol>
	
	
</div>
</div>
</div>

      
    </div>
    </div>
    );
}
export default DrRakesh;