import React from 'react';
import '../carousel.css';
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import NavbarMenu from '../NavbarMenu.js';
function DrAsha() {
    return (
      <div> <NavbarMenu /><div class="manual">
        <div class="row container">
        <p class="mypathhead">
        <Link to="/"><span class="mypath">Home</span></Link>&gt;<Link to="/ScientistNIOH"><span class="mypath">Scientist's Profile</span></Link>&gt;<span class="mypath">NIOH Ahmedabad</span>
        </p>
          
        </div>
        <h1 class="mypathhead">Scientist's Detail</h1>
        <div class="scientistdetail">
      
            <div class="panel my-panel" >
      <div class="panel-heading my-panel-head" align="left"><strong>Basic Details</strong></div>
      <div class="panel-body" align="left">
      <table width="100%">
            <tr>
              <td width="28%" rowspan="5"><img src="../assests/images/niohScientist/ashaK.jpg" width="170px" height="170px" CLASS="dg-img"/></td>
              <td width="50%" ><p class="myfontSci trSci">Dr K Asha</p> </td>
              
            </tr>
            
            <tr>
              
              <td class="myfontSci"><p class="myfontSci">Health sciences Division</p></td>
             </tr>
            <tr>
              <td><p ><a href=""><i class="fa fa-envelope" aria-hidden="true"></i>   Email: asha.k@gov.in</a></p></td>
            </tr> 
            <tr>
              <td><p ><a href=""> <i class="fa fa-phone-square"></i>  Contact: 079-22688702</a></p></td>
            </tr>   
           
            </table>   
      </div>
    </div>
       
        <div class="panel my-panel" >
      <div class="panel-heading my-panel-head" align="left"><strong>Academic Qualification</strong></div>
      <div class="panel-body" align="left">
      Ph.D. Sociology
</div>
    </div>
    <div class="panel my-panel" >
      <div class="panel-heading my-panel-head" align="left"><strong>Research Interest </strong></div>
      <div class="panel-body" align="left">
      <ul><li>Occupational and Environmental Health, Occupational Health research with focus on Unorganised sector, women workers, psycho social factors associated with occupational Health across occupations</li></ul>

</div>
</div>
<div class="panel my-panel">
      <div class="panel-heading my-panel-head" align="left"><strong>Membership/Affliation </strong></div>
      <div class="panel-body" align="left">
      <ul>
	  <li>	Indian Association of Occupational Health (Karnataka Chapter)</li>

</ul>
</div>
</div>
<div class="panel my-panel">
      <div class="panel-heading my-panel-head" align="left"><strong>Fellowship/Awards </strong></div>
      <div class="panel-body" align="left">
      <ul>
	  <li>	National Merit Scholarship during graduation and post-graduation. ICSSR Fellowship for Ph.D at Institute of Social and Economic Change, Bangalore.</li>

</ul>
</div>
</div>
<div class="panel my-panel">
      <div class="panel-heading my-panel-head" align="left"><strong>Publications </strong></div>
      <div class="panel-body" align="left">
	
      <ol>
      <li> Sexuality and Reproductive Health of Youth: Socio-Legal and Health Dimensions, Ed. By Swati Shirwadkar and <strong>K Asha</strong> (2005-06), Occasional Paper Series, Department of Sociology, University of Pune.</li>
<br/><li> Emotional Abuse in Family, - an act of Power Play, A Human Rights Debate with special reference to Scleroderma Patients, pp 304-321, in Family Violence in India- Human Rights, Issues, Actions and International Comparisons, Ed. Swati Shirwadkar, Rawat Publications, New Delhi, 2009</li>
<br/><li> Babu, G. R., Sathyanarayana, T. N., Ketharam, A., Kar, S. B., & Detels, R. (2015). Perceived occupational stressors and the Health of software professionals in Bengaluru, India. The Qualitative Report, 20(3), 314-335.</li>
<br/><li> Globalisation, Urbanisation and Marginalisation of Disadvantaged-Health of Unorganised Workers in Bangalore’, in the book titled ‘Urban Governance in Karnataka and Bangalore’, Ed. Sangita Satyanarayana, Shridhar Kala & Vaddiraju Anil. Cambridge Scholars Publishing, 2016</li> 
<br/><li> Tapas Chakma , Beena E Thomas , Simran Kohli , Rony Moral , Geetha R Menon , Murugesan Periyasamy , U Venkatesh , Ragini Nitin Kulkarni , Ranjan Kumar Prusty , Vinoth Balu , Ashoo Grover , Jugal Kishore , Maribon Viray , Chitra Venkateswaran , Geethu Mathew , <strong>Asha Ketharam </strong>, Rakesh Balachandar , Prashant Kumar Singh , Kiran Jakhar , Shalini Singh , Rekha Devi , Kalyan B Saha , Pradeep Barde , Ravinder Singh , Denny John , Bijaya Kumar Mishra , Jeetendra Yadav , Sumit Agarwal , Vishnu Vardhana Rao , Samiran Panda, Psychosocial impact of COVID-19 pandemic on Healthcare workers in India & their perceptions on the way forward - A qualitative study, Indian J Med Res. 2021 May;153(5&6):637-648. doi: 10.4103/ijmr.ijmr_2204_21. (IF- 2.375)</li>

      
</ol>
	
	
	
</div>
</div>

</div>   
    </div>
    </div>
    );
}
export default DrAsha;