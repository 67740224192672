import React from 'react';
import './carousel.css';
import {  Link } from "react-router-dom";
import NavbarMenu from './NavbarMenu.js';
function EdDetailNoti() {
    return (
      <div> <NavbarMenu /><div class="manual">
        <div class="row container">
        <p class="mypathhead">
        <Link to="/"><span class="mypath">Home</span></Link>&gt;<Link to="/edDissert"><span class="mypath">Education & Training</span></Link>&gt;<span class="mypath">Dissertation</span>
        </p>
          
        </div>
        <h1 class="mypathhead">MSc / MPH / MVSc / MTech / MCA / MPharm Dissertation/Project/Summer Training Program during the period from Jan - Jun, 2024</h1>
        <div class="scientistdetail">
          <br/>
        <p><strong>ICMR-NIOH Ahmedabad invites applications from eligible candidates pursuing masters in any branch of Life Science / Public Health / Microbiology / Chemistry/ Biochemistry / Biotechnology / Biostatistics / Bioinformatics / Pharmacy / Environmental Science / Veterinary Science / Computer Science or M. Tech. in Biotechnology / Bioinformatics to undertake dissertation /Computer Engineering / project work / summer training as a part of their curriculum at this institute.</strong></p>
          <p style={{color:"Green",fontWeight:"bold",fontSize:"19px"}}><center>Information on Dissertation/ Project Work:</center></p>
          <ol>
    <li>Institute accepts students for pursuing their dissertation work at ICMR-NIOH Ahmedabad as an essential part of their curriculum.<strong>Only those pursuing their master's degree</strong>  are accepted for such dissertation or project work</li>
    <li>In case of candidates pursing integrated M.Sc. degree and those completed at least three years of such course will also be considered.</li>
    <li>3.	Number of students taken each year may vary depending upon availability with the supervisor Scientists.</li>
    <li>Preference will be given to students with good academic record and to the candidates devoting their full time for the dissertation or project during this study period</li>
    <li>The <strong>minimum duration of dissertation work shall be FOUR months.</strong> Requests for shorter period projects shall not be considered</li>
    <li><strong>Maximum permissible/allowed period of dissertation work is SIX months, </strong>however extension is possible based on the performance of the candidate and the willingness of supervisor as well as requirement of the pending research work</li>
    <li><strong>Application should be forwarded by Head of the Institute, where the student is pursuing his/her degree</strong></li>
    <li>Interim  inquiries will not be considered.</li>
    <li><strong>Students are requested to follow the prescribed procedure for admission and NOT to approach individual scientists for placement for the project</strong></li>
    <li>Selected  students will need to pay as follow:</li>
    <div align="center">
    <table border="0" cellspacing="0" cellpadding="0" width="97%">
      <tr>
        <td width="318" valign="top"><p>Dissertation    for period of&nbsp;six months</p></td>
        <td width="139" valign="top"><p align="center">Rs. 8000/-</p></td>
      </tr>
      <tr>
        <td width="318" valign="top"><p>Dissertation    for period of&nbsp;four months</p></td>
        <td width="139" valign="top"><p align="center">Rs. 5000/-</p></td>
      </tr>
    </table>
  </div>
  <p>The fee should be submitted in the form of a <strong>Cash/Demand Draft/Online transfer in favour of Director NIOH, payable at Ahmedabad</strong> at the time of joining the institute</p>
  
  <li>ICMR-NIOH <strong>will not provide any stipend</strong> for the project training</li>
  <li>The shared limited accommodation facility is available at the institute's guest house <strong>on charge basis as an interim arrangement for a maximum period of 10 days only.</strong> The outside female students will be given the preference on accommodation facility. However, accommodation shall be provided based on the availability.</li>
  
  <li> <strong>The list of selected candidates shall be displayed on institute's website (www.nioh.org). </strong> </li>
  <li><strong>Candidates may submit their filled google form and submit. Link for the Google form is&nbsp;<a href="https://forms.gle/WZobkFZTVUCv3pFk6" target="_blank" ><strong>https://forms.gle/WZobkFZTVUCv3pFk6</strong></a></strong></li>

  <li>The schedule  is as follow:</li>
  <br/>
  <div align="center">
    <table border="1px" cellspacing="0" cellpadding="0" width="97%">
      <tr>
        <td width="318" valign="top"><p style={{fontWeight:"bold"}}>Last Date of receiving the applications</p></td>
        <td width="139" valign="top"><p style={{fontWeight:"bold"}}>05-12-2023</p></td>
      </tr>
      <tr>
        <td width="318" valign="top"><p style={{fontWeight:"bold"}}>Display of first list of candidate on www.nioh.org</p></td>
        <td width="139" valign="top"><p style={{fontWeight:"bold"}}>15-12-2023</p></td>
      </tr>
      <tr>
        <td width="318" valign="top"><p style={{fontWeight:"bold"}}>Submission of willingness letter</p></td>
        <td width="139" valign="top"><p style={{fontWeight:"bold"}}>By 20-12-2023</p></td>
      </tr>
      <tr>
        <td width="318" valign="top"><p style={{fontWeight:"bold"}}>Display of second list (if required)</p></td>
        <td width="139" valign="top"><p style={{fontWeight:"bold"}}>25-12-2023</p></td>
      </tr>
      <tr>
        <td width="318" valign="top"><p style={{fontWeight:"bold"}}>Initiation of the program</p></td>
        <td width="139" valign="top"><p style={{fontWeight:"bold"}}>01-01-2024</p></td>
      </tr>
    </table>
  </div>




  </ol>
          
        
       
        <p style={{color:"Green",fontWeight:"bold",fontSize:"17px"}}><center>Important Notes</center></p>
        <ol>
    <li><strong>The mandatory period for M.Sc. dissertation/Summer training should be minimum 120 days, which can be extended up to a maximum of 180 days,</strong> based on the curriculum of certain universities or the interest and work requirement of both the candidates and host scientist in ICMR-NIOH.</li>
    <li><strong>Selection criteria should be merit based giving emphasis on percentages of B.Sc. and M.Sc. (1st yr) marks, laboratory experience, soft skills such as writing capacity in English, communication skills, scientific data extraction and area of research interest, availability of Scientists and other information request in the form</strong></li>
    <li>It is mandatory for the candidates to strictly follow the ICMR-NIOH rules and regulations. No violation/interim request either from the candidate or their host institute /universities will be entertained.</li>
    <li><strong>For any information contact  niohswd@gmail.com</strong></li>
  </ol>
        </div>
        </div>
        </div>
    );
}
export default EdDetailNoti;