import React from 'react';
import '../carousel.css';
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import NavbarMenu from '../NavbarMenu.js';
function DrSiva() {
    return (
      <div> <NavbarMenu /> <div class="manual">
        <div class="row container">
        <p class="mypathhead">
        <Link to="/"><span class="mypath">Home</span></Link>&gt;<Link to="/ScientistNIOH"><span class="mypath">Scientist's Profile</span></Link>&gt;<span class="mypath">NIOH Ahmedabad</span>
        </p>
          
        </div>
        <h1 class="mypathhead">Scientist's Detail</h1>
        <div class="scientistdetail">
      
            <div class="panel my-panel" >
      <div class="panel-heading my-panel-head" align="left"><strong>Basic Details</strong></div>
      <div class="panel-body" align="left">
      <table width="100%">
            <tr>
              <td width="28%" rowspan="5"><img src="../assests/images/niohScientist/mtiwari.png" width="170px" height="170px" CLASS="dg-img"/></td>
              <td width="50%" ><p class="myfontSci trSci">Dr Mugdha Tiwari</p> </td>
              <td width="22%" ></td>
            </tr>
            
            <tr>
              
              <td class="myfontSci"><p class="myfontSci">Biological sciences Division </p></td>
             </tr>
            <tr>
              <td><p ><a href=""><i class="fa fa-envelope" aria-hidden="true"></i>   Email: mugdha.tiwari@gov.in</a></p></td>
            </tr> 
            <tr>
              <td><p ><a href=""> <i class="fa fa-phone-square"></i>  Contact: 079-22688873</a></p></td>
            </tr>   
           
            </table>
      </div>
    </div>
       
        <div class="panel my-panel" >
      <div class="panel-heading my-panel-head" align="left"><strong>Academic Qualification</strong></div>
      <div class="panel-body" align="left">M.Sc. Microbiology, PhD Microbiology 
</div>
    </div>
    <div class="panel my-panel" >
      <div class="panel-heading my-panel-head" align="left"><strong>Research Interest </strong></div>
      <div class="panel-body" align="left"> <ul><li>Study of molecular mechanisms related to occupational exposure to hazardous substances and Health problems/ disorders associated with them, early detection of these disorders, and development of therapeutic approach for mitigation of them.</li></ul></div>
    </div>
    <div class="panel my-panel" >
      <div class="panel-heading my-panel-head" align="left"><strong>Membership/Affliation </strong></div>
      <div class="panel-body" align="left">
      <ul>
	  <li>Life member of Indian Society of Cell Biology.</li>
<li>Life member of Indian Science Congress Association.</li>
<li>	Life member of Alumni Administrative Staff College of India, Hyderabad.</li>                  
<li>	Life member, Mushroom society of India, NRCM, Solan, H. P.
</li>

</ul>
</div>
</div>
<div class="panel my-panel">
      <div class="panel-heading my-panel-head" align="left"><strong>Fellowship/Awards </strong></div>
      <div class="panel-body" align="left"><ul>
	  <li>	Senior Research Fellowship by ICAR-World-Bank funded project, from Oct. 2001 to Nov. 2004 in Crop Improvement Division, ICAR-Directorate of Mushroom Research, Solan, H.P</li>
<br/>
<li>	Post Doctoral Fellowship by Defense Research and Development Organization in Molecular Cytogenetics Lab, Deptt. Of Botany, University of Delhi, Delhi, from June 2005 to Nov. 2006. </li>
<br/>
<li>	Post Doctoral Fellowship by Defense Research and Development Organization in Virology Division, Defense Research and Development Establishment, Gwalior, M.P., from Nov. 2006 to Nov. 2008. </li>
<br/>
<li>	Best paper of the year 2009 in the area of Biological science by Defence Research and development establishment.  </li><br/>
<li>	Young Scientist award 2006 from Indian Society of Sciences, M.P.</li><br/>
<li>	Best paper presented award by MP Science congress 2006, Bhopal from Indian Society of Sciences, M.P.
</li>

</ul>
</div>
</div>
<div class="panel my-panel">
      <div class="panel-heading my-panel-head" align="left"><strong>Publications </strong></div>
      <div class="panel-body" align="left">
	
      <ol>

      <li>	<strong>Mugdha Tiwari</strong>, Manmohan Parida, S R Santhosh, M Khan, PK Dash, PVL Rao. 2009. Assessment of immunogenic potential of Vero adapted formalin inactivated vaccine derived from novel ECSA genotype of Chikungunya virus. Vaccine. 27(18): 2513-22.
</li><br/><li>	Paban Kumar Dash, <strong>Mugdha Tiwari</strong>, S.R. Santhosh, Manmohan Parida, P.V. Lakshmana Rao. 2008. RNA interference mediated inhibition of Chikungunya virus replication in mammalian cells. BioChemical and Biophysical Research Communications. 376:718–722.
      Impact Factor- 2.985, Citation- 63
</li><br/><li>	Patel Bharat, Das Rajat, Gautam AK, <strong>Tiwari Mugdha</strong>, A Shukhdev, Kumar Sunil. 2017. Evaluation of vascular effect of arsenic using in vivo assays. Environmental Science and Pollution Research. 24-15521-15527.

</li><br/><li>	BN Acharya, D Saraswat, <strong>Mugdha Tiwari</strong>, Ashish K Shrivastava, Ramarao Ghorpade, Saroj  Bapna, MP Kaushik. 2009. Synthesis and antimalerial evaluation of 1,3,6 trisubstituted pyrazolines. European journal of medicinal chemistry. doi:10.1016/j.ejmech.2009.10.023

</li><br/><li>	Jyoti Shukla, Mohsin Khan, <strong>Mugdha Tiwari</strong>, Santhosh Sannarangaiah, Shashi Sharma, Putcha Venkata Lakshmana Rao, Manmohan Parida. 2009. Development and evaluation of antigen capture ELISA for early clinical diagnosis of Chikungunya. Diagnostic Microbiology & Infectious Disease; 65(2): 142-149.

</li><br/><li>	 Mohsin Khan, S.R. Santhosh, <strong>Mugdha Tiwari</strong>, P.V. Lakshmana Rao, Manmohan Parida. 2010. Assessment of in vitro prophylactic and therapeutic efficacy of chloroquine against chikungunya virus in vero cells. Journal of Medical Virology, 82(5):817-824.

</li><br/><li>	S R Santhosh, P K Dash, M M Parida, M Khan, <strong>M Tiwari</strong>, P V Lakshmana Rao. 2008. Comparative full genome analysis revealed E1: A226V shift in 2007 Indian Chikungunya virus isolates. Virus Research. 135 (1): 36-41.

</li><br/><li>	Pallavi Gupta, Mangalesh Kumar Singh, Padma Singh, <strong>Mugdha Tiwari</strong>, Ram Kumar Dhakad. 2010. Antibodies against Recombinant Shiga Toxin Subunit B Neutralize Shiga Toxin Toxicity in HeLa Cells. Protein Pept Lett. 17(8):774-781   
 
</li><br/><li>	Raina SN, Jain S, Sehgal D, Kumar A, Dar TH, Bhat V, Pandey V, Vaishnavi S, Bhargav A, Singh V, Rani V, Tandon R, <strong>Tiwari M</strong>, Mahmoudi A. 2012. Diversity and relationships of multipurpose seabuckthorn (Hippophae L.) germplasm from the Indian Himalayas as assessed by AFLP and SAMPL markers. Genetic Resources and Crop Evolution. 59(6):1033-1053.
</li><br/><li>	S.K. Singh, <strong>Mugdha Tiwari</strong>, Shwet Kamal, Mahesh C. Yadav and Surendra Singh. 2004. Cytology and hyphal interactions to determine mating types in Morchella esculenta. Mushroom Research. 15(1): 9-15.

</li><br/><li>	S. K. Singh, <strong>Mugdha Tiwari</strong>, Shwet Kamal and Mahesh C. Yadav. 2004. Morel Phylogeny and diagnostics based on restriction fragment length polymorphism analysis of ITS region of 5.8S ribosomal DNA. Journal of Plant Biochemistry and Biotechnology. 14:170-183.
National Academy of Agricultural Science (NAAS) Rating- 4, citation-4

</li><br/><li>	<strong>Mugdha Tiwari</strong>, S. K. Singh, Shwet Kamal, Surendra Singh and M. C. Yadav. 2004. Extracellular enzymatic polymorphism in Morchella and related species. Journal of Mycology and Plant Pathology. 36 (1): 64-68.
</li>

</ol>
	
</div>	
	
</div>
</div>

        </div>
    </div>
    );
}
export default DrSiva;