import React, {useState} from 'react';
import { BrowserRouter, Routes, Route, Link, NavLink} from "react-router-dom";
import './App.css';
import { Button, Navbar, Nav, NavItem, NavDropdown, Dropdown } from 'react-bootstrap';
function NavbarMenuHindi () {
  const [showMenu, setShowMenu] = useState(false)
    function myFunction(){
        var x = document.getElementById("topnav");
        if (x.className === "container-fluid topnav") {
            x.className += " responsive";
          } 
        
 }     
    return (<nav class="navbar navbar-expand-xl navbar-dark"  style={{backgroundColor:"#002147"}}>
   
      
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon" ></span>Click Here For Detailed Menu
    </button>
    <div class="collapse navbar-collapse" id="navbarNav" >
    
    <ul class="navbar-nav my-nav-font" >
  <li class="nav-item">
    <Link to="/" href="#" ><p class= "nav-link my-nav-font" style={{color:'white',marginTop:"2px",marginLeft:"180px"}}>मुख्‍यपृष्‍ठ</p></Link>
  </li>
  <li class="nav-item dropdown">
    <p class="my-nav-font nav-link dropdown-toggle" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{color:'white'}}> संस्‍थान </p>
    <ul class="dropdown-menu myulnav" aria-labelledby="navbarDropdownMenuLink">
        <Link to="/about"><li><a class="dropdown-item" href="#" style={{fontSize:"16px"}}>संस्थान के बारे में</a></li></Link>
        <Link to="/dic"><li><a class="dropdown-item" href="#" style={{fontSize:"16px"}}>निदेशक प्रोफाइल</a></li></Link>
        <Link to="/DirDesk"><li><a class="dropdown-item" href="#" style={{fontSize:"16px"}}>निदेशक की कलम से</a></li></Link>
        <Link to="/Orgorgano"><li><a class="dropdown-item" href="#" style={{fontSize:"16px"}}> ऑर्गेनोग्राम</a></li></Link>
        <Link to="/commitee"><li><a class="dropdown-item" href="#" style={{fontSize:"16px"}}>सलाहकार समितियां</a></li></Link>
</ul>
</li>
<li class="nav-item dropdown">
<p class="my-nav-font nav-link dropdown-toggle" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{color:'white'}}> शिक्षा</p>
<ul class="dropdown-menu myulnav" aria-labelledby="navbarDropdownMenuLink">

<Link to="/EdAFIH"><li><a class="dropdown-item" href="#" style={{fontSize:"16px"}}>एएफआईएच</a></li></Link>
<Link to="/EdPhd"><li><a class="dropdown-item" href="#" style={{fontSize:"16px"}}>पीएचडी</a></li></Link>

<Link to="/EdDissert"><li><a class="dropdown-item" href="#" style={{fontSize:"16px"}}>शोध-निबंध</a></li></Link>
</ul>
</li>
<li class="nav-item">
<Link to="/Division" href="#" ><p class= "nav-link my-nav-font" style={{color:'white',marginTop:"2px"}}>प्रभाग</p></Link>
</li>
<li class="nav-item dropdown">
<p class="my-nav-font nav-link dropdown-toggle" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{color:'white'}}> प्रसार </p>
    <ul class="dropdown-menu myulnav" aria-labelledby="navbarDropdownMenuLink">
        <Link to="/"><li><a class="dropdown-item" href="#" style={{fontSize:"16px"}}>प्रकाशन</a></li></Link>
        <Link to="/"><li><a class="dropdown-item" href="#" style={{fontSize:"16px"}}>वार्षिक प्रतिवेदन</a></li></Link>
        <Link to="/"><li><a class="dropdown-item" href="#" style={{fontSize:"16px"}}>संवाद पत्र </a></li></Link>
        <Link to="/workshop"><li><a class="dropdown-item" href="#" style={{fontSize:"16px"}}>कार्यशाला/संगोष्ठी/सम्मेलन</a></li></Link>
        <Link to="/"><li><a class="dropdown-item" href="#" style={{fontSize:"16px"}}>प्रशिक्षण</a></li></Link>
</ul>
</li>
<li class="nav-item dropdown">
<p class="my-nav-font nav-link dropdown-toggle" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{color:'white'}}>अधिसूचनाएं </p>
    <ul class="dropdown-menu myulnav" aria-labelledby="navbarDropdownMenuLink">
        <Link to="/tenderNioh"><li><a class="dropdown-item" href="#" style={{fontSize:"16px"}}>निविदाएं</a></li></Link>
        <Link to="/recruitment"><li><a class="dropdown-item" href="#" style={{fontSize:"16px"}}>भर्ती</a></li></Link>
        <Link to="/circular"><li><a class="dropdown-item" href="#" style={{fontSize:"16px"}}>कार्यालय परिपत्र</a></li></Link>
    </ul>
</li>
<li class="nav-item dropdown">
<p class="my-nav-font nav-link dropdown-toggle" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{color:'white'}}>वैज्ञानिकों की विवरणिका</p>
    <ul class="dropdown-menu myulnav" aria-labelledby="navbarDropdownMenuLink">
        <Link to="/ScientistNIOH"><li><a class="dropdown-item" href="#" style={{fontSize:"16px"}}>एनआईओएच अहमदाबाद</a></li></Link>
        <Link to="/ScientistROHCS"><li><a class="dropdown-item" href="#" style={{fontSize:"16px"}}>आरओएचसी (दक्षिणी) बेंगलुरु</a></li></Link>
       
    </ul>
</li>

<li class="nav-item dropdown">
<p class="my-nav-font nav-link dropdown-toggle" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{color:'white'}}> कर्मचारीगण </p>
<ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">

<li class="dropdown-submenu"><a class="my-nav-font dropdown-item dropdown-toggle" href="#">एनआईओएच अहमदाबाद</a>
    <ul class="dropdown-menu">
    <li><Link to="/AdminNioh"><a class=" dropdown-item" href="#" style={{fontSize:"16px"}}>प्रशासन</a></Link></li>
    <li><Link to="/TechNioh"> <a class="dropdown-item" href="#" style={{fontSize:"16px"}}>तकनीकी</a></Link></li>
        
    </ul>
</li>
<li class="dropdown-submenu"><a class="my-nav-font dropdown-item dropdown-toggle" href="#">आरओएचसी (दक्षिणी) बेंगलुरु</a>
    <ul class="dropdown-menu">
    <li><Link to="/"><a class=" dropdown-item" href="#" style={{fontSize:"16px"}}>प्रशासन</a></Link></li>
    <li><Link to="/"> <a class="dropdown-item" href="#" style={{fontSize:"16px"}}>तकनीकी</a></Link></li>
        
    </ul>
</li>
</ul>
</li>
<li class="nav-item">
<Link to="/contactUs" href="#" ><p class= "nav-link my-nav-font" style={{color:'white',marginTop:"2px"}}>हमसे संपर्क करें</p></Link>
  </li>
</ul>
</div>
   
 
</nav>);
}
export default NavbarMenuHindi;