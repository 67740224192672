import React from 'react';
import './carousel.css';
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import Othertech from './othertech';
import NavbarMenu from './NavbarMenu.js';
function TechROHCS () {
    return (<div> <NavbarMenu /><div className="manual">
        <div className="row container">
        <p className="mypathhead">
        <Link to="/"><span class="mypath">Home</span></Link>&gt;<span className="mypath">NIOH Staff</span>&gt;<span className="mypath">Technical Staff</span>
        </p>
          
        </div>
        <h1 class="mypathhead">Technical Staff-Staff-NIOH-ROHCS Bengaluru Ahmedabad</h1>
        <div className="row row1">
            <div className="col-md-4">
                <div className="panel panel-primary">
                <div className="panel-body">
                    <div className="row">
                        <div className="col-md-4">
                        <img src='../assests/images/TechnicalStaffImg/malaROHCS.jpg' width="135px" height="160px;"></img>
                            
                            </div>
                            
                            <div className="col-md-8 carddata">
                                <table><tbody>
                                    <tr>
                                        <td width="20%"><button className="mybutton-top" ><i className="fa fa-user fa-1x" aria-hidden="true"></i></button></td>
                                        <td WIDTH="95%" className="myfontCard">A Mala</td>
                                    </tr>
                                    <tr>
                                        <td width="20%"></td>
                                        <td WIDTH="95%"className="myfontCardbody">Sr.Technical Officer (II)</td>
                                    </tr>
                                    <tr>
                                        <td width="10%"></td>
                                        <td WIDTH="95%"className="myfontCardbody">Statistics</td>
                                    </tr>
                                    <tr>
                                        <td width="20%"><button className="mybutton-top" ><i className="fa fa-history fa-1x" aria-hidden="true"></i></button></td>
                                        <td WIDTH="95%" className="myfontCardbody">31 Yrs Experience</td>
                                    </tr>
                                    <tr>
                                        <td width="20%"><button className="mybutton-top" ><i className="fa fa-envelope fa-1x" aria-hidden="true"></i></button></td>
                                        <td WIDTH="95%" className="myfontCardbody">mala.a@gov.in
    </td>
                                    </tr>
                            </tbody></table>
                        </div>
                    </div>
                </div>
                </div>
            </div>
            <div className="col-md-4">
                <div className="panel panel-primary">
                <div className="panel-body">
                    <div className="row">
                        <div className="col-md-4">
                            <img src='../assests/images/TechnicalStaffImg/tharanROHCS.jpeg' width="130px" height="160px;"></img>
                            
                        </div>
                        
                        <div className="col-md-8 carddata">
                            <table><tbody>
                                <tr>
                                    <td width="20%"><button className="mybutton-top" ><i className="fa fa-user fa-1x" aria-hidden="true"></i></button></td>
                                    <td WIDTH="95%" className="myfontCard">THARA.N</td>
                                </tr>
                                <tr>
                                    <td width="20%"></td>
                                    <td WIDTH="95%"className="myfontCardbody">Technical Officer - C</td>
                                </tr>
                                <tr>
                                    <td width="10%"></td>
                                    <td WIDTH="95%"className="myfontCardbody">Hygiene</td>
                                </tr>
                                <tr>
                                    <td width="20%"><button className="mybutton-top" ><i className="fa fa-history fa-1x" aria-hidden="true"></i></button></td>
                                    <td WIDTH="95%" className="myfontCardbody">34 Yrs Experience</td>
                                </tr>
                                <tr>
                                    <td width="20%"><button className="mybutton-top" ><i className="fa fa-envelope fa-1x" aria-hidden="true"></i></button></td>
                                    <td WIDTH="95%" className="myfontCardbody">thara.n@gov.in</td>
                                </tr>
                                
                            </tbody></table>
                        </div>
                    </div>
                </div>
                </div>
            </div>
            <div className="col-md-4">
                <div className="panel panel-primary">
                <div className="panel-body">
                <div className="row">
                        <div className="col-md-4">
                            <img src='../assests/images/TechnicalStaffImg/rajuROHCS.jpg' width="135px" height="160px;"></img>
                            
                        </div>
                        
                        <div className="col-md-8 carddata">
                            <table><tbody>
                                <tr>
                                    <td width="20%"><button className="mybutton-top" ><i className="fa fa-user fa-1x" aria-hidden="true"></i></button></td>
                                    <td WIDTH="95%" className="myfontCard">Dr. RAJU N</td>
                                </tr>
                                <tr>
                                    <td width="20%"></td>
                                    <td WIDTH="95%"className="myfontCardbody">Technical Officer-A</td>
                                </tr>
                                <tr>
                                    <td width="10%"></td>
                                    <td WIDTH="95%"className="myfontCardbody">Regional Occupational Health Centre (S)</td>
                                </tr>
                                <tr>
                                    <td width="20%"><button className="mybutton-top" ><i className="fa fa-history fa-1x" aria-hidden="true"></i></button></td>
                                    <td WIDTH="95%" className="myfontCardbody">4 Yrs Experience</td>
                                </tr>
                                <tr>
                                    <td width="20%"><button className="mybutton-top" ><i className="fa fa-envelope fa-1x" aria-hidden="true"></i></button></td>
                                    <td WIDTH="95%" className="myfontCardbody">nagaraju.raju@icmr.gov.in</td>
                                </tr>
                                
                            </tbody></table>
                        </div>
                    </div>
                </div>
                </div>
            </div>
            
        </div>
        <div className="row row1">
        <div className="col-md-4">
                <div className="panel panel-primary">
                <div className="panel-body">
                <div className="row">
                        <div className="col-md-4">
                            <img src='../assests/images/TechnicalStaffImg/PanjaROHCS.jpg' width="135px" height="160px;"></img>
                            
                        </div>
                        
                        <div className="col-md-8 carddata">
                            <table><tbody>
                                <tr>
                                    <td width="20%"><button className="mybutton-top" ><i className="fa fa-user fa-1x" aria-hidden="true"></i></button></td>
                                    <td WIDTH="95%" className="myfontCard">PANJAKUMAR K</td>
                                </tr>
                                <tr>
                                    <td width="20%"></td>
                                    <td WIDTH="95%"className="myfontCardbody">Technical Officer-A</td>
                                </tr>
                                <tr>
                                    <td width="10%"></td>
                                    <td WIDTH="95%"className="myfontCardbody">Industrial Hygiene</td>
                                </tr>
                                <tr>
                                    <td width="20%"><button className="mybutton-top" ><i className="fa fa-history fa-1x" aria-hidden="true"></i></button></td>
                                    <td WIDTH="95%" className="myfontCardbody">9.5 Yrs Experience</td>
                                </tr>
                                <tr>
                                    <td width="20%"><button className="mybutton-top" ><i className="fa fa-envelope fa-1x" aria-hidden="true"></i></button></td>
                                    <td WIDTH="95%" className="myfontCardbody">panjakumar.j@icmr.gov.in</td>
                                </tr>
                                
                            </tbody></table>
                        </div>
                    </div>
                </div>
                </div>
            </div>
            <div className="col-md-4">
                <div className="panel panel-primary">
                <div className="panel-body">
                    <div className="row">
                        <div className="col-md-4">
                            <img src='../assests/images/TechnicalStaffImg/jawaharROHCS.jpg' width="130px" height="160px;"></img>
                            
                        </div>
                        
                        <div className="col-md-8 carddata">
                            <table><tbody>
                                <tr>
                                    <td width="20%"><button className="mybutton-top" ><i className="fa fa-user fa-1x" aria-hidden="true"></i></button></td>
                                    <td WIDTH="95%" className="myfontCard">Salavath Jawahar</td>
                                </tr>
                                <tr>
                                    <td width="20%"></td>
                                    <td WIDTH="95%"className="myfontCardbody">Technical Officer - A</td>
                                </tr>
                                <tr>
                                    <td width="10%"></td>
                                    <td WIDTH="95%"className="myfontCardbody">Industrial Hygiene and Toxicology</td>
                                </tr>
                                <tr>
                                    <td width="20%"><button className="mybutton-top" ><i className="fa fa-history fa-1x" aria-hidden="true"></i></button></td>
                                    <td WIDTH="95%" className="myfontCardbody">4 Yrs Experience</td>
                                </tr>
                                <tr>
                                    <td width="20%"><button className="mybutton-top" ><i className="fa fa-envelope fa-1x" aria-hidden="true"></i></button></td>
                                    <td WIDTH="95%" className="myfontCardbody">salavat.j@icmr.gov.in</td>
                                </tr>
                                
                            </tbody></table>
                        </div>
                    </div>
                </div>
                </div>
            </div>
            <div className="col-md-4">
            <div className="panel panel-primary">
                <div className="panel-body">
                <div className="row">
                        <div className="col-md-4">
                            <img src='../assests/images/TechnicalStaffImg/PriyankaROHCS.jpg' width="135px" height="160px;"></img>
                            
                        </div>
                        
                        <div className="col-md-8 carddata">
                            <table><tbody>
                                <tr>
                                    <td width="20%"><button className="mybutton-top" ><i className="fa fa-user fa-1x" aria-hidden="true"></i></button></td>
                                    <td WIDTH="95%" className="myfontCard">Priyanka N Mankar</td>
                                </tr>
                                <tr>
                                    <td width="20%"></td>
                                    <td WIDTH="95%"className="myfontCardbody">Technical Officer - A</td>
                                </tr>
                                <tr>
                                    <td width="10%"></td>
                                    <td WIDTH="95%"className="myfontCardbody">Industrial Hygiene and Toxicology</td>
                                </tr>
                                <tr>
                                    <td width="20%"><button className="mybutton-top" ><i className="fa fa-history fa-1x" aria-hidden="true"></i></button></td>
                                    <td WIDTH="95%" className="myfontCardbody">4 Yrs Experience</td>
                                </tr>
                                <tr>
                                    <td width="20%"><button className="mybutton-top" ><i className="fa fa-envelope fa-1x" aria-hidden="true"></i></button></td>
                                    <td WIDTH="95%" className="myfontCardbody">mankar.pn@icmr.gov.in</td>
                                </tr>
                                
                            </tbody></table>
                        </div>
                    </div>
                </div>
                </div>
            </div>
            
        </div>
        <div className="row row1">
            <div className="col-md-4">
                <div className="panel panel-primary">
                <div className="panel-body">
                <div className="row">
                        <div className="col-md-4">
                            <img src='../assests/images/TechnicalStaffImg/RajaniROHCS.jpg' width="130px" height="160px;"></img>
                            
                        </div>
                        
                        <div className="col-md-8 carddata">
                            <table><tbody>
                                <tr>
                                    <td width="20%"><button className="mybutton-top" ><i className="fa fa-user fa-1x" aria-hidden="true"></i></button></td>
                                    <td WIDTH="95%" className="myfontCard">Dr. Rajani Ganpatraoji Tumane</td>
                                </tr>
                                <tr>
                                    <td width="20%"></td>
                                    <td WIDTH="95%"className="myfontCardbody">Technical Officer - A</td>
                                </tr>
                                <tr>
                                    <td width="10%"></td>
                                    <td WIDTH="95%"className="myfontCardbody">Biochemistry</td>
                                </tr>
                                <tr>
                                    <td width="20%"><button className="mybutton-top" ><i className="fa fa-history fa-1x" aria-hidden="true"></i></button></td>
                                    <td WIDTH="95%" className="myfontCardbody">3 Yrs Experience</td>
                                </tr>
                                <tr>
                                    <td width="20%"><button className="mybutton-top" ><i className="fa fa-envelope fa-1x" aria-hidden="true"></i></button></td>
                                    <td WIDTH="95%" className="myfontCardbody">tumane.rg@icmr.gov.in</td>
                                </tr>
                                
                            </tbody></table>
                        </div>
                    </div>
                </div>
                </div>
            </div>
            <div className="col-md-4">
                <div className="panel panel-primary">
                <div className="panel-body">
                <div className="row">
                        <div className="col-md-4">
                            <img src='../assests/images/TechnicalStaffImg/MOHANRAOROHCS.jpg' width="135px" height="160px;"></img>
                            
                        </div>
                        
                        <div className="col-md-8 carddata">
                            <table><tbody>
                                <tr>
                                    <td width="20%"><button className="mybutton-top" ><i className="fa fa-user fa-1x" aria-hidden="true"></i></button></td>
                                    <td WIDTH="95%" className="myfontCard">Mohanrao Kanchipamu</td>
                                </tr>
                                <tr>
                                    <td width="20%"></td>
                                    <td WIDTH="95%"className="myfontCardbody">Technical Assistant</td>
                                </tr>
                                <tr>
                                    <td width="10%"></td>
                                    <td WIDTH="95%"className="myfontCardbody">Medical Biochemistry</td>
                                </tr>
                                <tr>
                                    <td width="20%"><button className="mybutton-top" ><i className="fa fa-history fa-1x" aria-hidden="true"></i></button></td>
                                    <td WIDTH="95%" className="myfontCardbody">5 Yrs Experience</td>
                                </tr>
                                <tr>
                                    <td width="20%"><button className="mybutton-top" ><i className="fa fa-envelope fa-1x" aria-hidden="true"></i></button></td>
                                    <td WIDTH="95%" className="myfontCardbody">mohanrao.ka@icmr.gov.in</td>
                                </tr>
                                
                            </tbody></table>
                        </div>
                    </div>
                </div>
                </div>
            </div>
            <div className="col-md-4">
                <div className="panel panel-primary">
                <div className="panel-body">
                <div className="row">
                        <div className="col-md-4">
                            <img src='../assests/images/TechnicalStaffImg/AvinashROHCS.jpg' width="135px" height="160px;"></img>
                            
                        </div>
                        
                        <div className="col-md-8 carddata">
                            <table><tbody>
                                <tr>
                                    <td width="20%"><button className="mybutton-top" ><i className="fa fa-user fa-1x" aria-hidden="true"></i></button></td>
                                    <td WIDTH="95%" className="myfontCard">Avinash Shivaji Gaikwad</td>
                                </tr>
                                <tr>
                                    <td width="20%"></td>
                                    <td WIDTH="95%"className="myfontCardbody">Sr. Technician-1</td>
                                </tr>
                                <tr>
                                    <td width="10%"></td>
                                    <td WIDTH="95%"className="myfontCardbody">Industrial Hygiene and Toxicology</td>
                                </tr>
                                <tr>
                                    <td width="20%"><button className="mybutton-top" ><i className="fa fa-history fa-1x" aria-hidden="true"></i></button></td>
                                    <td WIDTH="95%" className="myfontCardbody">10 Yrs Experience</td>
                                </tr>
                                <tr>
                                    <td width="20%"><button className="mybutton-top" ><i className="fa fa-envelope fa-1x" aria-hidden="true"></i></button></td>
                                    <td WIDTH="95%" className="myfontCardbody">gaikwad.a@gov.in</td>
                                </tr>
                                
                            </tbody></table>
                        </div>
                    </div>
                </div>
                </div>
            </div>
            
        </div>
    
        <div className="row row1">
            <div className="col-md-4">
                <div className="panel panel-primary">
                <div className="panel-body">
                <div className="row">
                        <div className="col-md-4">
                            <img src='../assests/images/TechnicalStaffImg/ShridharROHCS.jpg' width="130px" height="160px;"></img>
                            
                        </div>
                        
                        <div className="col-md-8 carddata">
                            <table><tbody>
                                <tr>
                                    <td width="20%"><button className="mybutton-top" ><i className="fa fa-user fa-1x" aria-hidden="true"></i></button></td>
                                    <td WIDTH="95%" className="myfontCard">Kondhalkar Shridhar Jagannath</td>
                                </tr>
                                <tr>
                                    <td width="20%"></td>
                                    <td WIDTH="95%"className="myfontCardbody">Sr. Technician-1</td>
                                </tr>
                                <tr>
                                    <td width="10%"></td>
                                    <td WIDTH="95%"className="myfontCardbody">Industrial Hygiene and Toxicology</td>
                                </tr>
                                <tr>
                                    <td width="20%"><button className="mybutton-top" ><i className="fa fa-history fa-1x" aria-hidden="true"></i></button></td>
                                    <td WIDTH="95%" className="myfontCardbody">10 Yrs Experience</td>
                                </tr>
                                <tr>
                                    <td width="20%"><button className="mybutton-top" ><i className="fa fa-envelope fa-1x" aria-hidden="true"></i></button></td>
                                    <td WIDTH="95%" className="myfontCardbody">kondhalkar.s@gov.in</td>
                                </tr>
                                
                            </tbody></table>
                        </div>
                    </div>
                </div>
                </div>
            </div>
            <div className="col-md-4">
                <div className="panel panel-primary">
                <div className="panel-body">
                <div className="row">
                        <div className="col-md-4">
                            <img src='../assests/images/TechnicalStaffImg/RAJEEVROHCS.jpg' width="135px" height="160px;"></img>
                            
                        </div>
                        
                        <div className="col-md-8 carddata">
                            <table><tbody>
                                <tr>
                                    <td width="20%"><button className="mybutton-top" ><i className="fa fa-user fa-1x" aria-hidden="true"></i></button></td>
                                    <td WIDTH="95%" className="myfontCard">Rajeev Kumar Yadav</td>
                                </tr>
                                <tr>
                                    <td width="20%"></td>
                                    <td WIDTH="95%"className="myfontCardbody">Technician - C</td>
                                </tr>
                                <tr>
                                    <td width="10%"></td>
                                    <td WIDTH="95%"className="myfontCardbody">Industrial Hygiene & Toxicology</td>
                                </tr>
                                <tr>
                                    <td width="20%"><button className="mybutton-top" ><i className="fa fa-history fa-1x" aria-hidden="true"></i></button></td>
                                    <td WIDTH="95%" className="myfontCardbody">5 Yrs Experience</td>
                                </tr>
                                <tr>
                                    <td width="20%"><button className="mybutton-top" ><i className="fa fa-envelope fa-1x" aria-hidden="true"></i></button></td>
                                    <td WIDTH="95%" className="myfontCardbody">rajeev.ky@icmar.gov.in</td>
                                </tr>
                                
                            </tbody></table>
                        </div>
                    </div>
                </div>
                </div>
            </div>
            <div className="col-md-4">
                <div className="panel panel-primary">
                <div className="panel-body">
                <div className="row">
                        <div className="col-md-4">
                            <img src='../assests/images/TechnicalStaffImg/VinayROHCS.jpg' width="135px" height="160px;"></img>
                            
                        </div>
                        
                        <div className="col-md-8 carddata">
                            <table><tbody>
                                <tr>
                                    <td width="20%"><button className="mybutton-top" ><i className="fa fa-user fa-1x" aria-hidden="true"></i></button></td>
                                    <td WIDTH="95%" className="myfontCard">Adepu Vinay Kumar</td>
                                </tr>
                                <tr>
                                    <td width="20%"></td>
                                    <td WIDTH="95%"className="myfontCardbody">Technician - C</td>
                                </tr>
                                <tr>
                                    <td width="10%"></td>
                                    <td WIDTH="95%"className="myfontCardbody">ROHCS</td>
                                </tr>
                                <tr>
                                    <td width="20%"><button className="mybutton-top" ><i className="fa fa-history fa-1x" aria-hidden="true"></i></button></td>
                                    <td WIDTH="95%" className="myfontCardbody">6 Yrs Experience</td>
                                </tr>
                                <tr>
                                    <td width="20%"><button className="mybutton-top" ><i className="fa fa-envelope fa-1x" aria-hidden="true"></i></button></td>
                                    <td WIDTH="95%" className="myfontCardbody">adepu.vk@icmr.gov.in</td>
                                </tr>
                                
                            </tbody></table>
                        </div>
                    </div>
                </div>
                </div>
            </div>
            
        </div>
        
        <div className="row row1">
            <div className="col-md-4">
                <div className="panel panel-primary">
                <div className="panel-body">
                <div className="row">
                        <div className="col-md-4">
                            <img src='../assests/images/TechnicalStaffImg/ArshadROHCS.jpg' width="130px" height="160px;"></img>
                            
                        </div>
                        
                        <div className="col-md-8 carddata">
                            <table><tbody>
                                <tr>
                                    <td width="20%"><button className="mybutton-top" ><i className="fa fa-user fa-1x" aria-hidden="true"></i></button></td>
                                    <td WIDTH="95%" className="myfontCard">Arshad Ahmed</td>
                                </tr>
                                <tr>
                                    <td width="20%"></td>
                                    <td WIDTH="95%"className="myfontCardbody">Tehcnician - A</td>
                                </tr>
                                <tr>
                                    <td width="10%"></td>
                                    <td WIDTH="95%"className="myfontCardbody">Occupational Hygiene</td>
                                </tr>
                                <tr>
                                    <td width="20%"><button className="mybutton-top" ><i className="fa fa-history fa-1x" aria-hidden="true"></i></button></td>
                                    <td WIDTH="95%" className="myfontCardbody">5 Yrs Experience</td>
                                </tr>
                                <tr>
                                    <td width="20%"><button className="mybutton-top" ><i className="fa fa-envelope fa-1x" aria-hidden="true"></i></button></td>
                                    <td WIDTH="95%" className="myfontCardbody">ahmed.arshad@icmr.gov.in</td>
                                </tr>
                                
                            </tbody></table>
                        </div>
                    </div>
                </div>
                </div>
            </div>
            <div className="col-md-4">
                <div className="panel panel-primary">
                <div className="panel-body">
                <div className="row">
                        <div className="col-md-4">
                            <img src='../assests/images/TechnicalStaffImg/PapuROHCS.jpeg' width="135px" height="160px;"></img>
                            
                        </div>
                        
                        <div className="col-md-8 carddata">
                            <table><tbody>
                                <tr>
                                    <td width="20%"><button className="mybutton-top" ><i className="fa fa-user fa-1x" aria-hidden="true"></i></button></td>
                                    <td WIDTH="95%" className="myfontCard">Prakash Nayak J</td>
                                </tr>
                                <tr>
                                    <td width="20%"></td>
                                    <td WIDTH="95%"className="myfontCardbody">Technician - A</td>
                                </tr>
                                <tr>
                                    <td width="10%"></td>
                                    <td WIDTH="95%"className="myfontCardbody">BioChemistry</td>
                                </tr>
                                <tr>
                                    <td width="20%"><button className="mybutton-top" ><i className="fa fa-history fa-1x" aria-hidden="true"></i></button></td>
                                    <td WIDTH="95%" className="myfontCardbody">15 Yrs Experience</td>
                                </tr>
                                <tr>
                                    <td width="20%"><button className="mybutton-top" ><i className="fa fa-envelope fa-1x" aria-hidden="true"></i></button></td>
                                    <td WIDTH="95%" className="myfontCardbody">nayak.jp@gov.in</td>
                                </tr>
                                
                            </tbody></table>
                        </div>
                    </div>
                </div>
                </div>
            </div>
            <div className="col-md-4">
                <div className="panel panel-primary">
                <div className="panel-body">
                <div className="row">
                        <div className="col-md-4">
                            <img src='../assests/images/TechnicalStaffImg/AnilROHCS.jpg' width="135px" height="160px;"></img>
                            
                        </div>
                        
                        <div className="col-md-8 carddata">
                            <table><tbody>
                                <tr>
                                    <td width="20%"><button className="mybutton-top" ><i className="fa fa-user fa-1x" aria-hidden="true"></i></button></td>
                                    <td WIDTH="95%" className="myfontCard">Narayana S Anil Kumar</td>
                                </tr>
                                <tr>
                                    <td width="20%"></td>
                                    <td WIDTH="95%"className="myfontCardbody">Laboratory Attendant-1</td>
                                </tr>
                                <tr>
                                    <td width="10%"></td>
                                    <td WIDTH="95%"className="myfontCardbody">ROHCS</td>
                                </tr>
                                <tr>
                                    <td width="20%"><button className="mybutton-top" ><i className="fa fa-history fa-1x" aria-hidden="true"></i></button></td>
                                    <td WIDTH="95%" className="myfontCardbody">7 Yrs Experience</td>
                                </tr>
                                <tr>
                                    <td width="20%"><button className="mybutton-top" ><i className="fa fa-envelope fa-1x" aria-hidden="true"></i></button></td>
                                    <td WIDTH="95%" className="myfontCardbody">kumar.n@icmr.gov.in</td>
                                </tr>
                                
                            </tbody></table>
                        </div>
                    </div>
                </div>
                </div>
            </div>
            
        </div>
        
     
            </div>

        </div>);
}
export default TechROHCS;