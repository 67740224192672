import React from 'react';
import '../carousel.css';
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import NavbarMenu from '../NavbarMenu.js';
function DrGitika() {
    return (
      <div> <NavbarMenu /><div class="manual">
        <div class="row container">
        <p class="mypathhead">
        <Link to="/"><span class="mypath">Home</span></Link>&gt;<Link to="/ScientistNIOH"><span class="mypath">Scientist's Profile</span></Link>&gt;<span class="mypath">NIOH Ahmedabad</span>
        </p>
          
        </div>
        <h1 class="mypathhead">Scientist's Detail</h1>
        <div class="scientistdetail">
      
            <div class="panel my-panel" >
      <div class="panel-heading my-panel-head" align="left"><strong>Basic Details</strong></div>
      <div class="panel-body" align="left">
      <table width="100%">
        <thead>
          <th width="28%"></th>
          <th width="50%"></th>
          <th width="22%"></th>
        </thead>
        <tbody>
          <tr>
              <td  rowspan="5"><img src="../assests/images/niohScientist/" alt="GITIKA" width="170px" height="170px" className="dg-img"/></td>
              <td  ><p className="myfontSci trSci"> Dr Gitika Kharkwal</p> </td>
             
            </tr>
            
            <tr>
              
              <td className="myfontSci"><p className="myfontSci">Biological sciences Division</p></td>
              <td></td>
             </tr>
            <tr>
              <td><p ><a href=""><i className="fa fa-envelope" aria-hidden="true"></i>   Email: Kharkwal.g@icmr.gov.in</a></p></td>
              <td></td>
            </tr> 
            <tr>
              <td><p ><a href=""> <i className="fa fa-phone-square"></i>  Contact: 079-22688849</a></p></td>
              <td></td>
            </tr>   
        </tbody>
           
            
            </table>
      </div>
    </div>
       
        <div class="panel my-panel" >
      <div class="panel-heading my-panel-head" align="left"><strong>Academic Qualification</strong></div>
      <div class="panel-body" align="left">PhD (Microbiology)
</div>
    </div>
    <div class="panel my-panel" >
      <div class="panel-heading my-panel-head" align="left"><strong>Research Interest </strong></div>
      <div class="panel-body" align="left"> Cellular responses to industrial and environmental pollutants & hazardous Chemicals;  stem cell biology; environmental microbiology and antimicrobial resistance</div>
    </div>
    <div class="panel my-panel" >
      <div class="panel-heading my-panel-head" align="left"><strong>Membership/Affliation </strong></div>
      <div class="panel-body" align="left">
      NIL
</div>
</div>
<div class="panel my-panel">
      <div class="panel-heading my-panel-head" align="left"><strong>Fellowship/Awards </strong></div>
      <div class="panel-body" align="left">NIL
</div>
</div>
<div class="panel my-panel">
      <div class="panel-heading my-panel-head" align="left"><strong>Publications </strong></div>
      <div class="panel-body" align="left">
	
      <ol>

      <li>	Quercetin protects C6 glial cells from oxidative stress induced by tertiary-butylhydroperoxide <strong>Gitika, B.</strong>, Sai Ram, M., Sharma, et al. Free Radical Research, 40(1):95-102, 2006
</li><br/><li>	Dose response effects of 810 nm laser light on mouse primary cortical neurons. ‡. Sulbha K. Sharma, <strong>Gitika B. Kharkwal</strong>, et al. Lasers in Surgery and Medicine, 43(8) 851-859, 2011.‡Sulbha K. Sharma and <strong>Gitika B. Kharkwal</strong> contributed equally to this work.
</li><br/><li>	Photodynamic therapy for infections - clinical applications. <strong>Gitika B Kharkwal</strong>, Sulbha Sharma, et al. Lasers in Surgery and Medicine, Volume 43, Issue 7, pages 755–767, September 2011
</li><br/><li>	Antioxidant activities of sea buckthorn (Hippophaerhamnoides) during hypoxia induced oxidative stress in glial cells. S. Narayanan, D. Ruma, B. <strong>Gitika</strong>, et al.  Molecular and Cellular Biochemistry278: 9–14, 2005. 
</li><br/><li>	Ultraviolet-C Light for Treatment of Candida albicans Burn Infection in Mice. Tianhong Dai, <strong>Gitika B Kharkwal</strong>, et al. Photochemistry and Photobiology, 2011, 87: 342–349.
</li><br/><li>	 Low-level laser therapy activates NF-kB via generation of reactive oxygen species in mouse embryonic fibroblasts. Aron Chen, Praveen Arany, Ying Ying Huang, Elizabeth Tomkinson, Sulbha Sharma, <strong>Gitika Kharkwal</strong>, TaimurSaleem, David Mooney, Fiona Yell, Timothy Blackwell, Michael Hamblin. PLoSONEJuly 2011, 6: 7, e22453.
</li><br/><li>	 Photodynamic inactivation of bacteria using polyethyleneimine-chlorin(e6) conjugates, effect of polymer molecular weight, substitution ratio of chlorine(e6) and pH.Liyi Huang, TimurZhiyentayev, DulatAzhibek, <strong>Gitika Kharkwal</strong>, Michael R Hamblin. Lasers in Surgery and Medicine 43:313–323 (2011).
</li><br/><li>	Animal models of external traumatic wound infections. Tianhong Dai, <strong>Gitika Kharkwal</strong>, et al. Virulence, July/Aug 2011, Volume2, Issue 4.
</li><br/><li>	Cell Death Pathways in Photodynamic Therapy of Cancer. PawelMroz, Anastasia Yaroslavsky, <strong>Gitika Kharkwal</strong>, Michael R Hamblin. Cancers 2011, 3, 2516-2539.
</li><br/><li>	 Drug Discovery of Antimicrobial Photosensitizers Using Animal Models. Sharma Sulbha, Dai Tianhong, B Kharkwal <strong>Gitika</strong>, et al. Current Pharmacheutical Design, Volume 17, 13, May 2011, pp 1303-1319 (17).
</li><br/><li>	Comparison of therapeutic effects between pulsed and continuous wave irradiation in 810-nm   low-level laser therapy for traumatic brain injury in mice.  Takahiro Ando;WeijunXuan;TaoXu;TianhongDai;Sulbha K Sharma; <strong>Gitika B Kharkwal</strong> et al.  PLoS ONE 6(10): e26212.
</li><br/><li>	Dexamethasone Alters the Appetite Regulation via Inductionof Hypothalamic Insulin Resistance in Rat Brain. RagithaChruvattil, Shrey Banerjee, SarmiNath, JatinMachhi, <strong>Gitika Kharkwal</strong>, Mange Ram Yadav, Sarita Gupta. MolNeurobiol. 2016 Nov 7.
</li>
</ol>
	
	
	
</div>
</div>
</div>
        </div>
    </div>
    );
}
export default DrGitika;