import React from 'react';
import '../carousel.css';
import NavbarMenu from '../NavbarMenu.js';
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
function DrSwati() {
    return (
      <div> <NavbarMenu /><div class="manual">
        <div class="row container">
        <p class="mypathhead">
        <Link to="/"><span class="mypath">Home</span></Link>&gt;<Link to="/ScientistNIOH"><span class="mypath">Scientist's Profile</span></Link>&gt;<span class="mypath">NIOH Ahmedabad</span>
        </p>
          
        </div>
        <h1 class="mypathhead">Scientist's Detail</h1>
        <div class="scientistdetail">
      
            <div class="panel my-panel" >
      <div class="panel-heading my-panel-head" align="left"><strong>Basic Details</strong></div>
      <div class="panel-body" align="left">
      <table width="100%">
        <thead>
          <th width="28%"></th>
          <th width="50%"></th>
          <th width="22%"></th>
        </thead>
        <tbody>
          <tr>
              <td  rowspan="5"><img src="../assests/images/niohScientist/swati.JPEG" width="170px" height="170px" className="dg-img"/></td>
              <td  ><p className="myfontSci trSci"> Dr Swati Joshi</p> </td>
              <td  ><button className="mybuttonSci trSci">View Profile</button></td>
            </tr>
            
            <tr>
              
              <td className="myfontSci"><p className="myfontSci">Biological sciences Division </p></td>
              <td></td>
             </tr>
            <tr>
              <td><p ><a href=""><i className="fa fa-envelope" aria-hidden="true"></i>   Email: swati.joshi05@icmr.gov.in</a></p></td>
              <td></td>
            </tr> 
            <tr>
              <td><p ><a href=""> <i className="fa fa-phone-square"></i>  Contact: 079-22688981</a></p></td>
              <td></td>
            </tr>   
        </tbody>
           
            
            </table>
      </div>
    </div>
       
        <div class="panel my-panel" >
      <div class="panel-heading my-panel-head" align="left"><strong>Academic Qualification</strong></div>
      <div class="panel-body" align="left">Ph.D. Microbiology (University of Delhi South Campus, New Delhi)</div>
    </div>
    <div class="panel my-panel" >
      <div class="panel-heading my-panel-head" align="left"><strong>Research Interest </strong></div>
      <div class="panel-body" align="left">Microbiology and Molecular Biology (Microbial Enzymes, Protein engineering, Metabolic engineering, Extremophiles)</div>
    </div>
    <div class="panel my-panel" >
      <div class="panel-heading my-panel-head" align="left"><strong>Membership/Affliation </strong></div>
      <div class="panel-body" align="left">
      <ul><li>Association of Microbiologists of India (AMI)</li>
<li>	Biotech Research Society of India (BRSI)</li>
<li>	Lichenology Society of India (LSI) </li>
<li>	Mycology Society of India (MSI)</li>
<li>	Indian Science Congress Association (ISCA)</li>
<li>	Vigyan Gurjari</li>
</ul>
        </div>
        </div>
        <div class="panel my-panel">
      <div class="panel-heading my-panel-head" align="left"><strong>Fellowship/Awards </strong></div>
      <div class="panel-body" align="left"><ul><li>DBT-BioCARe Research Grant (2017-2020)</li>
<li>2018: Young Scientist Award by AMI</li>
<li> 2015: M.J. Thirumalachar Young Scientist Award by MSI</li>
<li> 2013: CSIR-International Travel Award to present paper at
International conference at Germany</li>
<li> 2012: Best Poster Award at National Conference by BRSI
and Punjabi University, Patiala</li>
<li> 2011: ICAR-NET</li>
<li>2010: CSIR NET-Junior Research Fellowship (AIR 77)</li>
<li> 2009: UGC NET-Junior Research Fellowship</li>
<li> 2008: Best Poster Award by Indian Science Congress
(ISCA), Jaipur Chapter</li>
<li> 2008, 2009: GATE</li>
<li> 2007: NCC ‘C’ Certificate</li>
<li> 2005: 1st Prize in National Integration Program by NCC
Directorate, Rajasthan</li>
<li> 2002: Best Student of the School Award</li>
<li> 1998, 2000: GARGI Award by Govt. of Rajasthan</li>

</ul>
</div>
</div>
<div class="panel my-panel">
      <div class="panel-heading my-panel-head" align="left"><strong>Publications </strong></div>
      <div class="panel-body" align="left">
	
      <ol >
	  <li><strong> Swati Joshi</strong>, Ashok Pandey, Ranjana Sirohi, Sung Hoon
Park (Eds.). Designer Microbial Cell Factories: Metabolic
Engineering and Applications. Elsevier, May 2022, ISBN:
9780323885041.</li><br/><li>	
<strong>Joshi, S</strong>*.,Nayaka, S., Azad, M., Bhawsar, H., Vinayaka, K.S., Mishra, J.P.N. (2020).Usnea bailey (Stirt.) Zahlbr, a new record from Karnataka, India. Cryptogram Biodiversity and Assessment, 4(1):25-27. ISSN: 2456-0251

</li><br/><li>	Basotra, N., <strong>Joshi, S</strong>.,Satyanarayana, T., Pati, P.K., Tsang, A. Chadha, B.S., (2018). Expression of catalytically efficient xylanases from thermophilic fungus Malbrancheacinnamomea for synergistically enhancing hydrolysis of lignocellulosics. International Journal of Biological Macromolecules, 108: 185-192, (IF: 8.025, ISSN: 0141-8130,PMID: 29174359)
</li><br/><li>
	Bajpai A., Singh B., <strong>Joshi S.</strong>,Johri, B.N. (2018). Production and characterization of an antifungal compound from Pseudomonas protegens Strain W45. Proceedings of the National Academy of Sciences, India Section B: Biological Sciences. 88: 1081-89. (IF: 0.96, ISSN: 2250-1746)

</li><br/><li>	Phadtare P., <strong>Joshi S.</strong>,Satyanarayana T. (2017). Recombinant thermo-alkali-stable endoglucanase of Myceliopthorathermophila BJA (rMt-egl): Biochemical characteristics and applicability in enzymatic saccharification of agro-residues. International Journal of Biological Macromolecules, 104:107–116.(IF: 8.025, ISSN: 0141-8130, PMID: 28591592)

</li><br/><li>	Mohapatra B., Sarkar A, <strong>Joshi S.</strong>, Chatterjee A., Kazy S.K., Maiti M.K., Satyanarayana T., Sar, P (2017). An arsenate reducing and alkane metabolizing novel bacterium, Rhizobium arsenicireducenssp. nov., isolated from arsenic rich groundwater. Archives of Microbiology, 199 (2), 191-201.(IF: 2.667, ISSN: 0302-8933, PMID: 27663709)

</li><br/><li>	Dua, A.,<strong>Joshi, S</strong>., Satyanarayana, T. (2017). Recombinant exochitinase of the thermophilic mould Myceliopthorathermophila BJA: Characteristics and utility in generating N‐acetyl glucosamine and in biocontrol of phytopathogenic fungi. Biotechnology Progress, 33: 70-80. (IF: 2.909, ISSN: 1520-6033 PMID: 27689686)

</li><br/><li>	Rattu, G., <strong>Joshi S.</strong>, Satyanarayana, T. (2016). Bifunctional recombinant cellulase-xylanase (rBhcell-xyl) from the polyextremophilic bacterium Bacillus halodurans TSLV1 and its utility in valorization of renewable agro-residues. Extremophiles, 20(6):831-842. (IF: 3.035, ISSN: 1433-4909 PMID: 27558695)

</li><br/><li>	<strong>Joshi, S</strong>.,Satyanarayana, T. (2015). Characteristics and applicability of phytase of the yeast Pichia anomala in synthesizing haloperoxidase.Applied Biochemistry and Biotechnology, 176:1351-1369. (IF: 3.094, ISSN: 1559-0291 PMID: 25957272)

</li><br/><li>	<strong>Joshi, S</strong>., Satyanarayana, T. (2015). In vitro Engineering of Microbial Enzymes with Multifarious Applications: Prospects and Perspectives.Bioresource Technology, 176; 271-283. (IF: 11.889, ISSN: 0960-8524, PMID: 25435065)

</li><br/><li>	<strong>Joshi, S</strong>., Satyanarayana, T. (2015). Heterologous expression of yeast and fungal phytases: Developments and future perspectives. Indian Journal of Biotechnology, 14; 293-311. (IF: 0.324, ISSN: 0975-0967)

</li><br/><li>	<strong>Joshi, S</strong>.,Satyanarayana, T. (2015).Bioprocess for efficient production ofrecombinant Pichia anomalaphytase and its applicability in dephytinization of chick feed and whole wheat flat Indian breads. Journal of Industrial Microbiology and Biotechnology, 42; 1389-1400. (IF: 4.258, ISSN: 1367-5435, PMID: 26264930)

</li><br/><li>	<strong>Joshi, S</strong>.,Satyanarayana, T. (2014).Optimization of heterologous expression of the phytase (PPHY) of Pichia anomala in P. pastoris and its applicability in fractionating allergenic glycinin from soy protein. Journal of Industrial Microbiology and Biotechnology, 41(6); 977-87. (IF: 4.258, ISSN: 1367-5435, PMID: 24668018)

</li><br/><li>	Kaur, P., <strong>Joshi, S</strong>., Satyanarayana, T., (2014). Psychrophilic and psychrotolerant mycelial fungi. Kavaka, 42; 112-122. (ISSN: 0379-5179)

</li><br/><li>	<strong>Joshi, S</strong>.,Satyanarayana, T. (2013). Characteristics and applications of a recombinant alkaline serine protease from a novel bacterium Bacillus lehensis. Bioresource Technology, 131(4); 76-85.  (IF: 11.889, ISSN:0960-8524, PMID: 23340105)

</li><br/><li>	<strong>Joshi, S</strong>., Satyanarayana, T. (2013). Biotechnology of Cold-Active Proteases. Biology, 2 (2); 755-783.  (IF: 5.079, ISSN 2079-7737, PMID: 24832807)
</li>
<br/><li> <strong>Joshi S</strong> * ., Pandey A. (2022), Metabolic engineering: Tools for
rewiring of pathways and creation of value. In Designer
Microbial Cell factories: Metabolic Engineering and
Applications. Eds. Joshi, S., Pandey, A., Sirohi, R., Park, S.H.,
Elsevier, pp 03-20. ISBN: 9780323885041
</li><br/><li> <strong>Joshi S</strong>., Bajpai A., Johri, B.N. (2020). Extremophilic fungi at
the interface of climate change. Eds. Sharma, V., Shah, M.,
Parmar, S., Kumar A. In Fungi Bio-Prospects in Sustainable
Agriculture, Environment and Nano-Technology. Vol.2.
Sustainable Agriculture, Environment and Nano-technology.
Elsevier, pp 1-21. (ISBN: 978-0-12-821925-6)
</li><br/><li> <strong>Joshi, S</strong> * ., Mohapatra, B., Mishra, J.P.N. (2018). Microbial soil
enzymes: Maintenance of rhizospheric eco-system and soil
health. In Advances in soil microbiology: Recent trends and
future prospects. Eds: Adhya T., Lal B., Mohapatra B., Paul D.,
Das S. Springer, Netherlands. pp. 179-192. (e-ISBN 978-981-
10-6178-3)
</li><br/><li> <strong>Joshi, S</strong>., Satynarayana, T. (2017). Phytase of the
unconventional yeast Pichia anomala: Production and
applications. In Yeast Diversity in Human Welfare. Eds:
Satyanarayana, Tulasi, Kunze, Gotthard Springer, Netherlands;
pp. 371-383. (ISBN 978-981- 10-2621- 8)
5. <strong>Joshi, S</strong>., Satyanarayana, T. (2015). Heterologous expression
of yeast and fungal phytases. In &#39;Food Technology and
Application of Microbes&#39; (Ed. P.C. Trivedi), Pointer Publishers,
Jaipur, pp. 89-115. (ISBN-13: 978-8171328284)

</li><br/><li> Satyanarayana, T., Sharma, A., Mehta, D., Puri, A.K., Kumar,
V., Nisha, M., <strong>Joshi S</strong>., (2012). Biotechnological Applications of
Biocatalysts from the Firmicutes Bacillus and Geobacillus
Species. Eds Satyanarayana, T., Prakash A, Johri B.N., In
Microorganisms in Sustainable Agriculture and Biotechnology,
Springer, Netherlands, pp. 343-379. (ISBN 978-94-007-2214-9)</li>

	
</ol>
	
</div>
	
</div>
</div>

        </div>
    </div>
    );
}
export default DrSwati;