import React from 'react';
import './carousel.css';
import {  Link } from "react-router-dom";
import NavbarMenu from './NavbarMenu.js';
function EdDissert() {
  return (<div> <NavbarMenu />
  <div class="manual">
  <div class="row container">
  <p class="mypathhead">
  <Link to="/"><span class="mypath">Home</span></Link>&gt;<span class="mypath">Education & Training</span>&gt;<span class="mypath">Dissertation</span>
  </p>
    
  </div>
  <h1 class="mypathhead">Dissertation</h1>
   <div class="container">
   <div class="panel panel-default scientistdetail">
              <div class="panel-body">
                <p style={{color:"Green",fontWeight:"bold",fontSize:"20px"}}>MSc / MPH / MVSc / MTech / MCA / MPharm Dissertation/Project/Summer Training Program during the period from Jan - Jun, 2024</p><br/>
                <p><Link to ='../edDetailNoti'><button type="button" class="btn btn-primary"style={{fontSize:"15px",borderRadius:"7px",marginRight:"20px"}}>Detailed Notification</button></Link></p>
                <p><a href="../assests/pdf/pdf/Shortlisted_candidates_Jan _Jun_2024.pdf" target="_blank"><i class="fa fa-file-pdf-o" style={{fontSize:"28px",color:"red"}}></i>First list of shortlisted candidate for Dissertation work Jan-June 2024</a></p>
                <p><a href="../assests/pdf/pdf/2nd_List_Diss_Jan_June_2024.pdf" target="_blank"><i class="fa fa-file-pdf-o" style={{fontSize:"28px",color:"red"}}></i>Second list of shortlisted candidate for Dissertation work Jan-June 2024</a></p>
              </div>
          </div><br/> <br/><br/><br/> <br/><br/><br/> <br/><br/><br/> <br/><br/><br/> <br/><br/><br/> <br/><br/><br/>
          </div>
 
  
  </div>
  </div>

);
}
export default EdDissert;