import React from 'react';
import '../carousel.css';
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import NavbarMenu from '../NavbarMenu.js';
function DrNikhil() {
    return (
      <div> <NavbarMenu /><div class="manual">
        <div class="row container">
        <p class="mypathhead">
        <Link to="/"><span class="mypath">Home</span></Link>&gt;<Link to="/ScientistNIOH"><span class="mypath">Scientist's Profile</span></Link>&gt;<span class="mypath">NIOH Ahmedabad</span>
        </p>
          
        </div>
        <h1 class="mypathhead">Scientist's Detail</h1>
        <div class="scientistdetail">
      
            <div class="panel my-panel" >
      <div class="panel-heading my-panel-head" align="left"><strong>Basic Details</strong></div>
      <div class="panel-body" align="left">
      <table width="100%">
        <thead>
          <th width="28%"></th>
          <th width="50%"></th>
          <th width="22%"></th>
        </thead>
        <tbody>
          <tr>
              <td  rowspan="5"><img src="../assests/images/niohScientist/nikhilK.png" width="170px" height="170px" className="dg-img"/></td>
              <td  ><p className="myfontSci trSci"> Dr Nikhil Kulkarni</p> </td>
              <td  ><button className="mybuttonSci trSci">View Profile</button></td>
            </tr>
            
            <tr>
              
              <td className="myfontSci"><p className="myfontSci">Chemical sciences Division </p></td>
              <td></td>
             </tr>
            <tr>
              <td><p ><a href=""><i className="fa fa-envelope" aria-hidden="true"></i>   Email: kulkarni.np@icmr.gov.in</a></p></td>
              <td></td>
            </tr> 
            <tr>
              <td><p ><a href=""> <i className="fa fa-phone-square"></i>  Contact: 079-22688466</a></p></td>
              <td></td>
            </tr>   
        </tbody>
           
            
            </table>
      </div>
    </div>
       
        <div class="panel my-panel" >
      <div class="panel-heading my-panel-head" align="left"><strong>Academic Qualification</strong></div>
      <div class="panel-body" align="left">M.Sc (Environmental Sciences), Ph.D. (Chemistry), DBM</div>
    </div>
    <div class="panel my-panel" >
      <div class="panel-heading my-panel-head" align="left"><strong>Research Interest </strong></div>
      <div class="panel-body" align="left">Air pollutant monitoring, Industrial hygiene (dust, noise, vibration, heat stress and illumination) assessment, Noise mapping, FTIR spectroscopy</div>
    </div>
    <div class="panel my-panel" >
      <div class="panel-heading my-panel-head" align="left"><strong>Membership/Affliation </strong></div>
      <div class="panel-body" align="left">
      <ul><li>Life Member of National Environmental Science Academy (NESA)

</li>

</ul>
        </div>
        </div>
        <div class="panel my-panel">
      <div class="panel-heading my-panel-head" align="left"><strong>Fellowship/Awards </strong></div>
      <div class="panel-body" align="left">To be Update
</div>
</div>
<div class="panel my-panel">
      <div class="panel-heading my-panel-head" align="left"><strong>Publications </strong></div>
      <div class="panel-body" align="left">
	
      <ol >
      <li>Vanerkar, A. P., <strong>N. P. Kulkarni</strong>, P. D. Zade, and A. S. Kamavisdar. "Whole body vibration exposure in heavy earth moving machinery operators of metalliferrous mines." Environmental monitoring and assessment 143, no. 1 (2008): 239-245.
</li><br/><li>	Tangde, V. M., S. S. Prajapati, B. B. Mandal, and <strong>N. P. Kulkarni</strong>. "Study of kinetics and thermodynamics of removal of phosphate from aqueous solution using activated red mud." International Journal of Environmental Research 11, no. 1 (2017): 39-47.
</li><br/><li>	<strong>Kulkarni Nikhil</strong>, and DilipParwate. "Evaluation of Advanced NAA and AAS Techniques Against Conventional Techniques for Analysis of Metals in Bauxite Ores." Analytical Chemistry Letters 7, no. 4 (2017): 458-469
</li><br/><li>	<strong>Kulkarni, N. P.</strong>, and Y. K. Agrawal. "Physico-Chemical characteristics of the topsoil of a hospital." Indian Journal of Environmental Protection 23 (2003): 503-507.
</li><br/><li>	Vanerkar, A. P., P. D. Zade, <strong>N. P. Kulkarni</strong>, and A. Kamavisdar. "Studies on monitoring of respirable dust in lime stone mine area-A case study." Indian Journal of Environmental Protection 26, no. 8 (2006): 724.
</li><br/><li>	Mandal, B. B., Debasis Chatterjee, <strong>N. P. Kulkarni</strong>, Prashant D. Zade, Atul P. Vanerkar, S. C. Narwadiya, and N. R. Thote. "Whole body vibration exposure of heavy earth moving machinery operators in Indian mines." Indian Mini Eng J 45, no. 9 (2006): 29-30 
</li><br/><li>	<strong>N. P. Kulkarni</strong>, D. V. Parwate, S. M. Kale. “Estimation of Aluminium level in iron ores by established and non conventional method” Journal of Advances in Science and Technology 14, no. 2 (2014): 19-21
</li><br/><li>	Deshmukh, AnandArun, <strong>Nikhil Pradip Kulkarni</strong>*, Sarang Vilas Dhatrak, and SubrotoShambhu Nandi. "Assessment of Occupational Dust and Silica Exposure in Indian Stone Mining and Crushing Unit-A Case Study." Current World Environment 12, no. 3 (2017): 663.
</li><br/><li>	Dusane AP, <strong>Kulkarni NP</strong>*, Durlov S, Lingayat KP, and Hussain SA: “Comfort perception and work posture with regards to work station in a bauxite mine” Indian Journal of Physiology and Allied Sciences 71(1) (2017):43-52
</li><br/><li>	P. Sivaperumal and <strong>N. Kulkarni</strong> : “The Business Process Outsourcing Industry and its associated Occupational Stress” FSAI Journal (July-August 2021):25-27 (By invitation)
 </li>
</ol>
	
	
	
</div>
</div>
</div>
        </div>
    </div>
    );
}
export default DrNikhil;