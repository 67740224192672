import React from 'react';
import './carousel.css';
import NavbarMenu from './NavbarMenu.js';
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
function Othertech () {
    return (<div> <NavbarMenu /><div class="manual">
         
    <div class="row container">
    <p class="mypathhead">
    <Link to="/"><span class="mypath">Home</span></Link>&gt;<span class="mypath">NIOH Staff</span>&gt;<span class="mypath">Technical Staff</span>
    </p>
      
    </div>
    <h1 class="mypathhead">Technical Staff-NIOH Ahmedabad</h1>
    <div class="row row1">
        <div class="col-md-4">
            <div class="panel panel-primary">
            <div class="panel-body">
                <div class="row">
                    <div class="col-md-4">
                        <img src='../assests/images/TechnicalStaffImg/man.png' width="135px" height="160px;"></img>
                        
                    </div>
                    
                    <div class="col-md-8 carddata">
                        <table>
                            <tr>
                                <td width="20%"><button class="mybutton-top" ><i class="fa fa-user fa-1x" aria-hidden="true"></i></button></td>
                                <td WIDTH="95%" CLASS="myfontCard">G P Kamble</td>
                            </tr>
                            <tr>
                                <td width="20%"></td>
                                <td WIDTH="95%"CLASS="myfontCardbody">Lab Assistant</td>
                            </tr>
                            <tr>
                                <td width="10%"></td>
                                <td WIDTH="95%"CLASS="myfontCardbody"></td>
                            </tr>
                            <tr>
                                <td width="20%"><button class="mybutton-top" ><i class="fa fa-history fa-1x" aria-hidden="true"></i></button></td>
                                <td WIDTH="95%" CLASS="myfontCardbody">Yrs Experience</td>
                            </tr>
                            <tr>
                                <td width="20%"><button class="mybutton-top" ><i class="fa fa-envelope fa-1x" aria-hidden="true"></i></button></td>
                                <td WIDTH="95%" CLASS="myfontCardbody">gp.kamble@gov.in</td>
                            </tr>
                            
                        </table>
                    </div>
                </div>
            </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="panel panel-primary">
            <div class="panel-body">
            <div class="row">
                    <div class="col-md-4">
                        <img src='../assests/images/TechnicalStaffImg/man.png' width="135px" height="160px;"></img>
                        
                    </div>
                    
                    <div class="col-md-8 carddata">
                        <table>
                            <tr>
                                <td width="20%"><button class="mybutton-top" ><i class="fa fa-user fa-1x" aria-hidden="true"></i></button></td>
                                <td WIDTH="95%" CLASS="myfontCard">M T Makwana</td>
                            </tr>
                            <tr>
                                <td width="20%"></td>
                                <td WIDTH="95%"CLASS="myfontCardbody">Lab Assistant</td>
                            </tr>
                            <tr>
                                <td width="10%"></td>
                                <td WIDTH="95%"CLASS="myfontCardbody"></td>
                            </tr>
                            <tr>
                                <td width="20%"><button class="mybutton-top" ><i class="fa fa-history fa-1x" aria-hidden="true"></i></button></td>
                                <td WIDTH="95%" CLASS="myfontCardbody"> Yrs Experience</td>
                            </tr>
                            <tr>
                                <td width="20%"><button class="mybutton-top" ><i class="fa fa-envelope fa-1x" aria-hidden="true"></i></button></td>
                                <td WIDTH="95%" CLASS="myfontCardbody">makwana.m@gov.in</td>
                            </tr>
                            
                        </table>
                    </div>
                </div>
            </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="panel panel-primary">
            <div class="panel-body">
            <div class="row">
                    <div class="col-md-4">
                        <img src='../assests/images/TechnicalStaffImg/man.png' width="135px" height="160px;"></img>
                        
                    </div>
                    
                    <div class="col-md-8 carddata">
                        <table>
                            <tr>
                                <td width="20%"><button class="mybutton-top" ><i class="fa fa-user fa-1x" aria-hidden="true"></i></button></td>
                                <td WIDTH="95%" CLASS="myfontCard">J P Valodara</td>
                            </tr>
                            <tr>
                                <td width="20%"></td>
                                <td WIDTH="95%"CLASS="myfontCardbody">Lab Assistant</td>
                            </tr>
                            <tr>
                                <td width="10%"></td>
                                <td WIDTH="95%"CLASS="myfontCardbody"></td>
                            </tr>
                            <tr>
                                <td width="20%"><button class="mybutton-top" ><i class="fa fa-history fa-1x" aria-hidden="true"></i></button></td>
                                <td WIDTH="95%" CLASS="myfontCardbody"> Yrs Experience</td>
                            </tr>
                            <tr>
                                <td width="20%"><button class="mybutton-top" ><i class="fa fa-envelope fa-1x" aria-hidden="true"></i></button></td>
                                <td WIDTH="95%" CLASS="myfontCardbody">valodara.jp@gov.in</td>
                            </tr>
                            
                        </table>
                    </div>
                </div>
            </div>
            </div>
        </div>
    </div>
    <div class="row row1">
        <div class="col-md-4">
            <div class="panel panel-primary">
            <div class="panel-body">
                <div class="row">
                    <div class="col-md-4">
                        <img src='../assests/images/TechnicalStaffImg/kanti.jpg' width="135px" height="160px;"></img>
                        
                    </div>
                    
                    <div class="col-md-8 carddata">
                        <table>
                            <tr>
                                <td width="20%"><button class="mybutton-top" ><i class="fa fa-user fa-1x" aria-hidden="true"></i></button></td>
                                <td WIDTH="95%" CLASS="myfontCard">Kantibhai G Chauhan</td>
                            </tr>
                            <tr>
                                <td width="20%"></td>
                                <td WIDTH="95%"CLASS="myfontCardbody">Lab Assistant</td>
                            </tr>
                            <tr>
                                <td width="10%"></td>
                                <td WIDTH="95%"CLASS="myfontCardbody">Library</td>
                            </tr>
                            <tr>
                                <td width="20%"><button class="mybutton-top" ><i class="fa fa-history fa-1x" aria-hidden="true"></i></button></td>
                                <td WIDTH="95%" CLASS="myfontCardbody">39 Yrs Experience</td>
                            </tr>
                            <tr>
                                <td width="20%"><button class="mybutton-top" ><i class="fa fa-envelope fa-1x" aria-hidden="true"></i></button></td>
                                <td WIDTH="95%" CLASS="myfontCardbody">chauhan.km@icmr.gov.in</td>
                            </tr>
                            
                        </table>
                    </div>
                </div>
            </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="panel panel-primary">
            <div class="panel-body">
            <div className="row">
                        <div className="col-md-4">
                            <img src='../assests/images/TechnicalStaffImg/Suresh Parmar.jpg' width="130px" height="160px;"></img>
                            
                        </div>
                        
                        <div className="col-md-8 carddata">
                            <table><tbody>
                                <tr>
                                    <td width="20%"><button className="mybutton-top" ><i className="fa fa-user fa-1x" aria-hidden="true"></i></button></td>
                                    <td WIDTH="95%" className="myfontCard">Sureshbhai M. Parmar</td>
                                </tr>
                                <tr>
                                    <td width="20%"></td>
                                    <td WIDTH="95%"className="myfontCardbody">Technician-B</td>
                                </tr>
                                <tr>
                                    <td width="10%"></td>
                                    <td WIDTH="95%"className="myfontCardbody">Toxicology</td>
                                </tr>
                                <tr>
                                    <td width="20%"><button className="mybutton-top" ><i className="fa fa-history fa-1x" aria-hidden="true"></i></button></td>
                                    <td WIDTH="95%" className="myfontCardbody">23 Yrs Experience</td>
                                </tr>
                                <tr>
                                    <td width="20%"><button className="mybutton-top" ><i className="fa fa-envelope fa-1x" aria-hidden="true"></i></button></td>
                                    <td WIDTH="95%" className="myfontCardbody">parmar.sm@gov.in</td>
                                </tr>
                                
                            </tbody></table>
                        </div>
                    </div>
            </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="panel panel-primary">
            <div class="panel-body">
            <div class="row">
                    <div class="col-md-4">
                        <img src='../assests/images/TechnicalStaffImg/man.png' width="130px" height="160px;"></img>
                        
                    </div>
                    
                    <div class="col-md-8 carddata">
                        <table>
                            <tr>
                                <td width="20%"><button class="mybutton-top" ><i class="fa fa-user fa-1x" aria-hidden="true"></i></button></td>
                                <td WIDTH="95%" CLASS="myfontCard">Uzma Kadri</td>
                            </tr>
                            <tr>
                                <td width="20%"></td>
                                <td WIDTH="95%"CLASS="myfontCardbody">Technician-A</td>
                            </tr>
                            <tr>
                                <td width="10%"></td>
                                <td WIDTH="95%"CLASS="myfontCardbody"></td>
                            </tr>
                            <tr>
                                <td width="20%"><button class="mybutton-top" ><i class="fa fa-history fa-1x" aria-hidden="true"></i></button></td>
                                <td WIDTH="95%" CLASS="myfontCardbody">Yrs Experience</td>
                            </tr>
                            <tr>
                                <td width="20%"><button class="mybutton-top" ><i class="fa fa-envelope fa-1x" aria-hidden="true"></i></button></td>
                                <td WIDTH="95%" CLASS="myfontCardbody">uzma190489@gmail.com</td>
                            </tr>
                            
                        </table>
                    </div>
                </div>
            </div>
            </div>
        </div>
    </div>
    <div class="row row1">
        <div class="col-md-4">
            <div class="panel panel-primary">
            <div class="panel-body">
                <div class="row">
                    <div class="col-md-4">
                        <img src='../assests/images/TechnicalStaffImg/ratnesh.jpg' width="135px" height="160px;"></img>
                        
                    </div>
                    
                    <div class="col-md-8 carddata">
                        <table>
                            <tr>
                                <td width="20%"><button class="mybutton-top" ><i class="fa fa-user fa-1x" aria-hidden="true"></i></button></td>
                                <td WIDTH="95%" CLASS="myfontCard">Ratnesh Vala</td>
                            </tr>
                            <tr>
                                <td width="20%"></td>
                                <td WIDTH="95%"CLASS="myfontCardbody">Technician-A</td>
                            </tr>
                            <tr>
                                <td width="10%"></td>
                                <td WIDTH="95%"CLASS="myfontCardbody">Chemical sciences</td>
                            </tr>
                            <tr>
                                <td width="20%"><button class="mybutton-top" ><i class="fa fa-history fa-1x" aria-hidden="true"></i></button></td>
                                <td WIDTH="95%" CLASS="myfontCardbody">4 Yrs Experience</td>
                            </tr>
                            <tr>
                                <td width="20%"><button class="mybutton-top" ><i class="fa fa-envelope fa-1x" aria-hidden="true"></i></button></td>
                                <td WIDTH="95%" CLASS="myfontCardbody">ratnesh.v@icmr.gov.in</td>
                            </tr>
                            
                        </table>
                    </div>
                </div>
            </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="panel panel-primary">
            <div class="panel-body">
            <div class="row">
                    <div class="col-md-4">
                        <img src='../assests/images/TechnicalStaffImg/jyotsna.jpg' width="135px" height="160px;"></img>
                        
                    </div>
                    
                    <div class="col-md-8 carddata">
                        <table>
                            <tr>
                                <td width="20%"><button class="mybutton-top" ><i class="fa fa-user fa-1x" aria-hidden="true"></i></button></td>
                                <td WIDTH="95%" CLASS="myfontCard">Jyotsna Verma</td>
                            </tr>
                            <tr>
                                <td width="20%"></td>
                                <td WIDTH="95%"CLASS="myfontCardbody">Technician-A</td>
                            </tr>
                            <tr>
                                <td width="10%"></td>
                                <td WIDTH="95%"CLASS="myfontCardbody">Health sciences</td>
                            </tr>
                            <tr>
                                <td width="20%"><button class="mybutton-top" ><i class="fa fa-history fa-1x" aria-hidden="true"></i></button></td>
                                <td WIDTH="95%" CLASS="myfontCardbody">4 Yrs Experience</td>
                            </tr>
                            <tr>
                                <td width="20%"><button class="mybutton-top" ><i class="fa fa-envelope fa-1x" aria-hidden="true"></i></button></td>
                                <td WIDTH="95%" CLASS="myfontCardbody">jyotsna.v@icmr.gov.in</td>
                            </tr>
                            
                        </table>
                    </div>
                </div>
            </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="panel panel-primary">
            <div class="panel-body">
            <div class="row">
                    <div class="col-md-4">
                        <img src='../assests/images/TechnicalStaffImg/bhavesh.jpg' width="130px" height="160px;"></img>
                        
                    </div>
                    
                    <div class="col-md-8 carddata">
                        <table>
                            <tr>
                                <td width="20%"><button class="mybutton-top" ><i class="fa fa-user fa-1x" aria-hidden="true"></i></button></td>
                                <td WIDTH="95%" CLASS="myfontCard">Vaghela BhaveshKumar</td>
                            </tr>
                            <tr>
                                <td width="20%"></td>
                                <td WIDTH="95%"CLASS="myfontCardbody">Technician-A</td>
                            </tr>
                            <tr>
                                <td width="10%"></td>
                                <td WIDTH="95%"CLASS="myfontCardbody">Store Section</td>
                            </tr>
                            <tr>
                                <td width="20%"><button class="mybutton-top" ><i class="fa fa-history fa-1x" aria-hidden="true"></i></button></td>
                                <td WIDTH="95%" CLASS="myfontCardbody">4 Yrs Experience</td>
                            </tr>
                            <tr>
                                <td width="20%"><button class="mybutton-top" ><i class="fa fa-envelope fa-1x" aria-hidden="true"></i></button></td>
                                <td WIDTH="95%" CLASS="myfontCardbody">vaghela.bd@icmr.gov.in</td>
                            </tr>
                            
                        </table>
                    </div>
                </div>
            </div>
            </div>
        </div>
    </div>
    <div class="row row1">
        <div class="col-md-4">
            <div class="panel panel-primary">
            <div class="panel-body">
                <div class="row">
                    <div class="col-md-4">
                        <img src='../assests/images/TechnicalStaffImg/Mansuri.jpg' width="135px" height="160px;"></img>
                        
                    </div>
                    
                    <div class="col-md-8 carddata">
                        <table>
                            <tr>
                                <td width="20%"><button class="mybutton-top" ><i class="fa fa-user fa-1x" aria-hidden="true"></i></button></td>
                                <td WIDTH="95%" CLASS="myfontCard">Moinuddhin Mansuri</td>
                            </tr>
                            <tr>
                                <td width="20%"></td>
                                <td WIDTH="95%"CLASS="myfontCardbody">Technician-A</td>
                            </tr>
                            <tr>
                                <td width="10%"></td>
                                <td WIDTH="95%"CLASS="myfontCardbody">Health sciences</td>
                            </tr>
                            <tr>
                                <td width="20%"><button class="mybutton-top" ><i class="fa fa-history fa-1x" aria-hidden="true"></i></button></td>
                                <td WIDTH="95%" CLASS="myfontCardbody">4 Yrs Experience</td>
                            </tr>
                            <tr>
                                <td width="20%"><button class="mybutton-top" ><i class="fa fa-envelope fa-1x" aria-hidden="true"></i></button></td>
                                <td WIDTH="95%" CLASS="myfontCardbody">mansuri.m@icmr.gov.in</td>
                            </tr>
                            
                        </table>
                    </div>
                </div>
            </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="panel panel-primary">
            <div class="panel-body">
            <div class="row">
                    <div class="col-md-4">
                        <img src='../assests/images/TechnicalStaffImg/praveen.jpg' width="135px" height="160px;"></img>
                        
                    </div>
                    
                    <div class="col-md-8 carddata">
                        <table>
                            <tr>
                                <td width="20%"><button class="mybutton-top" ><i class="fa fa-user fa-1x" aria-hidden="true"></i></button></td>
                                <td WIDTH="95%" CLASS="myfontCard">K Praveen Kumar</td>
                            </tr>
                            <tr>
                                <td width="20%"></td>
                                <td WIDTH="95%"CLASS="myfontCardbody">Technician-A</td>
                            </tr>
                            <tr>
                                <td width="10%"></td>
                                <td WIDTH="95%"CLASS="myfontCardbody">Health sciences</td>
                            </tr>
                            <tr>
                                <td width="20%"><button class="mybutton-top" ><i class="fa fa-history fa-1x" aria-hidden="true"></i></button></td>
                                <td WIDTH="95%" CLASS="myfontCardbody">4 Yrs Experience</td>
                            </tr>
                            <tr>
                                <td width="20%"><button class="mybutton-top" ><i class="fa fa-envelope fa-1x" aria-hidden="true"></i></button></td>
                                <td WIDTH="95%" CLASS="myfontCardbody">praveen.kk@icmr.gov.in</td>
                            </tr>
                            
                        </table>
                    </div>
                </div>
            </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="panel panel-primary">
            <div class="panel-body">
            <div class="row">
                    <div class="col-md-4">
                        <img src='../assests/images/TechnicalStaffImg/bipin.jpg' width="135px" height="160px;"></img>
                        
                    </div>
                    
                    <div class="col-md-8 carddata">
                        <table>
                            <tr>
                                <td width="20%"><button class="mybutton-top" ><i class="fa fa-user fa-1x" aria-hidden="true"></i></button></td>
                                <td WIDTH="95%" CLASS="myfontCard">Bipinchandra Govindbhai Chaudhari</td>
                            </tr>
                            <tr>
                                <td width="20%"></td>
                                <td WIDTH="95%"CLASS="myfontCardbody">Technician-A</td>
                            </tr>
                            <tr>
                                <td width="10%"></td>
                                <td WIDTH="95%"CLASS="myfontCardbody"></td>
                            </tr>
                            <tr>
                                <td width="20%"><button class="mybutton-top" ><i class="fa fa-history fa-1x" aria-hidden="true"></i></button></td>
                                <td WIDTH="95%" CLASS="myfontCardbody">28 Yrs Experience</td>
                            </tr>
                            <tr>
                                <td width="20%"><button class="mybutton-top" ><i class="fa fa-envelope fa-1x" aria-hidden="true"></i></button></td>
                                <td WIDTH="95%" CLASS="myfontCardbody">chaudhari.bg@icmr.gov.in</td>
                            </tr>
                            
                        </table>
                    </div>
                </div>
            </div>
            </div>
        </div>
    </div>
    <div class="row row1">
        <div class="col-md-4">
            <div class="panel panel-primary">
            <div class="panel-body">
            <div class="row">
                    <div class="col-md-4">
                        <img src='../assests/images/TechnicalStaffImg/manekben.jpg' width="135px" height="160px;"></img>
                        
                    </div>
                    
                    <div class="col-md-8 carddata">
                        <table>
                            <tr>
                                <td width="20%"><button class="mybutton-top" ><i class="fa fa-user fa-1x" aria-hidden="true"></i></button></td>
                                <td WIDTH="95%" CLASS="myfontCard">Manekben V Shrimali</td>
                            </tr>
                            <tr>
                                <td width="20%"></td>
                                <td WIDTH="95%"CLASS="myfontCardbody">MTS (Technical)</td>
                            </tr>
                            <tr>
                                <td width="10%"></td>
                                <td WIDTH="95%"CLASS="myfontCardbody">Hindi Cell</td>
                            </tr>
                            <tr>
                                <td width="20%"><button class="mybutton-top" ><i class="fa fa-history fa-1x" aria-hidden="true"></i></button></td>
                                <td WIDTH="95%" CLASS="myfontCardbody">25 Yrs Experience</td>
                            </tr>
                            <tr>
                                <td width="20%"><button class="mybutton-top" ><i class="fa fa-envelope fa-1x" aria-hidden="true"></i></button></td>
                                <td WIDTH="95%" CLASS="myfontCardbody">shrimali.mv@icmr.gov.in</td>
                            </tr>
                            
                        </table>
                    </div>
                </div> 
            </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="panel panel-primary">
            <div class="panel-body">
            <div class="row">
                    <div class="col-md-4">
                        <img src='../assests/images/TechnicalStaffImg/man.png' width="130px" height="160px;"></img>
                        
                    </div>
                    
                    <div class="col-md-8 carddata">
                        <table>
                            <tr>
                                <td width="20%"><button class="mybutton-top" ><i class="fa fa-user fa-1x" aria-hidden="true"></i></button></td>
                                <td WIDTH="95%" CLASS="myfontCard">Satyanarayan</td>
                            </tr>
                            <tr>
                                <td width="20%"></td>
                                <td WIDTH="95%"CLASS="myfontCardbody">MTS(Tech)</td>
                            </tr>
                            <tr>
                                <td width="10%"></td>
                                <td WIDTH="95%"CLASS="myfontCardbody"></td>
                            </tr>
                            <tr>
                                <td width="20%"><button class="mybutton-top" ><i class="fa fa-history fa-1x" aria-hidden="true"></i></button></td>
                                <td WIDTH="95%" CLASS="myfontCardbody">Yrs Experience</td>
                            </tr>
                            <tr>
                                <td width="20%"><button class="mybutton-top" ><i class="fa fa-envelope fa-1x" aria-hidden="true"></i></button></td>
                                <td WIDTH="95%" CLASS="myfontCardbody">satyanarayanj088@gmail.com</td>
                            </tr>
                            
                        </table>
                    </div>
                </div>
            </div>
            </div>
        </div>
        
        <div class="col-md-4">
            <div class="panel panel-primary">
            <div class="panel-body">
            <div class="row">
                    <div class="col-md-4">
                        <img src='../assests/images/TechnicalStaffImg/man.png' width="135px" height="160px;"></img>
                        
                    </div>
                    
                    <div class="col-md-8 carddata">
                        <table>
                            <tr>
                                <td width="20%"><button class="mybutton-top" ><i class="fa fa-user fa-1x" aria-hidden="true"></i></button></td>
                                <td WIDTH="95%" CLASS="myfontCard">Dipak Dabhi</td>
                            </tr>
                            <tr>
                                <td width="20%"></td>
                                <td WIDTH="95%"CLASS="myfontCardbody">Lab Attendant-1</td>
                            </tr>
                            <tr>
                                <td width="10%"></td>
                                <td WIDTH="95%"CLASS="myfontCardbody"></td>
                            </tr>
                            <tr>
                                <td width="20%"><button class="mybutton-top" ><i class="fa fa-history fa-1x" aria-hidden="true"></i></button></td>
                                <td WIDTH="95%" CLASS="myfontCardbody"> Yrs Experience</td>
                            </tr>
                            <tr>
                                <td width="20%"><button class="mybutton-top" ><i class="fa fa-envelope fa-1x" aria-hidden="true"></i></button></td>
                                <td WIDTH="95%" CLASS="myfontCardbody">dabhi.dg@icmr.gov.in</td>
                            </tr>
                            
                        </table>
                    </div>
                </div>
            </div>
            </div>
        </div>
    </div>
    <div class="row row1">
        <div class="col-md-4">
            <div class="panel panel-primary">
            <div class="panel-body">
                <div class="row">
                    <div class="col-md-4">
                        <img src='../assests/images/TechnicalStaffImg/Chandrakant.jpg' width="135px" height="160px;"></img>
                        
                    </div>
                    
                    <div class="col-md-8 carddata">
                        <table>
                            <tr>
                                <td width="20%"><button class="mybutton-top" ><i class="fa fa-user fa-1x" aria-hidden="true"></i></button></td>
                                <td WIDTH="95%" CLASS="myfontCard">Chandrakant G Parmar</td>
                            </tr>
                            <tr>
                                <td width="20%"></td>
                                <td WIDTH="95%"CLASS="myfontCardbody">Lab Attendant-2</td>
                            </tr>
                            <tr>
                                <td width="10%"></td>
                                <td WIDTH="95%"CLASS="myfontCardbody">Chemical Sciences</td>
                            </tr>
                            <tr>
                                <td width="20%"><button class="mybutton-top" ><i class="fa fa-history fa-1x" aria-hidden="true"></i></button></td>
                                <td WIDTH="95%" CLASS="myfontCardbody">8 Yrs Experience</td>
                            </tr>
                            <tr>
                                <td width="20%"><button class="mybutton-top" ><i class="fa fa-envelope fa-1x" aria-hidden="true"></i></button></td>
                                <td WIDTH="95%" CLASS="myfontCardbody">parmar.c@gov.in</td>
                            </tr>
                            
                        </table>
                    </div>
                </div>
            </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="panel panel-primary">
            <div class="panel-body">
            <div class="row">
                    <div class="col-md-4">
                        <img src='../assests/images/TechnicalStaffImg/vinod.jpg' width="130px" height="160px;"></img>
                        
                    </div>
                    
                    <div class="col-md-8 carddata">
                        <table>
                            <tr>
                                <td width="20%"><button class="mybutton-top" ><i class="fa fa-user fa-1x" aria-hidden="true"></i></button></td>
                                <td WIDTH="95%" CLASS="myfontCard">Vinod Patni </td>
                            </tr>
                            <tr>
                                <td width="20%"></td>
                                <td WIDTH="95%"CLASS="myfontCardbody">Lab Attendant-1</td>
                            </tr>
                            <tr>
                                <td width="10%"></td>
                                <td WIDTH="95%"CLASS="myfontCardbody">Director Office</td>
                            </tr>
                            <tr>
                                <td width="20%"><button class="mybutton-top" ><i class="fa fa-history fa-1x" aria-hidden="true"></i></button></td>
                                <td WIDTH="95%" CLASS="myfontCardbody">7 Yrs Experience</td>
                            </tr>
                            <tr>
                                <td width="20%"><button class="mybutton-top" ><i class="fa fa-envelope fa-1x" aria-hidden="true"></i></button></td>
                                <td WIDTH="95%" CLASS="myfontCardbody">patni.vs@gov.in</td>
                            </tr>
                            
                        </table>
                    </div>
                </div>
            </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="panel panel-primary">
            <div class="panel-body">
            <div class="row">
                    <div class="col-md-4">
                        <img src='../assests/images/TechnicalStaffImg/man.png' width="135px" height="160px;"></img>
                        
                    </div>
                    
                    <div class="col-md-8 carddata">
                        <table>
                            <tr>
                                <td width="20%"><button class="mybutton-top" ><i class="fa fa-user fa-1x" aria-hidden="true"></i></button></td>
                                <td WIDTH="95%" CLASS="myfontCard">Zakir Hussain</td>
                            </tr>
                            <tr>
                                <td width="20%"></td>
                                <td WIDTH="95%"CLASS="myfontCardbody">Lab Attendant-1</td>
                            </tr>
                            <tr>
                                <td width="10%"></td>
                                <td WIDTH="95%"CLASS="myfontCardbody"></td>
                            </tr>
                            <tr>
                                <td width="20%"><button class="mybutton-top" ><i class="fa fa-history fa-1x" aria-hidden="true"></i></button></td>
                                <td WIDTH="95%" CLASS="myfontCardbody"> Yrs Experience</td>
                            </tr>
                            <tr>
                                <td width="20%"><button class="mybutton-top" ><i class="fa fa-envelope fa-1x" aria-hidden="true"></i></button></td>
                                <td WIDTH="95%" CLASS="myfontCardbody">zakir.deaf4@gmail.com</td>
                            </tr>
                            
                        </table>
                    </div>
                </div>
            </div>
            </div>
        </div>
    </div>
    <div class="row row1">
        <div class="col-md-4">
            <div class="panel panel-primary">
            <div class="panel-body">
                <div class="row">
                    <div class="col-md-4">
                        <img src='../assests/images/TechnicalStaffImg/krishan.jpg' width="135px" height="160px;"></img>
                        
                    </div>
                    
                    <div class="col-md-8 carddata">
                        <table>
                            <tr>
                                <td width="20%"><button class="mybutton-top" ><i class="fa fa-user fa-1x" aria-hidden="true"></i></button></td>
                                <td WIDTH="95%" CLASS="myfontCard">Krishan S Khemnani</td>
                            </tr>
                            <tr>
                                <td width="20%"></td>
                                <td WIDTH="95%"CLASS="myfontCardbody">Lab Attendant-1</td>
                            </tr>
                            <tr>
                                <td width="10%"></td>
                                <td WIDTH="95%"CLASS="myfontCardbody">Store Section</td>
                            </tr>
                            <tr>
                                <td width="20%"><button class="mybutton-top" ><i class="fa fa-history fa-1x" aria-hidden="true"></i></button></td>
                                <td WIDTH="95%" CLASS="myfontCardbody">5 Yrs Experience</td>
                            </tr>
                            <tr>
                                <td width="20%"><button class="mybutton-top" ><i class="fa fa-envelope fa-1x" aria-hidden="true"></i></button></td>
                                <td WIDTH="95%" CLASS="myfontCardbody">khemnani.ks@icmr.gov.in</td>
                            </tr>
                            
                        </table>
                    </div>
                </div>
            </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="panel panel-primary">
            <div class="panel-body">
            <div class="row">
                    <div class="col-md-4">
                        <img src='../assests/images/TechnicalStaffImg/Kaushik.png' width="130px" height="160px;"></img>
                        
                    </div>
                    
                    <div class="col-md-8 carddata">
                        <table>
                            <tr>
                                <td width="20%"><button class="mybutton-top" ><i class="fa fa-user fa-1x" aria-hidden="true"></i></button></td>
                                <td WIDTH="95%" CLASS="myfontCard">Kaushikbhai D Rana </td>
                            </tr>
                            <tr>
                                <td width="20%"></td>
                                <td WIDTH="95%"CLASS="myfontCardbody">Lab Attendant-1</td>
                            </tr>
                            <tr>
                                <td width="10%"></td>
                                <td WIDTH="95%"CLASS="myfontCardbody">Establishment Section</td>
                            </tr>
                            <tr>
                                <td width="20%"><button class="mybutton-top" ><i class="fa fa-history fa-1x" aria-hidden="true"></i></button></td>
                                <td WIDTH="95%" CLASS="myfontCardbody">5 Yrs Experience</td>
                            </tr>
                            <tr>
                                <td width="20%"><button class="mybutton-top" ><i class="fa fa-envelope fa-1x" aria-hidden="true"></i></button></td>
                                <td WIDTH="95%" CLASS="myfontCardbody">Rana.kbd@icmr.gov.in</td>
                            </tr>
                            
                        </table>
                    </div>
                </div>
            </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="panel panel-primary">
            <div class="panel-body">
            <div class="row">
                    <div class="col-md-4">
                        <img src='../assests/images/TechnicalStaffImg/sagar.jpg' width="135px" height="160px;"></img>
                        
                    </div>
                    
                    <div class="col-md-8 carddata">
                        <table>
                            <tr>
                                <td width="20%"><button class="mybutton-top" ><i class="fa fa-user fa-1x" aria-hidden="true"></i></button></td>
                                <td WIDTH="95%" CLASS="myfontCard">Dalwadi Sagar </td>
                            </tr>
                            <tr>
                                <td width="20%"></td>
                                <td WIDTH="95%"CLASS="myfontCardbody">Lab Attendant-1</td>
                            </tr>
                            <tr>
                                <td width="10%"></td>
                                <td WIDTH="95%"CLASS="myfontCardbody">Director Office </td>
                            </tr>
                            <tr>
                                <td width="20%"><button class="mybutton-top" ><i class="fa fa-history fa-1x" aria-hidden="true"></i></button></td>
                                <td WIDTH="95%" CLASS="myfontCardbody">5 Yrs Experience</td>
                            </tr>
                            <tr>
                                <td width="20%"><button class="mybutton-top" ><i class="fa fa-envelope fa-1x" aria-hidden="true"></i></button></td>
                                <td WIDTH="95%" CLASS="myfontCardbody">dalwadi.sa@icmr.gov.in</td>
                            </tr>
                            
                        </table>
                    </div>
                </div>
            </div>
            </div>
        </div>
    </div>
    <div class="row row1">
        <div class="col-md-4">
            <div class="panel panel-primary">
            <div class="panel-body">
                <div class="row">
                    <div class="col-md-4">
                        <img src='../assests/images/TechnicalStaffImg/man.png' width="135px" height="160px;"></img>
                        
                    </div>
                    
                    <div class="col-md-8 carddata">
                        <table>
                            <tr>
                                <td width="20%"><button class="mybutton-top" ><i class="fa fa-user fa-1x" aria-hidden="true"></i></button></td>
                                <td WIDTH="95%" CLASS="myfontCard">Sanket A Patni</td>
                            </tr>
                            <tr>
                                <td width="20%"></td>
                                <td WIDTH="95%"CLASS="myfontCardbody">Lab Attendant-1</td>
                            </tr>
                            <tr>
                                <td width="10%"></td>
                                <td WIDTH="95%"CLASS="myfontCardbody"></td>
                            </tr>
                            <tr>
                                <td width="20%"><button class="mybutton-top" ><i class="fa fa-history fa-1x" aria-hidden="true"></i></button></td>
                                <td WIDTH="95%" CLASS="myfontCardbody"> Yrs Experience</td>
                            </tr>
                            <tr>
                                <td width="20%"><button class="mybutton-top" ><i class="fa fa-envelope fa-1x" aria-hidden="true"></i></button></td>
                                <td WIDTH="95%" CLASS="myfontCardbody">patani.sa@icmr.gov.in</td>
                            </tr>
                            
                        </table>
                    </div>
                </div>
            </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="panel panel-primary">
            <div class="panel-body">
            <div class="row">
                    <div class="col-md-4">
                        <img src='../assests/images/TechnicalStaffImg/vikki.jpeg' width="130px" height="160px;"></img>
                        
                    </div>
                    
                    <div class="col-md-8 carddata">
                        <table>
                            <tr>
                                <td width="20%"><button class="mybutton-top" ><i class="fa fa-user fa-1x" aria-hidden="true"></i></button></td>
                                <td WIDTH="95%" CLASS="myfontCard">Vikki Rajesh kothekar</td>
                            </tr>
                            <tr>
                                <td width="20%"></td>
                                <td WIDTH="95%"CLASS="myfontCardbody">Lab Attendant-1</td>
                            </tr>
                            <tr>
                                <td width="10%"></td>
                                <td WIDTH="95%"CLASS="myfontCardbody">Health Sciences</td>
                            </tr>
                            <tr>
                                <td width="20%"><button class="mybutton-top" ><i class="fa fa-history fa-1x" aria-hidden="true"></i></button></td>
                                <td WIDTH="95%" CLASS="myfontCardbody">3.5 Yrs Experience</td>
                            </tr>
                            <tr>
                                <td width="20%"><button class="mybutton-top" ><i class="fa fa-envelope fa-1x" aria-hidden="true"></i></button></td>
                                <td WIDTH="95%" CLASS="myfontCardbody">kothekar.vr@icmr.gov.in</td>
                            </tr>
                            
                        </table>
                    </div>
                </div>
            </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="panel panel-primary">
            <div class="panel-body">
            <div class="row">
                    <div class="col-md-4">
                        <img src='../assests/images/TechnicalStaffImg/man.png' width="135px" height="160px;"></img>
                        
                    </div>
                    
                    <div class="col-md-8 carddata">
                        <table>
                            <tr>
                                <td width="20%"><button class="mybutton-top" ><i class="fa fa-user fa-1x" aria-hidden="true"></i></button></td>
                                <td WIDTH="95%" CLASS="myfontCard">Ravibhai P Patni</td>
                            </tr>
                            <tr>
                                <td width="20%"></td>
                                <td WIDTH="95%"CLASS="myfontCardbody">Lab Attendant-1</td>
                            </tr>
                            <tr>
                                <td width="10%"></td>
                                <td WIDTH="95%"CLASS="myfontCardbody"></td>
                            </tr>
                            <tr>
                                <td width="20%"><button class="mybutton-top" ><i class="fa fa-history fa-1x" aria-hidden="true"></i></button></td>
                                <td WIDTH="95%" CLASS="myfontCardbody"> Yrs Experience</td>
                            </tr>
                            <tr>
                                <td width="20%"><button class="mybutton-top" ><i class="fa fa-envelope fa-1x" aria-hidden="true"></i></button></td>
                                <td WIDTH="95%" CLASS="myfontCardbody"></td>
                            </tr>
                            
                        </table>
                    </div>
                </div>
            </div>
            </div>
        </div>
    </div>
    <div class="row row1">
        <div class="col-md-4">
            <div class="panel panel-primary">
            <div class="panel-body">
                <div class="row">
                    <div class="col-md-4">
                        <img src='../assests/images/TechnicalStaffImg/nitesh.jpg' width="135px" height="160px;"></img>
                        
                    </div>
                    
                    <div class="col-md-8 carddata">
                        <table>
                            <tr>
                                <td width="20%"><button class="mybutton-top" ><i class="fa fa-user fa-1x" aria-hidden="true"></i></button></td>
                                <td WIDTH="95%" CLASS="myfontCard">Nitesh Rathore</td>
                            </tr>
                            <tr>
                                <td width="20%"></td>
                                <td WIDTH="95%"CLASS="myfontCardbody">Lab Attendant-1</td>
                            </tr>
                            <tr>
                                <td width="10%"></td>
                                <td WIDTH="95%"CLASS="myfontCardbody">Establishment Section</td>
                            </tr>
                            <tr>
                                <td width="20%"><button class="mybutton-top" ><i class="fa fa-history fa-1x" aria-hidden="true"></i></button></td>
                                <td WIDTH="95%" CLASS="myfontCardbody">3 Yrs Experience</td>
                            </tr>
                            <tr>
                                <td width="20%"><button class="mybutton-top" ><i class="fa fa-envelope fa-1x" aria-hidden="true"></i></button></td>
                                <td WIDTH="95%" CLASS="myfontCardbody">rathor.nh@icmr.gov.in</td>
                            </tr>
                            
                        </table>
                    </div>
                </div>
            </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="panel panel-primary">
            <div class="panel-body">
            <div class="row">
                    <div class="col-md-4">
                        <img src='../assests/images/TechnicalStaffImg/umesh.jpg' width="130px" height="160px;"></img>
                        
                    </div>
                    
                    <div class="col-md-8 carddata">
                        <table>
                            <tr>
                                <td width="20%"><button class="mybutton-top" ><i class="fa fa-user fa-1x" aria-hidden="true"></i></button></td>
                                <td WIDTH="95%" CLASS="myfontCard">Umesh Chand Kori</td>
                            </tr>
                            <tr>
                                <td width="20%"></td>
                                <td WIDTH="95%"CLASS="myfontCardbody">Lab Attendant-1</td>
                            </tr>
                            <tr>
                                <td width="10%"></td>
                                <td WIDTH="95%"CLASS="myfontCardbody">Health Sciences</td>
                            </tr>
                            <tr>
                                <td width="20%"><button class="mybutton-top" ><i class="fa fa-history fa-1x" aria-hidden="true"></i></button></td>
                                <td WIDTH="95%" CLASS="myfontCardbody">3 Yrs Experience</td>
                            </tr>
                            <tr>
                                <td width="20%"><button class="mybutton-top" ><i class="fa fa-envelope fa-1x" aria-hidden="true"></i></button></td>
                                <td WIDTH="95%" CLASS="myfontCardbody">umeshc.kori@icmr.gov.in</td>
                            </tr>
                            
                        </table>
                    </div>
                </div>
            </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="panel panel-primary">
            <div class="panel-body">
            <div class="row">
                    <div class="col-md-4">
                        <img src='../assests/images/TechnicalStaffImg/kalpesh.jpg' width="135px" height="160px;"></img>
                        
                    </div>
                    
                    <div class="col-md-8 carddata">
                        <table>
                            <tr>
                                <td width="20%"><button class="mybutton-top" ><i class="fa fa-user fa-1x" aria-hidden="true"></i></button></td>
                                <td WIDTH="95%" CLASS="myfontCard">Kalpesh Patani</td>
                            </tr>
                            <tr>
                                <td width="20%"></td>
                                <td WIDTH="95%"CLASS="myfontCardbody">Lab Attendant-1</td>
                            </tr>
                            <tr>
                                <td width="10%"></td>
                                <td WIDTH="95%"CLASS="myfontCardbody">Store Section</td>
                            </tr>
                            <tr>
                                <td width="20%"><button class="mybutton-top" ><i class="fa fa-history fa-1x" aria-hidden="true"></i></button></td>
                                <td WIDTH="95%" CLASS="myfontCardbody">3 Yrs Experience</td>
                            </tr>
                            <tr>
                                <td width="20%"><button class="mybutton-top" ><i class="fa fa-envelope fa-1x" aria-hidden="true"></i></button></td>
                                <td WIDTH="95%" CLASS="myfontCardbody">kalpesh.rp@icmr.gov.in</td>
                            </tr>
                            
                        </table>
                    </div>
                </div> 
            </div>
            </div>
        </div>
    </div>
    <div class="row row1">
        <div class="col-md-4">
            <div class="panel panel-primary">
            <div class="panel-body">
            <div class="row">
                    <div class="col-md-4">
                        <img src='../assests/images/TechnicalStaffImg/vijay patni.jpg' width="135px" height="160px;"></img>
                        
                    </div>
                    
                    <div class="col-md-8 carddata">
                        <table>
                            <tr>
                                <td width="20%"><button class="mybutton-top" ><i class="fa fa-user fa-1x" aria-hidden="true"></i></button></td>
                                <td WIDTH="95%" CLASS="myfontCard">Patni Vijay kumar</td>
                            </tr>
                            <tr>
                                <td width="20%"></td>
                                <td WIDTH="95%"CLASS="myfontCardbody">Lab attendant -1</td>
                            </tr>
                            <tr>
                                <td width="10%"></td>
                                <td WIDTH="95%"CLASS="myfontCardbody">Biostatastics</td>
                            </tr>
                            <tr>
                                <td width="20%"><button class="mybutton-top" ><i class="fa fa-history fa-1x" aria-hidden="true"></i></button></td>
                                <td WIDTH="95%" CLASS="myfontCardbody">8 Yrs Experience</td>
                            </tr>
                            <tr>
                                <td width="20%"><button class="mybutton-top" ><i class="fa fa-envelope fa-1x" aria-hidden="true"></i></button></td>
                                <td WIDTH="95%" CLASS="myfontCardbody">patni.vk@gov.in</td>
                            </tr>
                            
                        </table>
                    </div>
                </div> 
            </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="panel panel-primary">
            <div class="panel-body">
            <div class="row">
                    <div class="col-md-4">
                        <img src='../assests/images/TechnicalStaffImg/saswat dodiya.jpg' width="135px" height="160px;"></img>
                        
                    </div>
                    
                    <div class="col-md-8 carddata">
                        <table>
                            <tr>
                                <td width="20%"><button class="mybutton-top" ><i class="fa fa-user fa-1x" aria-hidden="true"></i></button></td>
                                <td WIDTH="95%" CLASS="myfontCard">Shashwat Lalsinh Dodia</td>
                            </tr>
                            <tr>
                                <td width="20%"></td>
                                <td WIDTH="95%"CLASS="myfontCardbody">Lab attendant -1</td>
                            </tr>
                            <tr>
                                <td width="10%"></td>
                                <td WIDTH="95%"CLASS="myfontCardbody">Biochemistry</td>
                            </tr>
                            <tr>
                                <td width="20%"><button class="mybutton-top" ><i class="fa fa-history fa-1x" aria-hidden="true"></i></button></td>
                                <td WIDTH="95%" CLASS="myfontCardbody">7 Yrs Experience</td>
                            </tr>
                            <tr>
                                <td width="20%"><button class="mybutton-top" ><i class="fa fa-envelope fa-1x" aria-hidden="true"></i></button></td>
                                <td WIDTH="95%" CLASS="myfontCardbody">dodia.sl@gov.in</td>
                            </tr>
                            
                        </table>
                    </div>
                </div> 
            </div>
            </div>
        </div>
        
        <div class="col-md-4">
        <div class="panel panel-primary">
            <div class="panel-body">
            <div class="row">
                    <div class="col-md-4">
                        <img src='../assests/images/TechnicalStaffImg/ravi.jpg' width="135px" height="160px;"></img>
                        
                    </div>
                    
                    <div class="col-md-8 carddata">
                        <table>
                            <tr>
                                <td width="20%"><button class="mybutton-top" ><i class="fa fa-user fa-1x" aria-hidden="true"></i></button></td>
                                <td WIDTH="95%" CLASS="myfontCard">Ravi Patni</td>
                            </tr>
                            <tr>
                                <td width="20%"></td>
                                <td WIDTH="95%"CLASS="myfontCardbody">Lab attendant -1</td>
                            </tr>
                            <tr>
                                <td width="10%"></td>
                                <td WIDTH="95%"CLASS="myfontCardbody">Biological Science Division</td>
                            </tr>
                            <tr>
                                <td width="20%"><button class="mybutton-top" ><i class="fa fa-history fa-1x" aria-hidden="true"></i></button></td>
                                <td WIDTH="95%" CLASS="myfontCardbody">3 Yrs Experience</td>
                            </tr>
                            <tr>
                                <td width="20%"><button class="mybutton-top" ><i class="fa fa-envelope fa-1x" aria-hidden="true"></i></button></td>
                                <td WIDTH="95%" CLASS="myfontCardbody">patniravi.rp@icmr.gov.in</td>
                            </tr>
                            
                        </table>
                    </div>
                </div> 
            </div>
            </div>
        </div>
    </div>
    
    <div class="row row1">
        
       
        <div className="col-md-12">
                <center>
                <Link to="/TechNioh"><button className="btn btn-primary" style={{fontSize:"19px", width:"35px", marginRight :"10px"}}>1</button></Link>
                <Link to="/othertech"><button className="btn btn-primary" style={{fontSize:"19px", width:"35px"}}>2</button></Link>
                   
                    <Routes>
                        <Route exact path='/othertech' element={<Othertech />}></Route> 
                    </Routes>
                </center>
            </div> 
    </div>
    </div> </div>);
}
export default Othertech;