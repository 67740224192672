import React from 'react';
import './carousel.css';
import {  Link } from "react-router-dom";
import NavbarMenu from './NavbarMenu.js';
function Recruitment () {
    return ( <div> <NavbarMenu /><div class="manual">
       
        <div class="row container">
        <p class="mypathhead">
        <Link to="/"><span class="mypath">Home</span></Link>&gt;<span class="mypath">Notification</span>&gt;<span class="mypath">Recruitment</span>
        </p>
          
        </div>
        <h1 class="mypathhead">Recruitment</h1>
        
        <div class="table-responsive-sm" >
            <center>
                <table width="90%"style={{fontSize:"18px"}} class="table table-bordered">
                <tr>
                    <th width="30%" style={{color:"white", backgroundColor: "#005A9C"}}><strong>Notification</strong></th>
                    <th width="10%" style={{color:"white", backgroundColor: "#005A9C"}}><strong>Post Type </strong></th>
                    <th width="10%" style={{color:"white", backgroundColor: "#005A9C"}}><strong>Notification Date</strong></th>
                    <th width="25%" style={{color:"white", backgroundColor: "#005A9C"}}><strong>Last Date </strong></th>
                    <th width="25%" style={{color:"white", backgroundColor: "#005A9C"}}><strong>Details </strong></th>
                    
                </tr>
                <tr>
                    <td  valign="center" ><p style={{fontSize:"18px"}}>Walk in interview for the Project Technical Support II and Project Technical Support I position  at ICMR-NIOH, Ahmedabad </p>
                   </td>
                    <td  valign="center" >Temporary</td>
                    <td  valign="center" ><p>20/08/2024</p></td>
                    <td  valign="center" ><p>04/09/2024, 10:00AM</p></td>
                    <td  valign="center" > <p><a href="../assests/pdf/jobs/2024/Adv_RCT_Nudges_Project_20082024.pdf" target="_blank"><i class="fa fa-file-pdf-o" style={{fontSize:"28px",color:"red"}}></i>Details</a></p>
                    
                    
                    </td>
                </tr>
                <tr>
                    <td  valign="center" ><p style={{fontSize:"18px"}}>FINAL LIST OF CANDIDATES SELECTED AND JOINED UNDER TECHNICAL CADRE RECRUITMENT AT ICMR-NIOH AHMEDABAD ADVERTISED VIDE NO. NIOH/RCT/TECHNICAL/2023-24 DATED 05.07.2023 </p>
                   </td>
                    <td  valign="center" >Permanent</td>
                    <td  valign="center" ><p>09/08/2024</p></td>
                    <td  valign="center" ><p></p></td>
                    <td  valign="center" > <p><a href="../assests/pdf/jobs/2024/Tech_FINAL_NOTIFICATION.pdf" target="_blank"><i class="fa fa-file-pdf-o" style={{fontSize:"28px",color:"red"}}></i>Details</a></p>
                    
                    
                    </td>
                </tr>
                <tr>
                    <td  valign="center" ><p style={{fontSize:"18px"}}>Vacancy notification for Lower Division Clerk (LDC) and Upper Division Cleark (UDC) at ICMR-NIOH Ahmedabad and ROHC Bengaluru</p>
                   </td>
                    <td  valign="center" >Permanent</td>
                    <td  valign="center" ><p>13/03/2024</p></td>
                    <td  valign="center" ><p>Extended date 11/06/2024 (experience submission)</p></td>
                    <td  valign="center" > <p ><a href="../assests/pdf/jobs/2024/NIOH_Vacancy_Notification_LDC_UDC_2024_en.pdf" target="_blank"><i class="fa fa-file-pdf-o" style={{fontSize:"28px",color:"red"}}></i>Details</a></p>
                    <p ><a href="../assests/pdf/jobs/2024/NIOH_Vacancy_Notification_LDC_UDC_2024_h.pdf" target="_blank"><i class="fa fa-file-pdf-o" style={{fontSize:"28px",color:"red"}}></i>विवरण</a></p>
                    <p ><a href="../assests/pdf/jobs/2024/Corrigendum_LDC_UDC_10042024.pdf" target="_blank"><i class="fa fa-file-pdf-o" style={{fontSize:"28px",color:"red"}}></i>Corrigendum for Date Extension</a></p>
                    <p ><a href="../assests/pdf/jobs/2024/RCT_Admin_25042024.pdf" target="_blank"><i class="fa fa-file-pdf-o" style={{fontSize:"28px",color:"red"}}></i>Corrigendum regarding Lower Division Clerk and mark on post qualification experience</a></p>
                    <p ><a href="https://niohrecruitment.org/" target="_blank"><i class="fa fa-file-pdf-o" style={{fontSize:"28px",color:"red"}}></i>Application submission link</a></p>
                    <p ><a href="../assests/pdf/jobs/2024/LDC_UDC_Exam_Syll_Experience_Notice.pdf" target="_blank"><i class="fa fa-file-pdf-o" style={{fontSize:"28px",color:"red"}}></i>Notification to update experience and Syllabus for Examination dated 31/05/2024</a></p>
                    <p ><a href="../assests/pdf/jobs/2024/Corrigendum_3_UDC.pdf" target="_blank"><i class="fa fa-file-pdf-o" style={{fontSize:"28px",color:"red"}}></i>Corrigendum for increase in Vacancy of UDC</a></p>
                    <p ><a href="../assests/pdf/jobs/2024/LDC_UDC_Screening_List_06082024.pdf" target="_blank"><i class="fa fa-file-pdf-o" style={{fontSize:"28px",color:"red"}}></i>List of Eligible and Inelibible candidate List for the post of LDC and UDC</a></p>
                    </td>
                </tr>
                <tr>
                    <td  valign="center" ><p style={{fontSize:"18px"}}>Recruitment notification for IT/GIS Officer at ICMR-NIOH, Ahmedabad </p>
                   </td>
                    <td  valign="center" >Temporary</td>
                    <td  valign="center" ><p>24/07/2024</p></td>
                    <td  valign="center" ><p>09/08/2024</p></td>
                    <td  valign="center" > <p><a href="../assests/pdf/jobs/2024/NIOH_EAICP_IT_Officer_Adv_24072024.pdf" target="_blank"><i class="fa fa-file-pdf-o" style={{fontSize:"28px",color:"red"}}></i>Details</a></p>
                    <p ><a href="../assests/pdf/jobs/2024/EIACP_Adv_Application.pdf" target="_blank"><i class="fa fa-file-pdf-o" style={{fontSize:"28px",color:"red"}}></i>Application Form</a></p>
                    
                    </td>
                </tr>
                <tr>
                    <td  valign="center" ><p style={{fontSize:"18px"}}>Walk in Interview for the Project Research Scientist-I (Medical), Project Technical Support-II, Project Technical Support-II, Project Technical Support-I positions at ICMR-NIOH Ahmedabad</p>
                   </td>
                    <td  valign="center" >Temporary</td>
                    <td  valign="center" ><p>09/07/2024</p></td>
                    <td  valign="center" ><p>Cancelled</p></td>
                    <td  valign="center" > <p ><a href="../assests/pdf/jobs/2024/Adv_TB Project_09072024.pdf" target="_blank"><i class="fa fa-file-pdf-o" style={{fontSize:"28px",color:"red"}}></i>Details</a></p>
                    <p ><a href="../assests/pdf/jobs/2024/Cancel_Notice_TB_PRJ_24072024.pdf" target="_blank"><i class="fa fa-file-pdf-o" style={{fontSize:"28px",color:"red"}}></i>Cancellation Notice</a></p>
                    </td>
                </tr>
                <tr>
                    <td  valign="center" ><p style={{fontSize:"18px"}}>Walk in interview for the Project Associate I position at NIOH Ahmedabad</p>
                   </td>
                    <td  valign="center" >Temporary</td>
                    <td  valign="center" ><p>05/07/2024</p></td>
                    <td  valign="center" ><p>Walk in interview 15/07/2024 10:00AM</p></td>
                    <td  valign="center" > <p ><a href="../assests/pdf/jobs/2024/Nofication_RCT_05072024.pdf" target="_blank"><i class="fa fa-file-pdf-o" style={{fontSize:"28px",color:"red"}}></i>Details</a></p>
                    <p ><a href="../assests/pdf/jobs/2024/Result_notification_19072024.pdf" target="_blank"><i class="fa fa-file-pdf-o" style={{fontSize:"28px",color:"red"}}></i>Result</a></p>
                    </td>
                </tr>

                <tr>
                    <td  valign="center" ><p style={{fontSize:"18px"}}>List of candidate for the interview under CRISPI Project at ROHC(S) Bengaluru</p>
                   </td>
                    <td  valign="center" >Temporary</td>
                    <td  valign="center" ><p>03/07/2024</p></td>
                    <td  valign="center" ><p>Walk in interview 23/07/2024 11:00AM</p></td>
                    <td  valign="center" > <p ><a href="../assests/pdf/jobs/2024/CRISPI_ project_Interview_list.pdf" target="_blank"><i class="fa fa-file-pdf-o" style={{fontSize:"28px",color:"red"}}></i>Details</a></p>
                    <p ><a href="../assests/pdf/jobs/2024/CRISP_Result_ROHC_230724.pdf" target="_blank"><i class="fa fa-file-pdf-o" style={{fontSize:"28px",color:"red"}}></i>Result</a></p>
                    </td>
                </tr>
                <tr>
                    <td  valign="center" ><p style={{fontSize:"18px"}}>Walk-in-Interview for Project Scientist - I (Non medical), Project Technician - II, Project Tech I at ROHC (S) Bengaluru</p>
                   </td>
                    <td  valign="center" >Temporary</td>
                    <td  valign="center" ><p>25/06/2024</p></td>
                    <td  valign="center" ><p>Extended Walk in interview 30/07/2024 11:00AM</p></td>
                    <td  valign="center" > <p ><a href="../assests/pdf/jobs/2024/Notification_Project_Staff_BHME.pdf" target="_blank"><i class="fa fa-file-pdf-o" style={{fontSize:"28px",color:"red"}}></i>Details</a></p>
                    <p ><a href="../assests/pdf/jobs/2024/Addendum_Project_Staff_BHME.pdf" target="_blank"><i class="fa fa-file-pdf-o" style={{fontSize:"28px",color:"red"}}></i>Corrigendum</a></p>
                    <p ><a href="../assests/pdf/jobs/2024/ROHC_Result_notification_06082024.pdf" target="_blank"><i class="fa fa-file-pdf-o" style={{fontSize:"28px",color:"red"}}></i>Result</a></p>
                    </td>
                </tr>
                <tr>
                    <td  valign="center" ><p style={{fontSize:"18px"}}>Walk-in-Interview for SRF at ICMR-NIOH Ahmedabad</p>
                   </td>
                    <td  valign="center" >Temporary</td>
                    <td  valign="center" ><p>01/07/2024</p></td>
                    <td  valign="center" ><p>Walk in interview 18/07/2024 10:00AM</p></td>
                    <td  valign="center" > <p ><a href="../assests/pdf/jobs/2024/SRF_01072024.pdf" target="_blank"><i class="fa fa-file-pdf-o" style={{fontSize:"28px",color:"red"}}></i>Details</a></p>
                    </td>
                </tr>
                
                <tr>
                    <td  valign="center" ><p style={{fontSize:"18px"}}>Recruitment NOTICE for the Project Research Scientist –II (Non-medical) at ICMR-NIOH Ahmedabad</p>
                   </td>
                    <td  valign="center" >Temporary</td>
                    <td  valign="center" ><p>28/05/2024</p></td>
                    <td  valign="center" ><p>16/06/2024 </p></td>
                    <td  valign="center" > <p ><a href="../assests/pdf/jobs/2024/AOP_Project_Notice_28052024.pdf" target="_blank"><i class="fa fa-file-pdf-o" style={{fontSize:"28px",color:"red"}}></i>Details</a></p>
                    <p ><a href="../assests/pdf/jobs/2024/Application_format_28052024.pdf" target="_blank"><i class="fa fa-file-pdf-o" style={{fontSize:"28px",color:"red"}}></i>Application Form</a></p>
                    <p ><a href="../assests/pdf/jobs/2024/Corrigendum_200624.pdf" target="_blank"><i class="fa fa-file-pdf-o" style={{fontSize:"28px",color:"red"}}></i>Corrigendum regarding interview</a></p>
                    <p ><a href="../assests/pdf/jobs/2024/Shortlisted_candidate_list_AOP_PRJ.pdf" target="_blank"><i class="fa fa-file-pdf-o" style={{fontSize:"28px",color:"red"}}></i>List of shortlisted candidates</a></p>
                    <p ><a href="../assests/pdf/jobs/2024/Result_notification_AOP_Project.pdf" target="_blank"><i class="fa fa-file-pdf-o" style={{fontSize:"28px",color:"red"}}></i>Result</a></p>
                    </td>
                </tr>
                
                <tr>
                    <td  valign="center" ><p style={{fontSize:"18px"}}>Walk-in-Interview for Project Technical Support II at ICMR-NIOH Ahmedabad</p>
                   </td>
                    <td  valign="center" >Temporary</td>
                    <td  valign="center" ><p>03/06/2024</p></td>
                    <td  valign="center" ><p>Walk-in-interview: 19/06/2024  10:00AM</p></td>
                    <td  valign="center" > <p ><a href="../assests/pdf/jobs/2024/Recruit_NUDGE_notification.pdf" target="_blank"><i class="fa fa-file-pdf-o" style={{fontSize:"28px",color:"red"}}></i>Details</a></p>
                    <p ><a href="../assests/pdf/jobs/2024/Corrigendum_180624.pdf" target="_blank"><i class="fa fa-file-pdf-o" style={{fontSize:"28px",color:"red"}}></i>Corrigendum</a></p>
                    <p ><a href="../assests/pdf/jobs/2024/Result_TB_RRJ_21062024.pdf" target="_blank"><i class="fa fa-file-pdf-o" style={{fontSize:"28px",color:"red"}}></i>Result</a></p>
                    </td>
                </tr>
                
                <tr>
                    <td  valign="center" ><p style={{fontSize:"18px"}}>walk-in-Interview for the Project Technician-II, Project Scientist-I (Non Medical), Project Scientist-II (Non Medical), Project Scientist-I (Medical) at ICMR-NIOH Ahmedabad</p>
                   </td>
                    <td  valign="center" >Temporary</td>
                    <td  valign="center" ><p>13/05/2024</p></td>
                    <td  valign="center" ><p>Walk-in-interview:30/05/2024, 10.00 AM </p></td>
                    <td  valign="center" > <p ><a href="../assests/pdf/jobs/2024/RCT_SD_13052024.pdf" target="_blank"><i class="fa fa-file-pdf-o" style={{fontSize:"28px",color:"red"}}></i>Details</a></p>
                    <p ><a href="../assests/pdf/jobs/2024/509_result_notification.pdf" target="_blank"><i class="fa fa-file-pdf-o" style={{fontSize:"28px",color:"red"}}></i>Result</a></p>
                    </td>
                </tr>
                <tr>
                    <td  valign="center" ><p style={{fontSize:"18px"}}>Walk-in-interview for the Project Research Scientist-I (Non-Medical),Project Technical Support-III, Project Technical Support-II, Multi-Tasking Staff (MTS) positions at ICMR-NIOH, Ahmedabad</p>
                   </td>
                    <td  valign="center" >Temporary</td>
                    <td  valign="center" ><p>13/05/2024</p></td>
                    <td  valign="center" ><p>Walk-in-interview:29/05/2024, 10.00 AM </p></td>
                    <td  valign="center" > <p ><a href="../assests/pdf/jobs/2024/RS_Adv_13052024.pdf" target="_blank"><i class="fa fa-file-pdf-o" style={{fontSize:"28px",color:"red"}}></i>Details</a></p>
                    <p ><a href="../assests/pdf/jobs/2024/510_result_notification.pdf" target="_blank"><i class="fa fa-file-pdf-o" style={{fontSize:"28px",color:"red"}}></i>Result</a></p>
                    </td>
                </tr>
                <tr>
                    <td  valign="center" ><p style={{fontSize:"18px"}}>Walk-in-interview for Young Professional-II, Laboratory Attendant at ICMR-NIOH Ahmedabad</p>
                   </td>
                    <td  valign="center" >Temporary</td>
                    <td  valign="center" ><p>06/05/2024</p></td>
                    <td  valign="center" ><p>Walk-in-interview:06/06/2024, 10.00 AM </p></td>
                    <td  valign="center" > <p ><a href="../assests/pdf/jobs/2024/RCT_Nofication_MPRNL_06052024.pdf" target="_blank"><i class="fa fa-file-pdf-o" style={{fontSize:"28px",color:"red"}}></i>Details</a></p>
                    <p ><a href="../assests/pdf/jobs/2024/631_Result_notification_MPRNL.pdf" target="_blank"><i class="fa fa-file-pdf-o" style={{fontSize:"28px",color:"red"}}></i>Result</a></p>
                    </td>
                </tr>
                <tr>
                    <td  valign="center" ><p style={{fontSize:"18px"}}>Walk-in-interview for Project Technical Support-III, Project Technical Support-II positions at ICMR-NIOH Ahmedabad</p>
                   </td>
                    <td  valign="center" >Temporary</td>
                    <td  valign="center" ><p>03/05/2024</p></td>
                    <td  valign="center" ><p>Walk-in-interview:06/06/2024, 12.00 Noon </p></td>
                    <td  valign="center" > <p ><a href="../assests/pdf/jobs/2024/SP_IM_Project_030524.pdf" target="_blank"><i class="fa fa-file-pdf-o" style={{fontSize:"28px",color:"red"}}></i>Details</a></p>
                    <p ><a href="../assests/pdf/jobs/2024/630_Result_notification_NTF.pdf" target="_blank"><i class="fa fa-file-pdf-o" style={{fontSize:"28px",color:"red"}}></i>Result</a></p>
                    </td>
                </tr>
                <tr>
                    <td  valign="center" ><p style={{fontSize:"18px"}}>Walk-in-interview for Project Scientist-I (Non-Medical), Project Technician-III (1), Project Technician-I (1), Project Scientist-II (Non medical) (1) positions at ICMR-NIOH Ahmedabad</p>
                   </td>
                    <td  valign="center" >Temporary</td>
                    <td  valign="center" ><p>03/05/2024</p></td>
                    <td  valign="center" ><p>Walk-in-interview:27/05/2024, 10.00AM </p></td>
                    <td  valign="center" > <p ><a href="../assests/pdf/jobs/2024/KD_IM_Project_030524.pdf" target="_blank"><i class="fa fa-file-pdf-o" style={{fontSize:"28px",color:"red"}}></i>Details</a></p>
                    <p ><a href="../assests/pdf/jobs/2024/Result_Walkin_27052024.pdf" target="_blank"><i class="fa fa-file-pdf-o" style={{fontSize:"28px",color:"red"}}></i>Result</a></p>
                    </td>
                </tr>
                <tr>
                    <td  valign="center" ><p style={{fontSize:"18px"}}>Walk-in-interview for Project Scientist-I (Non-Medical), Project Technical Support-III (Project Technical Officer) at ICMR-NIOH Ahmedabad</p>
                   </td>
                    <td  valign="center" >Temporary</td>
                    <td  valign="center" ><p>03/05/2024</p></td>
                    <td  valign="center" ><p>Walk-in-interview:21/05/2024, 10.00AM </p></td>
                    <td  valign="center" > <p ><a href="../assests/pdf/jobs/2024/AS_IM_Project_030524.pdf" target="_blank"><i class="fa fa-file-pdf-o" style={{fontSize:"28px",color:"red"}}></i>Details</a></p>
                    <p ><a href="../assests/pdf/jobs/2024/Result_Walkin_21052024.pdf" target="_blank"><i class="fa fa-file-pdf-o" style={{fontSize:"28px",color:"red"}}></i>Result</a></p>
                    </td>
                </tr>
                <tr>
                    <td  valign="center" ><p style={{fontSize:"18px"}}>Vacancy notification for Lower Division Clerk (LDC) and Upper Division Cleark (UDC) at ICMR-NIOH Ahmedabad and ROHC Bengaluru</p>
                   </td>
                    <td  valign="center" >Permanent</td>
                    <td  valign="center" ><p>13/03/2024</p></td>
                    <td  valign="center" ><p>30/04/2024 11:59PM</p></td>
                    <td  valign="center" > <p ><a href="../assests/pdf/jobs/2024/NIOH_Vacancy_Notification_LDC_UDC_2024_en.pdf" target="_blank"><i class="fa fa-file-pdf-o" style={{fontSize:"28px",color:"red"}}></i>Details</a></p>
                    <p ><a href="../assests/pdf/jobs/2024/NIOH_Vacancy_Notification_LDC_UDC_2024_h.pdf" target="_blank"><i class="fa fa-file-pdf-o" style={{fontSize:"28px",color:"red"}}></i>विवरण</a></p>
                    <p ><a href="../assests/pdf/jobs/2024/Corrigendum_LDC_UDC_10042024.pdf" target="_blank"><i class="fa fa-file-pdf-o" style={{fontSize:"28px",color:"red"}}></i>Corrigendum for Date Extension</a></p>
                    <p ><a href="../assests/pdf/jobs/2024/RCT_Admin_25042024.pdf" target="_blank"><i class="fa fa-file-pdf-o" style={{fontSize:"28px",color:"red"}}></i>Corrigendum regarding Lower Division Clerk and mark on post qualification experience</a></p>
                    <p ><a href="https://niohrecruitment.org/" target="_blank"><i class="fa fa-file-pdf-o" style={{fontSize:"28px",color:"red"}}></i>Application submission link</a></p>
                    
                    </td>
                </tr>
                <tr>
                    <td  valign="center" ><p style={{fontSize:"18px"}}>Recruitement notification for Project Research Scientist -I (Medical), Project Assistant (Statistical), Project Assistant (Technical) at RoHC(S) Bangalore</p>
                   </td>
                    <td  valign="center" >Temporary</td>
                    <td  valign="center" ><p>20/02/2024</p></td>
                    <td  valign="center" ><p>Last Date to submit application:15/04/2024 </p></td>
                    <td  valign="center" > <p ><a href="../assests/pdf/jobs/2024/Recruit_Notice_ROHCS_CRIPSI _2022024.pdf" target="_blank"><i class="fa fa-file-pdf-o" style={{fontSize:"28px",color:"red"}}></i>Details</a></p>
                    <p ><a href="../assests/pdf/jobs/2024/Application_format_ROHC.pdf" target="_blank"><i class="fa fa-file-pdf-o" style={{fontSize:"28px",color:"red"}}></i>Application Form</a></p>
                    <p ><a href="../assests/pdf/jobs/2024/corrigendm_rohc.pdf" target="_blank"><i class="fa fa-file-pdf-o" style={{fontSize:"28px",color:"red"}}></i>Corrigendum for date Extension</a></p>
                    </td>
                </tr>
               
                <tr>
                    <td  valign="center" ><p style={{fontSize:"18px"}}>Walk in interview for Project Techninician III position under Gujarat Maritime Board Project at ICMR-NIOH Ahmedabad</p>
                   </td>
                    <td  valign="center" >Temporary</td>
                    <td  valign="center" ><p>22/02/2024</p></td>
                    <td  valign="center" ><p>Walk in interview:07/03/2024 10:00AM</p></td>
                    <td  valign="center" > <p ><a href="../assests/pdf/jobs/2024/Advt_GMB_Project_22022024.pdf" target="_blank"><i class="fa fa-file-pdf-o" style={{fontSize:"28px",color:"red"}}></i>Details</a></p>
                    <p ><a href="../assests/pdf/jobs/2024/GMB_Navlakhi_Project_Result.pdf" target="_blank"><i class="fa fa-file-pdf-o" style={{fontSize:"28px",color:"red"}}></i>Result</a></p>
                    
                    </td>
                </tr>
                <tr>
                    <td  valign="center" ><p style={{fontSize:"18px"}}>Walk in interview for Project Technical Support III position under intramural Project at ICMR-NIOH Ahmedabad</p>
                   </td>
                    <td  valign="center" >Temporary</td>
                    <td  valign="center" ><p>19/02/2024</p></td>
                    <td  valign="center" ><p>Walk in interview:05/03/2024 10:30AM</p></td>
                    <td  valign="center" > <p ><a href="../assests/pdf/jobs/2024/Advt_PTS_III_190224.pdf" target="_blank"><i class="fa fa-file-pdf-o" style={{fontSize:"28px",color:"red"}}></i>Details</a></p>
                    <p ><a href="../assests/pdf/jobs/2024/Result_notification_3084_14032024.pdf" target="_blank"><i class="fa fa-file-pdf-o" style={{fontSize:"28px",color:"red"}}></i>Result</a></p>
                    
                    </td>
                </tr>
                <tr>
                    <td  valign="center" ><p style={{fontSize:"18px"}}>Recruitement notification for Project Research Scientist -I (Non Medical), Project Assistant (Statistical), Project Technical Support-II at RoHC(S) Bangalore</p>
                   </td>
                    <td  valign="center" >Temporary</td>
                    <td  valign="center" ><p>22/02/2024</p></td>
                    <td  valign="center" ><p>Last Date to submit application:12/03/2024 05:00PM </p></td>
                    <td  valign="center" > <p ><a href="../assests/pdf/jobs/2024/Recruit_Notifice_Sewer_Project_22022024.pdf" target="_blank"><i class="fa fa-file-pdf-o" style={{fontSize:"28px",color:"red"}}></i>Details</a></p>
                    <p ><a href="../assests/pdf/jobs/2024/Application_format_ROHC.pdf" target="_blank"><i class="fa fa-file-pdf-o" style={{fontSize:"28px",color:"red"}}></i>Application Form</a></p>
                    </td>
                </tr>
               
                <tr>
                    <td  valign="center" ><p style={{fontSize:"18px"}}>Walk in interview for Project Technical Support III position under LTBI Project at ICMR-NIOH Ahmedabad</p>
                   </td>
                    <td  valign="center" >Temporary</td>
                    <td  valign="center" ><p>12/02/2024</p></td>
                    <td  valign="center" ><p>Walk in interview:22/02/2024 10:00AM</p></td>
                    <td  valign="center" > <p ><a href="../assests/pdf/jobs/2024/Advt_for_PTS_III_LTBI_120224.pdf" target="_blank"><i class="fa fa-file-pdf-o" style={{fontSize:"28px",color:"red"}}></i>Details</a></p>
                    <p ><a href="../assests/pdf/jobs/2024/Result_Project_Silica_27022024.pdf" target="_blank"><i class="fa fa-file-pdf-o" style={{fontSize:"28px",color:"red"}}></i>Result</a></p>
                    
                    </td>
                </tr>

                <tr>
                    <td  valign="center" ><p style={{fontSize:"18px"}}>Recruitement notification of Project Research Scientist -I (Non-medical) at ICMR-NIOH Ahmedabad</p>
                   </td>
                    <td  valign="center" >Temporary</td>
                    <td  valign="center" ><p>07/02/2024</p></td>
                    <td  valign="center" ><p>Last Date to submit application:21/02/2024 11:59PM </p></td>
                    <td  valign="center" > <p ><a href="../assests/pdf/jobs/2024/Project_AOP_PFAS_2024.pdf" target="_blank"><i class="fa fa-file-pdf-o" style={{fontSize:"28px",color:"red"}}></i>Details</a></p>
                    <p ><a href="../assests/pdf/jobs/2024/NIOH_Application_form.pdf" target="_blank"><i class="fa fa-file-pdf-o" style={{fontSize:"28px",color:"red"}}></i>Application Form</a></p>
                    <p ><a href="../assests/pdf/jobs/2024/AOP_RESULT.pdf" target="_blank"><i class="fa fa-file-pdf-o" style={{fontSize:"28px",color:"red"}}></i>Result</a></p>
                    </td>
                </tr>
                
                <tr>
                    <td  valign="center" ><p style={{fontSize:"18px"}}>Recruitement notification of Project Research Scientist II (Medical), Project Technical Support II (Laboratory Technician), Project Technical Support I (Health Assistant), Project Data Entry Operator Grade B, Senior Project Assistant (UDC), Work location at Gujarat</p>
                   </td>
                    <td  valign="center" >Temporary</td>
                    <td  valign="center" ><p>19/01/2024</p></td>
                    <td  valign="center" ><p>29/01/2024, 04:00 PM</p></td>
                    <td  valign="center" > <p ><a href="../assests/pdf/jobs/2024/NIRT_Guj_Adv250124.pdf" target="_blank"><i class="fa fa-file-pdf-o" style={{fontSize:"28px",color:"red"}}></i>Details</a></p>
                    <p ><a href="../assests/pdf/jobs/2024/NIRT_App_Form.pdf" target="_blank"><i class="fa fa-file-pdf-o" style={{fontSize:"28px",color:"red"}}></i>Application Form</a></p>
                    </td>
                </tr>
                <tr>
                    <td  valign="center" ><p style={{fontSize:"18px"}}>Walk in Interview for the Program Officer under EIACP Project at ICMR-NIOH Ahmedabad</p>
                   </td>
                    <td  valign="center" >Temporary</td>
                    <td  valign="center" ><p>19/01/2024</p></td>
                    <td  valign="center" ><p>Walk in date:06/02/2024, 10:00AM </p></td>
                    <td  valign="center" > <p ><a href="../assests/pdf/jobs/2024/PO_Adv_EIACP_190124.pdf" target="_blank"><i class="fa fa-file-pdf-o" style={{fontSize:"28px",color:"red"}}></i>Details</a></p>
                    <p ><a href="../assests/pdf/jobs/2024/Result_PO_EIACP.pdf" target="_blank"><i class="fa fa-file-pdf-o" style={{fontSize:"28px",color:"red"}}></i>Result</a></p>
                    </td>
                </tr>
                <tr>
                    <td  valign="center" ><p style={{fontSize:"18px"}}>Walk in Interview for the Project Research Scientist-I (Scientist-B), Project Technical Support- III (Technical), Project Technical Support-III (Statistical), Project Technical Support-II at ICMR-NIOH Ahmedabad</p>
                   </td>
                    <td  valign="center" >Temporary</td>
                    <td  valign="center" ><p>12/01/2024</p></td>
                    <td  valign="center" ><p>Walk in date:25/01/2024, 10:00AM </p></td>
                    <td  valign="center" > <p ><a href="../assests/pdf/jobs/2024/Nofication_CRISPI_12012024.pdf" target="_blank"><i class="fa fa-file-pdf-o" style={{fontSize:"28px",color:"red"}}></i>Details</a></p>
                    </td>
                </tr>
                <tr>
                    <td  valign="center" ><p style={{fontSize:"18px"}}>Recruitement notification of JRF position under GPCB Project at ICMR-NIOH Ahmedabad</p>
                   </td>
                    <td  valign="center" >Temporary</td>
                    <td  valign="center" ><p>10/01/2024</p></td>
                    <td  valign="center" ><p>Last Date:24/01/2024 </p></td>
                    <td  valign="center" > <p ><a href="../assests/pdf/jobs/2024/JRF_GPCB_10012024.pdf" target="_blank"><i class="fa fa-file-pdf-o" style={{fontSize:"28px",color:"red"}}></i>Details</a></p>
                    <p ><a href="https://forms.gle/YHFENxz4XYRs7nvn7" target="_blank"><i class="fa fa-file-pdf-o" style={{fontSize:"28px",color:"red"}}></i>Click to Submit Application</a></p>
                    <p ><a href="../assests/pdf/jobs/2024/GPCB_shortlisted_candidates.pdf" target="_blank"><i class="fa fa-file-pdf-o" style={{fontSize:"28px",color:"red"}}></i>Shortlisted Candidates</a></p>
                    <p ><a href="../assests/pdf/jobs/2024/GPCB_Project_result.pdf" target="_blank"><i class="fa fa-file-pdf-o" style={{fontSize:"28px",color:"red"}}></i>Result</a></p>
                    </td>
                </tr>

                <tr>
                    <td  valign="center" ><p style={{fontSize:"18px"}}>Recruitement notification of JRF position under SERB Project at ICMR-NIOH Ahmedabad</p>
                   </td>
                    <td  valign="center" >Temporary</td>
                    <td  valign="center" ><p>10/01/2024</p></td>
                    <td  valign="center" ><p>Last Date:24/01/2024 </p></td>
                    <td  valign="center" > <p ><a href="../assests/pdf/jobs/2024/JRF_SERB_10012024.pdf" target="_blank"><i class="fa fa-file-pdf-o" style={{fontSize:"28px",color:"red"}}></i>Details</a></p>
                    <p ><a href="https://forms.gle/DW8Kh6L99JUmYcUNA" target="_blank"><i class="fa fa-file-pdf-o" style={{fontSize:"28px",color:"red"}}></i>Click to Submit Application</a></p>
                    <p ><a href="../assests/pdf/jobs/2024/SERB_shortlisted_candidates.pdf" target="_blank"><i class="fa fa-file-pdf-o" style={{fontSize:"28px",color:"red"}}></i>Shortlisted Candidates</a></p>
                    <p ><a href="../assests/pdf/jobs/2024/DST_SERB_Project_result.pdf" target="_blank"><i class="fa fa-file-pdf-o" style={{fontSize:"28px",color:"red"}}></i>Result</a></p>
                    </td>
                </tr>

                <tr>
                    <td  valign="center" ><p style={{fontSize:"18px"}}>Walk-in-interview for the Project Technical Officer position at ICMR-NIE, Chennai</p>
                   </td>
                    <td  valign="center" >Temporary</td>
                    <td  valign="center" ><p>10/01/2024</p></td>
                    <td  valign="center" ><p>Walk-in date:23/01/2024 09:30 AM - 01 PM </p></td>
                    <td  valign="center" > <p ><a href="../assests/pdf/jobs/2024/Recruit_cardio_chennai_10012024.pdf" target="_blank"><i class="fa fa-file-pdf-o" style={{fontSize:"28px",color:"red"}}></i>Details</a></p>
                    <p ><a href="../assests/pdf/jobs/2024/Result_Notification_ICMR_NIE_300124.pdf" target="_blank"><i class="fa fa-file-pdf-o" style={{fontSize:"28px",color:"red"}}></i>Result</a></p>
                    </td>
                </tr>

                <tr>
                    <td  valign="center" ><p style={{fontSize:"18px"}}>Recruitement notification of SRF at ICMR-NIOH Ahmedabad</p>
                   </td>
                    <td  valign="center" >Temporary</td>
                    <td  valign="center" ><p>09/01/2024</p></td>
                    <td  valign="center" ><p>Last Date:21/01/2024 11:59PM </p></td>
                    <td  valign="center" > <p ><a href="../assests/pdf/jobs/2024/SRF_Catalog_project_09012024.pdf" target="_blank"><i class="fa fa-file-pdf-o" style={{fontSize:"28px",color:"red"}}></i>Details</a></p>
                    <p ><a href="../assests/pdf/jobs/2024/NIOH_Application_form.pdf" target="_blank"><i class="fa fa-file-pdf-o" style={{fontSize:"28px",color:"red"}}></i>Application Form</a></p>
                    </td>
                </tr>
                <tr>
                    <td  valign="center" ><p style={{fontSize:"18px"}}>Recruitement notification of SRF at ICMR-NIOH Ahmedabad</p>
                   </td>
                    <td  valign="center" >Temporary</td>
                    <td  valign="center" ><p>09/01/2024</p></td>
                    <td  valign="center" ><p>Last Date:21/01/2024 11:59PM </p></td>
                    <td  valign="center" > <p ><a href="../assests/pdf/jobs/2024/SRF_Catalog_project_09012024.pdf" target="_blank"><i class="fa fa-file-pdf-o" style={{fontSize:"28px",color:"red"}}></i>Details</a></p>
                    <p ><a href="../assests/pdf/jobs/2024/NIOH_Application_form.pdf" target="_blank"><i class="fa fa-file-pdf-o" style={{fontSize:"28px",color:"red"}}></i>Application Form</a></p>
                    </td>
                </tr>
                <tr>
                    <td  valign="center" ><p style={{fontSize:"18px"}}>Recruitement notification of JRF at ICMR-NIOH Ahmedabad</p>
                   </td>
                    <td  valign="center" >Temporary</td>
                    <td  valign="center" ><p>03/01/2024</p></td>
                    <td  valign="center" ><p>Last Date:11/01/2024 11:59PM </p></td>
                    <td  valign="center" > <p ><a href="../assests/pdf/jobs/2024/SERB_Notification_03012024.pdf" target="_blank"><i class="fa fa-file-pdf-o" style={{fontSize:"28px",color:"red"}}></i>Details</a></p>
                    <p ><a href="../assests/pdf/jobs/2024/NIOH_Application_form.pdf" target="_blank"><i class="fa fa-file-pdf-o" style={{fontSize:"28px",color:"red"}}></i>Application Form</a></p>
                    <p ><a href="../assests/pdf/jobs/2024/Result_JRF_SERB_25022024.pdf" target="_blank"><i class="fa fa-file-pdf-o" style={{fontSize:"28px",color:"red"}}></i>Result</a></p>
                    </td>
                </tr>
                <tr>
                    <td  valign="center" ><p style={{fontSize:"18px"}}>Revised shortlisted candidate list for Project Associate - I at ROHC(S) Bangalore </p>
                   </td>
                    <td  valign="center" >Temporary</td>
                    <td  valign="center" ><p>09/01/2024</p></td>
                    <td  valign="center" ><p>Interview Date:02/02/2024 10:00 AM </p></td>
                    <td  valign="center" > <p ><a href="../assests/pdf/jobs/2024/Revised_eligible_SERB_09012024.pdf" target="_blank"><i class="fa fa-file-pdf-o" style={{fontSize:"28px",color:"red"}}></i>Details</a></p>
                    <p ><a href="../assests/pdf/jobs/2024/Result_Project_Associate_DST_SERB_020224.pdf" target="_blank"><i class="fa fa-file-pdf-o" style={{fontSize:"28px",color:"red"}}></i>Result</a></p>
                    </td>
                </tr>
                <tr>
                    <td  valign="center" ><p style={{fontSize:"18px"}}>DECLARATION OF THE RESULTS FOR THE TECHNICAL CADRE POSTS OF THE ICMR-NIOH</p></td>
                    <td  valign="center" ><p>Permanent</p></td>
                    <td  valign="center" ><p>01/01/2024</p></td>
                    <td  valign="center" ><p></p></td>
                    <td  valign="center" > <p><a href="../assests/pdf/jobs/2023/Result_Technical_Cadre_Exam_NIOH_2023.pdf" target="_blank"><i class="fa fa-file-pdf-o" style={{fontSize:"28px",color:"red"}}></i>Details</a></p></td>
                </tr>
                <tr>
                    <td  valign="center" ><p style={{fontSize:"18px"}}>Offer of provisional appointment to the candidates selected for the technical cadre posts of the ICMR-NIOH </p></td>
                    <td  valign="center" ><p>Permanent</p></td>
                    <td  valign="center" ><p>30/12/2023</p></td>
                    <td  valign="center" ><p></p></td>
                    <td  valign="center" > <p><a href="../assests/pdf/jobs/2023/ICMRNIOH_Provisional_Selected_Candidate_Technical_Cadre.pdf" target="_blank"><i class="fa fa-file-pdf-o" style={{fontSize:"28px",color:"red"}}></i>Details</a></p></td>
                </tr>
                <tr>
                    <td  valign="center" ><p style={{fontSize:"18px"}}>Walk in Interview for Project Technical Officer, Work place- NIE Chennai </p></td>
                    <td  valign="center" >Temporary</td>
                    <td  valign="center" ><p>15/12/2023</p></td>
                    <td  valign="center" ><p>Walk in Interview Date:03/01/2024 09:30 AM at NIE Chennai </p></td>
                    <td  valign="center" > <p><a href="../assests/pdf/jobs/2023/NIE_Cardiovascular_15122023.pdf" target="_blank"><i class="fa fa-file-pdf-o" style={{fontSize:"28px",color:"red"}}></i>Details</a></p></td>
                </tr>
                <tr>
                    <td  valign="center" ><p style={{fontSize:"18px"}}>Recruitement of GIS/IT Officer at NIOH Ahmedabad </p>
                   </td>
                    <td  valign="center" >Temporary</td>
                    <td  valign="center" ><p>14/12/2023</p></td>
                    <td  valign="center" ><p>Last Date:26/12/2023 05:00 PM </p></td>
                    <td  valign="center" > <p><a href="../assests/pdf/jobs/2023/EIACP_Notification_IT_14122023.pdf" target="_blank"><i class="fa fa-file-pdf-o" style={{fontSize:"28px",color:"red"}}></i>Details</a></p>
                    <p ><a href="../assests/pdf/jobs/2023/EIACP_Adv_Application.pdf" target="_blank"><i class="fa fa-file-pdf-o" style={{fontSize:"28px",color:"red"}}></i>Application Form</a></p>
                    <p ><a href="../assests/pdf/jobs/2024/Result_IT_GIS_EIACP.pdf" target="_blank"><i class="fa fa-file-pdf-o" style={{fontSize:"28px",color:"red"}}></i>Result</a></p>
                    </td>
                </tr>
                <tr>
                    <td  valign="center" ><p style={{fontSize:"18px"}}>Recruitement of Project Associate - I at ROHC(S) Bangalore </p>
                   </td>
                    <td  valign="center" >Temporary</td>
                    <td  valign="center" ><p>14/12/2023</p></td>
                    <td  valign="center" ><p>Last Date:29/12/2023 05:00 PM </p></td>
                    <td  valign="center" > <p ><a href="../assests/pdf/jobs/2023/CRG-SERB APPLICATION FORM.pdf" target="_blank"><i class="fa fa-file-pdf-o" style={{fontSize:"28px",color:"red"}}></i>Details</a></p>
                    </td>
                </tr>
                <tr>
                    <td  valign="center" ><p style={{fontSize:"18px"}}>Walk in Interview for Project Research Scientist II (Medical), Project Technical Support III (Senior Investigator), Project Technical Support III (Medical Social Worker), Project Technical Support III (Senior Technical Assistant), Project Technical Support III (Field Investigator), Project Technical Support II (Laboratory Technician), Project Technical Support II (X-Ray Technician), Project Technical Support I (Health Assistant), Project Data Entry Operator Grade B, Senior Project Assistant (UDC), Project Driver cum Mechanic, Project Multi-Tasking Staff (Helper), Work Area-Gujarat </p>
                   </td>
                    <td  valign="center" >Temporary</td>
                    <td  valign="center" ><p>07/12/2023</p></td>
                    <td  valign="center" ><p>Walk-In Interview Date:20/12/2023 & 21/12/2023 09:00 AM </p></td>
                    <td  valign="center" > <p ><a href="../assests/pdf/jobs/2023/NIRT_Advertisement_GJ_rev.pdf" target="_blank"><i class="fa fa-file-pdf-o" style={{fontSize:"28px",color:"red"}}></i>Details</a></p>
                    </td>
                </tr>
                <tr>
                    <td  valign="center" ><p style={{fontSize:"18px"}}>Walk in Interview for Project Technician III, Data Entry Operator – Grade B-NIOH Ahmedabad </p>
                   </td>
                    <td  valign="center" >Temporary</td>
                    <td  valign="center" ><p>11/11/2023</p></td>
                    <td  valign="center" ><p>Walk-In Interview Date:19/12/2023, 10:00 AM </p></td>
                    <td  valign="center" > <p ><a href="../assests/pdf/jobs/2023/Adv_ECL_proejct_11122023_v1.pdf" target="_blank"><i class="fa fa-file-pdf-o" style={{fontSize:"28px",color:"red"}}></i>Details</a></p>
                    <p ><a href="../assests/pdf/jobs/2024/ECL_RESULT_040124.pdf" target="_blank"><i class="fa fa-file-pdf-o" style={{fontSize:"28px",color:"red"}}></i>Result</a></p>
                    </td>
                </tr>
                <tr>
                    <td  valign="center" ><p style={{fontSize:"18px"}}>NOTICE FOR WRITTEN EXAMINATIONS (CBT) SCHEDULE, SYLLABUS AND FINAL LIST OF CANDIDATES FOR THE TECHNICAL CADRE POSTS </p>
                   </td>
                    <td  valign="center" >Permanent</td>
                    <td  valign="center" ><p>22/11/2023</p></td>
                    <td  valign="center" ><p> </p></td>
                    <td  valign="center" ><p ><a href="../assests/pdf/jobs/2023/Notification_Syllabus_Candidate_List_22112023.pdf" target="_blank"><i class="fa fa-file-pdf-o" style={{fontSize:"28px",color:"red"}}></i>Details</a></p>
                    <p ><a href="https://joinicmr.in/download" target="_blank">Download Hall Ticket of Written Examination</a></p>
                    <p ><a href="../assests/pdf/jobs/2023/notification technical.pdf" target="_blank"><i class="fa fa-file-pdf-o" style={{fontSize:"28px",color:"red"}}></i>Notification For candidate Admit Card</a></p>
                    <p > Helpdesk: Email: support-icmr@cdac.in; Phone Number: 80352-27615 (0930 hrs to 1300 hrs & 1400 hrs to 1800 hrs) Monday to Saturday.</p>
                    </td>
                </tr>
                <tr>
                    <td  valign="center" ><p style={{fontSize:"18px"}}>Walk in Interview for Project Scientist B (Non Medical), SRF; Work place- NIOH Ahmedabad </p>
                   </td>
                    <td  valign="center" >Temporary</td>
                    <td  valign="center" ><p>03/11/2023</p></td>
                    <td  valign="center" ><p>Walk-In Interview Date:28/11/2023, 09:30 AM </p></td>
                    <td  valign="center" > <p ><a href="../assests/pdf/jobs/2023/NIN_Advt278_03112023.pdf" target="_blank"><i class="fa fa-file-pdf-o" style={{fontSize:"28px",color:"red"}}></i>Details</a></p>
                    <p ><a href="../assests/pdf/jobs/2023/Corrigendum_1_AdvtNo278.pdf" target="_blank"><i class="fa fa-file-pdf-o" style={{fontSize:"28px",color:"red"}}></i>Corrigendum 1</a></p>
                    <p ><a href="../assests/pdf/jobs/2023/Corrigendum_2_AdvtNo278.pdf" target="_blank"><i class="fa fa-file-pdf-o" style={{fontSize:"28px",color:"red"}}></i>Corrigendum 2</a></p>
                    <p ><a href="../assests/pdf/jobs/2023/Application_Form_AdvNo278.pdf" target="_blank"><i class="fa fa-file-pdf-o" style={{fontSize:"28px",color:"red"}}></i>Application Form</a></p>
                    </td>
                </tr>
                <tr>
                    <td  valign="center" ><p style={{fontSize:"18px"}}>EOI for full time senior consultant with Indian Journal of  Medical Research at ICMR HQ, New Delhi </p>
                   </td>
                    <td  valign="center" >Temporary</td>
                    <td  valign="center" ><p>07/11/2023</p></td>
                    <td  valign="center" ><p>28/11/2023 </p></td>
                    <td  valign="center" > <p ><a href="../assests/pdf/jobs/2023/Office_Memorandum_IJMR.pdf" target="_blank"><i class="fa fa-file-pdf-o" style={{fontSize:"28px",color:"red"}}></i>Details & Application Form</a></p>
                    </td>
                </tr>

                <tr>
                    <td  valign="center" ><p style={{fontSize:"18px"}}>Walk-In Interview for Junior Research Fellow (JRF) at ICMR-NIOH, Ahmedabad </p>
                   </td>
                    <td  valign="center" >Temporary</td>
                    <td  valign="center" ><p>06/11/2023</p></td>
                    <td  valign="center" ><p>Walk-In Interview Date: 24/11/2023, 10:00 AM. </p></td>
                    <td  valign="center" ><p ><a href="../assests/pdf/jobs/2023/NIOH_JRF_Call_2023.pdf" target="_blank"><i class="fa fa-file-pdf-o" style={{fontSize:"28px",color:"red"}}></i>Details</a></p>
                    <p ><a href="../assests/pdf/jobs/2023/JRF_App_Form_2023.pdf" target="_blank"><i class="fa fa-file-pdf-o" style={{fontSize:"28px",color:"red"}}></i>Application Form</a></p>
                    </td>
                </tr>

                <tr>
                    <td  valign="center" ><p style={{fontSize:"18px"}}>Walk-In Interview for Project Research Scientist-I (Scientist-B(M)), Project Technical Support-III (Technical), Project Technical Support-III (Statistical), Project Technical Support-II at ICMR-NIOH, Ahmedabad </p>
                   </td>
                    <td  valign="center" >Temporary</td>
                    <td  valign="center" ><p>06/11/2023</p></td>
                    <td  valign="center" ><p>Walk-In Interview Date: 21/11/2023, 10:00 AM. </p></td>
                    <td  valign="center" ><p ><a href="../assests/pdf/jobs/2023/NIOH_CRISPI_07112023.pdf" target="_blank"><i class="fa fa-file-pdf-o" style={{fontSize:"28px",color:"red"}}></i>Details</a></p>
                    <p ><a href="../assests/pdf/jobs/2023/Result_notificaion_1522.pdf" target="_blank"><i class="fa fa-file-pdf-o" style={{fontSize:"28px",color:"red"}}></i>Result</a></p>
                    </td>
                </tr>

                <tr>
                    <td  valign="center" ><p style={{fontSize:"18px"}}>Walk-In Interview for IT/GIS Officer at ICMR-NIOH, Ahmedabad </p>
                   </td>
                    <td  valign="center" >Temporary</td>
                    <td  valign="center" ><p>01/11/2023</p></td>
                    <td  valign="center" ><p>Walk-In Interview Date: 08/11/2023, 10:00 AM. </p></td>
                    <td  valign="center" ><p ><a href="../assests/pdf/jobs/2023/EIACP_notification_01112023.pdf" target="_blank"><i class="fa fa-file-pdf-o" style={{fontSize:"28px",color:"red"}}></i>Details</a></p>
                    </td>
                </tr>

                <tr>
                    <td  valign="center" ><p style={{fontSize:"18px"}}>Walk-In Interview for Project Technical Officer at ICMR-NIOH, Ahmedabad </p>
                   </td>
                    <td  valign="center" >Temporary</td>
                    <td  valign="center" ><p>27/10/2023</p></td>
                    <td  valign="center" ><p>Walk-In Interview Date: 09/11/2023</p></td>
                    <td  valign="center" ><p ><a href="../assests/pdf/jobs/2023/Notification.pdf" target="_blank"><i class="fa fa-file-pdf-o" style={{fontSize:"28px",color:"red"}}></i>Details</a></p> 
                    <p ><a href="../assests/pdf/jobs/2023/CARDOVISCULAR_RESULT_08122023.pdf" target="_blank"><i class="fa fa-file-pdf-o" style={{fontSize:"28px",color:"red"}}></i>Result</a></p>
                    </td>
                </tr>
                   <tr>
                    <td  valign="center" ><p style={{fontSize:"18px"}}>VACANCY For Project Assistant-1, ICMR-ROHCS Bangalore </p>
                   </td>
                    <td  valign="center" >Temporary</td>
                    <td  valign="center" ><p>26/10/2023</p></td>
                    <td  valign="center" ><p>Last Date  submission Application  05/11/2023,5:00PM.</p></td>
                    <td  valign="center" ><p ><a href="../assests/pdf/jobs/2023/ROHC_notification.pdf" target="_blank"><i class="fa fa-file-pdf-o" style={{fontSize:"28px",color:"red"}}></i>Details</a></p>
                    <p ><a href="../assests/pdf/jobs/2023/ROHCS_Result15122023.pdf" target="_blank"><i class="fa fa-file-pdf-o" style={{fontSize:"28px",color:"red"}}></i>Result</a></p>
                    
                    
                     </td>
                </tr>
                <tr>
                    <td  valign="center" ><p style={{fontSize:"18px"}}>SYLLABUS & BLUEPRINT FOR CBT OF TECHNICAL CADRE IN ICMR INSTITUTES </p>
                   </td>
                    <td  valign="center" >Permanent</td>
                    <td  valign="center" ><p>04/10/2023</p></td>
                    <td  valign="center" ><p></p></td>
                    <td  valign="center" ><p ><a href="../assests/pdf/jobs/2023/Detailed_syllabus.pdf" target="_blank"><i class="fa fa-file-pdf-o" style={{fontSize:"28px",color:"red"}}></i>SYLLABUS</a></p>
                                  
                    
                     </td>
                </tr>
                <tr>
                    <td  valign="center" ><p style={{fontSize:"18px"}}>ADDENDUM for TECHNICAL CADRE IN ICMR INSTITUTES</p>
                   </td>
                    <td  valign="center" >Permanent</td>
                    <td  valign="center" ><p>04/10/2023</p></td>
                    <td  valign="center" ><p> </p></td>
                    <td  valign="center" ><p ><a href="../assests/pdf/jobs/2023/Experience_weightage.pdf" target="_blank"><i class="fa fa-file-pdf-o" style={{fontSize:"28px",color:"red"}}></i>Document</a></p>
                    
                    
                     </td>
                </tr>
              
                <tr>
                    <td  valign="center" ><p style={{fontSize:"18px"}}>Notification for TECHNICAL CADRE IN ICMR INSTITUTES </p>
                   </td>
                    <td  valign="center" >Permanent</td>
                    <td  valign="center" ><p>04/10/2023</p></td>
                    <td  valign="center" ><p></p></td>
                    <td  valign="center" ><p ><a href="../assests/pdf/jobs/2023/Revised_list.pdf" target="_blank"><i class="fa fa-file-pdf-o" style={{fontSize:"28px",color:"red"}}></i>Document</a></p>
                    
                    
                     </td>
                </tr>
                <tr>
                    <td  valign="center" ><p style={{fontSize:"18px"}}>VACANCY For Administrative Officer,ICMR-NIIRNCD, Jodhpur, ICMR-RMRC Port Blair, ICMR-NIN, Hyderabad,ICMR-NJIL&OMD Agra </p>
                   </td>
                    <td  valign="center" >deputation</td>
                    <td  valign="center" ><p>03/10/2023</p></td>
                    <td  valign="center" ><p>Last Date 31/10/2023 </p></td>
                    <td  valign="center" ><p ><a href="../assests/pdf/jobs/2023/VACANCY_CIRCULAR_FOR_INSTITUTES.pdf" target="_blank"><i class="fa fa-file-pdf-o" style={{fontSize:"28px",color:"red"}}></i>Details</a></p>
                    
                    
                     </td>
                </tr>
                <tr>
                    <td  valign="center" ><p style={{fontSize:"18px"}}>Walk-In Interview for  SRF(Senior Research Fellow) at ICMR-NIOH, Ahmedabad </p>
                   </td>
                    <td  valign="center" >Temporary</td>
                    <td  valign="center" ><p>27/09/2023</p></td>
                    <td  valign="center" ><p>Walk-In Interview Date: 12/10/2023, 10:00 AM. </p></td>
                    <td  valign="center" ><p ><a href="../assests/pdf/jobs/2023/billingual.pdf" target="_blank"><i class="fa fa-file-pdf-o" style={{fontSize:"28px",color:"red"}}></i>Details</a></p>
                    <p ><a href="../assests/pdf/jobs/2023/Result_Silica_Project_1263.pdf" target="_blank"><i class="fa fa-file-pdf-o" style={{fontSize:"28px",color:"red"}}></i>Result</a></p>
                    
                    
                     </td>
                </tr>
             
                <tr>
                    <td  valign="center" ><p style={{fontSize:"18px"}}>Walk-In Interview for  Junior Research Fellow at ICMR-NIOH, Ahmedabad </p>
                   </td>
                    <td  valign="center" >Temporary</td>
                    <td  valign="center" ><p>26/09/2023</p></td>
                    <td  valign="center" ><p>Walk-In Interview Date: 16/10/2023, 10:00 AM. </p></td>
                    <td  valign="center" ><p ><a href="../assests/pdf/jobs/2023/Final JRF1.pdf" target="_blank"><i class="fa fa-file-pdf-o" style={{fontSize:"28px",color:"red"}}></i>Details</a></p>
                   <p> <a href="../assests/pdf/jobs/2023/serb_result.pdf" target="_blank"><i class="fa fa-file-pdf-o" style={{fontSize:"28px",color:"red"}}></i>Result</a></p>
                    
                    
                     </td>
                </tr>
              
                
                <tr>
                    <td  valign="center" ><p style={{fontSize:"18px"}}>Walk-In Interview for Project associate-I and Field Assistant at ICMR-NIOH, Ahmedabad </p>
                   </td>
                    <td  valign="center" >Temporary</td>
                    <td  valign="center" ><p>26/09/2023</p></td>
                    <td  valign="center" ><p>Walk-In Interview Date: 17/10/2023, 10:00 AM. </p></td>
                    <td  valign="center" ><p ><a href="../assests/pdf/jobs/2023/Final-DHR.pdf" target="_blank"><i class="fa fa-file-pdf-o" style={{fontSize:"28px",color:"red"}}></i>Details</a></p>
                    <p ><a href="../assests/pdf/jobs/2023/Notification_no_1304.pdf" target="_blank"><i class="fa fa-file-pdf-o" style={{fontSize:"28px",color:"red"}}></i>Result</a></p>
                    
                    
                     </td>
                </tr>
                
               

                <tr>
                    <td  valign="center" ><p style={{fontSize:"18px"}}>Publishing of Result of Screened Applications of Technical Cadre Post (Technical Assistant-28, Technician I-16, Laboratory Attendant I-10) </p>
                   </td>
                    <td  valign="center" >Permanent</td>
                    <td  valign="center" ><p>19/09/2023</p></td>
                    <td  valign="center" ><p>Last date to send representations 21/09/2023</p></td>
                    <td  valign="center" ><p ><a href="../assests/pdf/jobs/2023/Screened_data_en.pdf" target="_blank"><i class="fa fa-file-pdf-o" style={{fontSize:"28px",color:"red"}}></i>Details in English</a></p>
                                            <p ><a href="../assests/pdf/jobs/2023/Screened_data_hi.pdf" target="_blank"><i class="fa fa-file-pdf-o" style={{fontSize:"28px",color:"red"}}></i>Details in Hindi</a></p>
                                            <p ><a href="../assests/pdf/jobs/2023/AnnexureI_eligible.pdf" target="_blank"><i class="fa fa-file-pdf-o" style={{fontSize:"28px",color:"red"}}></i>List of Eligible candidate</a></p>
                                            <p ><a href="../assests/pdf/jobs/2023/AnnexureII_ineligible.pdf" target="_blank"><i class="fa fa-file-pdf-o" style={{fontSize:"28px",color:"red"}}></i>List of Not-Eligible candidate</a></p>
                     </td>
                </tr>
                <tr>
                    <td  valign="center" ><p style={{fontSize:"18px"}}>Recruitment Notification for Walk in- Interview at ICMR-NIOH, Ahmedabad </p>
                   </td>
                    <td  valign="center" >Temporary</td>
                    <td  valign="center" ><p>14/09/2023</p></td>
                    <td  valign="center" ><p> Recruitement Notification for Walk in Interview for Youth Professional-II, Interview Date: 5/10/2023, 10:00 AM.</p></td>
                    <td  valign="center" ><p ><a href="../assests/pdf/jobs/2023/MPRNL advt.pdf" target="_blank"><i class="fa fa-file-pdf-o" style={{fontSize:"28px",color:"red"}}></i>Details</a></p>
                    <p ><a href="../assests/pdf/jobs/2023/mprnl_result.pdf" target="_blank"><i class="fa fa-file-pdf-o" style={{fontSize:"28px",color:"red"}}></i>Result</a></p>
                  
                     </td>
                </tr>
                <tr>
                    <td  valign="center" ><p style={{fontSize:"18px"}}>Recruitment Notification for Walk in- Interview at ICMR-NIOH, Ahmedabad </p>
                   </td>
                    <td  valign="center" >Temporary</td>
                    <td  valign="center" ><p>21/08/2023</p></td>
                    <td  valign="center" ><p>Extended Walk-In Interview Date: 12/09/2023, 10:00 AM. </p></td>
                    <td  valign="center" ><p ><a href="../assests/pdf/jobs/2023/GSBTM Final advt.pdf" target="_blank"><i class="fa fa-file-pdf-o" style={{fontSize:"28px",color:"red"}}></i>Details</a></p>
                    <p ><a href="../assests/pdf/jobs/2023/GSBTM OM 04-9-2023.pdf" target="_blank"><i class="fa fa-file-pdf-o" style={{fontSize:"28px",color:"red"}}></i>Corrigendum For date extended</a></p>
                    <p ><a href="../assests/pdf/jobs/2023/GSBTM_RESULT.pdf" target="_blank"><i class="fa fa-file-pdf-o" style={{fontSize:"28px",color:"red"}}></i>Result</a></p>
                     </td>
                </tr>
                
                <tr></tr>
                <tr>
                    <td  valign="center" ><p style={{fontSize:"18px"}}>Vacancy notification for various regular technical posts (Technical Assistant-28, Technician I-16, Laboratory Attendant I-10) </p>
                   </td>
                    <td  valign="center" >Permanent</td>
                    <td  valign="center" ><p>05/07/2023</p></td>
                    <td  valign="center" ><p>Online Application submission start date: 08/07/2023, Online application submission end date: 04/08/2023 . </p></td>
                    <td  valign="center" ><p ><a href="../assests/pdf/jobs/2023/NIOH_Recruitment_Notice_05072023.pdf" target="_blank"><i class="fa fa-file-pdf-o" style={{fontSize:"28px",color:"red"}}></i>Details(English)</a></p>
                    <p ><a href="../assests/pdf/jobs/2023/NIOH_recruitment_notification_2023_h.pdf" target="_blank"><i class="fa fa-file-pdf-o" style={{fontSize:"28px",color:"red"}}></i>Details(Hindi)</a></p>
                   <p> <a href="../assests/pdf/jobs/2023/NIOH_Recruitment_Annex_05072023.pdf" target="_blank"><i class="fa fa-file-pdf-o" style={{fontSize:"28px",color:"red"}}></i>Annexure</a></p>
                   <p> <a href="https://niohrecruitment.org/" target="_blank"><i class="fa fa-file-pdf-o" style={{fontSize:"28px",color:"red"}}></i>Link for online application submission</a></p>
                   <p> <a href="../assests/pdf/jobs/2023/Syllabus.pdf" target="_blank"><i class="fa fa-file-pdf-o" style={{fontSize:"28px",color:"red"}}></i>Syllabus For Written Test</a></p>
                   <p> <a href="../assests/pdf/jobs/2023/corrigendum_TAPH_270723.pdf" target="_blank"><i class="fa fa-file-pdf-o" style={{fontSize:"28px",color:"red"}}></i>Corrigendum Technical Assistant(PH)</a></p>
                   <p> <a href="../assests/pdf/jobs/2023/corrigendum for date extend.pdf" target="_blank"><i class="fa fa-file-pdf-o" style={{fontSize:"28px",color:"red"}}></i>Corrigendum For date extended</a></p>

                  
                     </td>
                </tr>
                <tr>
                    <td  valign="center" ><p style={{fontSize:"18px"}}>Walk-In Interview for Technician at ICMR-NIOH, Ahmedabad </p>
                   </td>
                    <td  valign="center" >Temporary</td>
                    <td  valign="center" ><p>17/05/2023</p></td>
                    <td  valign="center" ><p>Walk-In Interview Date: 29/05/2023, 10:00 AM. </p></td>
                    <td  valign="center" ><p ><a href="../assests/pdf/jobs/2023/Adv_Technician_17052023.pdf" target="_blank"><i class="fa fa-file-pdf-o" style={{fontSize:"28px",color:"red"}}></i>Details</a></p>
                    
                     </td>
                </tr>
                <tr>
                    <td  valign="center" ><p style={{fontSize:"18px"}}>Walk-In Interview for Project Scientist C (Medical), Project Scientist B(Non-medical), Project Research Assistant, Project Jr. Medical Officer, Project Senior Research Fellow, Project Assistant, Project Field Worker</p>
                   
                    </td>
                    <td  valign="center" >Temporary</td>
                    <td  valign="center" ><p>13/05/2023</p></td>
                    <td  valign="center" ><p>Walk-In Interview Date: 25/05/2023 & 26/05/2023, 09:30 AM. </p></td>
                    <td  valign="center" ><p ><a href="../assests/pdf/jobs/2023/Advt-188-17-05-2023.pdf" target="_blank"><i class="fa fa-file-pdf-o" style={{fontSize:"28px",color:"red"}}></i>Details</a></p>
                    <p ><a href="../assests/pdf/jobs/2023/corrigendum_188_18052023.pdf" target="_blank"><i class="fa fa-file-pdf-o" style={{fontSize:"28px",color:"red"}}></i>Corrigendum</a></p>
                     </td>
                </tr>
                <tr>
                    <td  valign="center" ><p style={{fontSize:"18px"}}>Walk-In Interview for Project Technical Officer (UR-O1, OBC-01) at ICMR-NIOH, Ahmedabad and ICMR-NIE, Chennai</p>
                   
                    </td>
                    <td  valign="center" >Temporary</td>
                    <td  valign="center" ><p>16/05/2023</p></td>
                    <td  valign="center" ><p>Walk-In Interview Date: 26/05/2023, 10:00 AM. </p></td>
                    <td  valign="center" ><p ><a href="../assests/pdf/jobs/2023/ICMR_NIOH_NIE_Adv_16052023.pdf" target="_blank"><i class="fa fa-file-pdf-o" style={{fontSize:"28px",color:"red"}}></i>Details</a></p>
                    
                     </td>
                </tr>
                <tr>
                    <td  valign="center" ><p style={{fontSize:"18px"}}>Walk-In Interview for position of Data Entry Operator (UR-O1) at ICMR-NIOH</p>
                   
                    </td>
                    <td  valign="center" >Temporary</td>
                    <td  valign="center" ><p>09/05/2023</p></td>
                    <td  valign="center" ><p>Walk-In Interview Date: 14/06/2023, 10:30 AM. </p></td>
                    <td  valign="center" ><p ><a href="../assests/pdf/jobs/2023/Adv_COVID19_PRJ.pdf" target="_blank"><i class="fa fa-file-pdf-o" style={{fontSize:"28px",color:"red"}}></i>Details</a></p>
                    <p ><a href="../assests/pdf/jobs/2023/DEO_selection_list.pdf" target="_blank"><i class="fa fa-file-pdf-o" style={{fontSize:"28px",color:"red"}}></i>Result</a></p>
                    
                     </td>
                </tr>
                <tr>
                    <td  valign="center" ><p style={{fontSize:"18px"}}>Walk-In Interview for position of Project Reseach Scientist-I (Medical) UR-01, Project Assistant (Technical) SC-01, Project Assistant (Statistical) UR-01, Field Worker UR-2, SC-01, ST-01  at ICMR-NIOH</p>
                   
                    </td>
                    <td  valign="center" >Temporary</td>
                    <td  valign="center" ><p>04/05/2023</p></td>
                    <td  valign="center" ><p>Walk-In Interview extended Date: 30/05/2023, 10:30 AM. </p></td>
                    <td  valign="center" ><p ><a href="../assests/pdf/jobs/2023/CRIPSI_Job_Notice.pdf" target="_blank"><i class="fa fa-file-pdf-o" style={{fontSize:"28px",color:"red"}}></i>Details</a></p>
                    <p ><a href="../assests/pdf/jobs/2023/Corrigendum_18052023.pdf" target="_blank"><i class="fa fa-file-pdf-o" style={{fontSize:"28px",color:"red"}}></i>Corrigendum</a></p>
                    
                     </td>
                </tr>
                <tr>
                    <td  valign="center" ><p style={{fontSize:"18px"}}>Recruitment  for Temporary/Project Posts at ICMR-NIOH-ROHCS(re-advertised)</p>
                   
                    </td>
                    <td  valign="center" >Temporary</td>
                    <td  valign="center" ><p>01/05/2023</p></td>
                    <td  valign="center" ><p>Last Date: 19/05/2023 till 5:00 PM. </p></td>
                    <td  valign="center" ><p ><a href="../assests/pdf/jobs/2023/CRISPI RE-ADVT.pdf" target="_blank"><i class="fa fa-file-pdf-o" style={{fontSize:"28px",color:"red"}}></i>Details</a></p>
                    
                     </td>
                </tr>
                <tr>
                    <td  valign="center" ><p style={{fontSize:"18px"}}>Recruitment  for Temporary/Project Posts at ICMR-NIOH-ROHCS</p>
                   
                    </td>
                    <td  valign="center" >Temporary</td>
                    <td  valign="center" ><p>17/04/2023</p></td>
                    <td  valign="center" ><p>Last Date: 25/04/2023 till 5:00 PM. </p></td>
                    <td  valign="center" ><p ><a href="../assests/pdf/jobs/2023/Advt Proj assistant TDI Proj Dr S Raghavan.pdf" target="_blank"><i class="fa fa-file-pdf-o" style={{fontSize:"28px",color:"red"}}></i>Details</a></p>
                    
                     </td>
                </tr>
                <tr>
                    <td  valign="center" ><p style={{fontSize:"18px"}}>Recruitment Notification for Temporary/Project Posts at ICMR-NIOH</p>
                    <p style={{fontSize:"18px", color:"Green"}}>Corrigendum for Additional Posts</p>
                    </td>
                    <td  valign="center" >Temporary</td>
                    <td  valign="center" ><p>10/04/2023</p><p>13/04/2023</p></td>
                    <td  valign="center" ><p>Walk In Interview: 17/04/2023 at 9:30 AM to 11:00 AM. </p></td>
                    <td  valign="center" ><p ><a href="../assests/pdf/jobs/2023/ADVT_167-29-03-2023.pdf" target="_blank"><i class="fa fa-file-pdf-o" style={{fontSize:"28px",color:"red"}}></i>Details</a></p>
                    <p ><a href="../assests/pdf/jobs/2023/Corrigendum_11_4_2023.pdf" target="_blank"><i class="fa fa-file-pdf-o" style={{fontSize:"28px",color:"red"}}></i>Corrigendum</a></p>
                     </td>
                </tr>

                <tr>
                    <td  valign="center" ><p style={{fontSize:"18px"}}>Recruitment Notification for Temporary/Project Posts at ICMR-NIOH-ROHCS</p>          
                    </td>
                    <td  valign="center" >Temporary</td>
                    <td  valign="center" >29/03/2023</td>
                    <td  valign="center" ><p>Last Date: 13/04/2023 till 05:00 P.M. </p></td>
                    <td  valign="center" ><p ><a href="../assests/pdf/jobs/2023/Notification CRISPI Project.pdf" target="_blank"><i class="fa fa-file-pdf-o" style={{fontSize:"28px",color:"red"}}></i>Details</a></p>
                    
                  
                     <p ><a href="../assests/pdf/jobs/2023/Corrigendum_18052023.pdf" target="_blank"><i class="fa fa-file-pdf-o" style={{fontSize:"28px",color:"red"}}></i>Corrigendum</a></p>
                    
                    </td>
                </tr>
               
                
                <tr>
                    <td  valign="center" ><p style={{fontSize:"18px"}}>Walk-In Interview for Technician at ICMR-NIOH</p>
                    <p style={{fontSize:"18px", color:"Green"}}>Result Declared</p>
                    </td>
                    <td  valign="center" >Temporary</td>
                    <td  valign="center" ><p>20/03/2023</p><p>17/04/2023</p></td>
                    <td  valign="center" ><p>Walk-In Interview Date: 06/04/2023 at 10:00 A.M. </p></td>
                    <td  valign="center" ><p ><a href="../assests/pdf/jobs/2023/RecruitmentNotification_Dr.KuldipUpadhyay.pdf" target="_blank"><i class="fa fa-file-pdf-o" style={{fontSize:"28px",color:"red"}}></i>Details</a></p>
                    <p ><a href="../assests/pdf/jobs/2023/Result_Project_Dr.K.Upadhyay.pdf" target="_blank"><i class="fa fa-file-pdf-o" style={{fontSize:"28px",color:"red"}}></i>Selected Candidate</a></p>
                     </td>
                </tr>
                <tr>
                    <td  valign="center" ><p style={{fontSize:"18px"}}>Recruitment Notification  of Jr. Research Fellow at ICMR-NIOH</p>
                    <p style={{fontSize:"18px", color:"Green"}}>Result Declared</p>
                    </td>
                    <td  valign="center" >Temporary</td>
                    <td  valign="center" ><p>07/03/2023</p><p>05/04/2023</p></td>
                    <td  valign="center" ><p>Last Date: 16/03/2023 at 5:00 P.M. </p></td>
                    <td  valign="center" ><p ><a href="../assests/pdf/jobs/2023/JRF_RecruitmentNotification.pdf" target="_blank"><i class="fa fa-file-pdf-o" style={{fontSize:"28px",color:"red"}}></i>Details</a></p>
                    <p ><a href="../assests/pdf/jobs/2023/Notification_Selection.pdf" target="_blank"><i class="fa fa-file-pdf-o" style={{fontSize:"28px",color:"red"}}></i>Selected Candidate</a></p>
                     </td>
                </tr>
                <tr>
                    <td  valign="center" ><p style={{fontSize:"18px"}}>Recruitment Notification  of Project Techniian-III and Data Entry Operator-Grade B at ICMR-NIOH</p>
                    <p style={{fontSize:"18px", color:"Green"}}>Result Declared</p>
                    </td>
                    <td  valign="center" >Temporary</td>
                    <td  valign="center" ><p>07/03/2023</p><p>24/03/2023</p></td>
                    <td  valign="center" ><p>Walk-In Date: 15/03/2023 at 10:00 A.M. </p></td>
                    <td  valign="center" ><p ><a href="../assests/pdf/jobs/2023/RecruitmentNotification_ECLProject.pdf" target="_blank"><i class="fa fa-file-pdf-o" style={{fontSize:"28px",color:"red"}}></i>Details</a></p>
                    <p ><a href="../assests/pdf/jobs/2023/result.pdf" target="_blank"><i class="fa fa-file-pdf-o" style={{fontSize:"28px",color:"red"}}></i>Selected Candidate</a></p>
                     </td>
                </tr>



                <tr>
                    <td  valign="center" ><p style={{fontSize:"18px"}}>Recruitment Notification  of Project Techniian-III and Project Technical OFficer at ICMR-NIOH</p>
                    <p style={{fontSize:"18px", color:"Green"}}>Result Declared</p>
                    </td>
                    <td  valign="center" >Temporary</td>
                    <td  valign="center" ><p>28/02/2023 </p><p>13/04/2023 </p></td>
                    <td  valign="center" ><p>Walk-In Date: 21/03/2023 at 10:00 A.M. </p></td>
                    <td  valign="center" ><p ><a href="../assests/pdf/jobs/2023/RecruitmentNotification_Dr.MihirRupani.pdf" target="_blank"><i class="fa fa-file-pdf-o" style={{fontSize:"28px",color:"red"}}></i>Details</a></p>
                    <p ><a href="../assests/pdf/jobs/2023/Notification_SelectionofCandidates.pdf" target="_blank"><i class="fa fa-file-pdf-o" style={{fontSize:"28px",color:"red"}}></i>Selected Candidate</a></p>
                     </td>
                </tr>
                <tr>
                    <td  valign="center" ><p style={{fontSize:"18px"}}>Recruitment Notification  of Project Assistant at ICMR-NIOH-ROHCS(Bengaluru)</p>
                    <p style={{fontSize:"18px",color:"green"}}>Corrigendum for date Extension</p>
                    </td>
                    <td  valign="center" >Temporary</td>
                    <td  valign="center" ><p>28/02/2023 </p><p>23/03/2023 </p></td>
                    <td  valign="center" ><p>16/03/2023 </p><p>31/03/2023 </p></td>
                    <td  valign="center" ><p ><a href="../assests/pdf/jobs/2023/RecruitmentNotification_ROHCS_Dr.Raghavan.pdf" target="_blank"><i class="fa fa-file-pdf-o" style={{fontSize:"28px",color:"red"}}></i>Details</a></p>
                    <p ><a href="../assests/pdf/jobs/2023/Corrigendum_ExtensionofDueDate.pdf" target="_blank"><i class="fa fa-file-pdf-o" style={{fontSize:"28px",color:"red"}}></i>Corrigendum</a></p>
                     </td>
                </tr>
                <tr>
                    <td  valign="center" ><p style={{fontSize:"18px"}}>Recruitment Notification  of Project Assistant & Project Technical Officer at ICMR-NIOH</p>
                   <p style={{fontSize:"18px",color:"green"}}> Result Declared</p>
                    </td>
                    <td  valign="center" >Temporary</td>
                    <td  valign="center" >16/02/2023</td>
                    <td  valign="center" ><p>Walk In: 03/03/2023 at 10:00 A.M. </p></td>
                    <td  valign="center" ><p ><a href="../assests/pdf/jobs/2023/Final Notification-PROJECT-ICU-HCWs-2022-23.pdf" target="_blank"><i class="fa fa-file-pdf-o" style={{fontSize:"28px",color:"red"}}></i>Details</a></p>
                    <p ><a href="../assests/pdf/jobs/2023/Scan3.pdf" target="_blank"><i class="fa fa-file-pdf-o" style={{fontSize:"28px",color:"red"}}></i>Selected Candidate</a></p>
                     </td>
                </tr>



                <tr>
                    <td  valign="center" ><p style={{fontSize:"18px"}}>Notification For Recruitment of Project Assistant at ICMR-ROHCS-Bengaluru</p>
                    <p style={{fontSize:"18px",color:"green"}}> Shortlisted Candidate</p>
                    </td>
                    <td  valign="center" >Temporary</td>
                    <td  valign="center" >14/02/2023</td>
                    <td  valign="center" ><p>16/03/2023 5:00 P.M.</p></td>
                    <td  valign="center" ><p ><a href="../assests/pdf/jobs/2023/letter recruitment.pdf" target="_blank"><i class="fa fa-file-pdf-o" style={{fontSize:"28px",color:"red"}}></i>Details</a></p>
                    <p ><a href="../assests/pdf/jobs/2023/Lead Toxicity-2.pdf" target="_blank"><i class="fa fa-file-pdf-o" style={{fontSize:"28px",color:"red"}}></i>Details</a></p>
                     </td>
                </tr>
                <tr>
                    <td  valign="center" ><p style={{fontSize:"18px"}}>Notification For Recruitment of Project Technician-III at ICMR-ROHCS-Bengaluru</p> <p style={{fontSize:"18px",color:"green"}}> Corrigendum For application Form</p>
                     <p style={{fontSize:"18px",color:"green"}}>List of Shortlisted Candidate for Interview</p><p style={{fontSize:"18px",color:"green"}}>Result Declared</p>
                    </td>
                    <td  valign="center" >Temporary</td>
                    <td  valign="center" ><p>7/02/2023 </p><p>14/02/2023 </p><p>16/02/2023 </p><p>31/03/2023 </p></td>
                    <td  valign="center" ><p>25/02/2023 </p><p>Walk In-Interview: 20/03/2023 </p></td>
                    <td  valign="center" ><p ><a href="../assests/pdf/jobs/2023/RecruitmentNotification_ROHC(S)_Bangalore.pdf" target="_blank"><i class="fa fa-file-pdf-o" style={{fontSize:"28px",color:"red"}}></i>Details</a></p><p ><a href="../assests/pdf/jobs/2023/corrigendum_ApplicationForm.pdf" target="_blank"><i class="fa fa-file-pdf-o" style={{fontSize:"28px",color:"red"}}></i>Corrigendum</a></p>
                     <p ><a href="../assests/pdf/jobs/2023/ListofEligibleCandidates_ROHCS.pdf" target="_blank"><i class="fa fa-file-pdf-o" style={{fontSize:"28px",color:"red"}}></i>Shortlisted Candidate</a></p>
                     <p ><a href="../assests/pdf/jobs/2023/Interview Select Candidate Aflatoxin-2.pdf" target="_blank"><i class="fa fa-file-pdf-o" style={{fontSize:"28px",color:"red"}}></i>Selected Candidate</a></p>
                     </td>
                </tr>
                <tr>
                    <td  valign="center" ><p style={{fontSize:"18px"}}>Notification For Walk In Interview For Recruitment on Contract Basis for Project Scientist and Many more Posts</p>
                    </td>
                    <td  valign="center" >Temporary</td>
                    <td  valign="center" >27/01/2023</td>
                    <td  valign="center" ><p>Walk In Interview: 14/02/2023 & 15/02/2023 at 9:30 A.M. </p></td>
                    <td  valign="center" ><p ><a href="../assests/pdf/jobs/2023/NIOH-Gujarat.pdf" target="_blank"><i class="fa fa-file-pdf-o" style={{fontSize:"28px",color:"red"}}></i>Details</a></p>
                     </td>
                </tr>

                <tr>
                    <td  valign="center" ><p style={{fontSize:"18px"}}>Notification For Walk In Interview/Written Test For Recruitment on Contract Basis</p>
                    <p style={{fontSize:"18px",color:"Green"}}>Result Declared</p>
                    </td>
                    <td  valign="center" >Temporary</td>
                    <td  valign="center" >23/01/2023</td>
                    <td  valign="center" ><p>Walk In Interview: 08/02/2023 10:00 A.M.</p><p>Result Date: 17/02/2023</p></td>
                    <td  valign="center" ><p ><a href="../assests/pdf/jobs/2023/RecruitementNotifn_ENVIS.pdf" target="_blank"><i class="fa fa-file-pdf-o" style={{fontSize:"28px",color:"red"}}></i>Details</a></p>
                    <p ><a href="../assests/pdf/jobs/2023/Scan1.pdf" target="_blank"><i class="fa fa-file-pdf-o" style={{fontSize:"28px",color:"red"}}></i>Selected Candidate</a></p>
                     </td>
                </tr>
                <tr>
                    <td  valign="center" ><p style={{fontSize:"18px"}}>Recruitment of Project Technician III and Project Technician II under the extramural project</p>
                    <p style={{fontSize:"18px",color:"green"}}>Result</p>
                    </td>
                    <td  valign="center" >Temporary</td>
                    <td  valign="center" >17/01/2023</td>
                    <td  valign="center" ><p>Walk In Interview: 01/02/2023 10:00 A.M.</p><p>Result: 15/02/2023</p></td>
                    <td  valign="center" ><p ><a href="../assests/pdf/jobs/2023/Recruitement_Notification_17012023.pdf" target="_blank"><i class="fa fa-file-pdf-o" style={{fontSize:"28px",color:"red"}}></i>Details</a></p>
                     
                    <p ><a href="../assests/pdf/jobs/2023/Result_NIOH projects.pdf" target="_blank"><i class="fa fa-file-pdf-o" style={{fontSize:"28px",color:"red"}}></i>Selected Candidates</a></p>
                     </td>
                </tr>
                <tr>
                    <td  valign="center" ><p style={{fontSize:"18px"}}>Vacancy Circular for Accounts Officer and Accounts Officer (Jr.) on Deputation Basis</p>
                    <p style={{fontSize:"18px",color:"green"}}> Corrigendum For date Extension</p>
                    </td>
                    <td  valign="center" >Permanent</td>
                    <td  valign="center" >16/01/2023</td>
                    <td  valign="center" ><p>28/02/2023</p><p>15/03/2023(Extended)</p></td>
                    <td  valign="center" ><p ><a href="../assests/pdf/jobs/2023/ACO English.pdf" target="_blank"><i class="fa fa-file-pdf-o" style={{fontSize:"28px",color:"red"}}></i>Details in English</a></p>
                    <p ><a href="../assests/pdf/jobs/2023/ACO Hindi.pdf" target="_blank"><i class="fa fa-file-pdf-o" style={{fontSize:"28px",color:"red"}}></i>Details in Hindi</a></p>
                    <p ><a href="../assests/pdf/jobs/2023/Application form_ACO.pdf" target="_blank"><i class="fa fa-file-pdf-o" style={{fontSize:"28px",color:"red"}}></i>Application Form</a></p>
                    <p ><a href="../assests/pdf/jobs/2023/Memorandum.pdf" target="_blank"><i class="fa fa-file-pdf-o" style={{fontSize:"28px",color:"red"}}></i>Corrigendum</a></p>
                    </td>
                </tr>
                <tr>
                    <td  valign="center" ><p style={{fontSize:"18px"}}>Recruitment Notification for Various posts</p><p style={{fontSize:"18px"}}>List Of Selected Candidates</p>
                    </td>
                    <td  valign="center" >Temporary</td>
                    <td  valign="center" >23/11/2022</td>
                    <td  valign="center" ><p>Walik-In Date & Time :</p><p><strong>Dec 08,2022 .Reporting time: 10.30 AM</strong></p><p>Result Date :<strong>Dec 19,2022</strong></p></td>
                    <td  valign="center" ><p ><a href="../assests/pdf/jobs/2022/Notification dated 23.11.2022.pdf" target="_blank"><i class="fa fa-file-pdf-o" style={{fontSize:"28px",color:"red"}}></i>Details</a></p>
                    <p ><a href="../assests/pdf/jobs/2022/ProjectTech.pdf" target="_blank"><i class="fa fa-file-pdf-o" style={{fontSize:"28px",color:"red"}}></i>Selected Candidate</a></p>
                    </td>
                </tr>
                <tr>
                    <td  valign="center" ><p style={{fontSize:"18px"}}>Notification For Engagement of Consultant(Admin)</p>
                    <p style={{fontSize:"18px",color:"green"}}> Corrigendum For date Extension</p>
                    </td>
                    <td  valign="center" >Temporary</td>
                    <td  valign="center" >18/10/2022</td>
                    <td  valign="center" ><p>09/11/2022</p><p>30/11/2022(Extended)</p></td>
                    <td  valign="center" ><p ><a href="../assests/pdf/jobs/2022/Notification_Consultant(Admin).pdf" target="_blank"><i class="fa fa-file-pdf-o" style={{fontSize:"28px",color:"red"}}></i>Details</a></p>
                    <p ><a href="../assests/pdf/jobs/2022/Consultant_Corigendum(Admin).pdf" target="_blank"><i class="fa fa-file-pdf-o" style={{fontSize:"28px",color:"red"}}></i>Corrigendum</a></p>
                    </td>
                </tr>
                <tr>
                    <td  valign="center" ><p style={{fontSize:"18px"}}>Walk-In Interview for Project Positions in Gujarat State</p>
                    </td>
                    <td  valign="center" >Temporary</td>
                    <td  valign="center" >24/06/2022</td>
                    <td  valign="center" ><p>Date-Time & Venue: 05-07-2022,<br/>06-07-2022 from 09:30 A.M. Onwards in NIOH-Ahmedabad</p></td>
                    <td  valign="center" ><p ><a href="../assests/pdf/jobs/2022/DABS.pdf" target="_blank"><i class="fa fa-file-pdf-o" style={{fontSize:"28px",color:"red"}}></i>Details</a></p></td>
                </tr>
                <tr>
                    <td  valign="center"  ><p >Walk-In/Interview for Junior Research Fellow and Research Associate - NIOH Ahmedabad</p></td>
                    <td  valign="center" >Temporary</td>
                    <td  valign="center" >02/05/2022</td>
                    <td  valign="center" ><p>Date & Time: 20/05/2022,11:00 A.M.</p></td>
                    <td><p ><a href="../assests/pdf/jobs/2022/SelectedCandidateRA.pdf" target="_blank"><i class="fa fa-file-pdf-o" style={{fontSize:"28px",color:"red"}}></i>[Selected Candidates]</a></p>
                    <p ><a href="../assests/pdf/jobs/2022/Corrigendum.pdf" target="_blank"><i class="fa fa-file-pdf-o" style={{fontSize:"28px",color:"red"}}></i>[Corrigendum for Date Change]</a></p>
                    <p ><a href="../assests/pdf/jobs/2022/JRF_walkIN.pdf" target="_blank"><i class="fa fa-file-pdf-o" style={{fontSize:"28px",color:"red"}}></i>[Detailed Information]</a></p></td>
                </tr>
                <tr>
                    <td  valign="center"  ><p >Junior Research Fellow - NIOH Ahmedabad</p></td>
                    <td  valign="center" >Temporary</td>
                    <td  valign="center" >22/04/2022</td>
                    <td  valign="center" ><p>Date & Time: 04/05/2022,06:00 P.M.</p></td>
                    <td><p ><a href="../assests/pdf/jobs/2022/JRF Advertisement_BPA.pdf" target="_blank"><i class="fa fa-file-pdf-o" style={{fontSize:"28px",color:"red"}}></i>[Detailed Information]</a></p>
                    <p ><a href="../assests/pdf/jobs/2022/Application form_BPA.pdf" target="_blank"><i class="fa fa-file-pdf-o" style={{fontSize:"28px",color:"red"}}></i>[Application Form]</a></p></td>
                </tr>
                <tr>
                    <td  valign="center"  ><p>Technical Assistant - NIOH Ahmedabad</p>
                    </td>
                <td  valign="center" >Temporary</td>
                <td  valign="center">17/03/2022</td>
                <td  valign="center" ><p>Walk-in Date & Time: 07/04/2022,11:00 A.M. Onwards</p></td>
                <td><p ><i class="fa fa-file-pdf-o" style={{fontSize:"28px",color:"red"}}></i><a href="../assests/pdf/jobs/2022/Advertisement of TA (01-ST).pdf" target="_blank">[More Information]</a></p></td>
                </tr>
	
                
                </table>
            </center>
        
        </div>
      
        </div>
        
      
        </div>);
}
export default Recruitment;