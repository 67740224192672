import React from 'react';
import '../carousel.css';
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import NavbarMenu from '../NavbarMenu.js';
function DrAnkitVir() {
    return (
      <div> <NavbarMenu /><div class="manual">
        <div class="row container">
        <p class="mypathhead">
        <Link to="/"><span class="mypath">Home</span></Link>&gt;<Link to="/ScientistNIOH"><span class="mypath">Scientist's Profile</span></Link>&gt;<span class="mypath">NIOH Ahmedabad</span>
        </p>
          
        </div>
        <h1 class="mypathhead">Scientist's Detail</h1>
        <div class="scientistdetail">
      
            <div class="panel my-panel" >
      <div class="panel-heading my-panel-head" align="left"><strong>Basic Details</strong></div>
      <div class="panel-body" align="left">
      <table width="100%">
        <thead>
          <th width="28%"></th>
          <th width="50%"></th>
          <th width="22%"></th>
        </thead>
        <tbody>
          <tr>
              <td  rowspan="5"><img src="../assests/images/niohScientist/AnkitV.jpg" width="170px" height="170px" className="dg-img"/></td>
              <td  ><p className="myfontSci trSci"> Dr Ankit Viramgami</p> </td>
              <td  ><button className="mybuttonSci trSci">View Profile</button></td>
            </tr>
            
            <tr>
              
              <td className="myfontSci"><p className="myfontSci">Health sciences Division</p></td>
              <td></td>
             </tr>
            <tr>
              <td><p ><a href=""><i className="fa fa-envelope" aria-hidden="true"></i>   Email: viramgami.a@icmr.gov.in</a></p></td>
              <td></td>
            </tr> 
            <tr>
              <td><p ><a href=""> <i className="fa fa-phone-square"></i>  Contact: 079-22688756</a></p></td>
              <td></td>
            </tr>   
        </tbody>
           
            
            </table>
      </div>
    </div>
       
        <div class="panel my-panel" >
      <div class="panel-heading my-panel-head" align="left"><strong>Academic Qualification</strong></div>
      <div class="panel-body" align="left">M.B.B.S., MD (P&SM), PGCIH
</div>
    </div>
    <div class="panel my-panel" >
      <div class="panel-heading my-panel-head" align="left"><strong>Research Interest </strong></div>
      <div class="panel-body" align="left"> Air pollution and Health, Occupational lung diseases, Biological hazards at workplace & Health effects of heavy metals</div>
    </div>
    <div class="panel my-panel" >
      <div class="panel-heading my-panel-head" align="left"><strong>Membership/Affliation </strong></div>
      <div class="panel-body" align="left">
      <ul><li>IAPSM</li><li>IAOH</li>
</ul>
</div>
</div>
<div class="panel my-panel">
      <div class="panel-heading my-panel-head" align="left"><strong>Fellowship/Awards </strong></div>
      <div class="panel-body" align="left">To be Update
</div>
</div>
<div class="panel my-panel">
      <div class="panel-heading my-panel-head" align="left"><strong>Publications </strong></div>
      <div class="panel-body" align="left">
	
      <ol>

      <li>	Upadhyay, K., <strong>Viramgami, A.</strong>, Bagepally, B. S., &Balachandar, R. (2022). Association between blood lead levels and markers of calcium homeostasis: a systematic review and meta-analysis. Scientific Reports, 12(1), 1-9.
</li><br/><li>	Murhekar, M. V., Bhatnagar, T., Thangaraj, J. W. V., Saravanakumar, V., Santhosh Kumar, M., Selvaraju, S., ... & ICMR serosurveillance group. (2021). Seroprevalence of IgG antibodies against SARS-CoV-2 among the general population and Healthcare workers in India, June–July 2021: A population-based cross-sectional study. PLoS medicine, 18(12), e1003877.
</li><br/><li>	Singh, D. P., Sahu, M. C., Pagdhune, A., <strong>Viramgami, A.</strong>, Perumal, S., Balachandar, R., & Sarkar, K. (2021). Viral load could be an important determinant for fomites based transmission of viral infections. Journal of Family Medicine and Primary Care, 10(2), 929.
</li><br/><li>	Murhekar, M. V., Bhatnagar, T., Thangaraj, J. W. V., Saravanakumar, V., Kumar, M. S., Selvaraju, S., ... & Vinod, A. (2021). SARS-CoV-2 seroprevalence among the general population and Healthcare workers in India, December 2020–January 2021. International Journal of Infectious Diseases, 108, 145-155.
</li><br/><li>	Upadhyay, K., <strong>Viramgami, A.</strong>, Pagdhune, A., Balachandar, R., & Sarkar, K. (2021). Hematological and cardiovascular effects of chronic low level lead exposure: A study on e-waste recyclers. Clinical Epidemiology and Global Health, 9, 269-274.
</li><br/><li>	Murhekar, M. V., Bhatnagar, T., Selvaraju, S., Saravanakumar, V., Thangaraj, J. W. V., Shah, N., ... & Zaman, K. (2021). SARS-CoV-2 antibody seroprevalence in India, August–September, 2020: findings from the second nationwide household serosurvey. The Lancet Global Health, 9(3), e257-e266.
</li><br/><li>	<strong>Viramgami, A.</strong>, Bagepally, B. S., Balachandar, R., &Mansuri, M. (2021). Pulmonary Function Changes on Exposure To Air Pollutants: Inferences From Systematic Review and Meta-Analysis of Observational Studies Involving Traffic Regulators. Available at SSRN 3800556.
</li><br/><li>	Murhekar, M. V., Bhatnagar, T., Thangaraj, J. W. V., Saravanakumar, V., Kumar, M. S., Selvaraju, S., ... & Bhargava, B. (2021). Prevalence of IgG antibodies against SARS-CoV-2 among the general population and Healthcare workers in India, June–July 2021.
</li><br/><li>	<strong>Viramgami, A.</strong>, Pagdhune, A., Sarkar, K., &Balachandar, R. (2020). Occupational Health and Safety Practices at Workplace during COVID-19 Pandemic. Journal of Comprehensive Health, 8(2), 77-82.
</li><br/><li>	Panchal, S., <strong>Viramgami, A.</strong> P., &Pingle, S. (2020). Prevalence and Determinants of Musculoskeletal Disorders among Information Technology Sector Employees of Ahmedabad, Gujarat. Journal of Comprehensive Health, 8(2), 90-93.
</li><br/><li>	Murhekar, M. V., Bhatnagar, T., Selvaraju, S., Rade, K., Saravanakumar, V., Thangaraj, J. W. V., ... &Laxmaiah, A. (2020). Prevalence of SARS-CoV-2 infection in India: Findings from the national serosurvey, May-June 2020. The Indian journal of medical research, 152(1-2), 48.
</li><br/><li>	<strong>Viramgami, A.</strong>, Upadhyay, K., &Balachandar, R. (2020). Catastrophic Health expenditure and Health facility access among rural informal sector families. Clinical Epidemiology and Global Health, 8(4), 1325-1329.
</li><br/><li>	Kashyap, R., <strong>Viramgami, A.</strong> P., Sadhu, H. G., Raghavan, S., Mishra, S. D., &Thasale, R. R. (2020). Effect of kerosene and biomass fuel as cooking medium on pulmonary function of adult nontobacco addict homemaker women residing in slums of Ahmedabad City, Gujarat. Indian Journal of Public Health, 64(4), 362.
</li><br/><li>	Sharma, S., Mohanty, P. S., Omar, R., <strong>Viramgami, A.</strong> P., & Sharma, N. (2020). Determinants and utilization of maternal Health care services in urban slums of an industrialized city, in western India. Journal of Family & Reproductive Health, 14(2), 95.
</li><br/><li>	Murhekar, M. V., Bhatnagar, T., Selvaraju, S., Saravanakumar, V., Thangaraj, J. W. V., Shah, N., ... & Bhargava, B. (2020). SARS-CoV-2 antibody prevalence in India: findings from the second nationwide household serosurvey, August-September 2020.
</li><br/><li>	Upadhyay, K., &<strong>Viramgami, A.</strong> (2019). Specific Enzymatic Activity of Ceruloplasmin as a Potential Indicator of Copper Status. Current Chemical Biology, 13(3), 250-256.
</li><br/><li>	<strong>Viramgami, A.</strong> P., Verma, P. B., Vala, M. C., & Sharma, S. (2019). A cross-sectional study to assess reproductive and child Health profile of working women residing in urban slums of Rajkot city. Indian Journal of Community Medicine: Official Publication of Indian Association of Preventive & Social Medicine, 44(4), 313.
</li><br/><li>	Sharma, S., Verma, P. B., <strong>Viramgami, A. P.</strong>, Vala, M. C., &Lodhiya, K. K. (2018). Analysis of out-of-pocket expenditure in utilization of maternity care services in urban slums of Rajkot City, Gujarat. Indian journal of community medicine: official publication of Indian Association of Preventive & Social Medicine, 43(3), 215.
</li><br/><li>	<strong>Viramgami, A.</strong> P., & Sadhu, H. G. (2018). Evaluation of training program “Basic Concepts of Occupational Health” for students of diploma in sanitary inspector course and way forward. Indian Journal of Occupational and Environmental Medicine, 22(2), 106.
</li><br/><li>	 <strong>Viramgami AP</strong>, Vala M, Sharma S (2018) Influence of maternal factors on nutritional status of urban slum children..Int J Sci Res.;7(6);4-5.
</li><br/><li>	Banerjee, A., Patel, U., Verma, P., <strong>Viramgami, A.</strong>, &Vala, M. (2015). Health status of children under three years of age residing in slums of Rajkot city, Gujarat, India. JMR, 1, 118-21.
</li><br/><li>	Lodhiya, K. K., Zalavadiya, D. D., Dashratha, C. K., <strong>Viramgami, A.</strong> P., Jogia, A. D., &Kadri, A. M. (2015). Assessment of knowledge & skills of staff involved in providing routine new-born care at various public Health facilities of Rajkot district. Community Med, 7(1), 10-15.
</li><br/><li>	Chudasama, R. K., Patel, U. V., Verma, P. B., Vala, M., Rangoonwala, M., Sheth, A., & <strong>Viramgami, A.</strong> (2015). Evaluation of Anganwadi centres performance under integrated child development services (ICDS) program in Gujarat state, India during year 2012-13. Journal of Mahatma Gandhi Institute of Medical Sciences, 20(1), 60.
</li><br/><li>	<strong>Viramgami, A.</strong> P., Vala, M. C., Sharma, S., Sheth, A., Ninama, R., &Verma, P. B. (2014). Study of socio-demographic profile of malnourished children residing in urban slums of Rajkot city, Gujarat, India.Int.  J Res Med 19:123-31
</li><br/><li>	Chudasama, R. K., Patel, U. V., Patel, R. R., <strong>Viramgami, A.</strong>, Vala, M., & Sharma, S. (2014). A two wave comparison of characteristics of hospitalized patients with severe and non-severe pandemic influenza A (H1N1) 2009 (H1N1pdm09) in Saurashtra Region, India. Journal of Mahatma Gandhi Institute of Medical Sciences, 19(2), 123.
</li><br/><li>	Vala, M. C., Patel, U. V., Joshi, N. B., Zalavadiya, D. D., <strong>Viramgami, A.</strong> P., & Sharma, S. (2013). Knowledge and practice regarding Malaria among people of urban and rural areas of Rajkot District, Gujarat, India. Int J Res Med, 2(4), 38-42.
Mayur, V., Umed, P., Nirav, J., Dipesh, Z., Chirag, B., & <strong>Ankit, V. </strong>(2013). Knowledge and Practices regarding commonly occurring mosquito borne diseases among people of urban and rural areas of Rajkot District, Gujarat. J Res Med Dent Sci, 1, 46-51.
</li>
</ol>
	
	
	
</div>
</div>
</div>
        </div>
    </div>
    );
}
export default DrAnkitVir;