import React from 'react';
import {Link} from "react-router-dom";
import './carousel.css';

import NavbarMenu from './NavbarMenu.js';
function Pio() {
    return (
        <div> <NavbarMenu /> <div class="manual">
        <div class="row container">
        <p class="mypathhead">
        <Link to="/"><span class="mypath">Home</span></Link>&gt;<span class="mypath">PIO</span>
        </p>
          
        </div>
        <h1 class="mypathhead">Public Information Officer</h1>
        <div class="scientistdetail">
        <div class="mytext">
       <h2>In pursuance of Section 5 (1) and Section 19 (1) 0f the Right to Information Act, 2005, the Institute has revised the list of Central Public Information Officers as under:</h2>
       <table width="90%"style={{fontSize:"18px"}} class="table table-bordered mypathhead">
                <tr>
                    <th width="20%" style={{color:"white", backgroundColor: "#005A9C"}}><strong>S No</strong></th>
                    <th width="10%" style={{color:"white", backgroundColor: "#005A9C"}}><strong>Name of Officer </strong></th>
                    <th width="10%" style={{color:"white", backgroundColor: "#005A9C"}}><strong>Matters</strong></th>
                    <th width="15%" style={{color:"white", backgroundColor: "#005A9C"}}><strong>Email-ID</strong></th>
                    
                    
                </tr>
                <tr>
                    <td valign="top">1
                   </td>
                    <td valign="top">Dr. S D Mishra, Scientist-D</td>
                    <td valign="top">Scientific</td>
                    <td valign="top">sd[dot]mishra[at]gov[dot]in</td>
                    

                </tr>
                <tr>
                    <td valign="top">2
                   </td>
                    <td valign="top">Mr. Roadmal Porwal Jr. Translation Officer</td>
                    <td valign="top">Administrative & Finance</td>
                    <td valign="top">porwal[dot]rm[at]icmr[dot]gov[dot]in</td>
                </tr>
        </table>
        <h2>Appellate Authority</h2>
       <table width="90%"style={{fontSize:"18px"}} class="table table-bordered mypathhead">
                <tr>
                    <th width="20%" style={{color:"white", backgroundColor: "#005A9C"}}><strong>S No</strong></th>
                    <th width="10%" style={{color:"white", backgroundColor: "#005A9C"}}><strong>Name of Officer </strong></th>
                    <th width="10%" style={{color:"white", backgroundColor: "#005A9C"}}><strong>Matters</strong></th>
                    <th width="15%" style={{color:"white", backgroundColor: "#005A9C"}}><strong>Email-ID</strong></th>
                    
                    
                </tr>
                <tr>
                    <td valign="top">1
                   </td>
                    <td valign="top">Dr Ankit Mayurkumar Sheth, Scientist-E</td>
                    <td valign="top">Scientific</td>
                    <td valign="top">ankit[dot]sheth[at]icmr[dot]gov[dot]in</td>
                    

                </tr>
                <tr>
                    <td valign="top">2
                   </td>
                    <td valign="top">Mr Rahul Wadhvani, Admin Officer</td>
                    <td valign="top">Administrative & Finance</td>
                    <td valign="top">wadhwani[dot]rj[at]icmr[dot]gov[dot]in</td>
                    

                </tr>
        </table>
        </div>
        </div>
        </div>
    </div>
    
    );
}
export default Pio;