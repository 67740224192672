import React from 'react';
import '../carousel.css';
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import NavbarMenu from '../NavbarMenu.js';
function DrGyanendra() {
    return (
      <div> <NavbarMenu /> <div class="manual">
        <div class="row container">
        <p class="mypathhead">
        <Link to="/"><span class="mypath">Home</span></Link>&gt;<Link to="/ScientistNIOH"><span class="mypath">Scientist's Profile</span></Link>&gt;<span class="mypath">NIOH Ahmedabad</span>
        </p>
          
        </div>
        <h1 class="mypathhead">Scientist's Detail</h1>
        <div class="scientistdetail">
      
            <div class="panel my-panel" >
      <div class="panel-heading my-panel-head" align="left"><strong>Basic Details</strong></div>
      <div class="panel-body" align="left">
      <table width="100%">
        <thead>
          <th width="28%"></th>
          <th width="50%"></th>
          <th width="22%"></th>
        </thead>
        <tbody>
          <tr>
              <td  rowspan="5"><img src="../assests/images/niohScientist/gs.jpg" width="170px" height="170px" CLASS="dg-img"/></td>
              <td  ><p class="myfontSci trSci"> Dr Gyanendra Singh</p> </td>
             
            </tr>
            
            <tr>
              
              <td class="myfontSci"><p class="myfontSci">Biological sciences Division</p></td>
              <td></td>
             </tr>
            <tr>
              <td><p ><a href=""><i class="fa fa-envelope" aria-hidden="true"></i>   Email: singh.drgyanendra@gov.in</a></p></td>
              <td></td>
            </tr> 
            <tr>
              <td><p ><a href=""> <i class="fa fa-phone-square"></i>  Contact: 079-22688842</a></p></td>
              <td></td>
            </tr>   
        </tbody>
           
            
            </table>
      </div>
    </div>
       
        <div class="panel my-panel" >
      <div class="panel-heading my-panel-head" align="left"><strong>Academic Qualification</strong></div>
      <div class="panel-body" align="left"><ul><li>Ph. D. (Toxicology) –CSIR-CDRI, Lucknow & JNU, New Delhi</li>

<li>Postdoctoral Fellowship (Molecular Biology) – USA
(K-State University, Manhattan, KS &
LSU Health Sciences Center, New Orleans, LA)
</li></ul>
</div>
    </div>
    <div class="panel my-panel" >
      <div class="panel-heading my-panel-head" align="left"><strong>Research Interest </strong></div>
      <div class="panel-body" align="left"> Toxicology, Cancer biology, Infectious disease, Heavy metal toxicity, Molecular biology</div>
    </div>
    <div class="panel my-panel" >
      <div class="panel-heading my-panel-head" align="left"><strong>Membership/Affliation </strong></div>
      <div class="panel-body" align="left">
      <ul><li>American College of Toxicology (Ex Full sponsored membership)</li>
<li>American Society for Virology (Ex Nominated Associate membership) </li>
<li>American Society for Microbiology (Ex Contributing membership)</li>
<li>Indian Society for the Study of Reproduction and Fertility (Life membership)</li>
</ul>
</div>
</div>
<div class="panel my-panel">
      <div class="panel-heading my-panel-head" align="left"><strong>Fellowship/Awards </strong></div>
      <div class="panel-body" align="left"><ul><li>ICMR-DHR International Fellowship Award: 2019-2020. Availed fellowship at University of Pittsburgh Medical Centre (UPMC), Pittsburgh, PA, USA.
</li><br/><li>	Received the ICMR-NIOH, Ahmedabad Scientist award for the scientific contribution - 2019. 
</li><br/><li>	Prof.GP Talwar Young Scientist Award’ at ISSRF 2017, organized by ICMR, New Delhi.
</li><br/><li>	ICMR-IIT Kharagpur Med Tech Internship Award 2017
</li><br/><li>	ICMR-IIT Kharagpur Med Tech Internship Award 2017-18 for 2 lakhs
</li><br/><li>	Association of Biotechnology & Pharmacy (ABAP) - 2015 ‘Young Scientist Award’
</li><br/><li>	Awarded international fellowship to attend ‘International Training on Environmental Toxicology’ at Chulabhorn Research Institute, Bangkok, Thailand.
</li><br/><li>	Served as a Judge for Oral presentations at RBAT- 2017 conducted by Department of Biochemistry, University of Kerala, Kerala.
</li><br/><li>	Served as Judge: Science Excellence- 2018 conducted by Department of Botany, Gujarat University, Ahmedabad.
</li><br/><li>	Recognized PhD Guideship from Gujarat University, Ahmedabad and Raksha Shakti University, Ahmedabad in Toxicology subject.
</li><br/><li>	BabuBanarasi Das University, Lucknow in accordance with CSIR-Industrial Institute of Toxicology Research, Lucknow has approved as a subject expert to evaluate and examine the PhD work in subject of Biochemistry. 
</li><br/><li>	Postdoctoral Fellowship (2007-2014), LSU Health Sciences Center/K-State University, USA Pre-Doctoral Fellowship at CSIR-Central Drug Research Institute, Luclnow.
</li><br/><li>	Editor: Journal of Metabolomics and Systems Biology 
</li><br/><li>	Associate Editor: Universal Journal of Biotechnology & Bioinformatics
</li><br/><li>	Lead Faculty – Toxicology; Virology – Webmedcentral, U.K.
</li><br/><li>	Ex-Vice-President - LSU Health Sciences Center, Postdoctoral Association, New Orleans.
</li><br/><li>	Marquis Who's Who in America 2011 Biography selected (65th Edition).
</li><br/><li>	National Eligibility Test, CSIR-UGC (NET) - 2003.
</li><br/><li>	National Eligibility Test, ASRB-ICAR (NET) - 2004.
</li><br/><li>	Graduate Aptitude Test in Engineering (GATE) – 2002 with 92.39 Percentile.
	</li>
	 
</ul>
</div>
</div>
<div class="panel my-panel">
      <div class="panel-heading my-panel-head" align="left"><strong>Publications </strong></div>
      <div class="panel-body" align="left">
	
      <ol>

      <li>	Singh DP, Yadav SK, Patel K, Patel S, Patil GP, Bijalwan V, <strong>Singh G</strong>, Palkhade R, Kondepudi KK, Boparai RK, Bishnoi M, Das S. Short-term trivalent arsenic and hexavalent chromium exposures induce gut dysbiosis and transcriptional alteration in adipose tissue of mice. Mol Biol Rep. Nov 16 (2022) 

</li><br/><li>Suchanti S, Awasthi S, <strong>Singh G</strong>, Yadav PK, Singh A, Mishra R. In silico prediction of COVID-19 cytokine storm in lung cancer types Biochemistry and Biophysics Reports.  Dec; 32: 101350 (2022)	
</li><br/><li>Mishra R, Haldar S, Biondi S, Bhari VK, <strong>Singh G</strong>, Bhowmick NA. TGF-β controls stromal telomere length through epigenetic modifications 3 Biotech 12, 290 (2022) 
</li><br/><li>Tripathi S, Parmar D, Fathima S, Raval S, <strong>Singh G</strong>. Coenzyme Q10, Biochanin A and Phloretin Attenuate Cr(VI)-Induced Oxidative Stress and DNA Damage by Stimulating Nrf2/HO-1 Pathway in the Experimental Model. Biol Trace Elem Res, Aug 12  (2022).
</li><br/><li>Swapnil T, Shabrin F, Parmar D, Singh DP, Mishra S, Mishra R,  <strong>Singh G</strong>. Therapeutic effects of CoenzymeQ10, Biochanin A and Phloretin against arsenic and chromium induced oxidative stress in mouse (Mus musculus) brain 3 Biotech,12: 116 (2022) 
</li><br/><li>Patil GP, Yadav SK, Virmagami A, Bijalwan V, Devi K, Chauhan A; Gupta SK; Fathima S, Naorem CD Yadav S, <strong>Singh G</strong>; Mishra SD, Bishnoi M, Sarkar K, Singh DP. Occupational lead exposure is an independent modulator of hypertension and poor pulmonary functions: a case-control study. Toxicology & Industrial Health Mar;38(3):139-150 (2022) 
</li><br/><li>	Suchanti S, Stephen BJ, Awasthi S, Awasthi SK, <strong>Singh G</strong>, Singh A, Mishra R. Harnessing the role of Epigenetic Histone Modification in targeting HNSCC. Epigenomics Mar;14(5):279-293 (2022)
</li><br/><li>	Choudhury P, Biswas S, <strong>Singh G</strong>, Pal A, Ghosh N, Ojha A, Das S, Dutta G, Chaudhury K. Immunological profiling and development of a sensing device for detection of IL-13 in COPD and asthma. Bioelectrochemistry; 143, 107971. (2022)
</li><br/><li>	<strong>Singh G</strong>, Thaker R, Sharma A, Parmar D. Therapeutic effects of biochanin A, phloretin, and epigallocatechin-3-gallate in reducing oxidative stress in arsenic-intoxicated mice. Environ Sci Pollut Res Int. Apr; 28(16):20517-20536 (2021)
</li><br/><li>	Shank S, Saluja S, Rawat M; <strong>Singh G</strong>, Mohammad N, Naz H, Tarique M. The causal association between occupational, environmental, and lifestyle factors and reproductive cancer  risk. Current Molecular Biology Reports volume 6, pages149–160 (2020)
</li><br/><li>	Shirish A, Mishra, SD, Kapadia V, Kumar S, <strong>Singh G</strong>. Workplace heat exposure management in Indian construction workers using cooling garment. Workplace Health &   Safety  Jan;67(1):18-26. (2019)
</li><br/><li>	Sharma S, Dewan A and <strong>Singh G</strong>*. Toxico-vigilance – An inevitable prerequisite to keep a watch on toxins around you. Journal of Forensic and Legal Medicine Jan; 45:32-35. (2017) 

</li><br/><li>	Gao X, Pham TH, Feuerbacher LA, Chen K, Hays MP, <strong>Singh G</strong>, Rueter C, Guerrero RH, Hardwidge PR. Citrobacter rodentium  NleB Inhibits Tumor Necrosis Factor (TNF) Receptor-Associated Factor 3 (TRAF3) Ubiquitination to Reduce Host Type I Interferon Production. Journal of Biological Chemistry. Aug 26;291(35):18232-8. (2016)

</li><br/><li>	<strong>Singh G</strong>*, Maurya R, Kumar A and Sinha N. Role of Apoptosis in Mediating Diclofenac Induced Teratogenesis - An In Vitro Approach. Toxicology & Industrial Health 31(7) 614– 623. (2015)

</li><br/><li>	Pham TH, Gao X, <strong>Singh G</strong>, Hardwidge PR. Escherichia coli virulence protein NleH1 interaction with the v-crk sarcoma virus CT10 oncogene-Like protein (CRKL) governs NleH1 inhibition of the ribosomal protein S3 (RPS3)/NF-kappaB pathway. Journal of Biological Chemistry. Nov 29;288 (48):34567-74. (2013)
</li><br/><li>	<strong>Singh G</strong>*, Kumar A and Sinha N*. Studying Significance of Apoptosis in Mediating Tolbutamide Induced Teratogenesis In Vitro. Fundamental & Clinical Pharmacology Aug; 26(4): 484-94. (2012)

</li><br/><li>	Kelly B L, <strong>Singh G</strong>, and Aiyar A. Molecular and cellular characterization of an AT - hook protein from Leishmania. PLoS One (6(6): e21412. (2011)
</li><br/><li>	Washington A, <strong>Singh G</strong> and Aiyar A. Diametrically opposed effects of hypoxia and oxidative stress on two viral transactivators. Virology Journal May 10; 7:93. (2010)
</li><br/><li>	<strong>Singh G</strong>* and Sinha N. Involvement of apoptosis in mediating Mitomycin C-induced teratogenesis in vitro.  Toxicology Mechanisms and Methods 20(4):190-6. (2010)

</li><br/><li>	<strong>Singh G</strong>, Aras S, Zea AH, Koochekpour S and Aiyar A. Optimal transactivation by Epstein-Barr nuclear antigen 1 requires the UR1 and ATH1 domains. Journal of Virology 83(9): 4227-4235. (2009)

</li><br/><li>	Aras S, <strong>Singh G</strong>, Johnston K, Foster T, Aiyar A. Zinc-coordination is required for and regulates transcription activation by Epstein-Barr nuclear antigen 1. PLoS Pathogens (5(6):e1000469). (2009)

</li><br/><li>	Aiyar A, Aras S, Washington A*, <strong>Singh G</strong>* and Luftig R B. Epstein-Barr Nuclear Antigen 1 modulates replication of oriP-plasmids by impeding replication and transcription fork migration through the family of repeats. Virology Journal Mar 5; 6:29. (2009)

</li><br/><li>	Bhattacharjee PS, Neumann DM, Foster TP, Clement C, <strong>Singh G</strong>, Thompson HW, Kaufman HE, Hill JM. Effective treatment of ocular HSK with a human apolipoprotein E mimetic peptide in a mouse eye model. Investigative Ophthalmology and Visual Science Oct: 49(10):4263-8. (2008)
</li><br/><li>	Toma HS, <strong>Singh G</strong>, Neumann DM, Bhattacharjee PS, Bouhnik S, Kaufman HE, Hill JM. Spontaneous Shedding of HSV-1 DNA of High and Low Phenotypic Reactivating Strains in the Rabbit Eye Model Support an Emerging Paradigm. Investigative Ophthalmology and Visual Science May: 49(13):493-493. (2008)

</li><br/><li>	Shankar S, <strong>Singh G</strong> and Srivastava RK.  Chemoprevention by resveratrol: molecular mechanisms and therapeutic potential.  Frontiers in Bioscience, 1(12):4839-54. (2007)

</li><br/><li>	Bhadauria S, <strong>Singh G</strong>, Sinha N and Srivastava S. Isoniazid induces oxidative stress, mitochondrial dysfunction and apoptosis in Hep G2 cells. Cellular and Molecular Biology, 53: 102-14. (2007)

</li><br/><li>	<strong>Singh G</strong>, Sinha N, Koushik  JC, Mathur SK and Srivastava S.  Detecting role of apoptosis in mediating cyclophosphamide induced teratogenesis in vitro. Toxicology Mechanisms and Methods, 15(6):391-7. (2005)


  	</li>
</ol>
	
	
	
</div>
</div>
</div>
        </div>
    </div>
    );
}
export default DrGyanendra;