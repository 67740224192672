import React from 'react';
import './carousel.css';
import {  Link } from "react-router-dom";
import NavbarMenu from './NavbarMenu.js';
function EdPhd() {
    return (<div> <NavbarMenu />
        <div class="manual">
        <div class="row container">
        <p class="mypathhead">
        <Link to="/"><span class="mypath">Home</span></Link>&gt;<span class="mypath">Education & Training</span>&gt;<span class="mypath">PhD</span>
        </p>
          
        </div>
        <h1 class="mypathhead">PhD</h1>
        <div class="scientistdetail">
          <div class="row">
            
            <div class="col-md-4">
              <center>
                <img src="../assests/images/gnrImg/phd.pnG" alt="Phd Details"  height="200" />
              </center>
            </div>
            <div class="col-md-7">
            <ul>
            <p class="myfontCardbody" style={{fontSize:"17px"}}>The Institute’s scientists are recognized for Ph.D. supervision by the various universities. The university conducts entrance examination and course work during  their Ph.D. </p>
            <br></br>
            <p class="myfontCardbody" style={{fontSize:"17px"}}>The Ph. D. topic is finalized by the scholar and guide followed by its approval in the Research and Development Committee (RDC) of the respective university. The work carried out during the Ph. D. is approved by the university through the RDC.</p>

              </ul>
            </div>
          </div>
          <br/><br/>
          <div class="panel panel-default scientistdetail">
              <div class="panel-body">
                <p style={{color:"Green",fontWeight:"bold",fontSize:"20px"}}>Application and Proposal are inviting for Ph D. positions for Year 2023. </p>
                <a href="../assests/pdf/phd/NIOH PhD.pdf" target="_blank"><button type="button" class="btn btn-primary"style={{fontSize:"17px",borderRadius:"7px"}}>Detailed Notification</button></a>
              </div>
          </div><br/><br/>
          <div class="panel panel-default scientistdetail">
              <div class="panel-body">
                <p style={{color:"Green",fontWeight:"bold",fontSize:"20px"}}>Anti Ragging PDF. For Detailed Noitification click on below button</p>
                <a href="../assests/images/gnrImg/Anti Ragging Poster.pdf" target="_blank"><button type="button" class="btn btn-primary"style={{fontSize:"17px"}}>Detailed Notification</button></a>
              </div>
          </div><br/><br/>
          <div class="panel panel-default scientistdetail">
              <div class="panel-body">
          <p style={{color:"Green",fontWeight:"bold",fontSize:"20px"}}><center>Ph. D. Guide at NIOH Ahmedabad</center></p>
          <div class="table-responsive-sm">
            
              <table width="90%"style={{fontSize:"14px"}} class="table table-bordered mypathhead">
                <tr>
                  <th height="43" style={{color:"white", backgroundColor: "#005A9C"}}>Name</th>
                  <th style={{color:"white", backgroundColor: "#005A9C"}}>Subject</th>
                  <th style={{color:"white", backgroundColor: "#005A9C"}}>University</th>
                </tr>
                <tr>
                  <td>Dr. Lokesh Sharma</td>
                  <td >Computer Science, Forensic SCience</td>
                  <td>Gujarat University, Ahmedabad</td>
                </tr>
                <tr>
                  <td rowspan="2"><Link to='../DrSiva'>Dr P Sivaperumal</Link></td>
                  <td>Chemistry, Forensic Science</td>
                  <td>Gujarat University, Ahmedabad</td>
                </tr>
                <tr>
                  <td>Chemistry</td>
                  <td>Sardar Patel University, Vallabh Vidyanagar</td>
                </tr>
                <tr>
                  <td rowspan="2" ><Link to='../DrGyanendra'>Dr G Singh</Link></td>
                  <td >Life Science, Forensic Science, Toxicology</td>
                  <td >Gujarat University, Ahmedabad</td>
                </tr>
                <tr>
                  <td >Toxicology</td>
                  <td >Raksha Shakti University, Ahmedabd</td>
                </tr>
                <tr>
                  <td><Link to='../DrNibedita'>Dr Nibedita Naha</Link></td>
                  <td>Life Science, Biochemistry, Toxicology</td>
                  <td>Gujarat University, Ahmedabad</td>
                </tr>
                <tr>
                  <td ><Link to='../DrSukhdev'>Dr S D Mishra</Link></td>
                    <td >Statistics</td>
                      
                      <td >Gujarat University, Ahmedabad</td>
                </tr>
                
              </table>
          
        
          </div>
          </div></div>
          <br/><br/>
          <div class="panel panel-default scientistdetail">
              <div class="panel-body">
          <p style={{color:"Green",fontWeight:"bold",fontSize:"20px"}}><center>Ph. D. Guide at ROHCS Bengaluru</center></p>
          <div class="table-responsive-sm">
            
              <table width="90%"style={{fontSize:"14px"}} class="table table-bordered mypathhead">
                <tr>
                  <th height="43" style={{color:"white", backgroundColor: "#005A9C"}}>Name</th>
                  <th style={{color:"white", backgroundColor: "#005A9C"}}>Subject</th>
                  <th style={{color:"white", backgroundColor: "#005A9C"}}>University</th>
                </tr>
                <tr>
                  <td><Link to='../DrRavi'>Dr. B Ravichandran</Link></td>
                  <td >Environmetal Science, Biotechnology</td>
                  <td>Kuvempu University, Shivamogga</td>
                </tr>
                
              </table>
          
        
          </div>
          </div></div>

        </div>
       
        </div>

        </div>
    
    );
}
export default EdPhd;