import React from 'react';
import './carousel.css';
import NavbarMenu from './NavbarMenu.js';
import {  Link } from "react-router-dom";
function Workshop() {
    return (<div> <NavbarMenu />
        <div class="manual">
        <div class="row container">
        <p class="mypathhead">
        <Link to="/"><span class="mypath">Home</span></Link>&gt;<span class="mypath">Dissemination</span>&gt;<span class="mypath">Worskshop/Seminars/Conference</span>
        </p>
          
        </div>
        <h1 class="mypathhead">Worskshop/Seminar/Conference</h1>

        <div class="container">
        <div class="panel panel-default scientistdetail">

<div class="panel-body">
<p style={{color:"Green",fontWeight:"bold",fontSize:"20px"}}> Workshop on Systematic Review and Meta- Analysis at ROHC(S), Bengaluru 21-23 Aug 2024</p><br/>
<p><a href="../assests/workshop/ICMR_ROHC_Workshop_SRMA_AUG_21_23.pdf" target="_blank"><button type="button" class="btn btn-primary"style={{fontSize:"17px",borderRadius:"7px",marginRight:"20px"}}>Announcement Brochure</button></a> </p>
<p><a href="https://forms.gle/tuD7966MFbm4gwuH8" target="_blank"><button type="button" class="btn btn-primary"style={{fontSize:"17px",borderRadius:"7px",marginRight:"20px"}}>Registration Link</button></a>     

</p>
</div>
</div>

        <div class="panel panel-default scientistdetail">

<div class="panel-body">
<p style={{color:"Green",fontWeight:"bold",fontSize:"20px"}}> National Workshop on Approaches and Methods to Measure Oxidative Stress in Pre-Clinical Mouse Model, 5-9 Aug 2024, Last date for registration 25/07/2024</p><br/>
<p><a href="../assests/workshop/National_Workshop_MouseModel_2024.pdf" target="_blank"><button type="button" class="btn btn-primary"style={{fontSize:"17px",borderRadius:"7px",marginRight:"20px"}}>Announcement Brochure</button></a> </p>
<p><a href="https://forms.gle/DaMYzXhSXAmjVsqG7" target="_blank"><button type="button" class="btn btn-primary"style={{fontSize:"17px",borderRadius:"7px",marginRight:"20px"}}>Registration Link</button></a>     

</p>
</div>
</div>
        <div class="panel panel-default scientistdetail">
          <div class="panel-body">
                <p style={{color:"Green",fontWeight:"bold",fontSize:"20px"}}> Webinar on Empowering Research: Navigating the Path to Reproducible Science, 08 March 2024, 02:00 PM</p><br/>
                <p><a href="../assests/workshop/Webinar_IWD2024.pdf" target="_blank"><button type="button" class="btn btn-primary"style={{fontSize:"17px",borderRadius:"7px",marginRight:"20px"}}>Announcement Brochure</button></a> </p>
                <p><a href="https://us02web.zoom.us/j/86350499867" target="_blank"><button type="button" class="btn btn-primary"style={{fontSize:"17px",borderRadius:"7px",marginRight:"20px"}}>Click to Join the lecture</button></a>     
                
                </p>
              </div>
              </div>
        <div class="panel panel-default scientistdetail">

      <div class="panel-body">
      <p style={{color:"Green",fontWeight:"bold",fontSize:"20px"}}> Practical Guide to Systematic Reviews and Meta-Analysis: A Comprehensive 3-day National Workshop, 19-21 June 2024 at ICMR-NIOH Ahmedabad</p><br/>
      <p><a href="../assests/workshop/ICMR_NIOH_SRMA_Workshop.pdf" target="_blank"><button type="button" class="btn btn-primary"style={{fontSize:"17px",borderRadius:"7px",marginRight:"20px"}}>Announcement Brochure</button></a> </p>
      <p><a href="https://forms.gle/L3Svw4U2V5TZb6mDA" target="_blank"><button type="button" class="btn btn-primary"style={{fontSize:"17px",borderRadius:"7px",marginRight:"20px"}}>Registration Link</button></a>     
      
      </p>
    </div>
    </div>
          <div class="panel panel-default scientistdetail">

          <div class="panel-body">
                <p style={{color:"Green",fontWeight:"bold",fontSize:"20px"}}> National Seminar on Bayesian Analysis of Survival Data, Friday, 0 5 April 2024 at ICMR-NIOH Ahmedabad</p><br/>
                <p><a href="../assests/workshop/ICMRNIOH_Seminar_Flyer _Bayesian_v1.pdf" target="_blank"><button type="button" class="btn btn-primary"style={{fontSize:"17px",borderRadius:"7px",marginRight:"20px"}}>Announcement Brochure</button></a> </p>
                <p><a href="https://t.ly/acr7p" target="_blank"><button type="button" class="btn btn-primary"style={{fontSize:"17px",borderRadius:"7px",marginRight:"20px"}}>Registration Link</button></a>     
                
                </p>
              </div>
              </div>
              <div class="panel panel-default scientistdetail">
          <div class="panel-body">
                <p style={{color:"Green",fontWeight:"bold",fontSize:"20px"}}> Scientific lecture titled “Using the Risk Assessment Information System (RAIS) for Occupational Risk Assessment” by Mr Fred Dolislager, Senior Environmental Risk Analyst, Oak Ridge National Laboratory, USA, 20th Feb 2024, 09:00AM at ICMR-NIOH Ahmedabad</p><br/>
                <p><a href="../assests/workshop/ICMR_NIOH_Scientific_Lecture_Flyer.pdf" target="_blank"><button type="button" class="btn btn-primary"style={{fontSize:"17px",borderRadius:"7px",marginRight:"20px"}}>Announcement Brochure</button></a> </p>
                <p><a href="https://us02web.zoom.us/j/89632451674" target="_blank"><button type="button" class="btn btn-primary"style={{fontSize:"17px",borderRadius:"7px",marginRight:"20px"}}>Click to Join the lecture</button></a>     
                
                </p>
              </div>
              </div>
              
              <div class="panel panel-default scientistdetail">
          <div class="panel-body">
                <p style={{color:"Green",fontWeight:"bold",fontSize:"20px"}}>Webinar on Wetland Restoration for Sustainablity and Global Well-being, 2nd Feb 2024, 11:00AM at ICMR-NIOH Ahmedabad</p><br/>
                <p><a href="../assests/workshop/Wetland_Lecture.pdf" target="_blank"><button type="button" class="btn btn-primary"style={{fontSize:"17px",borderRadius:"7px",marginRight:"20px"}}>Announcement Brochure</button></a>
                </p>
              </div>

          <div class="panel-body">
                <p style={{color:"Green",fontWeight:"bold",fontSize:"20px"}}>Workshop on Ergonomics And Allied Subjects, 29-30 January 2024 at ROHC Bangalore</p><br/>
                <p><a href="../assests/workshop/Webinar_Ergonomics_29_30_Jan.pdf" target="_blank"><button type="button" class="btn btn-primary"style={{fontSize:"17px",borderRadius:"7px",marginRight:"20px"}}>Announcement Brochure</button></a>
                
                
                
                </p>
              </div>
              <div class="panel-body">
                <p style={{color:"Green",fontWeight:"bold",fontSize:"20px"}}>Stakeholders’ meet cum workshop on Occupational Health and Safety among Construction Workers, 24th January 2024, at ICMR-NIOH Ahmedabad</p><br/>
                <p><a href="../assests/workshop/Tentative_Workshop_Agenda.pdf" target="_blank"><button type="button" class="btn btn-primary"style={{fontSize:"17px",borderRadius:"7px",marginRight:"20px"}}>Tentative Agenda</button></a>
                
                
                
                </p>
              </div>
          </div><br/> <br/><br/>
          
          </div>

        

        </div>
       <br/><br/><br/> <br/><br/><br/> 
        </div>

       
    
    );
}
export default Workshop;