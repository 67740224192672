import React from 'react';
import './carousel.css';
import {  Link } from "react-router-dom";
import NavbarMenu from './NavbarMenu.js';
function Circular () {
    return (<div> <NavbarMenu /><div class="manual">
       
        <div class="row container">
        <p class="mypathhead">
        <Link to="/"><span class="mypath">Home</span></Link>&gt;<span class="mypath">Notification</span>&gt;<span class="mypath">Office Circulars</span>
        </p>
          
        </div>
        <h1 class="mypathhead">Office Circulars</h1>
       
        <div class="table-responsive-sm"  style={{padding:"10px"}}>
            <center>
                <table width="90%"style={{fontSize:"18px"}} class="table table-bordered">
                <tr>
                    <th width="70%" style={{color:"white", backgroundColor: "#005A9C"}}><strong>Subject of Cicular/Office Memorandum/Proforma</strong></th>
                    <th width="10%" style={{color:"white", backgroundColor: "#005A9C"}}><strong>Date </strong></th>
                    <th width="10%" style={{color:"white", backgroundColor: "#005A9C"}}><strong>Download</strong></th>
                    
                 </tr>
                 <tr>
                <td align="justify" >Regarding Azadi ka Amrit Mahotsav Har Ghar Tiranga Campaign</td>
                <td >13/08/2024</td>
                <td align="center" ><a href="../assests/pdf/circular/HarGharTiranga_2024.pdf" target="_blank">Download</a></td>
              </tr>

                 <tr>
                <td align="justify" >Charges for laboratory services and utilization of institute infrastructure</td>
                <td >03/07/2024</td>
                <td align="center" ><a href="../assests/pdf/circular/Circular_03072024.pdf" target="_blank">Download</a></td>
              </tr>
                 <tr>
                <td align="justify" >PURCHASE INDENT FORM</td>
                <td >17/05/2024</td>
                <td align="center" ><a href="../assests/pdf/circular/Procurement_form.docx" target="_blank">Download</a></td>
              </tr>
                 <tr>
                <td align="justify" >Proforma For Re-Imbursement Of Children Education Allowance Claim For the Academic Year: 2023-24</td>
                <td >02/04/2024</td>
                <td align="center" ><a href="../assests/pdf/circular/CEA_Form_hi.pdf" target="_blank"><img src="../assests/images/gnrImg/pdf.png" alt="Holidays 2023" width="44" height="36" title="CEA"/>Hindi</a><a href="../assests/pdf/circular/CEA_Form_en.docx" target="_blank"><img src="../assests/images/gnrImg/pdf.png" alt="Holidays 2023" width="44" height="36" title="CEA"/></a>English</td>
              </tr>
                 <tr>
                <td align="justify" > Internal Complaint Committee (ICC) in terms of Section 4(2) under the SHWW</td>
                <td >18/03/2024</td>
                <td align="center"><p><a href="../assests/pdf/circular/ICC_3174_180324.pdf" target="_blank"><img src="../assests/images/gnrImg/pdf.png" alt="Holidays 2023" width="30" height="36" title="CEA"/>Details</a></p> </td>
                 </tr>
                 <tr>
                <td align="justify" > DHR Policy on Research Publication, 2024</td>
                <td >08/02/2024</td>
                <td align="center"><p><a href="../assests/pdf/circular/DHR_Publication_Policy_2024.pdf" target="_blank"><img src="../assests/images/gnrImg/pdf.png" alt="Holidays 2023" width="30" height="36" title="CEA"/>Details</a></p> </td>
                 </tr>
                 <tr>
                <td align="justify" > MoU/MoA of Smt SMS Multispeciality Hospital, Ahmedabad for treatment</td>
                <td >30/01/2024</td>
                <td align="center"><p><a href="../assests/pdf/circular/SMS_Multispeciality_Hospital_300124.pdf" target="_blank"><img src="../assests/images/gnrImg/pdf.png" alt="Holidays 2023" width="30" height="36" title="CEA"/>Details</a></p> </td>
                 </tr>
                 <tr>
                <td align="justify" > MoU/MoA of Parekhs Hospital, Ahmedabad for treatment</td>
                <td >11/01/2024</td>
                <td align="center"><p><a href="../assests/pdf/circular/Parekh_Hospital_11012024.pdf" target="_blank"><img src="../assests/images/gnrImg/pdf.png" alt="Holidays 2023" width="30" height="36" title="CEA"/>Details</a></p> </td>
                 </tr>

                 <tr>
                <td align="justify" > Approval of In Vitro Speciality Lab Pvt Ltd, Navrangpura & Sterling Accuris Diagnostic, Ellisbridge, Ahmedabad for Laboratory/Diagnostic Services </td>
                <td >01/01/2024</td>
                <td align="center"><p><a href="../assests/pdf/circular/Invitro_Lab_Sterling_Accuris_010124.pdf" target="_blank"><img src="../assests/images/gnrImg/pdf.png" alt="Holidays 2023" width="30" height="36" title="CEA"/>Details</a></p> </td>
                 </tr>
                 <tr>
                <td align="justify" > Approval of Aartham Hospital, Ambawadi, Ahmedabad for treatment </td>
                <td >14/12/2023</td>
                <td align="center"><p><a href="../assests/pdf/circular/Aartham_Hospital.pdf" target="_blank"><img src="../assests/images/gnrImg/pdf.png" alt="Holidays 2023" width="30" height="36" title="CEA"/>Details</a></p>
                
                </td>
                
              </tr>
                 <tr>
                <td align="justify" >List of holidays for year 2024</td>
                <td >20/12/2023</td>
                <td align="center" ><a href="../assests/pdf/circular/hindi holiday list 2024.pdf" target="_blank"><img src="../assests/images/gnrImg/pdf.png" alt="Holidays 2023" width="44" height="36" title="CEA"/>Hindi</a><a href="../assests/pdf/circular/holiday list 2024.pdf" target="_blank"><img src="../assests/images/gnrImg/pdf.png" alt="Holidays 2023" width="44" height="36" title="CEA"/>English</a></td>
              </tr>
                 <tr>
                <td align="justify" > Submission of Declaration Form for the purpose of Income tax computation for financial year 2023_24, rcg</td>
                <td >23/11/2023</td>
                <td align="center"><p><a href="../assests/pdf/circular/Declaration_Form_F_Y_2023-24_0001.pdf" target="_blank"><img src="../assests/images/gnrImg/pdf.png" alt="Holidays 2023" width="30" height="36" title="CEA"/>Details</a></p>
                
                </td>
                
              </tr>
                 <tr>
                <td align="justify" > Office Memorandum </td>
                <td >05/10/2023</td>
                <td align="center"><p><a href="../assests/pdf/circular/Final_MRC_order.pdf" target="_blank"><img src="../assests/images/gnrImg/pdf.png" alt="Holidays 2023" width="30" height="36" title="CEA"/>Details</a></p>
                <p><a href="../assests/pdf/circular/AMA_List.pdf" target="_blank"><img src="../assests/images/gnrImg/pdf.png" alt="Holidays 2023" width="30" height="36" title="CEA"/>Details</a></p>
                </td>
                
              </tr>
              <tr>
          
                <td align="justify" >Cancer Treatment under CGHS/CS(MA) Rules. 1944.</td>
                <td >05/10/2023</td>
                <td align="center"><p><a href="../assests/pdf/circular/Cancer_Treatment_under_CGHS_-_CSMA_Rules_1944.pdf" target="_blank"><img src="../assests/images/gnrImg/pdf.png" alt="Holidays 2023" width="30" height="36" title="CEA"/>Details</a></p>
                
                </td>
                
              </tr>
              
                 <tr>
                <td align="justify" > Proforma for Travelling Allowance Bill For Tour And Certificate For T.A. ON Tour </td>
                <td >26/09/2023</td>
                <td align="center"><p><a href="../assests/pdf/circular/TA_Form 2023.pdf" target="_blank"><img src="../assests/images/gnrImg/pdf.png" alt="Holidays 2023" width="30" height="36" title="CEA"/>Details</a></p>
                <p><a href="../assests/pdf/circular/TA-Certificate-2023.pdf" target="_blank"><img src="../assests/images/gnrImg/pdf.png" alt="Holidays 2023" width="30" height="36" title="CEA"/>Details</a></p>
                </td>
                
              </tr>
              <tr>
          
                <td align="justify" >Circular for Jaydeep Hospital Narayanpura, Ahmedabad & Krishna Shalby Hospital, Bopal,Ahmedabad</td>
                <td >21/09/2023</td>
                <td align="center"><p><a href="../assests/pdf/circular/Circular_22092023.pdf" target="_blank"><img src="../assests/images/gnrImg/pdf.png" alt="Holidays 2023" width="30" height="36" title="CEA"/>Details</a></p>
                
                </td>
                
              </tr>
                 <tr>
                <td align="justify" > Proforma for Indent for hiring of outside vehicles under rate conteract </td>
                <td >25/08/2023</td>
                <td align="center" ><p><a href="../assests/pdf/circular/Vehicle_ Indent_Form.pdf" target="_blank"><img src="../assests/images/gnrImg/pdf.png" alt="Holidays 2023" width="30" height="36" title="CEA"/>English</a></p>
                
                </td>
              </tr>
                <tr>
                <td align="justify" >Circular for narayana Multispecilatity hospital</td>
                <td >06/07/2023</td>
                <td align="center"><p><a href="../assests/pdf/circular/Circular06072023.pdf" target="_blank"><img src="../assests/images/gnrImg/pdf.png" alt="Holidays 2023" width="30" height="36" title="CEA"/>English</a></p>
                
                
                </td>
              </tr>
                <tr>
                <td align="justify" >Clarification regarding deduction of TDS under section 192 read with subsection (IA) of section I I5BAC of the Income-tax Act, l96l</td>
                <td >29/05/2023</td>
                <td align="center" ><p><a href="../assests/pdf/circular/Circular_TDS_29052023.pdf" target="_blank"><img src="../assests/images/gnrImg/pdf.png" alt="Holidays 2023" width="30" height="36" title="CEA"/>English</a></p>
                
                </td>
              </tr>
                <tr>
                <td align="justify" >Office Order Of Display of Identity Card while on Duty</td>
                <td >01/05/2023</td>
                <td align="center" ><p><a href="../assests/pdf/circular/scan0001.pdf" target="_blank"><img src="../assests/images/gnrImg/pdf.png" alt="Holidays 2023" width="30" height="36" title="CEA"/>English</a></p>
                <p><a href="../assests/pdf/circular/scan0002.pdf" target="_blank"><img src="../assests/images/gnrImg/pdf.png" alt="Holidays 2023" width="30" height="36" title="CEA"/>Hindi</a></p>
                </td>
              </tr>
                <tr>
                <td align="justify" >Office Order Of Committee/Cell/squad for Anti-Ragging matters</td>
                <td >28/03/2023</td>
                <td align="center" ><a href="../assests/pdf/circular/OO_Anti Ragging.pdf" target="_blank"><img src="../assests/images/gnrImg/pdf.png" alt="Holidays 2023" width="44" height="36" title="CEA"/></a></td>
              </tr>
                
                <tr>
                <td align="justify" >Circular for MoU with CIMS Hospital Private LTD</td>
                <td >28/12/2022</td>
                <td align="center" ><a href="../assests/pdf/circular/CIIMS Hospital.pdf" target="_blank"><img src="../assests/images/gnrImg/pdf.png" alt="Holidays 2023" width="44" height="36" title="CEA"/></a></td>
              </tr>

                <tr>
                <td align="justify" >List of holidays for year 2023</td>
                <td >13/12/2022</td>
                <td align="center" ><a href="../assests/pdf/circular/Holiday_2023_h.pdf" target="_blank"><img src="../assests/images/gnrImg/pdf.png" alt="Holidays 2023" width="44" height="36" title="CEA"/>Hindi</a><a href="../assests/pdf/circular/List of Holidays_2023.pdf" target="_blank"><img src="../assests/images/gnrImg/pdf.png" alt="Holidays 2023" width="44" height="36" title="CEA"/>English</a></td>
              </tr>
              <tr>
                <td align="justify" >Circular For Adding 2 New Hospital For Pensioners</td>
                <td >30/11/2022</td>
                <td align="center" ><a href="../assests/pdf/circular/2NewHospital.pdf" target="_blank"><img src="../assests/images/gnrImg/pdf.png" alt="Circular for Hospital" width="44" height="36" title="CEA"/></a></td>
              </tr>
               
                <tr>
     
     <td align="justify" >Circular For Bills from Narayana Multispecilatity</td>
     <td >07/11/2022</td>
     <td align="center" ><a href="../assests/pdf/circular/12.pdf" target="_blank"><img src="../assests/images/gnrImg/pdf.png" alt="Circular for Hospital" width="44" height="36" title="CEA"/></a></td>
   </tr>
                <tr>
     
     <td align="justify" >Celebrate Vigilanace Awareness Week</td>
     <td >04/11/2022</td>
     <td align="center" ><a href="../assests/pdf/circular/Vigillance awarness week 2022 circular.pdf" target="_blank"><img src="../assests/images/gnrImg/pdf.png" alt="Circular for Hospital" width="44" height="36" title="CEA"/></a></td>
   </tr>
                <tr>
     
     <td align="justify" >3 New Hospital for Pensioners under CashLess Scheme</td>
     <td >01/11/2022</td>
     <td align="center" ><a href="../assests/pdf/circular/Pensionars_Hospital circuulafr.pdf" target="_blank"><img src="../assests/images/gnrImg/pdf.png" alt="Circular for Hospital" width="44" height="36" title="CEA"/></a></td>
   </tr>
                <tr>
     
     <td align="justify" >Income Tax Slab F. Y. 2022-2023</td>
     <td >07/10/2022</td>
     <td align="center" ><a href="../assests/pdf/circular/IncomeTaxSlab.pdf" target="_blank"><img src="../assests/images/gnrImg/pdf.png" alt="Circular for Mandate Forms" width="44" height="36" title="CEA"/></a></td>
   </tr>
          
                <tr>
     
     <td align="justify" >Circular for MoAs with 11 hospital under Cashless Scheme for Pensioner</td>
     <td >28/09/2022</td>
     <td align="center" ><a href="../assests/pdf/circular/CashlessScheme.pdf" target="_blank"><img src="../assests/images/gnrImg/pdf.png" alt="Circular for Mandate Forms" width="44" height="36" title="CEA"/></a></td>
   </tr>
                <tr>
     
     <td align="justify" >Submission of requests for Mandate Forms under extramural funded projects - reg.</td>
     <td >30/08/2022</td>
     <td align="center" ><a href="../assests/pdf/circular/mandate_form.pdf" target="_blank"><img src="../assests/images/gnrImg/pdf.png" alt="Circular for Mandate Forms" width="44" height="36" title="CEA"/></a></td>
   </tr>
                <tr>
     
     <td align="justify" >Circular For Reimbursement in respect of Newspapers purchased/supplied to officers</td>
     <td >18/08/2022</td>
     <td align="center" ><a href="../assests/pdf/circular/Newspaper.pdf" target="_blank"><img src="../assests/images/gnrImg/pdf.png" alt="Circular for pensioner ID card" width="44" height="36" title="CEA"/></a></td>
   </tr>
                <tr>
     
     <td align="justify" >Circular For Issuance of Medical Card In Respect Of Pensioner Of ICMR-NIOH</td>
     <td >06/06/2022</td>
     <td align="center" ><a href="../assests/pdf/circular/Medical Card.pdf" target="_blank"><img src="../assests/images/gnrImg/pdf.png" alt="Circular for pensioner ID card" width="44" height="36" title="CEA"/></a></td>
   </tr>
  
  <tr>
     
     <td align="justify" >Circular For Claiming Earned Leave Encashment while availing LTC/Hometown ICMR-NIOH</td>
     <td >03/06/2022</td>
     <td align="center" ><a href="../assests/pdf/circular/OO_Proforma_EL encashment.pdf" target="_blank"><img src="../assests/images/gnrImg/pdf.png" alt="Circular for pensioner ID card" widtd="44" height="36" title="CEA"/></a></td>
   </tr>
  <tr>
     
     <td align="justify" >Circular For Pensioner ID Card</td>
     <td >19/05/2022</td>
     <td align="center" ><a href="../assests/pdf/circular/Circular for pensioner ID card.pdf" target="_blank"><img src="../assests/images/gnrImg/pdf.png" alt="Circular for pensioner ID card" widtd="44" height="36" title="CEA"/></a></td>
   </tr>
  <tr>
     
     <td align="justify" >Circular For Medical Bill Query</td>
     <td >19/05/2022</td>
     <td align="center" ><a href="../assests/pdf/circular/Circular for medical bill queries.pdf" target="_blank"><img src="../assests/images/gnrImg/pdf.png" alt="Circular for medical bill queries" widtd="44" height="36" title="CEA"/></a></td>
   </tr>
   <tr>
     
     <td align="justify" >Circular For BriefCase/Official Bag/Ladies Purses</td>
     <td >12/05/2022</td>
     <td align="center" ><a href="../assests/pdf/circular/Briefcase OM.pdf" target="_blank"><img src="../assests/images/gnrImg/pdf.png" alt="Circular for BriefCase" widtd="44" height="36" title="CEA"/></a></td>
   </tr>
  <tr>
     
     <td align="justify" >Hospitals MoU for treatments</td>
     <td >01/04/2022</td>
     <td align="center" ><a href="../assests/pdf/circular/Hospital circular.pdf" target="_blank"><img src="../assests/images/gnrImg/pdf.png" alt="CEA_Claim_Format_2021-22" widtd="44" height="36" title="CEA"/></a></td>
   </tr>
  <tr>
    
     <td align="justify" >Proforma For Re-Imbursement Of Children Education Allowance Claim For the Academic Year: 2021-22</td>
     <td >01/04/2022</td>
     <td align="center" ><a href="../assests/pdf/circular/CEA_Claim_Format_2021-22" target="_blank"><img src="../assests/images/gnrImg/pdf.png" alt="CEA_Claim_Format_2021-22" widtd="44" height="36" title="CEA"/></a></td>
   </tr>
   <tr>
     
     <td align="justify" >List of Hospitals and diagnostic centers for tde consultation/treatment investigations as per tde CGHS rate</td>
     <td >10/01/2022</td>
     <td align="center" ><a href="../assests/pdf/circular/CGHS_Hospitals_NIOH_Circular_10012022.pdf" target="_blank"><img src="../assests/images/gnrImg/pdf.png" alt="List of Hospital" widtd="37" height="49" title="List of holiday 2022"/></a></td>
   </tr>
   <tr>
    
     <td align="justify" >List of holidays for year 2022</td>
     <td >08/12/2021</td>
     <td align="center" ><a href="../assests/pdf/circular/NIOH_2022_holidays.pdf" target="_blank"><img src="../assests/images/gnrImg/pdf.png" alt="Holiday 2022" widtd="37" height="49" title="List of holiday 2022"/></a></td>
   </tr>
   <tr>
    
     <td align="justify" >Application for Issue of Pensioner’s Identity Card<br/></td>
     <td >&nbsp;</td>
     <td align="center" ><a href="../assests/pdf/circular/Application_Form_Pensioner_ID.pdf" target="_blank"><img src="../assests/images/gnrImg/pdf.png" alt="Medical and fitness Certificate" widtd="37" height="49" title="Download Medical and fitness Certificate"/></a></td>
   </tr>
   <tr>
   
     <td align="justify" >Proforma for tde Medical and Fitness Certificate</td>
     <td >-</td>
     <td align="center" ><a href="../assests/pdf/circular/003_Medical_Fitness_Certificate.pdf" target="_blank"><img src="../assests/images/gnrImg/pdf.png" alt="Medical and fitness Certificate" widtd="37" height="49" title="Download Medical and fitness Certificate"/></a></td>
   </tr>
   <tr>
    
     <td align="justify" >Proforma for taking over charge and handing over charge report</td>
     <td >-</td>
     <td align="center" ><a href="../assests/pdf/circular/004_Handing_Taking_Charge.pdf" target="_blank"><img src="../assests/images/gnrImg/pdf.png" alt="Taking over and handing over charge report" widtd="37" height="49" title="Download Taking over and handing over charge report"/></a></td>
   </tr>
   <tr>
    
     <td align="justify" class="action-button" >Format for the Life Certificate to be submitted by the pensioner</td>
    <td ><strong>-</strong></td>
    <td align="center" ><a href="../assests/pdf/circular/001_PENSIONERS_LIFE_CERTIFICATE.pdf" target="_blank"><img src="../assests/images/gnrImg/pdf.png" alt="Life Certificate" widtd="37" height="49" title="Download Life Certificate"/></a></td>
  </tr>
  <tr>
   
    <td align="justify" class="action-button" >Circular and Declaration form for tde purpose of Income Tax for FY 2020-21 (AY 2021-22) and Tax Rates</td>
    <td ><strong>15/10/2020</strong></td>
    <td align="center" ><a href="../assests/pdf/circular/NIOH_IT_2020_21.docx" target="_blank"><img src="../assests/images/gnrImg/pdf.png" alt="IT Declaration" widtd="44" height="36" title="Download IT Declaration"/></a></td>
  </tr>
  <tr>
   
    <td align="justify" >Children Education Allowance claim format 2020</td>
    <td >13/05/2020</td>
    <td align="center" ><a href="../assests/pdf/circular/CEA_Claim_Format_2020.docx" target="_blank"><img src="../assests/images/gnrImg/pdf.png" alt="Life Certificate" widtd="44" height="36" title="Download CEA Claim Form"/></a></td>
  </tr>
  <tr>
   
    <td align="justify" >Circular and Declaration form for tde purpose of Income Tax for FY 2019-20 (AY 2020-21) and Tax Rates for Pensioners</td>
    <td >15/11/2019</td>
    <td align="center" ><a href="../assests/pdf/circular/08_Pensioners_Income_tax_notification.pdf" target="_blank"><img src="../assests/images/gnrImg/pdf.png" alt="Life Certificate" widtd="37" height="49" title="Download Life Certificate"/></a></td>
  </tr>
  
  <tr>
   
    <td align="justify" >Local Purchase Committee</td>
    <td >01/04/2020</td>
    <td align="center" ><a href="../assests/pdf/circular/09_LPC.pdf" target="_blank"><img src="../assests/images/gnrImg/pdf.png" alt="LPC" widtd="37" height="49" title="Download Life Certificate"/></a></td>
  </tr>
  <tr>
   
    <td align="justify" >Furnishing tde pro-forma for restoration of commuted portion of pension after 15 years -<br/>
      implementation of tde judgement of tde Supreme court.</td>
    <td >28/07/2020</td>
    <td align="center" ><a href="../assests/pdf/circular/08_Restoration_of_Pension.pdf" target="_blank"><img src="../assests/images/gnrImg/pdf.png" alt="LPC" widtd="37" height="49" title="Download Life Certificate"/></a></td>
  </tr>
  <tr>
 
    <td align="justify" >Circular for Appointment on Compassionate Ground</td>
    <td >13/01/2021</td>
    <td align="center" ><a href="../assests/pdf/circular/NIOH_Compassionate_Appointment_Circular_13Jan2021.pdf" target="_blank"><img src="../assests/images/gnrImg/pdf.png" alt="LPC" widtd="37" height="49"/></a></td>
  </tr>
  <tr>
   
    <td align="justify" >Special cash package equivalent in lieu of Leave Travel Concession Fare for Central Government Employee during block 2018-2021</td>
    <td >19/01/2021</td>
    <td align="center" ><a href="../assests/pdf/circular/LTC_NIOH.pdf" target="_blank"><img src="../assests/images/gnrImg/pdf.png" alt="LTC" widtd="37" height="49"/></a></td>
  </tr>
  <tr>
   
    <td align="justify" >SWACHHTA PLEDGE</td>
    <td >&nbsp;</td>
    <td align="center" ><a href="../assests/pdf/circular/swachhta_pledge.pdf" target="_blank"><img src="../assests/images/gnrImg/pdf.png" alt="SWACHHTA PLEDGE" widtd="37" height="49" title="SWACHHTA PLEDGE"/></a></td>
  </tr>
  <tr>
   
    <td align="justify" >Purchase Indent Form (Consumble/Non-Consumable)</td>
    <td >&nbsp;</td>
    <td align="center" ><a href="../assests/pdf/circular/NIOH_Indent_Form.pdf" target="_blank"><img src="../assests/images/gnrImg/pdf.png" alt="SWACHHTA PLEDGE" widtd="37" height="49" title="Purchase Indent Form (Consumble/Non-Consumable)"/></a></td>
  </tr>
  <tr>
   
    <td align="justify" >Office order regarding medical reimbursement claim</td>
    <td >30/11/2021</td>
    <td align="center" ><a href="../assets/pdf/circular/Medical_reimbursement_Notice.pdf" target="_blank"><img src="../assests/images/gnrImg/pdf.png" alt="medical reimbursement claim" widtd="37" height="49" title="medical reimbursement claim"/></a></td>
  </tr>
 
                </table>
            </center>
        
      
        </div>
        
        </div>
      
        </div>);
}
export default Circular;