import React from 'react';
import './carousel.css';
import {  Link } from "react-router-dom";
import NavbarMenu from './NavbarMenu.js';
function ScientistROHCS () {

  function myFunction(event){
        var elems = document.querySelectorAll(".active");
        
        [].forEach.call(elems, function(el) {
          
          el.classList.remove("active");
        });
        event.target.className = "active";
        
        
}     
    return (<div> <NavbarMenu /><div class="manual" id="accordion" >
      
      <div class="row container">
        <p class="mypathhead">
          <Link to="/"><span class="mypath">Home</span></Link>&gt;<span class="mypath">Scientist's Profile</span>&gt;<span class="mypath">ROHCS Scientists</span>
        </p>
        </div>
          <h1 class="mypathhead">ROHCS Scientists</h1>
        
              
   <div class="row">
    <div class="col-md-3">
    <div class="vertical-menu myverti" onClick={(e)=>myFunction(e)}>
       <a class="active" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne" id="headingOne">
            Scientist-E
        </a>    
        <a class="collapsed" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo" id="headingTwo">
        Scientist-D
        </a>
        <a class="collapsed" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree" id="headingThree">
        Scientist-C
        </a>
        <a class="collapsed" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour" id="headingFour">
        Scientist-B
        </a>
        
        
                       
      </div>
      
    </div>  
    
    <div class="col-md-6 ">
      <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
        <div class="card-body">
           <div class="row myvertic">
              <div class="col-md-4">
                 <img src="../assests/images/rohcsScientist/bravichandran.jpg"   alt="" width="170px" height="170px" class="dg-img"/>
               </div>
            <div class="col-md-6">
              <p class="myfontSci trSci">Dr B. Ravichandran</p>
              <p class="myfontSci">Officer In Charge </p>
              <p ><a href=""><i class="fa fa-envelope" aria-hidden="true"></i>   Email: ravichandran.b@gov.in</a></p>
               <p ><a href=""> <i class="fa fa-phone-square"></i>  Contact: 080-22172500</a></p>
            </div>
            <div class="col-md-2">
              <Link to="/DrRavi" class="btn btn-primary mybuttonSci trSci" style={{fontSize:"18px"}}>View Profile</Link>
            </div>
          </div>
           <div class="row myvertic">
            <div class="col-md-4">
              <img src="../assests/images/rohcsScientist/sraghavan.png"   alt="" width="170px" height="170px" class="dg-img"/>
            </div>
            <div class="col-md-6">
            <p class="myfontSci trSci">Dr S Raghavan</p>
            <p ><a href=""><i class="fa fa-envelope" aria-hidden="true"></i>   Email: raghuharihar@gov.in</a></p>
            <p ><a href=""> <i class="fa fa-phone-square"></i>  Contact:080-22172501</a></p>
            </div>
            <div class="col-md-2">
              <Link to="/DrRaghvan" class="btn btn-primary mybuttonSci trSci" style={{fontSize:"18px"}}>View Profile</Link>
            </div>
          </div>
          <div class="row myvertic">
            <div class="col-md-4">
              <img src="../assests/images/rohcsScientist/ShubhangiP.jpg"   alt="" width="170px" height="170px" class="dg-img"/>
            </div>
            <div class="col-md-6">
            <p class="myfontSci trSci">Dr Shubhangi Pingle</p>
            <p ><a href=""><i class="fa fa-envelope" aria-hidden="true"></i>   Email: pingle.shubhangi@gmail.com</a></p>
            <p ><a href=""> <i class="fa fa-phone-square"></i>  Contact:To Be Update</a></p>
            </div>
            <div class="col-md-2">
              <Link to="/DrShubh" class="btn btn-primary mybuttonSci trSci" style={{fontSize:"18px"}}>View Profile</Link>
            </div>
          </div>
         
   
      </div>
    </div>
  
  
    <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
    <div class="row myvertic">
            <div class="col-md-4">
              <img src="../assests/images/rohcsScientist/Debasis.JPG"   alt="" width="170px" height="170px" class="dg-img"/>
            </div>
            <div class="col-md-6">
            <p class="myfontSci trSci">Mr Debasis Chatterjee</p>
            <p ><a href=""><i class="fa fa-envelope" aria-hidden="true"></i>   Email: chatterjee.d@icmr.gov.in</a></p>
            <p ><a href=""> <i class="fa fa-phone-square"></i>  Contact: +91-9477281019</a></p>
            </div>
            <div class="col-md-2">
              <Link to="/DrDebasis" class="btn btn-primary mybuttonSci trSci" style={{fontSize:"18px"}}>View Profile</Link>
            </div>
          </div>
          <div class="row myvertic">
            <div class="col-md-4">
              <img src="../assests/images/rohcsScientist/kravibabu.png"   alt="" width="170px" height="170px" class="dg-img"/>
            </div>
            <div class="col-md-6">
            <p class="myfontSci trSci">Dr Ravi Babu K</p>
            <p ><a href=""><i class="fa fa-envelope" aria-hidden="true"></i>   Email: ravibabu.k@gov.in</a></p>
            <p ><a href=""> <i class="fa fa-phone-square"></i>  Contact:080-22172500</a></p>
            </div>
            <div class="col-md-2">
              <Link to="/DrRaviBabu" class="btn btn-primary mybuttonSci trSci" style={{fontSize:"18px"}}>View Profile</Link>
            </div>
          </div>
          <div class="row myvertic">
            <div class="col-md-4">
              <img src="../assests/images/TechnicalStaffImg/man.png"   width="170px" height="170px" class="dg-img" alt="Manikandan"/>
            </div>
            <div class="col-md-6">
            <p class="myfontSci trSci">Dr Manikandan S</p>
            <p ><a href=""><i class="fa fa-envelope" aria-hidden="true"></i>   Email: manikandan.s@icmr.gov.in</a></p>
            <p ><a href=""> <i class="fa fa-phone-square"></i>  Contact: To Be Update</a></p>
            </div>
            <div class="col-md-2">
              <Link to="/DrMani" class="btn btn-primary mybuttonSci trSci" style={{fontSize:"18px"}}>View Profile</Link>
            </div>
          </div>
           
          <div class="row myvertic">
            <div class="col-md-4">
              <img src="../assests/images/rohcsScientist/Geethu.jpg"   width="170px" height="170px" class="dg-img" alt="Manikandan"/>
            </div>
            <div class="col-md-6">
            <p class="myfontSci trSci">Dr Geethu Mathew</p>
            <p ><a href=""><i class="fa fa-envelope" aria-hidden="true"></i>   Email: geethu.mathew@icmr.gov.in</a></p>
            <p ><a href=""> <i class="fa fa-phone-square"></i>  Contact:080-22172502</a></p>
            </div>
            <div class="col-md-2">
              <Link to="/DrGeethu" class="btn btn-primary mybuttonSci trSci" style={{fontSize:"18px"}}>View Profile</Link>
            </div>
          </div>
           
      </div>
    
 
 
    
    <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordion">
      <div class="card-body">
          <div class="row myvertic">
             <div class="col-md-4">
               <img src="../assests/images/rohcsScientist/Dr V Dhananjayan.jpg"   width="170px" height="170px" class="dg-img" alt="Manikandan"/>
              </div>
              <div class="col-md-6">
                  <p class="myfontSci trSci">Dr V. Dhananjayan</p>
                  <p ><a href=""><i class="fa fa-envelope" aria-hidden="true"></i>   Email: dhananjayan.v@gov.in</a></p>
                <p ><a href=""> <i class="fa fa-phone-square"></i>  Contact:080-22172500</a></p>
              </div>
              <div class="col-md-2">
                 <Link to="/DrDhanan" class="btn btn-primary mybuttonSci trSci" style={{fontSize:"18px"}}>View Profile</Link>
              </div>
          </div>
          
           
      </div>
   </div>
  
     
    
    <div id="collapseFour" class="collapse" aria-labelledby="headingFour" data-parent="#accordion">
      <div class="card-body">
     
          <div class="row myvertic">
              <div class="col-md-4">
                  <img src="../assests/images/rohcsScientist/Dr. Akshaya.jpg"   width="170px" height="170px" class="dg-img" alt="Manikandan"/>
              </div>
            <div class="col-md-6">
              <p class="myfontSci trSci">Dr Akshaya K</p>
              <p ><a href=""><i class="fa fa-envelope" aria-hidden="true"></i>   Email:akshaya.k@icmr.gov.in</a></p>
              <p ><a href=""> <i class="fa fa-phone-square"></i>  Contact:To Be Update</a></p>
            </div>
            <div class="col-md-2">
              <Link to="/DrAkshya" class="btn btn-primary mybuttonSci trSci" style={{fontSize:"18px"}}>View Profile</Link>
            </div>
          </div>
      </div>
     
    </div>
    
    
    
    
    </div>
    
    </div>           
  </div>

 </div>
   
    );
    
}
export default ScientistROHCS;