import React from 'react';
import './carousel.css';
import {  Link } from "react-router-dom";
import NavbarMenu from './NavbarMenu.js';
function Division () {
    return (<div> <NavbarMenu /><div class="manual" style={{minHeight:"780px"}}>
        <div class="row container">
        <p class="mypathhead">
        <Link to="/"><span class="mypath">Home</span></Link>&gt;<span class="mypath">Division</span>
        </p>
          
        </div>
        <h1 class="mypathhead" >Division</h1>
        <div class="scientistdetail">
        <div class="row">
            <div class="col-md-4 col-offset-md-1">
                <center>
                    <div class="panel my-panel" >
                        <div class="panel-heading my-panel-head" align="left"><strong>Health Sciences &nbsp;&nbsp;Division</strong></div>
                        <div class="panel-body" align="left">
                        <ul align="left" >
                            <li >Clinical Epidemiology Lab</li>
                            <li>Poison Information center</li>
                            <li>Ergonomics Lab</li>
                        </ul></div>
                    </div>
                </center>
                
            </div>
            <div class="col-md-4 col-offset-md-1">
            <div class="panel my-panel" >
                <div class="panel-heading my-panel-head" align="left"><strong>Biological Sciences Division</strong></div>
                <div class="panel-body" align="left">
                <ul align="left">
                    <li >Microbiology Lab</li>
                    <li>Biochemistry Lab</li>
                    <li>Toxicology Lab</li>
                </ul>
                </div>
            </div>
            </div>
            <div class="col-md-4 col-offset-md-1">
            <div class="panel my-panel" >
                <div class="panel-heading my-panel-head" align="left"><strong>Chemical Sciences Division</strong></div>
                <div class="panel-body" align="left">
                <ul align="left">
                    <li >Pesticide Toxicology Lab</li>
		            <li>Air Pollution Lab</li>
		            <li>Industrial Hygiene Lab</li>
                </ul>
                </div>
            </div>
            </div>
            
            
        </div>
        <div class="row">
            <div class="col-md-4 col-offset-md-1">
               
                
            </div>
            <div class="col-md-4 col-offset-md-1">
            <div class="panel my-panel" >
                <div class="panel-heading my-panel-head" align="left"><strong>Supporting Facilities</strong></div>
                <div class="panel-body" align="left">
                <ul align="left">
                    <li >Animal House</li>
                    <li >Biostatistics</li> 
                    <li>Library</li>
                    <li >Central Equipment</li>
                    <li >Information Technology</li>
                    <li >Information Education and Communication(IEC)</li>
                </ul>
                </div>
            </div>
            </div>
            <div class="col-md-4 col-offset-md-1">
            
            </div>
            
            
        </div>
       
        </div>
        </div>
    </div>);
}
export default Division;