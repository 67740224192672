import React from 'react';
import './carousel.css';
import {  Link } from "react-router-dom";
import NavbarMenu from './NavbarMenu.js';
function About () {
    return (<div> <NavbarMenu /><div class="manual">
        
        <div class="row container">
        <p class="mypathhead">
         <Link to="/"><span class="mypath">Home</span></Link>&gt;<span class="mypath">The Institute</span>&gt;<span class="mypath">About Institute</span>
        </p>
          
        </div>
        <h1 class="mypathhead">About Institute</h1>
        <div class="scientistdetail">
        <div class="mytext">
            <p  align="justify"><strong>The NIOH is the premier institute, under the aegis of the<a href="http://www.icmr.nic.in/" target="_blank"> Indian Council of Medical Research (ICMR) </a> under the <a href="http://www.dhr.gov.in/" target="_blank">Department of Health Research</a>,<a href="http://mohfw.nic.in/" target="_blank"> Ministry of Health and Family Welfare</a>, Govt. of India.</strong></p>
            <p  align="justify"><strong>The need for research in Occupational Health in the country was first appreciated by Indian Research Fund Association (IRFA), the forerunner of the Indian Council of Medical Research (ICMR). IRFA set up an Industrial Health Advisory Committee (IHAC) under the chairmanship of Col. Bozman in 1945. As per the recommendations of this Committee, in 1947, an Industrial Health Research Unit was created at the All India Institute of Hygiene and Public Health, Calcutta. In 1956, the IHAC recommended to the Government of India that priority should be given for the establishment of an Institute of Occupational Health Research during the Second Five Year Plan.</strong></p>
            <p  align="justify"><strong>The National Institute of Occupational Health (NIOH), Ahmedabad, was established by the Indian Council of Medical Research, New Delhi. The Institute started functioning as &quot;Occupational Health Research Institute&quot; (OHRI) at the B. J. Medical College, Ahmedabad, in the year 1966. The OHRI was rechristened as &quot;National Institute of Occupational Health&quot; (NIOH) in 1970 and moved to the present premises.  To cater local needs of the Southern and Eastern regions, the Institute established two Regional Occupational Health Centres (ROHC) at Bangalore (1977) and Calcutta (1980). </strong></p>
            <p  align="justify"><strong> Occupational Health is a sustained activity aimed at promotion and maintenance of highest degree of physical, mental and social well being of workers in all occupations. The National Institute of Occupational Health (NIOH) has been established with the following objectives: </strong></p>
                            
            <div align="justify">
            <strong>
            <ul>
                <li><p  align="justify">To promote intensive research to evaluate  environmental stresses/factors at the workplace.</p></li>
                <li><p  align="justify">To promote the highest quality of occupational  health through fundamental and applied research.</p></li>
                <li><p  align="justify">To develop control technologies and health  programmes through basic and fundamental research and to generate human  resources in the field.</p></li>
            </ul>
            </strong>
            </div>
        </div>
        </div>
        </div>
    </div>);
}
export default About;