import React from 'react';
import '../carousel.css';
import NavbarMenu from '../NavbarMenu.js';
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
function Dic() {
    return (
        <div> <NavbarMenu /><div class="manual">
        <div class="row container">
        <p class="mypathhead">
		<Link to="/"><span class="mypath">Home</span></Link>&gt;<Link to="/ScientistNIOH"><span class="mypath">Scientist's Profile</span></Link>&gt;<span class="mypath">NIOH Ahmedabad</span>
        </p>
          
        </div>
        <h1 class="mypathhead">Scientist's Detail</h1>
        <div class="scientistdetail">
      
            <div class="panel my-panel" >
      <div class="panel-heading my-panel-head" align="left"><strong>Basic Details</strong></div>
      <div class="panel-body" align="left">
      <table width="100%">
                <tr>
                <td width="28%" rowspan="5"><img src="../assests/images/niohScientist/director.jpg" width="180px" height="170px" CLASS="dg-img"/></td>
                <td width="50%" ><p class="myfontSci trSci">Dr Santasabuj Das</p> </td>
                <td width="22%" ></td>
                
                </tr>
                
                <tr>
                
                <td class="myfontSci"><p class="myfontSci">Director-In-Charge, Scientist-G </p></td>
                </tr>
                <tr>
                <td><p ><a href=""><i class="fa fa-envelope" aria-hidden="true"></i>   Email: director-nioh@gov.in</a></p></td>
                </tr> 
                <tr>
                <td><p ><a href=""> <i class="fa fa-phone-square"></i>  Contact: 079-22688710,079-22688711</a></p></td>
                </tr>   
            
                </table> 
      </div>
    </div>
       
        <div class="panel my-panel" >
      <div class="panel-heading my-panel-head" align="left"><strong>Academic Qualification</strong></div>
      <div class="panel-body" align="left">MBBS,M.D. (General Medicine)</div>
    </div>
    <div class="panel my-panel" >
      <div class="panel-heading my-panel-head" align="left"><strong>Research Interest </strong></div>
      <div class="panel-body" align="left">Host-pathogen interactions and vaccine development for Salmonella Typhiand Paratyphi infections, and the regulation of mucosal immune responses at the intestine in health and disease</div>
    </div>
    <div class="panel my-panel" >
      <div class="panel-heading my-panel-head" align="left"><strong>Membership/Affliation </strong></div>
      <div class="panel-body" align="left">
	  <ul>
	  <li>Fellow, West Bengal Academy of Science and Technology, 2016</li><br/>
	  <li>Nominated member,Molecular Immunology Forum, India</li><br/>
	  <li>Contributing member, American Society of microbiology</li><br/>
	  <li>Life member, Probiotic Association of India</li><br/>
	  <li>Life member, Society of Biological Chemists, India</li><br/>
	  <li>Life member, Indian Science Congress Association</li><br/>
	  
        </ul>
        </div>
        </div>
        <div class="panel my-panel">
      <div class="panel-heading my-panel-head" align="left"><strong>Fellowship/Awards </strong></div>
      <div class="panel-body" align="left"><ul>
	  <li>Keystone Symposia Global Health Travel Award for London, UK, 2016 - Sayan Das, PhD student.</li><br/>
	  <li>Keystone Symposia Global Health Travel Award for London, UK, 2016 - Rimi Chowdhury, Ph.D.Student.</li><br/>
	  <li>Travel Award from the Department of Biotechnology, India to attend the "The EMBO Meeting" at Mannheim, Germany, 2016 - Sayan Das, PhD student.</li><br/>
	  <li>Travel Award from the Department of Biotechnology, India to attend the "The EMBO Meeting" at Mannheim, Germany, 2016 - Rimi Chowdhury, Ph.D. student</li><br/>
	  <li>Travel Award from Wellcome Trust to attend the "Protein Interactions andNetworks Workshop" atHinxton, UK, 2016 - Rimi Chowdhury, Ph.D. student.</li><br/>
	  <li>Best Poster Award at the 8th Indo Global Summit on Vaccines, Therapeutics and Healthcare,organized by OMICS International at Hyderabad, India, 2015 - Sayan Das, PhD student.</li><br/>
	  <li>BioAsia Innovation Award, 2015 - Rahul Shubhra Mondal, PhD student.</li><br/>
	  <li>Best Poster Award at the 2nd Annual conference of the Probiotic Association of India, 2014 - Piu Saha, Post doctoral Fellow.</li><br/>
	  <li>Travel Award from DST, India to attend the 5th ACM Conference at California, USA, 2014 - Rahul Shubhra Mondal, PhD student.</li><br/>
	  <li>Young Investigator Award at the Yakult (India) Probiotics Meeting, 2014 - Bhupesh Kumar Thakur, PhD student.</li><br/>
	  <li>Best Poster Award at the International Conference on Host-Pathogen Interaction at NIAB, Hyderabad, 2014 - Theeya Nagaraja, PhD student.</li><br/>
	  <li>Best Poster Award at the Conference on Recent Advances in Computational Drug Design at Indian Institute of Science, Bangalore, 2013 - Rahul Shubhra Mondal, PhD student.</li><br/>
	  <li>Best Poster Award at IMMUNOCON, 2013 - Piu Saha, Post doctoral Fellow.</li><br/>
	  <li>Best Poster Award at the 1st Annual conference of the Probiotic Association of India, 2012 - Bhupesh Kumar Thakur, PhD student.</li><br/>
	  <li>Prof. Awtar Krishnan Prize for excellence in Flowcytometry at NCBS, Bangalore, 2012 - Bhupesh Kumar Thakur, PhD student.</li><br/>
	  <li>Cytometrist of the Year Award at the 5th Annual meeting of the Cytometriy Society, India, 2011 - Piu Saha, Post doctoral Fellow.</li><br/>
	  <li>Travel Award from DST, India to attend the Keystone Symposia at Trinity College, Dublin, Ireland, 2010 - Krishnendu Chakraborty, PhD student.</li><br/>
	  <li>Best Poster Award at the IMMUNOCON 2007 - Krishnendu Chakraborty, PhD student </li>
	  <br/>
	  </ul>
</div>
</div>
<div class="panel my-panel">
      <div class="panel-heading my-panel-head" align="left"><strong>Publications </strong></div>
      <div class="panel-body" align="left">
	

	
	
	<p class="my-pclass">2019</p>
	<ul>
	<li>Dasgupta S, <strong>Das S</strong>, Biswas A, Bhadra RK, Das S. Small alarmones (p)ppGpp regulate virulence associated traits and pathogenesis of Salmonella entericaserovarTyphi.Cellular Microbiology. 2019 Aug;21(8):e13034</li>
	<br/><li><strong>Das S</strong>, Chowdhury R, Pal A, Okamoto K, Das S. Salmonella Typhi outer membrane protein STIV is a potential candidate for vaccine development against typhoid and paratyphoid fever.Immunobiology. 2019 May;224(3):371-382</li>
	<br/><li>Dasgupta N, BK Thakur, A Chakraborty, <strong>S Das</strong>. Butyrate-induced in vitro colonocyte differentiation network model identifies ITGB1, SYK, CDKN2A, CHAF1A, and LRP1 as the prognostic markers for colorectal cancer recurrence. Nutr Cancer. 2019;71(2):257-271</li>
	<br/><li>Chowdhury R, S Das, A Ta, <strong>S Das</strong>. Epithelial invasion by Salmonella Typhi using STIV-Met Interaction. Cell Microbiol. 2019 Mar;21(3):e12982</li>
	</ul>
	<p class="my-pclass">2018</p>
	<ul>
	<br/><li>Hriemer K, A Bobogare, B Ley, CS Gudo, MS Alam, NM Anstey, E Ashley, JK Baird, C Gryseels, E Jambert, M Lacerda, F Laihad, J Marfurt, AP Pasaribu, JR Poespoprodjo, I Sutanto, WR Taylor, C van den Boogaard, KE Battle, L Dysoley, P Ghimire, B Hawley, J Hwang, WA Khan, RNB Mudin, ME Sumiwi, R Ahmed, MM Aktaruzzaman, KR Awasthi, A Bardaji, D Bell, L Boaz, FH Burdam, D Chandramohan, Q Cheng, K Chindawongsa, J Culpepper, <strong>S Das</strong> et al. Quantifying primaquine effectiveness and improving adherence: a round table discussion of the APMEN Vivax Working Group. Malar J. 2018 Jun 20;17(1):241.</li>
	<br/><li>Banerjee A, M Lo, Indwar, AK Deb, <strong>S Das</strong>, B Manna, S Dutta, UK Bhadra, M Bhattacharya, P Okamoto K, M Chawla-Sarkar. Upsurge and spread of G3 rotaviruses in Eastern India (2014-2016): Full genome analyses reveals heterogeneity within Wa-like genomic constellation. Infect Genet Evol. 2018 May 26;63:158-174.</li>
	<br/><li>Mukherjee SK, Mandal RS,<strong>Das S</strong> , Mukherjee M. Effect of non-β-lactams on stable variants of inhibitor-resistant TEM β-lactamase in uropathogenic Escherichia coli: implication for alternative therapy. J Appl Microbiol. 2018 Mar;124(3):667-681.</li>
	<br/><li>Barik A, <strong>Das S</strong>. A comparative study of sequence - and structure-based features of small RNAs and other RNAs of bacteria. RNA Biol. 2018 Jan 2;15(1):95-103. </li>
	</ul>
	<p class="my-pclass">2017</p>
	<ul>
	<br/><li>Chowdhury R, H Ilyas, A Ghosh, H Ali, A Ghorai, A Midya, NR Jana,<strong>S Das</strong>, A. Bhunia. Multivalent Gold nanoparticle-Peptide Conjugates for Targeting Intracellular Bacterial Infections. Nanoscale. 2017 Sep 28;9(37):14074-14093.</li>
	<br/><li>Jana S, D Patel, S Patel, K Upadhyay, J Thadani, R Mandal, <strong>S Das</strong>, R Devkar. Anthocyanin rich extract of Brassica oleracea L. alleviates experimentally induced myocardial infarction. PLoS One. 2017 Aug 1;12(8):e0182137</li>
	<br/><li>Das S, R Chowdhury, S Ghosh, <strong>S Das</strong>. A recombinant protein of Salmonella Typhi induces humoral and cell-mediated immune responses including memory responses. Vaccine. 2017 Aug 16;35(35 Pt B):4523-4531.</li>
	<br/><li>Ta A, BK Thakur, P Dutta, R Sinha, H Koley, <strong>S Das</strong>. Double-stranded RNA induces cathelicidin expression in the intestinal epithelial cells through phosphatidylinositol 3-kinase-protein kinase Cζ-Sp1 pathway and ameliorates shigellosis in mice. Cell Signal. 2017 Jul; 35:140-153</li>
	<br/><li>Sinha R, DR Howlader, A Ta, S Mitra, <strong>S Das</strong>, H Koley. Retinoic acid pre-treatment down regulates V. cholerae outer membrane vesicles induced acute inflammation and enhances mucosal immunity. Vaccine. 2017 Jun 16; 35(28):3534-3547. </li>
	<br/><li>Mandal RS, S Panda, <strong>S Das</strong>. In silico prediction of drug resistance due to S247R mutation of Influenza H1N1 neuraminidase protein. J Biomol Struct Dyn. 2017 Apr 10:1-15.</li>
	<br/><li>Barman RK, A Mukhopadhyay, <strong>S Das</strong>. An improved method for identification of small non-coding RNAs in bacteria using support vector machine. Sci Rep. 2017 Apr 6; 7:46070.</li>
	<br/><li>Dasgupta N, BK Thakur, A Ta, <strong>S Das</strong>, G Banik, S Das. Polo-like kinase 1 expression is suppressed by CCAAT/enhancer-binding protein α to mediate colon carcinoma cell differentiation and apoptosis. Biochim Biophys Acta. 2017 Jul; 1861(7):1777-1787.</li>
	<br/><li>Dutta P, A. Ta, B. K. Thakur, N. Dasgupta, <strong>S Das</strong>. Biphasic Ccl20 regulation by Toll-like receptor 9 through the activation of ERK-AP-1 and non-canonical NF-κB signaling pathways. Biochim Biophys Acta. 2017 Jan; 1861(1 Pt A):3365-3377.</li>
	<br/><li>Dasgupta N., B. K. Thakur, A. Ta, P. Dutta, and <strong>S Das</strong>. Suppression of Spleen Tyrosine Kinase (Syk) by Histone Deacetylation Promotes, Whereas BAY61-3606, a Synthetic Syk Inhibitor Abrogates Colonocyte Apoptosis by ERK Activation. J Cell Biochem. 2017 Jan; 118(1):191-203.</li>
	</ul>
	<p class="my-pclass">2016</p>
	<ul><li>Mandal RS, A. Ta, R. Sinha, N. Theeya, A. Ghosh, M. Tasneem, A. Bhunia, H. Koley, <strong>S Das</strong>. 2016. Ribavirin suppresses bacterial virulence by targeting LysR-type transcriptional regulators. Sci Rep. 6:39454.</li>
	<br/><li>Thakur B. K., N. Dasgupta, A. Ta, and <strong>S. Das</strong>. 2016. Physiological TLR5 expression in the intestine is regulated by differential DNA binding of Sp1/Sp3 through simultaneous Sp1 dephosphorylation and Sp3 phosphorylation by two different PKC isoforms. Nucleic Acids Res. 2016 Jul 8;44(12):5658-72</li>
	<br/><li>Thakur B. K., P. Saha, G. Banik, D. R. Saha, S. Grover, V. K. Batish, and <strong>S Das</strong>. 2016. Live and heat-killed probiotic Lactobacillus casei Lbs2 protects from experimental colitis through Toll-like receptor 2-dependent induction of T-regulatory response. Int Immunopharmacol. 36:39-50</li>
	<br/><li>Parida S, I. Pal, A. Parekh, B. Thakur, R. Bharti, <strong>S. Das</strong>, and M. Mandal. 2016. GW627368X inhibits proliferation and induces apoptosis in cervical cancer by interfering with EP4/EGFR interactive signaling. Cell Death Dis.;7:e2154</li>
	<br/><li>Saha P, C. Manna, <strong>S. Das</strong>, and M. Ghosh. 2016. Antibiotic binding of STY3178, a yfdX protein from Salmonella Typhi. Sci Rep. 2016 Feb 19;6:21305</li><br/><li>Datta P, <strong>S. Das</strong>. 2016. Mammalian antimicrobial peptides: promising therapeutic targets against infection and chronic inflammation. Curr. Top. Med. Chem. 16(1):99-129.</li>
	</ul>
	<p class="my-pclass">2015</p>
	<ul>
	<br/><li><strong>Das S</strong> and B. K. Thakur. 2015. Mucosal immune system of the respiratory tract: regulation of tolerance and immune response. The Pulmo-Face XV(2): 61-70.</li>
	<br/><li>Mandal R. S and <strong>S. Das</strong>. In silico approach towards identification of potential inhibitors of Helicobacter pyloriDapE. J BiomolStructDyn.2015 Jul.33(7):1460-73</li>
	<br/><li>Dasgupta N, B. K. Thakur, A. Ta A and <strong>S. Das</strong>. 2015. Caveolin-1 is transcribed from a hypermethylated promoter to mediate colonocyte differentiation and apoptosis.Exp Cell Res. 2015 Jun 10;334(2):323-36</li><br/>
	<br/><li>Banerjee R, <strong>S. Das</strong>, S.Basak. Similarity of currently circulating H1N1 virus with the 2009 pandemic clone: Viability of an imminent pandemic. Infection, Genetics and Evolution. 2015 Jun; 32:107-112</li>
	<br/><li>Chowdhury R, R. S. Mandal, A. Ta and <strong>S. Das</strong>. An AIL family protein promotes Type Three Secretion System-1 independent invasion and pathogenesis of Salmonella entericaserovar Typhi. Cell Microbiol. 2015 Apr. 17(4):486-503</li>
	<br/><li>Nagaraja T, A. Ta, <strong>S. Das</strong>, R. S. Mandal, O. Chakrabarti, S. Chakrabarti, A. N. Ghosh and S. Das. An Inducible and Secreted Eukaryotic-like Serine/Threonine Kinase of Salmonella Typhi Promotes Intracellular Survival and Pathogenesis. 2015. Infect Immun. 83(2):522-33</li>
	<br/><li>Barman R. K., T. Jana, <strong>S. Das</strong>, S. Saha. 2015. Prediction of intra-species protein-protein interactions in enteropathogens facilitating systems biology study. PLoS One. 10(12):e0145648.</li><br/>
	<br/><li>Mandal R. S., S. Saha, <strong>S. Das</strong>. 2015. Metagenomic surveys of gut microbiota. Genomics, Proteomics Bioinformatics 13(3):148-158.</li>
	</ul>
	<p class="my-pclass">2014</p>
	<ul>
	<br/><li>Bhowmick S, M. Malar, A. Das, B. K. Thakur, P. Saha, <strong>S. Das</strong>, H. M. Rashmi, V. K. Batish, S. Grover and S. Tripathy. 2014. Draft Genome sequence of Lactobacillus casei Lbs2. Genome Announc.2(6): e01326-14</li>
	<br/><li>Barman RK, S. Saha, <strong>S. Das</strong> 2014. Prediction of interactions between viral and host proteins using supervised machine learning methods. PLoS ONE. 9(11):e112034</li>
	<br/><li>Dhal P. K, R. K Barman, S. Saha and <strong>S. Das</strong> 2014. Dynamic Modularity of Host Protein Interaction Networks in Salmonella Typhi Infection. PLoS One. 9(8):e104911</li>
	<br/><li>Bhattacherjee A, R.S. Mandal, <strong>S. Das</strong>, S. Kundu. 2014. Sequence and 3D structure based analysis of TNT degrading proteins in Arabidopsis thaliana. J Mol Model. 20(3):2174.</li>
	</ul>
	<p class="my-pclass">2013</p>
	<ul>
	<br/><li>Rajendra Kumar Labala, <strong>Santasabuj Das</strong>, and Surajit Basak 2013. ASRDb: A comprehensive resource for archaeal stress response genes. Bioinformation. 9: 650-655.</li>
	</ul>
	<p class="my-pclass">2012</p>
	<ul>
	<br/><li>Dasgupta A., R. Banerjee, <strong>S. Das</strong> and S. Basak. 2012. Evolutionary perspective on the origin of Haitian cholera outbreak strain. J Biomol Struct Dyn. 30: 338-46.</li>
	<br/><li>Banerjee R, A. Roy, F. Ahmad, <strong>Das S</strong>, S. Basak. 2012. Evolutionary patterning of hemagglutinin gene sequence of 2009 H1N1 pandemic. J Biomol Struct Dyn. 29: 733-742. </li>
	</ul>
	<p class="my-pclass">2011</p>
	<ul>
	<br/><li>Ghosh S., K. Chakraborty, T. Nagaraja, S. Basak, H. Koley, S. Dutta, U. Mitra and <strong>S. Das</strong>. 2011. An adhesion protein of Salmonella enterica serovar Typhi is required for pathogenesis and potential target for vaccine development.<br/>
			Proc Natl Acad Sci U S A. 108: 3348-3353. </li>
			</ul>
	<p class="my-pclass">2010</p>
	<ul>
	<br/><li>Roy N, S. Barman, A. Ghosh, A. Pal, K. Chakraborty, <strong>S. Das</strong>, D. R. Saha, S. Yamasaki and H. Koley. 2010. Immunogenicity and protective efficacy of Vibrio cholerae outer membrane vesicles in rabbit model. FEMS Immunol Med Microbiol. 60: 18-27.</li>
	<br/><li>Bhadra R. K., <strong>S. Das</strong> and G. B. Nair. 2010. Immunological basis for Immunization: Cholera. WHO/IVB/ISBN 978 92 4 159974 0, pp.1-31.</li>
	</ul>
	<p class="my-pclass">2009</p>
	<ul>
	<br/><li>Sinha, N. K., A. Roy, B. Das, <strong>S. Das</strong> and S. Basak. 2009. Evolutionary complexities of swine flu H1N1 gene sequences of 2009. Biochem Biophys Res Commun. 390: 349-51.</li>
	<br/><li>Chakraborty, K., P. C. Maity, A. K. Sil, Y. Takeda and <strong>S. Das</strong>. 2009. cAMP stringently regulates human cathelicidin antimicrobial peptide expression in the mucosal epithelial cells by activating cAMP-response element-binding ptotrin, AP-1, and Inducible cAMP early repressor. J Biol Chem. 284:21818-21827.</li>
	<br/><li>Basak, S., R. Banerjee, I. Mukherjee, <strong>S. Das</strong>. 2009. Influence of domain architecture and codon usage pattern on the evolution of virulence factors of Vibrio cholerae. Biochem Biophys Res Commun. 379: 803-805.</li>
	</ul>
	<p class="my-pclass">2008</p>
	<ul>
	<br/><li>Basak, S., I. Mukherjee, M. Choudhury and <strong>S. Das</strong>. 2008. Unusual codon usage bias in low expression genes of Vibrio cholerae. Bioinformation. 3: 213-217.</li>
	<br/><li>Chakraborty, K., S. Ghosh, H. Kole, A. K. Mukhopadhyay, T. Ramamurthy, D. R. Saha, D. Mukhopadhyay, S. Roychowdhury, T. Hamabata, Y. Takeda and <strong>S. Das</strong>. 2008. Bacterial exotoxins downregulate cathelicidin (hCAP18/LL37) and human defensin 1 (HBD-1) expression in the intestinal epithelial cells. Cell Microbiol. 10: 2520-2537.</li>
	<br/><li>Chakraborty, K., S. Ghosh, H. Kole, A. K. Mukhopadhyay, T. Ramamurthy, D. R. Saha, D. Mukhopadhyay, S. Roychowdhury, T. Hamabata, Y. Takeda and <strong>S. Das</strong>. 2008. Bacterial exotoxins downregulate cathelicidin (hCAP18/LL37) and human defensin 1 (HBD-1) expression in the intestinal epithelial cells. 2008. (In Press).</li>
	</ul>
	<p class="my-pclass">2007</p>
	<ul>
	<br/><li>Van Acker, G. J., G. Perides, E. R. Weiss, <strong>S. Das</strong>, P. N. Tsichlis and M. L. Steer. 2007. Tumor progression locus-2 is a critical regulator of pancreatic and lung inflammation during acute pancreatitis. J. Biol Chem. 282: 22140-9.</li>
	</ul>
	<p class="my-pclass">2006</p>
	<ul>
	<br/><li>Ghosh, A., D. R. Saha, K. M. Hoque, M. Asakuna, S. Yamazaki, H. Koley, <strong>S. Das</strong>, M. K. Chakraborty and A. Pal. 2006. Enterotoxigenicity of 45kDa matured and 35kDa processed forms of hemagglutinin protease purified from a cholera toxin gene negative Vibrio cholerae non-O1non-O139 strain. Infect Immun. 74: 2937-46.</li>
	<br/><li>Eliopoulos, A. G., <strong>S. Das</strong> and P. N. Tsichlis. 2006. The tyrosine kinase Syk regulates TPL2 activation signals. J. Biol Chem. 281: 1371-80.</li>
	</ul>
	<p class="my-pclass">2005</p>
	<ul>
	<br/><li><strong>Das S.</strong>, J. Cho, I. Lambertz, M. A. Kelliher, A. G. Eliopoulos, K. Du and P. N. Tsichlis. 2005. Tpl2/Cot Signals Activate ERK, JNK, and NF-?B in a Cell-type and Stimulus-specific Manner. J. Biol Chem. 280: 23748-57.</li>
	</ul>
	<p class="my-pclass">2002</p>
	<ul>
	<br/><li><strong>Das S.</strong>, J. H. Lin, J. Papamatheakis, Y. Sykulev and P. N. Tsichlis. 2002. Differential splicing generates Tvl-1/RFXANK isoforms with different functions. J. Biol Chem. 277: 45172-80.</li>
	<br/><li><span >Books/Books Chapter/Training Modules </span></li>
	<br/><li>Mandal RS, <strong>S. Das</strong>. 2017. In Silico Approaches Toward Combating Antibiotic Resistance. In Arora G, sajid A, Kalia VC (eds), Drug Resistance in Bacteria, Fungi, Malaria, and Cancer. Springer, pp.577-593</li>
	<br/><li>Dutta P and <strong>S. Das</strong>. 2015. Antimicrobial peptides and infectious Diseases. In Mendez-Vilas A (ed), The Battle against Microbial Pathogenesis: Basic Science and Educational Programmes, 5th series. Badazgoz Spain, Formatex Research Centre.</li>
	<br/><li>Bhadra R. K., <strong>S. Das</strong> and G. B. Nair. 2010. Immunological basis for Immunization: Cholera. WHO/IVB/ISBN 978 92 4 159974 0, pp.1-31.</li>
	<br/><li><span >Patents Granted </span></li>
	<br/><li><strong>Das S</strong>, Ghosh S. A novel salmonella typhi protein as subunit vaccine. (Patent No. 283894; Pub Date: 09.09.2011).</li>
	<br/><li><strong>Das S</strong>, Bhunia A. A peptide to neutralize lipopolysaccharide molecule. (Pub No. WO 2016 132377 A1; Pub Date: 25/08/2016).</li>
	<br/><li>Koley H, Mitra S,<strong>Das S</strong>, Chakrabarti MK. A multi-serotype outer membrane vesicles (momv) of Shigellae as a novel candidate vaccine. (Pub No. WO/2014/192031; Pub Date: 04.12.2014).</li>
	</ul>
</div>
</div>

        </div>
		</div>
    </div>
    );
}
export default Dic;