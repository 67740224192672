import React from 'react';
import '../carousel.css';
import NavbarMenu from '../NavbarMenu.js';
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
function DrSarang() {
    return (
      <div> <NavbarMenu /><div class="manual">
        <div class="row container">
        <p class="mypathhead">
        <Link to="/"><span class="mypath">Home</span></Link>&gt;<Link to="/ScientistNIOH"><span class="mypath">Scientist's Profile</span></Link>&gt;<span class="mypath">NIOH Ahmedabad</span>
        </p>
          
        </div>
        <h1 class="mypathhead">Scientist's Detail</h1>
        <div class="scientistdetail">
      
            <div class="panel my-panel" >
      <div class="panel-heading my-panel-head" align="left"><strong>Basic Details</strong></div>
      <div class="panel-body" align="left">
      <table width="100%">
            <tr>
              <td width="28%" rowspan="5"><img src="../assests/images/niohScientist/SDhatrak.jpg" width="170px" height="170px" CLASS="dg-img"/></td>
              <td width="50%" ><p class="myfontSci trSci">Dr Sarang Dhatrak</p> </td>
              <td width="22%" ></td>
            </tr>
            
            <tr>
              
              <td class="myfontSci"><p class="myfontSci">Health sciences Division, In-Charge PIC </p></td>
             </tr>
            <tr>
              <td><p ><a href=""><i class="fa fa-envelope" aria-hidden="true"></i>   Email: dhatrak.sv@icmr.gov.in</a></p></td>
            </tr> 
            <tr>
              <td><p ><a href=""> <i class="fa fa-phone-square"></i>  Contact: 079-22688757</a></p></td>
            </tr>   
           
            </table>   
      </div>
    </div>
       
        <div class="panel my-panel" >
      <div class="panel-heading my-panel-head" align="left"><strong>Academic Qualification</strong></div>
      <div class="panel-body" align="left">M.B.B.S. , M.D.
</div>
    </div>
    <div class="panel my-panel" >
      <div class="panel-heading my-panel-head" align="left"><strong>Research Interest </strong></div>
      <div class="panel-body" align="left">Pneumoconioses,Noise induced hearing loss, Pesticide toxicity</div>
    </div>
    <div class="panel my-panel" >
      <div class="panel-heading my-panel-head" align="left"><strong>Membership/Affliation </strong></div>
      <div class="panel-body" align="left">
      <ul><li>Indian Association of Epidemiology

</li>
<li>Indian Medical Association</li>

</ul>
</div>
</div>
<div class="panel my-panel">
      <div class="panel-heading my-panel-head" align="left"><strong>Fellowship/Awards </strong></div>
      <div class="panel-body" align="left">NIL
</div>
</div>
<div class="panel my-panel">
      <div class="panel-heading my-panel-head" align="left"><strong>Publications </strong></div>
      <div class="panel-body" align="left">
	
      <ol>

      <li>	Sarkar K, <strong>Dhatrak S</strong>, Sarka B, Ojha UC, Raghav P, Pagdhune A. Secondary prevention of silicosis and silico‐tuberculosis by periodic screening of silica dust exposed workers using serum club cell protein 16 as a proxy marker. Health Science Reports 4 (3), e373. Pubmed& SCI Emerging
</li><br/><li>	Prajapati SS, Mishra RA, Jhariya B,<strong>Dhatrak S*</strong> - Respirable dust and crystalline silica exposure among different mining sectors in India. Archives of Environmental & Occupational Health, 2021. SCI Indexed IF: 1.66
</li><br/><li>	Nandi SS, <strong>Dhatrak SV</strong>, Sarkar K. Silicosis, progressive massive fibrosis and silico-tuberculosis among workers with occupational exposure to silica dusts in sandstone mines of Rajasthan state: An urgent need for initiating national silicosis control programme in India. J Family Med Prim Care 2021;10:686-91. Pubmed
</li><br/><li>	Murhekar M, Bhatnagar T, Selvaraju S, Kumar VS, Thangaraj JW, Shah N, Santhosh Kumar M, Rade K, Sabarinathan R, Asthana S, Balachandar R,…<strong>Dhatrak S</strong>.. SARS-CoV-2 Antibody Prevalence in India: Findings from the Second Nationwide Household Serosurvey, August-September 2020. The Lancet Global Health. 2021 Mar 1;9(3):e257-66.  IF 21.59. 
</li><br/><li>	Murhekar M, Bhatnagar T, Thangaraj JW, Jeromie Wesley V Saravanakumar, Muthusamy Santhosh Kumar, SriramSelvaraju, Kiran Rade, CP Girish Kumar, R Sabarinathan, AlkaTuruk, Smita Asthana, Rakesh Balachandar, …… <strong>Dhatrak S</strong>. SARS-CoV-2 seroprevalence among the general population and Healthcare workers in India, December 2020–January 2021. International Journal of Infectious Diseases. 2021. 108. 145-155. IF 3.62. 
</li><br/><li>	Nimje S, Dhumne U, <strong>Dhatrak S*</strong>, Nandi S,. Comparative Study of Work‑Related Musculoskeletal Disorders among Mechanized and Manual Stone Miners. J DattaMeghe Inst Med SciUniv 2020;15:287-91. Scopus
</li><br/><li>	Nandi S,  Nimje S, Dhumne U, <strong>Dhatrak S*</strong>. Pulmonary tuberculosis among stone miners of India vis-à-vis silica exposure. Indian Journal of Occupational and Environmental Medicine. 2020; 24(2): 102-105. DOI: 10.4103/ijoem.IJOEM_272_19 Pubmed& SCI Emerging
</li><br/><li>	Prajapati S, Nandi S, Deshmukh A & <strong>Dhatrak S*</strong>. Exposure profile of respirable crystalline silica in stone mines in India. Journal of Occupational and Environmental Hygiene, 2020; 17(11-12): 531 - 37DOI: 10.1080/15459624.2020.1798011. SCI Indexed IF: 2.15 
</li><br/><li>	Nandi S, Gupta S, <strong>Dhatrak S.</strong>  Occupational impact of increased dust duration on pulmonary capacities of workers. Journal of Environmental and Occupational Health. 2020 10 (1): 10–14
</li><br/><li>	<strong>Dhatrak S</strong>, Nandi S. Assessment of silica dust exposure profile in relation to prevalence of silicosis among Indian sandstone mine workers: Need for review of standards. Am J Ind Med. 2020; 63(3) : 269-76 . SCI Indexed IF: 2.214
</li><br/><li>	Dhumne, U, Nimje S, <strong>Dhatrak S</strong>, Nandi S,Ingole S, Gupta S. Assessment of Health Status among Mine workers of Maharashtra. Journal of Biochemistry and Biophysics. 20194(1):15-19
</li><br/><li>	Nandi S, Burnase N, Barapatre A, Gullane P and <strong>Dhatrak S*</strong>, Assessment of Silicosis Awareness among Stone mine workers of Rajasthan state. Indian Journal of Occupational and Environmental Medicine. 2018; 22: 97-100.Pubmed& SCI Emerging
</li><br/><li>	<strong>Dhatrak S</strong>, Nandi S, Gupta S. Comparative Study of pulmonary impairment among diverse working group in coal mine. Am J Prev Med Public Health. 2018; 2(1): 13-17.
</li><br/><li>	Dhumne, U, Nimje S, Nandi S,<strong>Dhatrak S.</strong> Prevalence of Obesity Among Different Category of Mine Workers In India. Int J Recent Sci Res. .2018,  9(5), 27143. Deshmukh A, Kulkarni N, Dhatrak S, Nandi S. Assessment of Occupational dust and silica exposure in Indian Stone mining and crushing unit – A case study. Curr. World Environ. 2017; 12(2):663-671
</li><br/><li>	<strong>Dhatrak S</strong>, Nandi S, Sishodiya P, Dhumne U, Ingole S, Gupta S. Health Status Evaluation of mine workers and nearby population around iron ore mines in tribal district of Jharkhand, India. Am J Prev Med Public Health. 2017; 1(1): 20-26. 
</li><br/><li>	Gupta S, DhumneU ,<strong>Dhatrak S</strong> , Nandi S. Assessment of pulmonary function impairment among mine workers. Indian Journal of Immunology and Respiratory Medicine, 2017;2(1):7-9
</li><br/><li>	Nandi SS, <strong>Dhatrak S*</strong>, Chatterjee DM, Dhumne UL. Occurrence of Metabolic Syndrome with Relation to Job Profile among Mine Employees. Journal of Obesity and Metabolic Research. 2016; 3 (1): 32-36. 
</li><br/><li>	<strong>Dhatrak SV</strong>, Nandi SS and Dhumne UL. Metabolic syndrome in different sub occupations among mine workers. Indian Journal of Occupational and Environmental Medicine. 2015; 19(2): 76-79. Pubmed& SCI Emerging
</li><br/><li>	Nandi SS, <strong>Dhatrak SV*</strong>, Chatterjee DM, Dhumne UL and Ingole SV. A study on morbidity profile of lignite miners in western India. International Journal of Occupational Safety and Health. 2015; 5 (1): 14-16. 
</li><br/><li>	Nandi SS, <strong>Dhatrak SV*</strong>, Dhumne UL, Ingole SV and Gupta SR. A study on morbidity pattern among barytes mine workers in southern India. Indian Journal of Applied Research. 2015; 5 (1): 7-9.
</li><br/><li>	<strong>Dhatrak SV</strong>, Nandi SS and Dhumne UL. Impact of Occupation on Occurrence of Hypertension and Obesity among Mine Workers in Western India.International Journal of Scientific Research. 2015; 4 (1): 341-342.
</li><br/><li>	Dhumne UL, <strong>Dhatrak SV</strong>, Nandi SS. Effect of age and body mass index on the status of prehypertension and hypertension among mine employees. Int J ClinExpPhysiol 2015;2:165-8.
</li><br/><li>	<strong>Dhatrak SV</strong>, Nandi SS, Chatterjee DM and Dhumne UL. Health Status Evaluation of Limestone Mine Workers. National Journal of Community Medicine. 2014; 5 (1): 410-413. 
</li><br/><li>	<strong>Dhatrak SV</strong>, Nandi SS. Chronic Poisoning among Indian coal miners-A Risk Assessment. Indian Journal of Environmental Protection. 2009; 29(12):1083-1086. Scopus
</li><br/><li>	Nandi SS, <strong>Dhatrak SV</strong>, Chatterjee DM and Dhumne UL. Health Survey in Gypsum mines in India. Indian Journal of Community Medicine. 2009; 34 (4): 343-345.  Pubmed& SCI Emerging
</li><br/><li>	<strong>Dhatrak SV</strong>, Nandi SS. Risk Assessment of chronic Poisoning among Indian metallic miners.  Indian Journal of Occupational and Environmental Medicine. 2009; 13 (2): 60-64. Pubmed& SCI Emerging
</li><br/><li>	Nandi SS, <strong>Dhatrak SV</strong>. Occupational Noise Induced Hearing Loss in India.  Indian Journal of Occupational and Environmental Medicine. 2008; 12(2): 53-56.  Pubmed& SCI Emerging
</li><br/><li>	Chatterjee DM, S<strong>Dhatrak SV</strong>, Nandi SS, Mandal BB and Narwadiya S.  Respiratory Morbidity among miners in Rajasthan state- report of a pilot study. Asian-Pacific Newslettear on Occupational Health and Safety. 2008; 15(4): 42-43. 
</li><br/><li>	<strong>Dhatrak S</strong>, Thawani V, Khandelwal P, Thawani M. The role of probiotics, prebiotics and synbiotics in Health The Antiseptic, 103 (6), 311-13, 2006.
</li><br/><li>	Traditional medicine in osteoporosis. ThawaniV, <strong>Dhatrak S</strong>, GharpureK, ThakreS, PatelS. Orthopedics Today, Vol. 6 (2): 117 – 20, 2004,
</li><br/><li>	CissusQuadrangularis - a promising herb in fracture healing and osteoporosis. Gharpure K, Thawani V, <strong>Dhatrak S</strong>,  Patel S. The Antiseptic, Vol. 101 (6): 225–26, 2004 
</li><br/><li>	Nimesulide - efficacy and safety review..ThawaniV, SontakkeS,<strong>Dhatrak S</strong>, HatwarR. The Antiseptic, Vol. 100 (6): 230 – 32, 2003.</li>


</ol>
	
</div>
	
</div>
</div>

        </div>
    </div>
    );
}
export default DrSarang;