import React, {Component} from 'react';
import './carousel.css';

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import Slider from "react-slick";
import NavbarMenuHindi from './NavBarMenuHindi.js';
import { Link } from "react-router-dom";
import { TwitterTimelineEmbed, TwitterShareButton, TwitterFollowButton, TwitterHashtagButton, TwitterMentionButton, TwitterTweetEmbed, TwitterMomentShare, TwitterDMButton, TwitterVideoEmbed, TwitterOnAirButton } from 'react-twitter-embed';
class IndexHindi extends Component {
  constructor(props) {
   
    super(props);
    this.openCity = this.openCity.bind(this);
  }
  openCity(evt,cityName) {
   
    
  }
  
    
 render()
    {
      const Settings = {
        infinite: true,
        slidesToShow: 5,
        slidesToScroll: 1,
        autoplay:400,
        
    };
    
     return (
      <div> <NavbarMenuHindi /><div class="manual">
    
     <div class="container-fluid">
      <div class="col-sm-8">
         <center>
         <div id="carousel-example" class="carousel slide carousel-fade caro" data-ride="carousel">
          <ol class="carousel-indicators">
            <li data-target="#carousel-example" data-slide-to="0" class="active"></li>
            <li data-target="#carousel-example" data-slide-to="1"></li>
            <li data-target="#carousel-example" data-slide-to="2"></li>
            <li data-target="#carousel-example" data-slide-to="3"></li>
            <li data-target="#carousel-example" data-slide-to="4"></li>
          </ol>
          <div class="carousel-inner" role="listbox">
            <div class="carousel-item active">
              <div class="view">
                <img  class="d-block w-100" src='../assests/images/SliderImg/image1.jpg'  alt="GFG"width="500" height="400"/>
                <div class="mask rgba-black-light"></div>
              </div>
              <div class="carousel-caption">
                <h3 class="h3-responsive">राष्ट्रीय व्यावसायिक स्वास्थ्य संस्थान अहमदाबाद</h3>
                
              </div>
            </div>
            <div class="carousel-item">
              <div class="view">
                <img class="d-block w-100" src='../assests/images/SliderImg/rohcs.png'  
                  alt="Second slide"width="500" height="400"/>
                <div class="mask rgba-black-strong"></div>
              </div>
              <div class="carousel-caption">
                <h3 class="h3-responsive">क्षेत्रीय व्यावसायिक स्वास्थ्य केंद्र, बैंगलोर</h3>
                <p></p>
              </div>
            </div>
            <div class="carousel-item">
              <div class="view">
                <img class="d-block w-100" src='../assests/images/SliderImg/image4.jpg'  
                  alt="third slide" width="500" height="400"/>
                <div class="mask rgba-black-strong"></div>
              </div>
              <div class="carousel-caption">
                <h3 class="h3-responsive"> माननीय केंद्रीय स्वास्थ्य और परिवार कल्याण मंत्री द्वारा COVID-19 वैक्सीन पर स्मारक डाक टिकट का विमोचन</h3>
                <p></p>
              </div>
            </div>
            <div class="carousel-item">
              <div class="view">
                <img class="d-block w-100" src='../assests/images/SliderImg/image5.jpg'  
                  alt="Second slide" width="500" height="400"/>
                <div class="mask rgba-black-strong"></div>
              </div>
              <div class="carousel-caption">
                <h3 class="h3-responsive">स्वच्छभारत</h3>
                <p></p>
              </div>
            </div>
            <div class="carousel-item">
            <div class="view">
                <img class="d-block w-100" src='../assests/images/SliderImg/image3.jpg' 
                  alt="Third slide"width="500" height="400"/>
                <div class="mask rgba-black-slight"></div>
              </div>
              <div class="carousel-caption">
                <h3 class="h3-responsive">फिट-इंडिया</h3>
                <p></p>
              </div>
            </div>
          </div>
          <a class="carousel-control-prev" href="#carousel-example" role="button" data-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="sr-only">Previous</span>
          </a>
          <a class="carousel-control-next" href="#carousel-example" role="button" data-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="sr-only">Next</span>
          </a>
          
        </div>
          </center>
          
       </div>
     
      <div class="col-sm-4 ">
         <center>
       
          <h1 class="myheading">निदेशक से मिलें</h1>
        
         <div class="row">
          <div class="col-sm-6" style={{marginTop: "30px"}}>
              <img src="../assests/images/dic/dir_resize.jpg" class = "img-responsive" width="180px"/>
          </div>
          <div class="col-sm-6"style={{marginTop: "60px",marginLeft:"-40px"}}>
              <p class="myfont2"style={{marginBottom:"30px"}}>डॉ. शांतसबुज दास</p>
              
              <p class="myfont2" style={{textAlign: "center"}}> निदेशक</p>
          </div>
          
          </div>
           
           
          <div class="mx-auto">
            <Link to="/DirDesk"><button class="btn btn-primary temp-btn" >निदेशक संदेश</button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Link>
            <Link to="/About"><button class="btn btn-primary temp-btn">एनआईओएच के बारे में जानें</button></Link>
          </div>
       
      </center>  
       </div>
      </div>
      <div class="row mydiv1">
      <div class="col-sm-4 publ">
         <center>
           <table>
             <tr>
               <td><h1 class="myheading">संदृश्य</h1></td>
               <td><h5 class="mysubheading"> अधिक</h5> </td>
             </tr>
           </table>
           <p class="myfontCardbody">गहन  अनुसंधान  (बुनियादी/महामारी विज्ञान/स्थानांतरीय)  के  माध्यम  से  सुरक्षित कार्य के वातावरण को निर्मित कर व्यवसाय संबंधी स्वास्थ्य समस्याओं का निवारण और उन पर नियंत्रण करना,  जोखिम को कम करने के लिए उपयुक्त प्रौद्योगिकी का विकास करना और उनसे प्राप्त ज्ञान का प्रसार करना।</p>

                     </center>
          
       </div>
      <div class="col-sm-4 publ verLine" >
      
         <center>
          
         <table>
             <tr>
               <td><h1 class="myheading">मिशन</h1></td>
               <td><h5 class="mysubheading"> अधिक</h5> </td>
             </tr>
          </table>
         
                <p class="myfontCardbody" >उपर्युक्त लक्ष्य की प्राप्ति के लिए एक उत्कृष्ट अंतर्राष्ट्रीय रैंकिंग केंद्र के रूप में उभरना
<ul><li>अनुसंधान (आवश्यकता-आधारित बुनियादी/महामारी विज्ञान/स्थानांतरीय) और प्रौद्योगिकी विकास केंद्र </li><li>शैक्षणिक केंद्र </li></ul>


</p>

              
          </center>
          
          
        
       </div>
      <div class="col-sm-4 publ  verLine">
         <center>
         
         <table>
             <tr>
               <td><h1 class="myheading">अधिदेश</h1></td>
               <td><h5 class="mysubheading">अधिक पढ़ें</h5> </td>
             </tr>
          </table>
          
                <p class="myfontCardbody">"अनुसंधान, शिक्षा, सेवाएं और संबंधित गतिविधियों" द्वारा सभी व्यवसायों के श्रमिकों को "व्यावसायिक स्वास्थ्य" प्रदान करने और औद्योगिक गतिविधि संबंधी पर्यावरणीय परिवर्तनों को कम करने के लिए समर्पित।</p>


               
         
          </center>
         
        
       </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <hr class="horz"></hr>
        </div>
      </div>
      <div class="row">
        <div class="col sm-12 ">
        <center>
          <h1 class="myheading">आईसीएमआर-एनआईओएच के प्रभाग</h1>
          
            <div class="col-sm-2"></div>
            <div class="col-sm-2 ">
              <div class="card mycol">
                <img  class="myimghover1" src="../assests/images/DivImg/Health1.jpg" alt="Card image cap"/>
                  <div class="card-body">
                    <p class="myfont1">स्वास्थ्य विज्ञान</p>
                  </div>
              </div>
            </div>
            <div class="col-sm-2 ">
              <div class="card mb-3 mycol">
                <img  class="myimghover1" src="../assests/images/DivImg/bio.jpg" alt="Card image cap" />
                  <div class="card-body">
                    <p class="myfont1">	जैविक विज्ञान</p>
                  </div>
              </div>
            </div>
            <div class="col-sm-2 ">
              <div class="card mb-3 mycol">
                <img  class="myimghover1" src="../assests/images/DivImg/chem1.jpg" alt="Card image cap"/>
                  <div class="card-body">
                    <p class="myfont1">	रासायनिक विज्ञान</p>
                  </div>
              </div>
            </div>
            <div class="col-sm-2 ">
              <div class="card mb-3 mycol">
                <img  class="myimghover1" src="../assests/images/DivImg/support1.jpg" alt="Card image cap" width="223px" height="180px"/>
                  <div class="card-body">
                    <p class="myfont1">सहायक सुविधा</p>
                  </div>
              </div>
            </div>
            <div class="col-sm-2"></div>
          </center>
        </div>
        
      </div>

      <div class="row">
        <div class="col-sm-12">
          <hr class="horz"></hr>
        </div>
      </div>


      <div class="container-fluid row" style={{margin:"auto"}}>
      <div class="col-xl-4">
      <ul class="nav nav-tabs myheading3">
        <li class="active"><a data-toggle="tab" href="#fac" class="myheading3">फेसबुक</a></li>
        <li><a data-toggle="tab" href="#twitter" class="myheading3">ट्विटर</a></li>
       
      </ul>

      <div class="tab-content">
        <div id="fac" class="tab-pane active">
        <div class="facbok" style={{marginTop:"10px"}} data-width="auto">
          <center>
           <iframe
              src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Ficmrnioh&tabs=timeline&small_header=true&adapt_container_width=true&hide_cover=true&show_facepile=true&appId"
               height="550"
              allowTransparency="true" allow="encrypted-media"></iframe>
            
          </center>
        </div>
        </div>
        <div id="twitter" class="tab-pane fade">
          
          <div class="facbok" style={{marginTop:"10px"}}>
           <TwitterTimelineEmbed sourceType="profile" screenName="icmrnioh" options={{height: 550}}/>
          </div>
        </div>
        
      </div>
        
       </div>
<div class="col-xl-4 verLine" >
<ul class="nav nav-tabs myheading3">
        <li class="active"><a data-toggle="tab" href="#Circular" class="myheading3">परिपत्र</a></li>
        <li><a data-toggle="tab" href="#Career" class="myheading3">कैरियर</a></li>
        <li><a data-toggle="tab" href="#Tender" class="myheading3">निविदा</a></li>
      </ul>

      <div class="tab-content facbok" style={{overflow: "auto"}}>
        <div id="Circular" class="tab-pane active">
        
     
        <div style={{fontSize:"16px"}} >
      <p ><a href="../assests/pdf/circular/Pensionars_Hospital circuulafr.pdf" target="_blank"><img src="../assests/images/gnrImg/pdf.png" alt="Holiday 2022" width="37" height="49"/>कैशलेस योजना के तहत पेंशनरों के लिए 3 नए अस्पताल</a></p>
 <br/>
      <p ><a href="../assests/pdf/circular/CashlessScheme.pdf" target="_blank"><img src="../assests/images/gnrImg/pdf.png" alt="Holiday 2022" width="37" height="49"/>पेंशनर के लिए कैशलेस योजना के तहत 11 अस्पताल के साथ एमओए के लिए परिपत्र</a></p>
 <br/>
  <p ><a href="../assests/pdf/circular/Medical Card.pdf" target="_blank"><img src="../assests/images/gnrImg/pdf.png" alt="Holiday 2022" width="37" height="49"/>16.	आईसीएमआर-एनआईओएच के पेंशनभोगियों के लिए  मेडिकल कार्ड के बीमे के लिए परिपत्र</a></p>
 <br/>
  <p ><a href="../assests/pdf/circular/OO_Proforma_EL encashment.pdf" target="_blank"><img src="../assests/images/gnrImg/pdf.png" alt="Holiday 2022" width="37" height="49"/>आईसीएमआर-एनआईओएच के कर्मचारियों हेतु एलटीसी/गृहनगर  का लाभ लेते समय अर्जित अवकाश नकदीकरण का दावा करने के लिए परिपत्र</a></p>
 <br/>
  <p ><a href="../assests/pdf/circular/Circular for pensioner ID card.pdf" target="_blank"><img src="../assests/images/gnrImg/pdf.png" alt="Holiday 2022" width="37" height="49"/>&nbsp;&nbsp;&nbsp;पेंशनभोगियों के लिए परिचय पत्र हेतु परिपत्र</a></p>
 <br/>

    <p ><a href="../assests/pdf/circular/Circular for medical bill queries.pdf" target="_blank"><img src="../assests/images/gnrImg/pdf.png" alt="Holiday 2022" width="37" height="49"/>&nbsp;&nbsp;&nbsp;चिकित्सा बिल संबंधी पूछताछ के लिए परिपत्र</a></p>
 <br/>

</div>
            
      
       
        </div>
        <div id="Career" class="tab-pane fade">
          
        <div style={{fontSize:"16px"}} >
      <p ><a href="../assests/pdf/jobs/2022/Notification dated 23.11.2022.pdf.pdf" target="_blank"><img src="../assests/images/gnrImg/pdf.png" alt="Holiday 2022" width="37" height="49"/>विभिन्न पदों के लिए भर्ती अधिसूचना</a></p>
 <br/>
 
      <p ><a href="../assests/pdf/jobs/2022/Notification_Consultant(Admin).pdf" target="_blank"><img src="../assests/images/gnrImg/pdf.png" alt="Holiday 2022" width="37" height="49"/>सलाहकार (प्रशासन) की नियुक्ति के लिए अधिसूचना</a></p>
 <br/>
  <p ><a href="../assests/pdf/jobs/2022/SelectedCandidateRA.pdf" target="_blank"><img src="../assests/images/gnrImg/pdf.png" alt="Holiday 2022" width="37" height="49"/>एनआईओएच अहमदाबाद में कनिष्ठ अनुसंधान अध्येता और अनुसंधान सहायक के लिए वॉक-इन / इंटरव्यू के लिए चयनित उम्मीदवार</a></p>
 <br/>
  <p ><Link to="/recruitment"><img src="../assests/images/gnrImg/notify.jpg"  width="30" height="30"/>&nbsp;&nbsp;&nbsp;एनआईओएच अहमदाबाद में कनिष्ठ अनुसंधान अध्येता और अनुसंधान सहायक के लिए वॉक-इन / इंटरव्यू के लिए तिथि परिवर्तन का शुद्धिपत्र</Link></p>
 <br/>
  <p ><Link to="/recruitment"><img src="../assests/images/gnrImg/notify.jpg"  width="30" height="30"/>&nbsp;&nbsp;&nbsp;एनआईओएच अहमदाबाद में कनिष्ठ अनुसंधान अध्येता एवं अनुसंधान सहायक के लिए वॉक-इन/इंटरव्यू</Link></p>
 <br/>

    <p ><Link to="/recruitment"><img src="../assests/images/gnrImg/notify.jpg"  width="30" height="30"/>&nbsp;&nbsp;&nbsp;कनिष्ठ अनुसंधान अध्येता  - एनआईओएच अहमदाबाद</Link></p> 
 

</div>
            
     
        </div>
        <div id="Tender" class="tab-pane fade">
          
        <div style={{fontSize:"16px"}} >

  <p ><Link to="/tenderNioh"><img src="../assests/images/gnrImg/notify.jpg"  width="30" height="30"/>&nbsp;&nbsp;&nbsp;बड़े डिस्प्ले पैनल 01 सेट के साथ वीडियो कॉन्फ्रेंसिंग सिस्टम के लिए निविदा</Link></p>
 <br/>
  <p ><Link to="/tenderNioh"><img src="../assests/images/gnrImg/notify.jpg"  width="30" height="30"/>&nbsp;&nbsp;&nbsp;कंप्यूटर और उसके बाह्य उपकरणों के लिए निविदा आईडी : 2022_DoHR_667352_1 के साथ वार्षिक अनुरक्षण अनुबंध(एएमसी)</Link></p>
 <br/>
  <p ><Link to="/tenderNioh"><img src="../assests/images/gnrImg/notify.jpg"  width="30" height="30"/>&nbsp;&nbsp;&nbsp;सीपीपी पोर्टल पर निविदा आईडी :2022_DoHR_660806_1 के साथ अनुपयोगी वस्तुओं के लिए निविदा</Link></p>
 <br/>

    <p ><Link to="/tenderNioh"><img src="../assests/images/gnrImg/notify.jpg"  width="30" height="30"/>&nbsp;&nbsp;&nbsp;बायोइलेक्ट्रिकल इम्पीडेंस असेसमेंट (बीआईए) इंस्ट्रूमेंट जेम/2022/बी/889268/</Link></p>
 <br/>
 <p ><Link to="/tenderNioh"><img src="../assests/images/gnrImg/notify.jpg"  width="30" height="30"/>&nbsp;&nbsp;&nbsp;आईसीएमआर द्वारा जेम में प्रकाशित उपकरणों, वस्तुओं और सेवाओं की खरीद</Link></p>
 <br/>
 

</div>
        </div>
        
      </div>
                

   
    
  </div>
          
        
       
       <div class="col-xl-4 verLine" style={{overflow: "auto"}}>
         <center>
           <table>
             <tr>
               <td><h1 class="myheading" style={{marginTop:"25px"}}>समाचार</h1></td>
              
             </tr>
           </table>
           <hr style={{marginTop:"10px"}}/>
           <div class="news" >
           <p style={{fontSize:"18px"}}><a href="../assests/AFIH/AFIH_PROSPECTUS_NIOH_2023.pdf" target="_blank">एएफआईएच के लिए विवरण-पुस्तिका और आवेदन पत्र </a></p>
           <Link to="/edAFIH" target="_blank"><img src="../assests/images/gnrImg/AFIH.png" width="160" height="200" title="CEA"/><img src="../assests/images/gnrImg/ROHC-AFIH.png" width="160" height="200" title="CEA"/></Link><br/>

           <p style={{fontSize:"18px"}}><a href="../assests/pdf/jobs/2023/ACO English.pdf" target="_blank">प्रतिनियुक्ति के आधार पर लेखा अधिकारी और लेखा अधिकारी (कनिष्ठ) पद की रिक्ति लिए परिपत्र</a></p>
           <Link to="/recruitment" target="_blank"><img src="../assests/images/gnrImg/pdf.png" width="120" height="200" title="CEA"/></Link><br/>

           <p style={{fontSize:"18px"}}><Link to="/edDissert" target="_blank">आई सी एम आर -एन आई ओ एच शोध प्रबंध कार्यक्रम के तहत चयनित शोध प्रबंध छात्रों और उनके संकाय के मार्गदर्शकों की सूची (जनवरी-जून 2023) </Link></p>
            <a href="../assests/Dissert/Shortlisted candidates (Jan - Jun 2023).pdf" target="_blank"><img src="../assests/images/gnrImg/pdf.png" width="120" height="200" title="CEA"/></a>
        </div>

          </center>
          
       </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <hr class="horz"></hr>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-8 col-sm-offset-2 bottom">
        <center>
            <h1 class="myheading">उपयोगी कनेक्शन</h1>
            
            <Slider {...Settings}>
          <div>
            <img class="myimghover" src= "../assests/images/footerImg/footer-arpg.png"alt="footer"  height="50"/>
          </div>
          <div>
            <img class="myimghover" src= "../assests/images/footerImg/data_gov_logo.png"alt="footer"  height="60"/>
          </div>
          <div>
            <img class="myimghover" src= "../assests/images/footerImg/footer-dhr.png"alt="footer"  height="50"/>
          </div>
          
          <div>
            <img class="myimghover" src= "../assests/images/footerImg/mi2.png"alt="footer"  height="60"/>
          </div>
          <div>
            <img class="myimghover" src= "../assests/images/footerImg/pm.png"alt="footer" height="50"/>
          </div>
          <div>
            <img class="myimghover" src= "../assests/images/footerImg/who.png"alt="footer"  height="60"/>
          </div>
          <div>
            <img class="myimghover" src= "../assests/images/footerImg/icmr.png"alt="footer"  height="50"/>
          </div>
        </Slider>
        </center>
        </div>
        
        
      </div>
      <div class="my-footer">
      <center>
          <h1 class="myheading1">हमारे साथ जुड़ें</h1>
      </center>
      
  <div class="row">
  <div class="col-lg-2">
          </div>
    <div class="col-lg-2">
      <p class="my-footer-head">साइट मैप</p>
      <p class="my-footer-text">घर</p>
      <Link to="/about"><p class="my-footer-text">संस्थान के बारे में</p></Link>
      <Link to="/division"><p class="my-footer-text">विभाजन</p></Link>
      <Link to="/"><p class="my-footer-text">सम्मेलन</p></Link>
      <Link to="/recruitment"><p class="my-footer-text">प्लेसमेंट</p></Link>
    </div>
    <div class="col-lg-2">
      <p class="my-footer-head">त्वरित लिंक्स</p>
     
      <Link to="/rti"><p class="my-footer-text">RTI</p></Link>
      <p class="my-footer-text">गेस्ट हाउस</p>
      <p class="my-footer-text">नागरिक चार्टर</p>
      <p class="my-footer-text">जन शिकायत</p>
    </div>
    <div class="col-lg-2">
    
      <p class="my-footer-head">बाह्य लिंक्स</p>
      <a href="https://www.icmr.gov.in/" target="_blank"><p class="my-footer-text">आईसीएमआर</p></a>
      <a href="https://email.gov.in/"target="_blank"><p class="my-footer-text">ईमेल</p></a>
      <p class="my-footer-text">एनआईओएच इंट्रानेट</p>
      <p class="my-footer-text">गैलरी</p>
    </div>
    <div class="col-lg-3">
          
    <p class="my-footer-head">Youtube वीडियो</p>
       <iframe width="300" height="160" src="https://www.youtube.com/embed/MOn95iyELUI" frameborder="0" allowfullscreen></iframe>
       
    </div>
    <div class="col-lg-1">
          </div>
  </div>

<p align='center' class="mycopy">वेबसाइट को सूचना प्रौद्योगिकी प्रभाग, आईसीएमआर-एनआईओएच अहमदाबाद में डिजाइन, विकसित और अनुरक्षित किया गया ह</p>   

      </div>
    </div>
    </div>
    );
  }
} 
 
// Exporting the component
export default IndexHindi;