import React from 'react';
import './carousel.css';
import {  Link } from "react-router-dom";
import NavbarMenu from './NavbarMenu.js';
function TenderNioh () {
    return (<div> <NavbarMenu /><div class="manual">
        <div class="row container">
        <p class="mypathhead">
        <Link to="/"><span class="mypath">Home</span></Link>&gt;<span class="mypath">Notification</span>&gt;<span class="mypath">Tender</span>
        </p>
          
        </div>
        <h1 class="mypathhead">Tender</h1>
       
        <div class="table-responsive-sm" style={{padding:"10px"}}>
            <center>
                <table width="90%"style={{fontSize:"18px"}} class="table table-bordered">
                <tr>
                    <th width="20%" style={{color:"white", backgroundColor: "#005A9C"}}><strong>Tender Name</strong></th>
                    <th width="10%" style={{color:"white", backgroundColor: "#005A9C"}}><strong>End Date </strong></th>
                    <th width="10%" style={{color:"white", backgroundColor: "#005A9C"}}><strong>Start Date</strong></th>
                    <th width="15%" style={{color:"white", backgroundColor: "#005A9C"}}><strong>Opening   Date &amp; Place</strong></th>
                    <th width="15%" style={{color:"white", backgroundColor: "#005A9C"}}><strong>Downloads </strong></th>
                    
                </tr>

                <tr>
                    <td valign="top"><p>High Performance Liquid Chromatography (HPCL) system with Electrochemical Detector (ECD) GEM/2024/B/5281926 </p></td>
                    <td valign="top">02/09/2024</td>
                    <td valign="top">12/08/2024</td>
                    <td valign="top">02/09/2024</td>
                    <td valign="top"><p><a href="../assests/pdf/tender/2024/GeM-Bidding-6773972.pdf" target="_blank">Details</a>  </p>
                    </td>
                </tr>
                <tr>
                    <td valign="top"><p>Freeze Dryer / Lyophilizer GEM/2024/B/5281832 </p></td>
                    <td valign="top">02/09/2024</td>
                    <td valign="top">12/08/2024</td>
                    <td valign="top">02/09/2024</td>
                    <td valign="top"><p><a href="../assests/pdf/tender/2024/GeM-Bidding-6773872.pdf" target="_blank">Details</a>  </p>
                    </td>
                </tr>
                <tr>
                    <td valign="top"><p>Cryo-Microtome GEM/2024/B/5281620 </p></td>
                    <td valign="top">02/09/2024</td>
                    <td valign="top">12/08/2024</td>
                    <td valign="top">02/09/2024</td>
                    <td valign="top"><p><a href="../assests/pdf/tender/2024/GeM-Bidding-6773644.pdf" target="_blank">Details</a>  </p>
                    </td>
                </tr>
                <tr>
                    <td valign="top"><p>Ultraviolet / Visible Spectrophotometer GEM/2024/B/5280606 </p></td>
                    <td valign="top">02/09/2024</td>
                    <td valign="top">12/08/2024</td>
                    <td valign="top">02/09/2024</td>
                    <td valign="top"><p><a href="../assests/pdf/tender/2024/GeM-Bidding-6772511.pdf" target="_blank">Details</a>  </p>
                    </td>
                </tr>
                <tr>
                    <td valign="top"><p>Professional Large Format Display GEM/2024/B/5259839 </p></td>
                    <td valign="top">28/08/2024</td>
                    <td valign="top">07/08/2024</td>
                    <td valign="top">28/08/2024</td>
                    <td valign="top"><p><a href="../assests/pdf/tender/2024/GeM-Bidding-6749687.pdf" target="_blank">Details</a>  </p>
                    </td>
                </tr>

                <tr>
                    <td valign="top"><p>Perosnal Dust Sampler GEM/2024/B/5012086 </p></td>
                    <td valign="top">25/06/2024</td>
                    <td valign="top">04/06/2024</td>
                    <td valign="top">25/06/2024</td>
                    <td valign="top"><p><a href="../assests/pdf/tender/2024/GEM/2024/B/5012086.pdf" target="_blank">Details</a>  </p>
                    </td>

                </tr>

                <tr>
                    <td valign="top"><p>Supply of Computer systems and laptop GEM/2024/B/4991408 </p></td>
                    <td valign="top">19/06/2024</td>
                    <td valign="top">29/05/2024</td>
                    <td valign="top">19/06/2024</td>
                    <td valign="top"><p><a href="../assests/pdf/tender/2024/GeM-Bidding-6454848.pdf" target="_blank">Details</a>  </p>
                    </td>

                </tr>
                <tr>
                    <td valign="top"><p>Server and Switch GEM/2024/B/4870821</p></td>
                    <td valign="top">06/06/2024</td>
                    <td valign="top">29/4/2024</td>
                    <td valign="top">06/06/2024</td>
                    <td valign="top"><p><a href="../assests/pdf/tender/2024/GeM-Bidding-6322595.pdf" target="_blank">Details</a>  </p>
                    </td>

                </tr>
                <tr>
                    <td valign="top"><p>Nanobiospectrophotometer – Qty – 02 GEM-2023/B/4001868</p></td>
                    <td valign="top">23/09/2023</td>
                    <td valign="top">03/10/2023</td>
                    <td valign="top">03/10/2023,4:00 PM</td>
                    <td valign="top"><p><a href="../assests/pdf/tender/2023/GeM_Bid_Nanobio.pdf" target="_blank">Details</a>  </p>
                    </td>

                </tr>
                <tr>
                    <td valign="top"><p>Active-Passive Avoidance System – Qty - 01 GEM/2023/B/4001599</p>
                   </td>
                    <td valign="top">23/09/2023</td>
                    <td valign="top">03/10/2023</td>
                    <td valign="top">03/10/2023,3:00 PM</td>
                    <td valign="top"><p><a href="../assests/pdf/tender/2023/Active_Passive.pdf" target="_blank">Details</a>  </p>
                    </td>

                </tr>
                <tr>
                    <td valign="top"><p>Veterinary Hematology Analyser GEM/2023/B/4001539</p>
                   </td>
                    <td valign="top">23/09/2023</td>
                    <td valign="top">03/10/2023</td>
                    <td valign="top">03/10/2023,2:00 PM</td>
                    <td valign="top"><p><a href="../assests/pdf/tender/2023/Veterinary_Hematology_Analyser.pdf" target="_blank">Details</a>  </p>
                    </td>

                </tr>
                <tr>
                    <td valign="top"><p>Stereotaxic apparatus with accessories e.g. digital stereotaxic injector and micro motor drill- Qty -01  GEM/2023/B/4001858</p>
                   </td>
                    <td valign="top">23/09/2023</td>
                    <td valign="top">03/10/2023</td>
                    <td valign="top">03/10/2023,5:00 PM</td>
                    <td valign="top"><p><a href="../assests/pdf/tender/2023/stereotaxic27092023.pdf" target="_blank">Details</a>  </p>
                    </td>

                </tr>

                <tr>
                    <td valign="top"><p>Limited Tender Enquiry under 162 of GFR-2017 for Stainless Steel Washing Tank</p>
                   </td>
                    <td valign="top"></td>
                    <td valign="top"></td>
                    <td valign="top"></td>
                    <td valign="top"><p><a href="../assests/pdf/tender/2023/Tender25082023.pdf" target="_blank">Details</a>  </p>
                    </td>

                </tr>
                <tr>
                    <td valign="top"><p>Tender for Rate Contract for Lab chemicals on CPP portal having Tender ID : 2022_DoHR_676957_1</p>
                   </td>
                    <td valign="top">26/09/2022, 06:00 PM</td>
                    <td valign="top">06/09/2022</td>
                    <td valign="top">27/09/2022, 11:30 AM</td>
                    <td valign="top"><p>[search with tender ID] </p><p><a href="https://eprocure.gov.in/epublish/app">CPP Portal Link</a>  </p>
                    </td>

                </tr>
                <tr>
                    <td valign="top"><p>Tender for Video Conferencing system with large display panel 01 set</p>
                    <p><a href="../assests/pdf/tender/2022/VC_NIT.pdf" target="_blank">[Tender-Notice]</a></p>
                    <p><a href="../assests/pdf/tender/2022/VC_Tender_document.pdf" target="_blank">[Tender-Document]</a></p>
                    <p><a href="../assests/pdf/tender/2022/corrigendum.pdf" target="_blank">[Corrigendum]</a></p></td>
                    <td valign="top">24-08-2022,5:30 P.M.</td>
                    <td valign="top">27-07-2022</td>
                    <td valign="top">25-08-2022,11:00A.M.</td>
                    <td valign="top"><p><a href="../assests/pdf/tender/2022/ePublishing SystemVC.pdf" target="_blank">[Tender-Details]</a></p>
                    <p><a href="https://eprocure.gov.in/epublish/app?component=view&page=EpublishPublishedTenderList&service=direct&session=T&sp=SZ6ql4Gqw1l1TWQO0%2BbFhy0ImZ1ELvJqIJ%2BtZJ9LDlwfUtBXnO8FLjctWBV8qknqx" target="_blank">CPP Portal Link</a></p>
                    <p><a href="../assests/pdf/tender/2022/corrigendum_VIT.pdf" target="_blank">Corrigendum</a></p></td>

                </tr>
                <tr>
                    <td valign="top"><p>AMC for Computer and its peripherals with Tender ID : 2022_DoHR_667352_1</p>
                    <p><a href="../assests/pdf/tender/2022/Corrigendum_due_date.pdf" target="_blank">[Corrigendum-Extension of due date]</a></p></td>
                    <td valign="top">28-07-2022 at 5:30 P.M.</td>
                    <td valign="top">--</td>
                    <td valign="top">--</td>
                    <td valign="top"><a href="https://eprocure.gov.in/epublish/app?component=view&page=EpublishPublishedTenderList&service=direct&session=T&sp=SuQrPzVRXgsJtOqQNAgDitkw%2BEuNNJULncQuUr1KXZRDUtBXnO8FLjctWBV8qknqx" target="_blank">Details</a></td>
                </tr>
                <tr>
                    <td valign="top"><p>Tender for condemnation on CPP portal with Tender ID : 2022_DoHR_660806_1</p>
                    <p><a href="../assests/pdf/tender/2022/NIT.pdf" target="_blank">[Details]</a></p></td>
                    <td valign="top">08-06-2022 at 5:30 P.M.</td>
                    <td valign="top">&nbsp;</td>
                    <td valign="top">09-06-2022 at 10:30 A.M.</td>
                    <td valign="top"><a href="../assests/pdf/tender/2022/Tender document.pdf" target="_blank">Work and Items Covered under Dismantling & Disposal</a></td>
                </tr>
                <tr>
                    <td valign="top"><p>Bioelectrical Impedence Assessment(BIA) Instrument GeM/2022/B/889268</p></td>
                    <td valign="top">09-03-2022</td>
                    <td valign="top">&nbsp;</td>
                    <td valign="top">24-01-2022</td>
                    <td valign="top"><a href="../assests/pdf/tender/2022/CorrigendumBIA.pdf" target="_blank"><i class="fa fa-file-pdf-o" style={{fontSize:"48px",color:"red"}}></i>&nbsp;&nbsp;&nbsp;Details</a></td>
                </tr>
                <tr>
                    <td valign="top"><p>Procurement of Equipement, goods and services published at GeM by ICMR</p></td>
                    <td valign="top">&nbsp;</td>
                    <td valign="top">&nbsp;</td>
                    <td valign="top">&nbsp;</td>
                    <td valign="top"><a href="../assests/pdf/tender/2022//ICMR_Gem_Bids.pdf" target="_blank"><i class="fa fa-file-pdf-o" style={{fontSize:"48px",color:"red"}}></i>&nbsp;&nbsp;&nbsp;Details</a></td>

                </tr>
                <tr>
                    <td valign="top"><p><strong>AMC for Computer and its peripherals</strong></p>
                    <p>[Tender ID 2021_DoHR_637219_1]</p>
                    <p><a href="pdf/tender/2022/corrigendum_amc_computer.pdf">[Corrigendum-Extension of due date]</a></p>
                    <p><a href="pdf/tender/2022/corrigendum_cancellation.pdf">[Corrigendum-Cancellation]</a></p></td>
                    <td valign="top">Extended: 10-Jan-2022 05:30 PM</td>
                    <td valign="top">10-Dec-2021 03:20 PM</td>
                    <td valign="top">11-Jan-2022 11:30 AM</td>
                    <td valign="top"><a href="https://eprocure.gov.in/epublish/app?component=%24DirectLink&page=FrontEndLatestActiveTenders&service=direct&session=T&sp=S3iDv9ngzFlrt8J2t%2FPl%2FuA%3D%3D" title="AMC for Computer and its peripherals" target="_blank">&lt;&lt;Tender Link&gt;&gt;</a></td>
                </tr>
                </table>
            </center>
        
        </div>
        
        </div>
        
      
        </div>);
}
export default TenderNioh;