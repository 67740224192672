import React from 'react';
import '../carousel.css';
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import NavbarMenu from '../NavbarMenu.js';
function DrPankaj() {
    return (
      <div> <NavbarMenu /><div class="manual">
        <div class="row container">
        <p class="mypathhead">
        <Link to="/"><span class="mypath">Home</span></Link>&gt;<Link to="/ScientistNIOH"><span class="mypath">Scientist's Profile</span></Link>&gt;<span class="mypath">NIOH Ahmedabad</span>
        </p>
          
        </div>
        <h1 class="mypathhead">Scientist's Detail</h1>
        <div class="scientistdetail">
      
            <div class="panel my-panel" >
      <div class="panel-heading my-panel-head" align="left"><strong>Basic Details</strong></div>
      <div class="panel-body" align="left">
      <table width="100%">
        <thead>
          <th width="28%"></th>
          <th width="50%"></th>
          <th width="22%"></th>
        </thead>
        <tbody>
          <tr>
              <td  rowspan="5"><img src="../assests/images/niohScientist/pbarfal.png" width="170px" height="170px" CLASS="dg-img"/></td>
              <td  ><p class="myfontSci trSci"> Mr. Pankaj Barfal</p> </td>
              
            </tr>
            
            <tr>
              
              <td class="myfontSci"><p class="myfontSci">Biological sciences Division</p></td>
              <td></td>
             </tr>
            <tr>
              <td><p ><a href=""><i class="fa fa-envelope" aria-hidden="true"></i>   Email: barfal.p@gov.in</a></p></td>
              <td></td>
            </tr> 
            <tr>
              <td><p ><a href=""> <i class="fa fa-phone-square"></i>  Contact: 079-22688848</a></p></td>
              <td></td>
            </tr>   
        </tbody>
           
            
            </table>
      </div>
    </div>
       
        <div class="panel my-panel" >
      <div class="panel-heading my-panel-head" align="left"><strong>Academic Qualification</strong></div>
      <div class="panel-body" align="left">M.Tech Biotechnology,M.Sc. Microbiology
</div>
    </div>
    <div class="panel my-panel" >
      <div class="panel-heading my-panel-head" align="left"><strong>Research Interest </strong></div>
      <div class="panel-body" align="left"> <ul><li>Bioaerosol monitoring; Zoonotic diseases; AMR; </li><li>Bioremediation, Microbial genetic engineering; Molecular microbiology</li></ul></div>
    </div>
    <div class="panel my-panel" >
      <div class="panel-heading my-panel-head" align="left"><strong>Membership/Affliation </strong></div>
      <div class="panel-body" align="left">
      <ul>
	  <li>Life member - Biotech Research Society of India (BRSI)</li>
 <li>Life member - Association of Microbiologist of India (AMI)</li>
 <li>Life member- Indian Immunology Society (IIS)</li>
 <li>Associate member- Indian Association Occupational Health (IAOH)

</li>
</ul>
</div>
</div>
<div class="panel my-panel">
      <div class="panel-heading my-panel-head" align="left"><strong>Fellowship/Awards </strong></div>
      <div class="panel-body" align="left">NIL
</div>
</div>
<div class="panel my-panel">
      <div class="panel-heading my-panel-head" align="left"><strong>Publications </strong></div>
      <div class="panel-body" align="left">
	
      <ol>

      <li>C Thangadurai, Suthakaran, <strong>P Barfal</strong>, B Anandaraj, SN Pradhan, HK Boneya, S Ramalingam and V Murugan; Rare codon priority and its position specificity at the 5 of the gene modulates heterologous protein expression in Escherichia coli. BioChemical and Biophysical Research Communications (BBRC) 376 (2008) 647–652
</li>
<li>Rajendra Palkhade1*, <strong>Pankaj Barfal2</strong>, Suresh Yadav3, Awandkar S.P.4 and Avinash Pagdhune5; Epididymo-Orchitis in a Veterinary Physician: A Case of Occupational Brucellosis. Journal of Foodborne and Zoonotic Diseases | January-March, 2018 | Volume 06 | Issue 01 | Pages 05-07 © 2018 Jakraya
</li>

</ol>
	
	
	
</div>
</div>
</div>
        </div>
    </div>
    );
}
export default DrPankaj;