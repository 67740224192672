import React from 'react';
import './carousel.css';
import {  Link } from "react-router-dom";
import NavbarMenu from './NavbarMenu.js';
function Orgorgano () {
    return (<div> <NavbarMenu /><div class="manual">
        <div class="row container">
        <p class="mypathhead">
        <Link to="/"><span class="mypath">Home</span></Link>&gt;<span class="mypath">The Institute</span>&gt;<span class="mypath">Organogram</span>
        </p>
          
        </div>
        <h1 class="mypathhead">Organogram</h1>
        <center>
            <img src='../assests/images/gnrImg/hh.png'   usemap="#planetmap"></img>
        </center>
        
        </div></div>);
}
export default Orgorgano;