import React, {useState} from 'react';
import { BrowserRouter, Routes, Route, Link, NavLink} from "react-router-dom";
import './App.css';
import { Button, Navbar, Nav, NavItem, NavDropdown, Dropdown } from 'react-bootstrap';
function ShortMenu () {
  const [showMenu, setShowMenu] = useState(false)
    function myFunction(){
        var x = document.getElementById("topnav");
        if (x.className === "container-fluid topnav") {
            x.className += " responsive";
          } 
        
 }     
    return (<nav className="navbar navbar-expand navbar-light"  style={{backgroundColor:"#eef4fa",maxHeight:"0px"}}>
   
 

    <div className="collapse navbar-collapse" id="navbarNavDropdown" >
      <ul className="navbar-nav">
        <li className="nav-item shortMenu">
        <a className="nav-link" >
          <Link to="/">Home</Link>
          </a>
        </li>
        <li className="nav-item shortMenu" >
          <a className="nav-link" >
          <Link to="/PhotoGallery">Gallery</Link>
          </a>
        </li>
        
        <li className="nav-item dropdown" style={{fontSize:"16px",fontWeight:"bold",marginTop:"8px"}} >
          <p className="nav-link dropdown-toggle"
            id="navbarDropdownMenuLink"
            data-toggle="dropdown"
            aria-haspopup="false"
            aria-expanded="false">
            <Link to="/">Language</Link>
          </p>
          <div className="dropdown-menu shortMenu"aria-labelledby="navbarDropdownMenuLink">
            <a className="dropdown-item" href="#">
            <Link to="/">English</Link>
            </a>
            <a className="dropdown-item" href="#">
            <Link to="/indexHindi">Hindi</Link>
            </a>
           
          </div>
        </li>
      </ul>
    </div>
  </nav>);
}
export default ShortMenu;