import React from 'react';
import '../carousel.css';
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import NavbarMenu from '../NavbarMenu.js';
function DrKuldip() {
    return (
      <div> <NavbarMenu /><div class="manual">
        <div class="row container">
        <p class="mypathhead">
        <Link to="/"><span class="mypath">Home</span></Link>&gt;<Link to="/ScientistNIOH"><span class="mypath">Scientist's Profile</span></Link>&gt;<span class="mypath">NIOH Ahmedabad</span>
        </p>
          
        </div>
        <h1 class="mypathhead">Scientist's Detail</h1>
        <div class="scientistdetail">
      
            <div class="panel my-panel" >
      <div class="panel-heading my-panel-head" align="left"><strong>Basic Details</strong></div>
      <div class="panel-body" align="left">
      <table width="100%">
        <thead>
          <th width="28%"></th>
          <th width="50%"></th>
          <th width="22%"></th>
        </thead>
        <tbody>
          <tr>
              <td  rowspan="5"><img src="../assests/images/niohScientist/kuldipU.jpg" width="170px" height="170px" className="dg-img"/></td>
              <td  ><p className="myfontSci trSci"> Dr Kuldip Upadhyay</p> </td>
             
            </tr>
            
            <tr>
              
              <td className="myfontSci"><p className="myfontSci">Chemical sciences Division </p></td>
              <td></td>
             </tr>
            <tr>
              <td><p ><a href=""><i className="fa fa-envelope" aria-hidden="true"></i>   Email: upadhyay.kul@icmr.gov.in</a></p></td>
              <td></td>
            </tr> 
            <tr>
              <td><p ><a href=""> <i className="fa fa-phone-square"></i>  Contact: 079-22688868</a></p></td>
              <td></td>
            </tr>   
        </tbody>
           
            
            </table>
      </div>
    </div>
       
        <div class="panel my-panel" >
      <div class="panel-heading my-panel-head" align="left"><strong>Academic Qualification</strong></div>
      <div class="panel-body" align="left">Ph. D. (Chemistry)
</div>
    </div>
    <div class="panel my-panel" >
      <div class="panel-heading my-panel-head" align="left"><strong>Research Interest </strong></div>
      <div class="panel-body" align="left"> Heavy metal analysis and its associated Health risk assessment</div>
    </div>
    <div class="panel my-panel" >
      <div class="panel-heading my-panel-head" align="left"><strong>Membership/Affliation </strong></div>
      <div class="panel-body" align="left">
      <ul><li>Indian Science Congress (Life member)</li><li>Indian Association of Occupational Health (Life member)</li>
</ul>
</div>
</div>
<div class="panel my-panel">
      <div class="panel-heading my-panel-head" align="left"><strong>Fellowship/Awards </strong></div>
      <div class="panel-body" align="left">ICMR-NIOH annual award for the year 2019-20.
</div>
</div>
<div class="panel my-panel">
      <div class="panel-heading my-panel-head" align="left"><strong>Publications </strong></div>
      <div class="panel-body" align="left">
	
      <ol>

      <li>	<strong>Upadhyay, K.</strong>, Viramgami, A., Bagepally, B. S., & Balachandar, R. (2022). Association between blood lead levels and markers of calcium homeostasis: a systematic review and meta-analysis. Sci Rep, 12(1), 1850. https://doi.org/10.1038/s41598-022-05976-4
</li><br/><li>	<strong>Upadhyay, K.</strong>, Viramgami, A., Pagdhune, A., Balachandar, R., & Sarkar, K. (2021). Hematological and cardiovascular effects of chronic low level lead exposure: A study on e-waste recyclers. Clinical Epidemiology and Global Health, 9, 269-274. https://doi.org/https://doi.org/10.1016/j.cegh.2020.09.009
</li><br/><li>	Viramgami, A., <strong>Upadhyay, K.</strong>, & Balachandar, R. (2020). Catastrophic Health expenditure and Health facility access among rural informal sector families. Clinical Epidemiology and Global Health, 8(4), 1325-1329. https://doi.org/https://doi.org/10.1016/j.cegh.2020.05.005
</li><br/><li>	<strong>Upadhyay, K.</strong> D., & Shah, A. K. (2019). Evaluation of Pyrano[3,2 C] Quinoline Analogues as Anticancer Agents. Anticancer Agents Med Chem, 19(10), 1285-1292. https://doi.org/10.2174/1871520619666190308122734
</li><br/><li>	<strong>Upadhyay, K.</strong> D., Dodia, N. M., Khunt, R. C., Chaniara, R. S., & Shah, A. K. (2019). Evaluation and in vivo efficacy study of pyrano[3,2-c]quinoline analogues as TNF-α inhibitors. Chem Biol Drug Des, 94(3), 1647-1655. https://doi.org/10.1111/cbdd.13566
</li><br/><li>	<strong>Upadhyay, K.</strong>, & Viramgami, A. (2019). Specific Enzymatic Activity of Ceruloplasmin as a Potential Indicator of Copper Status. Current Chemical Biology, 13(3), 250-256. https://doi.org/http://dx.doi.org/10.2174/2212796813666190903115853
</li><br/><li>	Manvar, A., <strong>Upadhyay, K.</strong>, Patel, R. G., & Shah, A. (2014). Targeting Tuberculosis Through Diversity Oriented Synthesis (DOS) of Hydrazide Frameworks and Evaluation of Mycobacterium Activity Thereof. Current Organic Chemistry, 18(20), 2646-2651. https://doi.org/http://dx.doi.org/10.2174/138527281820141028105256
</li><br/><li>	Chaniyara, R., Thakrar, S., Kakadiya, R., Marvania, B., Detroja, D., Vekariya, N., <strong>Upadhyay, K.</strong>, Manvar, A., & Shah, A. (2014). DBU-catalyzed Multicomponent Synthesis: Facile Access of 4,5,6,9-Tetrahydro-pyrido[3,2-c]quinolines. Journal of Heterocyclic Chemistry, 51(2), 466-474. https://doi.org/https://doi.org/10.1002/jhet.1662
</li><br/><li>	<strong>Upadhyay, K.</strong>, Manvar, A., Virsodiya, V., Trivedi, J., Chaniyara, R., Shah, A., Giliberti, G., Secci, B., Busonera, B., Sanna, G., Loddo, R., & Colla, P. L. (2013). Erratum to: Syntheses and in vitro Biological screening of 1-aryl-10H-[1,2,4]triazolo[3′,4′:3,4][1,2,4]triazino[5,6-b]indoles. Medicinal Chemistry Research, 22(8), 3687-3687. https://doi.org/10.1007/s00044-012-0422-2
</li><br/><li>	<strong>Kuldip, U.</strong>, Atul, M., Ravi, C., Chetna, R., Kena, R., & Hardevsinh Vand Anamik, S. (2013). Synthesis and antitubercular activity of 1, 3, 4-oxadiazoles clubbed with pyrroles. Chem Biol Interface, 3(2), 107-115.  
</li><br/><li>	<strong>Upadhyay, K.</strong>, Manvar, A., Rawal, K., Joshi, S., Trivedi, J., Chaniyara, R., & Shah, A. (2012). Evaluation of structurally diverse benzoazepines clubbed with coumarins as Mycobacterium tuberculosis agents. Chem Biol Drug Des, 80(6), 1003-1008. https://doi.org/10.1111/j.1747-0285.2012.01436.x
</li><br/><li>	<strong>Upadhyay, K.</strong>, Bavishi, A., Thakrar, S., Radadiya, A., Vala, H., Parekh, S., Bhavsar, D., Savant, M., Parmar, M., Adlakha, P., & Shah, A. (2011). Synthesis and Biological evaluation of 4-styrylcoumarin derivatives as inhibitors of TNF-α and IL-6 with anti-tubercular activity. Bioorg Med Chem Lett, 21(8), 2547-2549. https://doi.org/10.1016/j.bmcl.2011.02.016
</li><br/><li>	Virsdoia, V., Shaikh, M. S., Manvar, A., Desai, B., Parecha, A., Loriya, R., Dholariya, K., Patel, G., Vora, V., <strong>Upadhyay, K.</strong>, Denish, K., Shah, A., & Coutinho, E. C. (2010). Screening for in vitro antimycobacterial activity and three-dimensional quantitative structure-activity relationship (3D-QSAR) study of 4-(arylamino)coumarin derivatives. Chem Biol Drug Des, 76(5), 412-424. https://doi.org/10.1111/j.1747-0285.2010.00997.x
</li><br/><li>	Manvar, A. T., Pissurlenkar, R. R., Virsodia, V. R., <strong>Upadhyay, K.</strong> D., Manvar, D. R., Mishra, A. K., Acharya, H. D., Parecha, A. R., Dholakia, C. D., Shah, A. K., & Coutinho, E. C. (2010). Synthesis, in vitro antitubercular activity and 3D-QSAR study of 1,4-dihydropyridines. Mol Divers, 14(2), 285-305. https://doi.org/10.1007/s11030-009-9162-8
</li><br/><li>	Virsodia, V., Manvar, A., <strong>Upadhyay, K.</strong>, Loriya, R., Karia, D., Jaggi, M., Singh, A., Mukherjee, R., Shaikh, M. S., Coutinho, E. C., & Shah, A. (2009). Synthesis of 1-(2,6-dichlorophenyl)-3-methylene-1,3-dihydro-indol-2-one derivatives and in vitro anticancer evaluation against SW620 colon cancer cell line. Eur J Med Chem, 44(3), 1355-1362. https://doi.org/10.1016/j.ejmech.2008.01.012
</li><br/><li>	Manvar, A., Malde, A., Verma, J., Virsodia, V., Mishra, A., <strong>Upadhyay, K.</strong>, Acharya, H., Coutinho, E., & Shah, A. (2008). Synthesis, anti-tubercular activity and 3D-QSAR study of coumarin-4-acetic acid benzylidene hydrazides. Eur J Med Chem, 43(11), 2395-2403. https://doi.org/10.1016/j.ejmech.2008.01.016
</li><br/><li>	Bariwal, J. B., <strong>Upadhyay, K.</strong> D., Manvar, A. T., Trivedi, J. C., Singh, J. S., Jain, K. S., & Shah, A. K. (2008). 1,5-Benzothiazepine, a versatile pharmacophore: a review. Eur J Med Chem, 43(11), 2279-2290. https://doi.org/10.1016/j.ejmech.2008.05.035
</li><br/><li>	Trivedi, J. C., Bariwal, J. B., <strong>Upadhyay, K.</strong> D., Naliapara, Y. T., Joshi, S. K., Pannecouque, C. C., De Clercq, E., & Shah, A. K. (2007). Improved and rapid synthesis of new coumarinyl chalcone derivatives and their antiviral activity. Tetrahedron Letters, 48(48), 8472-8474.https://doi.org/https://doi.org/10.1016/j.tetlet.2007.09.175
</li><br/><li>	Naveen, S., ADLAKHA, P., <strong>Upadhyay, K.</strong>, SHAH, A., ANANDALWAR, S. M., & Prasad, J. S. (2006). Crystal structure of 3-nitro-4-hydroxycoumarin. Analytical Sciences: X-ray Structure Analysis Online, 22, x103-x104.
</li>
</ol>
	
</div>
	
</div>
</div>

        </div>
    </div>
    );
}
export default DrKuldip;