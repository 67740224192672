import React from 'react';
import './carousel.css';
import NavbarMenu from './NavbarMenu.js';
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
function Otheradmin () {
    return (<div> <NavbarMenu /><div class="manual">
    <div class="row container">
    <p class="mypathhead">
    <Link to="/"><span class="mypath">Home</span></Link>&gt;<span class="mypath">NIOH Staff</span>&gt;<span class="mypath">Administrative Staff</span>
    </p>
      
    </div>
    <h1 class="mypathhead">Administrative Staff-NIOH Ahmedabad</h1>
    <div class="row row1">
        <div class="col-md-4">
            <div class="panel panel-primary">
            <div class="panel-body">
                <div class="row">
                    <div class="col-md-4">
                        <img src='../assests/images/AdminStaffImg/man.png' width="130px" height="160px;"></img>
                        
                    </div>
                    
                    <div class="col-md-8 carddata">
                        <table>
                            <tr>
                                <td width="20%"><button class="mybutton-top" ><i class="fa fa-user fa-1x" aria-hidden="true"></i></button></td>
                                <td WIDTH="95%" CLASS="myfontCard">Hemlata Rawat</td>
                            </tr>
                            <tr>
                                <td width="20%"></td>
                                <td WIDTH="95%"CLASS="myfontCardbody">Library Clerk</td>
                            </tr>
                            <tr>
                                <td width="10%"></td>
                                <td WIDTH="95%"CLASS="myfontCardbody"></td>
                            </tr>
                            <tr>
                                <td width="20%"><button class="mybutton-top" ><i class="fa fa-history fa-1x" aria-hidden="true"></i></button></td>
                                <td WIDTH="95%" CLASS="myfontCardbody">Yrs Experience</td>
                            </tr>
                            <tr>
                                <td width="20%"><button class="mybutton-top" ><i class="fa fa-envelope fa-1x" aria-hidden="true"></i></button></td>
                                <td WIDTH="95%" CLASS="myfontCardbody">rawat.hl@icmr.gov.in </td>
                            </tr>
                            
                        </table>
                    </div>
                </div>
            </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="panel panel-primary">
            <div class="panel-body">
            <div class="row">
                    <div class="col-md-4">
                        <img src='../assests/images/AdminStaffImg/man.png' width="135px" height="160px;"></img>
                        
                    </div>
                    
                    <div class="col-md-8 carddata">
                        <table>
                            <tr>
                                <td width="20%"><button class="mybutton-top" ><i class="fa fa-user fa-1x" aria-hidden="true"></i></button></td>
                                <td WIDTH="95%" CLASS="myfontCard">Y K Gajjar</td>
                            </tr>
                            <tr>
                                <td width="20%"></td>
                                <td WIDTH="95%"CLASS="myfontCardbody">Technician-2 (Electrician)</td>
                            </tr>
                            <tr>
                                <td width="10%"></td>
                                <td WIDTH="95%"CLASS="myfontCardbody"></td>
                            </tr>
                            <tr>
                                <td width="20%"><button class="mybutton-top" ><i class="fa fa-history fa-1x" aria-hidden="true"></i></button></td>
                                <td WIDTH="95%" CLASS="myfontCardbody"> Yrs Experience</td>
                            </tr>
                            <tr>
                                <td width="20%"><button class="mybutton-top" ><i class="fa fa-envelope fa-1x" aria-hidden="true"></i></button></td>
                                <td WIDTH="95%" CLASS="myfontCardbody">gajjar.yk@icmr.gov.in</td>
                            </tr>
                            
                        </table>
                    </div>
                </div>
            </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="panel panel-primary">
            <div class="panel-body">
            <div class="row">
                    <div class="col-md-4">
                        <img src='../assests/images/AdminStaffImg/man.png' width="135px" height="160px;"></img>
                        
                    </div>
                    
                    <div class="col-md-8 carddata">
                        <table>
                            <tr>
                                <td width="20%"><button class="mybutton-top" ><i class="fa fa-user fa-1x" aria-hidden="true"></i></button></td>
                                <td WIDTH="95%" CLASS="myfontCard">V K Rathod</td>
                            </tr>
                            <tr>
                                <td width="20%"></td>
                                <td WIDTH="95%"CLASS="myfontCardbody">Tech-A (Plumber)</td>
                            </tr>
                            <tr>
                                <td width="10%"></td>
                                <td WIDTH="95%"CLASS="myfontCardbody"></td>
                            </tr>
                            <tr>
                                <td width="20%"><button class="mybutton-top" ><i class="fa fa-history fa-1x" aria-hidden="true"></i></button></td>
                                <td WIDTH="95%" CLASS="myfontCardbody"> Yrs Experience</td>
                            </tr>
                            <tr>
                                <td width="20%"><button class="mybutton-top" ><i class="fa fa-envelope fa-1x" aria-hidden="true"></i></button></td>
                                <td WIDTH="95%" CLASS="myfontCardbody"></td>
                            </tr>
                            
                        </table>
                    </div>
                </div>
            </div>
            </div>
        </div>
    </div>
    <div class="row row1">
        <div class="col-md-4">
            <div class="panel panel-primary">
            <div class="panel-body">
                <div class="row">
                    <div class="col-md-4">
                        <img src='../assests/images/AdminStaffImg/man.png' width="130px" height="160px;"></img>
                        
                    </div>
                    
                    <div class="col-md-8 carddata">
                        <table>
                            <tr>
                                <td width="20%"><button class="mybutton-top" ><i class="fa fa-user fa-1x" aria-hidden="true"></i></button></td>
                                <td WIDTH="95%" CLASS="myfontCard">U R Joshi</td>
                            </tr>
                            <tr>
                                <td width="20%"></td>
                                <td WIDTH="95%"CLASS="myfontCardbody">Sr. Driver</td>
                            </tr>
                            <tr>
                                <td width="10%"></td>
                                <td WIDTH="95%"CLASS="myfontCardbody"></td>
                            </tr>
                            <tr>
                                <td width="20%"><button class="mybutton-top" ><i class="fa fa-history fa-1x" aria-hidden="true"></i></button></td>
                                <td WIDTH="95%" CLASS="myfontCardbody"> Yrs Experience</td>
                            </tr>
                            <tr>
                                <td width="20%"><button class="mybutton-top" ><i class="fa fa-envelope fa-1x" aria-hidden="true"></i></button></td>
                                <td WIDTH="95%" CLASS="myfontCardbody">joshi.ur@gov.in</td>
                            </tr>
                            
                        </table>
                    </div>
                </div>
            </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="panel panel-primary">
            <div class="panel-body">
            <div class="row">
                    <div class="col-md-4">
                        <img src='../assests/images/AdminStaffImg/man.png' width="135px" height="160px;"></img>
                        
                    </div>
                    
                    <div class="col-md-8 carddata">
                        <table>
                            <tr>
                                <td width="20%"><button class="mybutton-top" ><i class="fa fa-user fa-1x" aria-hidden="true"></i></button></td>
                                <td WIDTH="95%" CLASS="myfontCard">V R Parmar</td>
                            </tr>
                            <tr>
                                <td width="20%"></td>
                                <td WIDTH="95%"CLASS="myfontCardbody">Sr. Driver</td>
                            </tr>
                            <tr>
                                <td width="10%"></td>
                                <td WIDTH="95%"CLASS="myfontCardbody"></td>
                            </tr>
                            <tr>
                                <td width="20%"><button class="mybutton-top" ><i class="fa fa-history fa-1x" aria-hidden="true"></i></button></td>
                                <td WIDTH="95%" CLASS="myfontCardbody"> Yrs Experience</td>
                            </tr>
                            <tr>
                                <td width="20%"><button class="mybutton-top" ><i class="fa fa-envelope fa-1x" aria-hidden="true"></i></button></td>
                                <td WIDTH="95%" CLASS="myfontCardbody">parmar.vr@icmr.gov.in</td>
                            </tr>
                            
                        </table>
                    </div>
                </div>
            </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="panel panel-primary">
            <div class="panel-body">
            <div class="row">
                    <div class="col-md-4">
                        <img src='../assests/images/AdminStaffImg/man.png' width="135px" height="160px;"></img>
                        
                    </div>
                    
                    <div class="col-md-8 carddata">
                        <table>
                            <tr>
                                <td width="20%"><button class="mybutton-top" ><i class="fa fa-user fa-1x" aria-hidden="true"></i></button></td>
                                <td WIDTH="95%" CLASS="myfontCard">R M Mahajan</td>
                            </tr>
                            <tr>
                                <td width="20%"></td>
                                <td WIDTH="95%"CLASS="myfontCardbody">Driver</td>
                            </tr>
                            <tr>
                                <td width="10%"></td>
                                <td WIDTH="95%"CLASS="myfontCardbody"></td>
                            </tr>
                            <tr>
                                <td width="20%"><button class="mybutton-top" ><i class="fa fa-history fa-1x" aria-hidden="true"></i></button></td>
                                <td WIDTH="95%" CLASS="myfontCardbody">Yrs Experience</td>
                            </tr>
                            <tr>
                                <td width="20%"><button class="mybutton-top" ><i class="fa fa-envelope fa-1x" aria-hidden="true"></i></button></td>
                                <td WIDTH="95%" CLASS="myfontCardbody">rmmahajan7775@yahoo.com</td>
                            </tr>
                            
                        </table>
                    </div>
                </div>
            </div>
            </div>
        </div>
    </div>
    <div class="row row1">
        <div class="col-md-4">
            <div class="panel panel-primary">
            <div class="panel-body">
                <div class="row">
                    <div class="col-md-4">
                        <img src='../assests/images/AdminStaffImg/yashwant.jpeg' width="130px" height="160px;"></img>
                        
                    </div>
                    
                    <div class="col-md-8 carddata">
                        <table>
                            <tr>
                                <td width="20%"><button class="mybutton-top" ><i class="fa fa-user fa-1x" aria-hidden="true"></i></button></td>
                                <td WIDTH="95%" CLASS="myfontCard">Yashwant Meshram</td>
                            </tr>
                            <tr>
                                <td width="20%"></td>
                                <td WIDTH="95%"CLASS="myfontCardbody">Driver</td>
                            </tr>
                            <tr>
                                <td width="10%"></td>
                                <td WIDTH="95%"CLASS="myfontCardbody"></td>
                            </tr>
                            <tr>
                                <td width="20%"><button class="mybutton-top" ><i class="fa fa-history fa-1x" aria-hidden="true"></i></button></td>
                                <td WIDTH="95%" CLASS="myfontCardbody">2 Yrs Experience</td>
                            </tr>
                            <tr>
                                <td width="20%"><button class="mybutton-top" ><i class="fa fa-envelope fa-1x" aria-hidden="true"></i></button></td>
                                <td WIDTH="95%" CLASS="myfontCardbody">meshram.yb@icmr.gov.in</td>
                            </tr>
                            
                        </table>
                    </div>
                </div>
            </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="panel panel-primary">
            <div class="panel-body">
            <div class="row">
                    <div class="col-md-4">
                        <img src='../assests/images/AdminStaffImg/man.png' width="135px" height="160px;"></img>
                        
                    </div>
                    
                    <div class="col-md-8 carddata">
                        <table>
                            <tr>
                                <td width="20%"><button class="mybutton-top" ><i class="fa fa-user fa-1x" aria-hidden="true"></i></button></td>
                                <td WIDTH="95%" CLASS="myfontCard">Surendrasinh R Baraiya</td>
                            </tr>
                            <tr>
                                <td width="20%"></td>
                                <td WIDTH="95%"CLASS="myfontCardbody">Driver</td>
                            </tr>
                            <tr>
                                <td width="10%"></td>
                                <td WIDTH="95%"CLASS="myfontCardbody"></td>
                            </tr>
                            <tr>
                                <td width="20%"><button class="mybutton-top" ><i class="fa fa-history fa-1x" aria-hidden="true"></i></button></td>
                                <td WIDTH="95%" CLASS="myfontCardbody"> Yrs Experience</td>
                            </tr>
                            <tr>
                                <td width="20%"><button class="mybutton-top" ><i class="fa fa-envelope fa-1x" aria-hidden="true"></i></button></td>
                                <td WIDTH="95%" CLASS="myfontCardbody">baraiya.sr@icmr.gov.in</td>
                            </tr>
                            
                        </table>
                    </div>
                </div>
            </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="panel panel-primary">
            <div class="panel-body">
            <div class="row">
                    <div class="col-md-4">
                        <img src='../assests/images/AdminStaffImg/bharat.jpeg' width="135px" height="160px;"></img>
                        
                    </div>
                    
                    <div class="col-md-8 carddata">
                        <table>
                            <tr>
                                <td width="20%"><button class="mybutton-top" ><i class="fa fa-user fa-1x" aria-hidden="true"></i></button></td>
                                <td WIDTH="95%" CLASS="myfontCard">Bharat D Kshirsagar</td>
                            </tr>
                            <tr>
                                <td width="20%"></td>
                                <td WIDTH="95%"CLASS="myfontCardbody">Driver</td>
                            </tr>
                            <tr>
                                <td width="10%"></td>
                                <td WIDTH="95%"CLASS="myfontCardbody"></td>
                            </tr>
                            <tr>
                                <td width="20%"><button class="mybutton-top" ><i class="fa fa-history fa-1x" aria-hidden="true"></i></button></td>
                                <td WIDTH="95%" CLASS="myfontCardbody">6 Yrs Experience</td>
                            </tr>
                            <tr>
                                <td width="20%"><button class="mybutton-top" ><i class="fa fa-envelope fa-1x" aria-hidden="true"></i></button></td>
                                <td WIDTH="95%" CLASS="myfontCardbody">kshirsagar.bd@icmr.gov.in</td>
                            </tr>
                            
                        </table>
                    </div>
                </div>
            </div>
            </div>
        </div>
    </div>
    <div class="row row1">
        <div class="col-md-4">
            <div class="panel panel-primary">
            <div class="panel-body">
                <div class="row">
                    <div class="col-md-4">
                        <img src='../assests/images/AdminStaffImg/man.png' width="130px" height="160px;"></img>
                        
                    </div>
                    
                    <div class="col-md-8 carddata">
                        <table>
                            <tr>
                                <td width="20%"><button class="mybutton-top" ><i class="fa fa-user fa-1x" aria-hidden="true"></i></button></td>
                                <td WIDTH="95%" CLASS="myfontCard">Mukesh Kumar Seju</td>
                            </tr>
                            <tr>
                                <td width="20%"></td>
                                <td WIDTH="95%"CLASS="myfontCardbody">Driver</td>
                            </tr>
                            <tr>
                                <td width="10%"></td>
                                <td WIDTH="95%"CLASS="myfontCardbody"></td>
                            </tr>
                            <tr>
                                <td width="20%"><button class="mybutton-top" ><i class="fa fa-history fa-1x" aria-hidden="true"></i></button></td>
                                <td WIDTH="95%" CLASS="myfontCardbody"> Yrs Experience</td>
                            </tr>
                            <tr>
                                <td width="20%"><button class="mybutton-top" ><i class="fa fa-envelope fa-1x" aria-hidden="true"></i></button></td>
                                <td WIDTH="95%" CLASS="myfontCardbody">pseju.mk@icmr.gov.in</td>
                            </tr>
                            
                        </table>
                    </div>
                </div>
            </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="panel panel-primary">
            <div class="panel-body">
            <div class="row">
                    <div class="col-md-4">
                        <img src='../assests/images/AdminStaffImg/man.png' width="130px" height="160px;"></img>
                        
                    </div>
                    
                    <div class="col-md-8 carddata">
                        <table>
                            <tr>
                                <td width="20%"><button class="mybutton-top" ><i class="fa fa-user fa-1x" aria-hidden="true"></i></button></td>
                                <td WIDTH="95%" CLASS="myfontCard">J L Suthar </td>
                            </tr>
                            <tr>
                                <td width="20%"></td>
                                <td WIDTH="95%"CLASS="myfontCardbody">MTS(General)</td>
                            </tr>
                            <tr>
                                <td width="10%"></td>
                                <td WIDTH="95%"CLASS="myfontCardbody"></td>
                            </tr>
                            <tr>
                                <td width="20%"><button class="mybutton-top" ><i class="fa fa-history fa-1x" aria-hidden="true"></i></button></td>
                                <td WIDTH="95%" CLASS="myfontCardbody"> Yrs Experience</td>
                            </tr>
                            <tr>
                                <td width="20%"><button class="mybutton-top" ><i class="fa fa-envelope fa-1x" aria-hidden="true"></i></button></td>
                                <td WIDTH="95%" CLASS="myfontCardbody">suthar.jl@icmr.gov.in</td>
                            </tr>
                            
                        </table>
                    </div>
                </div>
            </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="panel panel-primary">
            <div class="panel-body">
            <div class="row">
                    <div class="col-md-4">
                        <img src='../assests/images/AdminStaffImg/man.png' width="135px" height="160px;"></img>
                        
                    </div>
                    
                    <div class="col-md-8 carddata">
                        <table>
                            <tr>
                                <td width="20%"><button class="mybutton-top" ><i class="fa fa-user fa-1x" aria-hidden="true"></i></button></td>
                                <td WIDTH="95%" CLASS="myfontCard">M A Makwana</td>
                            </tr>
                            <tr>
                                <td width="20%"></td>
                                <td WIDTH="95%"CLASS="myfontCardbody">MTS(General)</td>
                            </tr>
                            <tr>
                                <td width="10%"></td>
                                <td WIDTH="95%"CLASS="myfontCardbody"></td>
                            </tr>
                            <tr>
                                <td width="20%"><button class="mybutton-top" ><i class="fa fa-history fa-1x" aria-hidden="true"></i></button></td>
                                <td WIDTH="95%" CLASS="myfontCardbody"> Yrs Experience</td>
                            </tr>
                            <tr>
                                <td width="20%"><button class="mybutton-top" ><i class="fa fa-envelope fa-1x" aria-hidden="true"></i></button></td>
                                <td WIDTH="95%" CLASS="myfontCardbody">makwana.ma@gov.in</td>
                            </tr>
                            
                        </table>
                    </div>
                </div>
            </div>
            </div>
        </div>
    </div>
    <div class="row row1">
        <div class="col-md-4">
            <div class="panel panel-primary">
            <div class="panel-body">
                <div class="row">
                    <div class="col-md-4">
                        <img src='../assests/images/AdminStaffImg/amrut.jpg' width="130px" height="160px;"></img>
                        
                    </div>
                    
                    <div class="col-md-8 carddata">
                        <table>
                            <tr>
                                <td width="20%"><button class="mybutton-top" ><i class="fa fa-user fa-1x" aria-hidden="true"></i></button></td>
                                <td WIDTH="95%" CLASS="myfontCard">Amrutbhai C Vagela</td>
                            </tr>
                            <tr>
                                <td width="20%"></td>
                                <td WIDTH="95%"CLASS="myfontCardbody">MTS (General)</td>
                            </tr>
                            <tr>
                                <td width="10%"></td>
                                <td WIDTH="95%"CLASS="myfontCardbody">Store Section</td>
                            </tr>
                            <tr>
                                <td width="20%"><button class="mybutton-top" ><i class="fa fa-history fa-1x" aria-hidden="true"></i></button></td>
                                <td WIDTH="95%" CLASS="myfontCardbody">24 Yrs Experience</td>
                            </tr>
                            <tr>
                                <td width="20%"><button class="mybutton-top" ><i class="fa fa-envelope fa-1x" aria-hidden="true"></i></button></td>
                                <td WIDTH="95%" CLASS="myfontCardbody">vaghela.ac@icmr.gov.in</td>
                            </tr>
                            
                        </table>
                    </div>
                </div>
            </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="panel panel-primary">
            <div class="panel-body">
            <div class="row">
                    <div class="col-md-4">
                        <img src='../assests/images/AdminStaffImg/manjula.jpeg' width="135px" height="160px;"></img>
                        
                    </div>
                    
                    <div class="col-md-8 carddata">
                        <table>
                            <tr>
                                <td width="20%"><button class="mybutton-top" ><i class="fa fa-user fa-1x" aria-hidden="true"></i></button></td>
                                <td WIDTH="95%" CLASS="myfontCard">Manjula Ben J Solanki</td>
                            </tr>
                            <tr>
                                <td width="20%"></td>
                                <td WIDTH="95%"CLASS="myfontCardbody">MTS (General)</td>
                            </tr>
                            <tr>
                                <td width="10%"></td>
                                <td WIDTH="95%"CLASS="myfontCardbody">Establishment Section</td>
                            </tr>
                            <tr>
                                <td width="20%"><button class="mybutton-top" ><i class="fa fa-history fa-1x" aria-hidden="true"></i></button></td>
                                <td WIDTH="95%" CLASS="myfontCardbody">15 Yrs Experience</td>
                            </tr>
                            <tr>
                                <td width="20%"><button class="mybutton-top" ><i class="fa fa-envelope fa-1x" aria-hidden="true"></i></button></td>
                                <td WIDTH="95%" CLASS="myfontCardbody">solanki.mj@gov.in</td>
                            </tr>
                            
                        </table>
                    </div>
                </div>
            </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="panel panel-primary">
            <div class="panel-body">
            <div class="row">
                    <div class="col-md-4">
                        <img src='../assests/images/AdminStaffImg/man.png' width="135px" height="160px;"></img>
                        
                    </div>
                    <div class="col-md-8 carddata">
                        <table>
                            <tr>
                                <td width="20%"><button class="mybutton-top" ><i class="fa fa-user fa-1x" aria-hidden="true"></i></button></td>
                                <td WIDTH="95%" CLASS="myfontCard">M R Mir</td>
                            </tr>
                            <tr>
                                <td width="20%"></td>
                                <td WIDTH="95%"CLASS="myfontCardbody">MTS(General)</td>
                            </tr>
                            <tr>
                                <td width="10%"></td>
                                <td WIDTH="95%"CLASS="myfontCardbody"></td>
                            </tr>
                            <tr>
                                <td width="20%"><button class="mybutton-top" ><i class="fa fa-history fa-1x" aria-hidden="true"></i></button></td>
                                <td WIDTH="95%" CLASS="myfontCardbody">Yrs Experience</td>
                            </tr>
                            <tr>
                                <td width="20%"><button class="mybutton-top" ><i class="fa fa-envelope fa-1x" aria-hidden="true"></i></button></td>
                                <td WIDTH="95%" CLASS="myfontCardbody">rmir.mdb@icmr.gov.in</td>
                            </tr>
                            
                        </table>
                    </div>
                   
                </div>
            </div>
            </div>
        </div>
    </div>
    <div class="row row1">
        <div class="col-md-4">
            <div class="panel panel-primary">
            <div class="panel-body">
                <div class="row">
                    <div class="col-md-4">
                        <img src='../assests/images/AdminStaffImg/man.png' width="130px" height="160px;"></img>
                        
                    </div>
                    <div class="col-md-8 carddata">
                        <table>
                            <tr>
                                <td width="20%"><button class="mybutton-top" ><i class="fa fa-user fa-1x" aria-hidden="true"></i></button></td>
                                <td WIDTH="95%" CLASS="myfontCard">N B Damor</td>
                            </tr>
                            <tr>
                                <td width="20%"></td>
                                <td WIDTH="95%"CLASS="myfontCardbody">MTS(General)</td>
                            </tr>
                            <tr>
                                <td width="10%"></td>
                                <td WIDTH="95%"CLASS="myfontCardbody"></td>
                            </tr>
                            <tr>
                                <td width="20%"><button class="mybutton-top" ><i class="fa fa-history fa-1x" aria-hidden="true"></i></button></td>
                                <td WIDTH="95%" CLASS="myfontCardbody"> Yrs Experience</td>
                            </tr>
                            <tr>
                                <td width="20%"><button class="mybutton-top" ><i class="fa fa-envelope fa-1x" aria-hidden="true"></i></button></td>
                                <td WIDTH="95%" CLASS="myfontCardbody">	damor.nb@icmr.gov.in</td>
                            </tr>
                            
                        </table>
                    </div>
                    
                </div>
            </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="panel panel-primary">
            <div class="panel-body">
            <div class="row">
                    <div class="col-md-4">
                        <img src='../assests/images/AdminStaffImg/bhavesh.jpg' width="135px" height="160px;"></img>
                        
                    </div>
                    <div class="col-md-8 carddata">
                    <table>
                            <tr>
                                <td width="20%"><button class="mybutton-top" ><i class="fa fa-user fa-1x" aria-hidden="true"></i></button></td>
                                <td WIDTH="95%" CLASS="myfontCard">Bhavesh N Prajapati</td>
                            </tr>
                            <tr>
                                <td width="20%"></td>
                                <td WIDTH="95%"CLASS="myfontCardbody">	MTS(General)</td>
                            </tr>
                            <tr>
                                <td width="10%"></td>
                                <td WIDTH="95%"CLASS="myfontCardbody">Health Sciences</td>
                            </tr>
                            <tr>
                                <td width="20%"><button class="mybutton-top" ><i class="fa fa-history fa-1x" aria-hidden="true"></i></button></td>
                                <td WIDTH="95%" CLASS="myfontCardbody">13 Yrs Experience</td>
                            </tr>
                            <tr>
                                <td width="20%"><button class="mybutton-top" ><i class="fa fa-envelope fa-1x" aria-hidden="true"></i></button></td>
                                <td WIDTH="95%" CLASS="myfontCardbody">Prajapati.bn@gov.in</td>
                            </tr>
                            
                        </table>
                    </div>
                    
                </div>
            </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="panel panel-primary">
            <div class="panel-body">
            <div class="row">
                    <div class="col-md-4">
                        <img src='../assests/images/AdminStaffImg/Rupal.jpg' width="135px" height="160px;"></img>
                        
                    </div>
                    <div class="col-md-8 carddata">
                        <table>
                            <tr>
                                <td width="20%"><button class="mybutton-top" ><i class="fa fa-user fa-1x" aria-hidden="true"></i></button></td>
                                <td WIDTH="95%" CLASS="myfontCard">Rupal Joshi</td>
                            </tr>
                            <tr>
                                <td width="20%"></td>
                                <td WIDTH="95%"CLASS="myfontCardbody">MTS(General)</td>
                            </tr>
                            <tr>
                                <td width="10%"></td>
                                <td WIDTH="95%"CLASS="myfontCardbody"></td>
                            </tr>
                            <tr>
                                <td width="20%"><button class="mybutton-top" ><i class="fa fa-history fa-1x" aria-hidden="true"></i></button></td>
                                <td WIDTH="95%" CLASS="myfontCardbody">5 month Experience</td>
                            </tr>
                            <tr>
                                <td width="20%"><button class="mybutton-top" ><i class="fa fa-envelope fa-1x" aria-hidden="true"></i></button></td>
                                <td WIDTH="95%" CLASS="myfontCardbody">rupal.joshi@icmr.gov.in</td>
                            </tr>
                            
                        </table>
                    </div>
                    
                </div>
            </div>
            </div>
        </div>
    </div>
   
    <div class="row row1">
        
       
        <div className="col-md-12">
                <center>
                <Link to="/AdminNioh"><button className="btn btn-primary" style={{fontSize:"19px", width:"35px", marginRight :"10px"}}>1</button></Link>
                <Link to="/otheradmin"><button className="btn btn-primary" style={{fontSize:"19px", width:"35px"}}>2</button></Link>
                   
                    <Routes>
                        <Route exact path='/otheradmin' element={<Otheradmin />}></Route> 
                    </Routes>
                </center>
            </div>
    </div>
    </div> </div>);
}
export default Otheradmin;