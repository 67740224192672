import React from 'react';
import '../carousel.css';
import NavbarMenu from '../NavbarMenu.js';
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
function DrAkshya() {
    return (
      <div> <NavbarMenu /> <div class="manual">
        <div class="row container">
        <p class="mypathhead">
        <Link to="/"><span class="mypath">Home</span></Link>&gt;<Link to="/ScientistROHCS"><span class="mypath">Scientist's Profile</span></Link>&gt;<span class="mypath">ICMR-ROHCS Bengaluru</span>
        </p>
          
        </div>
        <h1 class="mypathhead">ICMR-ROHCS Scientist's Detail</h1>
        <div class="scientistdetail">
      
            <div class="panel my-panel" >
      <div class="panel-heading my-panel-head" align="left"><strong>Basic Details</strong></div>
      <div class="panel-body" align="left">
      <table width="100%">
            <tr>
                <td width="28%" rowspan="5"><img src="../assests/images/rohcsScientist/Geethu.jpg" width="180px" height="170px" CLASS="dg-img"/></td>
                <td width="50%" ><p class="myfontSci trSci"> Dr Geethu Mathew</p> </td>
                <td width="22%" ></td>
                
            </tr>
            <tr>
              <td><p ><a href=""><i class="fa fa-envelope" aria-hidden="true"></i>   Email: geethu.mathew@icmr.gov.in</a></p></td>
            </tr> 
            <tr>
              <td><p ><a href=""> <i class="fa fa-phone-square"></i>  Contact: 080-22172502</a></p></td>
            </tr>
           
            </table>   
      </div>
    </div>
       
        <div class="panel my-panel" >
      <div class="panel-heading my-panel-head" align="left"><strong>Academic Qualification</strong></div>
      <div class="panel-body" align="left">
      MBBS, MD,PGDEPI
</div>
    </div>
    <div class="panel my-panel" >
      <div class="panel-heading my-panel-head" align="left"><strong>Research Interest </strong></div>
      <div class="panel-body" align="left">
      <p>Occupational and environmental health, Industry based interventions for promotion of occupational health and safety, Application of M – Health, Economic evaluation</p>

</div>
</div>
<div class="panel my-panel">
      <div class="panel-heading my-panel-head" align="left"><strong>Membership/Affliation </strong></div>
      <div class="panel-body" align="left">
      <ul><li>Indian Association of Occupational Health(IAOH)</li>
<li>Indian Medical Association (IMA)</li>
<li>Indian Association of Preventive and Social Medicine(IAPSM)

</li>

</ul>
</div>
</div>
<div class="panel my-panel">
      <div class="panel-heading my-panel-head" align="left"><strong>Fellowship/Awards </strong></div>
      <div class="panel-body" align="left">
      <ul>
<li>University Rank holder for MD exam in 2015  (Rajiv Gandhi University for Health Sciences: Community Medicine)</li>
<li>Ford Foundation Award from Indian Association of Preventive and Social Medicine (IAPSM) in 2019</li>
<li>Best Researcher Award from the St. John’s Medical College Research Society for the year 2015</li>
<li>Best Research Paper Award in the National Conference, Trivandrum. December, 2014-15</li>
<li>Best Research Paper Award in 4th Annual Geriatric Conference of The Karnataka Chapter of Indian Academy of Geriatrics, 2013</li>
<li>4 Extramural projects as PI and 6 projects as Co-PI in last 3 years</li>
</ul>
</div>
</div>
<div class="panel my-panel">
      <div class="panel-heading my-panel-head" align="left"><strong>Publications </strong></div>
      <div class="panel-body" align="left">
	
      <ol>
                   <li><strong>Mathew G</strong>, Varghese AD, Sabu AM, Joseph A. Screening for post-traumatic stress disorder among adolescents following floods- a comparative study from private and public schools in Kerala, India. BMC Pediatr. 2021 Oct 20;21(1):462. doi: 10.1186/s12887-021-02933-4. PMID: 34670533.Impact factor: 2.3
</li><br/><li>Chakma T, Thomas BE, Kohli S, Moral R, Menon GR, Periyasamy M, Venkatesh U, Kulkarni RN, Prusty RK, Balu V, Grover A, Kishore J, Viray M, Venkateswaran C, <strong>Mathew G</strong>, Ketharam A, Balachandar R, Singh PK, Jakhar K, Singh S, Devi R, Saha KB, Barde P, Singh R, John D, Mishra BK, Yadav J, Agarwal S, Rao VV, Panda S. Psychosocial impact of COVID-19 pandemic on healthcare workers in India & their perceptions on the way forward - A qualitative study. Indian J Med Res 2021;153:637-48.
Impact factor: 2.37
</li><br/><li>Sebastian S R, <strong>Mathew G</strong>, Johnson A K, Chacko A, Babu B P, Joseph M R. Perceptions on the impact of a structured community-based training model in undergraduate medical training during the first phase of clinical exposure: A qualitative study from Kerala. Indian Journal of Public Health 2021;65:291-3 Impact factor: 1
</li><br/><li>Sebastian S R, A Joyal, <strong>Mathew G</strong>, Babu B P. A Cross-sectional Study on Empathy and Factors Associated with Individual Variations in Empathy Levels among College Students in Central Kerala. Journal of Clinical and Diagnostic Research. 15. LC24 - LC27. 10.7860/JCDR/2021/50742.15560.
</li><br/><li>Thomas, I., Thomas, T., <strong>Mathew G</strong>. et al. Spaced Intranasal Corticosteroid Therapy: A Better Treatment Option in Allergic Rhinitis?. Indian J Otolaryngol Head Neck Surg (2021). https://doi.org/10.1007/s12070-021-02514-y. Impact factor-1.5
</li><br/><li>Thomas I, <strong>Mathew G</strong>, Thomas T, Anilkumar M, George K. Profile of allergic rhinitis patients in a tertiary care centre in central Kerala. Int J Otorhinolaryngol Head Neck Surg 2021;7:842-6
</li><br/><li>Bava NP, Sreekumar S, <strong>Mathew G</strong>, Sushan A, Sheeja AL, Parvathy P. Strategies for COVID-19 control among migrant labourers in a developing country setting: Pathanamthitta model from Kerala. J Family Med Prim Care 2021;10:2342-7.Impact factor-0.6
</li><br/><li>Joseph V, Titus DJ, Varghese AD, John JJ, Mohan G, <strong>Mathew G</strong>, et al. Correlation between sonological and clinical profile of mesenteric lymphadenitis among children in a tertiary care hospital of central Kerala. International Journal of Contemporary Pediatrics. 8. 10.18203/2349-3291.ijcp20211416. 
</li><br/><li><strong>Mathew G</strong>,  Kumar Sruthy,  Cherian, Koshy, Issac Nidhish & Benjamin Anoop. (2020). Revisions in TB programme - BOON or BANE? A qualitative study exploring barriers and facilitators among health care workers in private and public sector, Kerala. Indian Journal of Tuberculosis. 10.1016/j.ijtb.2020.12.002. Impact factor -1
</li><br/><li><strong>Mathew G</strong>, Shaliet Rose Sebastian, AnoopI Benjamin, Vinay Goyal, Jiju Joseph, Abey Sushan, Abel K Samuel, and A L Sheeja. 2020. “Community-Based Burden, Warning Signs, and Risk Factors of Cancer Using Public-Private Partnership Model in Kerala, India.” Journal of Family Medicine and Primary Care, 2020 9 (2): 745. 
</li><br/><li><strong>Mathew G</strong>, Shaliet Rose Sebastian, Anoop I. Benjamin, Vinay Goyal, Abey Sushan, Jiju Joseph, and A. L. Sheeja “Cancer Burden and Alarm Signals: A Community Based Study from Kerala, India.” International Journal of Research in Medical Sciences2020.  8 (2): 681. 
</li><br/><li><strong>Mathew G</strong>, Fathima FN, Agrawal T, Misquith D. “DIABETIC TAX” – Cost of care among persons with type 2 diabetes mellitus in an Urban Underprivileged Area of Bengaluru. Indian J Community Med 2019;44:113-7
</li><br/><li><strong>Mathew G</strong>, Shanbhag D, Subramanian S, Lobo C, Xavier A, Dasari P, et al. HIV-related high-risk behaviour and awareness regarding HIV among the male workers at a construction site in Karnataka. Int J Med Sci Public Health 2019;8
</li><br/><li>Varghese AD, <strong>Mathew G</strong>, Benjamin AI. Are adolescents studying in private schools more obese? a comparative study among adolescents from different types of schools in Kerala, India. Int J Contemp Pediatr 2019;6:473-6.
</li><br/><li>Varghese AD, <strong>Mathew G</strong>, Kumar SC, Benjamin AI. Awareness regarding Nipah infection among health-care workers in a Medical College Hospital in Kerala. J Curr Res Sci Med 2019;5:33-8
</li><br/><li><strong>Mathew G</strong>, Varghese AD, Benjamin AI. A comparative study assessing sleep duration and associated factors among adolescents studying in different types of schools in an urban area of Kerala, India. Indian J Community Med 2019;44:10-3.
</li><br/><li><strong>Mathew G</strong>, Thomas S, Pretesh RK, Arvind K. Screening for dementia using picture memory impairment screen among older adults in an urban underprivileged area of Bangalore city, India. Int J Community Med Public Health 2018;5:1575-9
</li><br/><li><strong>Mathew G</strong>, Ramesh N, Ajay S, Lewis J, Pinto T, Rohan P, Singh J, Joseph B. Prevalence of Tobacco Dependence and Problem Drinking Among Workers in a Tea Plantation in South India. Natl J Community Med 2017; 8(8):462-466.
</li><br/><li><strong>Mathew G</strong>, Ramesh N, Shanbhag D, et al. Quality of life and probable psychological distress among male workers at a construction site, Kolar district, Karnataka, India. Indian J Occup Environ Med. 2016;20(1):54–59. doi:10.4103/0019-5278.183846
</li><br/><li><strong>Mathew G</strong>, Johnson AR, Thimmaiah S, Kumari R, Varghese A. Barriers to childhood immunisation among women in an urban underprivileged area of Bangalore city, Karnataka, India: a qualitative study. Int J Community Med Public Health 2016;3:1525-30
</li><br/><li>Anbazhagan, S., Johnson, A., <strong>Mathew, G</strong>, N., Reshma, S., Preety, S., & Agrawal, T. (2015). Sleep Hygiene among School- Going Adolescents in Rural Karnataka. Indian Journal of Youth and Adolescent Health (ISSN: 2349-2880), 2(1&2), 20-23.
</li><br/><li>Johnson AR, Edwin S, Joachim N, <strong>Mathew G</strong>, Ajay S, Joseph B. Postnatal depression among women availing maternal health services in a rural hospital in South India. Pak J Med Sci. 2015;31(2):408-413. doi:10.12669/pjms.312.
</li><br/><li>Varghese SM, Sheeja AL, Samuel Johnson AK, Sushan A, Nandini CS, Chandy GM, et al. Together let us confront it: An outbreak investigation of hepatitis B in Pathanamthitta district, Kerala. J Family Med Prim Care 2021;10:2159-65.
</li><br/><li>Adolescents and Floods: An Exploratory Study from Kerala (Accepted in JKIMSU, Vol. 10, No. 4, October-December 2021)
</li><br/><li>Is sleep hygiene neglected among adolescents? A school based study from India (Accepted in SLJCH November 2021)
</li>
					
                  </ol>
	
	
	
</div>
</div>
</div>
      
    </div>
    </div>
    );
}
export default DrAkshya;