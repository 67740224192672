import React from 'react';
import '../carousel.css';
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import NavbarMenu from '../NavbarMenu.js';
function DrMihir() {
    return (
      <div> <NavbarMenu /> <div class="manual">
        <div class="row container">
        <p class="mypathhead">
        <Link to="/"><span class="mypath">Home</span></Link>&gt;<Link to="/ScientistNIOH"><span class="mypath">Scientist's Profile</span></Link>&gt;<span class="mypath">NIOH Ahmedabad</span>
        </p>
          
        </div>
        <h1 class="mypathhead">Scientist's Detail</h1>
        <div class="scientistdetail">
      
            <div class="panel my-panel" >
      <div class="panel-heading my-panel-head" align="left"><strong>Basic Details</strong></div>
      <div class="panel-body" align="left">
      <table width="100%">
            <thead>
          <th width="28%"></th>
          <th width="50%"></th>
          <th width="22%"></th>
        </thead>
        <tbody>
            <tr>
              <td rowspan="5"><img src="../assests/images/niohScientist/MihirR.jpeg" width="170px" height="170px" CLASS="dg-img"/></td>
              <td ><p class="myfontSci trSci">Dr Mihir Prafulbhai Rupani</p> </td>
              
            </tr>
            
            <tr>
              
              <td class="myfontSci"><p class="myfontSci">Health Sciences Division </p></td>
              
             </tr>
            <tr>
              <td><p ><a href=""><i class="fa fa-envelope" aria-hidden="true"></i>   Email: mihir.rupani@icmr.gov.in</a></p></td>
              
            </tr> 
            <tr>
              <td><p ><a href=""> <i class="fa fa-phone-square"></i>  Contact: 079-22688700 (extn. no. 468) </a></p></td>
              
            </tr>   
          </tbody>
            </table>
      </div>
    </div>
       
        <div class="panel my-panel" >
      <div class="panel-heading my-panel-head" align="left"><strong>Academic Qualification</strong></div>
      <div class="panel-body" align="left">MD (Community Medicine), PhD (Community Medicine)</div>
    </div>
    <div class="panel my-panel" >
      <div class="panel-heading my-panel-head" align="left"><strong>Research Interest </strong></div>
      <div class="panel-body" align="left">Prevention and control of silicosis and silico-tuberculosis; impact of environmental and occupational pollutants on tuberculosis.</div>
    </div>
    <div class="panel my-panel" >
      <div class="panel-heading my-panel-head" align="left"><strong>Membership/Affliation </strong></div>
      <div class="panel-body" align="left">
      <ul><li>American Thoracic Society</li>
<li>Indian Association of Preventive and Social Medicine</li>
<li>Epidemiology Foundation of India</li>
</ul>
        </div>
        </div>
        <div class="panel my-panel">
      <div class="panel-heading my-panel-head" align="left"><strong>Fellowship/Awards </strong></div>
      <div class="panel-body" align="left"><ul><li>IAPSM Gujarat chapter - best published paper award (2022)</li>
        <li>	ATS-MECOR program graduate (2020)</li>
<li>	2017 ATS Foundation MECOR Award</li>
<li>	CK Purohit Young Scientist Award for best published paper in 2017</li>
<li>	H. M. Patel Memorial Young Scientist Award in 2016</li>
<li>	Gold Medal in MD Community Medicine</li>
</ul>
</div>
</div>
<div class="panel my-panel">
      <div class="panel-heading my-panel-head" align="left"><strong>Publications </strong></div>
      <div class="panel-body" align="left">
	
      <ol> <li>Rupani, M. P. (2023). A mixed-methods study on impact of silicosis on tuberculosis treatment outcomes and need for TB-silicosis collaborative activities in India. Scientific Reports, 13, 2785. https://doi.org/10.1038/s41598-023-30012-4 </li>
	  <li>Rupani, M. P. (2023). Challenges and opportunities for silicosis prevention and control: need for a national health program on silicosis in India. Journal of Occupational Medicine and Toxicology, 18, 11. https://doi.org/10.1186/s12995-023-00379-1</li>
    <li>upani, M. P. (2023). Silicosis as a predictor of tuberculosis mortality and treatment failure and need for incorporation in differentiated TB care models in India. Archives of Public Health, 81(1), 173. https://doi.org/10.1186/s13690-023-01189-x </li>
    <li>Rupani, M. P., & Vyas, S. (2023). A sequential explanatory mixed-methods study on costs incurred by patients with tuberculosis comorbid with diabetes in Bhavnagar, western India. Scientific Reports, 13, 150. https://doi.org/10.1038/s41598-023-27494-7 </li>
    <li>Rupani, M. P., & Vyas, S. (2022). Costs incurred by patients with tuberculosis co-infected with human immunodeficiency virus in Bhavnagar, western India: a sequential explanatory mixed-methods research. BMC Health Services Research, 22, 1268. https://doi.org/10.1186/s12913-022-08647-2</li>
    <li>Rupani, M. P. (2022). Alcohol and Disease: a focus on tuberculosis and implications for public health and policy. In V. B. Patel & V. R. Preedy (Eds.), Handbook of Substance Misuse and Addictions (pp. 1–25). Springer International Publishing. https://doi.org/10.1007/978-3-030-67928-6_54-1 </li>
    <li>Rupani, M. P. (2023). Need for implementation of national framework for tuberculosis-alcohol (TB-alcohol) collaborative activities in India - drifting closer to TB elimination by 2025. Indian Journal of Tuberculosis. https://doi.org/10.1016/j.ijtb.2023.05.019</li>
    <li>Tank, N. D., Rupani, M. P., Shah, R. B., Shah, I. A., & Dhatrak, S. V. (2023). Predictors of pulmonary dysfunction vis-à-vis the hypertension status of mine workers in Gujarat, western India: A cross-sectional study on the need to further investigate the role of anti-hypertensive drugs. Work, 1–12. https://doi.org/10.3233/WOR-220678 </li>
    <li>Dave, J. D., & Rupani, M. P. (2023). Advancing Social Protection and Tuberculosis Elimination in India – Beyond Cash Transfers and towards addressing social and structural determinants for a Healthier Future; A Response to the Recent Commentaries. International Journal of Health Policy and Management, 12(1), 8130. https://doi.org/10.34172/ijhpm.2023.8130 </li>
    <li>Dave, J. D., & Rupani, M. P. (2022). Does Direct Benefit Transfer Improve Outcomes Among People With Tuberculosis? – A Mixed-Methods Study on the Need for a Review of the Cash Transfer Policy in India. International Journal of Health Policy and Management, 11(11), 2552–2562. https://doi.org/10.34172/ijhpm.2022.5784 </li>
    <li>Soundararajan, S., Sheth, A., Rupani, M., Balachandar, R., & Viramgami, A. (2023). Need for workplace smoking cessation program among public transit drivers: Evidence from a cross.sectional study in a metropolitan city of Western India. Lung India, 40(6), 496–501. https://doi.org/10.4103/lungindia.lungindia_137_23 </li>
    <li>Rupani, M. P., Shah, C. J., Dave, J. D., Trivedi, A. V., & Mehta, K. G. (2021). ‘We are not aware of notification of tuberculosis’: A mixed‐methods study among private practitioners from western India. The International Journal of Health Planning and Management, 36(4), 1052–1068. https://doi.org/10.1002/hpm.3151 </li>
    <li>Rupani, M. P., Cattamanchi, A., Shete, P. B., Vollmer, W. M., Basu, S., & Dave, J. D. (2020). Costs incurred by patients with drug-susceptible pulmonary tuberculosis in semi-urban and rural settings of Western India. Infectious Diseases of Poverty, 9(1), 144. https://doi.org/10.1186/s40249-020-00760-w </li>
    <li>Rupani, M. P., & Vyas, S. (2022). Dissaving In the Era of “Free” Care for Tuberculosis (TB): A Qualitative Exploration of Financial Coping and Enablers Among Patients with Co-Prevalent TB-HIV/ TB- Diabetes in Bhavnagar Region, Western India. National Journal of Community Medicine, 13(09), 629–635. https://doi.org/10.55489/njcm.130920222242 </li>
    <li>Rupani, M. P., & Vyas, S. (2022). Predictors Of Catastrophic Costs Of Tuberculosis (TB) Among Patients Co-Affected With TB-HIV And TB-Diabetes In Bhavnagar Region, Western India. National Journal of Community Medicine, 13(08), 497–502. https://doi.org/10.55489/njcm.130820222251 </li>
    <li>Rupani, M. P. (2022). Is It The Right Time for India to Move From Targeted Cash Transfers to Universal Cash Transfers for Patients with Tuberculosis? National Journal of Community Medicine, 13(07), 494–495. https://doi.org/10.55489/njcm.130720222225 </li>
    <li>Rupani, M. P., Dave, J. D., Parmar, V. B., Singh, M. P., & Parikh, K. D. (2020). Adverse drug reactions and risk factors for discontinuation of multidrug-resistant tuberculosis regimens in Gujarat, western India. National Medical Journal of India, 33(1), 10–14. https://doi.org/10.4103/0970-258X.308234 </li>
    <li>Solanki, N., Sharma, P., Rupani, M. P., & Goswami, B. (2022). “I lost my faith and stopped taking the medicines” – need for an intervention model based on health belief constructs for improving adherence to tuberculosis treatment. Journal of Family Medicine and Primary Care, 11(6), 3006–3012. https://doi.org/10.4103/jfmpc.jfmpc_2128_21 </li>
    <li>Thummar, P. D., & Rupani, M. P. (2020). Prevalence and predictors of hazardous alcohol use among tuberculosis patients: The need for a policy on joint tuberculosis-alcohol collaborative activities in India. Alcohol, 86, 113–119. https://doi.org/10.1016/j.alcohol.2020.03.006 </li>
    <li>Ramana, A. B., Singh, S., Rupani, M. P., Mukherjee, R., & Mohapatra, A. (2023). Plight of migrant construction-site workers during the COVID-19 lockdown in 2020: A qualitative exploration in Bhavnagar, Western India. Work, 76(1), 33–45. https://doi.org/10.3233/WOR-220127 </li>
    <li>GRID COVID-19 Study Group. (2020). Combating the COVID-19 pandemic in a resource-constrained setting: insights from initial response in India. BMJ Global Health, 5(11), e003416. https://doi.org/10.1136/bmjgh-2020-003416 </li>
    <li>Chatterjee, A., Ramraj, B., & GRID COVID-19 study group (2023). Stigma Mechanisms in a Globalized Pandemic in India: A Theoretical Framework for Stigma. In: Pachauri, S., Pachauri, A. (eds) Global Perspectives of COVID-19 Pandemic on Health, Education, and Role of Media. Springer, Singapore. https://doi.org/10.1007/978-981-99-1106-6_18 </li>
    <li>Viramgami, A., Soundarajan, S., Sheth, A., Rupani, M., & Balachandar, R. (2023). O-34 Respirable dust exposure and lung function parameters for non-smoker metropolitan bus drivers of Gujarat (India). Occupational and Environmental Medicine, 80, A33. https://doi.org/10.1136/OEM-2023-EPICOH.78 </li>
    <li>Patel, J. R., & Rupani, M. P. (2021). Costs incurred by patients with oral potentially malignant disorders: is there a public health need for financial protection in India? BMC Research Notes, 14(1), 396. https://doi.org/10.1186/s13104-021-05814-2 </li>
    <li>Chauhan, P. A., & Rupani, M. P. (2021). High‐risk health behaviors predict depression among school‐going adolescents: the need for integration of mental health with school health program in India. Journal of Community Psychology, 49(6), 1891–1903. https://doi.org/10.1002/jcop.22627 </li> 
    <li>Patel, P. M., Rupani, M. P., & Gajera, A. N. (2019). Dependence on smokeless tobacco and willingness to quit among patients of a tertiary care hospital of Bhavnagar, Western India. Indian Journal of Psychiatry, 61(5), 472. https://doi.org/10.4103/psychiatry.IndianJPsychiatry_87_18 </li>
    <li>Rupani, M. P., Bhatt, G. S., & Gaonkar, N. T. (2017). Diarrhea Alleviation through Zinc-ORS Therapy (DAZT) program: Findings of concurrent monitoring from Western India. Indian Journal of Medical Sciences, 69(2), 11–14. https://ijmsweb.com/view-pdf/?&article=6463f75ee87ffc95950e56aff48eaf45l1CgOg5Yl4Q= </li>
    <li>Rupani, M. P., Gaonkar, N. T., & Bhatt, G. S. (2015). Low osmolar oral rehydration solution (ORS) for treating diarrhea in children: A systematic review and meta-analysis. Online Journal of Health and Allied Sciences, 14(3), 1–6. http://www.ojhas.org/issue55/2015-3-1.html </li>
    <li>Rupani, M. P., Gaonkar, N. T., & Bhatt, G. S. (2016). Bottleneck analysis and strategic planning using Tanahashi model for childhood diarrhea management in Gujarat, Western India. Evaluation and Program Planning, 58, 82–87. https://doi.org/10.1016/j.evalprogplan.2016.05.017 </li>
    <li>Rupani, M. P., Parikh, K. D., Kakadia, M. J., Pathak, M. M., Patel, M. R., & Shah, M. A. (2019). Cross-sectional study on smokeless tobacco use, awareness and expenditure in an urban slum of Bhavnagar, western India. The National Medical Journal of India, 32(3), 137–140. https://doi.org/10.4103/0970-258X.278686 </li>
    <li>Rupani, M. P., Parikh, K. D., Trivedi, A. V, Singh, M. P., Patel, A., Vadodariya, B., Bhagora, B., & Sharma, B. (2016). Cross-sectional study on mobile phone involvement among medical students of a tertiary care teaching hospital of western India. National Journal of Community Medicine, 7(7), 609–613. https://njcmindia.com/index.php/file/article/download/1014/784 </li>
    <li>Rupani, M. P., Parikh, K. D., Trivedi, A. V, Singh, M. P., Shah, K., Parmar, K., Shakya, K., Chauhan, K., & Ganveet, J. (2016). Cross-sectional study on exam anxiety among medical students of a tertiary care teaching hospital of western India. National Journal of Community Medicine, 7(5), 449–454. https://njcmindia.com/index.php/file/article/view/974/750 </li>
    <li>Rupani, M. P., Patel, P. M., Meena, P. R., Patel, P. P., Patel, P. A., & Paragda, P. K. (2019). Regular Antenatal Care Visits Predict Good Knowledge Among Post-natal Mothers Regarding Entitlements of Health Programs in Western India. International Journal of Health Policy and Management, 8(8), 467–473. https://doi.org/10.15171/ijhpm.2019.28 </li>
    <li>Rupani, M. P., Pawar, A. B., Bansal, R. K., Dalal, M., Vachhani, A., & Parikh, K. D. (2017). Cross-sectional study to assess the need to commence opportunistic screening of women for cervical cancer presenting with sexually transmitted disease in Western India. European Journal of Cancer Care, 26(1). https://doi.org/10.1111/ecc.12626 </li>
    <li>Rupani, M. P., Pawar, A. B., Bansal, R. K., Patel, P. B., Shah, P. S., & Parikh, K. D. (2015). Cross-sectional study on socio-demographic and clinical correlates of depression among human immunodeficiency virus-positive patients in Surat City, Western India. Asia-Pacific Psychiatry, 7(4), 406–418. https://doi.org/10.1111/appy.12197 </li>
    <li>Rupani, M. P., Trivedi, A. V, Singh, M. P., Tundia, M. N., Patel, K. N., Parikh, K. D., & Parmar, V. B. (2016). Socio-demographic , Epidemiological and Environmental Determinants of Acute Gastroenteritis in Western India. Journal of Nepal Medical Association, 54(1), 8–16. http://jnma.com.np/jnma/index.php/jnma/article/view/2809/2538 </li>
    <li>Rupani, M. P., Vasava, B. C., Mallick, K. H., Gharat, V. V., & Bansal, R. (2012). Reaching community through school going children for sickle cell disease in Zankhvav Village of Surat District, Western India. Online Journal of Health and Allied Sciences, 11(2), 1–3. https://www.ojhas.org/issue42/2012-2-4.htm </li>
    <li>Trivedi, B. J., Trivedi, A. V., & Rupani, M. P. (2021). Cross-Sectional Study to Estimate Dependence among Tobacco Smokers Attending a Tertiary Care Government Hospital in Bhavnagar, Western India. National Journal of Community Medicine, 12(06), 133–139. https://doi.org/10.5455/njcm.20210529040045 </li>
    <li>Vadher, S., Desai, R., Panchal, B., Vala, A., Ratnani, I. J., Rupani, M. P., & Vasava, K. (2020). Burden of care in caregivers of patients with alcohol use disorder and schizophrenia and its association with anxiety, depression and quality of life. General Psychiatry, 33(4), e100215. https://doi.org/10.1136/gpsych-2020-100215 </li>
    <li>Arora, N. K., Swaminathan, S., Mohapatra, A., Gopalan, H. S., & Katoch, V. M. (2017). Research Priorities in Maternal , Newborn , & Child Health & Nutrition for India : An Indian Council of Medical Research-INCLEN Initiative. Indian Journal of Medical Research, 145(5), 611–622. https://doi.org/10.4103/ijmr.IJMR </li>
    <li>Banik, A., Trivedi, A., & Rupani, M. (2017). A Cross-Sectional Study of Assessment of Quality of Life among Adolescents with Type-1 Diabetes Mellitus in Bhavnagar, Gujarat. Healthline Journal, 8(2), 54–59. </li>
    <li>Chavan, V. U., Ramavataram, D., Patel, P. A., & Rupani, M. P. (2015). Evaluation of serum magnesium, lipid profile and various biochemical parameters as risk factors of cardiovascular diseases in patients with rheumatoid arthritis. Journal of Clinical and Diagnostic Research, 9(4). https://doi.org/10.7860/JCDR/2015/12206.5740 </li>
    <li>Chavda, P., Rupani, M., Shringarpure, K., & Mehta, K. (2019). Research Methodology and Biostatistics. In A. Kadri, R. Kundapur, A. M. Khan, R. Kakkar, A. Sheth, & N. Mangrola (Eds.), IAPSM’s textbook of Community Medicine (1st ed., pp. 192–227). Jaypee. </li>
    <li>Shaikh, Z., & Rupani, M. (2017). Acceptability of Voluntary Medical Male Circumcision among Indian Men: An Online study. Scholars Journal of Applied Medical Sciences, 5(3F), 1137–1141. https://doi.org/10.21276/sjams.2017.5.3.80 </li>
    <li>Dekhaiya, F., Hathila, T., Doshi, S., Mehta, N., Shah, S., & Rupani, M. (2015). A prospective study of arteriovenous fistula creation in chronic renal failure patients in Bhavnagar, Gujarat, western India. International Journal of Medical Science and Public Health, 5(2), 1–5. https://doi.org/10.5455/ijmsph.2016.1507201543 </li>
    <li>Fichadiya, N. C., Rupani, M. P., Bhalani, K. D., & Singh, M. P. (2016). Knowledge and practices related to swine flu in school students of Bhavnagar , Gujarat. The Journal of Medical Research, 2(6), 170–173. </li>
    <li>Gharat, V., Vasava, B., Patel, S., Rupani, M., & Modi, B. (2011). Evaluation of pre-test and post-test knowledge questionnaire after intensive ICTC team training among health care workers. National Journal of Community Medicine, 2(1), 161–162. </li>
    <li>Hathila, T. N., Patel, C. J., & Rupani, M. P. (2014). A cross-sectional study of clinical features and management of liver abscesses in a tertiary care hospital, Ahmedabad, Gujarat. National Journal of Medical Research, 4(3), 249–252. </li>
    <li>Hathila, T. N., Sangle, R. B., Doshi, S. A., Firdaus, D. A., Rupani, M. P., & Hathila, T. (2015). A prospective study of Estrogen, Progestrone & Her2Neu receptor in cases of breast cancer in a tertiary care hospital of Bhavnagar, Gujarat, Western India. National Journal of Medical Research, 5(3), 185–189. </li>
    <li>Joshi, N. H., Trivedi, A. V, & Rupani, M. (2019). Cross-Sectional Study on Assessment of the Knowledge of Mothers Regarding Identification of Developmental Milestones of Young Children at an Urban Area of Bhavnagar, Gujarat. Healthline Journal, 10(1), 19–26. </li>
    <li>Kumar, V. A., Tejas N, H., Sachin, B., Somani, R., Rupani, M., & Hathila, T. (2014). A comparative study of circumcision and preputioplasty in pediatric cases of phimosis: A prospective study in a tertiary care hospital, Bhavnagar, Gujarat. National Journal of Medical Research, 4(4), 326–329. </li>
    <li>Mehta, R., Rathod, J., Narang, D., & Rupani, M. (2012). Prospective Study of Epidural Steroids for Low Back Pain in Orthopaedic Department of a Tertiary Care Hospital in Surat City. National Journal of Community Medicine, 3(4), 740–743. </li>
    <li>Naik, A. K., Rupani, M. P., & Bansal, R. K. (2013). Evaluation of vaccine cold chain in urban health centers of municipal corporation of Surat city, western India. International Journal of Preventive Medicine, 4(12). </li>
    <li>Parikh, K., Mehta, N., Goswami, H., & Rupani, M. (2021). Glial Tumors : Can You Rely on MRI? An Analysis Showing Agreement with Histopathology in Western India. Walwalkar International Medical Journal, 8(2), 50–56. https://www.wimjournal.com/pdf/current_issue/A9 - vol-2.pdf </li>
    <li>Parmar, G., Kava, D., Mehta, S., Mallick, K., Prasad, R., Bansal, R., & Rupani, M. (2013). Socio-demographic, Clinical and Laboratory Profile of Leptospirosis Cases registered at SMIMER, Surat. National Journal of Community Medicine, 4(3), 507–511. </li>
    <li>Parmar, V. B., Rupani, M. P., & Trivedi, A. V. (2019). Social Determinants of Diabetes and Hypertension in an Urban Slum of Gujarat , Western India: A Cross-Sectional Study. Online Journal of Health and Allied Sciences, 18(1), 1–6. </li>
    <li>Patel, H. L., Marwadi, M. R., Rupani, M. P., & Patel, P. (2012). Prevalence and associated factors of back pain among dentists in South Gujarat. National Journal of Medical Research, 2(2), 229–231. </li>
    <li>Patel, P. B., Rupani, M. P., & Patel, S. S. (2013). Antenatal care registration and predicting factors of late registration among pregnant women. Tropical Doctor, 43(1), 9–12. https://doi.org/10.1177/0049475513480772  </li>
    <li>Rujul, D., Parth, D., Mehul, M., Rupani, M., & Desai, R. (2012). Prevalence and Correlates of Hypertension in the Slum Population of Surat City. National Journal of Medical Research, 2, 381–385. </li>
    <li>Vasava, B., Malick, K., Kairavee, D., Amit, P., Rupani, M., Modi, B., & Ak, N. (2010). Awareness about vaccine vial monitor at pulse polio booths. National Journal of Community Medicine, 1(2), 172–173. </li>
    <li>Vasava, B., Pravin, G., Rupani, M., Vipul, M., & Rajesh, C. (2010). Evaluation of intensive pulse polio immunization in district Dang during 2008. National Journal of Community Medicine, 1(2), 132–134. </li>
</ol>
	
</div>	
	
</div>
</div>

        </div>
    </div>
    );
}
export default DrMihir;