import React from 'react';
import { BrowserRouter, Routes, Route, Link, NavLink} from "react-router-dom";
import './carousel.css';
import NavbarMenu from './NavbarMenu.js';
function Rti() {
    return (
      <div> <NavbarMenu /><div class="manual">
        <div class="row container">
        <p class="mypathhead">
        <Link to="/"><span class="mypath">Home</span></Link>&gt;<span class="mypath">RTI</span>
        </p>
          
        </div>
        <h1 class="mypathhead">Right to Information Act, 2005</h1>
        <div class="scientistdetail">
        <div class="mytext">
       <h3 align="center"><strong>Right to Information Act, 2005<br></br> 
            17 items (manuals) prescribed in clause ( b) sub-section 1 of section 4 which every public authority required to publish within one hundred and twenty days from the enactment of this Act.<a name="profbody" id="profbody"></a></strong></h3>
            <ol >
                  <li>
                    <h5 align="justify"> <strong> Particulars of the Organisation, functions and duties </strong></h5>
                  </li>
                </ol>
				<ul>
<p align="justify"><strong>The ICMR-NIOH is the premier institute, under the aegis of the <a href="http://www.icmr.nic.in/" target="_blank">Indian Council Medical Research</a> under the <a href="http://www.dhr.gov.in/" target="_blank">Department of Health Research</a>, <a href="http://mohfw.nic.in/" target="_blank">Ministry of Health and Family Welfare</a>, Govt. of India.</strong></p>
  <p align="justify"><strong> The need for research in Occupational Health in the country was first appreciated by Indian Research Fund Association (IRFA), the forerunner of the Indian Council of Medical Research (ICMR). IRFA set up an Industrial Health Advisory Committee (IHAC) under the chairmanship of Col. Bozman in 1945. As per the recommendations of this Committee, in 1947, an Industrial Health Research Unit was created at the All India Institute of Hygiene and Public Health, Calcutta. In 1956, the IHAC recommended to the Government of India that priority should be given for the establishment of an Institute of Occupational Health Research during the Second Five Year Plan.</strong></p>
 <p align="justify"><strong>The National Institute of Occupational Health (NIOH), Ahmedabad, was established by the <a href="http://www.icmr.nic.in/" target="_blank">Indian Council of Medical Research</a>, New Delhi. The Institute started functioning as &quot;Occupational Health Research Institute&quot; (OHRI) at the B. J. Medical College, Ahmedabad, in the year 1966. The OHRI was rechristened as &quot;National Institute of Occupational Health&quot; (NIOH) in 1970 and moved to the present premises. To cater local needs of the Southern and Eastern regions, the Institute established two Regional Occupational Health Centres (ROHC) at Bangalore (1977) and Calcutta (1980). </strong></p>
 <p align="justify"><strong> Occupational Health is a sustained activity aimed at promotion and maintenance of highest degree of physical, mental and social well being of workers in all occupations. The ICMR - National Institute of Occupational Health (NIOH) has been established with the following objectives;</strong></p>
					</ul>

<ul>
                  <li>
                    <p align="justify">To promote intensive research to evaluate environmental stresses/factors at the workplace.</p>
                  </li>
                  <li>
                    <p align="justify">To promote the highest quality of occupational health through fundamental and applied research.</p>
                  </li>
                  <li>
                    <p align="justify">To develop control technologies and health programmes through basic and fundamental research and to generate human resources in the field.<br />
                    </p>
                  </li>
                </ul>
			
				<ol >
                  <li>
                    <h5> <strong> Power and duties of Officers and employees; </strong></h5>
                  </li>
                </ol>
               <ul>
                  <p align="justify"> <strong> The powers available at the Headquarter to  the DG and other officers and at the Institutes to the Director and Sr. AO are  contained in Appendix I, II and III of the Rules and Regulations of the Council. </strong></p>
				  </ul>
             
			   
                <ol >
                  <li>
                    <h5 align="justify"> <strong> Procedure followed in the decision making process, including channels of supervision and accountability; </strong></h5>
                  </li>
                </ol>
				<ul>
                <p align="center"><img src="../assests/images/gnrImg//org_chart.JPG" alt="Organization Chart" class="img-responsive" height="960" /></p>
				</ul>
				
				
                <ol >
                  <li>
                    <h5 align="justify"><strong>Norms set by it for the discharge of its function;</strong></h5>
                  </li>
                </ol>
               <ul>
                  <p align="justify"><strong>The Institute  discharges its functions in accordance with the provisions contained in its  Rules and Regulations, Bylaws and the extant administrative and financial norms  prescribed by the Government of India for its employees</strong></p>
				  </ul>
				  
              <ol>
                  <li>
                    <h5 align="justify"><strong>The rules, regulations, Instructions, manuals and records, held by it or under its control or used by its employees for discharging its function.</strong></h5>
                  </li>
                </ol>
                <ul>
                  <p align="justify"><strong>The Institute is under control of the Rules &amp; Regulations of the ICMR, and its Bylaws.</strong></p>
				  </ul>
				 
                <ol>
                  <li>
                    <h5 align="justify"><strong>Statement of categories of documents that are held by it or under its control</strong></h5>
                  </li>
                </ol>
                <ul>
                  <li><p align="justify">All service related files and registers of the employees</p> </li>
                  <li><p align="justify">All research projects related files and registers</p> </li>
                  <li><p align="justify">All files and registers related to financial statement of the institutes and projects</p></li>
                </ul>
				
				
                <ol>
                  <li>
                    <h5 align="justify"><strong>The particulars of any arrangement that exists for consultation with, or representation by the member of the public in relation to the formulation for its policies or implementation thereof; [NA] </strong></h5>
                  </li>
				 
                  <li>
                    <h5 align="justify"> <strong> Statement of the boards, councils, committees and other bodies consisting of two or more persons constituted as its part or for the purpose of its advice, and so to whether meetings of those boards, councils, committees and other bodies are open to the public, or the minutes of such meting are accessible for public;</strong></h5>
                  </li>
                </ol>
               <ul>
                  <p align="justify"><strong>Meetings are restricted only to the members.</strong> </p>
               </ul>
			  
                <ol>
                  <li>
                    <h5><strong>Directory of ICMR - NIOH officers and employees</strong></h5>
                    <h5><strong> [<Link to="/ScientistNIOH">[Scientist ]</Link></strong> </h5>
                    <h5>Technical [<Link to="/TechNioh">NIOH Ahmedabad</Link>,    ROHC(S) Banagalore] </h5>
                    <h5>Administrative [<Link to="/AdminNioh">NIOH Ahmedabad</Link>, ROHC(S) Banagalore]</h5>
                  </li>
                  <li>
                    <h5 align="justify"><strong>Monthly enumeration received by each of officers and employees. [ <a href="../assests/pdf/rti/NIOH_Emp_Salary_042019.pdf" target="_blank">CLICK HERE</a> ]</strong></h5>
                  </li>
                  <li>
                    <h5 align="justify"><strong>Budget allocated to the NIOH, plans expenditure disbursement etc.</strong> </h5>
                  </li>
                </ol>
				
                <p ><h5 align="center">Budget Grant for the year 2018-19 (Rs. in Cr) </h5></p>
                <table>
                  <tr>  
     <th >S. No. </th>
     <th >Detail of Budget Heads </th>
     <th >Amount</th>
    
  </tr>
  <tr>
  
     <td>I</td>
     <td >Grant-in-aid-Salaries</td>
     <td >23.50</td>
     
  </tr>
  <tr>
    <td>II</td>
    <td>Grant-in-aid-General</td>
    <td>19.20</td>
    
  </tr>
  <tr>
    <td >III</td>
    <td >Grant for Creation of Capital Assets</td>
    <td >0.10</td>
  </tr>
  <tr>
    <td ></td>
    <td >Total</td>
    <td >42.80</td>
    
  </tr>
</table>

				
                <ol start="12"><li>
                  <h5 align="justify"><strong>Manner of execution of subsidy programmes, including the amounts allocated and the details of beneficiaries of such programmes;</strong></h5>
                </li>
                </ol>
                 <ul>
                  <p align="justify"><strong>The Institute does not deal with any programme involving release of subsidy.</strong></p>
				  
                </ul>
        
			   
                <ol start="13">
                  <li>
                    <h5 align="justify"><strong>Particulars of recipients of concession, permits or authorisation granted by it;</strong></h5>
                  </li>
                </ol>
               <ul>
                  <p align="justify"><strong>The Institute does not grant any concessions/permits/authorizations, however the concession extended by the government to SC / ST / OBC / Physically Handicapped / Ex-Servicemen etc. are granted in service matter.</strong></p>
				  </ul>
		
                
                <ol start="14">
                  <li>
                    <h5 align="justify"><strong>Information held by the ICMR - NIOH in electronic form</strong></h5>
                  </li>
                </ol>
				<ul>
                <p align="justify"><strong>The information with regard to ICMR - NIOH is available in electronic form on NIOH website: <a href="../www.nioh.org" target="_parent">www.nioh.org</a></strong></p>
				</ul>
			
				
                <ol start="15">
                  <li>
                    <h5 align="justify"><strong>Paticulars of facilites available to citizens for obtaining information including the working hours of a library or reading room, if maintained for public use. </strong></h5>
                  </li>
                </ol>
                <ul>
                  <p align="justify"><strong>Library  can be access with prior permission.</strong></p>
                </ul>
			
				
                <ol start="16">
                  <li>
                    <h5 align="justify"><strong>The names designation and other particulars of the public information officers. 
                      [<Link to="/pio"> CLICK HERE</Link> ] </strong></h5>
                  </li>
                </ol>
                <table>
                  <tr>
                    <td><h5 align="center" class="style2"> <strong>How to Apply for seeking information under RTI Act 2005 </strong></h5></td>
                  </tr>
                  <tr>
                    <td align="center" ><h5 ><strong>Indian citizen residing in India</strong></h5></td>
                  </tr>
                  <tr>
                    <td align="justify"><ul>
                        <li> <p align="justify">Application fee of Rs.10/- (Rupees ten only) paid and Mode of payment (Cash/Demand Draft/Indian Postal Order payable to “<span class="style2">Director -  National Institute of Occupational Health Ahmedabad, Payable at Ahmedabad</span>”)</p></li>
                        <li>No fee is required to be paid if the requester belongs to <strong>below poverty line (BPL)</strong>category for which proof should be furnished.</li>
                    </ul></td>
                  </tr>
                  <tr>
                    <td align="center" ><h5 ><strong>Indian citizen residing in Abroad</strong></h5></td>
                  </tr>
                  <tr>
                    <td><ul>
                        <li>
                          <p align="justify">The applicant needs to get himself registered in either of the two websites <a href="httt://www.epostoffice.gov.in" target="_blank">httt://www.epostoffice.gov.in </a>or <a href="../www.indiapost.gov.in" target="_blank">www.indiapost.gov.in </a>and gnerated e-IPO so generated, by making payment using Debit or Credit card payable to “Director -  National Institute of Occupational Health Ahmedabad, Payable at Ahmedabad&quot;</p>
                        </li>
                      <li>
                          <p align="justify">A printout of e-IPO is required to be attached with the RTI application. If the RTI application is being filed electronically, e-IPO is required to be attached as an attachment.</p>
                      </li>
                      <li>
                          <p align="justify">This facility is only for purchasing an Indian Postal Order electronically. All the requirement for filling an RTI application as well as other provision regarding eligibility, time limit, exemption etc., as provided in the RTI Act 2005 will continue to apply.</p>
                      </li>
                    </ul></td>
                  </tr>
                </table>
                <ol start="17"><li>
                  <h5 align="justify"><strong>Such other information as may be prescribed and thereafter update these publications every year.</strong></h5>
                </li>
                </ol>
                <ul>
                  <li>updated on 23/03/2019 </li>
                </ul>
                <p>&nbsp;</p>
        </div>
        </div>
       
        
        </div>   

        </div>
    
    );
}
export default Rti;