import React from 'react';
import '../carousel.css';
import NavbarMenu from '../NavbarMenu.js';
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
function DrSiva() {
    return (
      <div> <NavbarMenu /><div class="manual">
        <div class="row container">
        <p class="mypathhead">
        <Link to="/"><span class="mypath">Home</span></Link>&gt;<Link to="/ScientistNIOH"><span class="mypath">Scientist's Profile</span></Link>&gt;<span class="mypath">NIOH Ahmedabad</span>
        </p>
          
        </div>
        <h1 class="mypathhead">Scientist's Detail</h1>
        <div class="scientistdetail">
      
            <div class="panel my-panel" >
      <div class="panel-heading my-panel-head" align="left"><strong>Basic Details</strong></div>
      <div class="panel-body" align="left">
      <table width="100%">
            <tr>
              <td width="28%" rowspan="5"><img src="../assests/images/niohScientist/psivaperumal.png" width="170px" height="170px" CLASS="dg-img"/></td>
              <td width="50%" ><p class="myfontSci trSci">Dr P Sivaperumal</p> </td>
              <td width="22%" ></td>
            </tr>
            
            <tr>
              
              <td class="myfontSci"><p class="myfontSci">Chemical sciences Division, Division Head </p></td>
             </tr>
            <tr>
              <td><p ><a href=""><i class="fa fa-envelope" aria-hidden="true"></i>   Email: sivaperumal.p@gov.in</a></p></td>
            </tr> 
            <tr>
              <td><p ><a href=""> <i class="fa fa-phone-square"></i>  Contact: 079-2268470</a></p></td>
            </tr>   
           
            </table>   
      </div>
    </div>
       
        <div class="panel my-panel" >
      <div class="panel-heading my-panel-head" align="left"><strong>Academic Qualification</strong></div>
      <div class="panel-body" align="left">M.Sc., M.Phil, PhD
</div>
    </div>
    <div class="panel my-panel" >
      <div class="panel-heading my-panel-head" align="left"><strong>Research Interest </strong></div>
      <div class="panel-body" align="left"><ul><li>Occupational& environmental Health</li>
<li>Food safety, residue analysisand Health risk assessment</li> 
<li>Method developmentand validation</li></ul></div>
    </div>
    <div class="panel my-panel" >
      <div class="panel-heading my-panel-head" align="left"><strong>Membership/Affliation </strong></div>
      <div class="panel-body" align="left">
      <ul>
	  <li>	Society of Fisheries Technologies (India)</li>

</ul>
</div>
</div>
<div class="panel my-panel">
      <div class="panel-heading my-panel-head" align="left"><strong>Fellowship/Awards </strong></div>
      <div class="panel-body" align="left"><ul>
	  <li>	Graduate Aptitude Test in Engineering (GATE) -2003, Department of Education of Human Resource Development, Govt. of India.</li>
<li>	National Eligibility Test (NET) 2004, Agricultural Scientist Recruitment Board (ASRB) -Indian Council of Agricultural Research (ICAR)</li>
<li>	Best poster award In appreciation of Scientific contribution award</li>

</ul>
</div>
</div>
<div class="panel my-panel">
      <div class="panel-heading my-panel-head" align="left"><strong>Publications </strong></div>
      <div class="panel-body" align="left">
	
      <ol>

      <li>Kottadiyil, D., Mehta, T., Thasale, R., &amp; <strong>Sivaperumal P.</strong> (2022). Determination and dietary risk assessment of 52 pesticide residues in vegetable and fruit samples by GC-MS/MS and UHPLC-QTOF/MS from Gujarat, India. Journal of Food Composition and Analysis: 104957.
      </li><br/><li> <strong>Sivaperumal P</strong>, Thasale R, Kumar D, Mehta TG, Limbachiya R. (2022). Human health risk assessment of pesticide residues in vegetable and fruit samples in Gujarat State, India. Heliyon. 8(10):e10876.
</li><br/><li> Ansari S, Manvar T, Kumar D, Gupta R, <strong>Sivaperumal P</strong> (2022). A rapid, efficient, reliable method for simultaneous determination of pesticide residues in green Chilli by gas chromatography-mass spectrometry. Asian Journal of Chemistry.34(9):2379–2385. http://dx.doi.org/10.14233/ajchem.2022.23820.
</li><br/><li> Kottadiyil D, Thasale R, Deore S, <strong>Sivaperumal P</strong> (2022). Method development and optimisation for analysis of pesticide residues in biological matrices by modified QuEChERS and gas chromatography–triple quadrupole mass spectrometry. International Journal of Environmental Analytical Chemistry, 1–15.
</li><br/><li> Mummadi, M. K., Pandurangi, R., Geddam, J. J. B., Sinha, S. N., Rajendran, A., <strong>Sivaperumal P</strong>, Ramachandrappa, N. K., Ramakrishna S.K and Sreenu, P. (2021). Investigation of an acute neurological outbreak in Eluru, India, 2020. PloS One, 16(11), e0259192.
</li><br/><li> <strong>Sivaperumal P</strong> and Nikhil Kulkarni. (2021). The Business process outsourcings industry and its associated occupational health, FSAI Journal, 25-27.
</li><br/><li> Divya Kottadiyil, Shital Deore and <strong>Sivaperumal P</strong> (2021). Novel Method Development for Extraction and Analysis of Pesticide Residues in Human Serum Samples. Asian Journal of Water, Environment and Pollution, 18, 4, 79-86,
</li><br/><li> <strong>Sivaperumal P</strong>, Salauddin, A., Ramesh Kumar, A., Santhosh, K. and Rupal, T. (2017). Determination of Pesticide Residues in Mango Matrices by Ultra High- Performance Liquid Chromatography Coupled with Quadrupole Time-of-Flight Mass Spectrometry. Food Analytical Methods, 10(7), 2346-2357 (IF – 3.3).
</li><br/><li>	Singh DP, Sahu MC, Pagdhune A, Viramgami A, <strong>Sivaperumal P</strong>, Rakesh B, Sarkar K. Viral load could be an important determinant for fomites based transmission of viral infections. J. Family Med Prim Care 2021; 10, 929-932. 
</li><br/><li>	Edgar Mata1, Andrés Robles, SukhDev Mishra, <strong>P. Sivaperumal. </strong>(2018) Systematic Review of the occupational health hazards in banana plantation workers due to pesticide exposure in the Caribbean Region of Costa Rica from 1999 to 2014, Tecnología en Marcha. 31, (5), 122-133
 </li><br/><li>	Mata, E; Robles, A; Mishra, SD; <strong>Sivaperumal, P.</strong> (2018). Comparison of the methods for determination of pesticide residue in fruits and vegetables in the locations of India and Costa Rica. Tecnología en Marcha. 31 (5), 134-142
 </li><br/><li>	Jhamtani, R., Shukla, S., <strong>Sivaperumal P.</strong>, Dahiya, M. and Agarwal, R. (2018). Impact of co-exposure of Aldrin and Titanium dioxide nanoparticles at bioChemical and molecular levels in Zebrafish. Environmental Toxicology and Pharmacology, 58, 141-155 (IF-2.31/ CI-8)
 </li><br/><li>	Sunil Kumar, Vineet Mishra, RiddhiThaker, Mansi Gor, <strong>Sivaperumal, P.</strong>, Pratiksha Joshi, HardikSheth, Idrish Shaikh, Anil K. Gautam&YogendraVerma, (2018). Role of environmental factors & oxidative stress with respect to in vitro fertilization outcome. Indian Journal of Medical Research 148, 125-133 (IF 1.5/CI-6)
 </li><br/><li>	Shrivastava, A., <strong>Sivaperumal</strong>, Padmini Srikantiahet al.(2017). Association of acute toxic encephalopathy with litchi consumption in an outbreak in Muzaffarpur, India, 2014: a case-control study. The Lancet Global Health, 5(4), 458-466 (IF – 14.8 / CI-58)   
 </li><br/><li>	Ramesh Kumar, <strong>P. Sivaperumal</strong> (2016). Analytical methods for the determination of biomarkers of exposure to phthalates in human urine samples, Trends in Analytical Chemistry, 75, 151–161 (IF-7.4/CI-23).
 </li><br/><li>	Ahmed MN, Sinha SN, Vemula SR, <strong>Sivaperumal P</strong>, Vasudev K, Ashu S, Mendu VV, Bhatnagar V. (2016). Accumulation of polychlorinated biphenyls in fish and assessment of dietary exposure: a study in Hyderabad City, India. Environmental Monitoring and Assessment, 188(2), 94 (IF-1.6/CI-9).
 </li><br/><li>	<strong>P. Sivaperumal</strong>, P. Anand, L. Riddhi. (2015). Rapid determination of pesticide residues in fruits and vegetables, using ultra-high-performance liquid chromatography/time-of-flight mass spectrometry, Food Chemistry, 168, 356-365 (IF-4.0/CI-76).
 </li><br/><li>	AakashShrivastava., <strong>Sivaperumal</strong>, et al., Outbreaks of Unexplained Neurologic Illness -Muzaffarpur, India, 2013–2014, MMWR, Centers Disease Control and Prevention, Vol.64/ No.3, 2015 (IF-14.5/CI-47)
</li><br/><li>	<strong>P. Sivaperumal</strong>, T.V. Sankar. (2013). Toxicity of Organophosphorus Insecticide- Methylparathion on Fish(Labeo rohita), Fishery Technology, 50, 47 – 51.
</li><br/><li>	<strong>P. Sivaperumal</strong>, T.V. Sankar. (2013). BioChemical alterations in freshwater fish Labeo rohita, exposed to organophosphorus insecticide, Methylparathion, Indian Journal of fisheries, 60(1): 145-147. (IF-05/CI-7)
</li><br/><li>	<strong>P. Sivaperumal</strong>, T.V. Sankar. (2013). Evaluation of Organophosphorus Insecticide, Methylparathion Toxicity in Fish (Labeo rohita), Fisheries Technology,  50(1):45-49.
</li><br/><li>	<strong>P. Sivaperumal</strong>, T.V. Sankar, (2011). Toxic effects of Methylparathion on antioxidant enzymes and target enzyme acetylcholinesterase activity in fresh water fish, Labeo rohita, Fishery Technologist, 48 (1), 59-56.
</li><br/><li>	<strong>P. Sivaperumal</strong>, T.V. Sankar, and P.G. Viswanathan Nair (2007). Heavy metal Concentration in fish, shellfish and fish products from internal markets on India vis-a-vis International standards. Food Chemistry, 102,612-620. (IF-2.5/CI-550).


</li><br/><li>	White Paper on Electronic Nicotine Delivery System Writing Committee: Joy Kumar Chakma, R.S. Dhaliwal1 & Ravi Mehrotra, Expect Committee Chairman: K.S. Reddy, <strong>P. Sivaperumal</strong>, Prashant Mathur, Rakesh Kapoor, Shiv Choudhary, G.C. Khilani, Sanjay Rai, G.K. Rath, Ambuj Roy, L. Swasticharan, S.C. Khurana, Ravi Mehrotra, Indian J Med Res, May 2019, 574-583 (IF -1.5/CI-2).
</li><br/><li>	<strong>Sivaperumal P.</strong>, Nikhil P K, Smith C, Shaikh S, Rajnish G, Rupal T, Tejal G. M(2022). Analysis of emerging contaminants in water samples: advances and challenges, Environmental Toxicology and Ecosystem, CRC Press, 1st edition, 35,9781032154947
</li><br/><li>	<strong>P. Sivaperumal</strong> (2017) “Applications in High Resolution Mass Spectrometry”, Applications of Liquid Chromatography Coupled with High-Resolution Mass Spectrometry for Pesticide Residue Analysis in Fruit and Vegetable Matrices, Ch-6, 165-198.


</li>

</ol>
	
</div>
	
</div>
</div>

        </div>
    </div>
    );
}
export default DrSiva;