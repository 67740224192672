import React from 'react';
import './carousel.css';
import {  Link } from "react-router-dom";
import NavbarMenu from './NavbarMenu.js';
function EdAFIH() {
    return (<div> <NavbarMenu />
        <div class="manual">
        <div class="row container">
        <p class="mypathhead">
        <Link to="/"><span class="mypath">Home</span></Link>&gt;<span class="mypath">Education & Training</span>&gt;<span class="mypath">AFIH</span>
        </p>
          
        </div>
        <h1 class="mypathhead">Associate Fellow of Industrial Health (AFIH)</h1>
        <div class="scientistdetail">
         
          <p style={{color:"Green",fontWeight:"bold",fontSize:"20px"}}><center>Objectives of the course</center></p>
          
          <div class="table-responsive-sm">
            
              <table width="90%"style={{fontSize:"16px"}} class="table table-bordered">
                <tr style={{border: "1px solid"}}>
                <ul>
            <li><strong>To identify and manage the occupational health disorders/ occupational diseases encountered in various industries in the country and to manage the industrial injuries caused by chemical intoxication, in general and in hazardous process industry in particular. </strong></li>
            <p>&nbsp;</p><li><strong>To  suggest  preventive  and  control measures  of  such  occupational health problems.</strong></li>
            <p>&nbsp;</p><li><strong>To advise, supervise and participate in the national occupational health programmes for the health protection 
            of industrial workers, improving national productivity and national prosperity.</strong></li>

            </ul>
                </tr>
               
              </table>
          
        
          </div>

         
          <p style={{color:"green",fontWeight:"bold",fontSize:"20px"}}><center>Prospectus and Application Form</center></p>
          <div class="table-responsive-sm">
                        <table width="90%"style={{fontSize:"16px"}} class="table table-bordered">
                <tr style={{border: "1px solid"}}>
                <ul>
                
                <li><strong>We are Currently taking Admissions in the Course</strong></li>
                    <p><a href="../assests/AFIH/AFIH_PROSPECTUS_NIOH_2023.pdf" target="_blank"><button type="button" class="btn btn-primary"style={{fontSize:"13px",borderRadius:"7px",margin:"20px"}}>Prospectus For ICMR-NIOH</button></a>
                    <a href="../assests/AFIH/AFIH_ICMR ROHC S Bangalore Brochure.pdf" target="_blank"><button type="button" class="btn btn-warning"style={{fontSize:"13px",borderRadius:"7px",margin:"20px"}}>Prospectus For ICMR-NIOH-ROHCS</button></a>
                    
                    <a href="../assests/AFIH/AFIH_Application_form_with_annexures.pdf" target="_blank"><button type="button" class="btn btn-success"style={{fontSize:"13px",borderRadius:"7px",margin:"20px"}}>Application Form</button></a>
                    <a href="../assests/AFIH/NOTICE_EXTENSION_DATE_AFIH_2022-23.pdf" target="_blank"><button type="button" class="btn btn-danger"style={{fontSize:"13px",borderRadius:"7px",margin:"20px"}}>Date Extend for Application Form</button></a>
                    
                    <li><strong>Interview Dates for AFIH course-2023  is out. Click on below Button for details</strong></li>    
                    
                    <a href="../assests/AFIH/AFIH 2023_16032023125509.pdf" target="_blank"><button type="button" class="btn btn-primary"style={{fontSize:"13px",borderRadius:"7px",margin:"20px"}}>Interview Dates for ICMR-NIOH-ROHCS</button></a>
                    <a href="../assests/AFIH/Notice_of_AFIH_2023_course_NIOH.pdf" target="_blank"><button type="button" class="btn btn-warning"style={{fontSize:"13px",borderRadius:"7px",margin:"20px"}}>Interview Dates for ICMR-NIOH</button></a>
                    <li><strong>Interview Details for AFIH course-2023  is out.</strong></li>    
                    
                    <a href="../assests/AFIH/ROHC S Bangalore _AFIH 2023 INTERVIEW Schdule.pdf" target="_blank"><button type="button" class="btn btn-primary"style={{fontSize:"13px",borderRadius:"7px",margin:"20px"}}>Interview Detail for ICMR-NIOH-ROHCS</button></a>
                    <a href="../assests/AFIH/List_of_candidates_for_Virtual_Interview.pdf" target="_blank"><button type="button" class="btn btn-warning"style={{fontSize:"13px",borderRadius:"7px",margin:"20px"}}>Interview Detail for ICMR-NIOH</button></a>
                    <li><strong>List of selected Candidate for AFIH course-2023  is out.</strong></li>    
                    
                    <a href="../assests/AFIH/ICMR_ROHC S Bengaluru AFIH 2023 Selected and WL candidates List.pdf" target="_blank"><button type="button" class="btn btn-primary"style={{fontSize:"13px",borderRadius:"7px",margin:"20px"}}>Selected Candidate for ICMR-NIOH-ROHCS</button></a>
                    
                     <a href="../assests/AFIH/List of provisionally selected cnadidates at ICMR NIOH.pdf" target="_blank"><button type="button" class="btn btn-warning"style={{fontSize:"13px",borderRadius:"7px",margin:"20px"}}>Selected Candidate for ICMR-NIOH</button></a>
                     <li><strong>Admission Process and Fees Submission for AFIH course-2023  is out.</strong></li>    
                    
                    <a href="../assests/AFIH/ICMR ROHC S Bengaluru AFIH 2023 Admission Process.pdf" target="_blank"><button type="button" class="btn btn-primary"style={{fontSize:"13px",borderRadius:"7px",margin:"20px"}}>Admission Process for ICMR-NIOH-ROHCS</button></a>
                    <li><strong>Notice Regarding Vacant Seats</strong></li>    
                    
                    <a href="../assests/AFIH/vacant seats.pdf" target="_blank"><button type="button" class="btn btn-warning"style={{fontSize:"13px",borderRadius:"7px",margin:"20px"}}>Vacant Seats for ICMR-NIOH</button></a>
                    </p>
                    
                    
                    </ul>
               
                </tr>
                
                
              </table>
        
          </div>
         
          <p style={{color:"Green",fontWeight:"bold",fontSize:"20px"}}><center>Importance of the Course &amp; Accreditation</center></p>
          <div class="table-responsive-sm">
            
              <table width="90%"style={{fontSize:"16px"}} class="table table-bordered">
                <tr style={{border: "1px solid"}}>
                <ul>
<li><p align="justify"><strong>Associate Fellow of Industrial Health (AFIH)</strong> is a three months full time Post Graduate Certificate course in Industrial Health approved by <a href="http://www.dgfasli.nic.in/" title="Home page of DGFASLI" target="_blank">Directorate General Factory Advice Service &amp; Labour Institutes (DGFASLI),</a> Ministry of Labour &amp; Employment, Govt. of India. On completion of course an examination will be conducted by DGFASLI and successful candidates will be awarded Associate Fellow of Industrial Health (AFIH) which will fulfill the requirements in terms of additional qualification for Factory Medical Officers of Hazardous Process Industries as required under the Factories (Amendment) Act 1987.</p></li>

</ul>
                </tr>
                
                
              </table>
        
          </div>  
            

          <p style={{color:"Green",fontWeight:"bold",fontSize:"20px"}}><center>Duration of the Course and Seats</center></p>
          <div class="table-responsive-sm">
            
              <table width="90%"style={{fontSize:"16px"}} class="table table-bordered">
                <tr style={{border: "1px solid"}}>
                <ul>
<li><strong>Duration </strong>: 3 Months </li>
  <li>
    <p align="justify"><strong>Number of Seats</strong>:  Ahmedabad- 25, Bengaluru- 25</p></li>

</ul>
                </tr>
                
                
              </table>
        
          </div>  
          
          <p style={{color:"Green",fontWeight:"bold",fontSize:"20px"}}><center>Further Contact</center></p>
          <div class="table-responsive-sm"  style={{borderRadius:"10px"}}>
            
              <table width="90%"style={{fontSize:"16px"}} class="table table-bordered">
                <tr style={{border: "1px solid"}}>
                <td style={{border: "1px solid"}}><h2 >Director</h2>
  <p>  <strong>  ICMR-National Institute of Occupational Health<br />
    Meghani Nagar, Ahmedabad-380016, Gujarat, INDIA<br />
    <br/>
    Phone : +91- 79-22688700, 22686351,    (PS to Director): 22688709, 22686340 , 
    Fax : +91-79-22686110<br />
    Email : afihnioh[at]gmail[dot]com, director-nioh[at]gov[dot]in </strong></p>  
    </td>
    </tr>
   <tr style={{border: "1px solid"}}>
   <td style={{border: "1px solid"}}><h4 >Admission at ROHC(S) Bengaluru</h4></td>
    </tr>
    <tr style={{border: "1px solid"}}>
  <td style={{border: "1px solid"}}><h2 >Dr B. Ravichandran</h2>
  <strong>Scientist-E and Officer-in-Charge <br/>
    Regional Occupational Health Centre (Southern),<br/>
    ICMR Complex, Kannamangala PO, Poojanahalli Road<br/>
    Devanahalli Taluk, Bengaluru-562110, Karnataka, INDIA<br/>
    Phone: +91-080-22172500, Fax: +91-080- 22172502 <br/>
      Email: ravichandran[dot]b[at]gov[dot]in</strong>
</td>
                </tr>
                
                
              </table>
        
          </div>
        
          

        </div>
       
        </div>

        </div>
    
    );
}
export default EdAFIH;