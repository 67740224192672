import React from 'react';
import '../carousel.css';
import NavbarMenu from '../NavbarMenu.js';
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
function DrShivKumar() {
    return (
      <div> <NavbarMenu /><div class="manual">
        <div class="row container">
        <p class="mypathhead">
        <Link to="/"><span class="mypath">Home</span></Link>&gt;<Link to="/ScientistNIOH"><span class="mypath">Scientist's Profile</span></Link>&gt;<span class="mypath">NIOH Ahmedabad</span>
        </p>
          
        </div>
        <h1 class="mypathhead">Scientist's Detail</h1>
        <div class="scientistdetail">
      
            <div class="panel my-panel" >
      <div class="panel-heading my-panel-head" align="left"><strong>Basic Details</strong></div>
      <div class="panel-body" align="left">
      <table width="100%">
            <tr>
              <td width="28%" rowspan="5"><img src="../assests/images/niohScientist/shiv.jpg" width="170px" height="170px" CLASS="dg-img"/></td>
              <td width="50%" ><p class="myfontSci trSci">Dr ShivKumar S Prajapati</p> </td>
              <td width="22%" ></td>
            </tr>
            
            <tr>
              
              <td class="myfontSci"><p class="myfontSci">Chemical sciences Division, Division Head </p></td>
             </tr>
            <tr>
              <td><p ><a href=""><i class="fa fa-envelope" aria-hidden="true"></i>   Email: prajapati.shiv@icmr.gov.in</a></p></td>
            </tr> 
            <tr>
              <td><p ><a href=""> <i class="fa fa-phone-square"></i>  Contact: To be Update</a></p></td>
            </tr>   
           
            </table>   
      </div>
    </div>
       
        <div class="panel my-panel" >
      <div class="panel-heading my-panel-head" align="left"><strong>Academic Qualification</strong></div>
      <div class="panel-body" align="left">M.Sc (Chemistry) Ph.D.(Chemistry)
</div>
    </div>
    <div class="panel my-panel" >
      <div class="panel-heading my-panel-head" align="left"><strong>Research Interest </strong></div>
      <div class="panel-body" align="left"><ul><li>Air pollutant monitoring </li><li>Industrial hygiene (dust, noise, vibration, and illumination) assessment</li></ul></div>
    </div>
    <div class="panel my-panel" >
      <div class="panel-heading my-panel-head" align="left"><strong>Membership/Affliation </strong></div>
      <div class="panel-body" align="left">
      <ul>
	  <li>	To be Update</li>

</ul>
</div>
</div>
<div class="panel my-panel">
      <div class="panel-heading my-panel-head" align="left"><strong>Fellowship/Awards </strong></div>
      <div class="panel-body" align="left"><ul>
	  <li>	To be Update</li>

</ul>
</div>
</div>
<div class="panel my-panel">
      <div class="panel-heading my-panel-head" align="left"><strong>Publications </strong></div>
      <div class="panel-body" align="left">
	
      <ol>

      <li>	<strong>Shivkumar S. Prajapati</strong>, Rahul A. Mishra, Balram  & Sarang V. Dhatrak (2020):Respirable dust and crystalline silica exposure among different mining sectors in India, Archives of Environmental and Occupational Health 76(855):1-7, May 2021, DOI:10.1080/19338244.2021.1919857, SCI indexd and IF 1.2
      </li><br/><li>	<strong>Shivkumar S. Prajapati</strong>, Subroto S. Nandi, Anand Deshmukh & Sarang V. Dhatrak (2020): Exposure profile of respirable crystalline silica in stone mines in India, Journal of Occupational and Environmental Hygiene, DOI: 10.1080/15459624.2020.1798011. SCI indexd and IF 1.65
      </li><br/><li>	<strong>Shivkumar S. Prajapati*</strong>, Rahul A. Mishra, Balram , Anand A Deshmukh . Whole body vibration exposure experienced by dumper operators according to ISO 2631-1: 1997 and ISO 2631-5: 2004. A case study: Indian J Occup Environ Med 2020; 24:114-8. SCI Emerging and IF
      </li><br/><li>	Bibhuti B Mandal, Ashis Bhattacharjee, <strong>Shivkumar Prajapati</strong> and Syed Aftab Hussain. A comparative study of transmissibility factors of traditional and pneumatic dumper seats using one-third octave band analysis. Noise & Vibration Worldwide 1-10, 2020 https://doi.org/10.1177/0957456520924816, Scopus Indexes 
      </li><br/><li>	Vijay M. Tangde, <strong>Shivkumar S. Prajapati</strong>, Niraj T. Khaty. Activation and utilization of waste material of alumina industry: Red mud. Materials Today: Proceedings 29(2020) 753-759.Scopus Indexes 
      </li><br/><li>	Vijay. M. Tangde, <strong>Shivkumar S. Prajapati*</strong>, B. B. Mandal & N. P. Kulkarni . Study of Kinetics and Thermodynamics of Removal of Phosphate from Aqueous Solution using Activated Red Mud.  Int J Environ Res (2017) 11:39–47, DOI 10.1007/s41742-017-0004-8.-SCI Index and IF-1.52
      </li><br/><li>	 <strong>Shivkumar S. Prajapati</strong>, P. A. Mohamed Najar and Vijay M. Tangde. Removal of Phosphate Using Red Mud: An Environmentally Hazardous Waste By-Product of Alumina Industry. Advances in Physical Chemistry.
      </li><br/><li>	 Anand A deshmukh, <strong>Shivkumar S Prajapati*</strong> and Rahul A Mishra. Occupational Exposure of Noise Level in Opencast Iron Ore Mines in India.  Current World Environment. Vol.  13, No. (3) 2018, 987-993, Published by Enviro Research Publishers.
      </li><br/><li>	 Bibhuti B Mandal, <strong>Shivkumar S Prajapati*</strong>, Syed Aftab Hussain and Rahul Anup Mishra. Monitoring and Evaluation of Whole-Body Vibration Exposure of Equipment Operators and Assessment of Associated Health Risk in an Indian Underground Pb-Zn Mine. Current world envionment Vol. 13, No. (3) 2018, Pg.741-750. 
      </li><br/><li>	Vijay M Tangde and <strong>Shivkumar S Prajapati*</strong>. Sorption studies of Fluoride from aqueous solution using activated Red Mud. Indian Journal of Environmental Protection (IJEP):779-786(2019)—Scopus Index: 
      </li><br/><li>	<strong></strong>Shivkumar S. Prajapati, Rahul A. Mishra, Balram  , Exposure Assessment of Resipirable Dust For Opencast Limestone Mines India Indian Journal of Environmental Protection (IJEP):779-786(2019)—Scopus Index: 

      </li>

</ol>
	
</div>
	
</div>
</div>

        </div>
    </div>
    );
}
export default DrShivKumar;