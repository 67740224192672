import { useState } from "react";
import React, { useRef } from 'react';
import './carousel.css';
import emailjs from 'emailjs-com';
import styled from "styled-components";

import {  Link } from "react-router-dom";
import NavbarMenu from './NavbarMenu.js';
function GuestHouse() {
    const form = useRef();
    function sendEmail(e) {
        e.preventDefault();    //This is important, i'm not sure why, but the email won't send without it
    
        emailjs.sendForm('service_nq5iqrk', 'template_swc3jzg', e.target, '9-PLqF38sXZiv3yOM')
          .then((result) => {
              window.location.reload()  //This is if you still want the page to reload (since e.preventDefault() cancelled that behavior) 
          }, (error) => {
              console.log(error.text);
          });
      }
    return (
        <div> <NavbarMenu /><div class="manual">
        
        <div class="row container">
        <p class="mypathhead">
         <Link to="/"><span class="mypath">Home</span></Link>&gt;<span class="mypath">Guest House</span>
        </p>
          
        </div>
        <h1 class="mypathhead">Guest House</h1>
        <div class="scientistdetail">
            <div class="mytext">
                <ol>
            <li><p  align="justify">To Make Booking in GuestHouse click on Button </p><a href="../assests/GuestHouse/NIOH_Booking request.docx" download><button type="button" class="btn btn-danger"style={{fontSize:"20px",borderRadius:"7px",margin:"20px"}}>Application Form</button> </a></li>
            <li><p  align="justify">On Button Click, Guest House Form will be downloaded in your System in Microsoft-word Format</p></li>
            <li><p  align="justify">Please Read Instructions from Form before Filling</p></li>
            <li><p  align="justify">Please send Complete filled form together with Govt. ID Card and ADHAR-ID to the mail id: prajapati.shiv@icmr.gov.in , anu.minhas@icmr.gov.in and cc to director-nioh@icmr.gov.in</p></li>
            <li><p  align="justify">If the form duly filled and have all attachments correct,only then we will send an acknowledgment. </p></li>
            </ol>
            </div>
       
    </div><br/><br/></div><br/><br/><br/><br/></div>
    );
}

export default GuestHouse;


