import React from 'react';
import '../carousel.css';
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import NavbarMenu from '../NavbarMenu.js';
function DrAkshya() {
    return (
      <div> <NavbarMenu /><div class="manual">
        <div class="row container">
        <p class="mypathhead">
        <Link to="/"><span class="mypath">Home</span></Link>&gt;<Link to="/ScientistROHCS"><span class="mypath">Scientist's Profile</span></Link>&gt;<span class="mypath">ICMR-ROHCS Bengaluru</span>
        </p>
          
        </div>
        <h1 class="mypathhead">ICMR-ROHCS Scientist's Detail</h1>
        <div class="scientistdetail">
      
            <div class="panel my-panel" >
      <div class="panel-heading my-panel-head" align="left"><strong>Basic Details</strong></div>
      <div class="panel-body" align="left">
      <table width="100%">
            <tr>
                <td width="28%" rowspan="5"><img src="../assests/images/rohcsScientist/bravichandran.jpg" width="180px" height="170px" CLASS="dg-img"/></td>
                <td width="50%" ><p class="myfontSci trSci"> Dr B. Ravichandran</p> </td>
                <td width="22%" ></td>
                
            </tr>
            <tr>
              <td><p ><a href=""><i class="fa fa-envelope" aria-hidden="true"></i>   Email: ravichandran.b@gov.in</a></p></td>
            </tr> 
            <tr>
              <td><p ><a href=""> <i class="fa fa-phone-square"></i> Contact: 080-22172500</a></p></td>
            </tr>
           
            </table>   
      </div>
    </div>
       
        <div class="panel my-panel" >
      <div class="panel-heading my-panel-head" align="left"><strong>Academic Qualification</strong></div>
      <div class="panel-body" align="left">
      MSc (Environmental Toxicology) PhD. PG.Dip.Env.Law
</div>
    </div>
    <div class="panel my-panel" >
      <div class="panel-heading my-panel-head" align="left"><strong>Research Interest </strong></div>
      <div class="panel-body" align="left">
      Industrial hygiene, environmental exposure monitoring, biomarkers studies, environmental and occupational toxicology,Workplace assessments for health effects related to occupational and environmental exposures.Occupational health surveillance programs at Industrial Settings

</div>
</div>
<div class="panel my-panel">
      <div class="panel-heading my-panel-head" align="left"><strong>Membership/Affliation </strong></div>
      <div class="panel-body" align="left">
      <ul><li>Indian Association of Occupational Health</li>
<li>Environmental Mutagen Society of India</li>
<li>Society of Toxicology</li>
<li>International Commission on Occupational Health</li>
<li>Scientific Committee on Occupational Toxicology - ICOH


</li>

</ul>
</div>
</div>
<div class="panel my-panel">
      <div class="panel-heading my-panel-head" align="left"><strong>Fellowship/Awards </strong></div>
      <div class="panel-body" align="left">
      <ul><li>	L & T Oration award of Indian Association of Occupational Health (2015)</li><br/>
	  <li>	Sri Mohanmul Award For Research in Industrial Medicine (2004)</li>
	  <li>	UGC JRF Fellowship, Govt of India (1989)</li>


</ul>
</div>
</div>
<div class="panel my-panel">
      <div class="panel-heading my-panel-head" align="left"><strong>Publications </strong></div>
      <div class="panel-body" align="left">
      <ol>
	<li>Dhananjayan V, Panjakumar, <strong>R Beerappa</strong>, D Sharma, M Aambikapathy et al., (2021). Evaluation of work place pesticide concentration and health complaints among women workers in tea plantation, Southern India. Journal of Exposure Science & Environmental Epidemiology, 1-11.
</li><br/><li>Thiyagarajan P and <strong>Ravichandran B</strong> (2020). Prevalence of Occupational Ocular Morbidities and its Prevention Practices among Unorganized Welders from a city in South India. Indian J. Prev. Soc. Med 51 (3), 120-124.
</li><br/><li><strong>Ravichandran B</strong>,Avinash Gaikwad, Mala A, Panjakumar K, ShridharKondhalkar, V. Dhananjayan. Self reported health complaints and knowledge on use of pesticides and their safety practices among Indian grape garden workers. (Accepted, Journal of Environmental Science and Engineering. 2020)
</li><br/><li><strong>Ravichandran B</strong>, Dhananjayan V, Mala A, Sen S, Raghavan S (2020). Assessment of occupational and respiratory health implications among sheep breeding and wool shearing workers in Karnataka, india. Central European Journal of Occupational and Environmental Medicine 26-66
</li><br/><li>Gaikwad AS, R Mahmood, <strong>R Beerappa</strong>, P Karunamoorthy, D Venugopal (2020). Mitochondrial DNA copy number and Cytogenetic damage among Fuel Filling Station Attendants. Environmental and Molecular Mutagenesis, 61 (8): 820-829.
</li><br/><li>V Dhananjayan, P Jayanthi, S Jayakumar, <strong>B Ravichandran</strong> (2020). Agrochemicals Impact on Ecosystem and Bio-monitoring. In Book: Resources Use Efficiency in Agriculture, Springer. 349-388.
</li><br/><li>Avinash S Gaikwad, Riaz Mahmood, <strong>B Ravichandran</strong>, ShridharKondhalkar (2020). Evaluation of telomere length and genotoxicity among asphalt associated workers. JournalMutation Research/Genetic Toxicology and Environmental Mutagenesis. Volumes 858–860, October–December 2020.
</li><br/><li>Panjakumar K, Rajesh EM, <strong>Ravichandran B</strong>, Dhananjayan V, Mala A (2020). Prevalence of Occupational Skin Diseases and its Predisposing Factors in Leather Tanning Workers of Southern India. Current World Environment 15 (3).
</li><br/><li>V Dhananjayan, S Jayakumar, <strong>B Ravichandran</strong> (2020). Conventional methods of pesticide application in agricultural field and fate of the pesticides in the environment and human health. In Book: Controlled release of pesticides for sustainable agriculture. Springer. Pages1-39. 
</li><br/><li>Dhananjayan V, Jayakumar S, Jayanthi P, <strong>Ravichandran B</strong> (2020). Agrochemicals Impact on Ecosystem and Bio-monitoring. In: Resource Use Efficiency in Agriculture. Book Chapter, Springer. 
</li><br/><li>Dhananjayan V, Jayakumar S, <strong>Ravichandran B</strong> (2020). Conventional methods of pesticide application in agricultural field and fate of the pesticides on the environment and human health. Eds (Thomas S et al.), In: Controlled Release of Pesticides for Sustainable Agriculture. ISBN 978-3-030-23395-2, Cham, Springer.
</li><br/><li>V Dhananjayan, <strong>B Ravichandran</strong>, K Panjakumar, K Kalaiselvi, KausicRajasekar, A Mala, G Avinash, K Shridhar, A Manju, Rajesh Wilson (2019). Assessment of genotoxicity and cholinesterase activity among women workers occupationally exposed to pesticides in tea garden. JournalMutation Research/Genetic Toxicology and Environmental Mutagenesis. Volume 841 Pages 1-7.
</li><br/><li>Dhananjayan V, <strong>Ravichandran B</strong>, Sen S, Panjakumar K (2019). Source and health effects of nanoparticles with special reference to occupational exposure. In: Nanoarchitectonics in Biomedicine, Recent progress of nanoarchitectonics in biomedical science. Ed: Alexandru MG, pp 643-676, ISBN 9780128162002, Elsevier.
</li><br/><li>Dhananjayan V, <strong>Ravichandran B</strong> (2018). Occupational health risk of farmers exposed to pesticides in agricultural activities. Current Opinion in Environmental Science & Health, Vol 4, Pages 31-37. 
</li><br/><li>Manju A, Kalaiselvi K, Dhananjayan V, Palanivel M, Banupriya GS, Vidhya MH, Panjakumar K, <strong>Ravichandran B</strong> (2018). Spatio-seasonal variation in ambient air pollutants and influence of meteorological factors in Coimbatore, Southern India. Air QualAtmos Health 11:1179-1189
</li><br/><li>Sen S, Narayana J, <strong>Ravichandran B</strong>, Dhananjayan V (2018). Polyaromatic hydrocarbons depositions and their carcinogenic risk assessment in the foundry workers. Aerosol Science and Engineering 2 (4):173–181
</li><br/><li>Sly PD, Arphacharus N, Aung WP, Collman GW, Gamble MY, Graziano J, Hai DN, Henshaw DL, Navasumrit P, <strong>Ravichandran B</strong>, et al., (2017). South-East Asian Children’s Environmental Health: networkiing to improve health outcomes. View Point, 3:15-18.
</li><br/><li>Sen S, Narayana J, <strong>Ravichandran B</strong>, Dhananjayan V, Gaikwad AS (2016). Biological monitoring of PAHs exposure among the foundry workers, Int. J. of Research in Chemistry and Environment. Int  J Research in Chemistry and Environment 6:37-41
</li><br/><li>Somnath Sen, Jogattappa Narayana, Ramachandran Gurumurthy, <strong>Beerappa Ravichandran</strong>; Workplace Respirable Dust Monitoring and Risk Factor Assessment in Foundry Process. IOSR Journal of Environmental Science, Toxicology and Food Technology. (2016); 10(10), Pp 87-91. 
</li><br/><li>Ravibabu K, Barman T, Rajmohan HR, Bagepally BS, <strong>Ravichandran B</strong> (2016) Effectiveness of interventions on biological monitoring among workers exposed to Pb from lead-acid storage battery plant.Int J Med Sci Public Health;5.
</li><br/><li>A Saha, AK Mukherjee and <strong>B Ravichandran</strong>: Musculoskeletal problems and fluoride exposure: A cross-sectional study among metal smelting workers: ToxicolInd Health, 2015.
</li><br/><li>AvinashShivaji Gaikwad, PanjakumarKarunamoorthy, ShridharJagannathKondhalkar, Mala Ambikapathy and <strong>RavichandranBeerappa</strong>:  Assessment of hematological, biochemical effects and genotoxicity among pesticide Sprayers in grape garden. Journal of Occupational Medicine and Toxicology, 10:11, 2015.
</li><br/><li>Dhananjayan V, <strong>Ravichandran B</strong>: Organochlorine Pesticide Residues in Foodstuffs, Fish, Wildlife and Human Tissues from India: Historical Trend and Contamination Status. In: Abdul Malik and Elisabeth Grohmann (eds), Environmental Deterioration and Human Health: Natural and anthropogenic determinants.  Springer, The Netherlands, pp: 229-262, 2014.
</li><br/><li>Dhananjayan V, <strong>Ravichandran B</strong>,Nithya, R, Palanivel M, Kalaiselvi K: Levels of Environmental Pollutants and Urinary 1-Hydroxypyrene among Foundry Workers. Research & Reviews: J of Toxicology, 4 (1):6-113, 2014.
</li><br/><li>Dhananjayan V, <strong>Ravichandran B</strong> (2014). Organochlorine pesticide residues in foodstuffs, fish, wildlife and human tissues from India: historical trend and contamination status. In: Abdul Malik and Elisabeth Grohmann (eds), Environmental Deterioration and Human Health: Natural and anthropogenic determinants. Chapter 10, pp229-262, ISBN: 978-94-007-7889-4, Dordrecht, Springer.
</li><br/><li>Somnath Sen, <strong>B Ravichandran</strong>,A.Manju, M Palanivel S Deepa: Assessment of heat stress and noise intensity level in workplace area of the iron foundry. Journal of Industrial Safety Engineering, Vol 1, (1), 32-36, 2014.
</li><br/><li>Sen S, Narayana J, <strong>Ravichandran B</strong>,Dhananjayan V, Gaikwad AS (2016). Biological monitoring of PAHs exposure among the foundry workers, Int. J. of Research in Chemistry and Environment. 6:37-41.
</li><br/><li><strong>Ravichandran B</strong>, Dhananjayan V, Somnath Sen, Mala A, Rajmohan HR (2013). Assessment of 8-oxo-7,8-dihydro-2’-deoxyguanosine as a marker of oxidative DNA damage in gasoline filling station attendants. Int J Occup Med Environ Health 26:780-789.
</li><br/><li><strong>Ravichandran B</strong>, S Chattopadhyay, P.K Gangopadhyay, and H.N Saiyed:  Evaluation of Haematological Changes in Population Exposed to Fluoride. Toxicological and Environmental Chemistry. Vol. 94, No.10, pp 2052-2056, 2012.
</li><br/><li><strong>Ravichandran B</strong>, Mala A, Hema N and Rajmohan H.R: Biomonitoring of Oxidative DNA Damage in Traffic Policemen Exposed to Urban Air Pollution. Genes and Environment. Vol. 34, No.1 pp 45-49, 2012.
</li><br/><li><strong>Ravichandran B</strong>,Roychowdhury A, Bhattacharya S.K, Mukherjee A.K, Gangopadhyay P.K and Saiyed H.N: Fluoride Levels In Drinking Water and Other Surface Water of an Industrial Area Belt of Orissa State in India. International Journal of Environment and Pollution. Vol 49, 1/2 55-6, 2012
</li><br/><li>S Sen, Narayana J, <strong>Ravichandran B</strong>, Rajesh R. Assessment of Indoor Air Pollutants with Special Reference to Particulate Phase Poly aromatic Hydrocarbon in Rural Village. Research & Reviews: A Journal of Toxicology. Vol 2, No2, 2012.
</li><br/><li>Dhananjayan V, <strong>Ravichandran B</strong>,Rajmohan HR: Organochlorine pesticide residues in blood samples of agriculture and sheep wool workers in Bangalore (rural), India. Bulletin of Environmental Contamination and Toxicology, 88(4):497-500, 2012.
</li><br/><li>Dhananjayan V, <strong>Ravichandran B</strong>, Anitha N, Rajmohan HR (2012). Assessment of acetylcholinesterase and butyrylcholinesterase activity in blood plasma of agriculture workers. Indian J Occup Environ Med 16(3):127-130 
</li><br/><li>Dhananjayan V, <strong>Ravichandran B</strong>, Rajmohan HR (2012). Organochlorine pesticide residues in blood samples of agriculture and sheep wool workers in Bangalore (rural), India. Bull Environ ContamToxicol, 88(4):497-500.
</li><br/><li>Mala A, <strong>Ravichandran B</strong>,Ragavan S, H R Rajmohan: Multinomial logistic regression model to assess the levels in trans, trans-muconic acid and inferential-risk age group among benzene exposed group. Indian Journal of Occupational and Environmental Medicine. Vol.14, 39-41, 2011. 
</li><br/><li>K Golka, Y Abreu-Villaca, Attar R Anbari, M Angeli-Greaves, M Aslam, N Basaran, R Belik, C Butryee, O Dalpiaz, K Dzhusupov, TH Ecke, H Galambos, H Gerilovica, H Gerullis, PC González, ME Goossens, L Gorgishvili-Hermes, CF Heyns, J Hodzic, F Ikoma, P Jichlinski, BH Kang, E Kiesswetter, K Krishnamurthi, ML Lehmann, I Martinova, RD Mittal, <strong>B Ravichandran</strong>, I Romics, B Roy, F Rungkat-Zakaria, K Rydzynski, C Scutaru, J Shen, M Soufi, K Toguzbaeva, Duc T Vu, A Widera, M Wishahi, JG Hengstler: Bladder cancer documentation of causes: multilingual questionnaire,'bladder cancer doc'. Frontiers in bioscience. Vol. 4, pp 2809-2822, 2011.
</li><br/><li>Mukherjee A.K, <strong>Ravichandran B</strong>, Bhattacharya S.K, Roy SK, Ahmed S, Thakur S and Saiyed H.N: Industrial Hygiene Survey in Aluminium Reduction Plant In India. Journal of University of Occupational and Environmental Health, Vol 30, No.2, 253-268, 2008.
</li><br/><li><strong>Ravichandran B</strong>,Roychowdhury A, Mukherjee A.K, Gangopadhyay and Saiyed H.N: Fluoride Content in the Edible Vegetables of Polluted Area. Asian Journal of Water, Environment and Pollution;Vol 5 (2), 93-95, April 2008.
</li><br/><li><strong>Ravichandran B</strong>, Krishnamurthy V, Ravibabu K, Raghavan S, Rajan B.K and Rajmohan H.R: Assessing dust Exposure in an Integrated Iron and Steel Manufacturing Plant in South India. Work: A Journal of Prevention, Assessment & Rehabilitation. Vol 30, No.2, 195-200, March 2008. 
</li><br/><li><strong>Ravichandran B</strong>,Raghavan S, Ravibabu K, Krishnamurthy V and Rajmohan H.R: Monitoring the Work Environment of Job Processes in a Telephone Handset Manufacturing Industry. Central European Journal of Environmental & Occupational Medicine, 13 (2): 193-199, 2007.
</li><br/><li><strong>Ravichandran B</strong>, Krishnamurthy V, Raghavan S, Rajan B.K and Rajmohan H.R: Assessment of Thermal Environment and Noise Intensity Levels in an Integrated Iron and Steel Plant of South India. Central European Journal of Environmental & Occupational Medicine, 12 (3): 159-166, 2006.
</li><br/><li><strong>Ravichandran B</strong>,Roychowdhury A, Gangopadhyay P.K and Saiyed H.N: A study of biochemical parameters among individuals residing in a fluoride endemic area in India. Toxicological and Environmental Chemistry, Vol 88, No.4, 561-567, July-September 2006.
</li><br/><li><strong>Ravichandran B</strong>, Krishnamurthy V, Raghavan S, and Rajmohan H.R: Dust Problems – A Health Concern in State Archives Department, Poll Res, 24 (1): 235 – 238,2005.
</li><br/><li><strong>Ravichandran B</strong>,Ravibabu K, Raghavan S, Krishnamurthy V, Rajan B.K and Rajmohan H.R: Environmental and Biological Monitoring in a Lead acid Battery manufacturing Unit in India. Journal of Occupational Health, 47: 350-353, 2005. 
</li><br/><li>Mukherjee A.K, <strong>Ravichandran B</strong>, Bhattacharya S.K, Ahmed S, Roy S.K, Thakur S, Roychowdhury A and Saiyed H.N: Environmental Pollution in Rural Areas of Orissa State Due to Industrial Emissions – With Special Reference to Fluoride. Indian Journal of Environmental Health, Vol 45, No.4 P.325-334, Oct. 2003.
</li><br/><li>Krishnamurthy V, <strong>Ravichandran B</strong>,Ravibabu K, Kakde Y, Rao S.R: Assessment of work environment of a steel Company in Salem District, Tamil Nadu. Indian Journal of Occupational and Environmental Medicine, Vol 6, No.4, Oct-December 2002.
</li>
	
</ol>
</div>
</div>
</div>
      
    </div>
    </div>
    );
}
export default DrAkshya;