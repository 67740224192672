import React from 'react';
import '../carousel.css';
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import NavbarMenu from '../NavbarMenu.js';
function DrDPS() {
    return (
      <div> <NavbarMenu /><div class="manual">
        <div class="row container">
        <p class="mypathhead">
        <Link to="/"><span class="mypath">Home</span></Link>&gt;<Link to="/ScientistNIOH"><span class="mypath">Scientist's Profile</span></Link>&gt;<span class="mypath">NIOH Ahmedabad</span>
        </p>
          
        </div>
        <h1 class="mypathhead">Scientist's Detail</h1>
        <div class="scientistdetail">
      
            <div class="panel my-panel" >
      <div class="panel-heading my-panel-head" align="left"><strong>Basic Details</strong></div>
      <div class="panel-body" align="left">
      <table width="100%">
        <thead>
          <th width="28%"></th>
          <th width="50%"></th>
          <th width="22%"></th>
        </thead>
        <tbody>
          <tr>
              <td  rowspan="5"><img src="../assests/images/niohScientist/dps.jpg" width="170px" height="170px" className="dg-img"/></td>
              <td  ><p className="myfontSci trSci"> Dr Dhirendra Pratap Singh</p> </td>
              
            </tr>
            
            <tr>
              
              <td className="myfontSci"><p className="myfontSci">Biological sciences Division</p></td>
              <td></td>
             </tr>
            <tr>
              <td><p ><a href=""><i className="fa fa-envelope" aria-hidden="true"></i>   Email: singh.dpratap@icmr.gov.in</a></p></td>
              <td></td>
            </tr> 
            <tr>
              <td><p ><a href=""> <i className="fa fa-phone-square"></i>  Contact: 079-22688747</a></p></td>
              <td></td>
            </tr>   
        </tbody>
           
            
            </table>
      </div>
    </div>
       
        <div class="panel my-panel" >
      <div class="panel-heading my-panel-head" align="left"><strong>Academic Qualification</strong></div>
      <div class="panel-body" align="left">Ph.D. (Pharmaceutical Sciences), M. Pharm. (Pharmacology)
</div>
    </div>
    <div class="panel my-panel" >
      <div class="panel-heading my-panel-head" align="left"><strong>Research Interest </strong></div>
      <div class="panel-body" align="left"> Gut-Microflora-Brain axis, Heavy metal toxicities, Neurobehavioral pharmacology, Translation and product development oriented research</div>
    </div>
    <div class="panel my-panel" >
      <div class="panel-heading my-panel-head" align="left"><strong>Membership/Affliation </strong></div>
      <div class="panel-body" align="left">
      <ul><li>Life member- Indian Pharmacological Society (IPS)</li><li>Life member- All India Association for Advancing Research in Obesity (AIAARO) a national society of World Obesity Federation and affiliated to International Association for the Studies in Obesity (IASO)</li>
</ul>
</div>
</div>
<div class="panel my-panel">
      <div class="panel-heading my-panel-head" align="left"><strong>Fellowship/Awards </strong></div>
      <div class="panel-body" align="left"><ul><li>Post-doctoral fellowship: Lundberg Lab for Diabetes Research at Sahlgrenska University Hospital, University of Gothenburg, Sweden.
</li><br/><li>	All India Rank 41 in Graduate Pharmacy Aptitude Test (GPAT 2011) conducted by AICTE. 	
</li><br/><li>	Indian council of Medical Research (ICMR)’s Junior Research Fellowship 
</li><br/><li>	DST-SERBtravel award to attendXIII International Congress on Obesity (ICO 2016), May 1st -4th, 2016 atVancouver, Canada.
</li><br/><li>	International Brain Research Organization (IBRO) Travel award to attend the IBRO-Advanced School of Neuroscience, Hong Kongfrom June 8 – 21, 2014.
</li><br/><li>	International Society for Neurochemistry (ISN) Travel award to attend 13th ISN Advanced School of Neurochemistry in Mission Beach, QLD, Australia from August 19 – 23, 2015.
</li><br/><li>	Best Poster award at 3rd Biennial Conference & International Symposium on Stress, Microbiome & Probiotics organized by Probiotics Association of India (PAI) and NISER Bhubaneswar, March 11 – 13, 2016. 
</li><br/><li>	Best poster award at 7th Chandigarh Science Congress (CHASCON), Panjab University, Chandigarh, March 1 – 3, 2013.
</li></ul>
</div>
</div>
<div class="panel my-panel">
      <div class="panel-heading my-panel-head" align="left"><strong>Publications </strong></div>
      <div class="panel-body" align="left">
     <ol>
     <li> Kaur J*, <strong>Singh DP*</strong>, Kumar V, Kaur S, Bhunia RK, Kondepudi KK, Kuhad A, Bishnoi M. Transient Receptor Potential (TRP) based polypharmacological combination stimulates energy expending phenotype to reverse HFD-induced obesity in mice. Life Sciences, 2023, 121704 Accepted Manuscript, https://doi.org/10.1016/j.lfs.2023.121704 [*Equal first author] </li><br/>
    
     <li> <strong>Singh DP*</strong>, Dharavath RN, Singh R, Patel K, Patel S, Bijalwan V, Kolimi P, Bishnoi M, Das S. Impact of Heavy Metals on Gut Microbial Ecosystem: Implications in Health and Disease. In Environmental Toxicology and Ecosystem. CRC Press; 2022. pp. 233-266 (*Corresponding author).</li><br/>
     <li> Yadav SK, Bijalwan V, Yadav S, Sarkar K, Das S, <strong>Singh DP*</strong>. Susceptibility of male reproductive system to bisphenol A, an endocrine disruptor: Updates from epidemiological and experimental evidences. Journal of Biochemical and Molecular Toxicology, 2022, e23292. https://doi.org/10.1002/jbt.23292 (* corresponding author)</li><br/>
      <li> <strong>Singh DP*</strong>, Yadav SK, Patel K, Patel S, Patil GP, Bijalwan V, Singh G, Palkhade R, Kondepudi KK, Boparai RK, Bishnoi M, Das S. Short-term trivalent arsenic and hexavalent chromium exposures induce gut dysbiosis and transcriptional alteration in adipose tissue of mice. Molecular Biology Reports, 2022 DOI: 10.1007/s11033-022-07992-z (Accepted manuscript) [*Corresponding author]</li><br/>
      <li>Tripathi S, Fhatima S, Parmar D, <strong>Singh DP</strong>,  Mishra SD, Mishra R, Singh G. Therapeutic effects of CoenzymeQ10, Biochanin A and Phloretin against arsenic and chromium induced oxidative stress in mouse (Mus musculus) brain. 3 Biotech 2022, 5: 1-13</li><br/>
      <li> Dhiman A, Handa M, Ruwali M, <strong>Singh DP</strong>, Kesharwani P, Shukla R. Recent trends of natural based therapeutics for mitochondria targeting in Alzheimer’s disease. Mitochondrion 2022, 64: 112-124  </li><br/>
	<li> Yadav SK, Patil GP, Virmagami A, Bijalwan V, Devi K, Chauhan A, Gupta SK, Fathima S, Naorem CD, Yadav S, Singh G, Mishra S, Bishnoi M, Sarkar K, <strong>Singh DP*</strong>. Occupational lead exposure is an independent modulator of hypertension and poor pulmonary functions: A cross-sectional comparative study in lead-acid battery recycling workers. Toxicology and Industrial Health 2022, 38 (3):139-150.  [*Corresponding author] </li><br/>
	<li>		Sarkar K, Bishnoi M, <strong>Singh DP*</strong>. Designing, fabrication and evaluation of a rapid, point-of-care and noninvasive system for the detection of lead (Pb2+). Biomedical Microdevices 23, 56 (2021). https://doi.org/10.1007/s10544-021-00599-4 [* Corresponding author]
</li><br/><li>	Khare P*, Mahajan N*, <strong>Singh DP*.</strong>, Kumar V, Kumar V, Mangal P, Boparai RK, Gesing A, Bhadada SK, Sharma SS, Kondepudi KK, Chopra K, Bishnoi M. Allicin, a dietary trpa1 agonist, prevents high fat diet-induced dysregulation of gut hormones and associated complications. Food & Function. 2021, 12,11526. [*Equal first author]
</li><br/><li>	<strong>Singh DP</strong>, Sahu MC, Pagdhune A, Viramgami A, Sivaperumal P,Balachandar R, Sarkar K. Viral load could be an important determinant for fomites based transmission of viral infections. J Family Med Prim Care. 2021 Feb; 10(2): 929–932.
</li><br/><li>	Shukla R, HandaM, Vasdev N,<strong>Singh DP</strong>,Keshrwani P. Nanomedicine in pain management In: Kesarwani P., taurine S., Greish K (eds), Theory and Applications of NonparenteralNanomedicines. Academic Press, NY. 2021 pp 355-382
</li><br/><li>	<strong>Singh DP</strong>, Arya A, Kondepudi KK, Bishnoi M, Boparai RK. Prevalence and associated factors of overweight/obesity among school going children in Chandigarh, India. Child: Care, Health and Development. 2020;46(5):571-5.
</li><br/><li>	Deol PK, Khare P, <strong>Singh DP</strong>, Bishnoi M, Kondepudi KK, Kaur IP. Pharmabiotic beads with improved encapsulation and gut survival for management of colonic inflammation associated gut derangements. Journal of Drug Targeting. 2020: 28(10):1053-1062.
</li><br/><li>	Singh R, Bansal Y, Sodhi RK, <strong>Singh DP</strong>, Bishnoi M, Kondepudi KK, et al. Berberine attenuated olanzapine-induced metabolic alterations in mice: Targeting transient receptor potential vanilloid type 1 and 3 channels. Life Sciences. 2020;247:117442
</li><br/><li>	Dhaliwal N, Dhaliwal J, <strong>Singh DP</strong>, Kondepudi KK, Bishnoi M, Chopra K. The Probiotic Mixture VSL#3 Reverses Olanzapine-Induced Metabolic Dysfunction in Mice. In: Kobeissy FH, editor. Psychiatric Disorders: Methods and Protocols. New York, NY: Springer New York; 2019. p. 531-44.
</li><br/><li>	<strong>Singh DP</strong>, Singh S, Bijalwan V, Kumar V, Khare P, Baboota RK, et al. Co-supplementation of isomalto-oligosaccharides potentiates metabolic Health benefits of polyphenol-rich cranberry extract in high fat diet-fed mice via enhanced gut butyrate production. European journal of nutrition. 2018;57(8):2897-911
</li><br/><li>	Khare P, Mangal P, Baboota RK, Jagtap S, Kumar V, <strong>Singh DP</strong>, et al. Involvement of glucagon in preventive effect of menthol against high fat diet induced obesity in mice. Frontiers in pharmacology. 2018;9:1244.
</li><br/><li>	Dhaliwal J*, <strong>Singh DP*.</strong>, Singh S, Pinnaka AK, Boparai R, Bishnoi M, et al. Lactobacillus plantarum MTCC 9510 supplementation protects from chronic unpredictable and sleep deprivation‐induced behaviour, bioChemical and selected gut microbial aberrations in mice. Journal of applied microbiology. 2018;125(1):257-69. [*Equal first author]
</li><br/><li>	Mittal R, Kumar A, <strong>Singh DP</strong>, Bishnoi M, Nag TC. Ameliorative potential of rutin in combination with nimesulide in STZ model of diabetic neuropathy: targeting Nrf2/HO-1/NF-kB and COX signalling pathway. Inflammopharmacology. 2018;26(3):755-68
</li><br/><li>	Baboota RK, Khare P, Mangal P, <strong>Singh DP</strong>, Bhutani KK, Kondepudi KK, et al. Dihydrocapsiate supplementation prevented high-fat diet–induced adiposity, hepatic steatosis, glucose intolerance, and gut morphological alterations in mice. Nutrition Research. 2018;51:40-56
</li><br/><li>	Sarma SM, <strong>Singh DP*.</strong>, Singh P, Khare P, Mangal P, Singh S, et al. Finger millet arabinoxylan protects mice from high-fat diet induced lipid derangements, inflammation, endotoxemia and gut bacterial dysbiosis. International journal of Biological macromolecules. 2018;106:994-1003. [*Equal first author]
</li><br/><li>	Deol PK, Khare P, <strong>Singh DP</strong>, Soman G, Bishnoi M, Kondepudi KK, et al. Managing colonic inflammation associated gut derangements by systematically optimised and targeted ginger extract-Lactobacillus acidophilus loaded pharmacobiotic alginate beads. International Journal of Biological Macromolecules. 2017;105:81-91
</li><br/><li>	<strong>Singh DP</strong>, Khare P, Bijalwan V, Baboota RK, Singh J, Kondepudi KK, et al. Coadministration of isomalto‐oligosaccharides augments metabolic Health benefits of cinnamaldehyde in high fat diet fed mice. BioFactors. 2017;43(6):821-35
</li><br/><li>	<strong>Singh DP</strong>, Singh J, Boparai RK, Zhu J, Mantri S, Khare P, et al. Isomalto-oligosaccharides, a prebiotic, functionally augment green tea effects against high fat diet-induced metabolic alterations via preventing gut dysbacteriosis in mice. Pharmacological research. 2017;123:103-13
</li><br/><li>	Sarma SM, Khare P, Jagtap S, <strong>Singh DP</strong>, Baboota RK, Podili K, et al. Kodo millet whole grain and bran supplementation prevents high-fat diet induced derangements in a lipid profile, inflammatory status and gut bacteria in mice. Food Funct. 2017;8(3):1174-83
</li><br/><li>	Kondepudi K, <strong>Singh DP</strong>, Podili K, Boparai R, BishnoiM. Role of Probiotics and Prebiotics in the Management of Obesity. Food Microbiology: In Human Health and Disease. 2016:157
</li><br/><li>	<strong>Singh DP</strong>, Khare P, Zhu J, Kondepudi K, Singh J, Baboota R, et al. A novel cobiotic-based preventive approach against high-fat diet-induced adiposity, nonalcoholic fatty liver and gut derangement in mice. International Journal of Obesity. 2016;40(3):487-96
</li><br/><li>	Murtaza N, Baboota RK, Jagtap S, <strong>Singh DP</strong>, Khare P, Sarma SM, et al. Finger millet bran supplementation alleviates obesity-induced oxidative stress, inflammation and gut microbial derangements in high-fat diet-fed mice. British journal of nutrition. 2014;112(9):1447-58
</li><br/><li>	Baboota RK, <strong>Singh DP</strong>, Sarma SM, Kaur J, Sandhir R, Boparai RK, et al. Capsaicin induces "brite" phenotype in differentiating 3T3-L1 preadipocytes. PLoS One. 2014;9(7):e103093.doi: 10.1371/journal.pone.0103093. eCollection 2014
</li><br/><li>	<strong>Singh DP</strong>, KondepudiKK., Chopra K, Bishnoi M.Altered Monoamine Metabolism in High Fat Diet Induced Neuropsychiatric Changes in Rats. Journal of Obesity and weight loss therapy. 2014, 4(4), 234
</li><br/><li>	Baboota RK, Murtaza N, Jagtap S, <strong>Singh DP</strong>, Karmase A, Kaur J, et al. Capsaicin-induced transcriptional changes in hypothalamus and alterations in gut microbial count in high fat diet fed mice. The Journal of nutritional biochemistry. 2014;25(9):893-902
</li><br/><li>	<strong>Singh DP</strong>, Chopra K. Flavocoxid, dual inhibitor of cyclooxygenase-2 and 5-lipoxygenase, exhibits neuroprotection in rat model of ischaemic stroke. Pharmacology Biochemistry and Behavior. 2014;120(0):33-42
</li><br/><li>	<strong>Singh DP</strong>, Chopra K. Verapamil augments the neuroprotectant action of berberine in rat model of transient global cerebral ischemia. Eur J Pharmacol. 2013;720(1-3):98-106
</li>
  
</ol>
	
	
	
</div>
</div>
</div>
        </div>
    </div>
    );
}
export default DrDPS;