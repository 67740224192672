import React from 'react';
import '../carousel.css';
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import NavbarMenu from '../NavbarMenu.js';
function DrAkshya() {
    return (
      <div> <NavbarMenu /><div class="manual">
        <div class="row container">
        <p class="mypathhead">
        <Link to="/"><span class="mypath">Home</span></Link>&gt;<Link to="/ScientistROHCS"><span class="mypath">Scientist's Profile</span></Link>&gt;<span class="mypath">ICMR-ROHCS Bengaluru</span>
        </p>
          
        </div>
        <h1 class="mypathhead">ICMR-ROHCS Scientist's Detail</h1>
        <div class="scientistdetail">
      
            <div class="panel my-panel" >
      <div class="panel-heading my-panel-head" align="left"><strong>Basic Details</strong></div>
      <div class="panel-body" align="left">
      <table width="100%">
            <tr>
                <td width="28%" rowspan="5"><img src="../assests/images/rohcsScientist/kravibabu.png" width="180px" height="170px" CLASS="dg-img"/></td>
                <td width="50%" ><p class="myfontSci trSci"> Dr Ravi Babu K</p> </td>
                <td width="22%" ></td>
                
            </tr>
            <tr>
              <td><p ><a href=""><i class="fa fa-envelope" aria-hidden="true"></i>   Email: ravibabu.k@gov.in</a></p></td>
            </tr> 
            <tr>
              <td><p ><a href=""> <i class="fa fa-phone-square"></i>  Contact: 080-22172500</a></p></td>
            </tr>
           
            </table>   
      </div>
    </div>
       
        <div class="panel my-panel" >
      <div class="panel-heading my-panel-head" align="left"><strong>Academic Qualification</strong></div>
      <div class="panel-body" align="left">
      M.Sc (Biochemistry),PhD (Biochemistry)
</div>
    </div>
    <div class="panel my-panel" >
      <div class="panel-heading my-panel-head" align="left"><strong>Research Interest </strong></div>
      <div class="panel-body" align="left">
      Studies on Biochemical toxicity in occupational exposure.
	  <p>Biomarkers of exposure and responses in different occupational exposure.</p>

</div>
</div>
<div class="panel my-panel">
      <div class="panel-heading my-panel-head" align="left"><strong>Membership/Affliation </strong></div>
      <div class="panel-body" align="left">
      <ul><li>Indian Association of Occupational Health
</li>

</ul>
</div>
</div>
<div class="panel my-panel">
      <div class="panel-heading my-panel-head" align="left"><strong>Fellowship/Awards </strong></div>
      <div class="panel-body" align="left">
      <ul><li>	4th Rank in M.Sc Biochemistry</li>
<li>Junior Research fellowship in Joint CSIR-UGC</li>
<li>Mohanmul award for best scientific paper in Industrial Medicine</li>

</ul>
</div>
</div>
<div class="panel my-panel">
      <div class="panel-heading my-panel-head" align="left"><strong>Publications </strong></div>
      <div class="panel-body" align="left">
	
      <ol>
                    <li>
                      <div align="justify"><strong>Ravibabu Kalahasthi</strong> and Tapu Barman. Assessment of Lead Exposure and Urinary-δ-aminolevulinic Acid Levels in Male Lead Acid Battery Workers in Tamil Nadu, India. Journal of Health and Pollution: 2018; 8(17):6-13.https://doi.org/10.5696/2156-9614-8.17.6</div>
                    </li><br/>
                    <li>
                      <div align="justify"><strong>Kalahasthi Ravibabu</strong>, Tapu Barman, BS Bagepally. Assessment of systemic inflammatory response markers in workers exposed to Pb from Pb battery plant. Toxin Review, Published online: 20 November, 2017. https://doi.org/10.1080/15569543.2017.1402059.</div>
                    </li><br/>
                    <li>
                      <div align="justify"><strong>Kalahasthi R.</strong>, &amp; Barman, T. Effect of Lead Exposure on the Status of Reticulocyte Count Indices among Workers from Lead Battery Manufacturing Plant. Toxicological Research.2016; 32(4):281-287.doi: 10.5487/TR.2016.32.4.281</div>
                    </li><br/>
                    <li>
                      <div align="justify">Bhavani Shankara Bagepally, <strong>Ravibabu Kalahasthi</strong>, Tapu Barman. Serum Iron, Zinc and its relationship with Blood lead levels among lead exposed worker from lead battery plant. J Mol Pathophysiology.2016; 5(3):49-54. http://dx.doi.org/10.5455/jmp.20160906043935</div>
                    </li><br/>
                    <li>
                      <div align="justify"><strong>Kalahasthi, Ravibabu</strong>, Barman, Tapu, Rajmohan, HR, Bagepally, BS, Ravichandran. Effectiveness of interventions on biological monitoring among workers exposed to Pb from leadacid storage battery plant. Int J Med Sci Public Health.2016; 5(9):1770-74. http://dx.doi.org/10.5455/ijmsph.2016.19112015318</div>
                    </li><br/>
                    <li>
                      <div align="justify">Pigatto, PD Ronchi, A Pontillo, M Guzzi, <strong>K.Ravibabu</strong>, K. Serum Neuron-Specific Enolase in Lead-Exposed Individuals. The international journal of occupational and environmental medicine.2016; 7(1):58-60. DOI: 10.15171/ijoem.2016.710</div>
                    </li><br/>
                    <li>
                      <div align="justify"><strong>K Ravibabu</strong>, T Barman, HR Rajmohan. Serum Neuron-Specific Enolase, Biogenic AminoAcids and Neurobehavioral Function in Lead-Exposed Workers from Lead-Acid Battery Manufacturing Process. International Journal of Occupational and Environmental Medicine.2015; 6(1):50-57. DOI: 10.15171/ijoem.2015.436.</div>
                    </li><br/>
                    <li>
                      <div align="justify">Tapu Barman, <strong>Ravibabu Kalahasthi</strong> and HR Rajmohan. Effects of lead exposure on the status of platelet indices in workers involved in a lead-acid battery manufacturing plant. J Expo Sci Environ Epidemiology.2014; 24(6):629-33. DOI: 10.1038/jes.2014.4</div>
                    </li><br/>
                    <li>
                      <div align="justify"><strong>Ravibabu Kalahasthi</strong>, Tapu Barman and HR Rajmohan. The relationship between blood lead levels and morbidities among workers employed in a factory manufacturing lead–acid storage battery. International Journal of Environmental Health Research.2014;24(3):246-55. doi: 10.1080/09603123.2013.809702.<br/>
                      </div>
                    </li><br/>
                    <li>
                      <div align="justify"><strong>Ravibabu Kalahasthi</strong>, Tapu Barman, Hirehal Raghavendra Rao. Assessment of the relationship between blood lead levels and hematological parameters among lead acid –storage battery plant workers. J Environ Occup Sci. 2012; 1(1): 1-5</div>
                    </li><br/>
                    <li>
                      <div align="justify"><strong>Ravibabu Kalahasthi</strong>, HR Rajmohan, Pavitra Narendran, Adithya Pradyumna.Serum total immunoglobin-E and health hazards in workers involved in land fill and compost areas of hazardous waste management plants. Indian Journal of Occupational and Environmental Medicine.2012; 16(1):9-13.</div>
                    </li><br/>
                    <li>
                      <div align="justify"><strong>Kalahasthi Ravibabu</strong>, Adithya P, Pavithra N &amp; H.R. Rajmohan. Evaluation of the Relationship between Pro-Inflammatory Cytokines and Health Hazards in Workers Involved in Hazardous Waste Sites at Karnataka, India. Journal of Research in Health Sciences: 2010; 10(1): 7-14.</div>
                    </li><br/>
                    <li>
                      <div align="justify">N. Pavithra, <strong>K Ravibabu</strong> &amp; H.R. Rajmohan. A review on pro-inflammatory cytokines responses in different occupational exposure. Journal of Ecophysiology &amp; Occupational Health, 2009; 9(3&amp;4):113-118.</div>
                    </li><br/>
                    <li>
                      <div align="justify"><strong>Kalahasthi Ravibabu</strong>, Rajmohan. Hirehal Raghavendra Rao, Rajan and Karuna Kumar M. Effect of nickel exposure on urinary enzymes in nickel-plating workers. The journal of Occupational Health and Safety Australia and New Zealand-2008, 24(1): 73-80.</div>
                    </li><br/>
                    <li>
                      <div align="justify"><strong>Kalahasthi Ravibabu</strong>, Rajmohan. Hirehal Raghavendra Rao, Rajan. Bagalur Krishna Murthy and Karuna Kumar M. Effect of nickel exposure on serum amylase activity in nickel –plating workers. Toxicology and Environmental Chemistry-2007; 90(2): 393-400.</div>
                    </li><br/>
                    <li>
                      <div align="justify"><strong>Kalahasthi Ravibabu</strong>, Rajmohan. Hirehal Raghavendra Rao, Rajan. Bagalur Krishna Murthy and Karuna Kumar M. Urinary N-acetyl-β-D-glucosaminadase and its isoenzyme A &amp; B in workers exposed to cadmium from cadmium plating process. Journal of Occupational Medicine and Toxicology-2007, 2:5</div>
                    </li><br/>
                    <li>
                      <div align="justify"><strong>Kalahasthi Ravibabu</strong>, Hirehal Raghavendra Rao R, Bagalur Krishna Murthy R and Karuna Kumar M Effect of Chromium (VI) exposure on serum amylase activity in chromium plating workers. Environmental Science: an Indian Journal-2007; 2(1): 1-6.</div>
                    </li><br/>
                    <li>
                      <div align="justify"><strong>Kalahasthi Ravi Babu</strong>, Hirehal Raghavendra Rao R, Bagalur Krishna Murthy R and Karuna Kumar M Effect of chromium (VI) on the status of plasma lipid peroxidation and erythrocyte antioxidant enzymes in chromium plating workers. Chemico-Biological Interactions164 (2006) 192-199.</div>
                    </li><br/>
                    <li>
                      <div align="justify"><strong>Kalahasthi Ravi Babu</strong>, Hirehal Raghavendra Rao R, Bagalur Krishna Murthy R Association between urine cadmium and urinary indicators of renal dysfunction in cadmium plating workers. Central European journal of environmental medicine; 2006; 12(4): 235-242.</div>
                    </li><br/>
                    <li>
                      <div align="justify"><strong>Kalahasthi Ravibabu</strong>, Hirehal Raghavendra Rao R, Bagalur Krishna Murthy R and Karuna Kumar M. Effect of cadmium exposure on serum amylase activity in cadmium plating workers. Environmental Bioindicators-2006 October-December; 1(4): 26</div>
                    </li><br/>
                    <li>
                      <div align="justify"><strong>Kalahasthi Ravibabu</strong>, Hirehal Raghavendra Rao R, Bagalur Krishna Murthy R. Assessment of functional integrity of liver among workers exposed to soluble nickel compounds during nickel plating. Indian Journal of Occupational and Environmental Medicine-August 2006 10(2): 78-81</div>
                    </li><br/>
                    <li>
                      <div align="justify"><strong>Kalahasthi Ravibabu</strong>, Hirehal Raghavendra Rao R, Bagalur Krishna Murthy R and Karuna Kumar M. Plasma lipid peroxidation and erythrocyte antioxidant enzymes status in workers exposed to cadmium. Toxicology and Industrial Health-2006, September 22 (8): 329-336.</div>
                    </li><br/>
                    <li>
                      <div align="justify"><strong>Kalahasthi RB</strong>, Hirehal Raghavendra Rao R, Bagalur Krishna Murthy R.Plasma lipid peroxidation and erythrocyte antioxidant status in workers exposed to nickel. Biomarkers 2006 May-Jun; 11(3): 241-249.</div>
                      
                      </li><br/>
                    
					
					
                  </ol>
	
	
	
</div>
</div>

</div> 
    </div>
    </div>
    );
}
export default DrAkshya;