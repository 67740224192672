import React from 'react';
import './carousel.css';
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import Otheradmin from './otheradmin';
import NavbarMenu from './NavbarMenu.js';
function AdminROHCS () {
    return (<div> <NavbarMenu /><div className="manual">
        
        <div className="row container">
        <p className="mypathhead">
        <Link to="/"><span class="mypath">Home</span></Link>&gt;<span className="mypath">NIOH Staff</span>&gt;<span className="mypath">Administrative Staff</span>
        </p>
          
        </div>
        <h1 class="mypathhead">Administrative Staff-NIOH-ROHCS Bengaluru</h1>
        <div className="row row1">
            
            <div className="col-md-4">
                <div className="panel panel-primary">
                <div className="panel-body">
                <div className="row">
                        <div className="col-md-4">
                            <img src='../assests/images/AdminStaffImg/NSKROHCS.jpg' width="135px" height="160px;"></img>
                            
                        </div>
                        
                        <div className="col-md-8 carddata">
                            <table><tbody>
                                <tr>
                                    <td width="20%"><button className="mybutton-top" ><i className="fa fa-user fa-1x" aria-hidden="true"></i></button></td>
                                    <td WIDTH="95%" className="myfontCard">N.Shashikala</td>
                                </tr>
                                <tr>
                                    <td width="20%"></td>
                                    <td WIDTH="95%"className="myfontCardbody">Personnel Assistant</td>
                                </tr>
                                <tr>
                                    <td width="10%"></td>
                                    <td WIDTH="95%"className="myfontCardbody">Administration</td>
                                </tr>
                                <tr>
                                    <td width="20%"><button className="mybutton-top" ><i className="fa fa-history fa-1x" aria-hidden="true"></i></button></td>
                                    <td WIDTH="95%" className="myfontCardbody">23 Yrs Experience</td>
                                </tr>
                                <tr>
                                    <td width="20%"><button className="mybutton-top" ><i className="fa fa-envelope fa-1x" aria-hidden="true"></i></button></td>
                                    <td WIDTH="95%" className="myfontCardbody">nshashi73@gmail.com</td>
                                </tr>
                                
                            </tbody></table>
                        </div>
                    </div>
                </div>
                </div>
            </div>
           
            
        </div>
      
        </div>

        </div>);
}
export default AdminROHCS;