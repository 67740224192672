import React from 'react';
import './carousel.css';
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import Otheradmin from './otheradmin';
import NavbarMenu from './NavbarMenu.js';
function AdminNioh () {
    return (<div> <NavbarMenu /><div className="manual">
        
        <div className="row container">
        <p className="mypathhead">
        <Link to="/"><span class="mypath">Home</span></Link>&gt;<span className="mypath">NIOH Staff</span>&gt;<span className="mypath">Administrative Staff</span>
        </p>
          
        </div>
        <h1 class="mypathhead">Administrative Staff-NIOH Ahmedabad</h1>
        <div className="row row1">
            <div className="col-md-4">
                <div className="panel panel-primary">
                <div className="panel-body">
                    <div className="row">
                        <div className="col-md-4">
                            <img src='../assests/images/AdminStaffImg/man.png' width="130px" height="160px;"></img>
                            
                        </div>
                        
                        <div className="col-md-8 carddata">
                            <table><tbody>
                                <tr>
                                    <td width="20%"><button className="mybutton-top" ><i className="fa fa-user fa-1x" aria-hidden="true"></i></button></td>
                                    <td WIDTH="95%" className="myfontCard">Mr Rahul Wadhvani</td>
                                </tr>
                                <tr>
                                    <td width="20%"></td>
                                    <td WIDTH="95%"className="myfontCardbody">Admin Officer</td>
                                </tr>
                                <tr>
                                    <td width="10%"></td>
                                    <td WIDTH="95%"className="myfontCardbody"></td>
                                </tr>
                                <tr>
                                    <td width="20%"><button className="mybutton-top" ><i className="fa fa-history fa-1x" aria-hidden="true"></i></button></td>
                                    <td WIDTH="95%" className="myfontCardbody">10 Yrs Experience</td>
                                </tr>
                                <tr>
                                    <td width="20%"><button className="mybutton-top" ><i className="fa fa-envelope fa-1x" aria-hidden="true"></i></button></td>
                                    <td WIDTH="95%" className="myfontCardbody">wadhwani.rj@icmr.gov.in; ao-nioh@gov.in</td>
                                </tr>
                                
                            </tbody></table>
                        </div>
                    </div>
                </div>
                </div>
            </div>
            <div className="col-md-4">
                <div className="panel panel-primary">
                <div className="panel-body">
                <div className="row">
                        <div className="col-md-4">
                            <img src='../assests/images/AdminStaffImg/man.png' width="135px" height="160px;"></img>
                            
                        </div>
                        
                        <div className="col-md-8 carddata">
                            <table><tbody>
                                <tr>
                                    <td width="20%"><button className="mybutton-top" ><i className="fa fa-user fa-1x" aria-hidden="true"></i></button></td>
                                    <td WIDTH="95%" className="myfontCard">Manohar Lal Meena</td>
                                </tr>
                                <tr>
                                    <td width="20%"></td>
                                    <td WIDTH="95%"className="myfontCardbody">Accounts Officer (Addl Charge at NIOH)</td>
                                </tr>
                                <tr>
                                    <td width="10%"></td>
                                    <td WIDTH="95%"className="myfontCardbody">Accounts Section</td>
                                </tr>
                                <tr>
                                    <td width="20%"><button className="mybutton-top" ><i className="fa fa-history fa-1x" aria-hidden="true"></i></button></td>
                                    <td WIDTH="95%" className="myfontCardbody">30 Yrs Experience</td>
                                </tr>
                                <tr>
                                    <td width="20%"><button className="mybutton-top" ><i className="fa fa-envelope fa-1x" aria-hidden="true"></i></button></td>
                                    <td WIDTH="95%" className="myfontCardbody">aco-nioh@icmr.gov.in</td>
                                </tr>
                                
                            </tbody></table>
                        </div>
                    </div>
                </div>
                </div>
            </div>
            <div className="col-md-4">
                <div className="panel panel-primary">
                <div className="panel-body">
                <div className="row">
                        <div className="col-md-4">
                            <img src='../assests/images/AdminStaffImg/Parul.jpg' width="135px" height="160px;"></img>
                            
                        </div>
                        
                        <div className="col-md-8 carddata">
                            <table><tbody>
                                <tr>
                                    <td width="20%"><button className="mybutton-top" ><i className="fa fa-user fa-1x" aria-hidden="true"></i></button></td>
                                    <td WIDTH="95%" className="myfontCard">Mrs. Parul Modi</td>
                                </tr>
                                <tr>
                                    <td width="20%"></td>
                                    <td WIDTH="95%"className="myfontCardbody">Section Officer</td>
                                </tr>
                                <tr>
                                    <td width="10%"></td>
                                    <td WIDTH="95%"className="myfontCardbody">Store Section</td>
                                </tr>
                                <tr>
                                    <td width="20%"><button className="mybutton-top" ><i className="fa fa-history fa-1x" aria-hidden="true"></i></button></td>
                                    <td WIDTH="95%" className="myfontCardbody">30 Yrs Experience</td>
                                </tr>
                                <tr>
                                    <td width="20%"><button className="mybutton-top" ><i className="fa fa-envelope fa-1x" aria-hidden="true"></i></button></td>
                                    <td WIDTH="95%" className="myfontCardbody">modi.p@gov.in</td>
                                </tr>
                                
                            </tbody></table>
                        </div>
                    </div>
                </div>
                </div>
            </div>
            
        </div>
        <div className="row row1">
        <div className="col-md-4">
                <div className="panel panel-primary">
                <div className="panel-body">
                <div className="row">
                        <div className="col-md-4">
                            <img src='../assests/images/AdminStaffImg/sunita.jpeg' width="135px" height="160px;"></img>
                            
                        </div>
                        
                        <div className="col-md-8 carddata">
                            <table><tbody>
                                <tr>
                                    <td width="20%"><button className="mybutton-top" ><i className="fa fa-user fa-1x" aria-hidden="true"></i></button></td>
                                    <td WIDTH="95%" className="myfontCard">Sunita H Kerketta</td>
                                </tr>
                                <tr>
                                    <td width="20%"></td>
                                    <td WIDTH="95%"className="myfontCardbody">Section Officer</td>
                                </tr>
                                <tr>
                                    <td width="10%"></td>
                                    <td WIDTH="95%"className="myfontCardbody">Establishment Section</td>
                                </tr>
                                <tr>
                                    <td width="20%"><button className="mybutton-top" ><i className="fa fa-history fa-1x" aria-hidden="true"></i></button></td>
                                    <td WIDTH="95%" className="myfontCardbody">29 Yrs Experience</td>
                                </tr>
                                <tr>
                                    <td width="20%"><button className="mybutton-top" ><i className="fa fa-envelope fa-1x" aria-hidden="true"></i></button></td>
                                    <td WIDTH="95%" className="myfontCardbody">kerketta.sh@gov.in</td>
                                </tr>
                                
                            </tbody></table>
                        </div>
                    </div>
                </div>
                </div>
            </div>
            <div className="col-md-4">
                <div className="panel panel-primary">
                <div className="panel-body">
                <div className="row">
                        
                        <div className="col-md-4">
                                    <img src='../assests/images/AdminStaffImg/Nishad.jpeg' width="135px" height="160px;"></img>
                                    
                                </div>
                                
                                <div className="col-md-8 carddata">
                                    <table><tbody>
                                        <tr>
                                            <td width="20%"><button className="mybutton-top" ><i className="fa fa-user fa-1x" aria-hidden="true"></i></button></td>
                                            <td WIDTH="95%" className="myfontCard">Nishad Darji</td>
                                        </tr>
                                        <tr>
                                            <td width="20%"></td>
                                            <td WIDTH="95%"className="myfontCardbody">Section Officer</td>
                                        </tr>
                                        <tr>
                                            <td width="10%"></td>
                                            <td WIDTH="95%"className="myfontCardbody">Establishment Section</td>
                                        </tr>
                                        <tr>
                                            <td width="20%"><button className="mybutton-top" ><i className="fa fa-history fa-1x" aria-hidden="true"></i></button></td>
                                            <td WIDTH="95%" className="myfontCardbody">7 Yrs Experience</td>
                                        </tr>
                                        <tr>
                                            <td width="20%"><button className="mybutton-top" ><i className="fa fa-envelope fa-1x" aria-hidden="true"></i></button></td>
                                            <td WIDTH="95%" className="myfontCardbody">darji.nishad@icmr.gov.in</td>
                                        </tr>
                                        
                                    </tbody></table>
                                </div>   
                                
                        </div>
                </div>
                </div>
            </div>
            <div className="col-md-4">
                <div className="panel panel-primary">
                <div className="panel-body">
               
                <div className="row">
                        <div className="col-md-4">
                            <img src='../assests/images/AdminStaffImg/Rukshana.jpg' width="135px" height="160px;"></img>
                            
                        </div>
                        
                        <div className="col-md-8 carddata">
                            <table><tbody>
                                <tr>
                                    <td width="20%"><button className="mybutton-top" ><i className="fa fa-user fa-1x" aria-hidden="true"></i></button></td>
                                    <td WIDTH="95%" className="myfontCard">Rukshana Ghanchi</td>
                                </tr>
                                <tr>
                                    <td width="20%"></td>
                                    <td WIDTH="95%"className="myfontCardbody">Assistant</td>
                                </tr>
                                <tr>
                                    <td width="10%"></td>
                                    <td WIDTH="95%"className="myfontCardbody">Establishment Section</td>
                                </tr>
                                <tr>
                                    <td width="20%"><button className="mybutton-top" ><i className="fa fa-history fa-1x" aria-hidden="true"></i></button></td>
                                    <td WIDTH="95%" className="myfontCardbody">24 Yrs Experience</td>
                                </tr>
                                <tr>
                                    <td width="20%"><button className="mybutton-top" ><i className="fa fa-envelope fa-1x" aria-hidden="true"></i></button></td>
                                    <td WIDTH="95%" className="myfontCardbody">ghanchi.rr@gov.in</td>
                                </tr>
                                
                            </tbody></table>
                        </div>
                    </div> 
                   
                </div>
                </div>
            </div>
        </div>
        <div className="row row1">
            
            <div className="col-md-4">
                <div className="panel panel-primary">
                <div className="panel-body">
                <div className="row">
                        <div className="col-md-4">
                            <img src='../assests/images/AdminStaffImg/paras.jpg' width="130px" height="160px;"></img>
                            
                        </div>
                        
                        <div className="col-md-8 carddata">
                            <table><tbody>
                                <tr>
                                    <td width="20%"><button className="mybutton-top" ><i className="fa fa-user fa-1x" aria-hidden="true"></i></button></td>
                                    <td WIDTH="95%" className="myfontCard">Paraskumar Bhalchandra Panchal</td>
                                </tr>
                                <tr>
                                    <td width="20%"></td>
                                    <td WIDTH="95%"className="myfontCardbody">Assistant</td>
                                </tr>
                                <tr>
                                    <td width="10%"></td>
                                    <td WIDTH="95%"className="myfontCardbody">Account Section</td>
                                </tr>
                                <tr>
                                    <td width="20%"><button className="mybutton-top" ><i className="fa fa-history fa-1x" aria-hidden="true"></i></button></td>
                                    <td WIDTH="95%" className="myfontCardbody">29 Yrs Experience</td>
                                </tr>
                                <tr>
                                    <td width="20%"><button className="mybutton-top" ><i className="fa fa-envelope fa-1x" aria-hidden="true"></i></button></td>
                                    <td WIDTH="95%" className="myfontCardbody">panchal.pb@icmr.gov.in</td>
                                </tr>
                                
                            </tbody></table>
                        </div>
                    </div>
                </div>
                </div>
            </div>
            <div className="col-md-4">
                <div className="panel panel-primary">
                <div className="panel-body">
                <div className="row">
                        <div className="col-md-4">
                            <img src='../assests/images/AdminStaffImg/man.png' width="135px" height="160px;"></img>
                            
                        </div>
                        
                        <div className="col-md-8 carddata">
                            <table><tbody>
                                <tr>
                                    <td width="20%"><button className="mybutton-top" ><i className="fa fa-user fa-1x" aria-hidden="true"></i></button></td>
                                    <td WIDTH="95%" className="myfontCard">M R Chawla</td>
                                </tr>
                                <tr>
                                    <td width="20%"></td>
                                    <td WIDTH="95%"className="myfontCardbody">Personal Assistant</td>
                                </tr>
                                <tr>
                                    <td width="10%"></td>
                                    <td WIDTH="95%"className="myfontCardbody"></td>
                                </tr>
                                <tr>
                                    <td width="20%"><button className="mybutton-top" ><i className="fa fa-history fa-1x" aria-hidden="true"></i></button></td>
                                    <td WIDTH="95%" className="myfontCardbody"> Yrs Experience</td>
                                </tr>
                                <tr>
                                    <td width="20%"><button className="mybutton-top" ><i className="fa fa-envelope fa-1x" aria-hidden="true"></i></button></td>
                                    <td WIDTH="95%" className="myfontCardbody">chawla.mr@gov.in</td>
                                </tr>
                                
                            </tbody></table>
                        </div>
                    </div>
                </div>
                </div>
            </div>
      
       
            
            <div className="col-md-4">
                <div className="panel panel-primary">
                <div className="panel-body">
                <div className="row">
                        <div className="col-md-4">
                            <img src='../assests/images/AdminStaffImg/SINDHU.jpg' width="135px" height="160px;"></img>
                            
                        </div>
                        
                        <div className="col-md-8 carddata">
                            <table><tbody>
                                <tr>
                                    <td width="20%"><button className="mybutton-top" ><i className="fa fa-user fa-1x" aria-hidden="true"></i></button></td>
                                    <td WIDTH="95%" className="myfontCard">Sindhu I Acharya</td>
                                </tr>
                                <tr>
                                    <td width="20%"></td>
                                    <td WIDTH="95%"className="myfontCardbody">Personal Assistant</td>
                                </tr>
                                <tr>
                                    <td width="10%"></td>
                                    <td WIDTH="95%"className="myfontCardbody">Health Sciences</td>
                                </tr>
                                <tr>
                                    <td width="20%"><button className="mybutton-top" ><i className="fa fa-history fa-1x" aria-hidden="true"></i></button></td>
                                    <td WIDTH="95%" className="myfontCardbody">17 Yrs Experience</td>
                                </tr>
                                <tr>
                                    <td width="20%"><button className="mybutton-top" ><i className="fa fa-envelope fa-1x" aria-hidden="true"></i></button></td>
                                    <td WIDTH="95%" className="myfontCardbody">acharya.si@gov.in </td>
                                </tr>
                                
                            </tbody></table>
                        </div>
                    </div>
                </div>
                </div>
            </div>
            </div>
            <div className="row row1">
            <div className="col-md-4">
                <div className="panel panel-primary">
                <div className="panel-body">
                <div className="row">
                        <div className="col-md-4">
                            <img src='../assests/images/AdminStaffImg/Porwal.jpg' width="130px" height="160px;"></img>
                            
                        </div>
                        
                        <div className="col-md-8 carddata">
                            <table><tbody>
                                <tr>
                                    <td width="20%"><button className="mybutton-top" ><i className="fa fa-user fa-1x" aria-hidden="true"></i></button></td>
                                    <td WIDTH="95%" className="myfontCard">R.M. Porwal</td>
                                </tr>
                                <tr>
                                    <td width="20%"></td>
                                    <td WIDTH="95%"className="myfontCardbody">Jr. Translation Officer</td>
                                </tr>
                                <tr>
                                    <td width="10%"></td>
                                    <td WIDTH="95%"className="myfontCardbody">Hindi Cell</td>
                                </tr>
                                <tr>
                                    <td width="20%"><button className="mybutton-top" ><i className="fa fa-history fa-1x" aria-hidden="true"></i></button></td>
                                    <td WIDTH="95%" className="myfontCardbody">22 Yrs Experience</td>
                                </tr>
                                <tr>
                                    <td width="20%"><button className="mybutton-top" ><i className="fa fa-envelope fa-1x" aria-hidden="true"></i></button></td>
                                    <td WIDTH="95%" className="myfontCardbody">porwal.rm@icmr.gov.in</td>
                                </tr>
                                
                            </tbody></table>
                        </div>
                    </div>
                </div>
                </div>
            </div>
            <div className="col-md-4">
                <div className="panel panel-primary">
                <div className="panel-body">
                <div className="row">
                        <div className="col-md-4">
                            <img src='../assests/images/AdminStaffImg/man.png' width="135px" height="160px;"></img>
                            
                        </div>
                        
                        <div className="col-md-8 carddata">
                            <table><tbody>
                                <tr>
                                    <td width="20%"><button className="mybutton-top" ><i className="fa fa-user fa-1x" aria-hidden="true"></i></button></td>
                                    <td WIDTH="95%" className="myfontCard">Dhiren Mistry</td>
                                </tr>
                                <tr>
                                    <td width="20%"></td>
                                    <td WIDTH="95%"className="myfontCardbody">Upper Division Clerk</td>
                                </tr>
                                <tr>
                                    <td width="10%"></td>
                                    <td WIDTH="95%"className="myfontCardbody"></td>
                                </tr>
                                <tr>
                                    <td width="20%"><button className="mybutton-top" ><i className="fa fa-history fa-1x" aria-hidden="true"></i></button></td>
                                    <td WIDTH="95%" className="myfontCardbody"> Yrs Experience</td>
                                </tr>
                                <tr>
                                    <td width="20%"><button className="mybutton-top" ><i className="fa fa-envelope fa-1x" aria-hidden="true"></i></button></td>
                                    <td WIDTH="95%" className="myfontCardbody">mistry.da@gov.in</td>
                                </tr>
                                
                            </tbody></table>
                        </div>
                    </div>
                </div>
                </div>
            </div>
       
        
            <div className="col-md-4">
                <div className="panel panel-primary">
                <div className="panel-body">
                <div className="row">
                        <div className="col-md-4">
                            <img src='../assests/images/AdminStaffImg/saiyad.png' width="130px" height="160px;"></img>
                            
                        </div>
                        
                        <div className="col-md-8 carddata">
                            <table><tbody>
                                <tr>
                                    <td width="20%"><button className="mybutton-top" ><i className="fa fa-user fa-1x" aria-hidden="true"></i></button></td>
                                    <td WIDTH="95%" className="myfontCard">Mohammedsakib R Saiyed</td>
                                </tr>
                                <tr>
                                    <td width="20%"></td>
                                    <td WIDTH="95%"className="myfontCardbody">Upper Division Clerk</td>
                                </tr>
                                <tr>
                                    <td width="10%"></td>
                                    <td WIDTH="95%"className="myfontCardbody">Account Section</td>
                                </tr>
                                <tr>
                                    <td width="20%"><button className="mybutton-top" ><i className="fa fa-history fa-1x" aria-hidden="true"></i></button></td>
                                    <td WIDTH="95%" className="myfontCardbody">7 Yrs Experience</td>
                                </tr>
                                <tr>
                                    <td width="20%"><button className="mybutton-top" ><i className="fa fa-envelope fa-1x" aria-hidden="true"></i></button></td>
                                    <td WIDTH="95%" className="myfontCardbody">saiyed.msr@gov.in</td>
                                </tr>
                                
                            </tbody></table>
                        </div>
                    </div>
                </div>
                </div>
            </div>
            </div>
            <div className="row row1">
            <div className="col-md-4">
                <div className="panel panel-primary">
                <div className="panel-body">
                <div className="row">
                        <div className="col-md-4">
                            <img src='../assests/images/AdminStaffImg/niraj.jpg' width="135px" height="160px;"></img>
                            
                        </div>
                        
                        <div className="col-md-8 carddata">
                            <table><tbody>
                                <tr>
                                    <td width="20%"><button className="mybutton-top" ><i className="fa fa-user fa-1x" aria-hidden="true"></i></button></td>
                                    <td WIDTH="95%" className="myfontCard">Nirajbhai A Rathod</td>
                                </tr>
                                <tr>
                                    <td width="20%"></td>
                                    <td WIDTH="95%"className="myfontCardbody">Upper Division Clerk</td>
                                </tr>
                                <tr>
                                    <td width="10%"></td>
                                    <td WIDTH="95%"className="myfontCardbody">Establishment Section</td>
                                </tr>
                                <tr>
                                    <td width="20%"><button className="mybutton-top" ><i className="fa fa-history fa-1x" aria-hidden="true"></i></button></td>
                                    <td WIDTH="95%" className="myfontCardbody">5 Yrs Experience</td>
                                </tr>
                                <tr>
                                    <td width="20%"><button className="mybutton-top" ><i className="fa fa-envelope fa-1x" aria-hidden="true"></i></button></td>
                                    <td WIDTH="95%" className="myfontCardbody">rathod.na@icmr.gov.in</td>
                                </tr>
                                
                            </tbody></table>
                        </div>
                    </div>
                </div>
                </div>
            </div>
        
       
            
            <div className="col-md-4">
                <div className="panel panel-primary">
                <div className="panel-body">
                <div className="row">
                        <div className="col-md-4">
                            <img src='../assests/images/AdminStaffImg/pranali.jpg' width="135px" height="160px;"></img>
                            
                        </div>
                        
                        <div className="col-md-8 carddata">
                            <table><tbody>
                                
                                <tr>
                                    <td width="20%"><button className="mybutton-top" ><i className="fa fa-user fa-1x" aria-hidden="true"></i></button></td>
                                    <td WIDTH="95%" className="myfontCard">Pranali Shukla</td>
                                </tr>
                                <tr>
                                    <td width="20%"></td>
                                    <td WIDTH="95%"className="myfontCardbody">Upper Division Clerk</td>
                                </tr>
                                <tr>
                                    <td width="10%"></td>
                                    <td WIDTH="95%"className="myfontCardbody">Admin office</td>
                                </tr>
                                <tr>
                                    <td width="20%"><button className="mybutton-top" ><i className="fa fa-history fa-1x" aria-hidden="true"></i></button></td>
                                    <td WIDTH="95%" className="myfontCardbody">5 Yrs Experience</td>
                                </tr>
                                <tr>
                                    <td width="20%"><button className="mybutton-top" ><i className="fa fa-envelope fa-1x" aria-hidden="true"></i></button></td>
                                    <td WIDTH="95%" className="myfontCardbody">shukla.pv@icmr.gov.in</td>
                                </tr>
                               </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                </div>
            </div>
            </div>
            
            
        
     
        
        <div className="row">
       
          
            <div className="col-md-12">
                <center>
                <Link to="/AdminNioh"><button className="btn btn-primary" style={{fontSize:"19px", width:"35px", marginRight :"10px"}}>1</button></Link>
                <Link to="/otheradmin"><button className="btn btn-primary" style={{fontSize:"19px", width:"35px"}}>2</button></Link>
                   
                    <Routes>
                        <Route exact path='/otheradmin' element={<Otheradmin />}></Route> 
                    </Routes>
                </center>
            </div>
        </div>
       
        </div>

        </div>);
}
export default AdminNioh;